import React, { forwardRef, type ReactNode, type Ref } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import FieldBase from '../common/ui/index.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const getMessageKey = (fieldKey: string): MessageDescriptor => {
	if (fieldKey === 'storyPoints') {
		return messages.sumStoryPointsTooltip;
	}
	return messages.sumTimeEstimateTooltip;
};

const ToolTipContainer = forwardRef<HTMLElement, { children?: ReactNode }>((props, ref) => (
	<Box
		xcss={containerStylesOld}
		padding="space.100"
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		ref={ref as Ref<HTMLDivElement>}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		tabIndex={0}
	/>
));

export const EstimateCellOld = ({ fieldKey, value, groupName }: Props) => {
	const { formatMessage } = useIntl();

	const messageKey = getMessageKey(fieldKey);

	return (
		<Tooltip
			position="bottom"
			content={formatMessage(messageKey, { value })}
			tag={ToolTipContainer}
		>
			<Box
				as="span"
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.estimate-cell.${groupName}.${fieldKey}`}
				xcss={valueStyles}
			>
				{value}
			</Box>
		</Tooltip>
	);
};

const EstimateCell = ({ value, fieldKey, groupName }: Props) => {
	const estimate = value || '-';

	return (
		<FieldBase
			testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.estimate-cell.${groupName}.${fieldKey}`}
			message={messages.cellText}
			messageValues={{ estimate }}
			fieldKey={fieldKey}
		/>
	);
};

export default EstimateCell;

const containerStylesOld = xcss({
	display: 'flex',
	cursor: 'default',
	// Override the browser native focused color
	':focus': {
		outlineColor: 'color.border.focused',
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
	},
});

const valueStyles = xcss({
	flex: '1',
	fontWeight: token('font.weight.semibold'),
});
