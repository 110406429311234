import React, { type ChangeEvent, type SyntheticEvent, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import type UIAnalyticsEvent from '@atlaskit/analytics-next/UIAnalyticsEvent';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import {
	sort,
	filter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/utils.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import { ASSIGNEE_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { UserSelectItem } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH, ContentWrapper, ItemWrapper } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

type CommonEventHandler = (
	e: ChangeEvent<HTMLInputElement>,
	analyticsEvent: UIAnalyticsEvent,
) => void;

export function AssigneeCheckbox({
	person,
	checked,
	onChange,
}: {
	person: Person;
	checked: boolean;
	onChange: CommonEventHandler;
}) {
	const { title, email, avatarUrl } = person.jiraUser;

	return (
		<Checkbox
			value={person.personId}
			isChecked={checked}
			onChange={onChange}
			label={<UserSelectItem email={email} label={title} icon={avatarUrl} />}
		/>
	);
}

function AssigneeFilterOld({
	searchQuery,
	onQueryChange,
	value,
	onRequestChange,
	onRequestClear,
	assignees,
	setInitialFocusRef,
}: Props) {
	const intl = useIntl();
	const isSelected = (assignee: Person): boolean => R.includes(assignee.personId, value);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleAssigneeCheckboxChange = (e: SyntheticEvent<any>) => {
		const { value: personId, checked } = e.currentTarget;
		const nextValue = ((): typeof value => {
			const withoutTheAssignee = R.filter(R.pipe(R.equals(personId), R.not))(value);

			if (!checked) {
				return withoutTheAssignee;
			}

			return withoutTheAssignee.concat([personId]);
		})();

		onRequestChange(nextValue);
	};

	const assigneesToDisplay = sort(filter(searchQuery, assignees));

	return (
		<>
			<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
			<SearchField
				placeholder={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchAssigneePlaceholderIssueTermRefresh
						: messages.searchAssigneePlaceholder,
				)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={intl.formatMessage(messages.searchAssigneeLabel)}
				setInitialFocusRef={setInitialFocusRef}
			/>
			{assigneesToDisplay.map((assignee, index) => (
				<DialogMenuItem key={index}>
					<AssigneeCheckbox
						checked={isSelected(assignee)}
						onChange={handleAssigneeCheckboxChange}
						person={assignee}
					/>
				</DialogMenuItem>
			))}
			{R.isEmpty(assigneesToDisplay) && <NoMatchFound />}
		</>
	);
}

function AssigneeFilterInner({
	searchQuery,
	onQueryChange,
	value,
	onRequestChange,
	onRequestClear,
	assignees,
	setInitialFocusRef,
}: Props) {
	const intl = useIntl();
	const isSelected = (assignee: Person): boolean => R.includes(assignee.personId, value);

	const handleAssigneeCheckboxChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value: personId, checked } = e.currentTarget;
		const nextValue = ((): typeof value => {
			const withoutTheAssignee = value.filter((assigneeId) => assigneeId !== personId);
			if (!checked) {
				return withoutTheAssignee;
			}
			return withoutTheAssignee.concat([personId]);
		})();

		onRequestChange(nextValue);
	};

	const assigneesToDisplay = sort(filter(searchQuery, assignees));

	return (
		<>
			<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
			<SearchField
				placeholder={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchAssigneePlaceholderIssueTermRefresh
						: messages.searchAssigneePlaceholder,
				)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={intl.formatMessage(messages.searchAssigneeLabel)}
				setInitialFocusRef={setInitialFocusRef}
			/>
			{assigneesToDisplay.map((assignee, index) => (
				<ItemWrapper key={index}>
					<AssigneeCheckbox
						checked={isSelected(assignee)}
						onChange={handleAssigneeCheckboxChange}
						person={assignee}
					/>
				</ItemWrapper>
			))}
			{isEmpty(assigneesToDisplay) && <NoMatchFound />}
		</>
	);
}

const getNames = (assignees: Person[], selected: Set<string>) => {
	const selectedAssignees = assignees.filter((person) => selected.has(person.personId));
	const names = selectedAssignees.map((person) => person.jiraUser.title);
	return names.join(', ');
};

export function TriggerButtonText({
	value,
	persons,
}: {
	value: Props['value'];
	persons: Person[];
}) {
	const selected = new Set(value);

	if (selected.size === 0) {
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	}

	return <FilterText text={getNames(persons, selected)} />;
}

function AssigneeFilterDialogOld(props: Props) {
	const { isOpen, onOpenChange, value, intl, assignees } = props;

	const ariaText = () => {
		const selected = new Set(value);

		// ex: Asignees, All selected
		return `${intl.formatMessage(filterMessages[ASSIGNEE_FILTER_ID])}, ${
			selected.size > 0
				? getNames(assignees, selected)
				: intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			isOpen={isOpen}
			onClose={onOpenChange}
			content={
				<DialogMenuContainer>
					<AssigneeFilterOld {...props} />
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter"
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.trigger-btn"
				triggerButtonText={<TriggerButtonText value={props.value} persons={props.assignees} />}
				ariaLabel={ariaText()}
			/>
		</InlineDialog>
	);
}

function AssigneeFilterDialog(props: Props) {
	const { isOpen, onOpenChange, value, intl, assignees } = props;
	const triggerButtonRef = useRef<HTMLButtonElement>(null);
	const ariaText = () => {
		const selected = new Set(value);

		// ex: Assignees, All selected
		return `${intl.formatMessage(filterMessages[ASSIGNEE_FILTER_ID])}, ${
			selected.size > 0
				? getNames(assignees, selected)
				: intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			onClose={() => {
				onOpenChange({ isOpen: false });
				triggerButtonRef?.current?.focus();
			}}
			content={(contentProps) => {
				return (
					<ContentWrapper>
						<AssigneeFilterInner {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
					</ContentWrapper>
				);
			}}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter"
			trigger={(triggerProps) => {
				const { ref: triggerRef, ...triggerPropsForButton } = triggerProps;
				const mergedRef = mergeRefs(triggerRef, triggerButtonRef);
				return (
					<TriggerButton
						{...triggerPropsForButton}
						ref={mergedRef}
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.trigger-btn"
						triggerButtonText={<TriggerButtonText value={props.value} persons={props.assignees} />}
						ariaLabel={ariaText()}
					/>
				);
			}}
		/>
	);
}

export default componentWithFG(
	'migrate_plan_filter_to_popup',
	injectIntl(AssigneeFilterDialog),
	injectIntl(AssigneeFilterDialogOld),
);
