/** @jsx jsx */
import React from 'react';

import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Inline } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { ISSUE_STATUS_CATEGORIES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import FlyoutContent from '../../common/ui/flyout-content/index.tsx';
import messages from '../../common/ui/messages.tsx';
import type { Props } from './types.tsx';

const StatusFlyout = (props: Props) => {
	const { statuses, value, ariaLabel } = props;

	const label = fg('jira-issue-terminology-refresh-m3')
		? messages.issueCountLabelIssueTermRefresh
		: messages.issueCountLabel;

	const content = statuses.map((status) => {
		const getColorTile = () => {
			if (status.categoryId === ISSUE_STATUS_CATEGORIES.INPROGRESS) {
				return <span css={[tilesStyles, blueTile]} />;
			}
			if (status.categoryId === ISSUE_STATUS_CATEGORIES.DONE) {
				return <span css={[tilesStyles, greenTile]} />;
			}
			return <span css={[tilesStyles, grayTile]} />;
		};
		return {
			title: (
				<Inline alignBlock="center" space="space.075">
					{getColorTile()}
					{status.name}
				</Inline>
			),
			count: status.count,
		};
	});

	return (
		<FlyoutContent
			fieldName={messages.statusTitle}
			content={content}
			label={label}
			total={value.length}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.status-cell.status-flyout"
			ariaLabel={ariaLabel}
		/>
	);
};

export default StatusFlyout;

const greenTile = css({
	backgroundColor: token('color.border.success'),
});
const grayTile = css({
	backgroundColor: token('color.border'),
});
const blueTile = css({
	backgroundColor: token('color.border.information'),
});

const tilesStyles = css({
	borderRadius: token('border.radius.050'),
	width: '12px',
	height: '12px',
});
