import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dependencyFlagTitle: {
		id: 'portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.dependency-flag-title',
		defaultMessage: 'Add the Dependencies tab to continue',
		description: 'The title for the flag message.',
	},
	adminAddDependencyFlagBody: {
		id: 'portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.admin-add-dependency-flag-title-body',
		defaultMessage:
			'To view this dependency in more detail, add the Dependencies view to your plan using + in the navigation.',
		description: 'The body content for the flag message.',
	},
	nonAdminAddDependencyFlagBody: {
		id: 'portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.non-admin-add-dependency-flag-body',
		defaultMessage:
			'To view this dependency in more detail, ask the plan owner to add the tab back to this plan. You can see who plan owner is by going to Plan settings then Plan info.',
		description: 'The body content for the flag message.',
	},
});
