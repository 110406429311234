import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function ProgressBar(props: Props) {
	const { percentage } = props;

	// Capping percentage here as there is a bug where the percentage can be greater than 100
	// due to the count of changes to be saved going out of sync. Otter team to investigate
	// further into root cause - in the meantime due to low customer impact we are capping the percentage
	const displayPercentage = fg('cap_percentage_for_review_changes_modal_to_0_100')
		? Math.min(100, Math.max(0, percentage))
		: percentage;

	if (
		(percentage < 0 || percentage > 100) &&
		!fg('cap_percentage_for_review_changes_modal_to_0_100')
	) {
		throw new Error('Percentage must be within [0, 100] range');
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.container}>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.bar}
				style={{ width: `${displayPercentage}%` }}
				data-testid="portfolio-3-common.progress-bar.bar"
			/>
		</div>
	);
}
