import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getFocusAreasById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-focus-areas/index.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps = (state: State): StateProps => ({
	isExportMode: isExportMode(state),
	focusAreas: getFocusAreasById(state),
});

export default mapStateToProps;
