import { equals } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import { mapStateToPropsOld, mapStateToProps } from './query.tsx';
import View from './view.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const arePropsEqual = (a: any, b: any) => equals(a, b, 256);

const ConnectedIssueOld = connect(mapStateToPropsOld, mapDispatchToProps, null, {
	areOwnPropsEqual: arePropsEqual,
	areStatePropsEqual: arePropsEqual,
})(
	// @ts-expect-error - Argument of type 'ForwardRefExoticComponent<Omit<OwnProps & StateProps, keyof WithAnalyticsEventsProps> & RefAttributes<any>>' is not assignable to parameter of type 'ComponentType<Matching<StateProps & Partial<Props>, Omit<OwnProps & StateProps, keyof WithAnalyticsEventsProps> & RefAttributes<...>>>'.
	View,
);

const ConnectedIssue = connect(mapStateToProps, mapDispatchToProps, null, {
	areOwnPropsEqual: arePropsEqual,
	areStatePropsEqual: arePropsEqual,
})(
	// @ts-expect-error - Argument of type 'ForwardRefExoticComponent<Omit<OwnProps & StateProps, keyof WithAnalyticsEventsProps> & RefAttributes<any>>' is not assignable to parameter of type 'ComponentType<Matching<StateProps & Partial<Props>, Omit<OwnProps & StateProps, keyof WithAnalyticsEventsProps> & RefAttributes<...>>>'.
	View,
);

const Issue = componentWithFG(
	'plan-timeline-a11y-meatball-menu',
	ConnectedIssue,
	ConnectedIssueOld,
);

export default Issue;
