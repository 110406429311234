import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import StandardButton from '@atlaskit/button/standard-button';
import { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const RemoveIssuesDialog = ({
	toggleModalDialog,
	bulkActionSuccess,
	removeFromPlan,
	shouldReturnFocus,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ShortcutScope>
			<ModalDialog
				messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.remove-issues.modal-dialog"
				messageType="transactional"
				shouldScrollInViewport
				onClose={toggleModalDialog}
				{...(fg('plan_timeline_layering_wrapper')
					? { shouldReturnFocus, autoFocus: true }
					: { autoFocus: false })}
			>
				<ModalHeader>
					<ModalTitle appearance="danger">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.removeFromPlanTitleIssueTermRefresh
								: messages.removeFromPlanTitle,
						)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text>
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.removeIssueFromPlanMessageIssueTermRefresh
								: messages.removeIssueFromPlanMessage)}
							values={{
								b: (chunks) => <b>{chunks}</b>,
							}}
						/>
					</Text>
				</ModalBody>
				<ModalFooter>
					<StandardButton appearance="subtle" onClick={toggleModalDialog}>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
					<StandardButton
						appearance="primary"
						onClick={(
							_,
							analyticsEvent: UIAnalyticsEvent,
							event?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
						) => {
							removeFromPlan();
							if (fg('plan-timeline-a11y-meatball-menu')) {
								toggleModalDialog(event);
							}
							bulkActionSuccess(analyticsEvent);
						}}
					>
						{formatMessage(messages.removeFromPlanActionButton)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default RemoveIssuesDialog;
