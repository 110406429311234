import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { expVal } from '@atlassian/jira-feature-experiments';
import mapDispatchToPropsOld from './command.tsx';
import mapStateToProps, { createOldStructuredSelector } from './query.tsx';
import type {
	OldDispatchProps,
	OwnProps,
	StateProps,
	OldStateProps,
	OldOwnProps,
} from './types.tsx';
import View from './view.tsx';
import ViewOld from './view-old.tsx';

const ConfigurationDialog = connect<StateProps, {}, OwnProps, State>(mapStateToProps, null)(View);

const ConfigurationDialogOld = connect<OldStateProps, OldDispatchProps, OldOwnProps, State>(
	createOldStructuredSelector,
	mapDispatchToPropsOld,
)(ViewOld);

// remember to remove old messages and styles.module.css file as well when clean up m1_features_page_experiment
export default componentWithCondition(
	() => expVal('m1_features_page_experiment', 'cohort', 'not-enrolled') === 'variation',
	ConfigurationDialog,
	ConfigurationDialogOld,
);
