import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	priorities: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.priorities',
		defaultMessage:
			'{count} <qualifier>{priorityName} {count, plural, one {priority} other {priorities}}</qualifier>',
		description:
			'The label to show the number of issue priorities truncated within the group header.',
	},
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.aria-label',
		defaultMessage:
			'{count} {priorityName} {count, plural, one {priority} other {priorities}} for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Priority field in the group header row',
	},
});
