import { useLayoutEffect, useState, type RefObject } from 'react';
import { token } from '@atlaskit/tokens';
import {
	startOfUtcDay,
	endOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { dropTargetForDependencyCreate } from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type {
	TimelineRange,
	Timestamp,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { SCOPE_ROW_HEIGHT } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import type { BaselineDates, ForcedGradients } from './types.tsx';

const GRADIENT_LENGTH_PERCENTAGE = 30;
const MINIMUM_LEFT_FORCED_GRADIENT_PERCENTAGE = 12;
const MAXIMUM_RIGHT_FORCED_GRADIENT_PERCENTAGE = 100 - MINIMUM_LEFT_FORCED_GRADIENT_PERCENTAGE;

export const HORIZONTAL_HIT_TARGET_THRESHOLD = SCOPE_ROW_HEIGHT / 4;

export const getPositionsForBar = (
	{ baselineStart, baselineEnd }: BaselineDates,
	timelineRange: TimelineRange,
	forcedGradients?: ForcedGradients,
): {
	leftPositionPercentage: number;
	rightPositionPercentage: number;
	leftAnchorPercentage: number;
	rightAnchorPercentage: number;
} => {
	const timelineSize = timelineRange.end - timelineRange.start;
	const timestampToTimelineLeftPercentage = (time: Timestamp) =>
		((startOfUtcDay(time) - timelineRange.start) / timelineSize) * 100;

	const timestampToTimelineRightPercentage = (time: Timestamp) =>
		((endOfUtcDay(time) - timelineRange.start) / timelineSize) * 100;

	// Relative to the *left* edge of *timeline* as a percentage of *timeline* width.
	let leftPositionPercentage = 0;
	// Relative to the *right* edge of *timeline* as a percentage of *timeline* width.
	let rightPositionPercentage = 0;
	// Relative to the *left* edge of *bar* as a percentage of *bar* width.
	let leftAnchorPercentage = 0;
	// Relative to the *left* (sic!) edge of *bar* as a percentage of *bar* width.
	let rightAnchorPercentage = 100;

	if (isDefined(baselineStart) && isDefined(baselineEnd)) {
		leftPositionPercentage = timestampToTimelineLeftPercentage(baselineStart);
		rightPositionPercentage = 100 - timestampToTimelineRightPercentage(baselineEnd);
	} else if (isDefined(baselineStart)) {
		leftPositionPercentage = timestampToTimelineLeftPercentage(baselineStart);
		rightPositionPercentage = 100 - (leftPositionPercentage + GRADIENT_LENGTH_PERCENTAGE);
		rightAnchorPercentage = 0;
	} else if (isDefined(baselineEnd)) {
		rightPositionPercentage = 100 - timestampToTimelineRightPercentage(baselineEnd);
		leftPositionPercentage = 100 - (rightPositionPercentage + GRADIENT_LENGTH_PERCENTAGE);
		leftAnchorPercentage = 100;
	}

	if (forcedGradients) {
		const earliestStartPercentage = timestampToTimelineLeftPercentage(
			forcedGradients.earliestStart,
		);

		const latestEndPercentage = timestampToTimelineRightPercentage(forcedGradients.latestEnd);

		if (forcedGradients.left) {
			leftPositionPercentage = Math.min(
				timestampToTimelineRightPercentage(forcedGradients.earliestEnd) -
					GRADIENT_LENGTH_PERCENTAGE,
				earliestStartPercentage,
			);
		}

		if (forcedGradients.right) {
			rightPositionPercentage =
				100 -
				Math.max(
					timestampToTimelineLeftPercentage(forcedGradients.latestStart) +
						GRADIENT_LENGTH_PERCENTAGE,
					latestEndPercentage,
				);
		}

		const totalPercentage = 100 - (leftPositionPercentage + rightPositionPercentage);

		if (forcedGradients.left) {
			leftAnchorPercentage = Math.max(
				MINIMUM_LEFT_FORCED_GRADIENT_PERCENTAGE,
				(100 * (earliestStartPercentage - leftPositionPercentage)) / totalPercentage,
			);
		}

		if (forcedGradients.right) {
			rightAnchorPercentage = Math.min(
				MAXIMUM_RIGHT_FORCED_GRADIENT_PERCENTAGE,
				(100 * (latestEndPercentage - leftPositionPercentage)) / totalPercentage,
			);
		}
	}

	return {
		leftPositionPercentage,
		rightPositionPercentage,
		leftAnchorPercentage,
		rightAnchorPercentage,
	};
};

// Convert ratio to percentage and limit fraction part length.
const ratioToCSS = (x: number) => Math.round(x * 1e6) / 1e4;

// Colors are passed to Bar in hex form.
// To create nice fade effect we need to fade to the same color, but with opacity 0.
// Which should be represented in rgba() form.
// This function does conversion like #102030 -> rgba(16, 32, 48, 0)
const colorToFadeColor = (x: string) => {
	const opacity = 0.1;
	const r = parseInt(x.slice(1, 3), 16);
	const g = parseInt(x.slice(3, 5), 16);
	const b = parseInt(x.slice(5, 7), 16);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/** Represent color stripes as a multi-layer background, optionally fading in and/or out.
 *
 * Each element of `colors` provides a `colour` of the corresponding stripe,
 * and its height to bar's height `ratio`.
 *
 * Each stripe fades in to the `leftAnchorPercentage` of its length,
 * and fades out starting from the `rightAnchorPercentage` of its length.
 *
 * Implementation notes:
 *
 * When no fading is required linear-gradient still need to be used as raw color can't be used
 * on any layer except the last one.
 * Ref https://developer.mozilla.org/en-US/docs/Web/CSS/background#Formal_syntax
 *
 * Position of layer is given as a percentage of entire bar height, but size must be provided as
 * a percentage of height left (from layer position to bar bottom)!
 * Similar weirdness applies to the width of fade in/out layers.
 * Extra width is added to them to avoid small gaps which appear when percentage adds up perfectly
 * (but why?! please tell us if you know!).
 */
export const colorsToGradient = ({
	colors,
	leftAnchorPercentage,
	rightAnchorPercentage,
	fadeSolidPercentage,
}: {
	colors: {
		colour: string;
		ratio: number;
	}[];
	leftAnchorPercentage: number;
	rightAnchorPercentage: number;
	fadeSolidPercentage?: number;
}) => {
	const antiGapFactor = 1.005;
	const layers: Array<string> = [];
	let progress = 0;
	for (const { colour, ratio } of colors) {
		const top = ratioToCSS(progress);
		const height = ratioToCSS(ratio / (1 - progress));
		if (leftAnchorPercentage > 0) {
			const image = `linear-gradient(to left, ${colour}${
				fadeSolidPercentage ? `, ${colour} ${fadeSolidPercentage}%` : ''
			}, ${colorToFadeColor(colour)})`;
			const position = `0% ${top}% / ${leftAnchorPercentage * antiGapFactor}% ${height}% no-repeat`;
			layers.push(`${image} ${position}`);
		}
		const solidPercentage = rightAnchorPercentage - leftAnchorPercentage;
		if (solidPercentage > 0) {
			const image = `linear-gradient(${colour}, ${colour})`;
			const position = `${
				solidPercentage < 100 ? ratioToCSS(leftAnchorPercentage / (100 - solidPercentage)) : 100
			}% ${top}% / ${solidPercentage * antiGapFactor}% ${height}% no-repeat`;
			layers.push(`${image} ${position}`);
		}
		if (rightAnchorPercentage < 100) {
			const image = `linear-gradient(to right, ${colour}${
				fadeSolidPercentage ? `, ${colour} ${fadeSolidPercentage}%` : ''
			}, ${colorToFadeColor(colour)})`;
			const position = `100% ${top}% / ${
				(100 - rightAnchorPercentage) * antiGapFactor
			}% ${height}% no-repeat`;
			layers.push(`${image} ${position}`);
		}
		progress += ratio;
	}
	return layers.join(', ');
};

export const getStripesStyle = (
	stripes: {
		left: boolean;
		right: boolean;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	colors: any,
) => {
	const stripesLayer = `repeating-linear-gradient(
        120deg,
        transparent,
        transparent 15px,
        ${token('color.background.inverse.subtle.pressed')} 15px,
        ${token('color.background.inverse.subtle.pressed')} 30px,
        transparent 30px
    )`;

	const { left, right } = stripes;
	if (!(left || right)) {
		return {};
	}

	if (left && right) {
		return { background: stripesLayer };
	}

	// Gradients of cover layers is reversed relative to stripes position
	// as it supposed to cover them where we don't want to see stripes.
	const coverLayers = colorsToGradient({
		colors,
		leftAnchorPercentage: left ? 100 : 0,
		rightAnchorPercentage: right ? 0 : 100,
		fadeSolidPercentage: 25,
	});

	return { background: `${coverLayers}, ${stripesLayer}` };
};

export const useDropTarget = (ref: RefObject<HTMLElement | null>) => {
	const { row } = useRow();
	const [isDraggedOver, setIsDraggedOver] = useState(false);

	useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}

		return dropTargetForDependencyCreate({
			element: ref.current,
			onDragEnter: () => setIsDraggedOver(true),
			onDragLeave: () => setIsDraggedOver(false),
			onDrop: () => setIsDraggedOver(false),
			getData: () => ({ row }),
		});
	}, [ref, row]);

	return isDraggedOver;
};
