import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	// Incoming dependencies messages
	incomingIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.incoming-dependencies.issues',
		defaultMessage: '{count, plural, one {# issue} other {# issues}}',
		description: "Number of issue's inward links",
	},
	incomingAddDependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.incoming-dependencies.add-dependency',
		defaultMessage: '+ Add dependency',
		description: 'Label for button to add incoming dependency',
	},
	incomingIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.incoming-dependencies.issues-issue-term-refresh',
		defaultMessage: '{count, plural, one {# work item} other {# work items}}',
		description: "Number of issue's inward links",
	},

	// Outgoing dependencies messages
	outgoingIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.outgoing-dependencies.issues',
		defaultMessage: '{count, plural, one {# issue} other {# issues}}',
		description: "Number of issue's outward links",
	},
	outgoingAddDependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.outgoing-dependencies.add-dependency',
		defaultMessage: '+ Add dependency',
		description: 'Label for button to add outgoing dependency',
	},
	outgoingIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-cells.outgoing-dependencies.issues-issue-term-refresh',
		defaultMessage: '{count, plural, one {# work item} other {# work items}}',
		description: "Number of issue's outward links",
	},

	addDependenciesPopupLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.add-dependencies-popup-label',
		defaultMessage: 'Add dependencies',
		description: 'Label for dialog to add dependencies',
	},
	dependenciesPopupLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.dependencies-popup-label',
		defaultMessage: 'Dependencies',
		description: 'Label for dependencies dialog',
	},
});
