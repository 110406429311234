import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip, { type PositionType } from '@atlaskit/tooltip';
import Icon from '@atlaskit/icon';
import { useIntl } from '@atlassian/jira-intl';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { scalesGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/scales/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type WeightTagProps = {
	options: OptionProperty[];
	tooltipPosition?: PositionType;
	isInsideTooltip?: boolean;
};

type WeightTagBodyProps = {
	totalWeight: number;
};
const WeightTagBody = ({ totalWeight }: WeightTagBodyProps) => {
	const { formatNumber } = useIntl();

	return (
		<TagContainer>
			<Icon glyph={scalesGlyph} size="small" label="Weighting value" />
			<Tag data-testid="polaris-component-weight-tag.ui.tag">
				{formatNumber(totalWeight, {
					notation: 'compact',
					compactDisplay: 'short',
					maximumFractionDigits: 1,
				})}
			</Tag>
		</TagContainer>
	);
};

export const WeightTag = (props: WeightTagProps) => {
	const { options = [], tooltipPosition = 'bottom', isInsideTooltip = false } = props;
	const { formatNumber } = useIntl();
	const totalWeight = useMemo(
		() => options.reduce((acc, { weight = 0 }) => acc + weight, 0),
		[options],
	);

	// we cannot have Tooltip inside Tooltip
	if (isInsideTooltip && fg('polaris-pinned-fields-for-ideas-in-plans')) {
		return <WeightTagBody totalWeight={totalWeight} />;
	}

	return (
		<Tooltip
			content={totalWeight < 1000 && totalWeight > -1000 ? undefined : formatNumber(totalWeight)}
			position={tooltipPosition}
			testId="polaris-component-weight-tag.ui.tooltip"
		>
			<WeightTagBody totalWeight={totalWeight} />
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	height: '22px',
	minWidth: '28px',
	gap: token('space.075'),
	paddingTop: 0,
	paddingRight: token('space.025'),
	paddingBottom: 0,
	paddingLeft: token('space.025'),
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.disabled')}`,
	borderRadius: '3px',
	backgroundColor: token('color.background.neutral'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Tag = styled.div({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
});
