import React from 'react';
import AggregateField, {
	AggregateFieldOld,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/group/aggregate-field/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import type { Props } from './types.tsx';

// @Export for testing
export const isAggregateSupported = (
	columnId: string,
	startDateCustomFieldId: number | undefined,
) => {
	if (startDateCustomFieldId && startDateCustomFieldId.toString() === columnId) {
		return true;
	}

	switch (columnId) {
		// first iteration
		case 'dueDate':
		case 'targetStart':
		case 'targetEnd':
		case 'estimate':
			return true;

		// second iteration
		case 'issueStatus':
		case 'assignee':
		case 'breakdown':
		case 'team':
		case 'priority':
		case 'progressByEstimation':
		case 'goal':
		case 'release':
		case 'labels':
		case 'ideas':
		case 'incomingDependencies':
		case 'outgoingDependencies':
			if (!expValEquals('aggregate_fields_for_plan_m2_experiment', 'cohort', 'variation')) {
				return false;
			}
			return true;

		default:
			return false;
	}
};

export const GroupHeaderField = ({ showAggregate, startDateCustomField, columnId, item }: Props) =>
	showAggregate &&
	columnId &&
	item?.group &&
	isAggregateSupported(columnId, startDateCustomField?.id) ? (
		<AggregateField id={columnId} groupName={item.group} item={item} />
	) : null;

export const GroupHeaderFieldOld = ({
	showAggregate,
	startDateCustomField,
	columnId,
	group,
}: Props) =>
	showAggregate && columnId && group && isAggregateSupported(columnId, startDateCustomField?.id) ? (
		<AggregateFieldOld id={columnId} groupName={group} />
	) : null;
