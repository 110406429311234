/** @jsx jsx */
/* eslint-disable  @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
import React, {
	type CSSProperties,
	type PropsWithChildren,
	memo,
	type ReactNode,
	useCallback,
	useLayoutEffect,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { css, jsx } from '@compiled/react';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';
import { xcss } from '@atlaskit/primitives';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';

import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useNativeScrollbarHeight } from '@atlassian/jira-portfolio-3-common/src/custom-scrollbar/utils.tsx';
import {
	ADD_FIELDS,
	FIELD,
	HEADER,
	SCOPE,
	SUBHEADER,
	TABLE_ITEM,
	TIMELINE,
	EMPTY,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import {
	TABLE_BLANK_ROW,
	TABLE_GROUP,
	TABLE_GROUP_HEADER,
	TABLE_INLINE_CREATE,
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import {
	EDIT,
	EXPORT,
	OPTIMIZED,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { INLINE_CREATE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/reducer.tsx';
import type {
	RowConfig,
	ColumnConfig,
	LiteRowConfig,
} from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import { Grid } from '@atlassian/jira-portfolio-3-treegrid/src/ui/grid/index.tsx';
import { Row as RowPrimitive } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row/index.tsx';
import { Row as RowCompiled } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row-compiled/index.tsx';
import { useParentIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import ZoomLevelSync from '../horizontal-scrolling/zoom-level-sync/index.tsx';
import AllIssueSelect from '../scope/all-issues-select/index.tsx';
import InlineCreate from '../scope/issues/inline-create/index.tsx';
import NowLine from '../timeline/now/index.tsx';
import { InlineCreatePopper } from './body/inline-create-popper/index.tsx';
import { Field as FieldComponent } from './body/field-new/index.tsx';
import { Field } from './body/field/index.tsx';
import { GroupHeader } from './body/group-header/index.tsx';
import { GroupHeaderField } from './body/group-header-field/index.tsx';
import { Group } from './body/group/index.tsx';
import { OrphanDropTarget } from './body/orphan-drop-target/index.tsx';
import { Scope } from './body/scope/index.tsx';
import { TimelineAligned } from './body/timeline/aligned/index.tsx';
import { TimelineAxis } from './body/timeline/axis/index.tsx';
import { TimelineArrows, TimelineArrowsProvider } from './body/timeline/arrows/index.tsx';
import { TimelineIssueRow } from './body/timeline/issue-row/index.tsx';
import { TimelineReleaseBar } from './body/timeline/release-bar/index.tsx';
import { TimelineTableGroup } from './body/timeline/table-group/index.tsx';
import { SCROLL_PADDING } from './constants.tsx';
import { EmptyView } from './empty/index.tsx';
import { FieldsHeader } from './header/fields/index.tsx';
import { ScopeHeader } from './header/scope/index.tsx';
import { TimelineHeader } from './header/timeline/index.tsx';
import messages from './messages.tsx';
import { Arrows } from './overlay/arrows/index.tsx';
import { AxisFirstTimeUnit } from './overlay/axis-first-time-unit/index.tsx';
import { Blanket } from './overlay/blanket/index.tsx';
import { ColumnText, TimelineColumnText } from './overlay/column-text/index.tsx';
import { DependencyDragPreview } from './overlay/dependency-drag-preview/view.tsx';
import { DependencyLineDetail } from './overlay/dependency-line-detail/index.tsx';
import { DependencyLinesWrapper } from './overlay/dependency-lines/index.tsx';
import { EmptyList } from './overlay/empty-list/index.tsx';
import { ScrollShadowOverlay } from './overlay/scroll-shadow/index.tsx';
import { ScrollBar } from './scroll-bar/index.tsx';
import { AddFields } from './subheader/add-fields/index.tsx';
import { FieldSubheader } from './subheader/field/index.tsx';
import { ScopeSubheader } from './subheader/scope/index.tsx';
import { TimelineSubheader } from './subheader/timeline/index.tsx';
import type { Props } from './types.tsx';
import {
	getDefaultStickyOffset,
	makeRangeExtractor,
	getColumnIds,
	getViewportInset,
	getBackgroundColor,
} from './utils.tsx';
import { BackgroundProvider } from './utils/background-provider/index.tsx';
import { Colspan as ColspanProvider } from './utils/colspan/index.tsx';
import { createGetSelectColspan } from './utils/colspan/selectors.tsx';
import { createGetSelectRowspan } from './utils/rowspan/selectors.tsx';
import { Collapsible } from './utils/column-collapse/index.tsx';
import { useColumnToggle } from './utils/column-collapse/utils/hooks.tsx';
import { ColumnWidthsProvider } from './utils/column-widths-provider/index.tsx';
import { DependencyDragCreate } from './utils/dependency-drag-create/index.tsx';
import { ColumnDragAutoScroll } from './utils/draggable-column/drag-auto-scroll/index.tsx';
import { EnhancedCell as EnhancedCellCompiled } from './utils/enhanced-cell-compiled/index.tsx';
import { EnhancedCell as EnhancedCellPrimitive } from './utils/enhanced-cell/index.tsx';
import { FieldAccessories } from './utils/fields-accessories/index.tsx';
import { HorizontalScrollingSync } from './utils/horizontal-scrolling-sync/index.tsx';
import { ItemProvider } from './utils/item-provider/index.tsx';
import { ResizeHandle } from './utils/resize-handle/index.tsx';
import { ScrollReset } from './utils/scroll-reset/index.tsx';
import { TimelineDragAutoScroll } from './utils/timeline-auto-scroll/index.tsx';
import { TimelineDragAutoScroll as TimelineDragAutoScrollOld } from './utils/timeline-drag-auto-scroll/view.tsx';

export const PlanTable: React.FC<Props> = ({
	rows,
	rowIds,
	rowConfigs,
	columnConfigs,
	stickyRows,
	columns,
	mode,
	viewMode,
	viewId,
	reportTimeToInteractive,
	fieldColumns,
	showOptimizations,
	items,
	containerRef,
}) => {
	const startTime = useRef<number | null>(performance.now());
	const columnIds = useMemo(() => getColumnIds(columns), [columns]);
	const numOfRows = rowConfigs.length;
	const hasGroups = rows.some(
		(row) => row.tag === TABLE_ITEM && row.payload.itemTag === TABLE_GROUP,
	);
	const rangeExtractor = useMemo(() => makeRangeExtractor(stickyRows), [stickyRows]);
	const isExportMode = mode === EXPORT;
	const isReadOnly = mode !== EDIT;
	const nativeScrollbarHeight = useNativeScrollbarHeight() ?? 0;
	const hasFields = columns.length > 2;

	const shouldHideScrollbar = !isExportMode && viewMode === VIEW_MODES.TIMELINE;

	const viewportInset = useMemo(
		() => (shouldHideScrollbar ? getViewportInset(nativeScrollbarHeight) : undefined),
		[shouldHideScrollbar, nativeScrollbarHeight],
	);

	const renderRow = useCallback(
		(
			_row: RowConfig,
			_columns: ColumnConfig[],
			index: number,
			liteRow: LiteRowConfig | undefined,
		): ReactNode => {
			const row = rows[index];
			const key = rowIds[index] ?? index;

			if (row.tag === HEADER) {
				return (
					<MemoedHeaderRow
						key={key}
						index={index}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
					/>
				);
			}

			if (row.tag === SUBHEADER) {
				return (
					<MemoedSubheaderRow
						key={key}
						index={index}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
					/>
				);
			}

			if (items && fg('plans_performance_improvements_3')) {
				return (
					<ItemRow
						key={key}
						row={index}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
						hasFields={hasFields}
						liteRow={liteRow}
						item={items[row.payload.itemIndex]}
						fieldColumns={fieldColumns}
						showOptimizations={showOptimizations}
						isReadOnly={isReadOnly}
					/>
				);
			}

			return (
				<WrappedItemProvider
					key={key}
					row={index}
					itemIndex={row.payload.itemIndex}
					columns={columns}
					mode={mode}
					viewMode={viewMode}
					hasFields={hasFields}
					liteRow={liteRow}
					fieldColumns={fieldColumns}
					showOptimizations={showOptimizations}
					isReadOnly={isReadOnly}
				/>
			);
		},
		[
			rows,
			rowIds,
			columns,
			mode,
			viewMode,
			hasFields,
			fieldColumns,
			showOptimizations,
			items,
			isReadOnly,
		],
	);

	const EnhancedCell = fg('plans_performance_improvements_3')
		? EnhancedCellCompiled
		: EnhancedCellPrimitive;

	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	const renderEmpty = useCallback(
		(height?: number, isFirstRow?: boolean): ReactNode => (
			<Row index={-1} height={height} role="presentation">
				{columns.map((column, index) => (
					<EnhancedCell key={index} column={index} hasRowSpan={isFirstRow}>
						{/* If we only have 2 rows (header and subheader), it means there are no issues in the plan  */}
						{column.tag === SCOPE && numOfRows === 2 && <EmptyView />}

						{column.tag === TIMELINE && (
							<TimelineAligned offset={SCROLL_PADDING}>
								<TimelineAxis />
								<NowLine noMarker />
								<TimelineReleaseBar noMarker />
							</TimelineAligned>
						)}
					</EnhancedCell>
				))}
			</Row>
		),
		[Row, columns, EnhancedCell, numOfRows],
	);

	useLayoutEffect(() => {
		reportTimeToInteractive({
			isNewSidebarEnabled: true,
			isTransposed: true,
		});
	}, [reportTimeToInteractive]);

	useEffect(() => {
		if (startTime.current && fg('plans_action_timing_metrics')) {
			addSpanToAll(
				'custom',
				'plans roadmap table render',
				[{ name: 'jira-spa' }, { name: 'plans-roadmap-table' }],
				startTime.current,
				performance.now(),
			);
			startTime.current = null;
		}
	}, []);

	// Remove this abstraction when cleaning up plan_timeline_arrows_transposition
	const withTimelineArrowsProvider = (table: ReactNode) =>
		fg('plan_timeline_arrows_transposition') ? (
			<TimelineArrowsProvider>{table}</TimelineArrowsProvider>
		) : (
			table
		);

	return (
		<>
			<ScrollShadowOverlay hasGroups={hasGroups} />
			<ColumnWidthsProvider
				columnIds={columnIds}
				columns={columns}
				viewMode={viewMode}
				viewId={viewId}
			>
				{({
					value: columnWidths,
					defaultValue: defaultColumnWidths,
					onChange: handleColumnWidthsChange,
					resizer,
				}) => (
					<Grid
						layout="fixed"
						columnWidths={columnWidths}
						columnConfigs={columnConfigs}
						rowConfigs={rowConfigs}
						resizer={resizer}
						row={renderRow}
						empty={renderEmpty}
						overscan={isExportMode ? Infinity : 20}
						stickyOffset={getDefaultStickyOffset()}
						rangeExtractor={rangeExtractor}
						virtualizationScrollThreshold={400 /** approx. 10 rows */}
						onColumnWidthsChange={handleColumnWidthsChange}
						getSelectColspan={
							fg('plan_timeline_colspan') ? createGetSelectColspan(viewMode, columns) : undefined
						}
						getSelectRowspan={
							fg('plan_timeline_colspan') ? createGetSelectRowspan(viewMode) : undefined
						}
					>
						{(table) => {
							if (viewMode === VIEW_MODES.LIST) {
								return (
									<>
										{!hasFields && <EmptyList />}
										{fg('plan_timeline_colspan') ? (
											table
										) : (
											<ColspanProvider viewMode={viewMode} columns={columns}>
												{table}
											</ColspanProvider>
										)}
										<ScrollReset viewId={viewId} viewMode={viewMode} />
										{fg('plan_timeline_drag_and_drop_field_columns') && (
											<ColumnDragAutoScroll containerRef={containerRef} />
										)}
									</>
								);
							}

							return (
								<>
									<AxisFirstTimeUnit />
									{!fg('plan_timeline_arrows_transposition') && <Arrows />}
									<DependencyDragPreview />
									<Blanket />
									<Collapsible
										columnIds={columnIds}
										onColumnWidthsChange={handleColumnWidthsChange}
										defaultColumnWidths={defaultColumnWidths}
										resizer={resizer}
									>
										{fg('plan_timeline_colspan') ? (
											withTimelineArrowsProvider(table)
										) : (
											<ColspanProvider viewMode={viewMode} columns={columns}>
												{withTimelineArrowsProvider(table)}
											</ColspanProvider>
										)}
									</Collapsible>
									<DependencyLinesWrapper />
									<DependencyLineDetail />
									<HorizontalScrollingSync columnWidths={columnWidths} offset={SCROLL_PADDING} />
									<ZoomLevelSync />
									<ScrollReset viewId={viewId} viewMode={viewMode} />
									<ScrollBar bottom={viewportInset?.bottom ?? 0} />

									{fg('plan_timeline_drag_and_drop_field_columns') ? (
										<TimelineDragAutoScroll containerRef={containerRef} />
									) : (
										<TimelineDragAutoScrollOld containerRef={containerRef} />
									)}
								</>
							);
						}}
					</Grid>
				)}
			</ColumnWidthsProvider>
			<DependencyDragCreate rows={rows} />
		</>
	);
};

const HeaderRow: React.FC<{ index: number } & Pick<Props, 'columns' | 'mode' | 'viewMode'>> = ({
	index,
	columns,
	mode,
	viewMode,
}) => {
	const EnhancedCell = fg('plans_performance_improvements_3')
		? EnhancedCellCompiled
		: EnhancedCellPrimitive;

	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	return (
		<Row
			index={index}
			style={
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{
					'--plan-grid-row-background': token('color.background.neutral'),
				} as CSSProperties
			}
		>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				columns.map((column, index) => {
					if (column.tag === SCOPE) {
						return (
							<EnhancedCell
								key={index}
								column={index}
								hasBorderBottom
								resizeHandle={<ResizeHandle />}
								top={0}
								rowType="header"
							>
								<AllIssueSelect>
									{({ isSelected }) => <ScopeHeader isSelected={isSelected} />}
								</AllIssueSelect>
							</EnhancedCell>
						);
					}

					if (column.tag === TIMELINE) {
						return (
							<EnhancedCell
								key={index}
								column={index}
								hasBorderBottom
								top={0}
								columnText={
									<TimelineColumnText
										column={index}
										hasStripe={mode === OPTIMIZED}
										title={<FormattedMessage {...messages.timeline} />}
										icon={null}
										appearance="section"
										useColumnToggle={useColumnToggle}
									/>
								}
								rowType="header"
							>
								<TimelineAligned offset={SCROLL_PADDING}>
									<TimelineHeader />
								</TimelineAligned>
							</EnhancedCell>
						);
					}

					if (index === 1) {
						// FIELDS
						return (
							<EnhancedCell
								key={index}
								column={index}
								hasBorderBottom
								columnText={
									<ColumnText
										column={index}
										title={<FormattedMessage {...messages.fields} />}
										icon={null}
										appearance="section"
										hasStripe={mode === OPTIMIZED}
										useColumnToggle={useColumnToggle}
									/>
								}
								resizeHandle={viewMode === VIEW_MODES.TIMELINE ? <ResizeHandle /> : undefined}
								clipped={false}
								top={0}
								rowType="header"
							>
								<FieldsHeader
									useColumnToggle={useColumnToggle}
									collapsible={viewMode === VIEW_MODES.TIMELINE}
								/>
							</EnhancedCell>
						);
					}

					return null;
				})
			}
		</Row>
	);
};

const MemoedHeaderRow = memo(HeaderRow);

const SubheaderRow: React.FC<{ index: number } & Pick<Props, 'columns' | 'mode' | 'viewMode'>> = ({
	index,
	columns,
	mode,
	viewMode,
}) => {
	const EnhancedCell = fg('plans_performance_improvements_3')
		? EnhancedCellCompiled
		: EnhancedCellPrimitive;

	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	return (
		<Row index={index}>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				columns.map((column, index) => {
					if (column.tag === SCOPE) {
						return (
							<EnhancedCell
								key="scope"
								column={index}
								top={50}
								hasBorderBottom
								hasColumnDropIndicator
								rowType="subheader"
							>
								<AllIssueSelect>
									{({ totalSelected, isSelected, isIndeterminate, toggleAllSelected }) => (
										<ScopeSubheader
											totalSelected={totalSelected}
											isSelected={isSelected}
											isIndeterminate={isIndeterminate}
											toggleAllSelected={toggleAllSelected}
										/>
									)}
								</AllIssueSelect>
							</EnhancedCell>
						);
					}

					if (column.tag === ADD_FIELDS || column.tag === EMPTY) {
						return (
							<EnhancedCell
								key="add-fields"
								column={index}
								top={50}
								hasBorderBottom
								rowType="subheader"
							>
								<AddFields />
							</EnhancedCell>
						);
					}

					if (column.tag === TIMELINE) {
						return (
							<EnhancedCell key={index} column={index} top={50} hasBorderBottom rowType="subheader">
								<TimelineAligned offset={SCROLL_PADDING}>
									<TimelineSubheader />
									<TimelineReleaseBar />
								</TimelineAligned>
							</EnhancedCell>
						);
					}

					return (
						<FieldAccessories key={column.payload.id} columnId={column.payload.id}>
							{({ title, icon, column: issueColumn }) => (
								<EnhancedCell
									column={index}
									columnId={column.payload.id}
									isDraggableColumn
									hasColumnDropIndicator
									top={50}
									hasBorderBottom
									resizeHandle={<ResizeHandle />}
									columnText={
										<ColumnText
											column={index}
											title={title}
											icon={icon}
											appearance="field"
											hasStripe={mode === OPTIMIZED}
											useColumnToggle={useColumnToggle}
										/>
									}
									rowType="subheader"
								>
									<FieldSubheader
										title={title}
										icon={icon}
										column={issueColumn}
										useColumnToggle={useColumnToggle}
										collapsible={viewMode === VIEW_MODES.TIMELINE}
									/>
								</EnhancedCell>
							)}
						</FieldAccessories>
					);
				})
			}
		</Row>
	);
};

const MemoedSubheaderRow = memo(SubheaderRow);

const withoutTopOffset = omit('topOffset');

const ItemRow: React.FC<
	{
		row: number;
		liteRow: LiteRowConfig | undefined;
		hasFields: boolean;
		viewMode: ViewMode;
		item: TableItem;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		row,
		columns,
		viewMode,
		mode,
		hasFields,
		liteRow,
		fieldColumns,
		showOptimizations,
		item,
		isReadOnly,
	}) => {
		const memoedRef = useRef(item);
		// Performance note:
		// The table item, or even the issue object (if any) in the item, is changed every time it's calculated at getTableItems()
		// This method is trying not the change the ref of the item when it's not necessary to avoid re-render for inner components
		memoedRef.current = isEqual(withoutTopOffset(item), withoutTopOffset(memoedRef.current))
			? memoedRef.current
			: item;

		return (
			<MemoedBodyRow
				{...{
					row,
					columns,
					viewMode,
					mode,
					hasFields,
					liteRow,
					fieldColumns,
					showOptimizations,
					isReadOnly,
				}}
				item={memoedRef.current}
			/>
		);
	},
);

const MemoedBodyRow: React.FC<
	{
		row: number;
		liteRow: LiteRowConfig | undefined;
		hasFields: boolean;
		viewMode: ViewMode;
		item: TableItem;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		row,
		columns,
		viewMode,
		mode,
		hasFields,
		liteRow,
		item,
		isReadOnly,
		fieldColumns,
		showOptimizations,
	}) =>
		fg('plans_performance_improvements_3') ? (
			<BodyRowWithoutBackgroundProvider
				index={row}
				item={item}
				showOptimizations={showOptimizations}
			>
				<BodyRowCells
					isFirst={row === 2}
					item={item}
					columns={columns}
					mode={mode}
					viewMode={viewMode}
					hasFields={hasFields}
					liteRow={liteRow}
					fieldColumns={fieldColumns}
					showOptimizations={showOptimizations}
					isReadOnly={isReadOnly}
				/>
			</BodyRowWithoutBackgroundProvider>
		) : (
			<BodyRowWithBackgroundProvider index={row} item={item}>
				<BodyRowCells
					isFirst={row === 2}
					item={item}
					columns={columns}
					mode={mode}
					viewMode={viewMode}
					hasFields={hasFields}
					liteRow={liteRow}
					fieldColumns={fieldColumns}
					showOptimizations={showOptimizations}
					isReadOnly={isReadOnly}
				/>
			</BodyRowWithBackgroundProvider>
		),
);

const WrappedItemProvider: React.FC<
	{
		row: number;
		itemIndex: number;
		liteRow: LiteRowConfig | undefined;
		hasFields: boolean;
		viewMode: ViewMode;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		row,
		columns,
		viewMode,
		mode,
		hasFields,
		liteRow,
		itemIndex,
		fieldColumns,
		showOptimizations,
		isReadOnly,
	}) => (
		<ItemProvider itemIndex={itemIndex}>
			{(item) => (
				<BodyRowWithBackgroundProvider index={row} item={item}>
					<BodyRowCells
						isFirst={row === 2}
						item={item}
						columns={columns}
						mode={mode}
						viewMode={viewMode}
						hasFields={hasFields}
						liteRow={liteRow}
						fieldColumns={fieldColumns}
						showOptimizations={showOptimizations}
						isReadOnly={isReadOnly}
					/>
				</BodyRowWithBackgroundProvider>
			)}
		</ItemProvider>
	),
);

const BodyRowWithBackgroundProvider: React.FC<
	PropsWithChildren<{
		index: number;
		item: TableItem;
	}>
> = memo(({ index: rowIndex, children, item }) => {
	const Row = fg('plans_performance_improvements_3') ? RowCompiled : RowPrimitive;

	switch (item?.tag) {
		case TABLE_GROUP:
			return <Row index={rowIndex}>{children}</Row>;

		case TABLE_INLINE_CREATE:
			return (
				<Row data-issue={INLINE_CREATE_ID} index={rowIndex}>
					{children}
				</Row>
			);

		case TABLE_GROUP_HEADER:
			return (
				<BackgroundProvider index={rowIndex}>
					{({ base }) => (
						<Row
							index={rowIndex}
							style={
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								{
									'--plan-grid-row-background': base,
								} as CSSProperties
							}
						>
							{children}
						</Row>
					)}
				</BackgroundProvider>
			);

		case TABLE_ISSUE:
			return (
				<BackgroundProvider issue={item.value}>
					{({ base, hover }) =>
						fg('plans_performance_improvements_3') ? (
							<RowCompiled
								index={rowIndex}
								style={
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									{
										'--plan-grid-row-background-base': base,
										'--plan-grid-row-background-hover': hover,
									} as CSSProperties
								}
								css={rowHoverCompiledStyles}
							>
								{children}
							</RowCompiled>
						) : (
							<RowPrimitive
								index={rowIndex}
								style={
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									{
										'--plan-grid-row-background-base': base,
										'--plan-grid-row-background-hover': hover,
									} as CSSProperties
								}
								xcss={rowHoverStyles}
							>
								{children}
							</RowPrimitive>
						)
					}
				</BackgroundProvider>
			);

		case TABLE_BLANK_ROW:
		default:
			return (
				<BackgroundProvider index={rowIndex}>
					{({ base }) => (
						<Row
							index={rowIndex}
							style={
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								{
									'--plan-grid-row-background': base,
								} as CSSProperties
							}
						>
							{children}
						</Row>
					)}
				</BackgroundProvider>
			);
	}
});

// Rename to BodyRow when cleaning up plans_performance_improvements_3
const BodyRowWithoutBackgroundProvider: React.FC<
	PropsWithChildren<{
		index: number;
		item: TableItem;
		showOptimizations?: boolean;
	}>
> = memo(({ index: rowIndex, children, item, showOptimizations }) => {
	const parentIndicator = useParentIndicator({
		id: item?.tag === TABLE_ISSUE ? item.value.id : undefined,
		index: rowIndex,
	});

	switch (item?.tag) {
		case TABLE_GROUP:
			return <RowCompiled index={rowIndex}>{children}</RowCompiled>;

		case TABLE_INLINE_CREATE:
			return (
				<RowCompiled data-issue={INLINE_CREATE_ID} index={rowIndex}>
					{children}
				</RowCompiled>
			);

		case TABLE_GROUP_HEADER: {
			const { base } = getBackgroundColor({
				isOptimized: showOptimizations,
			});
			return (
				<RowCompiled
					index={rowIndex}
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--plan-grid-row-background': base,
						} as CSSProperties
					}
				>
					{children}
				</RowCompiled>
			);
		}

		case TABLE_ISSUE: {
			const { base, hover } = getBackgroundColor({
				issue: item.value,
				isOptimized: showOptimizations,
				parentIndicator,
			});
			return (
				<RowCompiled
					index={rowIndex}
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--plan-grid-row-background-base': base,
							'--plan-grid-row-background-hover': hover,
						} as CSSProperties
					}
					css={rowHoverCompiledStyles}
				>
					{children}
				</RowCompiled>
			);
		}

		case TABLE_BLANK_ROW:
		default: {
			const { base } = getBackgroundColor({
				isOptimized: showOptimizations,
			});
			return (
				<RowCompiled
					index={rowIndex}
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							'--plan-grid-row-background': base,
						} as CSSProperties
					}
				>
					{children}
				</RowCompiled>
			);
		}
	}
});

// @Export for testing
export const BodyRowCells: React.FC<
	{
		item: TableItem;
		liteRow: LiteRowConfig | undefined;
		viewMode: ViewMode;
		hasFields: boolean;
		/** Determines whether the row is the first one in the table body. */
		isFirst: boolean;
		isReadOnly: boolean;
	} & Pick<Props, 'columns' | 'mode' | 'fieldColumns' | 'showOptimizations'>
> = memo(
	({
		item,
		viewMode,
		hasFields,
		isFirst,
		columns,
		mode,
		liteRow,
		isReadOnly,
		fieldColumns,
		showOptimizations,
	}) => {
		/**
		 * Optimisation for lite row:
		 * Remove indicators and only render the visible columns in lite row.
		 */
		const isLite = Boolean(liteRow);
		const isVisibleInLite = (column: number): boolean =>
			Boolean(liteRow?.visibleCols && liteRow.visibleCols[column.toString()]);

		const EnhancedCell = fg('plans_performance_improvements_3')
			? EnhancedCellCompiled
			: EnhancedCellPrimitive;

		switch (item?.tag) {
			case TABLE_GROUP:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								top={90}
								hasBorderTop={!isFirst /* Sub-header already has a bottom border. */}
								rowType="group"
							>
								{column.tag === SCOPE && (
									<div data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.scope">
										<Group item={item} />
									</div>
								)}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineTableGroup item={item} />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
								{!fg('plans_performance_improvements_3') &&
									column.tag !== SCOPE &&
									column.tag !== TIMELINE && <Group />}

								{column.tag !== SCOPE &&
									column.tag !== TIMELINE &&
									column.tag !== ADD_FIELDS &&
									column.tag !== EMPTY &&
									expValEquals('plan_timeline_aggregate_field_values', 'cohort', 'variation') && (
										<GroupHeaderField
											group={
												!expValEquals(
													'aggregate_fields_for_plan_m2_experiment',
													'cohort',
													'variation',
												)
													? item.group
													: undefined
											}
											item={
												expValEquals(
													'aggregate_fields_for_plan_m2_experiment',
													'cohort',
													'variation',
												)
													? item
													: undefined
											}
											columnId={column.payload.id}
										/>
									)}
							</EnhancedCell>
						))}
					</>
				);

			case TABLE_INLINE_CREATE:
				return fg('plan_timeline_inline_create_re-design') ? (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								rowType="inline-create"
							>
								{column.tag === SCOPE && <InlineCreatePopper issue={item.value} />}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				) : (
					<>
						{columns.map((column, index) => (
							<EnhancedCell column={index} key={index} hasDropIndicator={!isLite}>
								{column.tag === SCOPE && (
									<InlineCreate
										rootIndex={item.value.rootIndex}
										isReadOnly={false}
										fixVersions={item.value.fixVersions?.map((x) => Number(x))}
										components={item.value.components?.map((x) => Number(x))}
										getContainer={getBody}
									/>
								)}
								{column.tag === FIELD &&
									(showOptimizations !== undefined &&
									fieldColumns &&
									fg('plans_performance_improvements_3') ? (
										<FieldComponent
											issue={item.value}
											column={fieldColumns[column.payload.id]}
											isScrolling={isLite}
											isReadOnly={isReadOnly}
											showOptimizations={showOptimizations}
										/>
									) : (
										<Field issue={item.value} columnId={column.payload.id} isScrolling={isLite} />
									))}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				);

			case TABLE_GROUP_HEADER:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.group-header"
							>
								{column.tag === SCOPE && <GroupHeader item={item} readonly={mode !== EDIT} />}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				);

			case TABLE_ISSUE:
				return (
					<>
						{columns.map((column, index) => {
							if (column.tag === SCOPE) {
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
										testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.scope"
									>
										<Scope item={item} readonly={mode !== EDIT} isScrolling={isLite} />
									</EnhancedCell>
								);
							}

							if (column.tag === FIELD)
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
										data-name={`cell-${item.value.id}`}
										data-attribute={column.payload.id}
									>
										{(!isLite || isVisibleInLite(index)) &&
											(showOptimizations !== undefined &&
											fieldColumns &&
											fg('plans_performance_improvements_3') ? (
												<FieldComponent
													issue={item.value}
													column={fieldColumns[column.payload.id]}
													isScrolling={isLite}
													isReadOnly={isReadOnly}
													showOptimizations={showOptimizations}
												/>
											) : (
												<Field
													issue={item.value}
													columnId={column.payload.id}
													isScrolling={isLite}
												/>
											))}
									</EnhancedCell>
								);

							if (column.tag === ADD_FIELDS || column.tag === EMPTY) {
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
										hasBackground={hasFields || viewMode === VIEW_MODES.TIMELINE}
									/>
								);
							}

							if (column.tag === TIMELINE)
								return (
									<EnhancedCell
										column={index}
										key={index}
										hasDragIndicator={!isLite}
										hasDropIndicator={!isLite}
									>
										{fg('plan_timeline_arrows_transposition') && (
											<TimelineArrows issue={item.value} />
										)}
										<TimelineAligned offset={SCROLL_PADDING}>
											<TimelineAxis />
											<TimelineIssueRow issue={item.value} />
											<NowLine noMarker />
											<TimelineReleaseBar noMarker />
										</TimelineAligned>
									</EnhancedCell>
								);

							return null;
						})}
					</>
				);

			case TABLE_BLANK_ROW:
			default:
				return (
					<>
						{columns.map((column, index) => (
							<EnhancedCell
								column={index}
								key={index}
								hasDropIndicator={!isLite}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.empty"
							>
								{column.tag === SCOPE && <OrphanDropTarget />}
								{column.tag === TIMELINE && (
									<TimelineAligned offset={SCROLL_PADDING}>
										<TimelineAxis />
										<NowLine noMarker />
										<TimelineReleaseBar noMarker />
									</TimelineAligned>
								)}
							</EnhancedCell>
						))}
					</>
				);
		}
	},
);

const rowHoverStyles = xcss({
	'--plan-grid-row-background': 'var(--plan-grid-row-background-base)',
	':hover': {
		// @ts-expect-error - TS2353: Object literal may only specify known properties
		'--plan-grid-row-background':
			'var(--plan-grid-row-background-hover, var(--plan-grid-row-background-base))',
	},
});

const rowHoverCompiledStyles = css({
	'--plan-grid-row-background': 'var(--plan-grid-row-background-base)',
	'&:hover': {
		'--plan-grid-row-background':
			'var(--plan-grid-row-background-hover, var(--plan-grid-row-background-base))',
	},
});
