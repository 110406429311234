/* eslint-disable jira/react/no-style-attribute */
import React, { type CSSProperties, type MouseEventHandler, useCallback } from 'react';
import { Box, xcss, type XCSS } from '@atlaskit/primitives';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCell } from '../../controllers/cell/index.tsx';
import { useScrollX } from '../../controllers/container/index.tsx';
import { useGridActions, useResizerVisible } from '../../controllers/grid/index.tsx';
import { useResize } from '../../controllers/resize/index.tsx';

interface Props {
	xcss?: XCSS | Array<XCSS | false | undefined>;
	style?: CSSProperties;
	testId?: string;
}

export const ResizeHandle = ({ style, xcss: xcssStyles, testId }: Props) => {
	const [resizePayload, { hover, activate }] = useResize();
	const [{ column, colSpan }] = useCell();
	const [scrollX] = useScrollX();
	const [visible] = useResizerVisible({ column, scrollX, preview: true });
	const columnToResize = column + (colSpan - 1);
	const { resize } = useGridActions();
	const hovered = resizePayload.column === columnToResize && resizePayload.hovered;
	const active = resizePayload.column === columnToResize && resizePayload.active;

	const handleMouseMove: MouseEventHandler = useCallback(() => {
		if (!hovered) {
			hover(columnToResize, true);
		}
	}, [columnToResize, hover, hovered]);

	const handleMouseLeave: MouseEventHandler = useCallback(() => {
		hover(columnToResize, false);
	}, [columnToResize, hover]);

	const handleMouseDown: MouseEventHandler = useCallback(
		(e) => {
			e.preventDefault(); // Such as drag behavior being handled on ancestors
			activate(columnToResize, true);

			/** The blanket element to ensure that:
			 *
			 * - the mouse cursor can't interact with other elements while dragging, and
			 * - the mouse cursor is always ew-resize while dragging regardless the handle position.
			 * */

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const blanketEl = document.createElement('div');

			blanketEl.style.zIndex = String(layers.blanket);
			blanketEl.style.position = 'fixed';
			blanketEl.style.inset = '0px';
			blanketEl.style.cursor = 'ew-resize';

			let delta = 0;
			const anchorOffset = e.clientX;

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const handleMouseMove = (e: MouseEvent) => {
				delta = e.clientX - anchorOffset;
				resize(columnToResize, delta);
			};

			const handleMouseUp = () => {
				activate(columnToResize, false);
				resize(columnToResize, delta, true);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.body.removeChild(blanketEl);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('mousemove', handleMouseMove);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('mouseup', handleMouseUp);
			};

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.appendChild(blanketEl);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousemove', handleMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mouseup', handleMouseUp);
		},
		[activate, columnToResize, resize],
	);

	return (
		<Box
			role="separator"
			aria-orientation={fg('plan-timeline-a11y-rholloway') ? 'vertical' : 'horizontal'}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			onMouseDown={handleMouseDown}
			xcss={
				fg('plan_timeline_drag_and_drop_field_columns')
					? [
							handleStyles,
							(hovered || active) && activeStyles,
							visible && visibleStyles,
							...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles]),
						]
					: [
							handleStyles,
							(hovered || active) && activeStyles,
							visible && visibleStyles,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/design-system/consistent-css-prop-usage
							xcssStyles as XCSS,
						]
			}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
			testId={testId}
		/>
	);
};

const handleStyles = xcss({
	width: '2px',
	position: 'relative',
	cursor: 'ew-resize',
	visibility: 'hidden',

	'::before': {
		content: '""',
		position: 'absolute',
		inset: 'space.0',
		left: 'space.negative.050',
		right: 'space.negative.050',
	},
});

const activeStyles = xcss({
	backgroundColor: 'color.background.selected.bold',
});

const visibleStyles = xcss({
	visibility: 'visible',
});
