import React from 'react';
import type { Props } from './types.tsx';

export const Field = ({ issue, column, isReadOnly, isScrolling, showOptimizations }: Props) => {
	const { cell: Cell } = column;
	return (
		<Cell
			issue={issue}
			attribute={column.attribute}
			isReadOnly={(isReadOnly || column.isReadOnly) ?? false}
			showOptimizations={showOptimizations}
			isScrolling={isScrolling}
		/>
	);
};
