import React, { type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import type { Store } from 'redux';
import { styled } from '@compiled/react';
import { PersistGate } from 'redux-persist/integration/react';
import { token } from '@atlaskit/tokens';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { isPlanEmbed } from '@atlassian/jira-portfolio-3-common/src/embed/index.tsx';
import { isPollinatorTenant } from '@atlassian/jira-portfolio-3-common/src/feature-flags/index.tsx';
import defaultStore, {
	persistor,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/store.tsx';
import BridgeEventSubscriber from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/bridge-subscriber/index.tsx';
import PortfolioErrorBoundary from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/error-boundary.tsx';
import PortfolioErrorNotification from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/error-notification/index.tsx';
import ExperienceTracker from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/experience-tracker/index.tsx';
import ReduxStoreLifecycle from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/redux-store-lifecycle/index.tsx';
import type { InjectedProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/experience-tracking/with-view-experience-tracker/index.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsPlanReportPageInitialChecksResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { JIRA_PORTFOLIO } from '@atlassian/jira-shared-types/src/application.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { RedirectToLoginPage } from '@atlassian/jira-spa-apps-common/src/redirect/login/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useRouter, useResource } from '@atlassian/react-resource-router';
import { expVal } from '@atlassian/jira-feature-experiments';

import { ErrorScreen } from '../../../ui/error-screens/index.tsx';
import { AnalyticsInitializer } from './analytics-initializer/index.tsx';
import { usePlanCheck } from './utils.tsx';

interface Props {
	/** Passes the id of the app */
	appKey?: string;

	/** Provides the app with the default ARJ Redux store. */
	withReduxStore?: boolean;

	/** Provides the default left padding to the container. */
	paddingLeft?: boolean;

	/** Provides the default right padding to the container. */
	paddingRight?: boolean;

	children?: ReactNode | ReactNode[];

	/** Provides a custom Redux store. */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store?: Store<any>;
}

export const ReportAppBase = ({
	withReduxStore,
	paddingLeft = true,
	paddingRight = true,
	children,
	store = defaultStore,
}: Props & InjectedProps) => {
	const locale: Locale = useLocale();
	const editions = useAppEditions();

	const { data, loading, error } = useResource(advancedRoadmapsPlanReportPageInitialChecksResource);

	// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)'.
	if (error?.statusCode === 401) {
		return <RedirectToLoginPage />;
	}

	if (loading || data === null) {
		return <Spinner />;
	}

	if (!data.success) {
		return <ErrorScreen errorMessage={data.errorMessage} planId={data.planId} />;
	}

	return (
		<JSErrorBoundary
			id="spa-app.advanced-roadmaps-plan.report"
			packageName="jiraSpaAppsAdvancedRoadmaps"
			teamName="advanced-roadmaps"
		>
			<MarkProductStart />
			<ViewExperienceTrackingProvider
				experience="viewPortfolio3"
				analyticsSource="portfolio3"
				application={JIRA_PORTFOLIO}
				edition={editions.software}
				additionalAttributes={{
					synthetic: isPollinatorTenant(),
				}}
			>
				<SubProductUpdater subProduct="software" />
				<AppBase store={withReduxStore ? store : undefined} hasErrorBoundary={false}>
					<AnalyticsInitializer>
						<PortfolioErrorBoundary>
							{/* aks my rules */}
							{/* eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage */}
							{document.body && createPortal(<PortfolioErrorNotification />, document.body)}
							<PersistGate loading={null} persistor={persistor}>
								<ReduxStoreLifecycle locale={locale} />
								{getWillShowNav4() ? (
									<Container
										paddingLeft={paddingLeft ? token('space.300') : undefined}
										paddingRight={paddingRight ? token('space.300') : undefined}
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="arj"
									>
										{children}
									</Container>
								) : (
									<ContainerOld
										paddingLeft={paddingLeft}
										paddingRight={paddingRight}
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="arj"
									>
										{children}
									</ContainerOld>
								)}
								<SpaStatePageReady />
							</PersistGate>
							<ExperienceTracker />
						</PortfolioErrorBoundary>
					</AnalyticsInitializer>
				</AppBase>
			</ViewExperienceTrackingProvider>
		</JSErrorBoundary>
	);
};

export const PlanAppBase = ({
	appKey = 'spa-app.advanced-roadmaps-plan.default',
	withReduxStore,
	paddingLeft = true,
	paddingRight = true,
	children,
	store = defaultStore,
}: Props & InjectedProps) => {
	const locale: Locale = useLocale();
	const editions = useAppEditions();
	const { data, loading, error, refresh } = usePlanCheck();
	const [{ match }] = useRouter();

	// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)'.
	if (error?.statusCode === 401) {
		return <RedirectToLoginPage />;
	}

	/* This is to allow the plan to function when strict mode for local dev is on, it should only affect local dev.
	 *  The root cause of the plan failing to load with strict mode on is the clear(); useEffect in
	 *  jira/src/packages/platform/ui/layouts/jira-plan-layout/src/ui/resources-invalidate/utils.tsx
	 *  This is because strict mode causes components to mount -> unmount -> mount
	 *  and this useEffect doesn't have code to refresh the Router Resources, only to clear them
	 */
	if (!loading && data === null && fg('plans-fix-strict-mode-loading')) {
		refresh();
	}

	if (loading || data === null) {
		return <Spinner />;
	}

	if (!data.success) {
		const planId = Number.isNaN(match.params.planId) ? undefined : Number(match.params.planId);
		return <ErrorScreen errorMessage={data.errorMessage} planId={planId} />;
	}

	return (
		<JSErrorBoundary
			id={appKey}
			packageName="jiraSpaAppsAdvancedRoadmaps"
			teamName="advanced-roadmaps"
		>
			<MarkProductStart />
			<ViewExperienceTrackingProvider
				experience="viewPortfolio3"
				analyticsSource="portfolio3"
				application={JIRA_PORTFOLIO}
				edition={editions.software}
				additionalAttributes={{
					synthetic: isPollinatorTenant(),
					...(fg('smart_links_for_plans') ? { isEmbed: isPlanEmbed() } : {}),
				}}
			>
				<SubProductUpdater subProduct="software" />
				<AppBase store={withReduxStore ? store : undefined} hasErrorBoundary={false}>
					<AnalyticsInitializer>
						<PortfolioErrorBoundary>
							{/* aks my rules */}
							{/* eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage */}
							{document.body && createPortal(<PortfolioErrorNotification />, document.body)}
							<PersistGate loading={null} persistor={persistor}>
								<ReduxStoreLifecycle locale={locale} />
								{getWillShowNav4() ? (
									<Container
										paddingLeft={paddingLeft ? token('space.300') : undefined}
										paddingRight={paddingRight ? token('space.300') : undefined}
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="arj"
									>
										{children}
									</Container>
								) : (
									<ContainerOld
										paddingLeft={paddingLeft}
										paddingRight={paddingRight}
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="arj"
									>
										{children}
									</ContainerOld>
								)}
								<SpaStatePageReady />
							</PersistGate>
							<ExperienceTracker />
							<BridgeEventSubscriber />
						</PortfolioErrorBoundary>
					</AnalyticsInitializer>
				</AppBase>
			</ViewExperienceTrackingProvider>
		</JSErrorBoundary>
	);
};

export const SettingsAppBase = ({
	children,
	appKey = 'spa-app.advanced-roadmaps-plan.settings',
	paddingLeft = true,
	paddingRight = true,
	store = defaultStore,
}: Props) => {
	const locale: Locale = useLocale();
	const editions = useAppEditions();
	const [{ match }] = useRouter();

	const { data, loading, error } = useResource(advancedRoadmapsPlanPageInitialChecksResource);

	// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)'.
	if (error?.statusCode === 401) {
		return <RedirectToLoginPage />;
	}

	if (loading || data === null) {
		return <Spinner />;
	}

	if (!data.success) {
		const planId = Number.isNaN(match.params.planId) ? undefined : Number(match.params.planId);
		return <ErrorScreen errorMessage={data.errorMessage} planId={planId} />;
	}
	return (
		<JSErrorBoundary
			id={appKey}
			packageName="jiraSpaAppsAdvancedRoadmaps"
			teamName="advanced-roadmaps"
		>
			<MarkProductStart />
			<ViewExperienceTrackingProvider
				experience="viewPortfolio3PlanSettings"
				analyticsSource="portfolio3"
				application={JIRA_PORTFOLIO}
				edition={editions.software}
				additionalAttributes={{
					synthetic: isPollinatorTenant(),
				}}
			>
				<SubProductUpdater subProduct="software" />
				<AppBase store={store} hasErrorBoundary={false}>
					<AnalyticsInitializer>
						<PortfolioErrorBoundary>
							{/* aks my rules */}
							{/* eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage */}
							{document.body && createPortal(<PortfolioErrorNotification />, document.body)}
							<PersistGate loading={null} persistor={persistor}>
								<ReduxStoreLifecycle locale={locale} />
								{getWillShowNav4() ? (
									<Container
										paddingLeft={paddingLeft ? token('space.300') : undefined}
										paddingRight={paddingRight ? token('space.300') : undefined}
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="arj"
									>
										{children}
									</Container>
								) : (
									<ContainerOld
										paddingLeft
										paddingRight
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="arj"
									>
										{children}
									</ContainerOld>
								)}
								<SpaStatePageReady />
							</PersistGate>
							<ExperienceTracker />
							{expVal('m1_features_page_experiment', 'cohort', 'not-enrolled') === 'variation' ? (
								<BridgeEventSubscriber />
							) : null}
						</PortfolioErrorBoundary>
					</AnalyticsInitializer>
				</AppBase>
			</ViewExperienceTrackingProvider>
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ paddingLeft: string | undefined; paddingRight: string | undefined }>(
	{
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingLeft: ({ paddingLeft }) => paddingLeft,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingRight: ({ paddingRight }) => paddingRight,
		height: '100%',
		overflowY: 'auto',
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerOld = styled.div<{ paddingLeft: boolean; paddingRight: boolean }>({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ paddingLeft }) => (paddingLeft ? token('space.500') : undefined),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ paddingRight }) => (paddingRight ? token('space.300') : undefined),
	height: '100%',
});
