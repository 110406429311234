/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Container } from '@atlassian/jira-portfolio-3-treegrid/src/ui/container-compiled/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';

export const EnhancedContainer: typeof Container = ({ className, ...restProps }) => {
	const [
		{
			match: { params },
		},
	] = useRouter();
	const { planId, scenarioId } = params;

	return (
		<Container
			scope={`${planId}-${scenarioId}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={className}
			css={containerStyles}
			{...restProps}
		/>
	);
};

const containerStyles = css({
	position: 'absolute',
	inset: '0',
	zIndex: 0,
});
