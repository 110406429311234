import type { Effect } from 'redux-saga';
import isEqual from 'lodash/isEqual';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import {
	fetchMissingAssociatedIssuesFail,
	fetchMissingAssociatedIssuesStart,
	fetchMissingAssociatedIssuesSuccess,
} from '../../state/domain/associated-issues/actions.tsx';
import { addFlag } from '../../state/domain/flags/actions.tsx';
import {
	MISSING_ASSOCIATED_ISSUES_FETCH_LIMIT_ERROR,
	MISSING_ASSOCIATED_ISSUES_GENERAL_ERROR,
} from '../../state/domain/flags/types.tsx';
import {
	getAssociatedIssues,
	getLastAssociatedIssueIds,
} from '../../query/associated-issues/index.tsx';
import type { AssociatedIssue } from '../../state/domain/associated-issues/types.tsx';
import { fetchAssociatedIssuesById, type AssociatedIssuesFetchResult } from './api/index.tsx';
import { getIssueIdsToFetch } from './api/utils.tsx';

export const FETCH_MISSING_ASSOCIATED_ISSUES =
	'command.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES' as const;

export type FetchMissingAssociatedIssuesPayload = {
	associatedIssueIds: string[];
	cloudId: string;
};

type FetchMissingAssociatedIssuesCommand = {
	type: typeof FETCH_MISSING_ASSOCIATED_ISSUES;
	payload: FetchMissingAssociatedIssuesPayload;
};

export const fetchMissingAssociatedIssues = (
	payload: FetchMissingAssociatedIssuesPayload,
): FetchMissingAssociatedIssuesCommand => ({
	type: FETCH_MISSING_ASSOCIATED_ISSUES,
	payload,
});

export function* doFetchMissingAssociatedIssues({
	payload,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: FetchMissingAssociatedIssuesCommand): Generator<Effect, any, any> {
	if (payload.associatedIssueIds.length === 0) {
		return;
	}
	const lastAssociatedIssueIds: string[] = yield select(getLastAssociatedIssueIds);
	if (isEqual(lastAssociatedIssueIds, payload.associatedIssueIds)) {
		return;
	}
	const associatedIssues: Record<string, AssociatedIssue> = yield select(getAssociatedIssues);
	const issueIdsToFetch = getIssueIdsToFetch(payload.associatedIssueIds, associatedIssues);
	if (issueIdsToFetch.length === 0) {
		return;
	}
	yield put(fetchMissingAssociatedIssuesStart());
	let isError = false;
	let fetchResultAssociatedIssues: AssociatedIssuesFetchResult['associatedIssues'] = {};
	try {
		const fetchResult: AssociatedIssuesFetchResult = yield call(
			fetchAssociatedIssuesById,
			issueIdsToFetch,
			payload.cloudId,
		);
		if (fetchResult.appliedLimit) {
			isError = true;
			yield put(addFlag({ key: MISSING_ASSOCIATED_ISSUES_FETCH_LIMIT_ERROR }));
			fireErrorAnalytics({
				meta: {
					packageName: 'jiraPortfolio3AssociatedIssues',
					id: 'fetchAssociatedIssuesLimitExceeded',
					teamName: 'jpd-juno',
				},
				attributes: { requestedIssues: issueIdsToFetch.length },
				error: new Error('Ideas fetch limit exceeded'),
				sendToPrivacyUnsafeSplunk: false,
			});
		} else {
			fetchResultAssociatedIssues = fetchResult.associatedIssues;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		isError = true;
		yield put(addFlag({ key: MISSING_ASSOCIATED_ISSUES_GENERAL_ERROR }));
		fireErrorAnalytics({
			meta: {
				packageName: 'jiraPortfolio3AssociatedIssues',
				id: 'fetchAssociatedIssues',
				teamName: 'jpd-juno',
			},
			error,
			sendToPrivacyUnsafeSplunk: false,
		});
	}

	if (isError) {
		yield put(fetchMissingAssociatedIssuesFail());
	} else {
		yield put(
			fetchMissingAssociatedIssuesSuccess({
				associatedIssues: fetchResultAssociatedIssues,
				lastAssociatedIssueIds: issueIdsToFetch,
			}),
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* watchFetchMissingAssociatedIssues(): Generator<Effect, any, any> {
	yield takeEvery(FETCH_MISSING_ASSOCIATED_ISSUES, doFetchMissingAssociatedIssues);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* root(): Generator<Effect, any, any> {
	yield fork(watchFetchMissingAssociatedIssues);
}
