import React, { type ReactNode, useCallback, useMemo, useState } from 'react';
import type noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	getPlanId,
	getScenarioId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/urls.tsx';
import TeamDialog from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/teams/list/team-dialog/index.tsx';
import type { TeamDialogCopyOverrides } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/teams/list/team-dialog/types.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const MODAL_COPY: Record<string, TeamDialogCopyOverrides> = {
	Kanban: {
		teamDialogHeading: messages.kanbanHeader,
		teamDialogSubheading: messages.kanbanSubheading,
		teamDialogBody: messages.kanbanBodyCopy,
	},
	Scrum: {
		teamDialogHeading: messages.scrumHeader,
		teamDialogSubheading: messages.scrumSubheading,
		teamDialogBody: messages.scrumBodyCopy,
	},
};

const MODAL_COPY_ISSUE_TERM_REFRESH: Record<string, TeamDialogCopyOverrides> = {
	Kanban: {
		teamDialogHeading: messages.kanbanHeader,
		teamDialogSubheading: messages.kanbanSubheadingIssueTermRefresh,
		teamDialogBody: messages.kanbanBodyCopyIssueTermRefresh,
	},
	Scrum: {
		teamDialogHeading: messages.scrumHeader,
		teamDialogSubheading: messages.scrumSubheading,
		teamDialogBody: messages.scrumBodyCopyIssueTermRefresh,
	},
};
/**
 * EditTeamModal component.
 *
 * @remarks
 * The aim of this component is to provide access to the configuration of a team without leaving the timeline.
 * It was implemented in the context of sprint capacity ease of use improvements. It allows to resolve the main blockers:
 * - Team needs to be added to the plan
 * - Team needs to have an issue source assigned
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered EditTeamModal component.
 */
export default function EditTeamModal({
	openTeamsDialog,
	onAddAtlassianTeam,
	closeTeamsDialog,
	deleteTeam,
	isDialogOpen,
	additionalTeamsById,
	team,
	teamId,
	trigger,
	editedTeam,
	mode,
}: Props) {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	/**
	 * Memoized additional team based on the team ID.
	 * Additional teams are teams that are not part of the current plan.
	 *
	 * @param additionalTeamsById - The object containing additional teams indexed by ID.
	 * @param teamId - The ID of the team.
	 * @returns The additional team corresponding to the team ID.
	 */
	const additionalTeam = useMemo(() => additionalTeamsById[teamId], [additionalTeamsById, teamId]);

	/**
	 * Capture if we should revert the team change. This will be used to determine if we should revert the team change
	 * on dialog close.
	 */
	const [cancellable, setCancellable] = useState(false);

	/**
	 * Opens the team modal for editing. Used when the team already exists.
	 */
	const openModal = useCallback(() => {
		if (!team) {
			return;
		}
		openTeamsDialog({
			teamId: team.id,
			title: team.title,
			recommendedSchedulingMode: mode,
		});
	}, [mode, openTeamsDialog, team]);

	/**
	 * Adds a team to the schedule, then opens the team dialog.
	 *
	 * @remarks
	 * This function is used to add a team to the schedule.
	 * If the team is an Atlassian team, it calls the `onAddAtlassianTeam` function with the team's external ID and title.
	 *
	 * @returns void
	 */
	const addTeam = useCallback(async () => {
		const { externalId, title } = additionalTeam;

		setCancellable(true);

		// Atlassian team exist
		if (externalId) {
			onAddAtlassianTeam({
				id: externalId,
				title,
			});
			openTeamsDialog({
				teamId,
				title,
				recommendedSchedulingMode: mode,
			});
		}
	}, [additionalTeam, mode, onAddAtlassianTeam, openTeamsDialog, teamId]);

	/**
	 * Handles the click event for the team edit button.
	 * If a team is already selected, it opens the modal.
	 * If no team is selected, it adds a new team.
	 */
	const handleClickModal = useCallback(() => {
		if (team) {
			openModal();
		} else {
			addTeam();
		}
	}, [addTeam, openModal, team]);

	/**
	 * Handles the cancel event for the team dialog.
	 * If the dialog is cancellable, it deletes the team.
	 */
	const onCancel = useCallback(() => {
		closeTeamsDialog();

		if (cancellable) {
			deleteTeam({
				id: teamId,
			});
			setCancellable(false);
		}
	}, [cancellable, closeTeamsDialog, deleteTeam, teamId]);

	const shouldRenderModal = useMemo(
		() => isDialogOpen && teamId === editedTeam?.teamId,
		[editedTeam?.teamId, isDialogOpen, teamId],
	);

	const redirectToIssueSources = useCallback(() => {
		const url = `/jira/plans/${getPlanId()}/scenarios/${getScenarioId()}/settings/issue-sources`;
		push(url);
	}, [push]);

	const isExternalPlanOnlyTeam = !team && !additionalTeam?.externalId;

	return (
		<Flex alignItems="center">
			{/*
				Legacy button added waiting for the new button link to be available in the design system
			*/}

			{!isExternalPlanOnlyTeam
				? formatMessage(trigger, {
						modal: (chunks: ReactNode[]) => renderModalTrigger(chunks, handleClickModal),
						link: (chunks: ReactNode[]) => renderLink(chunks, redirectToIssueSources),
					})
				: null}

			{shouldRenderModal && (
				<TeamDialog
					onClose={onCancel}
					{...(mode &&
						(fg('jira-issue-terminology-refresh-m3')
							? MODAL_COPY_ISSUE_TERM_REFRESH[mode]
							: MODAL_COPY[mode]))}
					recommendedSchedulingMode={mode}
				/>
			)}
		</Flex>
	);
}

export const renderModalTrigger = (chunks: ReactNode[], handleClickModal: typeof noop) => (
	<Button aria-haspopup="dialog" appearance="link" spacing="compact" onClick={handleClickModal}>
		{chunks}
	</Button>
);

export const renderLink = (chunks: ReactNode[], redirectToIssueSources: typeof noop) => (
	<Button
		as="a"
		appearance="link"
		onClick={redirectToIssueSources}
		href={`/jira/plans/${getPlanId()}/scenarios/${getScenarioId()}/settings/issue-sources`}
	>
		{chunks}
	</Button>
);
