import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import { type Grouping, CustomFields, GROUPING } from '../constant.tsx';
import { isRoadmapGroupedByCustomField } from '../custom-fields/index.tsx';
import commonMessages from '../messages.tsx';

const isGroupableField = (customFieldKey: string) =>
	CustomFields.GroupableSingleValueFields.includes(customFieldKey) ||
	CustomFields.GroupableMultiValueFields.includes(customFieldKey);

export const standardFieldsGroupings = (
	isAtlasConnectInstalled: boolean,
	isConfluenceMacroProxyMode: boolean,
	isFocusConnectedWithReadPermissions: boolean,
): { [id: string]: MessageDescriptor } => ({
	[GROUPING.NONE]: commonMessages.none,
	[GROUPING.ASSIGNEE]: commonMessages.assignee,
	[GROUPING.REPORTER]: commonMessages.reporter,
	[GROUPING.COMPONENT]: commonMessages.component,
	...(isFocusConnectedWithReadPermissions &&
		fg('focus_area_in_plans') && {
			[GROUPING.FOCUS_AREA]: commonMessages.focusArea,
		}),
	...(isAtlasConnectInstalled && {
		[GROUPING.GOALS]: commonMessages.goals,
	}),
	...(!isConfluenceMacroProxyMode &&
		fg('polaris-arj-eap-override') && {
			[GROUPING.IDEAS]: commonMessages.ideas,
		}),
	[GROUPING.LABEL]: commonMessages.label,
	[GROUPING.PROJECT]: commonMessages.project,
	[GROUPING.RELEASE]: commonMessages.releases,
	[GROUPING.SPRINT]: commonMessages.sprint,
	[GROUPING.TEAM]: commonMessages.team,
});

export const getGroupingCustomFields = (
	customFields: CustomField[],
): { [id: string]: { title: string; type: string } } =>
	customFields.reduce((acc: { [id: string]: { title: string; type: string } }, customField) => {
		if (isGroupableField(customField.type.key)) {
			acc[`customfield_${customField.id}`] = {
				title: customField.title,
				type: customField.type.key,
			};
		}
		return acc;
	}, {});

export const getGroupByStandardFieldLabel = (
	grouping: string,
	isConfluenceMacroProxyMode: boolean,
	focusConnectedWithReadPermissions: boolean,
) =>
	standardFieldsGroupings(true, isConfluenceMacroProxyMode, focusConnectedWithReadPermissions)[
		grouping
	];

export const getGroupByCustomFieldLabel = (grouping: string, customFields: CustomField[]) =>
	getGroupingCustomFields(customFields)[grouping]?.title;

export const getGroupForAnalyticEvent = (grouping: string, customFields: CustomField[]) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (isRoadmapGroupedByCustomField(grouping as Grouping)) {
		return getGroupingCustomFields(customFields)[grouping]?.type;
	}
	return grouping;
};
