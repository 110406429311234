import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import xorWith from 'lodash/xorWith';
import * as R from 'ramda';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	EPIC_LEVEL,
	STORY_LEVEL,
	SUB_TASK_LEVEL,
	INITIATIVE_LEVEL,
	STRATEGY_LEVEL,
	GOAL_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type {
	IssueLink,
	Issue as ApiIssue,
	CustomFieldValue,
	IssueLinksData,
	IssueValues,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	indexBy,
	filterMap,
	isDefined,
	mapGroupsToIds,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
	SCENARIO_ISSUE_ID_PREFIX,
	CustomFieldTypes,
	PlanningUnits,
	type ScenarioType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

import type {
	Warning,
	Warnings,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { getPlanSize as calcPlanSize } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/get-plan-size.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { OriginalIssues } from '../../state/domain/original-issues/types.tsx';
import type { PlanInfo } from '../../state/domain/plan/types.tsx';
import type {
	ApiScenarioType,
	ChangeMetadata,
	EntityMetadata,
} from '../../state/domain/update-jira/changes/types.tsx';
import type { Version } from '../../state/domain/versions/types.tsx';
import {
	HIERARCHY_RANGE_FILTER_ID,
	type HierarchyFilterValue,
	type HierarchyRangeFilter,
	type ResolvedFilters,
} from '../../state/domain/view-settings/filters/types.tsx';
import type { State } from '../../state/types.tsx';
import { isOptimizedMode } from '../app/index.tsx';
import {
	getCustomFields,
	getCustomFieldById,
	getCustomFieldsByKey,
} from '../custom-fields/index.tsx';
import {
	applyOption as applyShowFullHierarchyOption,
	getShowFullHierarchy,
} from '../filter-options/show-full-hierarchy/index.tsx';
import {
	getFilters,
	getUnmatchedFiltersList,
	matchFilter,
	type MatchFilterOptions,
} from '../filters/index.tsx';
import { getHierarchyRange, type HierarchyRange } from '../hierarchy/index.tsx';
import {
	getIncomingLinks,
	getIncomingLinkOriginals,
	getOutgoingLinks,
	getOutgoingLinkOriginals,
	getIssueLinkChangesData,
	getIssueLinkChangesMetaData,
	type IssueLinksDataMap,
	type IssueLinksByIssueId,
} from '../issue-links/index.tsx';
import { getPlan } from '../plan/index.tsx';
import { getAllIssues, getIssues, getIssueMapById } from '../raw-issues/index.tsx';
import {
	getDescendants,
	getChildrenIdsByParent,
	getDescendantsByParent,
} from '../raw-issues/issues-tree.tsx';
import { getSort, type SortIssues } from '../raw-issues/sort-utils.tsx';
import { getVersions, getProjectIdsByCrossProjectVersionsMap } from '../versions/index.tsx';
import {
	applyHierarchyRangeFilter,
	getHierarchyRangeFilter,
} from '../filters/hierarchy-range-filter/index.tsx';
import type {
	IssuesByVersionMap,
	IssueChange,
	DescendantsMap,
	IssueMap,
	HierarchyValue,
	SimplifiedIssueValues,
} from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Issue } from '../../state/domain/issues/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { OriginalIssues } from '../../state/domain/original-issues/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	getAllIssues,
	getIssues,
	getIssueMapById,
	createIssueMapByIdPure,
	getScenarioRemovedIssueMapById,
} from '../raw-issues';

export const getAllSortedIssuesPure = (issues: Issue[], sort: SortIssues): Issue[] => sort(issues);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	getRollupMap,
	getChildrenByParent,
	getDescendants,
	getChildrenIdsByParent,
	getDescendantsByParent,
	getChildrenByParentPure,
	getDescendantsByParentPure,
} from '../raw-issues/issues-tree';

export const getAllSortedIssues = createSelector([getAllIssues, getSort], getAllSortedIssuesPure);

// Whether initial set of issues is loading (set to true in initial state)
export const getIsIssuesLoading = (state: State): boolean =>
	state.ui.Main.Tabs.Roadmap.Scope.Issues.isLoading;

export const getIssuesByLevel = createSelector(
	[getIssues, (state: State, targetLevel: number) => targetLevel],
	(issues, targetLevel) => issues.filter(({ level }) => level === targetLevel),
);

export const getOriginalIssues = (state: State): OriginalIssues => state.domain.originalIssues;

export const getAncestors = (
	issue: Issue,
	issueMap: IssueMap,
	maxAncestorLevel: number,
): Issue[] => {
	const ancestorsIds: Array<string> = [];
	const ancestors: Issue[] = [];

	let currentIssue = issue;
	while (currentIssue && currentIssue.level < maxAncestorLevel && currentIssue.parent) {
		currentIssue = issueMap[currentIssue.parent];
		// It is entirely possible for an issue to have a parent, but that parent issue to NOT be in the plan
		if (!currentIssue) {
			break;
		}
		if (ancestorsIds.includes(currentIssue.id)) {
			// prevent getting into infinite loop
			break;
		}
		ancestorsIds.push(currentIssue.id);

		ancestors.push(currentIssue);
	}
	return ancestors;
};

export const getDescendantIdsByParent = createSelector([getDescendantsByParent], mapGroupsToIds);

export const getIssueFilterMatcherPure =
	(filters: ResolvedFilters, optimizedMode: boolean, customFields: CustomField[]) =>
	(issue: Issue, options?: MatchFilterOptions) => {
		return matchFilter(filters, optimizedMode, customFields, issue, {
			...options,
			exclude: options?.exclude,
		});
	};

export const getIssueFilterMatcher = createSelector(
	[getFilters, isOptimizedMode, getCustomFields],
	getIssueFilterMatcherPure,
);

export const getFilteredIssuesPure = (
	issues: Issue[],
	issueFilterMatcher: (arg1: Issue) => boolean,
): Issue[] => {
	const start = performance.now();
	const filtered = R.filter(issueFilterMatcher)(issues);
	if (issues.length > 0 && fg('plans_action_timing_metrics')) {
		addSpanToAll(
			'custom',
			'getFilteredIssuesPure',
			[{ name: 'redux' }, { name: 'query' }],
			start,
			performance.now(),
		);
	}
	return filtered;
};

export const getIssuesGroupedByHierarchyLevelPure = (
	issues: Issue[],
): {
	subtaskLevel?: Issue[];
	storyLevel?: Issue[];
	epicLevel?: Issue[];
	initiativeLevel?: Issue[];
	strategyLevel?: Issue[];
	goalLevel?: Issue[];
	levelAboveGoalLevel?: Issue[];
} => {
	if (issues.length > 0 && fg('focus_area_in_plans')) {
		return groupBy(issues, ({ level }: Issue) => {
			if (level === SUB_TASK_LEVEL) {
				return 'subtaskLevel';
			}
			if (level === STORY_LEVEL) {
				return 'storyLevel';
			}
			if (level === EPIC_LEVEL) {
				return 'epicLevel';
			}
			if (level === INITIATIVE_LEVEL) {
				return 'initiativeLevel';
			}
			if (level === STRATEGY_LEVEL) {
				return 'strategyLevel';
			}
			if (level === GOAL_LEVEL) {
				return 'goalLevel';
			}
			return 'levelAboveGoalLevel';
		});
	}
	return {};
};

export const getIssuesGroupedByHierarchyLevel = createSelector(
	[getIssues],
	getIssuesGroupedByHierarchyLevelPure,
);

export const getHierarchyFilteredIssues = createSelector(
	[getIssues, getHierarchyRangeFilter],
	(issues: Issue[], hierarchyFilter: HierarchyRangeFilter) =>
		issues.filter((issue) => applyHierarchyRangeFilter(issue, hierarchyFilter)),
);

export const getFilteredIssues = createSelector(
	[getIssues, getIssueFilterMatcher],
	getFilteredIssuesPure,
);

export const getIssueFilterMatcherWithFilterId = createSelector(
	[getFilters, isOptimizedMode, getCustomFields],
	(filters: ResolvedFilters, optimizedMode: boolean, customFields: CustomField[]) =>
		(issue: Issue) =>
			getUnmatchedFiltersList(filters, optimizedMode, customFields, issue),
);

export const getParentsAndDescendantsPure = (
	filteredIssues: Issue[],
	{ start, end }: HierarchyValue,
	issueMap: IssueMap,
	descendantsMap: DescendantsMap,
): Record<
	string,
	{
		ancestors: Issue[];
		descendants: Issue[];
	}
> => {
	const resultMap: Record<
		string,
		{
			ancestors: Issue[];
			descendants: Issue[];
		}
	> = {};
	for (const filteredIssue of filteredIssues) {
		resultMap[filteredIssue.id] = {
			ancestors: getAncestors(filteredIssue, issueMap, start),
			descendants: getDescendants(filteredIssue, issueMap, descendantsMap, end),
		};
	}
	return resultMap;
};

export const getParentAndDescendant = createSelector(
	[getFilteredIssues, getFilters, getIssueMapById, getChildrenIdsByParent],
	(
		filteredIssues: Issue[],
		filters: ResolvedFilters,
		issueMap: IssueMap,
		descendantsMap: DescendantsMap,
	) =>
		getParentsAndDescendantsPure(
			filteredIssues,
			{
				start: filters[HIERARCHY_RANGE_FILTER_ID].value.start,
				end: filters[HIERARCHY_RANGE_FILTER_ID].value.end,
			},
			issueMap,
			descendantsMap,
		),
);

export const getWholeHierarchyParentAndDescendant = createSelector(
	[getAllIssues, getHierarchyRange, getIssueMapById, getChildrenIdsByParent],
	(
		issues: Issue[],
		{ max: start, min: end }: HierarchyRange,
		issueMap: IssueMap,
		descendantsMap: DescendantsMap,
	) =>
		getParentsAndDescendantsPure(
			issues,
			{
				start,
				end,
			},
			issueMap,
			descendantsMap,
		),
);

export const getFilteredIssuesWithHierarchyPure = (
	flatIssues: Issue[],
	ancestorsAndDescendants: {
		[key: string]: {
			ancestors: Issue[];
			descendants: Issue[];
		};
	},
	showFullHierarchy: boolean,
) => applyShowFullHierarchyOption(flatIssues, ancestorsAndDescendants, showFullHierarchy);

export const getFilteredIssuesWithHierarchy = createSelector(
	[getFilteredIssues, getParentAndDescendant, getShowFullHierarchy],
	getFilteredIssuesWithHierarchyPure,
);

export const getFilteredIssuesWithHierarchyById = createSelector(
	[getFilteredIssuesWithHierarchy],
	(filteredIssuesWithHierarchy) => R.indexBy(R.prop('id'), filteredIssuesWithHierarchy),
);

export const getIssueChangesMetaData = (state: State): EntityMetadata =>
	R.path(['domain', 'updateJira', 'changes', 'data', 'metaData', 'issues'], state) || {};

export const getIssueChangesData = (state: State): ApiIssue[] => {
	const issues = getIssueMapById(state);
	const changes: ApiIssue[] =
		R.path(['domain', 'updateJira', 'changes', 'data', 'issues'], state) || [];
	return changes.map((change) => {
		const issue = issues[change.id];
		if (!isDefined(issue) || !isDefined(change.values)) return change;
		const isSameParent = R.eqProps('parent', issue, change.values);
		return isSameParent ? change : R.assocPath(['values', 'parent'], issue.parent, change);
	});
};

// Splits a change with array values as added and removed attributes
export const splitComponentsChange = (issue: ApiIssue, attributeName: 'labels' | 'components') => {
	if (!R.has(attributeName)(issue.originals)) {
		return issue;
	}

	// originalItems and valueItems will both either be a string[] or a number[]
	// TS has a hard time determining that they would both be the same type and complains when you try and difference them
	// Cast to any to avoid this
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const originalItems = (issue.originals[attributeName] || []) as any[];
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const valueItems = (issue.values[attributeName] || []) as any[];
	const addedItems = R.difference(valueItems, originalItems);
	const removedItems = R.difference(originalItems, valueItems);

	const haveAddedItems = addedItems.length > 0;
	const haveRemovedItems = removedItems.length > 0;

	if (!haveAddedItems && !haveRemovedItems) {
		return issue;
	}

	if (haveAddedItems || haveRemovedItems) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, no-param-reassign
		delete issue.values[attributeName as keyof ApiIssue['values']];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, no-param-reassign
		delete issue.originals[attributeName as keyof ApiIssue['originals']];
	}

	const capitalize = R.replace(/^./, R.toUpper);
	const addedAttributeName = `added${capitalize(attributeName)}`;
	const removedAttributeName = `removed${capitalize(attributeName)}`;

	return {
		...issue,
		originals: {
			...issue.originals,
			...(haveAddedItems ? { [addedAttributeName]: [] } : {}),
			...(haveRemovedItems ? { [removedAttributeName]: [] } : {}),
		},
		values: {
			...issue.values,
			...(haveAddedItems ? { [addedAttributeName]: addedItems } : {}),
			...(haveRemovedItems ? { [removedAttributeName]: removedItems } : {}),
		},
	};
};

export const spreadCustomFieldChanges = (
	issue: ApiIssue,
	customFields: CustomField[],
	scenarioType: ApiScenarioType,
) => {
	const multiSelectFields = getCustomFieldsByKey(CustomFieldTypes.MultiSelect, customFields);
	multiSelectFields.forEach(({ id: multiSelectField }) => {
		// for the newly created issue, the originals would be undefined,
		// but we still need to display the multi select custom field value, refer to https://hello.jira.atlassian.cloud/browse/JPO-27813
		if (
			(scenarioType === SCENARIO_TYPE.ADDED &&
				R.has(multiSelectField.toString())(issue.values.customFields || {})) ||
			(issue.originals.customFields &&
				R.has(multiSelectField.toString())(issue.originals.customFields || {}))
		) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const originalItems = ((issue.originals.customFields &&
				issue.originals.customFields[multiSelectField]) ||
				[]) as string[];
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const valueItems = ((issue.values.customFields &&
				issue.values.customFields[multiSelectField]) ||
				[]) as string[]; // Multi select field values are always string arrays
			const addedItems = R.difference(valueItems, originalItems);
			const removedItems = R.difference(originalItems, valueItems);

			const haveAddedItems = addedItems.length > 0;
			const haveRemovedItems = removedItems.length > 0;

			if (haveAddedItems) {
				// eslint-disable-next-line no-param-reassign
				issue.values[`customFieldValueAdded-${multiSelectField}`] = {
					id: multiSelectField,
					typeKey: CustomFieldTypes.MultiSelect,
					value: addedItems,
				};
				// eslint-disable-next-line no-param-reassign
				issue.originals[`customFieldValueAdded-${multiSelectField}`] = {
					id: multiSelectField,
					typeKey: CustomFieldTypes.MultiSelect,
					value: [],
				};
			}

			if (haveRemovedItems) {
				// eslint-disable-next-line no-param-reassign
				issue.values[`customFieldValueRemoved-${multiSelectField}`] = {
					id: multiSelectField,
					typeKey: CustomFieldTypes.MultiSelect,
					value: removedItems,
				};
				// eslint-disable-next-line no-param-reassign
				issue.originals[`customFieldValueRemoved-${multiSelectField}`] = {
					id: multiSelectField,
					typeKey: CustomFieldTypes.MultiSelect,
					value: [],
				};
			}

			// the cases where subsequent scenario changes haved cancelled each other out.
			// still needs to be displayed so the user can clean up scenario data.
			if (!haveRemovedItems && !haveAddedItems) {
				// eslint-disable-next-line no-param-reassign
				issue.values[`customField-${multiSelectField}`] = {
					id: multiSelectField,
					typeKey: CustomFieldTypes.MultiSelect,
					value: valueItems?.length > 0 ? valueItems : undefined,
				};
				// eslint-disable-next-line no-param-reassign
				issue.originals[`customField-${multiSelectField}`] = {
					id: multiSelectField,
					typeKey: CustomFieldTypes.MultiSelect,
					value: originalItems?.length > 0 ? originalItems : undefined,
				};
			}
		}
	});

	const filterMultiSelectCustomFields = (
		customFieldsValues: Record<PropertyKey, CustomFieldValue>,
	) =>
		Object.entries(customFieldsValues).filter(([fieldId]) => {
			const customField = getCustomFieldById(Number(fieldId), customFields)[0];
			return !customField || (customField && customField.type.key !== CustomFieldTypes.MultiSelect);
		});

	const getCustomFieldTypeKey = (fieldId: string) => {
		const customField = getCustomFieldById(Number(fieldId), customFields)[0];
		return customField && customField.type ? customField.type.key : undefined;
	};

	if (issue.values.customFields) {
		filterMultiSelectCustomFields(issue.values.customFields).forEach(([fieldId, value]) => {
			// eslint-disable-next-line no-param-reassign
			issue.values[`customField-${fieldId}`] = {
				id: fieldId,
				value,
				typeKey: getCustomFieldTypeKey(fieldId),
			};
		});
		// eslint-disable-next-line no-param-reassign
		delete issue.values.customFields;
	}

	if (issue.originals.customFields) {
		filterMultiSelectCustomFields(issue.originals.customFields).forEach(([fieldId, value]) => {
			// eslint-disable-next-line no-param-reassign
			issue.originals[`customField-${fieldId}`] = {
				id: fieldId,
				value,
				typeKey: getCustomFieldTypeKey(fieldId),
			};
		});
		// eslint-disable-next-line no-param-reassign
		delete issue.originals.customFields;
	}

	return issue;
};

export const getChangedAttributes = (
	{ values, originals }: ApiIssue,
	scenarioType?: string,
): (keyof IssueValues)[] => {
	if (scenarioType === SCENARIO_TYPE.ADDED) {
		const { summary, lexoRank, project, type, color, ...remainingValues } = values;

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return Object.keys(remainingValues) as Array<keyof IssueValues>;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return Object.keys(originals) as Array<keyof IssueValues>;
};

export const removeIncompatibleChange = (
	valueObject: Partial<IssueValues>,
	plan: PlanInfo,
): Partial<IssueValues> => {
	const { planningUnit } = plan;
	const attributes = Object.keys(valueObject);

	// Remove completedSprints because they should not change.
	const { completedSprints, ...values } = valueObject;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const valuesToRemove: Array<any | string> = [];

	if (
		attributes.includes('storyPoints') &&
		(planningUnit === PlanningUnits.hours || planningUnit === PlanningUnits.days)
	) {
		valuesToRemove.push('storyPoints');
	}
	if (attributes.includes('timeEstimate') && planningUnit === PlanningUnits.storyPoints) {
		valuesToRemove.push('timeEstimate');
	}

	/**
	 * Themes are not supported in 3.0 but however users can make theme
	 * changes in 2.0 and switch to 3.0 interface so we ignore those changes from showing up
	 * in the review changes dialog.
	 */

	if (attributes.includes('theme')) {
		valuesToRemove.push('theme');
	}

	/**
	 * In Portfolio 2.0, distribution values are aggregated as story points and when the user
	 * tries to commit, only the story point value is shown as the change.
	 * Distribution is not supported in 3.0 but however users can make distribution
	 * changes in 2.0 and switch to 3.0 interface so we ignore those changes from showing up
	 * in the review changes dialog.
	 */
	if (attributes.includes('distribution')) {
		valuesToRemove.push('distribution');
	}

	return R.omit(valuesToRemove, values);
};

export const getChangedAttributeName = (
	changedAttributes: (keyof IssueValues)[],
	scenarioType: ScenarioType,
): keyof IssueValues | undefined => {
	if (scenarioType === SCENARIO_TYPE.ADDED || changedAttributes.length !== 1) {
		return;
	}
	const attributeName = changedAttributes[0];
	return attributeName;
};

export const getCommitWarningsForIssues = ({
	domain: {
		updateJira: { warnings },
	},
}: State): Warnings => warnings[ENTITY.ISSUE];

export const getChangeWarningsForIssues = ({
	domain: {
		updateJira: { changesWarnings },
	},
}: State): Warnings => changesWarnings[ENTITY.ISSUE];

const getIssueChangesWithLinks = (
	planInfo: PlanInfo,
	issue: ApiIssue,
	metaData: ChangeMetadata,
	warnings: Warning[] = [],
	issueLinks: IssueLinksData[] = [],
	issueLinksMetaData: EntityMetadata,
	customFields: CustomField[],
) => {
	const scenarioType = metaData.scenarioType;
	if (scenarioType === SCENARIO_TYPE.ADDED) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, no-param-reassign
		issue.values = removeIncompatibleChange(issue.values, planInfo) as any;
	} else {
		// eslint-disable-next-line no-param-reassign
		issue.originals = removeIncompatibleChange(issue.originals, planInfo);
	}
	for (const link of issueLinks) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { itemKey, scenarioType } = link;
		const linkMeta = issueLinksMetaData[itemKey];
		if (linkMeta.lastChangeTimestamp > metaData.lastChangeTimestamp) {
			// eslint-disable-next-line no-param-reassign
			metaData.lastChangeTimestamp = linkMeta.lastChangeTimestamp;
			// eslint-disable-next-line no-param-reassign
			metaData.lastChangeUser = linkMeta.lastChangeUser;
		}
		const attributeName = `issueLink-${itemKey}` as const;
		switch (scenarioType) {
			case SCENARIO_TYPE.ADDED:
				// eslint-disable-next-line no-param-reassign
				issue.values[attributeName] = link;
				// eslint-disable-next-line no-param-reassign
				issue.originals[attributeName] = null;
				break;
			case SCENARIO_TYPE.DELETED:
				// eslint-disable-next-line no-param-reassign
				issue.values[attributeName] = null;
				// eslint-disable-next-line no-param-reassign
				issue.originals[attributeName] = link;
				break;
			default:
				throw new Error(`Unexpected issue link scenarioType: ${scenarioType}`);
		}
	}
	const issueWithComponentsChange = splitComponentsChange(issue, 'components');
	const issueWithLabelsChange = splitComponentsChange(issueWithComponentsChange, 'labels');
	const issueWithCustomFieldChanges = spreadCustomFieldChanges(
		issueWithLabelsChange,
		customFields,
		scenarioType,
	);

	const changedAttributes = getChangedAttributes(issueWithCustomFieldChanges, scenarioType);
	return {
		id: issueWithCustomFieldChanges.id,
		category: ENTITY.ISSUE,
		metaData,
		changeCount: changedAttributes.length,
		warnings,
		attributeName: getChangedAttributeName(changedAttributes, scenarioType),
		details: issueWithCustomFieldChanges,
	};
};

export const getIssueChangesPure = (
	planInfo: PlanInfo,
	issues: ApiIssue[] = [],
	entityMetaData: EntityMetadata,
	warnings: Warnings,
	issueLinks: IssueLinksDataMap,
	issueLinksMetaData: EntityMetadata,
	changesWarnings: Warnings,
	customFields: CustomField[],
): IssueChange[] => {
	const issueChangeDataByIdMap = indexBy(R.prop('id'), issues);

	// Issue changes generated by this function incorporate issue links changes
	// represented as issue values.
	// Injecting them brings some complications:
	// 1. Changes handling architecture doesn't support spreading of array values as to multiple changes rows (see JPOS-2583)
	// 2. metaData should be calculated based on both issues and issueLinks metaData
	// 3. Issue link changes should be injected into existing issue changes when they exists,
	// otherwise new fake issue changes should be produced.

	const changes: IssueChange[] = filterMap(
		(id) => isDefined(issueChangeDataByIdMap[id]),
		(id) => {
			const issue: ApiIssue = issueChangeDataByIdMap[id];
			const metaData = entityMetaData[id];
			return getIssueChangesWithLinks(
				planInfo,
				{ ...issue },
				{ ...metaData },
				[...(changesWarnings[id] || []), ...(warnings[id] || [])],
				issueLinks[id],
				issueLinksMetaData,
				customFields,
			);
		},
		Object.keys(entityMetaData),
	);

	const issueLinkOnlyChanges: IssueChange[] = filterMap(
		(id) => !isDefined(entityMetaData[id]) && isDefined(issueChangeDataByIdMap[id]),
		(id) => {
			const issue: ApiIssue = issueChangeDataByIdMap[id];
			const metaData = {
				lastChangeTimestamp: 0,
				lastChangeUser: 'unassigned',
				scenarioType: SCENARIO_TYPE.UPDATED,
			};
			return getIssueChangesWithLinks(
				planInfo,
				{ ...issue },
				metaData,
				[...(changesWarnings[id] || []), ...(warnings[id] || [])],
				issueLinks[id],
				issueLinksMetaData,
				customFields,
			);
		},
		Object.keys(issueLinks),
	);

	return changes
		.concat(issueLinkOnlyChanges)
		.filter(
			({ metaData: { scenarioType }, details: { originals } }) =>
				(scenarioType === SCENARIO_TYPE.UPDATED && Object.keys(originals).length > 0) ||
				scenarioType === SCENARIO_TYPE.ADDED ||
				scenarioType === SCENARIO_TYPE.DELETED,
		);
};

export const getIssueChanges = createSelector(
	[
		getPlan,
		getIssueChangesData,
		getIssueChangesMetaData,
		getCommitWarningsForIssues,
		getIssueLinkChangesData,
		getIssueLinkChangesMetaData,
		getChangeWarningsForIssues,
		getCustomFields,
	],
	getIssueChangesPure,
);

export const getIssueChangesWithChangedLinksPure = (issueChanges: IssueChange[]): IssueChange[] =>
	issueChanges.filter((change: IssueChange) => {
		const changedAttributes = Object.keys(change.details.originals);
		return changedAttributes.some((attribute) => attribute.match(/^issueLink-/));
	});

export const getIssueChangesWithChangedLinks = createSelector(
	[getIssueChanges],
	getIssueChangesWithChangedLinksPure,
);

export const getIssueAttributeChangeCountsPure = (issueChanges: IssueChange[]) => {
	const customFieldChangeKey = /^customField-\d+$/;

	const simplifyAttributeList = (attributes: (keyof IssueValues)[]) => {
		const multiValueFields: [string, string[]][] = [
			['labels', ['addedLabels', 'removedLabels']],
			['components', ['addedComponents', 'removedComponents']],
		];

		let simplifiedAttributes = attributes.map((attribute) =>
			attribute.match(customFieldChangeKey) ? 'customField' : attribute,
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		multiValueFields.forEach(([key, fields]: [any, any]) => {
			if (fields.some((field: SimplifiedIssueValues) => simplifiedAttributes.includes(field))) {
				simplifiedAttributes = simplifiedAttributes.filter(
					(attribute) => !fields.includes(attribute),
				);
				simplifiedAttributes.push(key);
			}
		});

		return simplifiedAttributes;
	};

	return issueChanges
		.filter((issue) => issue.metaData.scenarioType === SCENARIO_TYPE.UPDATED)
		.map((issue) => getChangedAttributes(issue.details, issue.metaData.scenarioType)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Record<string, any>>((attributeCounts, changedAttributes) => {
			const simplifiedChangedAttributes = simplifyAttributeList(changedAttributes);
			simplifiedChangedAttributes.forEach((attribute) => {
				// eslint-disable-next-line no-param-reassign
				attributeCounts[attribute] = (attributeCounts[attribute] || 0) + 1;
			});
			return attributeCounts;
		}, {});
};

export const getIssueAttributeChangeCounts = createSelector(
	[getIssueChanges],
	getIssueAttributeChangeCountsPure,
);

export const getCreatedIssueCountPure = (issueChanges: IssueChange[]) =>
	issueChanges.filter((issue) => issue.metaData.scenarioType === SCENARIO_TYPE.ADDED).length;

export const getCreatedIssueCount = createSelector([getIssueChanges], getCreatedIssueCountPure);

function getIdsOfIssuesWithChangedLinks(
	incomingIssueLinks: IssueLinksByIssueId,
	originalIncomingIssueLinks: IssueLinksByIssueId,
	outgoingIssueLinks: IssueLinksByIssueId,
	originalOutgoingIssueLinks: IssueLinksByIssueId,
): Set<string> {
	const changedLinkIssues = new Set<string>();

	// There could be scenario changes on both internal and external links.
	// Checking both incoming and outgoing links would pickup all changes.
	const linksData = [
		[incomingIssueLinks, originalIncomingIssueLinks],
		[outgoingIssueLinks, originalOutgoingIssueLinks],
	];

	for (const [issueLinks, originalIssueLinks] of linksData) {
		const issueLinkIds = new Set(Object.keys(issueLinks).concat(Object.keys(originalIssueLinks)));

		for (const id of issueLinkIds) {
			const changedLinks = xorWith(
				issueLinks[id] || [],
				originalIssueLinks[id] || [],
				(link: IssueLink, originalLink: IssueLink) =>
					get(link, 'itemKey') === get(originalLink, 'itemKey'),
			);

			if (changedLinks.length > 0) {
				changedLinkIssues.add(id);
			}
		}
	}
	return changedLinkIssues;
}

export const getIssueChangeCountPure = (
	planInfo: PlanInfo,
	originalIssues: OriginalIssues,
	incomingIssueLinks: IssueLinksByIssueId,
	originalIncomingIssueLinks: IssueLinksByIssueId,
	outgoingIssueLinks: IssueLinksByIssueId,
	originalOutgoingIssueLinks: IssueLinksByIssueId,
): number => {
	const changedIssues: Set<string> = new Set(Object.keys(originalIssues));
	for (const id of [...changedIssues]) {
		// keep excluded issues
		if (Object.keys(originalIssues[id]).length >= 1) {
			const processedOriginalIssue = removeIncompatibleChange(originalIssues[id], planInfo);

			if (id == null) {
				log.safeErrorWithoutCustomerData(
					'plans.accessing-property-of-undefined',
					'getIssueChangeCountPure id is undefined',
				);
			}

			if (
				!id?.startsWith(SCENARIO_ISSUE_ID_PREFIX) &&
				Object.keys(processedOriginalIssue).length < 1
			) {
				changedIssues.delete(id);
			}
		}
	}

	// Add IDs for issues with changed links
	const changedLinkIssues = getIdsOfIssuesWithChangedLinks(
		incomingIssueLinks,
		originalIncomingIssueLinks,
		outgoingIssueLinks,
		originalOutgoingIssueLinks,
	);
	changedLinkIssues.forEach(changedIssues.add, changedIssues);

	return changedIssues.size;
};

export const getIssueChangeCount = createSelector(
	[
		getPlan,
		getOriginalIssues,
		getIncomingLinks,
		getIncomingLinkOriginals,
		getOutgoingLinks,
		getOutgoingLinkOriginals,
	],
	getIssueChangeCountPure,
);

export const getIssueChangesWithChangedLinksCountPure = (
	incomingIssueLinks: IssueLinksByIssueId,
	originalIncomingIssueLinks: IssueLinksByIssueId,
	outgoingIssueLinks: IssueLinksByIssueId,
	originalOutgoingIssueLinks: IssueLinksByIssueId,
): number =>
	getIdsOfIssuesWithChangedLinks(
		incomingIssueLinks,
		originalIncomingIssueLinks,
		outgoingIssueLinks,
		originalOutgoingIssueLinks,
	).size;

export const getIssueChangesWithChangedLinksCount = createSelector(
	[getIncomingLinks, getIncomingLinkOriginals, getOutgoingLinks, getOutgoingLinkOriginals],
	getIssueChangesWithChangedLinksCountPure,
);

export const getInitialFilterValuesPure = (issues: Issue[]): HierarchyFilterValue => {
	const issueCountByLevel = R.countBy((issue) => issue.level.toString(), issues);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const startLevel = Object.keys(issueCountByLevel).reduce((startLevel, level) => {
		const currentLevel = parseFloat(level);
		if (currentLevel > startLevel && issueCountByLevel[level] > 1) {
			return currentLevel;
		}
		return startLevel;
	}, EPIC_LEVEL);

	return { start: startLevel, end: SUB_TASK_LEVEL };
};

export const getInitialFilterValues = createSelector([getIssues], getInitialFilterValuesPure);

export const getIssuesByVersionMapPure = (
	versions: Version[],
	issues: Issue[],
): IssuesByVersionMap => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issuesByVersionMap: Record<string, any> = {};
	// This is suboptimal but necessary to preserve semantics of the old
	// algorithm, as consumers rely on the fact that for any valid version id
	// they will get at least an empty array but not null. Now imagine that
	// there is a version that is not assigned to any issue. Omitting this loop
	// will not create an entry with empty array for its id, unlike an old
	// selector reducing over versions.
	for (const { id } of versions) {
		issuesByVersionMap[id] = [];
	}
	for (const issue of issues) {
		for (const versionId of issue.fixVersions || []) {
			if (typeof issuesByVersionMap[versionId] === 'undefined') {
				issuesByVersionMap[versionId] = [];
			}
			issuesByVersionMap[versionId].push(issue);
		}
	}
	return issuesByVersionMap;
};

export const getIssuesByVersionMap = createSelector(
	[getVersions, getIssues],
	getIssuesByVersionMapPure,
);

export const getHistoryIssues = (state: State) => state.domain.historyIssues;
export const isLoadingHistory = (state: State) =>
	state.ui.Main.Tabs.Roadmap.Scope.Issues.isLoadingHistory;
export const isSavingIssue = (state: State) =>
	state.ui.Main.Tabs.Roadmap.Scope.Issues.isSavingIssue;

// Wrapping into a new Set() to workaround a bug in V8 in Chrome 75
// which gets crazy on direct access to the underlying set and leaks memory until page crashes.
// References:
// * https://getsupport.atlassian.com/browse/JPO-3619
// * https://bulldog.internal.atlassian.com/browse/JPOS-3827
export const getSelected = (state: State) =>
	new Set<string>(state.ui.Main.Tabs.Roadmap.Scope.Issues.selected);

export const getSelectedIssuesPure = (issuesById: IssueMap, selectedIds: Set<string>): Issue[] =>
	Array.from(selectedIds)
		.map((id) => issuesById[id])
		// Remove undefined issues
		.filter(isDefined);

export const getSelectedIssues = createSelector(
	[getIssueMapById, getSelected],
	getSelectedIssuesPure,
);

export const getSelectedIssuesByProjectIdPure = (
	selectedIssues: Issue[],
): Record<string, Issue[]> =>
	R.groupBy(({ project }: Issue) => R.toString(project), selectedIssues);

export const getSelectedIssuesByProjectId = createSelector<State, Issue[], Record<string, Issue[]>>(
	[getSelectedIssues],
	getSelectedIssuesByProjectIdPure,
);

export const getCPRsForSelectedIssuesPure = (
	projectsByCPRsMap: Record<string, number[]>,
	selectedIssuesByProject: Record<string, Issue[]>,
): string[] => {
	const selectedProjectIds: string[] = Object.keys(selectedIssuesByProject);
	return R.filter(
		(cprId) =>
			selectedProjectIds.every((issueProjectId) =>
				projectsByCPRsMap[cprId].includes(Number(issueProjectId)),
			),
		Object.keys(projectsByCPRsMap),
	);
};

export const getCPRsForSelectedIssues = createSelector(
	[getProjectIdsByCrossProjectVersionsMap, getSelectedIssuesByProjectId],
	getCPRsForSelectedIssuesPure,
);

export const toApiIssue = (
	issue: Issue,
	originals: Partial<IssueValues>,
	scenarioType: string = SCENARIO_TYPE.UPDATED,
): ApiIssue => {
	const {
		id,
		issueKey,
		assignments = [],
		annotations = [],
		issueSources = [],
		level /** Level can never be scenario changed and it's not present in API issue. It is calculated from 'type' */,
		inferred /** Inferred is not present in API issue as it's metadata which maps field name to inference source. */,
		rollups /** rollups not needed in scenario changes */,
		...rest
	} = issue;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	let values = R.reject(R.equals<IssueValues[keyof IssueValues]>(undefined))(rest) as IssueValues;

	if (scenarioType === SCENARIO_TYPE.ADDED) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		values = R.reject(R.equals<IssueValues[keyof IssueValues]>(null))(values) as IssueValues;

		// Exclude baselineStart and baselineEnd in newly added issues as they are the inferred dates value, rather than dates set by user
		delete values.baselineStart;
		delete values.baselineEnd;
		// 'issueKey' is not defined in the newly created issue.
		return {
			id,
			assignments,
			annotations,
			issueSources,
			values,
			originals,
		};
	}

	return {
		id,
		issueKey,
		assignments,
		annotations,
		issueSources,
		values,
		originals,
	};
};

export const getFirstParentIssueAndNestedDescendantPure = (
	sortedIssues: Issue[],
	descendantMap: {
		[key: string]: string[];
	},
	sort: SortIssues,
): String[] => {
	if (isEmpty(sortedIssues)) {
		return [];
	}

	const hierarchyGroupedIssues = groupBy(sortedIssues, ({ level }: Issue) => level);
	const idGroupedIssues = groupBy(sortedIssues, ({ id }: Issue) => id);
	const issueLevels = sortedIssues.map((issue) => issue.level);

	const minMaxHierarchyLevel = issueLevels.reduce(
		(acc, hierarchyLevel) => {
			if (acc.minHierarchyLevel > hierarchyLevel) acc.minHierarchyLevel = hierarchyLevel;
			if (acc.maxHierarchyLevel < hierarchyLevel) acc.maxHierarchyLevel = hierarchyLevel;
			return acc;
		},
		{
			minHierarchyLevel: issueLevels[0],
			maxHierarchyLevel: issueLevels[0],
		},
	);

	const { maxHierarchyLevel, minHierarchyLevel } = minMaxHierarchyLevel;

	let firstIssueWithDescendant;
	// Finding the first parent issue on the list
	// We don't need to check the lowest level, as they will never have any child.
	for (
		let hierarchyLevel = maxHierarchyLevel;
		hierarchyLevel > minHierarchyLevel;
		hierarchyLevel--
	) {
		const currentHierarchyLevelIssues: Issue[] = hierarchyGroupedIssues[hierarchyLevel];
		const issueWithDescendant = currentHierarchyLevelIssues?.find((issue) => {
			const descendants = descendantMap[issue.id];
			return !isEmpty(descendants);
		});

		if (!isEmpty(issueWithDescendant)) {
			firstIssueWithDescendant = issueWithDescendant;
			break;
		}
	}

	if (isEmpty(firstIssueWithDescendant)) {
		return [];
	}

	// Descendant list includes the direct and indirect descendants
	// For each nested children, we want to only return the first one for expansion.
	// If there is a descendant to the descendant of an issue, it will also be in the list
	const issueDescendants: Issue[] = descendantMap[firstIssueWithDescendant.id].flatMap(
		(id) => idGroupedIssues[id],
	);

	return [
		firstIssueWithDescendant.id,
		...recursivelyFindFirstDescendantToExpand(
			issueDescendants,
			descendantMap,
			idGroupedIssues,
			sort,
			Math.max(...issueDescendants.map((issue) => issue.level)),
			[],
		),
	];
};

const recursivelyFindFirstDescendantToExpand = (
	issueDescendants: Issue[],
	descendantMap: { [key: string]: string[] },
	idGroupedIssues: { [key: string]: Issue[] },
	sort: SortIssues,
	descendantsHighestHierarchy: number,
	acc: String[],
): String[] => {
	if (isEmpty(issueDescendants)) {
		return acc;
	}

	// Finding the first descendant in the list where the hierarchyLevel is the highest and has children.
	const firstDescendantToExpand = sort(
		issueDescendants.filter((issue) => issue.level === descendantsHighestHierarchy),
	).find((issue) => !isEmpty(descendantMap[issue.id]));
	if (isEmpty(firstDescendantToExpand)) {
		return acc;
	}
	return recursivelyFindFirstDescendantToExpand(
		descendantMap[firstDescendantToExpand.id].flatMap((id) => idGroupedIssues[id]),
		descendantMap,
		idGroupedIssues,
		sort,
		descendantsHighestHierarchy - 1,
		[...acc, firstDescendantToExpand.id],
	);
};

export const getFirstParentIssueAndNestedDescendant = createSelector(
	[getAllSortedIssues, getDescendantIdsByParent, getSort],
	getFirstParentIssueAndNestedDescendantPure,
);

export const usePlanSize = () =>
	useSelector((state: State) => calcPlanSize(state.domain.issues.length));

export const getPlanSize = (state: State) => calcPlanSize(state.domain.issues.length);
