import React, {
	createContext,
	useContext,
	useMemo,
	type PropsWithChildren,
	useCallback,
	useRef,
} from 'react';
import noop from 'lodash/fp/noop';
import sum from 'lodash/fp/sum';
import isEqual from 'lodash/isEqual';
import {
	SCOPE,
	TIMELINE,
	SECTION_COLLAPSE_THRESHOLD,
	COLUMN_COLLAPSE_THRESHOLD,
	TIMELINE_DEFAULT_EXPANDABLE_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import type { Resizer } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/types.tsx';
/** Map of collapsed states for each column. FIELDS refers to the entire fields state */
type Collapsed = Record<number | 'FIELDS', boolean>;
type Precollapsed = Record<string /* column ID */, number /* Its width */>;

const isFieldColumn = (columnId: string) => columnId !== TIMELINE && columnId !== SCOPE;

const getCollapsed = (widths: number[]): Collapsed => {
	const result: Collapsed = {
		FIELDS: isCollapsed(widths, {
			column: 1,
			colSpan: widths.length - 2 /* Except the scope and timeline */,
		}),
	};

	for (let column = 0; column < widths.length; column++) {
		result[column] = isCollapsed(widths, { column, colSpan: 1 });
	}

	return result;
};

export const isCollapsed = (
	widths: number[],
	{ column, colSpan }: { column: number; colSpan: number },
) => {
	const accumulatedColumnWidth /* includes colspan */ = widths
		.slice(column, column + colSpan)
		.reduce(
			(acc: number, width = Infinity) =>
				Number.isFinite(acc) && Number.isFinite(width) ? acc + width : Infinity,
			0,
		);

	const fieldsSectionWidth = sum(widths.slice(1, widths.length - 1));

	if (column === 1) {
		// Fields
		return (
			accumulatedColumnWidth <= COLUMN_COLLAPSE_THRESHOLD ||
			fieldsSectionWidth <= SECTION_COLLAPSE_THRESHOLD
		);
	}

	if (column === widths.length - 1) {
		return accumulatedColumnWidth <= SECTION_COLLAPSE_THRESHOLD;
	}

	return accumulatedColumnWidth <= COLUMN_COLLAPSE_THRESHOLD;
};

type Props = {
	columnIds: string[];
	defaultWidths: number[];
	widths: number[];
	resizer: Resizer;
	updateWidths: (arg0: number[]) => void;
};

export type ContextType = {
	/** Map of collapsed states for each column. FIELDS refers to the entire fields state */
	collapsed?: Record<number | 'FIELDS', boolean>;
	cache: (arg0: number[]) => void;
	expand: (arg0: number) => void;
	collapse: (arg0: number) => void;
	expandFields: () => void;
	collapseFields: () => void;
};

export const context = createContext<ContextType>({
	collapsed: { FIELDS: false },
	cache: noop,
	expand: noop,
	collapse: noop,
	expandFields: noop,
	collapseFields: noop,
});

export const useColumnCollapsed = ({ isHeader, column }: { isHeader: boolean; column: number }) => {
	const { collapsed } = useContext(context);
	return [collapsed?.[isHeader && column === 1 ? 'FIELDS' : column] ?? false];
};

export const CollapseProvider = ({
	columnIds,
	defaultWidths,
	widths,
	resizer,
	updateWidths,
	children,
}: PropsWithChildren<Props>) => {
	/* The widths of the fields before the section collapsing. */
	const fieldsPrecollapsedRef = useRef<Precollapsed>({});
	/* The widths of the columns before their collapsing. */
	const columnsPrecollapsedRef = useRef<Precollapsed>({});

	const widthsRef = useRef(widths);
	widthsRef.current = widths;

	const collapsedRef = useRef(getCollapsed(widths));
	let collapsed = collapsedRef.current;
	const collapsedNew = getCollapsed(widths);

	if (!isEqual(collapsed, collapsedNew)) {
		collapsedRef.current = collapsedNew;
		collapsed = collapsedNew;
	}

	const resize = useCallback(
		(column: number, delta: number) => {
			if (column === widthsRef.current?.length - 1) {
				// Timeline column resizing needs to rely on the last field column resizing.
				return resize(column - 1, -delta);
			}

			let nextWidths = resizer({ column, delta })(widthsRef.current);

			const remaining = delta - (nextWidths[column] - widthsRef.current?.[column]);

			if (remaining !== 0 && column > 0) {
				// Try to expand to the left if the right space is not enough.
				nextWidths = resizer({ column: column - 1, delta: -remaining })(nextWidths);
				nextWidths = resizer({ column, delta: remaining })(nextWidths);
			}
			updateWidths(nextWidths);
		},
		[resizer, updateWidths],
	);

	const cache = useCallback(
		(newWidths: number[]) => {
			const columns: Precollapsed = { ...columnsPrecollapsedRef.current };
			const fields: Precollapsed = { ...fieldsPrecollapsedRef.current };

			const isFieldsCollapsed = isCollapsed(newWidths, {
				column: 1,
				colSpan: columnIds.length - 2 /* Except the scope and timeline */,
			});

			columnIds.forEach((columnId, column) => {
				if (isFieldColumn(columnId) && !isFieldsCollapsed) {
					fields[columnId] = newWidths[column];
				}

				if (!isCollapsed(newWidths, { column, colSpan: 1 })) {
					columns[columnId] = newWidths[column];
				}
			});
			fieldsPrecollapsedRef.current = fields;
			columnsPrecollapsedRef.current = columns;
		},
		[columnIds],
	);

	const expandFields = useCallback(() => {
		const nextWidths = columnIds.map((columnId, index) => {
			if (!isFieldColumn(columnId)) {
				return widthsRef.current?.[index];
			}

			if (columnIds.length === 3) {
				// When it's the only one field column being collapsed.
				return defaultWidths[index];
			}

			return fieldsPrecollapsedRef.current?.[columnId] ?? defaultWidths[index];
		});

		updateWidths(nextWidths);
	}, [columnIds, defaultWidths, updateWidths]);

	const expand = useCallback(
		(column: number) => {
			const columnId = columnIds[column];
			const defaultWidth =
				columnId === TIMELINE ? TIMELINE_DEFAULT_EXPANDABLE_WIDTH : defaultWidths[column];
			const width = columnsPrecollapsedRef.current?.[columnId] ?? defaultWidth;

			resize(column, width - widthsRef.current?.[column]);
		},
		[columnIds, defaultWidths, resize],
	);

	const collapseFields = useCallback(() => {
		const lastFieldColumnIndex = columnIds.length - 2;
		const fieldsSectionWidth = sum(widthsRef.current?.slice(1, widthsRef.current?.length - 1));
		const delta = SECTION_COLLAPSE_THRESHOLD - fieldsSectionWidth;
		resize(lastFieldColumnIndex, delta);
	}, [columnIds.length, resize]);

	const collapse = useCallback(
		(column: number) => {
			resize(column, COLUMN_COLLAPSE_THRESHOLD - widthsRef.current?.[column]);
		},
		[resize],
	);

	const value = useMemo(() => {
		return {
			collapsed,
			collapse,
			expand,
			cache,
			expandFields,
			collapseFields,
		};
	}, [collapsed, collapse, expand, cache, expandFields, collapseFields]);

	return <context.Provider value={value}>{children}</context.Provider>;
};

export const useContextActions = () => {
	const { collapse, expand, cache, expandFields, collapseFields } = useContext(context);

	const actionList = useMemo(() => {
		return { collapse, expand, cache, expandFields, collapseFields };
	}, [collapse, expand, cache, expandFields, collapseFields]);

	return actionList;
};
