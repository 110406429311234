import pick from 'lodash/fp/pick';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { CTP_ONBOARDING_STAGE } from './constants.tsx';
import type { ContainerProps, State, CtpOnboarding, Stage } from './types.tsx';

const PERSIST_KEY = 'jira.user.arj.ctp-onboarding';

const getCtpOnboardingUserProperties = async (accountId: string): Promise<unknown> => {
	const url = `/rest/api/3/user/properties/${PERSIST_KEY}?accountId=${accountId}`;
	const fetchResponse: Response = await fetch(url, getDefaultOptions(url));

	// ok is true only when status code is 2xx.
	if (fetchResponse.ok) {
		const responseObj = await fetchResponse.json();
		return responseObj.value;
	}

	const { status } = fetchResponse;

	// 404: API deliberately returns 404 if user property is not found, which implies the user has not migrated their data
	if (status === 404) {
		return undefined;
	}

	throw new Error(
		`Fetching ${PERSIST_KEY} user property failed. Status code: ${fetchResponse.status}`,
	);
};

export const rehydrate: Action<State, ContainerProps> = async (
	{ getState, setState },
	{ accountId },
) => {
	if (getState()._rehydrated) {
		return;
	}

	// Mark as any since it's assumed to be opaque, we ensure type safety in the code below.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let raw: {} | any = {};

	const response = await getCtpOnboardingUserProperties(accountId);

	if (response) {
		raw = response;
	} else {
		raw = {};
	}

	const ctpOnboarding: CtpOnboarding | undefined = (() => {
		const stage: Stage = raw.ctpOnboarding?.stage;
		if (stage && Object.values(CTP_ONBOARDING_STAGE).includes(stage)) {
			return { stage, planId: raw.ctpOnboarding?.planId };
		}

		return undefined;
	})();

	setState({
		_rehydrated: true,
		ctpOnboarding,
	});
};

export const persist =
	(update?: Partial<State>): Action<State, ContainerProps> =>
	async ({ getState }, { accountId }) => {
		const serialize = pick(['ctpOnboarding']);

		await performPutRequest(`/rest/api/3/user/properties/${PERSIST_KEY}?accountId=${accountId}`, {
			body: JSON.stringify(
				serialize({
					...getState(),
					...update,
				}),
			),
		});
	};

export const update =
	(partial: Partial<State>): Action<State> =>
	async ({ getState, setState, dispatch }) => {
		const { _rehydrated, _rehydratePromise } = getState();

		if (!_rehydrated) {
			await _rehydratePromise;
		}

		setState(partial);
		dispatch(persist({ ...getState(), ...partial }));
	};
