import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps, { mapStateToPropsOld } from './query.tsx';
import type { CommandProps, OwnProps, QueryProps } from './types.tsx';
import View from './view.tsx';

const Group = connect<QueryProps, CommandProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);

const GroupOld = connect<QueryProps, CommandProps, OwnProps, State>(
	mapStateToPropsOld,
	mapDispatchToProps,
)(View);

export default componentWithFG('refactor_plan_time_group_name', Group, GroupOld);
