import noop from 'lodash/noop';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, createSelector, type Action } from '@atlassian/react-sweet-state';
import type { State, Flag } from './types.tsx';

const actions = {
	register:
		(flag: Flag): Action<State> =>
		({ setState }) => {
			setState({ flag });
		},
	deregister:
		(flag: Flag): Action<State> =>
		({ getState, setState }) => {
			const { flag: currFlag } = getState();
			setState({ flag: currFlag === flag ? null : currFlag });
		},
} as const;

const Store = createStore<State, typeof actions>({
	initialState: {
		flag: null,
		register: noop,
		deregister: noop,
	},
	actions,
	name: 'arj-dnd-scrolling',
});

export const useDragging = createHook(Store, {
	selector: (state) => !!state.flag,
});

const getUseDndScrolling = createSelector(
	(state: State) => state.flag,
	(state: State) => !!state.flag,
	(flag, dragging) => ({ flag, dragging }),
);

export const useDndScrolling = createHook(Store, {
	selector: getUseDndScrolling,
});
