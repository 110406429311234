import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ariaLabelStatus: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.status-cell.aria-label-status',
		defaultMessage: 'Status breakdown for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Status field in the group header row',
	},
	ariaLabelBreakdown: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.status-cell.aria-label-breakdown',
		defaultMessage: 'Issue count breakdown for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Progress (issue count) field in the group header row',
	},
});
