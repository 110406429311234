import React from 'react';
import DependencyLinesLayer from '../layer/index.tsx';
import type { Props } from './types.tsx';

export const DependencyLinesContainer = ({ scope, timelineRange, width }: Props) => (
	<DependencyLinesLayer
		width={width}
		scope={scope}
		startIndex={0}
		stopIndex={Infinity}
		timelineRange={timelineRange}
	/>
);
