import React from 'react';
import SuccessIcon from '@atlaskit/icon/core/migration/success--check-circle';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import { Box } from '@atlaskit/primitives';
import {
	AkFlag as Flag,
	FlagGroup,
	AkAutoDismissFlag as AutoDismissFlag,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';

import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const CommitWarningNotification = ({
	commitNotificationMessages,
	onClose,
	openDialog,
}: Props) => {
	const { formatMessage } = useIntl();
	if (!commitNotificationMessages) return null;

	if (fg('unblock_ui_during_committing_changes')) {
		return (
			<FlagGroup onDismissed={onClose}>
				{commitNotificationMessages.onlySuccessful ? (
					<AutoDismissFlag
						id="portfolio-3-app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification"
						title={commitNotificationMessages.title}
						description={commitNotificationMessages.description.map((d) => (
							<Box key={d}>{d}</Box>
						))}
						delayAnnouncement={0}
						icon={<SuccessIcon spacing="spacious" label="" color={token('color.icon.success')} />}
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.auto-dismiss-flag"
					/>
				) : (
					<Flag
						id="commit-warning-modification-flag"
						title={commitNotificationMessages.title}
						description={commitNotificationMessages.description.map((d) => (
							<Box key={d}>{d}</Box>
						))}
						actions={[
							{
								content: formatMessage(messages.openUnsavedChangesNonFinal),
								onClick: (e) => {
									openDialog(e);
								},
							},
						]}
						delayAnnouncement={0}
						icon={<WarningIcon spacing="spacious" label="" color={token('color.icon.warning')} />}
					/>
				)}
			</FlagGroup>
		);
	}
	return (
		<FlagGroup onDismissed={onClose}>
			{commitNotificationMessages.onlySuccessful ? (
				<AutoDismissFlag
					id="portfolio-3-app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification"
					title={commitNotificationMessages.title}
					description={commitNotificationMessages.description.map((d) => (
						<Box key={d}>{d}</Box>
					))}
					delayAnnouncement={0}
					icon={<SuccessIcon spacing="spacious" label="" color={token('color.icon.success')} />}
					testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.auto-dismiss-flag"
				/>
			) : (
				<Flag
					id="commit-warning-modification-flag"
					title={commitNotificationMessages.title}
					description={commitNotificationMessages.description.map((d) => (
						<Box key={d}>{d}</Box>
					))}
					delayAnnouncement={0}
					icon={<WarningIcon spacing="spacious" label="" color={token('color.icon.warning')} />}
				/>
			)}
		</FlagGroup>
	);
};
