/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import AvatarGroup from '@atlaskit/avatar-group';
import Spinner from '@atlaskit/spinner';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isDefined } from '../../ramda/index.tsx';
import { openInNewTab } from '../../window/index.tsx';
import ErrorIllustration from './error-illustration.tsx';
import { teamProfileMessages } from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, TeamContentProps } from './types.tsx';

const TeamProfileContent = ({ team }: TeamContentProps) => {
	const { formatMessage } = useIntl();
	const atlassianAccountId = useAccountId();

	const { displayName, largeHeaderImageUrl, id, verified } = team;

	const avatarsData = (team.members || []).map((member) => ({
		name: member.fullName,
		src: member.avatarUrl,
	}));
	const includingYou = team.members?.some((member) => member.accountId === atlassianAccountId);
	const headerImage = isDefined(largeHeaderImageUrl) ? largeHeaderImageUrl : '';
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.card}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.cardHeader} style={{ backgroundImage: `url("${headerImage}")` }} />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.cardContent}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.teamInfo}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-heading -- Ignored via go/DSP-18766 */}
					<h4 className={styles.heading}>
						{displayName}
						{verified && fg('plans_managed_teams_indicator_teams_enabled') && (
							<span css={verifiedTeamIconWrapperStyles}>
								<VerifiedTeamIcon showTooltip />
							</span>
						)}
					</h4>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
					<p className={styles.subheading}>
						{formatMessage(teamProfileMessages.members, {
							members: avatarsData.length,
							includingYou,
						})}
					</p>
					<AvatarGroup data={avatarsData} />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.button}>
					<Button
						onClick={() => {
							openInNewTab(`/jira/people/team/${id}`);
						}}
					>
						{formatMessage(teamProfileMessages.teamProfile)}
					</Button>
				</div>
			</div>
		</div>
	);
};

const TeamProfileLoadingState = () => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${styles.card} ${styles.loadingOrError}`}>
			<Spinner label={formatMessage(teamProfileMessages.loadingLabel)} />
		</div>
	);
};

const TeamProfileErrorState = () => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${styles.card} ${styles.loadingOrError}`}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.errorContainer}>
				<ErrorIllustration />
				{/* eslint-disable-next-line @atlaskit/design-system/use-heading */}
				<h5>{formatMessage(teamProfileMessages.teamErrorTitle)}</h5>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{formatMessage(teamProfileMessages.teamErrorText)}</p>
			</div>
		</div>
	);
};

const TeamProfile = ({ team, isLoading, hasError }: Props) => {
	if (isLoading === true) {
		return <TeamProfileLoadingState />;
	}
	if ((!isDefined(hasError) || hasError === false) && team) {
		return <TeamProfileContent team={team} />;
	}
	return <TeamProfileErrorState />;
};

export default TeamProfile;

const verifiedTeamIconWrapperStyles = css({
	display: 'inline-flex',
	verticalAlign: 'middle',
});
