import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	FOCUS_AREA_FILTER_ID,
	type FocusAreaFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { FocusAreasByARI } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-focus-areas/types.tsx';
import { getFocusAreasById } from '../../issue-focus-areas/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';
import { applyFilter as applyAttributeFilter } from '../attribute-filter/index.tsx';
import { isFocusConnectedWithReadPermissions } from '../../system/index.tsx';

export const applyFilter = (
	issue: Issue,
	filter: FocusAreaFilter,
	optimizedMode: boolean,
): boolean => {
	if (fg('focus_area_in_plans')) {
		return applyAttributeFilter(issue, filter.value, 'focusAreas', optimizedMode);
	}
	return true;
};

export const getAppliedFocusAreaFilter = (state: State): FocusAreaFilter => {
	const focusConnectedWithReadPermissions = isFocusConnectedWithReadPermissions(state);
	if (!fg('focus_area_in_plans') || !focusConnectedWithReadPermissions) {
		return {
			id: FOCUS_AREA_FILTER_ID,
			value: [],
		};
	}
	return (
		getFiltersViewSettings(state)[FOCUS_AREA_FILTER_ID] || {
			id: FOCUS_AREA_FILTER_ID,
			value: [],
		}
	);
};

export const getFocusAreaFilterPure = (
	issueFocusById: FocusAreasByARI,
	pureFilter: FocusAreaFilter,
	focusConnectedWithReadPermissions: boolean,
): FocusAreaFilter => {
	if (!fg('focus_area_in_plans') || !focusConnectedWithReadPermissions) {
		return {
			id: FOCUS_AREA_FILTER_ID,
			value: [],
		};
	}

	const { value = [] } = pureFilter;
	const filteredFocusAreas = value.filter((focusId: string) => !!issueFocusById[focusId]);
	return {
		id: FOCUS_AREA_FILTER_ID,
		value: filteredFocusAreas,
	};
};

export const getFocusAreaFilter = createSelector(
	[getFocusAreasById, getAppliedFocusAreaFilter, isFocusConnectedWithReadPermissions],
	getFocusAreaFilterPure,
);
