import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DELIVERY_DATE_AGGREGATION_TYPES } from '@atlassian/jira-polaris-domain-field/src/delivery/types.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { ConfigurationForFields, IdeaDateAggregation, TmpIds } from './types.tsx';

export const extractFieldsForOveriddenDates = (fieldsRaw: FieldMap, fieldKeys: string[]) => {
	const fieldsForOverridenDates: ConfigurationForFields = {};

	for (const key of fieldKeys) {
		const configuration = fieldsRaw[key].configuration;
		if (
			configuration &&
			configuration.aggregationType === DELIVERY_DATE_AGGREGATION_TYPES.LATEST &&
			configuration.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE
		) {
			fieldsForOverridenDates[key] = {
				aggregationType: configuration.aggregationType,
				fieldKey: configuration.customfieldKey,
			};
		}
	}

	return fieldsForOverridenDates;
};

const createTmpId = (aggregationType: string, fieldKey?: string) => aggregationType + fieldKey;

export const createTmpIds = (data: ConfigurationForFields) => {
	const customFieldKeys = Object.keys(data);
	const tmpIds: TmpIds = {};

	customFieldKeys.forEach((key) => {
		const tmpId = createTmpId(data[key].aggregationType, data[key].fieldKey);
		tmpIds[tmpId] = [...(tmpIds[tmpId] || []), key];
	});
	return tmpIds;
};

export const extractConfigurationForFields = (data: ConfigurationForFields) =>
	Object.keys(data).map((key) => ({
		aggregationType: data[key].aggregationType,
		fieldKey: data[key].fieldKey,
	}));

export const mapResponseToTheCustomField = (
	tmpIds: TmpIds,
	response: Array<IdeaDateAggregation>,
) => {
	const customFieldWithValue: { [key: string]: IdeaDateAggregation } = {};

	response.forEach((data) => {
		const tmpId = createTmpId(data.aggregationType, data.fieldKey);
		const customFieldsKeys = tmpIds[tmpId];

		customFieldsKeys.forEach((key) => {
			customFieldWithValue[key] = data;
		});
	});

	return customFieldWithValue;
};
