/* eslint-disable jira/react/no-style-attribute */
import React, { type Ref, forwardRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { CellProvider } from '../../controllers/cell/index.tsx';
import { useColumnStickyOffset } from '../../controllers/grid/index.tsx';
import type { Props } from './types.tsx';

export const Cell = forwardRef(
	(
		{ column, colSpan, style, top, xcss: xcssStyles, ...restProps }: Props,
		ref: Ref<HTMLTableCellElement>,
	) => {
		const [left] = useColumnStickyOffset({ column, preview: true });

		const isSticky = top !== undefined || left !== undefined;

		return (
			<CellProvider column={column} colSpan={colSpan ?? 1}>
				<Box
					as="td"
					ref={ref}
					colSpan={colSpan}
					xcss={[
						cellStyles,
						isSticky && stickyStyles,
						...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles]),
					]}
					style={{
						top,
						left,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					// This spreading is to allow the consumer to customize the tr attributes
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...restProps}
				/>
			</CellProvider>
		);
	},
);

const cellStyles = xcss({
	margin: 'space.0',
	padding: 'space.0',
	position: 'relative',
});

const stickyStyles = xcss({
	position: 'sticky',
});
