/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import AvatarGroup from '@atlaskit/avatar-group';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import ExportUserPicker from '@atlassian/jira-portfolio-3-common/src/avatar-initials/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const UserCell = ({ fieldKey, values, groupName, groupByLabel, swimlane, isExportMode }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={containerStyles}
			paddingInline="space.100"
			testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.user-cell.${groupName}.${fieldKey}`}
			aria-label={formatMessage(messages.ariaLabel, {
				groupByLabel,
				swimlane,
			})}
		>
			{isExportMode ? (
				<ul css={userListWrapperStyle}>
					{values.map(
						(value, i) =>
							value.personId !== 'unassigned' && (
								<li
									key={value.personId}
									css={userListItemStyle}
									style={{ zIndex: values.length - i }}
								>
									<ExportUserPicker name={value.jiraUser.title} />
								</li>
							),
					)}
				</ul>
			) : (
				<AvatarGroup
					size="small"
					data={values.map((value) => ({
						key: value.personId,
						src: value.jiraUser.avatarUrl,
						name: value.jiraUser.title,
					}))}
				/>
			)}
		</Box>
	);
};

export default UserCell;

const containerStyles = xcss({
	background: 'none',
	height: '100%',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	':focus': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, space.negative.025 does not map to token
		outlineOffset: token('space.negative.025'),
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, space.negative.025 does not map to token
		outlineOffset: token('space.negative.025'),
	},
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
});

const userListWrapperStyle = css({
	marginTop: token('space.0'),
	marginRight: token('space.0'),
	marginBottom: token('space.0'),
	marginLeft: token('space.0'),
	paddingTop: token('space.0'),
	paddingRight: token('space.0'),
	paddingBottom: token('space.0'),
	paddingLeft: token('space.0'),
	display: 'flex',
});

const userListItemStyle = css({
	marginInlineStart: token('space.0'),
	marginInlineEnd: token('space.negative.100'),
	marginBlockStart: token('space.0'),
	marginBlockEnd: token('space.0'),
	paddingTop: token('space.0'),
	paddingRight: token('space.0'),
	paddingBottom: token('space.0'),
	paddingLeft: token('space.0'),
	border: `2px solid ${token('color.border.inverse')}`,
	borderRadius: '100%',
	listStyleType: 'none',
});
