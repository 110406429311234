import React, { useState } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { DialogMenuContainer } from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import {
	UNASSIGNED_GROUP,
	groupSortComparator,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import MenuItem from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/add-issue/menu-item/view.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import { projectToGroupMapComparator } from './utils.tsx';

export default function ReleaseDialogContent({
	groupOptions,
	onGroupClick,
	onQueryChange,
	hierarchyItem,
	projectsById,
}: Props) {
	const [searchQuery, setSearchQuery] = useState('');
	const { formatMessage } = useIntl();

	const filterGroupsWithSearchQuery = (group: GroupOption) => {
		const groupName = group.groupName.length ? group.groupName : UNASSIGNED_GROUP;

		return groupName.toLowerCase().includes(searchQuery.toLowerCase());
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const searchOptions = (searchQuery: string) => {
		setSearchQuery(searchQuery);
		if (onQueryChange) {
			onQueryChange(searchQuery);
		}
	};

	// Sort by project id, with null at the top so unassigned is always first
	const groupOptionsEntries = groupOptions.sort(projectToGroupMapComparator);

	const renderGroupOptions = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
		const groupOptions = groupOptionsEntries.map(([projectId, groups]: [any, any]) => {
			const groupsToDisplay = groups.filter(filterGroupsWithSearchQuery).sort(groupSortComparator);
			if (groupsToDisplay.length > 0) {
				return (
					<DropMenuItemGroup
						key={projectId}
						title={
							projectId !== null
								? projectsById[projectId.toString()].name
								: formatMessage(commonMessages.unassigned)
						}
					>
						{groupsToDisplay.map((group: GroupOption, i: number) => {
							const { groupName, isDisabled, formattedTitle, group: groupKey } = group;
							return (
								<MenuItem
									menuItem={hierarchyItem}
									key={`${hierarchyItem.id}-${groupKey}-${i}`}
									isDisabled={isDisabled}
									onMenuItemClick={(analyticsEvent: UIAnalyticsEvent) =>
										onGroupClick(group, analyticsEvent)
									}
								>
									<div
										data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.dialog-content.${groupKey}`}
									>
										{formattedTitle || groupName}
									</div>
								</MenuItem>
							);
						})}
					</DropMenuItemGroup>
				);
			}
			return null;
		});

		if (groupOptions.every((el) => el === null)) {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles['no-matches']}>
					<FormattedMessage {...commonMessages.noMatchFoundText} />
				</div>
			);
		}
		return groupOptions;
	};

	return (
		<DialogMenuContainer>
			<SearchField
				placeholder={formatMessage(messages.releaseSearchPlaceholder)}
				searchQuery={searchQuery}
				onQueryChange={searchOptions}
			/>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles['inline-dialog-child']}>{renderGroupOptions()}</div>
		</DialogMenuContainer>
	);
}
