import React, { memo } from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import AllIssuesSelect from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/all-issues-select/index.tsx';
import type { OnOptionSelectHandler } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issue/types.tsx';
import messages from './messages.tsx';
import SelectedItems from './selected-items/view.tsx';
import DeselectSelectedItems from './deselect-selected-items/view.tsx';

const BulkActionsMenu = memo(
	({
		dropMenuItems,
		moreDropMenuItems,
		onOptionSelect,
		isNestedMenu,
	}: {
		dropMenuItems: React.JSX.Element[];
		moreDropMenuItems?: React.JSX.Element[];
		onOptionSelect?: OnOptionSelectHandler;
		isNestedMenu?: boolean;
	}) => {
		const { formatMessage } = useIntl();
		return fg('plan-timeline-a11y-meatball-menu') ? (
			<AllIssuesSelect>
				{({ totalSelected, deselectAllSelected }) => (
					<div
						aria-label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.selectedIssueTermRefresh
								: messages.selected,
							{ count: totalSelected },
						)}
						data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions-menu.menu-container"
					>
						<DropdownItemGroup>
							<SelectedItems selectedIssueCount={totalSelected} />
							<DeselectSelectedItems
								deselectAllSelected={deselectAllSelected}
								onOptionSelect={onOptionSelect}
								isNestedMenu={!!isNestedMenu}
							/>
						</DropdownItemGroup>
						<DropdownItemGroup hasSeparator>{dropMenuItems}</DropdownItemGroup>
						{moreDropMenuItems?.map((moreDropMenuItem, i) => (
							<DropdownItemGroup key={i} hasSeparator>
								{moreDropMenuItem}
							</DropdownItemGroup>
						))}
					</div>
				)}
			</AllIssuesSelect>
		) : (
			<div data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions-menu.menu-container">
				<DropdownItemGroup title={formatMessage(messages.setFieldGroupTitle)}>
					{dropMenuItems}
				</DropdownItemGroup>
				{moreDropMenuItems && (
					<DropdownItemGroup title={formatMessage(messages.moreActionsGroupTitle)}>
						{moreDropMenuItems}
					</DropdownItemGroup>
				)}
			</div>
		);
	},
);

export default BulkActionsMenu;
