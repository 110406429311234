// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { CttSpotlights } from '../ctt-spotlights/index.tsx';
import {
	CttOnboardingStage,
	type CttOnboardingStageType,
	type State,
	type WithSilent,
} from './types.tsx';
import { update } from './utils.tsx';

const cttOnboardingActions = {
	initCttOnboarding:
		(planId: number | null): Action<State> =>
		async ({ getState, setState }) => {
			await getState()._rehydratePromise;

			const { cttOnboarding } = getState();

			if (planId === null) return;

			if (!cttOnboarding) {
				setState({
					cttOnboarding: { stage: CttOnboardingStage.CREATE_INITATIVE, planId },
				});
				return;
			}

			if (
				cttOnboarding.planId !== planId &&
				cttOnboarding.stage !== CttOnboardingStage.CLOSED &&
				cttOnboarding.stage !== CttOnboardingStage.FINISH_FLAG
			) {
				setState({
					cttOnboarding: { stage: CttOnboardingStage.CREATE_INITATIVE, planId },
				});
			}
		},

	setCttTakingTour:
		(): Action<State> =>
		({ setState, getState }) => {
			const { cttOnboarding: currentCttOnboarding } = getState();
			if (!currentCttOnboarding) return;
			setState({
				cttOnboarding: {
					...currentCttOnboarding,
				},
				isTakingOnboardingTour: true,
			});
		},
	resetCttTakingTour:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isTakingOnboardingTour: false,
			});
		},

	nextCttStage:
		(skipTo?: CttOnboardingStageType): Action<State> =>
		({ setState, getState, dispatch }) => {
			const assertUnreachable = (_: never): never => {
				throw new Error("Didn't expect to get here");
			};

			const { cttOnboarding } = getState();

			if (!cttOnboarding) {
				return;
			}

			const { planId, stage } = cttOnboarding;

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const updateStage = (updateStage: CttOnboardingStageType, persist = true, ui = true) => {
				dispatch(
					update({ ui, persist })({
						cttOnboarding: { stage: updateStage, planId },
					}),
				);
			};

			if (skipTo) {
				Object.values(CttOnboardingStage).includes(skipTo) && updateStage(skipTo, true);
				return;
			}

			try {
				switch (stage) {
					case CttOnboardingStage.CREATE_INITATIVE:
						updateStage(CttOnboardingStage.INPUT_TITLE, false);
						break;
					case CttOnboardingStage.INPUT_TITLE:
						updateStage(CttOnboardingStage.NAVIGATE_ISSUE_SOURCES, false);
						break;
					case CttOnboardingStage.NAVIGATE_ISSUE_SOURCES:
						updateStage(CttOnboardingStage.SHOW_ISSUE_SOURCES, true);
						break;
					case CttOnboardingStage.SHOW_ISSUE_SOURCES:
						updateStage(CttOnboardingStage.NAVIGATE_PLAN, false);
						break;
					case CttOnboardingStage.NAVIGATE_PLAN:
						updateStage(CttOnboardingStage.EPIC_REPARENT, true);
						break;
					case CttOnboardingStage.EPIC_REPARENT:
						updateStage(CttOnboardingStage.INPUT_EPIC_REPARENT, false);
						break;
					case CttOnboardingStage.INPUT_EPIC_REPARENT:
						updateStage(CttOnboardingStage.REVIEW_CHANGES, true);
						break;
					case CttOnboardingStage.REVIEW_CHANGES:
						updateStage(CttOnboardingStage.FINISH_FLAG, true);
						updateStage(CttOnboardingStage.CLOSED, true, false);
						break;
					case CttOnboardingStage.FINISH_FLAG:
						updateStage(CttOnboardingStage.CLOSED, true);
						break;
					case CttOnboardingStage.CLOSED:
						break;
					default: {
						assertUnreachable(stage);
					}
				}
			} catch (e) {
				setState({
					cttOnboarding: {
						...cttOnboarding,
						stage: CttOnboardingStage.CLOSED,
					},
				});
			}
		},
	closeCttOnboarding:
		({ silent }: WithSilent<{}> = { silent: true }): Action<State> =>
		({ getState, dispatch }) => {
			const planId = getState().cttOnboarding?.planId || -1;
			dispatch(
				update({ ui: !silent, persist: true })({
					cttOnboarding: {
						stage: CttOnboardingStage.CLOSED,
						planId,
					},
					isTakingOnboardingTour: false,
				}),
			);
		},
	setSpotlight:
		(spotlight: CttSpotlights): Action<State> =>
		({ getState, dispatch }) => {
			const { cttOnboarding: currentCttOnboarding } = getState();
			if (!currentCttOnboarding) return;
			dispatch(
				update({ ui: true, persist: false })({
					spotlight,
				}),
			);
		},
	resetSpotlight:
		(): Action<State> =>
		({ getState, dispatch }) => {
			const { cttOnboarding: currentCttOnboarding } = getState();
			if (!currentCttOnboarding) return;
			dispatch(
				update({ ui: true, persist: false })({
					spotlight: undefined,
				}),
			);
		},
};

export const actions = {
	...cttOnboardingActions,
};
