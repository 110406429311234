import React from 'react';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { CardAction } from '@atlaskit/smart-card';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import { FocusAreaStatusValues } from '@atlassian/jira-shared-types/src/focus-area.tsx';
import { FocusAreaIcon } from './icon/index.tsx';
import { FocusAreaTracker } from './tracker/index.tsx';
import type { FocusArea as FocusAreaType, FocusAreaProps, BasicFocusAreaProps } from './types.tsx';

export const FocusArea = ({
	name,
	status: { key, displayName } = {
		key: FocusAreaStatusValues.PENDING,
		displayName: 'Pending',
	},
	isExportMode,
}: FocusAreaProps) => (
	<Inline space="space.050" alignBlock="center" alignInline="start" xcss={focusAreaStyles}>
		<BasicFocusArea name={name} status={key} isExportMode={isExportMode} />
		<FocusAreaTracker status={key} displayName={displayName} isExportMode={isExportMode} />
	</Inline>
);

export const BasicFocusArea = ({
	name,
	status,
	displayName,
	isExportMode,
}: BasicFocusAreaProps) => (
	<>
		<FocusAreaIcon status={status} ariaLabel={displayName} />
		<Box as="span" xcss={!isExportMode ? focusAreaNameStyles : exportFocusAreaNameStyles}>
			{name}
		</Box>
	</>
);

export const FocusAreaHoverCard = ({
	focusArea,
	flexShrink,
	onClick,
	isExportMode,
}: {
	focusArea: FocusAreaType;
	flexShrink: number;
	onClick?: () => void;
	isExportMode: boolean;
}) => {
	const { id, name, status, url } = focusArea;
	if (isExportMode) {
		return (
			// eslint-disable-next-line jira/react/no-style-attribute
			<Box key={id} xcss={outerWrapperStyles} style={{ flexShrink }}>
				<Box xcss={innerWrapperStyles}>
					<FocusArea name={name} status={status} isExportMode={isExportMode} />
				</Box>
			</Box>
		);
	}
	return (
		// eslint-disable-next-line jira/react/no-style-attribute
		<Box key={id} xcss={outerWrapperStyles} style={{ flexShrink }}>
			{url !== undefined ? (
				<HoverCard
					url={url ?? ''}
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
				>
					<Box xcss={innerWrapperStyles} onClick={onClick}>
						<FocusArea name={name} status={status} isExportMode={isExportMode} />
					</Box>
				</HoverCard>
			) : (
				<Box xcss={innerWrapperStyles}>
					<FocusArea name={name} status={status} isExportMode={isExportMode} />
				</Box>
			)}
		</Box>
	);
};

const outerWrapperStyles = xcss({
	display: 'inline',
	minWidth: '38px',
});

const innerWrapperStyles = xcss({
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius',
	backgroundColor: 'elevation.surface',
	height: '32px',
	marginLeft: 'space.025',
	marginRight: 'space.025',
});

const focusAreaStyles = xcss({
	height: '30px',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	padding: 'space.050',
});

const focusAreaNameStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});

const exportFocusAreaNameStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'clip',
	overflow: 'hidden',
});
