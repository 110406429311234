/** @jsx jsx */
import React, { useMemo, createContext, type ReactNode, useContext } from 'react';
import { css, jsx } from '@compiled/react';
import { Arrows } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/arrows/index.tsx';
import {
	useScrollX,
	useIsScrollingX,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import {
	useColumnWidth,
	useIsResizing,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import type { Props } from './types.tsx';
import { useTimelineViewportWidth } from './utils.tsx';

const context = createContext({
	left: 0,
	width: 0,
	visible: true,
});

export const TimelineArrowsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [scrollX] = useScrollX();
	const [isScrollingX] = useIsScrollingX();
	const [isResizing] = useIsResizing();
	const [{ column }] = useCell();
	const [columnWidth] = useColumnWidth({ column, preview: false });
	const [width] = useTimelineViewportWidth(columnWidth ?? 0);
	const visible = !isScrollingX && !isResizing;

	const value = useMemo(() => ({ left: scrollX, width, visible }), [scrollX, width, visible]);

	return <context.Provider value={value}>{children}</context.Provider>;
};

export const TimelineArrows: React.FC<Props> = ({ issue }) => {
	const { left, width, visible } = useContext(context);

	if (!visible) {
		return null;
	}

	return (
		<div
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.body.timeline.arrows"
			css={containerStyles}
			style={{ left, width }}
		>
			<Arrows width={width} issue={issue} />
		</div>
	);
};

const containerStyles = css({
	position: 'absolute',
});
