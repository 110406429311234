import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Badge from '@atlaskit/badge';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export default function SelectedItems({ selectedIssueCount }: Props) {
	return (
		<Box testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions-menu.selected-items.box">
			<Box xcss={bulkIndicatorContainer}>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.selectedIssueTermRefresh
						: messages.selected)}
					values={{
						count: selectedIssueCount,
						badge: (chunks: ReactNode[]) => <Badge appearance="primary">{chunks}</Badge>,
					}}
				/>
			</Box>
		</Box>
	);
}

const bulkIndicatorContainer = xcss({
	display: 'flex',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	alignItems: 'center',
	height: token('space.400'),
	color: 'color.text.subtle',
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	gap: 'space.100',
});
