import React, { useState, useCallback, useMemo } from 'react';
import { xcss, Pressable, Box } from '@atlaskit/primitives';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { fg } from '@atlassian/jira-feature-gating';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import IdeaIssue from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ideas-cell/idea-issue/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '../common/ui/messages.tsx';
import FlyoutContent from '../common/ui/flyout-content/index.tsx';
import FieldBase from '../common/ui/index.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const IdeasCell = ({
	fieldKey,
	values,
	groupName,
	totalIssues,
	unassignedCount,
	groupByLabel,
	swimlane,
	loading,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { formatMessage } = useIntl();
	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const label = useMemo(
		() =>
			fg('jira-issue-terminology-refresh-m3')
				? commonMessages.workItemCountWithTotalLabel
				: commonMessages.issueCountWithTotalLabel,
		[],
	);

	const content = useMemo(
		() =>
			values.map(({ id, count, data }) => ({
				// When cleaning up polaris-pinned-fields-for-ideas-in-plans, remove the default empty string from projectId, as it will be defined
				title: <IdeaIssue data={data} key={id} flexShrink={1} projectId={data.projectId || ''} />,
				count,
			})),
		[values],
	);

	const renderContent = useCallback(() => {
		return (
			<FlyoutContent
				fieldName={commonMessages.ideasTitle}
				label={label}
				content={content}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.ideas-cell.ideas-flyout"
				issuesWithoutField={unassignedCount}
				total={totalIssues}
				showTotal={false}
				ariaLabel={formatMessage(messages.ariaLabel, {
					count: content.length,
					groupByLabel,
					swimlane,
				})}
			/>
		);
	}, [content, label, totalIssues, unassignedCount, groupByLabel, swimlane, formatMessage]);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<FieldBase
				component={Pressable}
				onClick={onToggle}
				triggerProps={triggerProps}
				tabIndex={0}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.ideas-cell.${groupName}.${fieldKey}`}
				message={messages.numberOfIdeas}
				messageValues={{ count: values.length }}
				fieldKey={fieldKey}
			/>
		),
		[onToggle, values.length, groupName, fieldKey],
	);

	if (loading) {
		return (
			<Box xcss={skeletonStyles}>
				<CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />
			</Box>
		);
	}

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default IdeasCell;

const skeletonStyles = xcss({
	width: '100%',
	height: '100%',
	paddingInline: 'space.100',
	paddingBlock: 'space.075',
});
