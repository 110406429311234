import {
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { RankAnchors } from './types.tsx';

export const getRankAnchors = ({
	issueRow,
	issueId,
	issueGroup,
	issueLevel,
	tableItems,
	sortedIssues,
}: {
	issueRow: number;
	issueId: string;
	issueGroup?: string;
	issueLevel: number | undefined;
	tableItems: TableItem[] | undefined;
	sortedIssues: Issue[] | undefined;
}) => {
	const anchors: RankAnchors = {};

	// Finds the index of the current issue in tableItems.
	// Checks both issueId and the issueGroup, as the same issue can be present in multiple groups.
	const currentIssueIndex = tableItems?.findIndex(
		(tableItem) =>
			tableItem.tag === TABLE_ISSUE &&
			tableItem.value.id === issueId &&
			(tableItem.group === issueGroup || (!issueGroup && !tableItem.group)),
	);

	// rowOffset is the difference between the actual row count of the issue in the table and the same
	// issue's position in the tableItems array. TableItems does not include the header rows.
	const rowOffset = issueRow - (currentIssueIndex ?? 0);

	// If issue is not rendered, how is the meatball menu open?
	if (!tableItems || !sortedIssues || currentIssueIndex === undefined || currentIssueIndex === -1) {
		return { anchors, currentIssueIndex: -1 };
	}

	const getIssueIdOfSameLevel = (
		tableItem: TableItem,
	): { id: string; shouldSkip: boolean } | null => {
		let shouldSkip = false;

		// If tableItem is not an issue, we have hit a group boundary or header
		if (tableItem.tag !== TABLE_ISSUE) {
			return null;
		}

		// If the tableItem issue level is greater than current issue level, we have changed hierarchy level upwards
		if (issueLevel === undefined || tableItem.value.level > issueLevel) {
			return null;
		}

		// If tableItem issue level is lower than current issue, skip item
		if (tableItem.value.level < issueLevel) {
			shouldSkip = true;
		}

		return { id: tableItem.value.id, shouldSkip };
	};

	const currentIssue = { id: issueId, rowIndex: currentIssueIndex };

	// Find top issue in group/hierarchy level
	// Use index and traverse backwards through tableItems until a non-issue row or different issue level is reached
	// If the top issue in that level is the same as the current issue, remove move up options
	let topIssueIndex = -1;
	let aboveIssue = currentIssue;
	let hasUpdatedFirstValidIssue = false;
	for (let i = currentIssueIndex - 1; i >= 0; i--) {
		const issue = getIssueIdOfSameLevel(tableItems[i]);

		if (!issue) {
			break;
		}

		if (issue && !issue.shouldSkip) {
			const validIssue = { id: issue.id, rowIndex: i };
			topIssueIndex = validIssue.rowIndex;

			if (!hasUpdatedFirstValidIssue) {
				aboveIssue = validIssue;
				hasUpdatedFirstValidIssue = true;
			}
		}
	}

	// Find bottom issue in group/hierarchy level
	// Use index and traverse forwards through tableItems until a non-issue row or different issue level is reached
	// If the top issue in that level is the same as the current issue, remove move down options
	let bottomIssueIndex = -1;
	let belowIssue = currentIssue;
	hasUpdatedFirstValidIssue = false;
	for (let i = currentIssueIndex + 1; i < tableItems.length; i++) {
		const issue = getIssueIdOfSameLevel(tableItems[i]);

		if (!issue) {
			break;
		}

		if (issue && !issue.shouldSkip) {
			const validIssue = { id: issue.id, rowIndex: i };
			bottomIssueIndex = validIssue.rowIndex;

			if (!hasUpdatedFirstValidIssue) {
				belowIssue = validIssue;
				hasUpdatedFirstValidIssue = true;
			}
		}
	}

	// Add anchors if bottom/top issue ids do not match current issue id
	// Use presence of anchors to show/hide the respective actions
	if (aboveIssue.id !== issueId) {
		// Apply rowOffset to each row index to account for differences between
		// tableItems and visually rendered row indexes.
		anchors.top = { rowIndex: topIssueIndex + rowOffset, id: sortedIssues[0].id };
		anchors.above = { ...aboveIssue, rowIndex: aboveIssue.rowIndex + rowOffset };
	}

	if (belowIssue.id !== issueId) {
		anchors.bottom = {
			rowIndex: bottomIssueIndex + rowOffset,
			id: sortedIssues[sortedIssues.length - 1].id,
		};
		anchors.below = { ...belowIssue, rowIndex: belowIssue.rowIndex + rowOffset };
	}

	return { anchors };
};
