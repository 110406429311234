import type { ReactNode } from 'react';

export type Option<Value> = {
	id: string;
	name: string;
	value?: Value;
	description?: string;
	url?: string;
	urlType?: 'avatar' | 'icon';
	isDisabled?: boolean;
	disabledTooltip?: string;
	elemAfterLabel?: ReactNode;
};

export type OptionGroup<Value> = {
	id: string;
	options: Option<Value>[];
	name?: string;
	sortValue?: number;
};

export const GROUPED = 'GROUPED';
export const UNGROUPED = 'UNGROUPED';

type GroupedOptions<Value> = {
	type: typeof GROUPED;
	groups: OptionGroup<Value>[];
};

type UngroupedOptions<Value> = {
	type: typeof UNGROUPED;
	options: Option<Value>[];
};

export type Options<Value> = GroupedOptions<Value> | UngroupedOptions<Value>;

export type OptionsProviderResult<Value> = {
	options: Options<Value>;
	menuTitle: string;
	searchPlaceholder: string;
	onQueryChange?: (query: string) => void;
	isLoading?: boolean;
};

export type OptionsProviderProps<Value> = {
	children: (result: OptionsProviderResult<Value>) => ReactNode;
};
