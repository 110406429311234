import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import CloseIcon from '@atlaskit/icon/core/close';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import CelebrateIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/success/components/celebrate/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import HelpPanelButton from '../help-panel-button/index.tsx';
import messages from './messages.tsx';

type Props = {
	handleCloseDialog: (
		e: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
};

const EmptyDialog = ({ handleCloseDialog }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<FireScreenAnalytics attributes={{ changesCount: 0 }} />
			<ShortcutScope>
				<ModalDialog
					messageId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.modal-dialog"
					messageType="transactional"
					autoFocus
					shouldReturnFocus
					shouldCloseOnEscapePress
					onClose={handleCloseDialog}
					shouldCloseOnOverlayClick={false}
				>
					<Box xcss={relativeStyles}>
						<Box xcss={closeIconAbsoluteStyles}>
							<IconButton
								appearance="subtle"
								label={formatMessage(messages.closeIconLabel)}
								spacing="compact"
								icon={CloseIcon}
								onClick={handleCloseDialog}
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.selected-field-remove-button"
							/>
						</Box>
					</Box>
					<EmptyState
						header={formatMessage(messages.noChangesDialogHeader)}
						description={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.noChangesDialogDescriptionIssueTermRefresh
								: messages.noChangesDialogDescription,
						)}
						primaryAction={
							<HelpPanelButton onClick={handleCloseDialog} articleId="5jV03VyuxP6cfYQwcKWxWO">
								<Text color="color.link">
									{formatMessage(messages.noChangesDialogActionButtonText)}
								</Text>
							</HelpPanelButton>
						}
						renderImage={() => (
							<Box xcss={emptyImageContainerStyles}>
								<Box xcss={emptyImageStyles}>
									<CelebrateIllustration alt="" />
								</Box>
							</Box>
						)}
					/>
				</ModalDialog>
			</ShortcutScope>
		</>
	);
};

const relativeStyles = xcss({
	position: 'relative',
});

const closeIconAbsoluteStyles = xcss({
	position: 'absolute',
	top: 'space.250',
	right: 'space.250',
});

const emptyImageContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: 'space.400',
});

const emptyImageStyles = xcss({ width: '160px' });

export default EmptyDialog;
