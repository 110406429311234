import type { Effect } from 'redux-saga';
import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { DATEFIELD_TYPES } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	type IssueMap,
	getIssueMapById,
	getConfiguredDateNew,
} from '../../query/raw-issues/index.tsx';
import type { DateField } from '../../state/domain/plan/types.tsx';
import {
	narrowToBuiltInDateFields,
	type Issue,
	type IssueRollups,
} from '../../state/domain/issues/types.tsx';
import { getDateConfiguration } from '../../query/plan/index.tsx';
import { updateRollups } from '../../state/domain/issues/actions.tsx';
import { getChildrenIdsByParent } from '../../query/issues/index.tsx';
import { areArraysEqual, mergeChangesMaps } from './utils.tsx';

export const BUBBLE_ROLLUPS_TO_ANCESTORS = 'command.issue-rollups.BUBBLE_ROLLUPS' as const;

export type BubbleRollupsPayload = {
	issue: Issue;
	updatedValues: Partial<Issue>;
	originalValues: Partial<Issue>;
};

export type BubbleRollupsAction = {
	type: typeof BUBBLE_ROLLUPS_TO_ANCESTORS;
	payload: BubbleRollupsPayload;
};

export const bubbleRollupChangesToAncestors = (payload: BubbleRollupsPayload) => ({
	type: BUBBLE_ROLLUPS_TO_ANCESTORS,
	payload,
});

const setDateFieldRollup = (
	dateField: DateField,
	issueRollups: Partial<IssueRollups>,
	value: number | undefined,
): Partial<IssueRollups> => {
	const { type, key } = dateField;
	if (type === DATEFIELD_TYPES.BUILT_IN && narrowToBuiltInDateFields(key)) {
		return { ...issueRollups, [key]: value };
	}
	if (type === DATEFIELD_TYPES.CUSTOM) {
		if (value !== undefined) {
			return {
				...issueRollups,
				customFields: { ...issueRollups.customFields, [Number(key)]: value },
			};
		}
		const { [Number(key)]: _, ...customFields } = issueRollups.customFields ?? {};
		return {
			...issueRollups,
			customFields,
		};
	}
	return issueRollups;
};

export function bubbleSetRollups(
	issue: Issue,
	childrenIdsByParent: Record<string, string[]>,
	issuesById: IssueMap,
	field: 'team' | 'sprint' | 'fixVersions',
	rollupField: 'teams' | 'sprints' | 'fixVersionIds',
): Map<string, Partial<IssueRollups>> {
	const changesById = new Map<string, Partial<IssueRollups>>();

	let childChanged = true;
	let parent = issue.parent ? issuesById[issue.parent] : undefined;

	// loop through children of parent, recalculate rollups for it
	while (childChanged && isDefined(parent)) {
		const existingParentRollup = parent.rollups?.[rollupField] ?? [];
		const newParentRollup: string[] = [];
		childChanged = false;
		childrenIdsByParent[parent.id]?.forEach((id) => {
			const child = issuesById[id];
			if (isDefined(child)) {
				// fixVersions handled differently since it is a list on the issue
				if (field === 'fixVersions') {
					(child.fixVersions ?? []).forEach((versionId) => {
						if (!newParentRollup.includes(versionId)) {
							newParentRollup.push(versionId);
						}
					});
				} else {
					const childField = child[field];
					if (isDefined(childField) && !newParentRollup.includes(childField)) {
						newParentRollup.push(childField);
					}
				}

				(child.rollups?.[rollupField] ?? []).forEach((sprint) => {
					if (!newParentRollup.includes(sprint)) {
						newParentRollup.push(sprint);
					}
				});
			}
		});

		// update if there has been a change, and continue bubbling
		if (!areArraysEqual(newParentRollup, existingParentRollup)) {
			changesById.set(parent.id, { [rollupField]: newParentRollup });

			// update local issueById to allow use of the bubbled value in next iteration
			// eslint-disable-next-line no-param-reassign
			issuesById[parent.id] = {
				...parent,
				rollups: {
					...parent.rollups,
					[rollupField]: newParentRollup,
				},
			};
			// move up to the next parent if one exists
			parent = parent.parent ? issuesById[parent.parent] : undefined;
			childChanged = true;
		}
	}

	return changesById;
}

export function bubbleEstimateRollups(
	issue: Issue,
	childrenIdsByParent: Record<string, string[]>,
	issuesById: IssueMap,
	field: 'timeEstimate' | 'storyPoints',
): Map<string, Partial<IssueRollups>> {
	const changesById = new Map<string, Partial<IssueRollups>>();
	let childChanged = true;
	let parent = issue.parent ? issuesById[issue.parent] : undefined;
	while (childChanged && isDefined(parent)) {
		childChanged = false;
		let newEstimate: number | undefined;
		childrenIdsByParent[parent.id]?.forEach((id) => {
			const child = issuesById[id];
			if (isDefined(child)) {
				const childField = child[field];
				const childRollup = child.rollups?.[field];
				if (isDefined(childField)) {
					newEstimate = (newEstimate ?? 0) + childField;
				}
				if (isDefined(childRollup)) {
					newEstimate = (newEstimate ?? 0) + childRollup;
				}
			}
		});

		// update if there has been a change, and continue bubbling
		if (newEstimate !== parent.rollups?.[field]) {
			changesById.set(parent.id, { [field]: newEstimate });

			// update local issueById to allow use of the bubbled value in next iteration
			// eslint-disable-next-line no-param-reassign
			issuesById[parent.id] = {
				...parent,
				rollups: {
					...parent.rollups,
					[field]: newEstimate,
				},
			};
			// move up to the next parent if one exists
			parent = parent.parent ? issuesById[parent.parent] : undefined;
			childChanged = true;
		}
	}
	return changesById;
}

export function bubbleConfiguredDateFieldRollups(
	issue: Issue,
	childrenIdsByParent: Record<string, string[]>,
	issuesById: IssueMap,
	field: 'baselineStart' | 'baselineEnd',
	configuredField: DateField,
): Map<string, Partial<IssueRollups>> {
	const changesById = new Map<string, Partial<IssueRollups>>();
	let childChanged = true;
	let parent = issue.parent ? issuesById[issue.parent] : undefined;
	while (childChanged && isDefined(parent)) {
		childChanged = false;
		let newDateField: number = field === 'baselineStart' ? Infinity : -Infinity;

		childrenIdsByParent[parent.id]?.forEach((id) => {
			const childField = issuesById[id]?.[field];
			if (field === 'baselineStart') {
				if (isDefined(childField) && childField < newDateField) {
					newDateField = childField;
				}
			} else if (isDefined(childField) && childField > newDateField) {
				newDateField = childField;
			}
		});

		const newRollup = isFinite(newDateField) ? newDateField : undefined;
		// update if there has been a change, and continue bubbling
		if (newRollup !== getConfiguredDateNew(configuredField, parent.rollups ?? {})) {
			changesById.set(parent.id, setDateFieldRollup(configuredField, {}, newRollup));
			// update local issueById to allow use of the bubbled value in next iteration
			// eslint-disable-next-line no-param-reassign
			issuesById[parent.id] = {
				...parent,
				[field]: newRollup,
			};
			// move up to the next parent if one exists
			parent = parent.parent ? issuesById[parent.parent] : undefined;
			childChanged = true;
		}
	}
	return changesById;
}

// When an issue is updated in the UI, this function checks whether the issue update changes the rollup of it's direct ancestors
// by calculating the new rollup from the issue and siblings, and continuing to higher levels if changes are made at the level below
export function* doBubbleRollupChangesToAncestors({
	payload: { issue, updatedValues, originalValues },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: BubbleRollupsAction): Generator<Effect, any, any> {
	const issuesById: IssueMap = yield select(getIssueMapById);
	const childrenIdsByParent: Record<string, string[]> = yield select(getChildrenIdsByParent);
	const changesById = new Map<string, Partial<IssueRollups>>();

	if (isDefined(updatedValues.timeEstimate) || isDefined(originalValues.timeEstimate)) {
		const newChanges = bubbleEstimateRollups(
			issue,
			childrenIdsByParent,
			issuesById,
			'timeEstimate',
		);
		mergeChangesMaps(changesById, newChanges);
	}

	if (isDefined(updatedValues.storyPoints) || isDefined(originalValues.storyPoints)) {
		const newChanges = bubbleEstimateRollups(issue, childrenIdsByParent, issuesById, 'storyPoints');
		mergeChangesMaps(changesById, newChanges);
	}

	if (isDefined(updatedValues.team) || isDefined(originalValues.team)) {
		const newChanges = bubbleSetRollups(issue, childrenIdsByParent, issuesById, 'team', 'teams');
		mergeChangesMaps(changesById, newChanges);
	}

	if (isDefined(updatedValues.sprint) || isDefined(originalValues.sprint)) {
		const newChanges = bubbleSetRollups(
			issue,
			childrenIdsByParent,
			issuesById,
			'sprint',
			'sprints',
		);
		mergeChangesMaps(changesById, newChanges);
	}

	if (isDefined(updatedValues.fixVersions) || isDefined(originalValues.fixVersions)) {
		const newChanges = bubbleSetRollups(
			issue,
			childrenIdsByParent,
			issuesById,
			'fixVersions',
			'fixVersionIds',
		);
		mergeChangesMaps(changesById, newChanges);
	}

	const { baselineStartField, baselineEndField } = yield select(getDateConfiguration);
	if (
		isDefined(getConfiguredDateNew(baselineStartField, updatedValues)) ||
		isDefined(getConfiguredDateNew(baselineStartField, originalValues))
	) {
		const newChanges = bubbleConfiguredDateFieldRollups(
			issue,
			childrenIdsByParent,
			issuesById,
			'baselineStart',
			baselineStartField,
		);
		mergeChangesMaps(changesById, newChanges);
	}

	if (
		isDefined(getConfiguredDateNew(baselineEndField, updatedValues)) ||
		isDefined(getConfiguredDateNew(baselineEndField, originalValues))
	) {
		const newChanges = bubbleConfiguredDateFieldRollups(
			issue,
			childrenIdsByParent,
			issuesById,
			'baselineEnd',
			baselineEndField,
		);
		mergeChangesMaps(changesById, newChanges);
	}

	if (changesById.size > 0) {
		yield put(updateRollups(changesById));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* watchUpdateIssueRollups(): Generator<Effect, any, any> {
	yield takeEvery(BUBBLE_ROLLUPS_TO_ANCESTORS, doBubbleRollupChangesToAncestors);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* issueRollupSagas(): Generator<Effect, any, any> {
	yield fork(watchUpdateIssueRollups);
}
