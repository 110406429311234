import React, { useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuItem,
	DialogMenuContainer,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { GOAL_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { GoalIcon } from '@atlassian/jira-software-goals/src/ui/goal/icon/index.tsx';
import { GoalTracker } from '@atlassian/jira-software-goals/src/ui/goal/tracker/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH, ContentWrapper, ItemWrapper } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const GoalFilter = ({
	clearGoalsFilter,
	value,
	searchQuery,
	onOpenChange,
	isOpen,
	onQueryChange,
	changeGoalsFilter,
	issueGoalARIs,
	definedGoalsByARI,
}: Props) => {
	const { formatMessage } = useIntl();
	const triggerButtonRef = useRef<HTMLButtonElement>(null);
	const isGoalsSelected = !isEmpty(definedGoalsByARI) && value.length > 0;

	const onGoalClick = (id: string): void => {
		if (isGoalSelected(id)) {
			changeGoalsFilter(value.filter((goalId) => goalId !== id));
		} else {
			changeGoalsFilter([...value, id]);
		}
	};

	const constructFilterText = () => {
		const goalNames = value.flatMap((goalARI) => {
			const definedGoal = definedGoalsByARI[goalARI];
			return definedGoal.name;
		});
		const sortedGoalNames = goalNames.sort();
		return sortedGoalNames.join(', ');
	};

	const renderFilterText = () => {
		if (isGoalsSelected) {
			const filterText = constructFilterText();
			return <FilterText text={filterText} />;
		}
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	};

	const ariaText = () => {
		const filterText = isGoalsSelected
			? constructFilterText()
			: formatMessage(messages.emptyPlaceholder);

		return `${formatMessage(filterMessages[GOAL_FILTER_ID])}, ${filterText} ${formatMessage(
			filterMessages.selected,
		)}`;
	};

	const isGoalSelected = (goalARI: string) => value.includes(goalARI);

	const filterGoalsWithSearchQuery = (goalARI: string) => {
		const definedGoal = definedGoalsByARI[goalARI];
		// goalARI could refer to a goal that has been unlinked
		return definedGoal ? definedGoal.name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
	};
	const filteredGoalARIs = issueGoalARIs.filter(filterGoalsWithSearchQuery);
	if (fg('migrate_plan_filter_to_popup')) {
		return (
			<Popup
				placement="bottom-start"
				isOpen={isOpen}
				onClose={() => {
					onOpenChange({ isOpen: false });
					triggerButtonRef?.current?.focus();
				}}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter"
				trigger={({ ref: triggerRef, ...triggerPropsForButton }) => {
					const mergedRef = mergeRefs(triggerRef, triggerButtonRef);
					return (
						<TriggerButton
							{...triggerPropsForButton}
							ref={mergedRef}
							isOpen={isOpen}
							onOpenChange={onOpenChange}
							triggerButtonText={renderFilterText()}
							testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter.trigger-btn"
							ariaLabel={ariaText()}
						/>
					);
				}}
				content={({ setInitialFocusRef }) => (
					<ContentWrapper>
						<ClearFilterButton isVisible={isGoalsSelected} onClearClick={clearGoalsFilter} />
						<SearchField
							setInitialFocusRef={setInitialFocusRef}
							placeholder={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.searchGoalsPlaceholderIssueTermRefresh
									: messages.searchGoalsPlaceholder,
							)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
						/>
						{filteredGoalARIs.map((goalARI) => {
							const { name, state } = definedGoalsByARI[goalARI];
							return (
								<ItemWrapper key={goalARI}>
									<Checkbox
										id={`${goalARI}`}
										isChecked={isGoalSelected(goalARI)}
										onChange={() => onGoalClick(goalARI)}
										label={
											state !== undefined ? (
												<Box xcss={filterItemContentStyles}>
													<GoalIcon state={state.value} />
													<Inline xcss={goalNameWrapperStyles}>{name}</Inline>
													<GoalTracker
														label={state.label}
														value={state.value}
														score={state.score}
													/>
												</Box>
											) : (
												name
											)
										}
									/>
								</ItemWrapper>
							);
						})}
						{filteredGoalARIs.length === 0 && <NoMatchFound />}
					</ContentWrapper>
				)}
			/>
		);
	}
	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			content={
				<DialogMenuContainer>
					<ClearFilterButton isVisible={isGoalsSelected} onClearClick={clearGoalsFilter} />
					<SearchField
						placeholder={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchGoalsPlaceholderIssueTermRefresh
								: messages.searchGoalsPlaceholder,
						)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
					/>
					{filteredGoalARIs.map((goalARI) => {
						const { name, state } = definedGoalsByARI[goalARI];
						return (
							<DialogMenuItem key={goalARI}>
								<Checkbox
									id={`${goalARI}`}
									isChecked={isGoalSelected(goalARI)}
									onChange={() => onGoalClick(goalARI)}
									label={
										state !== undefined ? (
											<Box xcss={filterItemContentStyles}>
												<GoalIcon state={state.value} />
												<Inline xcss={goalNameWrapperStyles}>{name}</Inline>
												<GoalTracker label={state.label} value={state.value} score={state.score} />
											</Box>
										) : (
											name
										)
									}
								/>
							</DialogMenuItem>
						);
					})}
					{filteredGoalARIs.length === 0 && <NoMatchFound />}
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter"
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				triggerButtonText={renderFilterText()}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter.trigger-btn"
				ariaLabel={ariaText()}
			/>
		</InlineDialog>
	);
};

const filterItemContentStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '24px',
	display: 'flex',
	flexDirection: 'row',
	maxWidth: '390px',
});

const goalNameWrapperStyles = xcss({
	display: 'inline',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

export default GoalFilter;
