/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Lozenge from '@atlaskit/lozenge';
import { Text, Inline } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { ISSUE_STATUS_CATEGORIES, PLANNING_UNITS } from '../../constant.tsx';
import type { Props } from '../types.tsx';
import messages from './messages.tsx';
import Percentage from './percentage.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import { roundToTwoDigit } from './util.tsx';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;

const StatusFlyout = (props: Props) => {
	const { issueCount, planningUnit, byCategoryId, flyoutHeader, ariaLabel } = props;
	const { formatMessage } = useIntl();

	const getBreakdown = () => ({
		todo: byCategoryId[String(TODO)],
		inprogress: byCategoryId[String(INPROGRESS)],
		done: byCategoryId[String(DONE)],
	});

	const getLabel = (count: number) => {
		let label = formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.issueCountLabelIssueTermRefresh
				: messages.issueCountLabel,
			{ numberOfIssues: count },
		);
		const countToTwoDigit = roundToTwoDigit(count);
		if (planningUnit === PLANNING_UNITS.STORY_POINTS) {
			label = formatMessage(messages.storyPointsCountLabel, {
				storyPoints: countToTwoDigit,
			});
		} else if (planningUnit === PLANNING_UNITS.DAYS) {
			label = formatMessage(messages.daysCountLabel, {
				days: countToTwoDigit,
			});
		} else if (planningUnit === PLANNING_UNITS.HOURS) {
			label = formatMessage(messages.hoursCountLabel, {
				hours: countToTwoDigit,
			});
		}
		return label;
	};

	const { todo, inprogress, done } = getBreakdown();
	const total = todo + inprogress + done;

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={!fg('plan-timeline-non-transposed') ? styles.flyout : styles.flyoutOld}
			data-testid="portfolio-3-portfolio.common.status-breakdown.components.status-flyout"
			aria-label={ariaLabel}
		>
			<table>
				{flyoutHeader && fg('aggregate_fields_for_plan_m2') && (
					<thead css={noBorderStyle}>
						<tr>
							<th colSpan={2} css={headerStyle}>
								<Text size="small" color="color.text.subtlest" weight="semibold">
									{formatMessage(flyoutHeader)}
								</Text>
							</th>
						</tr>
					</thead>
				)}
				<tbody>
					<tr>
						<td>
							{fg('aggregate_fields_for_plan_m2') ? (
								<Inline alignBlock="center" space="space.075">
									<span css={[tileStyles, greenTile]} />
									<FormattedMessage {...messages.done} />
								</Inline>
							) : (
								<Lozenge appearance="success">
									<FormattedMessage {...messages.done} />
								</Lozenge>
							)}
						</td>
						<td
							data-testid="portfolio-3-portfolio.common.status-breakdown.components.done"
							data-count={done}
						>
							<Percentage count={done} total={total} label={getLabel(done)} />
						</td>
					</tr>
					<tr>
						<td>
							{fg('aggregate_fields_for_plan_m2') ? (
								<Inline alignBlock="center" space="space.075">
									<span css={[tileStyles, blueTile]} />
									<FormattedMessage {...messages.inprogress} />
								</Inline>
							) : (
								<Lozenge appearance="inprogress">
									<FormattedMessage {...messages.inprogress} />
								</Lozenge>
							)}
						</td>
						<td
							data-testid="portfolio-3-portfolio.common.status-breakdown.components.inprogress"
							data-count={inprogress}
						>
							<Percentage count={inprogress} total={total} label={getLabel(inprogress)} />
						</td>
					</tr>
					<tr>
						<td>
							{fg('aggregate_fields_for_plan_m2') ? (
								<Inline alignBlock="center" space="space.075">
									<span css={[tileStyles, grayTile]} />
									<FormattedMessage {...messages.todo} />
								</Inline>
							) : (
								<Lozenge appearance="default">
									<FormattedMessage {...messages.todo} />
								</Lozenge>
							)}
						</td>
						<td
							data-testid="portfolio-3-portfolio.common.status-breakdown.components.todo"
							data-count={todo}
						>
							<Percentage count={todo} total={total} label={getLabel(todo)} />
						</td>
					</tr>
					<tr>
						<td>
							<FormattedMessage {...messages.total} />
						</td>
						<td>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.countLabel}>{getLabel(total)}</span>
						</td>
					</tr>
					{isDefined(issueCount) && (
						<tr>
							<td>
								<FormattedMessage
									{...(fg('jira-issue-terminology-refresh-m3')
										? messages.totalIssuesLabelIssueTermRefresh
										: messages.totalIssuesLabel)}
								/>
							</td>
							<td>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<span className={styles.countLabel}>{issueCount}</span>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default StatusFlyout;

const noBorderStyle = css({
	borderBottom: 'none',
});

const headerStyle = css({
	paddingTop: token('space.0'),
	paddingBottom: token('space.100'),
});

const tileStyles = css({
	borderRadius: token('border.radius.050'),
	width: '12px',
	height: '12px',
});
const greenTile = css({
	backgroundColor: token('color.border.success'),
});
const grayTile = css({
	backgroundColor: token('color.border'),
});
const blueTile = css({
	backgroundColor: token('color.border.information'),
});
