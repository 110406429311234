import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	collapseAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.field.toggle.collapse-aria-label',
		defaultMessage: 'Collapse the column',
		description: 'Aria label to the button to collapse the field column',
	},
	collapseAriaLabelWithColumn: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.field.toggle.collapse-column-aria-label',
		defaultMessage: '{column}',
		description: 'Aria label to the button to collapse the field column',
	},
});
