/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import * as R from 'ramda';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import DropdownMenu from '@atlassian/jira-portfolio-3-common/src/dropdown-menu/index.tsx';
import RangeSelector from '@atlassian/jira-portfolio-3-common/src/range-selector/index.tsx';
import type {
	RangeSelection,
	OwnProps as RangeSelectorProps,
} from '@atlassian/jira-portfolio-3-common/src/range-selector/types.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import HierarchyLink from './hierarchy-link/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import HierarchyTriggerTooltip from './hierarchy-trigger/index.tsx';

const comparator = R.comparator(R.gt);

function HierarchyFilter({ end, hierarchy, id, onHierarchyChange, start }: Props) {
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const handleOnChange = (selection: RangeSelection) => {
		if (selection.start && selection.end) {
			onHierarchyChange({
				start: selection.start.value,
				end: selection.end.value,
			});
		}
	};

	const hierarchyLevelRangeItems = hierarchy.map(({ level, title }) => ({
		content: title,
		id: level,
		value: level,
	}));

	let rangeSelectorProps: RangeSelectorProps = {
		groups: [{ id, items: hierarchyLevelRangeItems }],
		comparator,
		onChange: handleOnChange,
	};

	const startItem = R.find(R.propEq('value', start), hierarchyLevelRangeItems);
	const endItem = R.find(R.propEq('value', end), hierarchyLevelRangeItems);

	if (startItem) {
		rangeSelectorProps = {
			...rangeSelectorProps,
			start: {
				...startItem,
				...{
					label: formatMessage(messages.topHierarchyLevelLabel),
					name: 'topHierarchyLevel',
					showTooltip: !fg('portfolio-3-dropdown-menu-update'),
				},
			},
		};
	}

	if (endItem) {
		rangeSelectorProps = {
			...rangeSelectorProps,
			end: {
				...endItem,
				...{
					label: formatMessage(messages.bottomHierarchyLevelLabel),
					name: 'bottomHierarchyLevel',
					showTooltip: !fg('portfolio-3-dropdown-menu-update'),
				},
			},
		};
	}

	const startItemPropsWithFg = fg('portfolio-3-dropdown-menu-update')
		? {
				shouldDisplayTruncationTooltip: true,
				ariaLabel: `${formatMessage(messages.hierarchyFromWithSelectedValue, {
					value: startItem?.content,
				})}`,
				CustomTriggerComponent: HierarchyTriggerTooltip,
			}
		: {
				ariaLabel: `${formatMessage(messages.hierarchyFrom)}`,
			};

	const endItemPropsWithFg = fg('portfolio-3-dropdown-menu-update')
		? {
				shouldDisplayTruncationTooltip: true,
				ariaLabel: `${formatMessage(messages.hierarchyToWithSelectedValue, {
					value: endItem?.content,
				})}`,
				CustomTriggerComponent: HierarchyTriggerTooltip,
			}
		: {
				ariaLabel: `${formatMessage(messages.hierarchyTo)}`,
			};
	const rangeSelector = (
		<RangeSelector {...rangeSelectorProps}>
			<DropdownMenu
				footerContent={isAdmin ? <HierarchyLink /> : undefined}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.from"
				{...startItemPropsWithFg}
			/>

			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.separator} />

			<DropdownMenu
				footerContent={isAdmin ? <HierarchyLink /> : undefined}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.to"
				{...endItemPropsWithFg}
			/>
		</RangeSelector>
	);

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={styles.hierarchyFilter}
		>
			{fg('plan_a11y_fixes_jtran2') ? (
				<fieldset css={fieldsetStyles}>
					<legend>
						<VisuallyHidden>{formatMessage(messages.hierarchyLegend)}</VisuallyHidden>
					</legend>
					{rangeSelector}
				</fieldset>
			) : (
				rangeSelector
			)}
		</div>
	);
}

export default HierarchyFilter;

const fieldsetStyles = css({
	display: 'flex',
});
