import React, {
	memo,
	useCallback,
	useState,
	type KeyboardEventHandler,
	type FocusEventHandler,
	useEffect,
} from 'react';
import { Box, xcss, type BoxProps } from '@atlaskit/primitives';
import { useLayering } from '@atlaskit/layering';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsScrollingY, useViewport } from '../../controllers/container/index.tsx';
import {
	GridProvider,
	useFocusGridEffect,
	useFocusVisible,
	useGridActions,
	useGridId,
	useGridRef,
} from '../../controllers/grid/index.tsx';
import { Colgroup } from './colgroup/index.tsx';
import { Head, Body } from './rows/index.tsx';
import type { Props } from './types.tsx';
import { Virtualized } from './virtualized/index.tsx';
import { Virtualizer } from './virtualizer/index.tsx';
import { FocusPassthrough } from './focus-passthrough/index.tsx';

const Blanket = () => {
	const [isScrollingY] = useIsScrollingY();
	if (!isScrollingY) {
		return null;
	}
	return <Box xcss={blanketStyles} />;
};
export const Grid = memo(
	({
		rowConfigs,
		columnConfigs,
		colspan,
		layout = 'auto',
		row: renderRow,
		virtualizationScrollThreshold,
		empty,
		children,
		stickyOffset,
		rangeExtractor,
		columnWidths,
		overscan = 0,
		resizer,
		onColumnWidthsChange,
		ariaLabel,
		role,
		testId,
		dataName,
		xcss: overrideXcss,
		getSelectColspan,
		getSelectRowspan,
	}: Props) => {
		const [id] = useState(() => Math.random().toString(36).slice(2));
		const layoutStyles = layout === 'fixed' ? [fixedTableStyles] : [];
		const TableComponent = fg('treegrid_keyboard_navigation') ? Table : Box;

		const [{ bottom: bottomOffset }] = useViewport();

		const table = (top: number, bottom: number) => (
			<>
				<TableComponent
					// Remove this with treegrid_keyboard_navigation cleanup
					as="table"
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
					xcss={[tableStyles, ...layoutStyles, overrideXcss]}
					aria-label={ariaLabel}
					role={fg('treegrid_keyboard_navigation') ? role ?? 'treegrid' : role}
					testId={testId}
					data-name={dataName}
					aria-rowcount={fg('treegrid_keyboard_navigation') ? rowConfigs.length : undefined}
					aria-colcount={fg('treegrid_keyboard_navigation') ? columnConfigs.length : undefined}
				>
					<Colgroup count={columnConfigs.length} />
					<Virtualized rowConfigs={rowConfigs}>
						{({ head, body, liteRowIndexes, visibleCols }) => (
							<>
								<Blanket />
								<Box as="thead">
									<Head head={head} renderRow={renderRow} columnConfigs={columnConfigs} />
								</Box>
								<Box as="tbody">
									{empty(top /* Virtualization */, true)}
									<Body
										body={body}
										renderRow={renderRow}
										columnConfigs={columnConfigs}
										liteRowIndexes={liteRowIndexes}
										visibleCols={visibleCols}
									/>
									{empty(bottom)}
								</Box>
							</>
						)}
					</Virtualized>
				</TableComponent>
				{!fg('plan_timeline_table_viewport_inset_fix') && (
					<Box role="presentation" xcss={fillWrapperStyles}>
						<Box
							as="table"
							xcss={[tableStyles, ...layoutStyles, fillParentStyles]}
							role="presentation"
						>
							<Colgroup count={columnConfigs.length} />
							<Box as="tbody">{empty()}</Box>
						</Box>
					</Box>
				)}
				<FocusPassthrough />
			</>
		);

		return (
			<Virtualizer
				rowConfigs={rowConfigs}
				overscan={overscan}
				rangeExtractor={rangeExtractor}
				scrollThreshold={virtualizationScrollThreshold}
			>
				{({ top, bottom }) => (
					<GridProvider
						id={id}
						layout={layout}
						columns={columnConfigs}
						widths={columnWidths}
						onWidthsChange={onColumnWidthsChange}
						rows={rowConfigs}
						colspan={colspan}
						stickyOffset={stickyOffset}
						resizer={resizer}
						getSelectColspan={fg('plan_timeline_colspan') ? getSelectColspan : undefined}
						getSelectRowspan={fg('plan_timeline_colspan') ? getSelectRowspan : undefined}
					>
						{children?.(
							table(
								top,
								fg('plan_timeline_table_viewport_inset_fix')
									? Math.max(bottom, bottomOffset)
									: bottom,
							),
						)}
					</GridProvider>
				)}
			</Virtualizer>
		);
	},
);

const Table: React.FC<BoxProps<'table'>> = (props) => {
	const [id] = useGridId();
	const [ref] = useGridRef();
	const [focusVisible, { setFocusVisible }] = useFocusVisible();
	const [gridEffect] = useFocusGridEffect();
	const { isLayerDisabled } = useLayering();
	const { navigate, enter, escape, tab } = useGridActions();
	const handleKeyDown: KeyboardEventHandler = useCallback(
		({ key, nativeEvent: e }) => {
			if (isLayerDisabled()) return;

			switch (key) {
				case 'ArrowUp':
					navigate(e, 'backward', 'vertical', 'single');
					break;

				case 'ArrowDown':
					navigate(e, 'forward', 'vertical', 'single');
					break;

				case 'ArrowLeft':
					navigate(e, 'backward', 'horizontal', 'single');
					break;

				case 'ArrowRight':
					navigate(e, 'forward', 'horizontal', 'single');
					break;

				case 'PageUp':
					navigate(e, 'backward', 'vertical', 'multiple');
					break;

				case 'PageDown':
					navigate(e, 'forward', 'vertical', 'multiple');
					break;

				case 'Home':
					navigate(e, 'backward', 'auto', 'all');
					break;

				case 'End':
					navigate(e, 'forward', 'auto', 'all');
					break;

				case 'Enter':
					enter(e);
					break;

				case 'Escape':
					escape(e);
					break;

				case 'Tab':
					tab(e, e.shiftKey ? 'backward' : 'forward');
					break;

				default:
					break;
			}
		},
		[navigate, enter, escape, tab, isLayerDisabled],
	);

	const handleFocus: FocusEventHandler = useCallback(
		(e) => {
			const nextFocusVisible =
				e.target === e.currentTarget && e.currentTarget.matches(':focus-visible');
			setFocusVisible(nextFocusVisible);
		},
		[setFocusVisible],
	);

	const handleBlur: FocusEventHandler = useCallback(
		(e) => {
			if (e.target === e.currentTarget) {
				setFocusVisible(false);
			}
		},
		[setFocusVisible],
	);

	useEffect(() => {
		if (ref.current && gridEffect) {
			return gridEffect(ref.current);
		}
	}, [gridEffect, ref]);

	return (
		// The following element is a interactable table.
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<Box
			ref={ref}
			as="table"
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}
			aria-activedescendant={focusVisible ? `${id}:activedescendant` : undefined}
			onKeyDown={handleKeyDown}
			onFocus={handleFocus}
			onBlur={handleBlur}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

const tableStyles = xcss({
	margin: 'space.0',
	borderCollapse: 'separate',
	borderSpacing: 0,
	minWidth: '100%',
});

const fixedTableStyles = xcss({
	tableLayout: 'fixed',
});

const fillParentStyles = xcss({
	height: '100%',
});

const fillWrapperStyles = xcss({
	flex: 1,
	position: 'relative',
});

const blanketStyles = xcss({
	position: 'absolute',
	top: 'space.0',
	left: 'space.0',
	width: '100%',
	height: '100%',
	zIndex: 'blanket',
});
