import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	warningHeaderText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.warning-header-text',
		defaultMessage:
			"{successfulCommitCount, plural, =0 {Changes couldn't be saved} other {Some changes couldn't be saved}}",
		description: 'Message to be shown in the flag header when there are commit failures',
	},
	warningWithSuccessContentSuccessText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.warning-with-success-content-success-text',
		defaultMessage:
			'{successfulCommitCount, plural, one {# change was} other {# changes were}} successfully saved to your plan.',
		description:
			'First sentence when some changes were able to be saved but others were not. Full text is "X change(s) was/were successfully saved to your plan. However, X change(s) could not be committed."',
	},
	warningWithSuccessContentWarningText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.warning-with-success-content-warning-text',
		defaultMessage:
			'However, {failedCommitCount, plural, one {# change} other {# changes}} could not be committed.',
		description:
			'Second sentence when some changes were able to be saved but others were not. Full text is "X change(s) was/were successfully saved to your plan. However, X change(s) could not be committed."',
	},
	warningContentText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.warning-content-text',
		defaultMessage:
			"{failedCommitCount, plural, =0 {Your plan is out of sync due to concurrent editing.} one {# change couldn't be saved.} other {# changes couldn't be saved.}}",
		description: 'Message to be shown in the flag body when when there are commit failures',
	},
	successHeaderText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.success-header-text',
		defaultMessage: 'Success',
		description: 'Message to be shown in the flag header when all commits where successful',
	},
	successContentText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.success-content-text',
		defaultMessage:
			'{successfulCommitCount, plural, one {# change was} other {# changes were}} successfully saved in Jira.',
		description: 'Message to be shown in the flag body when all commits where successful',
	},
	openUnsavedChangesNonFinal: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.open-unsaved-changes-non-final',
		defaultMessage: 'Open unsaved changes to resolve',
		description: 'Message to be shown in the flag body when all commits where successful',
	},
});

export default messages;
