import React, { type ChangeEventHandler, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import EditorInfoIcon from '@atlaskit/icon/core/migration/information--editor-info';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TriggerProps } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const NotificationToggle = ({ active, onChange }: Props) => {
	const { formatMessage } = useIntl();
	const [shouldShowTooltipContent, setShouldShowTooltipContent] = useState(true);

	const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		(e) => onChange(e.target.checked),
		[onChange],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			if (fg('jpo_a11y_pb_fixes')) {
				if (e.key === 'Escape' && shouldShowTooltipContent) {
					e.stopPropagation();
					setShouldShowTooltipContent(false);
				} else if (e.key === 'Enter' || e.key === ' ') {
					setShouldShowTooltipContent(true);
				}
			}
		},
		[shouldShowTooltipContent],
	);

	const handleOnFocusButton =
		(onFocus: (event: React.FocusEvent<HTMLDivElement>) => void) =>
		(event: React.FocusEvent<HTMLDivElement>) => {
			onFocus(event);
			if (fg('jpo_a11y_pb_fixes')) {
				setShouldShowTooltipContent(true);
			}
		};

	const label = formatMessage(messages.notifyWatchers);
	const tooltip = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.notifyWatchersDescIssueTermRefresh
			: messages.notifyWatchersDesc,
	);
	const tooltipAriaLabel = formatMessage(messages.notifyWatchersTooltipAriaLabel);

	const tooltipContent = shouldShowTooltipContent && fg('jpo_a11y_pb_fixes') ? tooltip : undefined;

	return (
		<NotifyWatchersContainer>
			<Checkbox
				testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.notification-toggle.checkbox"
				isChecked={active}
				onChange={handleCheckboxChange}
				label={label}
			/>
			<Tooltip
				content={fg('jpo_a11y_pb_fixes') ? tooltipContent : tooltip}
				onShow={fg('jpo_a11y_pb_fixes') ? () => setShouldShowTooltipContent(true) : undefined}
			>
				{({ onFocus, ...rest }: TriggerProps) => (
					// eslint-disable-next-line @atlaskit/design-system/no-html-button
					<div
						role="button"
						tabIndex={0}
						onKeyDown={handleKeyDown}
						onFocus={handleOnFocusButton(onFocus)}
						{...rest}
					>
						<EditorInfoIcon
							spacing="spacious"
							label={tooltipAriaLabel}
							color={token('color.icon.information')}
						/>
					</div>
				)}
			</Tooltip>
		</NotifyWatchersContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotifyWatchersContainer = styled.div({
	display: 'flex',
});
