import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	topHierarchyLevelLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.top-hierarchy-level-label',
		defaultMessage: 'From',
		description:
			'Label for the first field in the hierarchy range selector "From" <level> To <level>',
	},
	bottomHierarchyLevelLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.bottom-hierarchy-level-label',
		defaultMessage: 'To',
		description:
			'Label for the second field in the hierarchy range selector From <level> "To" <level>',
	},
	hierarchyFrom: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-from',
		defaultMessage: 'Hierarchy from:',
		description: 'Aria-label text for hierarchy range selector - FROM filter button.',
	},
	hierarchyFromWithSelectedValue: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-from-with-selected-value',
		defaultMessage: 'Hierarchy from: {value}',
		description: 'Aria-label text for hierarchy range selector - FROM filter button.',
	},
	hierarchyTo: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-to',
		defaultMessage: 'Hierarchy to:',
		description: 'Aria-label text for hierarchy range selector - TO filter button.',
	},
	hierarchyToWithSelectedValue: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-to-with-selected-value',
		defaultMessage: 'Hierarchy to: {value}',
		description: 'Aria-label text for hierarchy range selector - TO filter button.',
	},
	hierarchyFooterLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-footer-link',
		defaultMessage: 'Configure hierarchy',
		description: 'This will be displayed at the end of the hierarchy filter list',
	},
	hierarchyLegend: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-legend',
		defaultMessage: 'Hierarchy',
		description: 'Legend for the fieldset for the hierarchy range selector.',
	},
});
