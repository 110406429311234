import React from 'react';
import { token } from '@atlaskit/tokens';

const OnTrackIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
		<g fill="none" fillRule="evenodd">
			<circle fill={token('color.icon.information')} cx={12} cy={12} r={7} />
		</g>
	</svg>
);
export { OnTrackIcon };
