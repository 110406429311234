import type { Dispatch, Action, Middleware } from 'redux';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { fg } from '@atlassian/jira-feature-gating';
import { FETCH_GOALS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue-goals/index.tsx';
import { CALCULATE_STATUS_OF_ALL_RELEASES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/versions/index.tsx';
import { RESET_BACKLOG, SET_PLAN_INFO, UPDATE_ISSUE_REFERENCES } from '../../domain/actions.tsx';
import { UPDATE_ISSUE_ASSIGNEE_MAP } from '../../domain/assignees/actions.tsx';
import { BULK_UPDATE } from '../../domain/cross-project-versions/actions.tsx';
import { UPDATE_ISSUE_REPORTER_MAP } from '../../domain/reporters/actions.tsx';

const ACTIONS_TO_MEASURE = [
	SET_PLAN_INFO,
	RESET_BACKLOG,
	UPDATE_ISSUE_REFERENCES,
	CALCULATE_STATUS_OF_ALL_RELEASES,
	BULK_UPDATE,
	UPDATE_ISSUE_ASSIGNEE_MAP,
	UPDATE_ISSUE_REPORTER_MAP,
	FETCH_GOALS,
] as const;

// type assertion is needed due to the Middleware type being broken in the version of redux we are using: https://github.com/reduxjs/redux-thunk/issues/203
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const actionTimingMiddleware = (() => (next: Dispatch) => (action: Action) => {
	if (!fg('plans_action_timing_metrics') || !ACTIONS_TO_MEASURE.includes(action.type)) {
		return next(action);
	}
	const actionStart = performance.now();
	const result = next(action);
	addSpanToAll('reducer', action.type, [{ name: 'redux' }], actionStart);
	return result;
}) as unknown as Middleware;
