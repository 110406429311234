import React, { type ReactNode } from 'react';
import { Box, Text, Stack, xcss, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptorV2 } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import Percentage from '../percentage.tsx';
import messages from '../messages.tsx';

type Props = {
	// Content of the breakdown
	content: { title: ReactNode; count: number }[];
	// Count of issue that does not have the field value
	issuesWithoutField?: number;
	// Field name
	fieldName?: MessageDescriptorV2;
	// Unit display in the breakdown i.e. "{count} of {total} work items"
	label: MessageDescriptorV2;
	// Count of the total issues
	total: number;
	// Display the total in the footer
	showTotal?: boolean;
	// Width of the title
	titleWidth?: number;
	// Test id
	testId: string;
	// Message for aria label
	ariaLabel?: string;
};

const FlyoutContent = (props: Props) => {
	const {
		content,
		label,
		total,
		testId,
		issuesWithoutField,
		showTotal = true,
		titleWidth = 160,
		ariaLabel,
		fieldName: fieldNameProp,
	} = props;
	const { formatMessage } = useIntl();

	const getLabel = (count: number) => {
		return formatMessage(label, { count, total });
	};

	const fieldName = fieldNameProp && formatMessage(fieldNameProp).toLowerCase();

	return (
		<Box
			aria-label={ariaLabel}
			xcss={flyoutStyle}
			paddingBlock="space.200"
			paddingInline="space.300"
			testId={testId}
		>
			{fieldNameProp && (
				<Stack>
					<Text size="small" color="color.text.subtlest" weight="semibold">
						{formatMessage(fieldNameProp)}
					</Text>
				</Stack>
			)}
			<Box xcss={tableContentSyle}>
				{content.length > 0
					? content.map(({ title, count }, index) => (
							<Inline key={index} space="space.100" xcss={listItemStyle}>
								<Box as="span" xcss={contentTitleStyle} style={{ width: titleWidth }}>
									{title}
								</Box>
								<Box as="span">
									<Percentage count={count} total={total} label={getLabel(count)} />
								</Box>
							</Inline>
						))
					: formatMessage(
							fg('jira-issue-terminology-refresh-m3') ? messages.addWorkItem : messages.addIssue,
							{ field: fieldName },
						)}
			</Box>
			{showTotal && (
				<Inline space="space.100">
					<Box as="span" xcss={contentTitleStyle} style={{ width: titleWidth }}>
						{formatMessage(messages.total)}
					</Box>
					<Box xcss={countLabel} as="span">
						{getLabel(total)}
					</Box>
				</Inline>
			)}
			{issuesWithoutField !== undefined && (
				<Inline xcss={tableFooterStyle} space="space.100">
					<Box as="span">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.workItemsWithoutField
								: messages.issuesWithoutField,
							{ field: fieldName },
						)}
					</Box>
					<Box as="span">
						<Text weight="semibold">{issuesWithoutField}</Text>
					</Box>
				</Inline>
			)}
		</Box>
	);
};

export default FlyoutContent;

const listItemStyle = xcss({
	marginBottom: 'space.100',
});

const contentTitleStyle = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const flyoutStyle = xcss({
	color: 'color.text.subtlest',
	width: '100%',
	maxWidth: '800px',
	maxHeight: '400px',
	boxSizing: 'border-box',
});

const countLabel = xcss({
	fontWeight: token('font.weight.semibold'),
	color: 'color.text',
});

const tableContentSyle = xcss({
	maxHeight: '160px',
	overflowY: 'auto',
	paddingRight: 'space.200',
	paddingTop: 'space.100',
	marginTop: 'space.050',
	marginBottom: 'space.050',
});

const tableFooterStyle = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	marginTop: 'space.150',
	paddingTop: 'space.150',
});
