import React, { forwardRef, type Ref } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { AggregatedIssueTypeHierarchy } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/types.tsx';
import {
	ISSUE_TYPE_KEY_FILTER_ID,
	type IssueTypeKeyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FilterText } from '../../common/filter-text/index.tsx';
import TriggerButton from '../../common/trigger-button/index.tsx';
import type { FilterItem } from '../../filter-item-hoc/types.tsx';
import filterMessages from '../../messages.tsx';
import messages from './messages.tsx';

type Props = {
	isOpen: boolean;
	value: IssueTypeKeyFilterValue;
	aggregatedIssueTypeHierarchy: AggregatedIssueTypeHierarchy[];
	onOpenChange: FilterItem['onOpenChange'];
};

const IssueTypesFilterTriggerOld = ({
	value,
	aggregatedIssueTypeHierarchy,
	isOpen,
	onOpenChange,
}: Props) => {
	const { formatMessage } = useIntl();

	const validValues = value.filter(
		({ level, name }) =>
			aggregatedIssueTypeHierarchy
				.find(({ level: hierarchyLevel }) => level === hierarchyLevel)
				?.issueTypes.some(({ name: aggregatedName }) => name === aggregatedName) ?? false,
	);

	const filteredIssueTypeNames = validValues.map(({ name }) => name).join(', ');

	const triggerLabel =
		validValues.length > 0 ? (
			<FilterText text={filteredIssueTypeNames} />
		) : (
			formatMessage(messages.placeholder)
		);

	const ariaLabel = `${formatMessage(filterMessages[ISSUE_TYPE_KEY_FILTER_ID])}, ${
		validValues.length > 0 ? filteredIssueTypeNames : formatMessage(messages.placeholder)
	} ${formatMessage(filterMessages.selected)}`;

	return (
		<TriggerButton
			isOpen={isOpen}
			onOpenChange={onOpenChange}
			triggerButtonText={triggerLabel}
			ariaLabel={ariaLabel}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-types-key-filter.trigger"
		/>
	);
};

const IssueTypesFilterTrigger = forwardRef(
	(
		{ value, aggregatedIssueTypeHierarchy, isOpen, onOpenChange }: Props,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { formatMessage } = useIntl();

		const validValues = value.filter(
			({ level, name }) =>
				aggregatedIssueTypeHierarchy
					.find(({ level: hierarchyLevel }) => level === hierarchyLevel)
					?.issueTypes.some(({ name: aggregatedName }) => name === aggregatedName) ?? false,
		);

		const filteredIssueTypeNames = validValues.map(({ name }) => name).join(', ');

		const triggerLabel =
			validValues.length > 0 ? (
				<FilterText text={filteredIssueTypeNames} />
			) : (
				formatMessage(messages.placeholder)
			);

		const ariaLabel = `${formatMessage(filterMessages[ISSUE_TYPE_KEY_FILTER_ID])}, ${
			validValues.length > 0 ? filteredIssueTypeNames : formatMessage(messages.placeholder)
		} ${formatMessage(filterMessages.selected)}`;

		return (
			<TriggerButton
				ref={ref}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				triggerButtonText={triggerLabel}
				ariaLabel={ariaLabel}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-types-key-filter.trigger"
			/>
		);
	},
);

export default componentWithFG(
	'migrate_plan_filter_to_popup',
	IssueTypesFilterTrigger,
	IssueTypesFilterTriggerOld,
);
