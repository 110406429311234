import countBy from 'lodash/countBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import type { VersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { OwnProps, MapStateToProps, ReleaseValue } from './types.tsx';

const getValues = createSelector(
	[getVersionsById, (_: State, { rawValues }: OwnProps) => rawValues],
	(versionsById: VersionsById, rawValues: string[]): ReleaseValue[] => {
		const versionsCounts = countBy(rawValues);
		const uniqueVersions = Object.keys(versionsCounts).sort(
			(a, b) => versionsCounts[b] - versionsCounts[a],
		);

		return uniqueVersions
			.map((id) => {
				if (!versionsById[id]) return undefined;

				const { name } = versionsById[id];
				return { name, count: versionsCounts[id] };
			})
			.filter(Boolean);
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	values: getValues,
});

export default mapStateToProps;
