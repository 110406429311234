// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createContainer,
	type Action,
	type ActionThunk,
} from '@atlassian/react-sweet-state';
import { DEPENDENCY_SETTINGS } from '../common/constants.tsx';
import type { State, NullableClickedLine } from './types.tsx';

const actions = {
	setActiveLine:
		(activeLine: NullableClickedLine): Action<State> =>
		({ setState }) => {
			setState({ activeLine });
		},
} as const;

const Store = createStore<State, typeof actions>({
	initialState: {
		dependencyLines: [],
		dependencySettings: { display: DEPENDENCY_SETTINGS.DEFAULT },
		activeLine: null,
	},
	actions,
	name: 'arj-dependency-lines',
});

type ContainerProps = {
	dependencyLines: State['dependencyLines'];
	dependencySettings: State['dependencySettings'];
};

const hydrateContainer: ActionThunk<State, typeof actions> =
	() =>
	({ setState }, { dependencyLines, dependencySettings }: ContainerProps) => {
		setState({ dependencyLines, dependencySettings });
	};

export const DependencyLineContainer = createContainer<State, typeof actions, ContainerProps>(
	Store,
	{
		onInit: hydrateContainer,
		onUpdate: hydrateContainer,
	},
);

// Selectors

export const useDependencyLines = createHook(Store, {
	selector: (state) => state.dependencyLines,
});

export const useActiveLine = createHook(Store, {
	selector: (state) => state.activeLine,
});

export const useDependencySettings = createHook(Store, {
	selector: (state) => state.dependencySettings,
});
