import React, { forwardRef, memo, type ReactNode, type Ref } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

import Button, {
	IconButton,
	LinkButton,
	LinkIconButton,
	type ButtonProps,
	type IconButtonProps,
	type LinkButtonProps,
	type LinkIconButtonProps,
} from '@atlaskit/button/new';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export const ThemedButtonWrapper = ({ children }: { children: ReactNode }) =>
	fg('jira_theming_milestone_1_fg') ||
	(isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons')) ? (
		<Box as="span" xcss={overrideStyles}>
			{children}
		</Box>
	) : (
		children
	);
export const ThemedButtonNew = memo(
	forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
		<ThemedButtonWrapper>
			<Button {...props} ref={ref} />
		</ThemedButtonWrapper>
	)),
);

export const ThemedIconButton = memo(
	forwardRef((props: IconButtonProps, ref: Ref<HTMLButtonElement>) => (
		<ThemedButtonWrapper>
			<IconButton {...props} ref={ref} />
		</ThemedButtonWrapper>
	)),
);

export const ThemedLinkButton = memo(
	forwardRef((props: LinkButtonProps, ref: Ref<HTMLAnchorElement>) => (
		<ThemedButtonWrapper>
			<LinkButton {...props} ref={ref} />
		</ThemedButtonWrapper>
	)),
);

export const ThemedLinkIconButton = memo(
	forwardRef((props: LinkIconButtonProps, ref: Ref<HTMLAnchorElement>) => (
		<ThemedButtonWrapper>
			<LinkIconButton {...props} ref={ref} />
		</ThemedButtonWrapper>
	)),
);

const overrideStyles = xcss({
	// @ts-expect-error Object literal may only specify known properties, and ''--ds-background-brand-bold'' does not exist in type 'SafeCSSObject'.ts(2353)
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-brand-bold': Tokens.COLOR_BACKGROUND_BRAND_BOLD,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-brand-bold-hovered': Tokens.COLOR_BACKGROUND_BRAND_BOLD_HOVERED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-brand-bold-pressed': Tokens.COLOR_BACKGROUND_BRAND_BOLD_PRESSED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-disabled': Tokens.COLOR_BACKGROUND_DISABLED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-selected': Tokens.COLOR_BACKGROUND_SELECTED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-selected-hovered': Tokens.COLOR_BACKGROUND_SELECTED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-selected-pressed': Tokens.COLOR_BACKGROUND_SELECTED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-neutral': Tokens.COLOR_BACKGROUND_NEUTRAL,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-neutral-hovered': Tokens.COLOR_BACKGROUND_NEUTRAL_HOVERED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-neutral-pressed': Tokens.COLOR_BACKGROUND_NEUTRAL_PRESSED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-neutral-subtle-hovered': Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-background-neutral-subtle-pressed': Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_PRESSED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-icon-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-icon': Tokens.COLOR_TEXT,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-link': Tokens.COLOR_LINK,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-link-pressed': Tokens.COLOR_LINK_PRESSED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-text': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-text-disabled': Tokens.COLOR_TEXT_DISABLED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-text-inverse': Tokens.COLOR_TEXT_INVERSE,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-text-selected': Tokens.COLOR_TEXT_SELECTED,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-text-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-border': Tokens.COLOR_BORDER,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-border-selected': Tokens.COLOR_BORDER_SELECTED,
});
