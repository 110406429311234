import {
	TARGET_START_FIELD,
	TARGET_END_FIELD,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { TARGET_DATE_FIELD_TO_API_DATE_FIELD_MAP } from '../../state/domain/issues/types.tsx';
import type { Plan } from '../../state/domain/plan/types.tsx';
import type {
	UpdateIssuePayload,
	RankIssuePayload,
	RankRequest,
	NewIssue,
	HiddenIssuesBody,
	UpdateIssueBody,
} from './types.tsx';

export const urls = {
	add: '/rest/jpo/1.0/scenarios/addIssue',
	update: '/rest/jpo/1.0/scenarios/updateIssues',
	removeIssues: '/rest/jpo/1.0/scenarios/removeIssues',
	deleteIssues: '/rest/jpo/1.0/scenarios/deleteIssues',
	rank: '/rest/jpo/1.0/issues/rank',
	commitChanges: '/rest/jpo/1.0/issues/commit',
	updateIssueKey: '/rest/jpo/1.0/scenarios/updateIssueKey',
	revertIssues: '/rest/jpo/1.0/scenarios/revertIssues',
	getHiddenIssues: '/rest/jpo/1.0/backlog/hiddenIssues',
	getDoneHistory: '/rest/jpo/1.0/backlog/history',
	getDescription: '/rest/jpo/1.0/issue/description/get',
} as const;

// `id` is removed because it's passed as `itemKeys` outside `values`
// `level` is removed as a client-specific field
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const issueToPatch = ({ id, level, ...issue }: any) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const patch: Record<string, any> = {};
	for (const key of Object.keys(issue)) {
		if (key === 'assignee' && issue.assignee === 'unassigned') {
			patch.assignee = { value: null };
		} else if (key === 'fixVersions' && Array.isArray(issue[key])) {
			patch.versionIds = { value: issue[key] };
		} else if (key === 'customFields') {
			const resultCustomFields: Record<string, unknown> = {};
			Object.keys(issue.customFields).forEach((field) => {
				resultCustomFields[field] = {
					value: issue.customFields[field] === null ? undefined : issue.customFields[field],
				};
			});
			patch.customFields = resultCustomFields;
		} else if (key === TARGET_START_FIELD || key === TARGET_END_FIELD) {
			const apiKey = TARGET_DATE_FIELD_TO_API_DATE_FIELD_MAP[key] || key;
			patch[apiKey] = { value: issue[key] };
		} else if (key === 'labels') {
			if (issue[key].fieldAction != null) {
				patch[key] = issue[key];
			} else {
				patch[key] = { value: issue[key] };
			}
		} else {
			patch[key] = { value: issue[key] };
		}
	}
	return patch;
};

export const addIssueBody = (plan: Plan, payload: NewIssue) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;
	return {
		planId,
		scenarioId,
		description: issueToPatch(payload),
	};
};

export const updateIssueBody = (
	plan: Plan,
	payload: UpdateIssuePayload,
	itemKeys?: string[],
): UpdateIssueBody => {
	const { id: planId, currentScenarioId: scenarioId } = plan;
	return {
		itemKeys: itemKeys || [payload.id],
		planId,
		scenarioId,
		description: issueToPatch(payload),
	};
};

export const rankIssueBody = (plan: Plan, payload: RankIssuePayload): RankRequest => {
	const { id: planId, currentScenarioId: scenarioId } = plan;
	const { itemKeys, anchor, relation } = payload;
	return {
		operations: [
			{
				anchor,
				itemKeys,
				operationType: relation,
			},
		],
		planId,
		scenarioId,
	};
};

export const hiddenIssuesBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	issueIds: number[],
) => {
	if (!planId || !scenarioId) {
		throw new Error('Need both plan and scenario to get hidden issues');
	}
	const payload: HiddenIssuesBody = {
		planId,
		scenarioId,
		issueIds,
	};
	return payload;
};

export const getDescription = (plan: Plan, itemKey: string) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;
	return { planId, scenarioId, itemKey, renderRaw: true };
};
