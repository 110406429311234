import difference from 'lodash/difference';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fetchPinnedFieldsByProjectIds } from '../services/index.tsx';
import type { StoreApi, ContainerProps } from '../types.tsx';

export const loadPinnedFieldsByProjectIds =
	() =>
	async ({ getState, setState }: StoreApi, { projectIds }: ContainerProps) => {
		let projectIdsToLoad = difference(projectIds, getState().currentFetchingProjectIds);

		// call only for newly added project ids (not for everyting once again)
		const pinnedFields = getState().pinnedFields;
		if (pinnedFields) {
			const currentProjectIds = Object.keys(pinnedFields);
			projectIdsToLoad = difference(projectIds, currentProjectIds);
		}

		// don't call once again, when we have data
		if (!projectIdsToLoad.length) {
			return;
		}

		setState({
			error: undefined,
			currentFetchingProjectIds: Array.from(
				new Set([...getState().currentFetchingProjectIds, ...projectIdsToLoad]),
			),
		});

		try {
			const pinnedFieldsResponse = await fetchPinnedFieldsByProjectIds(projectIdsToLoad);
			setState({
				pinnedFields: {
					// dont's use here currentState - it should be getState() to have the freshest data from the store as possible
					...(getState().pinnedFields || {}),
					...pinnedFieldsResponse.projectIdsToFieldIds,
				},
				error: undefined,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (!isClientFetchError(error)) {
				log.safeErrorWithoutCustomerData(
					'portfolio-3.portfolio.common.view.ideas-cell.idea-issue.issue-card-pinned-fields.actions',
					'Failed to fetch pinned fields by project id.',
					error,
				);
			}
			setState({ error });
		} finally {
			setState({
				currentFetchingProjectIds: difference(
					getState().currentFetchingProjectIds,
					projectIdsToLoad,
				),
			});
		}
	};
