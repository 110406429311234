/** @jsx jsx */
import React, {
	useState,
	type KeyboardEvent,
	type MouseEvent,
	useCallback,
	memo,
	useRef,
} from 'react';
import { css, jsx } from '@compiled/react';
import map from 'lodash/map';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import StandardButton from '@atlaskit/button/standard-button';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { openInNewTab } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import BulkActionsMenu from './bulk-actions-menu/index.tsx';
import UpdateAttributeForm from './dialog/common/update-attribute-form/view.tsx';
import messages from './messages.tsx';
import {
	type Props,
	type DropOption,
	type GetDropOption,
	REMOVE_FROM_PLAN,
	SET_DATES,
	BULK_CHANGE,
} from './types.tsx';
import { type MapKey, DROPMENU_MAP } from './utils.tsx';

const DROPMENU_KEYS: MapKey[] = [
	'setAssignee',
	'dates',
	'setEstimate',
	'setLabel',
	'setParent',
	'setRank',
	'setReleases',
	'setSprint',
	'setTeam',
];
const DROPMENU_ITEMS = DROPMENU_KEYS.map((key) => DROPMENU_MAP[key]);

const MORE_DROPDOWN_ITEM_KEYS_OLD: MapKey[] = ['bulkChange', 'removeFromPlanTitle'];
const MORE_DROPMENU_ITEMS_OLD = MORE_DROPDOWN_ITEM_KEYS_OLD.map((key) => DROPMENU_MAP[key]);

const MORE_DROPDOWN_ITEM_KEYS: MapKey[] = ['removeFromPlanTitle', 'bulkChange'];
const MORE_DROPMENU_ITEMS = MORE_DROPDOWN_ITEM_KEYS.map((key) => DROPMENU_MAP[key]);

export const BulkActions = memo(
	({
		selectedIssues,
		projectsById,
		meatballTriggerRef,
		onOptionSelect,
		onModalOpen,
		onModalClose,
		children,
		...props
	}: Props) => {
		const [isMenuOpen, setIsMenuOpen] = useState(false);
		const [isDialogOpen, setIsDialogOpen] = useState(false);
		const [optionSelected, setOptionSelected] = useState<DropOption | null>(null);

		const { createAnalyticsEvent } = useAnalyticsEvents();

		const buttonRef = useRef<HTMLButtonElement | null>(null);

		const { formatMessage } = useIntl();

		const toggleModalDialog = useCallback((newIsDialogOpen: boolean) => {
			setIsDialogOpen(newIsDialogOpen);
		}, []);

		const buildUrl = useCallback((): string => {
			const issueKeys = map(selectedIssues, ({ project: projectId, issueKey }) => {
				const project = projectsById[projectId];
				if (!issueKey) {
					return undefined;
				}
				return `${project.key}-${issueKey}`;
			});

			const encodedIds = encodeURIComponent(issueKeys.filter((key) => key).toString());

			return proxyContextSafeUrl(`/secure/GHBulkChange.jspa?issueKeys=${encodedIds}`);
		}, [projectsById, selectedIssues]);

		const sendIDtoJira = useCallback(() => {
			openInNewTab(buildUrl());
			setIsMenuOpen((currentIsMenuOpen) => !currentIsMenuOpen);
		}, [buildUrl]);

		const selectOption = useCallback(
			(
				event: MouseEvent | KeyboardEvent,
				analyticsEvent: UIAnalyticsEvent,
				dropOption: DropOption,
			) => {
				if (analyticsEvent) {
					const [actionSubject, eventAction] =
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_OPTION_SELECTED.split(' ');
					fireUIAnalytics(
						analyticsEvent.update({ action: eventAction, actionSubject }),
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_OPTION_SELECTED,
						{
							selectedOption: dropOption.analyticsId,
						},
					);
				}

				if (dropOption.type === BULK_CHANGE) {
					sendIDtoJira();
					return;
				}

				setIsMenuOpen(false);
				toggleModalDialog(true);
				setOptionSelected(dropOption);
				onOptionSelect?.(event);
				onModalOpen?.();
			},
			[onModalOpen, onOptionSelect, sendIDtoJira, toggleModalDialog],
		);

		const renderOneItem = useCallback(
			(getDropOption: GetDropOption) => {
				const option = getDropOption({
					selectedIssues,
					projectsById,
					...props,
				});
				const { label, disabled, analyticsId, disabledTooltip } = option;
				const title = formatMessage(label);
				const tooltip =
					isDefined(disabled) && disabled && disabledTooltip ? formatMessage(disabledTooltip) : '';

				return (
					<Tooltip content={tooltip} key={title} position="bottom">
						<DropMenuItem
							testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.drop-menu-item.${analyticsId}`}
							onClick={(event: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
								selectOption(event, analyticsEvent, option);
							}}
							isDisabled={disabled}
						>
							{title}
						</DropMenuItem>
					</Tooltip>
				);
			},
			[selectedIssues, projectsById, props, formatMessage, selectOption],
		);

		const toggleMenu = useCallback(
			(_: KeyboardEvent | MouseEvent, analyticsEvent?: UIAnalyticsEvent) => {
				if (fg('plan-timeline-a11y-meatball-menu')) {
					const [actionSubject, eventAction] =
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED.split(' ');

					const analyticsPayload = { action: eventAction, actionSubject };
					const analytics =
						analyticsEvent?.update(analyticsPayload) ?? createAnalyticsEvent?.(analyticsPayload);

					// Can remove if check on FG cleanup as analytics will always be defined 'plan-timeline-a11y-meatball-menu'
					if (analytics) {
						fireUIAnalytics(analytics, PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED, {
							selectedIssues: selectedIssues.length,
							isMenuOpen: !isMenuOpen,
							isNestedMeatballMenu: !!meatballTriggerRef,
						});
					}
				} else if (analyticsEvent) {
					const [actionSubject, eventAction] =
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED.split(' ');
					fireUIAnalytics(
						analyticsEvent.update({ action: eventAction, actionSubject }),
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED,
						{
							selectedIssues: selectedIssues.length,
							isMenuOpen: !isMenuOpen,
						},
					);
				}

				setIsMenuOpen((currentIsMenuOpen) => !currentIsMenuOpen);
			},
			[createAnalyticsEvent, selectedIssues.length, isMenuOpen, meatballTriggerRef],
		);

		const fireBulkActionSuccessAnalytics = useCallback(
			(analyticsEvent: UIAnalyticsEvent, selectedOption: string) => {
				if (analyticsEvent) {
					const [actionSubject, eventAction] =
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED.split(' ');
					fireUIAnalytics(
						analyticsEvent.update({ action: eventAction, actionSubject }),
						PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED,
						{
							selectedOption,
							selectedIssues: selectedIssues.length,
						},
					);
				}
			},
			[selectedIssues.length],
		);

		const handleCloseDialog = useCallback(
			(e?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent) => {
				toggleModalDialog(!isDialogOpen);
				onModalClose?.(e);
			},
			[isDialogOpen, onModalClose, toggleModalDialog],
		);

		const renderDialog = useCallback(() => {
			const Dialog = optionSelected && optionSelected.dialog;
			if (!isDialogOpen || !optionSelected || !Dialog) {
				return null;
			}

			switch (optionSelected.type) {
				case SET_DATES:
				case REMOVE_FROM_PLAN: {
					return (
						<Dialog
							toggleModalDialog={
								fg('plan_timeline_layering_wrapper')
									? handleCloseDialog
									: () => toggleModalDialog(!isDialogOpen)
							}
							bulkActionSuccess={(analyticsEvent: UIAnalyticsEvent) =>
								fireBulkActionSuccessAnalytics(analyticsEvent, optionSelected.analyticsId)
							}
							{...(fg('plan_timeline_layering_wrapper')
								? { shouldReturnFocus: meatballTriggerRef ?? buttonRef }
								: {})}
						/>
					);
				}
				default: {
					return (
						<Dialog
							toggleModalDialog={
								fg('plan_timeline_layering_wrapper')
									? handleCloseDialog
									: () => toggleModalDialog(!isDialogOpen)
							}
							UpdateAttributeForm={UpdateAttributeForm}
							bulkActionSuccess={(analyticsEvent: UIAnalyticsEvent) =>
								fireBulkActionSuccessAnalytics(analyticsEvent, optionSelected.analyticsId)
							}
							{...(fg('plan_timeline_layering_wrapper')
								? { shouldReturnFocus: meatballTriggerRef ?? buttonRef }
								: {})}
						/>
					);
				}
			}
		}, [
			fireBulkActionSuccessAnalytics,
			handleCloseDialog,
			isDialogOpen,
			meatballTriggerRef,
			optionSelected,
			toggleModalDialog,
		]);

		const onOpenChange = ({ isOpen }: { isOpen: boolean }) => {
			if (!isOpen) {
				setIsMenuOpen(false);
			}
		};

		const renderDropdown = useCallback(
			() => (
				<DropMenu
					isOpen={isMenuOpen}
					onOpenChange={onOpenChange}
					placement={
						meatballTriggerRef && fg('plan-timeline-a11y-meatball-menu')
							? 'right-start'
							: 'bottom-end'
					}
					shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
					trigger={({ triggerRef, ...triggerProps }) =>
						meatballTriggerRef && fg('plan-timeline-a11y-meatball-menu') ? (
							<DropdownItem
								{...triggerProps}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions"
								onClick={toggleMenu}
								ref={mergeRefs(triggerRef, meatballTriggerRef)}
								elemAfter={<ChevronRightIcon label="" />}
							>
								{formatMessage(messages.bulkActions)}
							</DropdownItem>
						) : (
							<StandardButton
								{...triggerProps}
								iconAfter={<ChevronDownIcon label="" />}
								onClick={toggleMenu}
								ref={
									fg('plan_timeline_layering_wrapper')
										? mergeRefs(triggerRef, buttonRef)
										: triggerRef
								}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions"
							>
								{formatMessage(messages.bulkActions)}
							</StandardButton>
						)
					}
				>
					<div css={contentWrapperStyles}>
						<BulkActionsMenu
							dropMenuItems={DROPMENU_ITEMS.map(renderOneItem)}
							moreDropMenuItems={MORE_DROPMENU_ITEMS.map(renderOneItem)}
							onOptionSelect={onOptionSelect}
							isNestedMenu={!!meatballTriggerRef}
						/>
					</div>
				</DropMenu>
			),
			[formatMessage, isMenuOpen, meatballTriggerRef, onOptionSelect, renderOneItem, toggleMenu],
		);

		return children ? (
			children(renderDropdown, renderDialog)
		) : (
			<>
				{renderDropdown()}
				{renderDialog()}
			</>
		);
	},
);

export const BulkActionsOld = memo(({ selectedIssues, projectsById, ...props }: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [optionSelected, setOptionSelected] = useState<DropOption | null>(null);

	const buttonRef = useRef<HTMLButtonElement | null>(null);

	const { formatMessage } = useIntl();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const toggleModalDialog = useCallback((isDialogOpen: boolean) => {
		setIsDialogOpen(isDialogOpen);
	}, []);

	const buildUrl = useCallback((): string => {
		const issueKeys = map(selectedIssues, ({ project: projectId, issueKey }) => {
			const project = projectsById[projectId];
			if (!issueKey) {
				return undefined;
			}
			return `${project.key}-${issueKey}`;
		});

		const encodedIds = encodeURIComponent(issueKeys.filter((key) => key).toString());

		return proxyContextSafeUrl(`/secure/GHBulkChange.jspa?issueKeys=${encodedIds}`);
	}, [projectsById, selectedIssues]);

	const sendIDtoJira = useCallback(() => {
		openInNewTab(buildUrl());
		// eslint-disable-next-line @typescript-eslint/no-shadow
		setIsMenuOpen((isMenuOpen) => !isMenuOpen);
	}, [buildUrl]);

	const selectOption = useCallback(
		(dropOption: DropOption, analyticsEvent: UIAnalyticsEvent) => {
			if (analyticsEvent) {
				const [actionSubject, eventAction] =
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_OPTION_SELECTED.split(' ');
				fireUIAnalytics(
					analyticsEvent.update({ action: eventAction, actionSubject }),
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_OPTION_SELECTED,
					{
						selectedOption: dropOption.analyticsId,
					},
				);
			}

			if (dropOption.type === BULK_CHANGE) {
				sendIDtoJira();
				return;
			}

			setIsMenuOpen(false);
			setIsDialogOpen(true);
			setOptionSelected(dropOption);
		},
		[sendIDtoJira],
	);

	const renderOneItem = useCallback(
		(getDropOption: GetDropOption) => {
			const option = getDropOption({
				selectedIssues,
				projectsById,
				...props,
			});
			const { label, disabled, analyticsId, disabledTooltip } = option;
			const title = formatMessage(label);
			const tooltip =
				isDefined(disabled) && disabled && disabledTooltip ? formatMessage(disabledTooltip) : '';

			return (
				<Tooltip content={tooltip} key={title} position="bottom">
					<DropMenuItem
						testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.drop-menu-item.${analyticsId}`}
						onClick={(_: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) =>
							selectOption(option, analyticsEvent)
						}
						isDisabled={disabled}
					>
						{title}
					</DropMenuItem>
				</Tooltip>
			);
		},
		[selectedIssues, projectsById, props, formatMessage, selectOption],
	);

	const toggleMenu = useCallback(
		(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (analyticsEvent) {
				const [actionSubject, eventAction] =
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED.split(' ');
				fireUIAnalytics(
					analyticsEvent.update({ action: eventAction, actionSubject }),
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED,
					{
						selectedIssues: selectedIssues.length,
						isMenuOpen: !isMenuOpen,
					},
				);
			}

			setIsMenuOpen((currentIsMenuOpen) => !currentIsMenuOpen);
		},
		[selectedIssues.length, isMenuOpen],
	);

	const fireBulkActionSuccessAnalytics = useCallback(
		(analyticsEvent: UIAnalyticsEvent, selectedOption: string) => {
			if (analyticsEvent) {
				const [actionSubject, eventAction] =
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED.split(' ');
				fireUIAnalytics(
					analyticsEvent.update({ action: eventAction, actionSubject }),
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED,
					{
						selectedOption,
						selectedIssues: selectedIssues.length,
					},
				);
			}
		},
		[selectedIssues.length],
	);

	const handleCloseDialog = useCallback(() => {
		toggleModalDialog(!isDialogOpen);
	}, [isDialogOpen, toggleModalDialog]);

	const renderDialog = useCallback(() => {
		const Dialog = optionSelected && optionSelected.dialog;
		if (!isDialogOpen || !optionSelected || !Dialog) {
			return null;
		}

		switch (optionSelected.type) {
			case SET_DATES:
			case REMOVE_FROM_PLAN: {
				return (
					<Dialog
						toggleModalDialog={
							fg('plan_timeline_layering_wrapper')
								? handleCloseDialog
								: () => toggleModalDialog(!isDialogOpen)
						}
						bulkActionSuccess={(analyticsEvent: UIAnalyticsEvent) =>
							fireBulkActionSuccessAnalytics(analyticsEvent, optionSelected.analyticsId)
						}
						{...(fg('plan_timeline_layering_wrapper') ? { shouldReturnFocus: buttonRef } : {})}
					/>
				);
			}
			default: {
				return (
					<Dialog
						toggleModalDialog={
							fg('plan_timeline_layering_wrapper')
								? handleCloseDialog
								: () => toggleModalDialog(!isDialogOpen)
						}
						UpdateAttributeForm={UpdateAttributeForm}
						bulkActionSuccess={(analyticsEvent: UIAnalyticsEvent) =>
							fireBulkActionSuccessAnalytics(analyticsEvent, optionSelected.analyticsId)
						}
						{...(fg('plan_timeline_layering_wrapper') ? { shouldReturnFocus: buttonRef } : {})}
					/>
				);
			}
		}
	}, [
		fireBulkActionSuccessAnalytics,
		handleCloseDialog,
		isDialogOpen,
		optionSelected,
		toggleModalDialog,
	]);

	const onOpenChange = ({ isOpen }: { isOpen: boolean }) => {
		if (!isOpen) {
			setIsMenuOpen(false);
		}
	};

	return (
		<>
			<DropMenu
				isOpen={isMenuOpen}
				onOpenChange={onOpenChange}
				placement="bottom-end"
				shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
				trigger={({ triggerRef, ...triggerProps }) => (
					<StandardButton
						{...triggerProps}
						iconAfter={<ChevronDownIcon label="" />}
						onClick={toggleMenu}
						ref={
							fg('plan_timeline_layering_wrapper') ? mergeRefs(triggerRef, buttonRef) : triggerRef
						}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions"
					>
						{formatMessage(messages.bulkActions)}
					</StandardButton>
				)}
			>
				<BulkActionsMenu
					dropMenuItems={DROPMENU_ITEMS.map(renderOneItem)}
					moreDropMenuItems={MORE_DROPMENU_ITEMS_OLD.map(renderOneItem)}
				/>
			</DropMenu>
			{renderDialog()}
		</>
	);
});

const contentWrapperStyles = css({
	minWidth: '160px',
});

export default componentWithFG('plan-timeline-a11y-meatball-menu', BulkActions, BulkActionsOld);
