import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	numberOfReleases: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.release-cell.number-of-releases',
		defaultMessage:
			'{count} <qualifier>{count, plural, one {Release} other {Releases}}</qualifier>',
		description:
			'Text to show the count of the total number of releases associated with a group of issues.',
	},
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.release-cell.aria-label',
		defaultMessage:
			'{count} {count, plural, one {release} other {releases}} for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Release field in the group header row',
	},
});
