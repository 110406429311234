import { getShowWarning } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type {
	MapDispatchToPropsSimple,
	MapStateToProps,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { toggleShowWarning } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/warning-settings/actions.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';

import type { DispatchProps, StateProps } from './types.tsx';
import { WarningCenter } from './view.tsx';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	showWarning: getShowWarning(state),
});

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	toggleShowWarning: () => {
		dispatch(toggleShowWarning());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningCenter);
