import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import {
	type TeamFilter,
	TEAM_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { UNDEFINED_KEY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getTeams } from '../../teams/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';
import { applyFilter as applyAttributeFilter } from '../attribute-filter/index.tsx';

export const NO_TEAM_ID = UNDEFINED_KEY;

export const applyFilter = (issue: Issue, filter: TeamFilter, optimizedMode: boolean): boolean =>
	applyAttributeFilter(issue, filter.value, 'team', optimizedMode);

export const getTeamsFilterPure = (state: State): TeamFilter =>
	getFiltersViewSettings(state)[TEAM_FILTER_ID] || {
		id: TEAM_FILTER_ID,
		value: [],
	};

export const getTeamFilter = createSelector(
	[getTeams, getTeamsFilterPure],
	(teams: Team[], pureFilter: TeamFilter): TeamFilter => {
		const { value = [] } = pureFilter;
		const filteredTeams = value.filter(
			(teamId: string) => teams.some(({ id }) => id === teamId) || teamId === NO_TEAM_ID,
		);
		return {
			id: TEAM_FILTER_ID,
			value: filteredTeams,
		};
	},
);
