import { createSelector, createStructuredSelector } from 'reselect';
import xor from 'lodash/xor';
import {
	isOptimizedMode,
	isReadOnly,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getExternalIncomingLinks,
	getInternalIncomingLinks,
	getIncomingLinks,
	getIncomingLinkOriginals,
	getExternalOutgoingLinks,
	getInternalOutgoingLinks,
	getOutgoingLinks,
	getOutgoingLinkOriginals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const emptyIssueLinksList: IssueLink[] = [];

const getIssue = (_: State, { issue }: OwnProps) => issue;

const getIncomingLinkCount = createSelector(
	[getIssue, getExternalIncomingLinks, getInternalIncomingLinks],
	(issue, externalIncomingLinks, internalIncomingLinks) => {
		const issueId = issue.id;

		const externalIncomingIssueLinks = externalIncomingLinks[issueId] || emptyIssueLinksList;
		const internalIncomingIssueLinks = internalIncomingLinks[issueId] || emptyIssueLinksList;

		return externalIncomingIssueLinks.length + internalIncomingIssueLinks.length;
	},
);

const getIncomingIsChanged = createSelector(
	[getIssue, getIncomingLinks, getIncomingLinkOriginals],
	(issue, incomingLinks, incomingLinkOriginals) => {
		const issueId = issue.id;

		const issueLinks = incomingLinks[issueId] || emptyIssueLinksList;
		const originalIssueLinks = incomingLinkOriginals[issueId] || emptyIssueLinksList;

		return (
			xor(
				issueLinks.map(({ itemKey }) => itemKey),
				originalIssueLinks.map(({ itemKey }) => itemKey),
			).length > 0
		);
	},
);

const getOutgoingLinkCount = createSelector(
	[getIssue, getExternalOutgoingLinks, getInternalOutgoingLinks],
	(issue, externalOutgoingLinks, internalOutgoingLinks) => {
		const issueId = issue.id;

		const externalOutgoingIssueLinks = externalOutgoingLinks[issueId] || emptyIssueLinksList;
		const internalOutgoingIssueLinks = internalOutgoingLinks[issueId] || emptyIssueLinksList;

		return externalOutgoingIssueLinks.length + internalOutgoingIssueLinks.length;
	},
);

const getOutgoingIsChanged = createSelector(
	[getIssue, getOutgoingLinks, getOutgoingLinkOriginals],
	(issue, outgoingLinks, outgoingLinkOriginals) => {
		const issueId = issue.id;

		const issueLinks = outgoingLinks[issueId] || emptyIssueLinksList;
		const originalIssueLinks = outgoingLinkOriginals[issueId] || emptyIssueLinksList;

		return (
			xor(
				issueLinks.map(({ itemKey }) => itemKey),
				originalIssueLinks.map(({ itemKey }) => itemKey),
			).length > 0
		);
	},
);

export const mapStateToPropsIncoming = createStructuredSelector<State, OwnProps, StateProps>({
	issueLinkCount: getIncomingLinkCount,
	isChanged: getIncomingIsChanged,
	isOptimizedMode,
	isReadOnly,
	direction: () => ISSUE_LINK_DIRECTION.INWARD,
});

export const mapStateToPropsOutgoing = createStructuredSelector<State, OwnProps, StateProps>({
	issueLinkCount: getOutgoingLinkCount,
	isChanged: getOutgoingIsChanged,
	isOptimizedMode,
	isReadOnly,
	direction: () => ISSUE_LINK_DIRECTION.OUTWARD,
});
