/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { jsx } from '@atlaskit/css';
import { useIntl } from '@atlassian/jira-intl';

import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SettingsItem } from '../settings-item/index.tsx';
import type { Props as SettingsItemProps } from '../settings-item/types.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export const WarningCenter = ({ showWarning, toggleShowWarning }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onChange = useCallback(() => {
		if (!showWarning) {
			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: 'toggle',
				actionSubjectId: 'toggleWarningCenter',
				attributes: {
					enabled: true,
					fromFeaturesPage: true,
				},
			});
		}
		toggleShowWarning();
	}, [showWarning, toggleShowWarning, createAnalyticsEvent]);

	const testId =
		'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.warning-center';

	const settings: SettingsItemProps['settings'] = useMemo(
		() => [
			{
				isChecked: showWarning,
				testId,
				descriptionId: `${testId}.description`,
				title: formatMessage(messages.title),
				toggleLabel: formatMessage(messages.toggleLabel),
				onChange,
			},
		],
		[showWarning, formatMessage, onChange],
	);

	return <SettingsItem settings={settings} />;
};
