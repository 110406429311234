import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { isCollapsed } from '../column-collapse/utils/state.tsx';

type GetColSpan = ({ row, column }: { row: number; column: number }) => number;

export const createGetSelectRowspan =
	(viewMode: ViewMode) =>
	(columnWidths: number[], getColspan: GetColSpan) =>
	({ row, column }: { row: number; column: number }) => {
		if (viewMode === VIEW_MODES.TIMELINE) {
			const colSpan = getColspan({ row, column });
			const isColumnCollapsed = isCollapsed(columnWidths, { column, colSpan });

			const fieldsHeaderColSpan = getColspan({ row: 0, column: 1 });
			const isFieldsHeaderCollapsed = isCollapsed(columnWidths, {
				column: 1,
				colSpan: fieldsHeaderColSpan,
			});

			if (isColumnCollapsed) {
				// check fields header collapsed state since cells enveloped in a colspan returns isCollapsed true
				if (isFieldsHeaderCollapsed && row === 0) {
					// fields header column
					return 9999;
				}
				if (isFieldsHeaderCollapsed) {
					return 0;
				}
				if (row === 1) {
					// fields sub header row
					return 9999;
				}
				if (row > 1) {
					// body rows
					return 0;
				}
			}
			return 1;
		}
		// list mode
		if (row === 0 && column === 1) {
			const fieldsHeaderColSpan = getColspan({ row: 0, column: 1 });
			return fieldsHeaderColSpan;
		}
		return 1;
	};
