import React, { useMemo, useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import TimelineExportTab from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/share-tabs/export/index.tsx';
import TimelineIframeTab from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/share-tabs/iframe/index.tsx';
import JiraShareButton from '@atlassian/jira-share-button/src/index.tsx';
import { useShareIntegrations } from '@atlassian/jira-share-integrations/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import { routeMapping } from './constants.tsx';
import messages from './messages.tsx';

const ShareButton = ({ routeName }: { routeName: RouteName }) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const [planId] = usePathParam('planId');

	const getIntegrationType = useCallback(() => {
		const shareInfo = routeMapping[routeName];

		if (shareInfo) {
			return {
				...shareInfo,
				shareTitle: formatMessage(shareInfo.shareTitle),
				shareFormTitle: formatMessage(shareInfo.shareFormTitle),
			};
		}

		return { integrationType: '', shareTitle: '', shareFormTitle: '' };
	}, [formatMessage, routeName]);

	const { integrationType, shareTitle, shareFormTitle } = getIntegrationType();
	const { shareIntegrations } = useShareIntegrations(integrationType);

	const entityAri = useMemo(
		() =>
			createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'plan',
				resourceId: String(planId),
			}),
		[cloudId, planId],
	);

	const additionalTabs = useMemo(() => {
		return routeName === 'software-plan-timeline'
			? [
					{
						label: formatMessage(messages.export),
						Content: TimelineExportTab,
					},
					{
						label: formatMessage(messages.iframeTitle),
						Content: TimelineIframeTab,
					},
				]
			: [];
	}, [formatMessage, routeName]);

	return (
		<Box xcss={wrapperStyles}>
			<JiraShareButton
				productId="jira"
				subProductId="jira-software"
				shareContentType={integrationType}
				integrationType={integrationType}
				shareFormTitle={shareFormTitle}
				shareTitle={shareTitle}
				shareIntegrations={shareIntegrations}
				integrationMode="tabs"
				objectAri={entityAri}
				triggerButtonStyle="icon-only"
				copyTooltipText={formatMessage(messages.copyTooltipText)}
				additionalTabs={additionalTabs}
				builtInTabContentWidth={352}
			/>
		</Box>
	);
};

const wrapperStyles = xcss({
	display: 'inline-flex',
});

export default ShareButton;
