import * as R from 'ramda';
import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getEnrichedCrossProjectVersions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import { getCrossProjectReleaseFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/cross-project-release-filter/index.tsx';
import {
	getProjectFilter,
	getFilteredProjects,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/project-filter/index.tsx';
import { getReleaseFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/release-filter/index.tsx';
import { getReleaseBarState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/release-bar/index.tsx';
import {
	getEnrichedVersions,
	getSolutionVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import type {
	ReleaseFilter,
	CrossProjectReleaseFilter,
	ProjectFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';
import { isProject } from './utils.tsx';

export const getVersionsPure = (
	enrichedVersions: EnrichedVersion[],
	versionFilter: ReleaseFilter,
	projectFilter: ProjectFilter,
): EnrichedVersion[] => {
	const { value, versionsFromCrossProjectReleaseFilterValue } = versionFilter;
	const { value: projectValue } = projectFilter;
	const filterValue = isDefined(versionsFromCrossProjectReleaseFilterValue)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[...value, ...(versionsFromCrossProjectReleaseFilterValue as string[])]
		: value;

	return enrichedVersions.filter(
		({ id, projects }) =>
			(filterValue.length === 0 || R.contains(id, filterValue)) &&
			(projectValue.length === 0 ||
				projects.some((project) => {
					if (!isProject(project)) return false;
					return projectValue.includes(project.id);
				})),
	);
};

export const getCrossProjectVersionsPure = (
	enrichedCrossProjectVersions: EnrichedCrossProjectVersion[],
	versionFilter: ReleaseFilter,
	crossProjectVersionFilter: CrossProjectReleaseFilter,
	filteredProjects: Project[],
): EnrichedCrossProjectVersion[] => {
	const { value, versionsFromCrossProjectReleaseFilterValue } = versionFilter;
	const versionFilterValue = isDefined(versionsFromCrossProjectReleaseFilterValue)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[...value, ...(versionsFromCrossProjectReleaseFilterValue as string[])]
		: value;
	const filteredByProjectVersionsSet = new Set(
		R.flatten(R.map(R.prop('versions'), filteredProjects)),
	);

	const filterByProject = (versions: Version[]) =>
		versions.some(({ id }) => filteredByProjectVersionsSet.has(id));

	return enrichedCrossProjectVersions.filter(({ id, versions }) => {
		if (crossProjectVersionFilter.value.length > 0 || versionFilterValue.length > 0) {
			return R.contains(id, crossProjectVersionFilter.value) && filterByProject(versions);
		}
		return filterByProject(versions);
	});
};

const getCrossProjectVersions = createSelector(
	[
		getEnrichedCrossProjectVersions,
		getReleaseFilter,
		getCrossProjectReleaseFilter,
		getFilteredProjects,
	],
	getCrossProjectVersionsPure,
);

const getVersions = createSelector(
	[getEnrichedVersions, getReleaseFilter, getProjectFilter],
	getVersionsPure,
);

export default createStructuredSelector<State, OwnProps, StateProps>({
	mode: getMode,
	versions: getVersions,
	crossProjectVersions: getCrossProjectVersions,
	releaseBarState: getReleaseBarState,
	solutionVersionsById: getSolutionVersionsById,
});
