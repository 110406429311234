import { getRollupMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { getSprintsForTeam } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import {
	getExistingTeams,
	getAllExistingTeams,
	getAdditionalTeamsById,
	getScenarioRemovedAtlassianTeams,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State, props: Props) => ({
	teams: getExistingTeams(state),
	allTeams: getAllExistingTeams(state),
	scenarioRemovedAtlassianTeams: getScenarioRemovedAtlassianTeams(state),
	additionalTeamsById: getAdditionalTeamsById(state),
	sprintsForTeam: getSprintsForTeam(state),
	teamRollUpForIssue: teamRollUpForIssue(state, props),
});

const teamRollUpForIssue = (state: State, props: Props) => {
	if (fg('plans-timeline-be-rollups')) {
		return getRollupMap(state)[props.issue.id]?.team ?? null;
	}
	return isDefined(getRollupMap(state)[props.issue.id])
		? getRollupMap(state)[props.issue.id].team
		: null;
};
