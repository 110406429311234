import React, { memo, useMemo } from 'react';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export const useRandomWidth = (width: number, variance: number) => {
	const random = useMemo(() => Math.random(), []);
	const varianceAdjustment = 1 + (random * 2 - 1) * variance;
	return Math.min(width * varianceAdjustment, 1);
};

const VariableWidthSkeleton = ({
	width = 1,
	variance = 0.1,
}: {
	width?: number;
	variance?: number;
}) => {
	const adjustedWidth = useRandomWidth(width, variance);

	return <Skeleton width={`${adjustedWidth * 100}%`} height="20px" />;
};

const HeaderSkeleton = () => (
	<Box xcss={rowStyles}>
		<Box xcss={[cellStyles, fillerSizeStyle]} />
		<Box xcss={[cellStyles, largeSizeStyle]}>
			<VariableWidthSkeleton width={0.4} />
		</Box>
		<Box xcss={[cellStyles, largeSizeStyle]}>
			<VariableWidthSkeleton width={0.5} />
		</Box>
		<Box xcss={[cellStyles, mediumSizeStyle]}>
			<VariableWidthSkeleton width={0.5} />
		</Box>
		<Box xcss={[cellStyles, mediumSizeStyle]}>
			<VariableWidthSkeleton width={0.8} />
		</Box>
		<Box xcss={[cellStyles, mediumSizeStyle]}>
			<VariableWidthSkeleton width={0.5} />
		</Box>
		<Box xcss={[cellStyles, smallSizeStyle]}>
			<VariableWidthSkeleton width={0.7} />
		</Box>
	</Box>
);

const RowSkeleton = () => (
	<Box xcss={rowStyles}>
		<Box xcss={[cellStyles, fillerSizeStyle]} />
		<Box xcss={[cellStyles, largeSizeStyle]}>
			<VariableWidthSkeleton width={0.5} />
		</Box>
		<Box xcss={[cellStyles, largeSizeStyle]}>
			<VariableWidthSkeleton width={0.8} />
		</Box>
		<Box xcss={[cellStyles, mediumSizeStyle]}>
			<VariableWidthSkeleton width={0.7} />
		</Box>
		<Box xcss={[cellStyles, mediumSizeStyle]}>
			<VariableWidthSkeleton width={0.6} />
		</Box>
		<Box xcss={[cellStyles, mediumSizeStyle]}>
			<VariableWidthSkeleton width={0.7} />
		</Box>
		<Box xcss={[cellStyles, smallSizeStyle]}>
			<Skeleton width="20px" height="20px" />
		</Box>
	</Box>
);

const DependenciesSkeleton = memo(({ issueCount }: { issueCount: number }) =>
	issueCount > 0 ? (
		<Box xcss={skeletonWrapperStyles}>
			<Box xcss={[sectionWrapperStyles, tableWrapperStyles]}>
				<HeaderSkeleton />
				{Array.from({ length: Math.min(issueCount, 10) }).map((_, index) => (
					<RowSkeleton key={index} />
				))}
			</Box>
			<Box xcss={sectionWrapperStyles}>
				<Skeleton width="100%" height="32px" />
				<Inline spread="space-between" space="space.100">
					<Skeleton width="40%" height="20px" />
					<Skeleton width="30%" height="20px" />
				</Inline>
			</Box>
		</Box>
	) : (
		<Box xcss={skeletonWrapperStyles}>
			<Box xcss={sectionWrapperStyles}>
				<Inline spread="space-between" space="space.100">
					<Skeleton width="50%" height="40px" />
					<Skeleton width="100%" height="40px" />
					<Inline spread="space-between" space="space.100" xcss={buttonsWrapperStyles}>
						<Skeleton width="40px" height="40px" />
						<Skeleton width="40px" height="40px" />
					</Inline>
				</Inline>
			</Box>
		</Box>
	),
);

const cellStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
});

const fillerSizeStyle = xcss({
	width: '5px',
});

const smallSizeStyle = xcss({
	width: '25%',
});

const mediumSizeStyle = xcss({
	width: '40%',
});

const largeSizeStyle = xcss({
	width: '70%',
});

const rowStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	height: '36px',
	gap: 'space.200',
	flexShrink: 0,
});

const sectionWrapperStyles = xcss({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.200',
	paddingBlock: 'space.200',
	paddingInline: 'space.300',
});

const tableWrapperStyles = xcss({
	gap: 'space.0',
	borderBottom: `1px solid ${token('color.border')}`,
	maxHeight: '400px',
	overflow: 'hidden',
	boxSizing: 'border-box',
});

const skeletonWrapperStyles = xcss({
	width: '800px',
	marginBottom: 'space.100',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
});

const buttonsWrapperStyles = xcss({
	flexShrink: 0,
});

export default DependenciesSkeleton;
