import React, { useState, type ChangeEvent } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '@atlaskit/modal-dialog';
import AkTextField from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { getEstimateValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/estimation/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { PlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MODAL_DIALOG_WIDTH } from '../../types.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const useCurrentValue = ({
	selectedIssues,
	planningUnit,
	workingHours,
}: {
	planningUnit: PlanningUnit;
	selectedIssues: Issue[];
	workingHours: number;
}): string => {
	const { formatMessage } = useIntl();

	const firstValue = getEstimateValue(planningUnit, selectedIssues[0], workingHours);
	const isSameValue = selectedIssues.every(
		(issue) => getEstimateValue(planningUnit, issue, workingHours) === firstValue,
	);

	return isSameValue
		? firstValue?.toString(10) ?? formatMessage(commonMessages.none)
		: formatMessage(messages.variousValues);
};

const withTooltip = (component: JSX.Element, tooltip: string) =>
	tooltip ? <Tooltip content={tooltip}>{component}</Tooltip> : component;

function SetEstimateDialog({
	bulkActionSuccess,
	bulkUpdateAttribute,
	plan: { planningUnit },
	selectedIssues,
	toggleModalDialog,
	workingHours,
	shouldReturnFocus,
}: Props) {
	const [estimate, setEstimate] = useState('');
	const [showEstimateError, setShowEstimateError] = useState(false);
	const [clearEstimates, setClearEstimates] = useState(false);

	const { formatMessage } = useIntl();
	const currentValue = useCurrentValue({ planningUnit, selectedIssues, workingHours });
	const currentContent = withTooltip(
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['value-placeholder']}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles['current-value-title']}>{currentValue}</div>
		</div>,
		currentValue,
	);

	return (
		<ShortcutScope>
			<ModalDialog
				messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate.modal-dialog"
				messageType="transactional"
				autoFocus
				onClose={toggleModalDialog}
				shouldScrollInViewport
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate"
				width={MODAL_DIALOG_WIDTH}
				{...(fg('plan_timeline_layering_wrapper') ? { shouldReturnFocus } : {})}
			>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.setHeader)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<section className={styles['form-group']}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles['current-value']}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles['value-label']}>{formatMessage(commonMessages.current)}</div>
							{currentContent}
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.icon}>
							<ArrowRightIcon spacing="spacious" label="" />
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles['new-value']}>
							<Field
								isRequired
								label={formatMessage(messages.newEstimate, {
									planningUnit: formatMessage(messages[planningUnit]),
								})}
								name="estimate"
							>
								{({ fieldProps }) => (
									<>
										<AkTextField
											{...fieldProps}
											autoFocus
											isDisabled={clearEstimates}
											min={0}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const newValue = e.target.value;
												setShowEstimateError(Number(newValue) < 0);
												setEstimate(newValue);
											}}
											type="number"
											value={estimate}
										/>
										{showEstimateError && (
											<ErrorMessage>{formatMessage(messages.outOfRange)}</ErrorMessage>
										)}
									</>
								)}
							</Field>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.checkbox}>
								<Checkbox
									label={formatMessage(messages.clearEstimates)}
									onChange={(event) => {
										setClearEstimates(event.target.checked);
									}}
								/>
							</div>
						</div>
					</section>
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={toggleModalDialog}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button
						appearance="primary"
						isDisabled={clearEstimates ? false : estimate === '' || Number(estimate) < 0}
						onClick={(
							_,
							analyticsEvent,
							event?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
						) => {
							bulkUpdateAttribute({ estimate, clearEstimates });
							if (fg('plan-timeline-a11y-meatball-menu')) {
								toggleModalDialog(event);
							} else {
								toggleModalDialog();
							}
							bulkActionSuccess(analyticsEvent);
						}}
					>
						{formatMessage(commonMessages.apply)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}

export default SetEstimateDialog;
