import React, { useEffect } from 'react';
import type { Props } from './types.tsx';
import { useCollapsibleWidths } from './utils/hooks.tsx';
import { CollapseProvider, useContextActions } from './utils/state.tsx';

/** Provides the ability to toggle the columns. */
export const Collapsible = ({
	columnIds,
	resizer,
	defaultColumnWidths,
	onColumnWidthsChange,
	children,
}: Props) => {
	const widths = useCollapsibleWidths({ preview: true });

	return (
		<CollapseProvider
			widths={widths}
			defaultWidths={defaultColumnWidths}
			columnIds={columnIds}
			resizer={resizer}
			updateWidths={onColumnWidthsChange}
		>
			{children}
			<Observer />
		</CollapseProvider>
	);
};

/** Cache the pre-collapsed widths of the columns to expand after they are collapsed */
const Observer = () => {
	const widths = useCollapsibleWidths({ preview: false });
	const { cache } = useContextActions();

	useEffect(() => {
		cache(widths);
	}, [cache, widths]);

	return null;
};
