import type { Effect } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { fg } from '@atlassian/jira-feature-gating';

export type ActionWithExternalPromise<T> = T & {
	payload: {
		promise?: {
			resolve: Function;
			reject: Function;
		};
	};
};

export type PayloadWithExternalPromise<T> = T & {
	promise?: {
		resolve?: Function;
		reject?: Function;
	};
};

export function* withExternalPromiseResolve(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	block: () => Generator<Effect, any, any>,
	promise: { resolve: Function; reject: Function } | undefined,
	checkResult = false,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (!promise || !promise?.resolve || !promise?.reject) {
		return yield call(block);
	}

	try {
		const result = yield call(block);

		if (checkResult && result === undefined) {
			promise.reject(new Error('No result returned from the block'));
		} else {
			promise.resolve(result);
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		promise.reject(err);
	}
}

const SAGA_ERROR_PREFIX = 'arj-saga';

export const toErrorID = (error: Error, message?: string) => {
	const errorId = message || error?.message || 'plans-no-error-message';
	return `${SAGA_ERROR_PREFIX}-${errorId}`;
};

export const tryParseJson = (raw: string) => {
	try {
		return JSON.parse(raw);
	} catch {
		return raw;
	}
};

export const addSagaSpan = (name: string, start: number, end: number) => {
	if (fg('plans_action_timing_metrics')) {
		addSpanToAll('custom', name, [{ name: 'redux' }, { name: 'saga' }], start, end);
	}
};
