import { createHorizontalScrollingHook } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx'; /** Returns the viewport width for horizontal/non-horizontal mode. */
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createSelector } from '@atlassian/react-sweet-state';

export const useTimelineViewportWidth = createHorizontalScrollingHook<number, number>(
	createSelector(
		(_, fallback) => fallback,
		(state) => state.zoomLevel,
		(state) => state.viewport.width,
		(fallback, zoomLevel, viewportWidth) => (zoomLevel === undefined ? fallback : viewportWidth),
	),
);
