import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';

export type GetProjectSettingsUrl = {
	projectKey: string;
	projectType?: ProjectType | string;
};

export const SUMMARY_VIEW_SOURCE = 'summaryView' as const;
export const LIST_VIEW_SOURCE = 'listView' as const;
export const LIST_VIEW_NO_RESULTS_SOURCE = 'listViewNoResults' as const;
export const LIST_VIEW_GROUP_BY_STATUS_SOURCE = 'listViewGroupByStatus' as const;
export const ISSUE_VIEW_SOURCE = 'issueView' as const;
export const GIC_VIEW_SOURCE = 'gicView' as const;

export const EDIT_STATUS_ACTION_SUBJECT_ID = 'editStatusButton';

export const DELETE_STATUS_ACTION_SUBJECT_ID = 'deleteStatusButton';

export type ConfigureSource =
	| typeof SUMMARY_VIEW_SOURCE
	| typeof LIST_VIEW_SOURCE
	| typeof LIST_VIEW_NO_RESULTS_SOURCE
	| typeof LIST_VIEW_GROUP_BY_STATUS_SOURCE
	| typeof ISSUE_VIEW_SOURCE
	| typeof GIC_VIEW_SOURCE;

export type EditActionSubjectId = typeof EDIT_STATUS_ACTION_SUBJECT_ID;

export type DeleteActionSubjectId = typeof DELETE_STATUS_ACTION_SUBJECT_ID;

export type Project = {
	label: string;
	value: string;
	iconUrl: string;
	projectKey: string;
	projectType: ProjectType;
	isSimplified: boolean;
	classificationTags?: string[];
};
