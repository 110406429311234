import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import mapStateToProps from './query.tsx';
import { GroupHeaderField as View, GroupHeaderFieldOld as ViewOld } from './view.tsx';

export const GroupHeaderFieldNew = connect(mapStateToProps, null)(View);

export const GroupHeaderFieldOld = connect(mapStateToProps, null)(ViewOld);

export const GroupHeaderField = componentWithCondition(
	() => expValEquals('aggregate_fields_for_plan_m2_experiment', 'cohort', 'variation'),
	GroupHeaderFieldNew,
	GroupHeaderFieldOld,
);
