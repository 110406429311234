/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import BulkActions from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/bulk-actions/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const ScopeSubheader = ({
	totalSelected,
	isSelected,
	isReadOnly,
	isIndeterminate,
	toggleAllSelected,
	rankDigits,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={containerStyles}>
			{!isReadOnly && (
				<>
					<Checkbox
						isChecked={isSelected}
						isIndeterminate={isIndeterminate}
						onChange={toggleAllSelected}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.checkbox"
						aria-label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.selectAllIssueLabelIssueTermRefresh
								: messages.selectAllIssueLabel,
						)}
					/>
					{fg('plan-timeline-a11y-meatball-menu') ? (
						<ScreenReaderText aria-live="polite">
							{isSelected
								? formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.selectedIssuesIssueTermRefresh
											: messages.selectedIssues,
										{ totalSelected },
									)
								: formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.noIssuesSelected
											: messages.noIssuesSelectedIssueTermRefresh,
									)}
						</ScreenReaderText>
					) : null}
					{isSelected ? (
						<>
							<small
								{...(!fg('plan-timeline-a11y-meatball-menu') ? { style: { margin: 0 } } : {})}
								css={[fg('plan-timeline-a11y-meatball-menu') && selectedIssueCountStyles]}
								data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.issues-selected-count"
							>
								<FormattedMessage
									{...(fg('jira-issue-terminology-refresh-m3')
										? messages.selectedIssuesIssueTermRefresh
										: messages.selectedIssues)}
									values={{ totalSelected }}
								/>
							</small>
							{fg('plan-timeline-a11y-meatball-menu') ? (
								<div css={bulkActionsWrapperStyles}>
									<BulkActions />
								</div>
							) : null}
						</>
					) : (
						<Box
							xcss={rankHeaderStyles}
							style={{ marginRight: 10 * rankDigits }}
							data-name="rank-header"
						>
							#
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	boxSizing: 'border-box',
});

const bulkActionsWrapperStyles = css({
	marginLeft: 'auto',
	flexShrink: '0',
});

const selectedIssueCountStyles = css({
	margin: '0',
	minWidth: '0',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flexGrow: '0',
	flexShrink: '1',
});

const rankHeaderStyles = xcss({
	color: 'color.text.subtlest',
	display: 'inline-flex',
});
