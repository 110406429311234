import {
	START,
	type StartAction,
	INTERRUPT,
	type InterruptAction,
	STOP,
	type StopAction,
	INCREMENT_NUMBER_OF_COMMITTED_CHANGES,
	type IncrementAction,
	ADD_NUMBER_OF_COMMITTED_CHANGES,
	type AddAction,
	ADD_TO_ISSUE_CHANGE_QUEUE,
	type AddToIssueChangeQueueAction,
	type SetPendingChangesAction,
	type RemoveFromPendingChangesAction,
	type ClearPendingChangesAction,
	SET_PENDING_CHANGES,
	REMOVE_FROM_PENDING_CHANGES,
	CLEAR_PENDING_CHANGES,
	REMOVE_FROM_ISSUE_CHANGE_QUEUE,
	type RemoveFromIssueChangeQueueAction,
	UPDATE_ISSUE_REFERENCES,
	type UpdateIssueReferencesAction,
} from './actions.tsx';
import type { Commit, IssueChangeQueueEntry } from './types.tsx';

type Action =
	| StartAction
	| InterruptAction
	| IncrementAction
	| StopAction
	| AddAction
	| AddToIssueChangeQueueAction
	| RemoveFromIssueChangeQueueAction
	| SetPendingChangesAction
	| RemoveFromPendingChangesAction
	| ClearPendingChangesAction
	| UpdateIssueReferencesAction;

const initialState: Commit = {
	inProgress: false,
	isInterrupted: false,
	progressStats: {
		numberOfCommittedChanges: 0,
		totalChanges: 0,
	},
	pendingChanges: {
		ISSUE: [],
		RELEASE: [],
		CROSS_PROJECT_RELEASE: [],
		TEAM: [],
		RESOURCE: [],
		PLANNED_CAPACITY: [],
		SPRINT: [],
		NONE: [],
	},
	issueChangeQueue: new Map(),
	changesCommitted: 0,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Commit = initialState, action: Action) => {
	switch (action.type) {
		case START:
			return {
				...state,
				inProgress: true,
				progressStats: {
					numberOfCommittedChanges: 0,
					totalChanges: action.payload,
				},
			};
		case INCREMENT_NUMBER_OF_COMMITTED_CHANGES:
			return {
				...state,
				progressStats: {
					...state.progressStats,
					numberOfCommittedChanges: state.progressStats.numberOfCommittedChanges + 1,
				},
			};
		case ADD_NUMBER_OF_COMMITTED_CHANGES:
			return {
				...state,
				progressStats: {
					...state.progressStats,
					numberOfCommittedChanges: state.progressStats.numberOfCommittedChanges + action.payload,
				},
			};
		case INTERRUPT:
			return {
				...state,
				isInterrupted: true,
			};
		case STOP:
			return {
				...state,
				inProgress: false,
				isInterrupted: false,
				progressStats: {
					...initialState.progressStats,
				},
				changesCommitted: state.progressStats.numberOfCommittedChanges,
			};
		case ADD_TO_ISSUE_CHANGE_QUEUE: {
			const currentEntry = state.issueChangeQueue.get(action.payload.id) ?? [];
			const newEntry = currentEntry.concat([action.payload.entry]);
			return {
				...state,
				issueChangeQueue: state.issueChangeQueue.set(action.payload.id, newEntry),
			};
		}
		case REMOVE_FROM_ISSUE_CHANGE_QUEUE:
			state.issueChangeQueue.delete(action.payload);
			return state;
		case SET_PENDING_CHANGES:
			return {
				...state,
				pendingChanges: {
					...state.pendingChanges,
					...action.payload,
				},
			};
		case REMOVE_FROM_PENDING_CHANGES:
			return {
				...state,
				pendingChanges: {
					...state.pendingChanges,
					[action.payload.type]: state.pendingChanges[action.payload.type].filter(
						(id) => action.payload.id !== id,
					),
				},
			};
		case CLEAR_PENDING_CHANGES:
			return {
				...state,
				pendingChanges: initialState.pendingChanges,
			};
		case UPDATE_ISSUE_REFERENCES: {
			// replace issue id in issue change queue
			const queueForThisId = state.issueChangeQueue.get(action.payload.id);
			if (queueForThisId != null) {
				const updatedQueue: IssueChangeQueueEntry[] = queueForThisId.map((entry) => {
					if (entry.type === 'UPDATE') {
						return {
							type: 'UPDATE',
							payload: {
								...entry.payload,
								itemKeys: entry.payload.itemKeys.map((key) =>
									key === action.payload.id ? action.payload.generatedId : key,
								),
							},
						};
					}
					if (entry.type === 'DELETE') {
						return {
							type: 'DELETE',
							payload: {
								...entry.payload,
								itemKeys: entry.payload.itemKeys.map((key) =>
									key === action.payload.id ? action.payload.generatedId : key,
								),
							},
						};
					}
					return entry;
				});
				state.issueChangeQueue.set(action.payload.generatedId, updatedQueue);
				state.issueChangeQueue.delete(action.payload.id);
			}
			return {
				...state,
				pendingChanges: {
					...state.pendingChanges,
					ISSUE: state.pendingChanges.ISSUE.map((id) =>
						id === action.payload.id ? action.payload.generatedId : id,
					),
				},
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
