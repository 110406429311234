import countBy from 'lodash/countBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getAssigneesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/index.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import type { OwnProps, MapStateToProps } from './types.tsx';

const getUniqueAssignees = (assignees: string[]): string[] => {
	const assigneeCounts = countBy(assignees);
	return Object.keys(assigneeCounts).sort((a, b) => assigneeCounts[b] - assigneeCounts[a]);
};

const getValues = createSelector(
	[getAssigneesById, (_: State, { rawValues }: OwnProps) => rawValues],
	(assigneesById: Record<string, Person>, rawValues: string[]): Person[] => {
		const uniqueAssignees = getUniqueAssignees(rawValues);
		return uniqueAssignees.map((id) => assigneesById[id]).filter(Boolean);
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	values: getValues,
	isExportMode,
});

export default mapStateToProps;
