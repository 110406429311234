import React from 'react';
import Avatar from '@atlaskit/avatar';
import PeopleIcon from '@atlaskit/icon/core/migration/people-group--people';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import NotInPlan from '../not-in-plan/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function Team(props: Props) {
	const { team, teams } = props;
	if (!team) {
		return <div>-</div>;
	}
	const targetTeam = teams.find((currentTeam) => currentTeam.id === team);
	if (targetTeam) {
		const { avatarUrl, title, verified } = targetTeam;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['title-group']}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.avatar}>
					{!avatarUrl ? (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.defaultTeamAvatar}>
							<PeopleIcon label="" LEGACY_size="small" />
						</div>
					) : (
						<Avatar size="xsmall" src={avatarUrl} />
					)}
				</div>
				<EllipsedWithTooltip content={title} id={team}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.title}>{title}</span>
				</EllipsedWithTooltip>
				{verified && fg('plans_managed_teams_indicator_timeline_enabled') && (
					<VerifiedTeamIcon showTooltip />
				)}
			</div>
		);
	}
	return (
		<div>
			<NotInPlan />
		</div>
	);
}
