import React from 'react';
import { Text, Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import image from '../../../assets/very-specific-illu.create-initiative.svg';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding/index.tsx';
import {
	CttOnboardingVariant,
	type CttOnboardingVariantType,
} from '../../../controllers/ctt-onboarding/types.tsx';
import { CttSpotlights } from '../../../controllers/ctt-spotlights/index.tsx';
import { messages } from './messages.tsx';

interface Props {
	variant: CttOnboardingVariantType;
}

export const CreateInitativeSpotlight = ({ variant }: Props) => {
	const { formatMessage } = useIntl();

	const [, { nextCttStage }] = useCttOnboardingTour();

	return (
		<ContextualAnalyticsData
			sourceName="topLevelPlanningCreateInitiativeSpotlight"
			sourceType={MODAL}
		>
			<JiraSpotlight
				actions={[
					{
						text: formatMessage(messages.spotlightGotIt),
						onClick: (_, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'topLevelPlanningCreateInitiativeNext', {
								isInitiativeLevelConfigured: variant !== CttOnboardingVariant.NO_INITIATIVE,
							});
							nextCttStage();
						},
					},
				]}
				image={image}
				dialogWidth={275}
				heading={formatMessage(messages.spotlightCreateInitativeTitle)}
				target={CttSpotlights.CreateIssue}
				key={CttSpotlights.CreateIssue}
				targetRadius={3}
				targetBgColor={token('elevation.surface')}
				messageId="portfolio-3-onboarding.ui.ctt-onboarding.spotlight-create-initative.jira-spotlight"
				messageType="engagement"
			>
				<Stack space="space.100">
					<Text>
						<FormattedMessage {...messages.spotlightCreateInitativeBodyP1} />
					</Text>
					<Text>
						<FormattedMessage {...messages.spotlightCreateInitativeBodyP2} />
					</Text>
				</Stack>
			</JiraSpotlight>
			<FireScreenAnalytics
				attributes={{
					isInitiativeLevelConfigured: variant !== CttOnboardingVariant.NO_INITIATIVE,
				}}
			/>
		</ContextualAnalyticsData>
	);
};
