import React, { useState, useCallback, forwardRef } from 'react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss, Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';

import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const CustomTooltipComponent = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => {
	const { children, ...rest } = props;
	return (
		<TooltipPrimitive {...rest} ref={ref}>
			<Box xcss={tooltipContainerStyles}>{children}</Box>
		</TooltipPrimitive>
	);
});

const WarningDetails = (props: Props) => {
	const { warnings, opacity, isOpen: defaultIsOpen, zIndex } = props;
	const [isOpen, setIsOpen] = useState(!!defaultIsOpen);
	const { formatMessage } = useIntl();

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onHoverChanged = useCallback((isHovered: boolean) => {
		setIsOpen(isHovered);
	}, []);

	const renderTrigger = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => (
			<HoverObserver onHoverChanged={onHoverChanged}>
				<div
					style={{ opacity }}
					ref={ref}
					data-testid="portfolio-3-portfolio.common.warning-details.warning-icon"
				>
					<WarningIcon
						label=""
						spacing="none"
						color={token('color.icon.warning')}
						LEGACY_size="small"
						{...triggerProps}
					/>
				</div>
			</HoverObserver>
		),
		[onHoverChanged, opacity],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const internationaliseNestedMessages = (values: { [key: string]: any }) => {
		const amount = values.amount ? formatMessage(values.amount.key, values.amount) : null;
		return { ...values, amount };
	};

	const renderContent = () => (
		<>
			{warnings &&
				warnings.map((warning, index) => (
					<Box key={index} xcss={entryStyles}>
						<Box xcss={headingStyles}>
							{warnings.length > 1 && `${index + 1}. `}
							<FormattedMessage
								{...warning.header}
								values={internationaliseNestedMessages(warning.messageValues)}
							/>
						</Box>
						{warning.message && (
							<FormattedMessage
								{...warning.message}
								values={internationaliseNestedMessages(warning.messageValues)}
							/>
						)}
					</Box>
				))}
		</>
	);

	return fg('chronos_a11y_fixes_jtran2') ? (
		<Tooltip position="bottom-start" component={CustomTooltipComponent} content={renderContent()}>
			{(tooltipProps) => (
				<Pressable
					{...tooltipProps}
					xcss={warningsButtonStyles}
					style={{ opacity }}
					aria-label={formatMessage(messages.warningsButtonLabel)}
					testId="portfolio-3-portfolio.common.warning-details.warning-icon"
				>
					<WarningIcon
						label=""
						spacing="none"
						color={token('color.icon.warning')}
						LEGACY_size="small"
					/>
				</Pressable>
			)}
		</Tooltip>
	) : (
		<Popup
			messageId="portfolio-3-portfolio.common.warning-details.popup"
			messageType="transactional"
			zIndex={zIndex}
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
		/>
	);
};

export default WarningDetails;

const entryStyles = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	minWidth: '300px',
	maxWidth: '448px',
});

const headingStyles = xcss({
	fontWeight: token('font.weight.bold'),
	marginBottom: 'space.100',
});

const tooltipContainerStyles = xcss({
	background: token('elevation.surface.overlay'),
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.overlay',
	boxSizing: 'content-box',
	color: 'color.text',
	minWidth: '300px',
	maxWidth: '448px',
});

const warningsButtonStyles = xcss({
	display: 'flex',
	backgroundColor: 'color.background.neutral.subtle',
	padding: 'space.0',
});
