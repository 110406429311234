import React from 'react';
import type { ContentProps } from '@atlaskit/popup';
import BaseComponent from '@atlassian/jira-portfolio-3-portfolio/src/common/view/base-component/index.tsx';
import { DependenciesDialogContext } from '../dependencies-old/context.tsx';
import DependenciesFlyout from '../dependencies-old/flyout/index.tsx';
import Dependencies from '../dependencies-old/view.tsx';
import IncomingDependenciesCellHOC from './incoming-dependencies/index.tsx';
import OutgoingDependenciesCellHOC from './outgoing-dependencies/index.tsx';
import type { Props, State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class DependenciesCell extends BaseComponent<Props, State> {
	state = {
		dialogLockingInstances: {},
	};

	toggleDialogLocked = (instanceName: string) => (isLocked: boolean) => {
		this.setState(({ dialogLockingInstances }) => ({
			dialogLockingInstances: { ...dialogLockingInstances, [instanceName]: isLocked },
		}));
	};

	render() {
		const { issue, externalIssueLinks, internalIssueLinks, internalOriginalIssueLinks } =
			this.props;
		const { direction, ...dependenciesProps } = this.props;
		const isDialogLocked = Object.values(this.state.dialogLockingInstances).some(
			(value) => !!value,
		);
		const renderDialogContent = (update?: ContentProps['update']) => (
			<DependenciesDialogContext.Provider
				value={{
					isDialogLocked,
					toggleDialogLocked: this.toggleDialogLocked,
				}}
			>
				<DependenciesFlyout
					direction={direction}
					issue={issue}
					externalIssueLinks={externalIssueLinks}
					internalIssueLinks={internalIssueLinks}
					internalOriginalIssueLinks={internalOriginalIssueLinks}
					context="dropdown"
					updatePopup={update}
				/>
			</DependenciesDialogContext.Provider>
		);

		return (
			<Dependencies
				{...dependenciesProps}
				renderDialogContent={renderDialogContent}
				isDialogLocked={isDialogLocked}
			/>
		);
	}
}

export const IncomingDependenciesCell = IncomingDependenciesCellHOC(DependenciesCell);
export const OutgoingDependenciesCell = OutgoingDependenciesCellHOC(DependenciesCell);
