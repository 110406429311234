import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.heading',
		defaultMessage: 'Roll-ups',
		description: 'Heading for rollups setting items',
	},
	titleDates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.title-dates',
		defaultMessage: 'Dates',
		description: 'Title for rollup dates',
	},
	descriptionDates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.description-dates',
		defaultMessage: 'Show a roll-up of dates in the fields and timeline sections.',
		description: 'Description for roll-up dates',
	},
	toggleLabelDates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.toggle-label-dates',
		defaultMessage: 'Toggle for roll-up dates',
		description: 'Toggle label for roll-up dates',
	},
	titleOthers: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.title-others',
		defaultMessage: 'Others',
		description: 'Title for other roll-ups',
	},
	descriptionOthers: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.description-others',
		defaultMessage:
			'Show a roll-up of sprints, releases, teams, and estimates in the fields section.',
		description: 'Description for other roll-ups',
	},
	toggleLabelOthers: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings.toggle-label-others',
		defaultMessage: 'Toggle for other roll-ups',
		description: 'Toggle label for other roll-ups',
	},
});
