import { calculateStatusOfAllReleases } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/versions/index.tsx';
import { getRollupViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import {
	setShowRolledUpDates,
	setShowRolledUpOthers,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/rollup-settings/actions.tsx';
import type {
	MapDispatchToPropsSimple,
	MapStateToProps,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { DispatchProps, StateProps } from './types.tsx';
import { RollupsSettings } from './view.tsx';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	rollupSettings: getRollupViewSettings(state),
});

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	setShowingRolledUpDates: (shouldShow: boolean) => {
		dispatch(
			setShowRolledUpDates({
				shouldShow,
			}),
		);
		dispatch(calculateStatusOfAllReleases());
	},
	setShowRolledUpOthers: (shouldShow: boolean) => {
		dispatch(
			setShowRolledUpOthers({
				shouldShow,
			}),
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(RollupsSettings);
