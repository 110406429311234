/** @jsx jsx */
import { jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	option?: AssociatedIssue;
};

const Option = ({ option }: Props) => {
	if (!option) return null;
	return (
		<Box xcss={outerWrapperStyles}>
			<Inline space="space.050" alignBlock="center" alignInline="start">
				<Box xcss={fg('aggregate_fields_for_plan_m2') ? avatar : avatarOld}>
					{fg('aggregate_fields_for_plan_m2') ? (
						<AsyncIcon alt="" url={option.iconUrl} width={16} height={16} />
					) : (
						<Avatar src={option.iconUrl} size="xsmall" />
					)}
				</Box>
				<Box xcss={summary}>{option.summary}</Box>
			</Inline>
		</Box>
	);
};

const outerWrapperStyles = xcss({
	display: 'inline',
	minWidth: '38px',
});

const avatar = xcss({
	display: 'flex',
	paddingRight: 'space.050',
	flexShrink: 0,
});

const avatarOld = xcss({
	paddingLeft: 'space.050',
	flexShrink: 0,
});

const summary = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export default Option;
