import React from 'react';
import { cssMap, jsx } from '@atlaskit/css';
import { Inline } from '@atlaskit/primitives/compiled';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import type { OnOptionSelectHandler } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issue/types.tsx';
import {
	useGridActions,
	useGridRef,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types.tsx';
import messages from './messages.tsx';

const styles = cssMap({
	root: {
		maxWidth: '215px',
		display: 'inline-block',
	},
});

export default function DeselectSelectedItems({
	onOptionSelect,
	deselectAllSelected,
	isNestedMenu,
}: {
	onOptionSelect?: OnOptionSelectHandler;
	deselectAllSelected: (
		e: React.KeyboardEvent | React.MouseEvent,
		analyticsEvent: UIAnalyticsEvent,
		attributes?: AnalyticsAttributesUntyped,
	) => void;
	isNestedMenu: boolean;
}) {
	const [gridRef] = useGridRef();
	const { setFocusPosition, setFocusVisible } = useGridActions();

	return (
		<DropMenuItem
			onClick={(
				event: React.KeyboardEvent | React.MouseEvent,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				deselectAllSelected(event, analyticsEvent, {
					source: isNestedMenu ? 'issue-bulk-actions' : 'scope-bulk-actions',
				});
				// Convert to required on FG cleanup plan-timeline-a11y-meatball-menu
				onOptionSelect?.(event);

				if (!isNestedMenu && event.detail === 0) {
					setFocusVisible(true);
					setFocusPosition(1, 0);
					requestAnimationFrame(() => gridRef.current?.focus());
				}
			}}
		>
			<Inline xcss={styles.root}>
				<FormattedMessage {...messages.deselectSelectedItems} />
			</Inline>
		</DropMenuItem>
	);
}
