import React from 'react';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { IdeasCell } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ideas-cell/index.tsx';
import { IdeasCellLegacy } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ideas-cell/ideas-cell-legacy/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';
import {
	filterOnlyExistedAssociatedIssues,
	filterOnlyExistedOriginalAssociatedIssues,
} from './utils.tsx';

const View = ({
	associatedIssues,
	missingAssociatedIssuesFetchInProgress,
	issue,
	isReadOnly,
	isExportMode,
	onChange,
}: Props) => {
	if (missingAssociatedIssuesFetchInProgress) {
		return <CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />;
	}

	const showEmpty = !associatedIssues || !issue.associatedIssueIds;
	if (showEmpty) {
		return null;
	}

	if (fg('polaris-pinned-fields-for-ideas-in-plans')) {
		return (
			<IdeasCell
				isExportMode={isExportMode}
				isDisabled={isReadOnly}
				isReadOnly={isReadOnly}
				associatedIssues={filterOnlyExistedAssociatedIssues(issue, associatedIssues)}
				originalIssues={filterOnlyExistedOriginalAssociatedIssues(issue, associatedIssues)}
				issue={issue}
				onChange={onChange}
			/>
		);
	}

	return (
		<IdeasCellLegacy
			isExportMode={isExportMode}
			isDisabled={isReadOnly}
			isReadOnly={isReadOnly}
			associatedIssues={filterOnlyExistedAssociatedIssues(issue, associatedIssues)}
			originalIssues={filterOnlyExistedOriginalAssociatedIssues(issue, associatedIssues)}
			issue={issue}
			onChange={onChange}
		/>
	);
};

export default View;
