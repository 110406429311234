import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type Props = {
	backgroundUrl: string;
};

const IssueTypeIcon = ({ backgroundUrl }: Props) => <Icon backgroundUrl={backgroundUrl} />;

export default IssueTypeIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.span<Props>({
	width: token('space.200'),
	height: token('space.200'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ backgroundUrl }) => `url('${backgroundUrl}') no-repeat center / cover`,
});
