import type { MessageDescriptor } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';

export const UNASSIGNED = 'unassigned' as const;
export const OTHERS = 'others' as const;
export const UNKNOWN_TEAM = 'unknownTeam' as const;
export const COMPLETED_RELEASE = 'completedReleases' as const;
export const COMPLETED_SPRINT = 'completedSprints' as const;

/**
 * Map of group names, used when the group is unknown, unassigned, or completed
 */
export const GROUP_DESCRIPTOR: Record<string, MessageDescriptor> = {
	[UNASSIGNED]: commonMessages.unassigned,
	[OTHERS]: commonMessages.allOtherIssues,
	[UNKNOWN_TEAM]: messages.unknownTeam,
	[COMPLETED_RELEASE]: messages.completedReleasesGroup,
	[COMPLETED_SPRINT]: messages.completedSprintsGroup,
};
