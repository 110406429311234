import React, { useState, useCallback } from 'react';
import { Pressable } from '@atlaskit/primitives';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import FieldBase from '../common/ui/index.tsx';
import DependenciesFlyout from './flyout/index.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const DependenciesCell = ({
	rawValues,
	fieldKey,
	count,
	groupName,
	groupByLabel,
	swimlane,
	isIncoming,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { formatMessage } = useIntl();
	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const renderContent = useCallback(
		() => (
			<DependenciesFlyout
				rawValues={rawValues}
				isIncoming={isIncoming}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.dependencies-cell.dependencies-flyout"
				ariaLabel={formatMessage(messages.ariaLabel, {
					count,
					groupByLabel,
					swimlane,
				})}
			/>
		),
		[isIncoming, rawValues, groupByLabel, count, swimlane, formatMessage],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<FieldBase
				component={Pressable}
				onClick={onToggle}
				triggerProps={triggerProps}
				tabIndex={0}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.dependencies-cell.${groupName}.${fieldKey}`}
				message={
					fg('jira-issue-terminology-refresh-m3')
						? messages.numberOfWorkItems
						: messages.numberOfIssues
				}
				messageValues={{ count }}
				fieldKey={fieldKey}
			/>
		),
		[onToggle, count, groupName, fieldKey],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default DependenciesCell;
