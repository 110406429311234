export const AT_RISK = 'at_risk';
export const COMPLETED = 'completed';
export const OFF_TRACK = 'off_track';
export const ON_TRACK = 'on_track';
export const PAUSED = 'paused';
export const PENDING = 'pending';

export const FocusAreaStatusValues = {
	AT_RISK,
	OFF_TRACK,
	ON_TRACK,
	PAUSED,
	PENDING,
	COMPLETED,
} as const;

export type FocusAreaStatusValue =
	| typeof AT_RISK
	| typeof ON_TRACK
	| typeof OFF_TRACK
	| typeof PAUSED
	| typeof PENDING
	| typeof COMPLETED;

export interface FocusAreaStatus {
	readonly displayName: string;
	readonly key: FocusAreaStatusValue;
}

export interface FocusArea {
	readonly id: string;
	readonly name: string;
	readonly url: string;
	readonly status: FocusAreaStatus;
}
