import React, { type KeyboardEvent, type MouseEvent } from 'react';
import * as R from 'ramda';
import StandardButton from '@atlaskit/button/standard-button';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import TimelineIcon from '@atlaskit/icon/core/timeline';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import Lozenge from '@atlaskit/lozenge';
import { Text, Box, Pressable, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import ListSkeleton from '@atlassian/jira-portfolio-3-common/src/skeleton/list.tsx';
import {
	UNTITLED_VIEW_ID,
	type View,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/views/types.tsx';
import {
	getPlanId,
	getScenarioId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/urls.tsx';
import SavedViewIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/saved-view.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { IPH_IDS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getMigratedPresetViewName } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/preset-view-names/index.tsx';
import { buildManageViewsPath } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, ModifiedViewProps, TriggerElProps } from './types.tsx';

function MenuItemLabel({ view }: { view: View }) {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<span className={styles.viewName}>
			{view.id !== UNTITLED_VIEW_ID
				? getMigratedPresetViewName(view)
				: formatMessage(messages.untitled)}
		</span>
	);
}

const TriggerEl = ({ views, activeViewName = '', elemAfter }: TriggerElProps) => {
	const { formatMessage } = useIntl();
	if (R.isEmpty(views)) {
		return (
			<div>
				<Text>{formatMessage(messages.savedViews)}</Text>
			</div>
		);
	}

	return (
		<Tooltip content={activeViewName} position="top" delay={500}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.view}>
				<>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.viewName}>{activeViewName}</span> {elemAfter}
				</>
			</div>
		</Tooltip>
	);
};

function ModifiedView({
	activeViewName,
	onRequestSave,
	onRequestSaveAs,
	onRequestDiscardChanges,
	onRequestDelete,
	isReadOnly,
	isDraft,
}: ModifiedViewProps) {
	const { formatMessage } = useIntl();

	if (!activeViewName) {
		return null;
	}

	const description = isVisualRefreshEnabled() ? (
		<Box paddingBlockStart="space.050">
			{isReadOnly ? null : (
				<>
					<Pressable
						xcss={modifiedViewActionStyles}
						padding="space.0"
						onClick={isDraft ? onRequestSaveAs : onRequestSave}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.save-current-view"
					>
						{formatMessage(messages.saveChanges)}
					</Pressable>
					{' • '}
				</>
			)}
			<Pressable
				xcss={modifiedViewActionStyles}
				padding="space.0"
				onClick={isDraft ? onRequestDelete : onRequestDiscardChanges}
			>
				{formatMessage(isDraft ? messages.delete : messages.reset)}
			</Pressable>
		</Box>
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.modifiedViewActions}>
			{isReadOnly ? null : (
				<>
					<StandardButton
						spacing="none"
						appearance="link"
						onClick={isDraft ? onRequestSaveAs : onRequestSave}
						testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.save-current-view"
					>
						{formatMessage(messages.saveChanges)}
					</StandardButton>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.actionSeparator} />
				</>
			)}
			<StandardButton
				spacing="none"
				appearance="link"
				onClick={isDraft ? onRequestDelete : onRequestDiscardChanges}
			>
				{formatMessage(isDraft ? messages.delete : messages.reset)}
			</StandardButton>
		</div>
	);

	return (
		<DropMenuItem
			component="div"
			isSelected
			role="menuitem"
			description={description}
			elemAfter={
				!isVisualRefreshEnabled() && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<span className={styles.lozengeContainer}>
						<Lozenge>
							{isDraft ? formatMessage(messages.draftOld) : formatMessage(messages.editedOld)}
						</Lozenge>
					</span>
				)
			}
		>
			{isVisualRefreshEnabled() ? (
				<Text color="color.text">
					{/* eslint-disable-next-line jira/i18n/text-wrapped-in-format-message */}
					{`${activeViewName} • ${isDraft ? formatMessage(messages.draft) : formatMessage(messages.edited)}`}
				</Text>
			) : (
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
				<span className={styles.modifiedViewName}>{activeViewName}</span>
			)}
		</DropMenuItem>
	);
}

function SavedViewSwitcher({
	onRequestSave,
	onRequestSaveAs,
	onRequestDiscardChanges,
	onRequestViewChange,
	onRequestDelete,
	views,
	isReadOnly,
	defaultOpen = false,
	buttonRef,
	isSmartLink,
}: Props) {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const openInProductHelp = useHelpPanelLink();
	const activeView = views.find(R.prop('active'));

	const activeViewName =
		isDefined(activeView) && activeView.id !== UNTITLED_VIEW_ID
			? getMigratedPresetViewName(activeView)
			: formatMessage(messages.untitled);

	if (!R.isEmpty(views) && !activeView) {
		throw new Error('active view not found');
	}

	const renderViewItemsList = () => {
		// displaying a skeleton list when waiting for the views to be ready
		if (R.isEmpty(views)) {
			return (
				<div
					data-testid="portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.skeleton"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.viewsListSkeleton}
					role="menuitem"
				>
					<ListSkeleton
						numOfRows={5}
						rowHeight={32}
						spaceInBetween={8}
						ariaLabel={formatMessage(messages.skeletonAriaLabel)}
					/>
				</div>
			);
		}

		// reordering the views to put the active view first
		const reorderedViews = R.unnest(R.partition(R.prop('active'), views));

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.viewsList}>
				{reorderedViews.map((view, index) => {
					// this is used to set a different background-color on the view item (only when on the backlog view)
					const isActiveViewSelected = !view.modified && index === 0;
					const isActiveViewModified = view.modified && index === 0;

					if (isActiveViewModified) {
						return null;
					}
					return (
						<DropMenuItem
							isSelected={isActiveViewSelected}
							key={view.id}
							onClick={(e: KeyboardEvent | MouseEvent) => {
								onRequestViewChange(e, view.id);
							}}
						>
							<MenuItemLabel view={view} />
						</DropMenuItem>
					);
				})}
			</div>
		);
	};

	const onClickManageViews = () => {
		const planId = getPlanId();
		const scenarioId = getScenarioId();
		if (isDefined(planId) && isDefined(scenarioId)) {
			push(buildManageViewsPath(planId, scenarioId));
		}
	};

	const onClickLearnMore = () => openInProductHelp(IPH_IDS.SAVED_VIEWS);

	const getActiveViewName = (): string => {
		let name = activeViewName;

		if (activeView?.modified && isVisualRefreshEnabled()) {
			name = `${name} • ${
				activeView.id === UNTITLED_VIEW_ID
					? formatMessage(messages.draft)
					: formatMessage(messages.edited)
			}`;
		}

		return name;
	};

	return (
		<div
			data-testid="portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.button"
			ref={buttonRef}
		>
			<DropMenu
				testId="portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.dropdown-menu"
				defaultOpen={defaultOpen}
				shouldRenderToParent={!fg('plans-fix-dropdown-menus-z-index')}
				trigger={({ triggerRef, ...props }) => (
					<StandardButton
						ref={triggerRef}
						{...props}
						iconAfter={<ChevronDownIcon label="" />}
						iconBefore={isVisualRefreshEnabled() ? <TimelineIcon label="" /> : <SavedViewIcon />}
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.viewTrigger}>
							<TriggerEl
								views={views}
								activeViewName={getActiveViewName()}
								elemAfter={
									activeView?.modified ? (
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										<span className={styles.activeLozengeContainer}>
											{!isVisualRefreshEnabled() && (
												<Lozenge>
													{activeView.id === UNTITLED_VIEW_ID
														? formatMessage(messages.draftOld)
														: formatMessage(messages.editedOld)}
												</Lozenge>
											)}
										</span>
									) : null
								}
							/>
						</div>
					</StandardButton>
				)}
			>
				<DropMenuItemGroup title={formatMessage(messages.savedViews)}>
					{activeView?.modified && (
						<ModifiedView
							onRequestSave={onRequestSave}
							onRequestSaveAs={onRequestSaveAs}
							activeViewName={activeViewName}
							onRequestDiscardChanges={onRequestDiscardChanges}
							onRequestDelete={onRequestDelete}
							isReadOnly={isReadOnly}
							isDraft={activeView.id === UNTITLED_VIEW_ID}
						/>
					)}
					{renderViewItemsList()}
				</DropMenuItemGroup>

				{((!isSmartLink && fg('smart_links_for_plans')) || !fg('smart_links_for_plans')) && (
					<DropMenuItemGroup hasSeparator>
						<DropMenuItem onClick={onClickLearnMore}>
							{formatMessage(messages.iphLink)}
						</DropMenuItem>
					</DropMenuItemGroup>
				)}

				{!isReadOnly && (
					<DropMenuItemGroup hasSeparator>
						<DropMenuItem onClick={onClickManageViews}>
							{formatMessage(messages.manageViews)}
						</DropMenuItem>
						<DropMenuItem onClick={onRequestSaveAs}>
							{formatMessage(messages.createANewView)}
						</DropMenuItem>
					</DropMenuItemGroup>
				)}
			</DropMenu>
		</div>
	);
}

const modifiedViewActionStyles = xcss({
	color: 'color.link',
	fontWeight: 'font.weight.regular',
	background: 'transparent',

	':hover': {
		textDecoration: 'underline',
	},

	':active': {
		color: 'color.text',
	},
});

export default SavedViewSwitcher;
