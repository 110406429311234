import React, { Component } from 'react';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { PROJECT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { PropsOld } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class ProjectFilter extends Component<PropsOld> {
	constructFilterText = () => {
		const { projects, value } = this.props;
		return projects
			.filter(({ id }) => R.contains(id, value))
			.map(({ name }) => name)
			.join(', ');
	};

	renderFilterText = () => {
		const { value } = this.props;
		const filterText = this.constructFilterText();
		return value.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl } = this.props;
		const filterText = this.constructFilterText();
		// Projects, All
		return `${intl.formatMessage(filterMessages[PROJECT_FILTER_ID])}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	isProjectSelected = (projectId: number) => R.contains(projectId, this.props.value);

	filterProjectsWithSearchQuery = (project: Project) => {
		const queryMatchProp: (prop: string) => boolean = R.compose(
			R.includes(R.toLower(this.props.searchQuery)),
			R.toLower,
			// @ts-expect-error - TS2769 - No overload matches this call.
			R.flip(R.prop)(project),
		);
		return queryMatchProp('name') || queryMatchProp('key');
	};

	onProjectClick = (projectId: number): void => {
		const { value, changeProjectFilter } = this.props;
		if (this.isProjectSelected(projectId)) {
			changeProjectFilter(R.filter((project) => project !== projectId, value));
		} else {
			changeProjectFilter([...value, projectId]);
		}
	};

	renderProjectGroups = () => {
		// @ts-expect-error - TS2769 - No overload matches this call.
		const searchResultProjects = this.props.projects.filter(this.filterProjectsWithSearchQuery);

		return (
			<>
				{searchResultProjects.map(({ id, key, name, avatarUrl }) => (
					<DialogMenuItem key={id}>
						<Checkbox
							id={`${id}`}
							key={id}
							isChecked={this.isProjectSelected(id)}
							onChange={() => this.onProjectClick(id)}
							label={<TitleWithAvatar avatarUrl={avatarUrl} id={id} projectKey={key} name={name} />}
						/>
					</DialogMenuItem>
				))}
				{searchResultProjects.length === 0 && <NoMatchFound />}
			</>
		);
	};

	render() {
		const { intl, onOpenChange, isOpen, value, clearProjectFilter, searchQuery, onQueryChange } =
			this.props;

		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				onClose={onOpenChange}
				isOpen={isOpen}
				content={
					<DialogMenuContainer>
						<ClearFilterButton isVisible={!!value.length} onClearClick={clearProjectFilter} />
						<SearchField
							placeholder={intl.formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.searchProjectPlaceholderIssueTermRefresh
									: messages.searchProjectPlaceholder,
							)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
							ariaLabel={intl.formatMessage(messages.searchProjectLabel)}
						/>
						{this.renderProjectGroups()}
					</DialogMenuContainer>
				}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter"
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter.trigger-btn"
					triggerButtonText={this.renderFilterText()}
					ariaLabel={this.ariaText()}
				/>
			</InlineDialog>
		);
	}
}

export default injectIntl(ProjectFilter);
