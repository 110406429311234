import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import InlineCreate from './view.tsx';
import { InlineCreate as InlineCreateRedesigned } from './new.view.tsx';

const View = componentWithFG(
	'plan_timeline_inline_create_re-design',
	InlineCreateRedesigned,
	InlineCreate,
);
export default connect(mapStateToProps, mapDispatchToProps)(View);
