import type { MessageDescriptor } from '@atlassian/jira-intl';

export type Timestamp = number;
export type Period = number;

export type Dimensions = {
	width: number;
	height: number;
};

export type IssueId = string;
export type ProjectId = number;
export type VersionKey = string;
export type TeamKey = string;
export type ResourceKey = string;
export type SprintId = string;
export type IterationId = string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormatMessage = (messageDescriptor: MessageDescriptor, values?: any) => string;

export type TimelineRange = {
	start: Timestamp;
	end: Timestamp;
};

export const FEATURES_KEY = {
	FEATURE_MULTI_SCENARIO: 'FEATURE_MULTI_SCENARIO',
	FEATURE_AUTO_SCHEDULER: 'FEATURE_AUTO_SCHEDULER',
} as const;

export type FeatureKeyType = (typeof FEATURES_KEY)[keyof typeof FEATURES_KEY];
