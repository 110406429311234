/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
// To reduce code duplication and to improve styling consistency, export common components that are reusable across different filters.
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as TriggerButton } from './trigger-button';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ClearFilterButton, type Props as ClearFilterButtonProps } from './clear-filter';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { FilterText } from './filter-text/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as TitleWithAvatar } from './title-with-avatar';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as NoMatchFound } from './no-match-text';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { calculateCategoryCheckboxStatus } from './utils';

export const FILTER_MAX_WIDTH = 450;
export const FILTER_WIDTH = 300;

export const ContentWrapper = (props: { children: ReactNode }) => {
	return (
		<Box
			paddingInlineStart="space.0"
			paddingInlineEnd="space.0"
			paddingBlockEnd="space.100"
			paddingBlockStart="space.100"
			xcss={contentWrapperStyles}
		>
			{props.children}
		</Box>
	);
};

export const ItemLabelWrapper = (props: { children: ReactNode }) => {
	return <span css={optionWrapperStyles}>{props.children}</span>;
};

export const ItemWrapper = (props: { children: ReactNode }) => (
	<Box paddingBlock="space.100" paddingInline="space.200">
		{props.children}
	</Box>
);

const optionWrapperStyles = css({
	maxWidth: '236px',
	display: 'inline-block',
	wordBreak: 'break-all',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.300'),
});

const contentWrapperStyles = xcss({
	overflowX: 'hidden',
	overflowY: 'auto',
	maxHeight: '40vh',
	minWidth: '278px',
	maxWidth: '450px',
});
