import React, { type ReactNode } from 'react';
import isNil from 'lodash/fp/isNil';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import PeopleIcon from '@atlaskit/icon/core/migration/people-group--people';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import { selectComponents } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	FormatOptionLabelMeta,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import planCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import { getOptimizedValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import Cell from '../../column/cell/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const getTeamName = (teamId: string | null | undefined, teams: Team[]) => {
	if (!isDefined(teamId) || !isDefined(teams)) {
		return '';
	}
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const team = teams.find((team: Team) => team.id === teamId);
	if (team !== undefined && team !== null) {
		return team.title;
	}
	return '';
};

const getNoTeamAvatar = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={styles.defaultTeamAvatarOption}>
		<PeopleIcon label="" color={token('color.icon')} LEGACY_size="small" />
	</div>
);

const getTeamAvatar = (teamId: string | null | undefined, teams: Team[]) => {
	if (!isDefined(teamId) || !isDefined(teams)) {
		return getNoTeamAvatar();
	}
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const team = teams.find((team: Team) => team.id === teamId);
	if (team !== undefined && team !== null && team.avatarUrl) {
		return team.avatarUrl;
	}
	return getNoTeamAvatar();
};

const isPlanOnlyTeam = (teamId: string | null | undefined, teams: Team[]) => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const team = teams.find((team: Team) => team.id === teamId);
	if (team !== undefined && team !== null) {
		return !team.shareable;
	}
	return true;
};

const isVerified = (teamId: string | null | undefined, teams: Team[]) => {
	if (!isDefined(teamId) || !isDefined(teams)) {
		return false;
	}
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const team = teams.find((team: Team) => team.id === teamId);
	if (team !== undefined && team !== null && team.verified) {
		return team.verified;
	}
	return false;
};

export const makeHandleSelection =
	({ onChange, issue }: { onChange: Props['onChange']; issue: Props['issue'] }) =>
	(selection: { value?: string }) => {
		if (selection && typeof selection.value === 'string') {
			const newTeamId = selection.value;
			onChange(issue, newTeamId);
		} else {
			onChange(issue, undefined);
		}
	};

export const TeamCell = ({
	showOptimizations = false,
	issue,
	onChange,
	CellSelectWithRollup,
	isScrolling,
	teams,
	allTeams,
	scenarioRemovedAtlassianTeams,
	teamRollUpForIssue,
	isReadOnly = false,
	additionalTeamsById,
}: Props) => {
	const attribute = 'team';
	const { formatMessage } = useIntl();
	const handleSelection = makeHandleSelection({ onChange, issue });

	const isAdditionalTeam = (teamId: string) =>
		!isNil((additionalTeamsById || {})[teamId]) ||
		scenarioRemovedAtlassianTeams.filter((team: Team) => team.id === teamId).length > 0;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getSingleValue = ({ children, ...rest }: { children: ReactNode; [key: string]: any }) => (
		// @ts-expect-error - TS2604 - JSX element type 'selectComponents.SingleValue' does not have any construct or call signatures.
		<selectComponents.SingleValue {...rest}>{children}</selectComponents.SingleValue>
	);

	const getTeamOptionLabelWithLozenge = (
		label: string,
		icon: string,
		teamType: string,
		verified: boolean,
	) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={!icon ? styles.optionNoAvatarWrapper : styles.optionWrapper}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={!icon ? '' : styles.externalValueWrapper}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.teamAvatarItem}>
					{!icon ? (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.defaultTeamAvatarValue}>
							<PeopleIcon label="" color={token('color.icon')} LEGACY_size="small" />
						</div>
					) : (
						<Avatar size="small" src={icon} />
					)}
					{label}
				</div>
			</div>
			{fg('plans_managed_teams_indicator_timeline_enabled') && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
				<div className={styles.verifiedTeamIcon}>
					{verified && <VerifiedTeamIcon showTooltip />}
				</div>
			)}
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.externalLozenge}
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onFocus={() => {}}
				onMouseOver={(e) => e.stopPropagation()}
			>
				<Tooltip
					content={
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.tooltip}>
							<Heading as="h4" size="small">
								{formatMessage(
									teamType === 'external'
										? planCommonMessages.externalTeamTooltipTitle
										: planCommonMessages.planOnlyTeamTooltipTitle,
								)}
							</Heading>
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							<p>
								{formatMessage(
									teamType === 'external'
										? planCommonMessages.externalTeamTooltipContent
										: planCommonMessages.planOnlyTeamTooltipContent,
								)}
							</p>
						</div>
					}
				>
					<Lozenge>
						{formatMessage(
							teamType === 'external'
								? planCommonMessages.externalLozengeShort
								: planCommonMessages.planOnlyLozenge,
						)}
					</Lozenge>
				</Tooltip>
			</div>
		</div>
	);

	const formatOptionLabel = (opt: OptionType, { context }: FormatOptionLabelMeta) => {
		if (context === 'value') {
			if (opt.isExternalTeam) {
				return getTeamOptionLabelWithLozenge(opt.label, opt.icon, 'external', opt.verified);
			}

			if (opt.isPlanOnlyTeam) {
				return getTeamOptionLabelWithLozenge(opt.label, opt.icon, 'planOnly', opt.verified);
			}

			if (!opt.icon) {
				if (fg('plans_managed_teams_indicator_timeline_enabled')) {
					return (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.optionNoAvatarWrapper}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop */}
							<div className={styles.teamAvatarItem}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.defaultTeamAvatarValue}>
									<PeopleIcon label="" color={token('color.icon')} LEGACY_size="small" />
								</div>
								{opt.label}
							</div>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop */}
							<div className={styles.verifiedTeamIcon}>
								{opt.verified && <VerifiedTeamIcon showTooltip />}
							</div>
						</div>
					);
				}
				return (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.teamAvatarItem}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.defaultTeamAvatarValue}>
							<PeopleIcon label="" color={token('color.icon')} LEGACY_size="small" />
						</div>
						{opt.label}
					</div>
				);
			}

			if (fg('plans_managed_teams_indicator_timeline_enabled')) {
				return (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
					<div className={styles.optionAvatarWrapper}>
						{/*  eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop */}
						<div className={styles.teamAvatarItem}>
							<Avatar size="small" src={opt.icon} />
							{opt.label}
						</div>
						{/*  eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop */}
						<div className={styles.verifiedTeamIcon}>
							{opt.verified && <VerifiedTeamIcon showTooltip />}
						</div>
					</div>
				);
			}
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.teamAvatarItem}>
					<Avatar size="small" src={opt.icon} />
					{opt.label}
				</div>
			);
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.selectOption}>
				{!opt.icon ? (
					getNoTeamAvatar()
				) : (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.avatar}>
						<Avatar size="small" src={opt.icon} />
					</div>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.label}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.truncated}>{opt.label}</span>
					{fg('plans_managed_teams_indicator_timeline_enabled') && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
						<div className={styles.verifiedTeamIcon}>
							{opt.verified && <VerifiedTeamIcon showTooltip />}
						</div>
					)}
				</div>
			</div>
		);
	};

	const select = () => {
		const { level, summary, team } = issue;
		const options: {
			value: string;
			label: string;
			icon?: string | JSX.Element | undefined;
			isPlanOnlyTeam?: boolean;
			isExternalTeam?: boolean;
			verified?: boolean;
		}[] = teams.map(({ id, title, avatarUrl, verified }) => ({
			value: id,
			label: title,
			icon: avatarUrl,
			isPlanOnlyTeam: isPlanOnlyTeam(id, allTeams),
			verified,
		}));

		let id = team;
		if (showOptimizations === true) {
			id = getOptimizedValue(issue, attribute);
		}
		let value = options.find((option) => option.value === id);
		if (!value && id !== null && id !== undefined) {
			value = {
				icon: getTeamAvatar(id, allTeams.concat(scenarioRemovedAtlassianTeams)),
				label: getTeamName(id, allTeams.concat(scenarioRemovedAtlassianTeams)),
				value: id,
				isExternalTeam: isAdditionalTeam(id || ''),
				verified: isVerified(id, allTeams.concat(scenarioRemovedAtlassianTeams)),
			};
		}
		const isIssueASubtask = level === SUB_TASK_LEVEL;
		const isSelectReadOnly = isIssueASubtask || isReadOnly;
		const allTeamsById = Object.fromEntries(
			allTeams.concat(scenarioRemovedAtlassianTeams).map((t) => [t.id, t]),
		);
		const rolledUpValues = (teamRollUpForIssue || [])
			.reduce<string[]>((values, t) => {
				if (allTeamsById[t]) {
					values.push(allTeamsById[t].title);
				}
				return values;
			}, [])
			.sort((a, b) => a.localeCompare(b));

		return (
			<CellSelectWithRollup
				aria-label={formatMessage(
					{
						...(fg('jira-issue-terminology-refresh-m3')
							? messages.teamSelectorLabelIssueTermRefresh
							: messages.teamSelectorLabel),
					},
					{ issue: summary },
				)}
				attribute={attribute}
				components={{ SingleValue: getSingleValue }}
				// eslint-disable-next-line @typescript-eslint/no-shadow
				formatOptionLabel={(opt: OptionType, value: FormatOptionLabelMeta) =>
					formatOptionLabel(opt, value)
				}
				isClearable
				isDisabled={isSelectReadOnly}
				issueId={issue.id}
				onChange={(selection: { value?: string }) => handleSelection(selection)}
				options={options}
				placeholder={formatMessage(messages.placeholder)}
				rolledUpValues={rolledUpValues}
				value={value === undefined ? null : value}
			/>
		);
	};

	const selectWithTooltip = () => {
		const tooltipContent = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.tooltip}>
				<Heading as="h4" size="small">
					{formatMessage(messages.issueIsSubtaskHeader)}
				</Heading>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{formatMessage(messages.issueIsSubtaskContent)}</p>
			</div>
		);
		return <Tooltip content={tooltipContent}>{select()}</Tooltip>;
	};

	const component = () => {
		const { level } = issue;
		const mapTeamIdToTitle = (_: ScopeIssue, value: string): string =>
			getTeamName(value, allTeams.concat(scenarioRemovedAtlassianTeams));

		return (
			<Cell
				attribute={attribute}
				issue={issue}
				valueMapper={mapTeamIdToTitle}
				isScrolling={isScrolling}
				showOptimizations={showOptimizations}
			>
				{level === SUB_TASK_LEVEL ? selectWithTooltip() : select()}
			</Cell>
		);
	};

	const wireframe = () => {
		const { optimized } = issue;
		const team =
			showOptimizations === true && optimized && optimized.team ? optimized.team : issue.team;
		return (
			<SelectWireframe
				content={getTeamName(team, allTeams.concat(scenarioRemovedAtlassianTeams))}
				isReadOnly={showOptimizations}
			/>
		);
	};

	return isScrolling ? wireframe() : component();
};

export default withSlots({ CellSelectWithRollup: slots.CellSelectWithRollup })(TeamCell);
