.teamName {
	min-height: 36px;
	text-align: start;
	padding: var(--jpo-common-base-spacing);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.teamAvatarItem {
	align-items: center;
	display: flex;
	gap: var(--jpo-common-base-spacing);
}

.defaultTeamAvatarValue {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 50%;
	margin: 2px;
	padding: 4px 4px;

	& > span {
		display: flex;
	}
}

.defaultTeamAvatarOption {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 50%;
	margin: 2px 10px 2px 2px;
	padding: 4px 4px;

	& > span {
		display: flex;
	}
}

.teamDropdown {
	text-align: start;
	display: inline-flex;
	flex: 1 1 auto;
	justify-content: space-between;
}

.dropdownArrow {
	width: 24px;
	min-width: 24px;
	height: 24px;
	display: flex;
	align-self: center;

	& > span {
		height: 24px;
		width: 24px;
	}
}

.tooltip {
	padding: var(--jpo-common-base-spacing);
	text-align: center;
}

.tooltip > h4 {
	font-weight: var(--ds-font-weight-bold, bold);
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.externalValueWrapper {
	display: inline-block;
	max-width: calc(100% - 30px);
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	min-width: 24px;
}

.externalLozenge {
	display: inline-block;
	margin-left: var(--jpo-common-base-spacing);
	margin-right: var(--jpo-common-base-spacing);
	vertical-align: middle;
	overflow: hidden;
}

.verifiedTeamIcon {
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	margin-top: 3px;
}

.optionWrapper {
	padding-right: var(--jpo-common-base-spacing);
}

.optionNoAvatarWrapper {
	align-items: center;
	display: flex;
}

.optionAvatarWrapper {
	align-items: center;
	display: flex;
}

.selectOption {
	display: flex;
}

.avatar {
	display: inline-flex;
	margin-right: 8px;
}

.label {
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	line-height: 20px;
}

.truncated {
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}
