/** @jsx jsx */

import React, { forwardRef, type Ref, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import Checkbox from '@atlaskit/checkbox';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { NO_LABEL_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/label-filter/index.tsx';
import type { IssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-labels/types.tsx';
import { LABEL_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import { ContentWrapper, ItemWrapper } from '../common/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const LabelFilterInner = (
	props: Props & { setInitialFocusRef?: (elem: HTMLElement | null) => void },
) => {
	const { formatMessage } = useIntl();
	const {
		value,
		changeFilter,
		searchQuery,
		onQueryChange,
		clearFilter,
		labels,
		setInitialFocusRef,
	} = props;

	const filterLabelsWithSearchQuery = (label: string) =>
		label.toLowerCase().includes(searchQuery.toLowerCase());

	const searchResultLabels = labels.filter(filterLabelsWithSearchQuery);

	const isLabelSelected = useCallback((label: string) => value.includes(label), [value]);

	const onLabelClick = useCallback(
		(clickedLabel: string): void => {
			if (isLabelSelected(clickedLabel)) {
				changeFilter(value.filter((label) => label !== clickedLabel));
			} else {
				changeFilter([...value, clickedLabel]);
			}
		},
		[changeFilter, isLabelSelected, value],
	);
	const renderLabels = (resultLabels: IssueLabels) => (
		<>
			{resultLabels.map((label, index) => (
				<ItemWrapper key={label}>
					<Checkbox
						id={`${index}`}
						key={index}
						isChecked={isLabelSelected(label)}
						onChange={() => onLabelClick(label)}
						label={
							<Tooltip content={label}>
								<div css={labelCheckboxLabelWrapperStyles}>
									<div css={labelCheckboxLabelTitleStyles}>{label}</div>
								</div>
							</Tooltip>
						}
					/>
				</ItemWrapper>
			))}
			{resultLabels.length === 0 && <NoMatchFound />}
		</>
	);

	const renderNoLabel = () => {
		const name = formatMessage({ ...messages.noLabelsFilterText });
		return (
			<ItemWrapper>
				<Checkbox
					id={NO_LABEL_ID}
					key={NO_LABEL_ID}
					isChecked={isLabelSelected(NO_LABEL_ID)}
					onChange={() => onLabelClick(NO_LABEL_ID)}
					label={
						<Tooltip content={name}>
							<div css={labelCheckboxLabelTitleStyles}>{name}</div>
						</Tooltip>
					}
				/>
			</ItemWrapper>
		);
	};

	return (
		<ContentWrapper>
			<ClearFilterButton isVisible={!!value.length} onClearClick={clearFilter} />
			<SearchField
				placeholder={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchLabelsPlaceholderIssueTermRefresh
						: messages.searchLabelsPlaceholder,
				)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={formatMessage(messages.searchLabelsAriaLabel)}
				setInitialFocusRef={setInitialFocusRef}
			/>
			{!!searchResultLabels.length && renderNoLabel()}
			{renderLabels(searchResultLabels)}
		</ContentWrapper>
	);
};

const LabelFilterTriggerButton = forwardRef(
	(
		props: Pick<Props, 'isOpen' | 'value' | 'labels' | 'onOpenChange'> & TriggerProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { formatMessage } = useIntl();
		const { isOpen, value, labels, onOpenChange, ...triggerProps } = props;

		const constructFilterText = () => {
			const filteredValues = labels.filter((label) => value.includes(label));

			return value.includes(NO_LABEL_ID)
				? [formatMessage({ ...messages.noLabelsFilterText }), ...filteredValues].join(', ')
				: filteredValues.join(', ');
		};

		const renderFilterText = () => {
			const filterText = constructFilterText();
			return value.length > 0 ? (
				<FilterText text={filterText} />
			) : (
				<FormattedMessage {...messages.emptyPlaceholder} />
			);
		};

		const ariaText = () => {
			const filterText = constructFilterText();
			// Labels, All
			return `${formatMessage(filterMessages[LABEL_FILTER_ID])}, ${
				value.length > 0 ? filterText : formatMessage(messages.emptyPlaceholder)
			} ${formatMessage(filterMessages.selected)}`;
		};

		return (
			<TriggerButton
				{...triggerProps}
				ref={ref}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter.trigger-btn"
				triggerButtonText={renderFilterText()}
				ariaLabel={ariaText()}
			/>
		);
	},
);

const LabelFilter = (props: Props) => {
	const { labels, value, onOpenChange, isOpen } = props;

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
			onClose={() => {
				onOpenChange({ isOpen: false });
			}}
			content={(contentProps) => (
				<LabelFilterInner {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter"
			trigger={(triggerProps) => (
				<LabelFilterTriggerButton
					{...triggerProps}
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					labels={labels}
					value={value}
				/>
			)}
			shouldRenderToParent
		/>
	);
};

const labelCheckboxLabelWrapperStyles = css({
	alignItems: 'center',
	display: 'grid',
	gap: token('space.100'),
	gridTemplateColumns: 'fit-content(100%) fit-content(100%)',
});

const labelCheckboxLabelTitleStyles = css({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

export default LabelFilter;
