import React, { useCallback } from 'react';
import { CustomThemeButton } from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import ExportIcon from '@atlaskit/icon/core/migration/upload--export';
import { Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import type { AdditionalData } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/types.tsx';
import type { GoalsByARI } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../../styles.module.css';
import messages from '../messages.tsx';

type Props = {
	isReportMode: boolean;
	noIssuesInPlan: boolean;
	onClickExport: (additionalData: AdditionalData) => void;
	onClose: () => void;
	definedGoalsByARI: GoalsByARI;
	associatedIssues: Record<string, AssociatedIssue>;
};

const CSVExport = ({
	isReportMode,
	noIssuesInPlan,
	onClickExport,
	onClose,
	definedGoalsByARI,
	associatedIssues,
}: Props) => {
	const { formatMessage } = useIntl();

	const onClickExportWithAdditionalData = useCallback(() => {
		onClickExport({ goalsByARI: definedGoalsByARI, associatedIssues });
	}, [onClickExport, definedGoalsByARI, associatedIssues]);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.content}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-heading -- Ignored via go/DSP-18766 */}
			<h3 className={styles.contentHeading}>{formatMessage(messages.shareDialogHeader)}</h3>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.contentBody}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.contentBodyIntro}>
					{isReportMode && (
						<SectionMessage>
							<Text as="p">{formatMessage(messages.reportModeMessage)}</Text>
						</SectionMessage>
					)}
					<Text as="p">{formatMessage(messages.exportCsvIntro)}</Text>
					<Text as="p">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.plansExportCsvIntro2IssueTermRefresh
								: messages.plansExportCsvIntro2,
						)}
					</Text>
				</div>
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.contentFooter}>
				<ButtonGroup>
					<Tooltip
						content={
							noIssuesInPlan &&
							formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.noIssuesToExportTooltipIssueTermRefresh
									: messages.noIssuesToExportTooltip,
							)
						}
					>
						<CustomThemeButton
							appearance="primary"
							iconBefore={<ExportIcon spacing="spacious" label="" />}
							onClick={onClickExportWithAdditionalData}
							testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.csv-export.export-spreadsheet"
							theme={(current, themeProps) => ({
								...current(themeProps),
								buttonStyles: {
									...current(themeProps).buttonStyles,
									...(noIssuesInPlan && {
										background: token('color.background.disabled'),
										color: token('color.text.disabled'),
										cursor: 'not-allowed',
									}),
								},
							})}
						>
							{formatMessage(messages.exportCsvButton)}
						</CustomThemeButton>
					</Tooltip>
					<Button appearance="subtle" onClick={onClose}>
						{formatMessage(commonMessages.cancel)}
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);
};

export { CSVExport };
