import type { Effect } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { getIssueChangeQueue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/index.tsx';
import type {
	IssueChangeQueue,
	IssueChangeQueueEntry,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/commit/types.tsx';
import { removeFromIssueChangeQueue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/commit/actions.tsx';
import { increment as incrementSequence } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sequence/actions.tsx';
import {
	doUpdateIssueRequest,
	executeRemoveIssuesRequestAndHandleResult,
} from '../../issue/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* executeQueuedChange(id: string): Generator<Effect, any, any> {
	const issueChangeQueue: IssueChangeQueue = yield select(getIssueChangeQueue);
	const changes = issueChangeQueue.get(id);
	if (changes === undefined) {
		return;
	}
	for (const change of changes) {
		yield call(executeQueuedChangeInner, change);
	}
	yield put(removeFromIssueChangeQueue(id));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* executeAllQueuedChanges(): Generator<Effect, any, any> {
	const issueChangeQueue: IssueChangeQueue = yield select(getIssueChangeQueue);
	for (const [id, changes] of issueChangeQueue) {
		for (const change of changes) {
			yield call(executeQueuedChangeInner, change);
		}
		yield put(removeFromIssueChangeQueue(id));
	}
}

function* executeQueuedChangeInner(
	change: IssueChangeQueueEntry,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (change.type === 'UPDATE') {
		yield call(doUpdateIssueRequest, change.payload);
		yield put(incrementSequence(['issues']));
	} else if (change.type === 'DELETE') {
		yield call(executeRemoveIssuesRequestAndHandleResult, change.payload);
	}
}
