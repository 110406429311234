import React, { Component } from 'react';
import * as R from 'ramda';
import StandardButton from '@atlaskit/button/standard-button';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Box, xcss } from '@atlaskit/primitives';
import type { DroplistGroup } from '../common/types/droplist-group.tsx';
import type { DroplistItem } from '../common/types/droplist-item.tsx';
import DropMenu, { DropMenuItem } from '../drop-menu/index.tsx';
import type { Props, State } from './types.tsx';

type ButtonContent =
	| React.ReactNode // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| React.ReactElement<any, string | React.JSXElementConstructor<any>>;

// eslint-disable-next-line jira/react/no-class-components
export default class DropdownMenu extends Component<Props, State> {
	static defaultProps = {
		defaultValue: null,
		isLoading: false,
		groups: [],
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onChange: () => {},
		placement: 'bottom-start',
		testId: '',
	};

	state = {
		selectedItem: this.props.defaultValue,
	};

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		/* istanbul ignore else */
		if (this.props.defaultValue !== nextProps.defaultValue) {
			this.setState(() => ({
				selectedItem: nextProps.defaultValue,
			}));
		}
	}

	handleClick = (item: DroplistItem, group: DroplistGroup, e: Event) => {
		this.setState({
			selectedItem: item,
		});

		/* istanbul ignore else */
		if (this.props.onChange) {
			this.props.onChange(item, group, e);
		}
	};

	createItemElement = (item: DroplistItem, group: DroplistGroup) => (
		<DropMenuItem
			key={item.id}
			onClick={(e: Event) => this.handleClick(item, group, e)}
			title={item.tooltipDescription}
			{...item}
		>
			{item.content}
		</DropMenuItem>
	);

	createGroupElement = (group: DroplistGroup) => {
		const itemElements = R.map((item) => this.createItemElement(item, group), group.items);

		return (
			<DropMenuItemGroup key={group.id} title={group.heading}>
				{itemElements}
			</DropMenuItemGroup>
		);
	};

	render() {
		const { groups = [], isLoading, placement, placeholder, testId } = this.props;
		const { selectedItem } = this.state;
		const { footerContent, defaultOpen } = this.props;

		let buttonContent: ButtonContent =
			placeholder !== null && placeholder !== undefined ? placeholder : 'Select from a list';
		if (selectedItem) {
			buttonContent = selectedItem.content;
		}

		const triggerContent = ({ triggerRef, ...props }: { triggerRef: React.Ref<HTMLElement> }) => (
			<StandardButton
				ref={triggerRef}
				{...props}
				iconAfter={<ChevronDownIcon label="" />}
				aria-label={this.props.ariaLabel}
			>
				{buttonContent}
			</StandardButton>
		);

		return (
			<DropMenu
				defaultOpen={defaultOpen}
				isLoading={isLoading}
				placement={placement}
				testId={testId}
				trigger={triggerContent}
			>
				{R.map(this.createGroupElement, groups)}
				{footerContent && <Box xcss={FooterStyles}>{footerContent}</Box>}
			</DropMenu>
		);
	}
}

// Export as functional component for componentWithFG
export const DropdownMenuLegacy = (props: Props) => {
	return <DropdownMenu {...props} />;
};

const FooterStyles = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: 'border.width',
	position: 'sticky',
	backgroundColor: 'elevation.surface.overlay',
	bottom: 'space.0',
});
