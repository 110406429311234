import sum from 'lodash/fp/sum';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createSelector } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from './types.tsx';

export const getColumnWidths = (state: State, preview: boolean) =>
	(preview ? state.preview : undefined) ?? state.widths;

export const selectColumnStickyOffset = (
	state: State,
	{ column, preview }: { column: number; preview: boolean },
) =>
	state.columns[column].sticky
		? getColumnWidths(state, preview)
				.slice(0, column)
				.reduce((offset = 0, width) => offset + width, state.stickyOffset.horizontal)
		: undefined;

export const getSelectColspan = createSelector(
	(state: State) => state.getSelectColspan,
	(state: State) => getColumnWidths(state, true),
	(getColspan, columnWidths) => {
		return ({ row, column }: { row: number; column: number }) =>
			getColspan ? getColspan(columnWidths)({ row, column }) : 1;
	},
);

export const getSelectRowspan = createSelector(
	(state: State) => state.getSelectRowspan,
	(state: State) => getSelectColspan(state),
	(state: State) => getColumnWidths(state, true),
	(getRowspan, getColspan, columnWidths) => {
		return ({ row, column }: { row: number; column: number }) =>
			getRowspan && getColspan ? getRowspan(columnWidths, getColspan)({ row, column }) : 1;
	},
);

export const getHighlightCell = createSelector(
	(state: State) => getSelectRowspan(state),
	(state: State) => getSelectColspan(state),
	(state: State) => state.focus,
	(getRowspan, getColspan, focus) => {
		const { row, column, visible } = focus;

		if (!visible || column === -1) {
			return undefined;
		}

		let focusRow = focus.row;
		let focusColumn = focus.column;

		const colspan = getColspan({ row, column });
		if (colspan === 0) {
			// Find left-most column of the colspan
			for (let i = column - 1; i >= 0; i--) {
				const cellColspan = getColspan({ row, column: i });
				if (cellColspan > 0) {
					focusColumn = i;
					break;
				}
			}
		}

		const rowspan = getRowspan({ row, column });
		if (rowspan === 0) {
			// Find top-most row of the rowspan
			for (let i = row - 1; i >= 0; i--) {
				const cellRowspan = getRowspan({ row: i, column });
				if (cellRowspan > 0) {
					focusRow = i;
					break;
				}
			}
		}

		return { row: focusRow, column: focusColumn };
	},
);

/** Returns the <col /> width attribute */
export const getColWidth = (
	state: State,
	viewportWidth: number,
	column: number,
): number | string | undefined => {
	const columnWidths = getColumnWidths(state, true);
	const columnWidth = columnWidths.at(column) ?? 0;
	const lastIndex = columnWidths.length - 1;

	if (columnWidth === 0) {
		return undefined;
	}

	if (state.layout === 'auto') {
		return columnWidth;
	}

	if (column < lastIndex) {
		return columnWidth;
	}

	const hasHorizontalScrolling = sum(columnWidths) > viewportWidth;

	if (hasHorizontalScrolling) {
		return columnWidth;
	}

	return '100%';
};

export const selectRowStickyOffset = (
	state: State,
	{ row, virtualized }: { row: number; virtualized: number[] },
) => {
	const sticky = virtualized.filter((index) => state.rows[index].sticky);
	if (!sticky.includes(row)) {
		return undefined;
	}

	return sticky
		.slice(0, sticky.indexOf(row))
		.map((index) => state.rows[index])
		.reduce((acc, { height }) => acc + height, state.stickyOffset.vertical);
};

export const selectColSpan = (
	state: State,
	{ row, column, preview }: { row: number; column: number; preview: boolean },
) => state.colspan?.({ column, row, columnWidths: getColumnWidths(state, preview) }) ?? 1;

export const selectResizerVisible = (
	state: State,
	{ column, scrollX, preview }: { column: number; scrollX: number; preview: boolean },
) => {
	const isSticky = (col: number) => state.columns[col].sticky;

	if (isSticky(column)) return true;

	return (
		getColumnWidths(state, preview)
			.slice(0, column + 1)
			.filter((_, index) => !isSticky(index))
			.reduce((visible, width) => visible + width, -scrollX) > 0
	);
};

/**
 * @returns undefined - when there is no focus visible
 * @returns true - when it is the focused cell
 * @returns false - when it is not the focused cell and does not belongs to the focused row
 * @returns "start" - when it belongs to the focused row and being the most-left one
 * @returns "end" - when it belongs to the focused row and being the most-right one
 * @returns "middle" - when it belongs to the focused row and part of the cells in the middle
 */
export const selectCellHighlight = (
	state: State,
	{ row, column, colSpan }: { row: number; column: number; colSpan: number },
): undefined | 'start' | 'end' | 'middle' | boolean => {
	const { focus, columns } = state;

	if (!focus.visible) {
		return undefined;
	}

	const highlightCell = fg('plan-timeline-a11y-highlight-api') ? getHighlightCell(state) : null;

	if (fg('plan-timeline-a11y-highlight-api')) {
		if (row !== (highlightCell ? highlightCell?.row : focus.row)) {
			return false;
		}
	} else if (row !== focus.row) {
		return false;
	}

	if (focus.column === -1) {
		if (column === 0) {
			return 'start';
		}

		if (column + colSpan === columns.length) {
			return 'end';
		}

		return 'middle';
	}

	if (fg('plan-timeline-a11y-highlight-api')) {
		return column === (highlightCell ? highlightCell.column : focus.column);
	}

	return column === focus.column;
};

/**
 * Selects the focus effect from the state if the specified row and column match the current focus.
 *
 * @param state - The current state of the grid.
 * @param param - An object containing the row and column to check against the current focus.
 * @param param.row - The row number to check.
 * @param param.column - The column number to check.
 * @returns The focus action if the specified row and column match the current focus, otherwise undefined.
 */
export const selectFocusCellEffect = (
	state: State,
	{ row, column }: { row: number; column: number },
) => {
	if (fg('plan-timeline-a11y-highlight-api')) {
		const highlightCell = getHighlightCell(state);
		const isHighlightOnCell = highlightCell?.row === row && highlightCell?.column === column;

		if (isHighlightOnCell) {
			return state.focus.cellEffect;
		}
	} else if (state.focus.row === row && state.focus.column === column) {
		return state.focus.cellEffect;
	}

	return undefined;
};

export const selectFocusRowEffect = (state: State, { row }: { row: number }) => {
	if (state.focus.row === row && state.focus.column === -1) {
		return state.focus.rowEffect;
	}
	return undefined;
};

/**
 * Selects the active descendant ID based on the given state and cell position.
 *
 * @param {State} param0 - The state object containing the grid ID and focus information.
 * @param {Object} param1 - The cell position object containing row and column numbers.
 * @param {number} param1.row - The row number of the cell.
 * @param {number} param1.column - The column number of the cell.
 * @returns {string | undefined} The active descendant ID if the cell is focused, otherwise undefined.
 */
export const selectActiveDecendantId = (
	state: State,
	{ row, column }: { row: number; column: number },
) => {
	const { id, focus } = state;

	const highlightCell = fg('plan-timeline-a11y-highlight-api') ? getHighlightCell(state) : null;

	if (fg('plan-timeline-a11y-highlight-api')) {
		if (
			row === (highlightCell?.row ?? focus.row) &&
			column === (highlightCell?.column ?? focus.column)
		) {
			return `${id}:activedescendant`;
		}

		return undefined;
	}

	if (focus.row === row && focus.column === column) {
		return `${id}:activedescendant`;
	}

	return undefined;
};

export const selectIsRowFocused = ({ focus }: State, row: number) => {
	return focus.row === row;
};
