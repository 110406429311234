import React, { useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import { ErrorMessage } from '@atlaskit/form';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import CalendarInput from './calendar-input/index.tsx';
import DateInput from './date-input/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const FixedDateRange = ({
	fromDate,
	toDate,
	isValidDateRange,
	onChange,
	setInvalidFormat,
}: Props) => {
	const [startDate, setStartDate] = useState(new Date(fromDate));
	const [endDate, setEndDate] = useState(new Date(toDate));

	const [startInvalid, setStartInvalid] = useState(false);
	const [endInvalid, setEndInvalid] = useState(false);

	useEffect(() => {
		setInvalidFormat(startInvalid || endInvalid);
	}, [startInvalid, endInvalid, setInvalidFormat]);

	const onStartCalendarSelect = ({ iso }: { iso: string }) => {
		if (iso !== undefined) {
			const newStartDate = new Date(iso);
			setStartDate(newStartDate);
			onChange(newStartDate.getTime(), endDate.getTime());
			setStartInvalid(false);
		}
	};

	const onEndCalendarSelect = ({ iso }: { iso: string }) => {
		if (iso !== undefined) {
			const newEndDate = new Date(iso);
			setEndDate(newEndDate);
			onChange(startDate.getTime(), newEndDate.getTime());
			setEndInvalid(false);
		}
	};

	const onStartInputChange = (date: Date) => {
		setStartDate(date);
		onChange(date.getTime(), endDate.getTime());
	};

	const onEndInputChange = (date: Date) => {
		setEndDate(date);
		onChange(startDate.getTime(), date.getTime());
	};

	return (
		<Container>
			<ColumnContainer>
				<DateInput
					id="date-range-input-start-date"
					label={<FormattedMessage {...messages.from} />}
					date={startDate}
					onChange={onStartInputChange}
					setInvalidFormat={setStartInvalid}
				/>
				{!isValidDateRange && !startInvalid && !endInvalid && (
					<ErrorMessageWrapper>
						<ErrorMessage>
							<FormattedMessage {...messages.startDateAfterEndDateErrorMessage} />
						</ErrorMessage>
					</ErrorMessageWrapper>
				)}
				<CalendarInput date={startDate} onSelect={onStartCalendarSelect} />
			</ColumnContainer>
			<ColumnContainer>
				<DateInput
					id="date-range-input-end-date"
					label={<FormattedMessage {...messages.to} />}
					date={endDate}
					onChange={onEndInputChange}
					setInvalidFormat={setEndInvalid}
				/>
				<CalendarInput date={endDate} onSelect={onEndCalendarSelect} />
			</ColumnContainer>
		</Container>
	);
};

export default FixedDateRange;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flex: 1,
	justifyContent: 'space-between',
	gap: token('space.200'),

	'@media (max-width: 840px) and (min-width: 480px)': {
		flexDirection: 'column',
		flexGrow: 0,
	},

	'@media (max-width: 480px)': {
		flexDirection: 'column',
		flexGrow: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnContainer = styled.div({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageWrapper = styled.div({
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.100'),
	marginTop: token('space.negative.300'),
	marginBottom: token('space.050'),
});
