.container {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	height: 40px;
	display: flex;
	align-items: center;
}

.issuesCount {
	cursor: pointer;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	height: 100%;
	outline: none;
}

.issuesLabel {
	flex: 1 1 auto;
	padding-left: 8px;
	white-space: nowrap;
}
