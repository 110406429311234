import React, { useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	getPlanId,
	getScenarioId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/urls.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useFlagsService } from '@atlassian/jira-flags';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isDefined } from '../../ramda/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export default function FilterDependencyGraphFromRoadmap({
	children,
	ariaLabel,
	issueId,
	filterDependencyGraphByIssue,
	isDependencyViewEnabled,
	leadAccountId,
}: Props) {
	const { push } = useRouterActions();
	const planId = getPlanId();
	const scenarioId = getScenarioId();
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const accountId = useAccountId();

	const handleClick = useCallback(() => {
		if (fg('migrate_plans_navigation_to_relay')) {
			if (!isDependencyViewEnabled) {
				if (leadAccountId === accountId) {
					showFlag({
						messageId:
							'portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.show-flag.info.admin-dependency-flag',
						messageType: 'transactional',
						id: 'filter-dependency-graph-from-roadmap-admin-add-dependency-tab-flag',
						type: 'info',
						title: formatMessage(messages.dependencyFlagTitle),
						description: formatMessage(messages.adminAddDependencyFlagBody),
						isAutoDismiss: false,
					});
					return;
				}
				showFlag({
					messageId:
						'portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.show-flag.info.non-admin-dependency-flag',
					messageType: 'transactional',
					id: 'filter-dependency-graph-from-roadmap-non-admin-add-dependency-tab-flag',
					type: 'info',
					title: formatMessage(messages.dependencyFlagTitle),
					description: formatMessage(messages.nonAdminAddDependencyFlagBody),
					isAutoDismiss: false,
				});
				return;
			}
		}

		if (fg('smart_links_for_plans')) {
			// issueId should always be defined (even if it is scenario issue), but better be safe than sorry
			if (isDefined(issueId) && planId && scenarioId)
				push(`/jira/plans/${planId}/scenarios/${scenarioId}/dependencies?issueFilter=${issueId}`);

			return;
		}

		// issueId should always be defined (even if it is scenario issue), but better be safe than sorry
		if (isDefined(issueId)) {
			// redirecting the user to the dependencies tab
			if (planId && scenarioId) {
				push(`/jira/plans/${planId}/scenarios/${scenarioId}/dependencies`);
			}

			// setting the issue id in the Filter by issue field of the dependencies tab
			filterDependencyGraphByIssue(issueId);
		}
	}, [
		isDependencyViewEnabled,
		leadAccountId,
		accountId,
		showFlag,
		formatMessage,
		issueId,
		planId,
		scenarioId,
		push,
		filterDependencyGraphByIssue,
	]);

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.footerActionsSeparator}>·</div>
			<Button
				appearance="link"
				spacing="none"
				onClick={handleClick}
				ariaLabel={ariaLabel}
				testId={`portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.issue-${issueId}`}
			>
				{children}
			</Button>
		</>
	);
}
