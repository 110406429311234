import React from 'react';
import cx from 'classnames';
import StandardButton from '@atlaskit/button/standard-button';
import { Box, xcss } from '@atlaskit/primitives';
import PlansRoadmapIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/plans-roadmap/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import type { MenuTriggerProps } from '../../header/advanced-fields-menu/types.tsx';
import { FADE_OUT_WIDTH, HIDE_WIDTH } from './constants.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const calculateOpacity = (width: number) => {
	if (width >= FADE_OUT_WIDTH) return 1;
	if (width <= HIDE_WIDTH) return 0;

	return (width - HIDE_WIDTH) / (FADE_OUT_WIDTH - HIDE_WIDTH);
};

/* An empty view for the fields column.
 * In an attempt to optimise for various screen sizes, column configurations, and user interactions:
 * 1. The hero image shrinks at a certain threshold
 * 2. The hero image fades away at a second, smaller threshold (this was less jarring than binary visibility)
 */
const EmptyFields = ({
	width,
	columns,
	showColumn,
	moveColumn,
	hideColumn,
	getMatchingConfiguredDate,
	AdvancedFieldsMenu,
}: Props) => {
	const { formatMessage } = useIntl();

	const renderMenuTrigger = (menuTriggerProps: MenuTriggerProps) => (
		<StandardButton
			{...menuTriggerProps}
			appearance="primary"
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.empty.trigger-menu-button"
		>
			{formatMessage(messages.addFields)}
		</StandardButton>
	);
	return (
		<div
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.empty"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.container}
			style={{ width, opacity: calculateOpacity(width) }}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.imageGroup}>
				<Box xcss={imageStyles}>
					<PlansRoadmapIllustration alt="" fill />
				</Box>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-heading -- Ignored via go/DSP-18766 */}
				<h3 className={cx(styles.header, styles.text)}>{formatMessage(messages.header)}</h3>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
				<p className={styles.text}>{formatMessage(messages.callToAction)}</p>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.dropdown}>
					<AdvancedFieldsMenu
						id="empty-view"
						columns={columns}
						hideColumn={hideColumn}
						showColumn={showColumn}
						moveColumn={moveColumn}
						getMatchingConfiguredDate={getMatchingConfiguredDate}
						isShortcutEnabled={false}
						renderMenuTrigger={renderMenuTrigger}
						placement="right-end"
					/>
				</div>
			</div>
		</div>
	);
};

export default withSlots({ AdvancedFieldsMenu: slots.AdvancedFieldsMenu })(EmptyFields);

const imageStyles = xcss({
	position: 'relative',
	width: '360px',
	height: '269px',
});
