import React, { useCallback, useEffect } from 'react';
import { Box, Text, xcss, Inline } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import commonMessages from '../../common/ui/messages.tsx';
import type { DependencyIssue } from '../types.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const DependenciesFlyout = ({
	dependencies,
	missingIssueIds,
	isIncoming,
	testId,
	loadExternalIssues,
	ariaLabel,
}: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (missingIssueIds.length > 0) {
			loadExternalIssues(missingIssueIds);
		}
	}, [missingIssueIds, loadExternalIssues]);

	const fieldName = formatMessage(commonMessages.dependenciesTitle).toLowerCase();

	const renderIssue = useCallback(
		(issue?: DependencyIssue) => {
			if (!issue) return <Spinner size="medium" />;

			const { id, issueKey, summary, projectKey, typeIconUrl } = issue;

			return (
				<Inline key={issue.id} space="space.100" alignBlock="center" xcss={issueColumnStyle}>
					<AsyncIcon url={typeIconUrl} width={16} height={16} />
					<IssueLink
						projectKey={projectKey}
						issueKey={issueKey}
						issueId={id}
						// This aria label is required since the usual hidden text in <Anchor/> causes issues with the scroll container.
						ariaLabel={formatMessage(messages.ariaLabel, {
							fullIssueKey: issueKey ? `${projectKey}-${issueKey}` : projectKey,
						})}
					/>
					<Box as="span" xcss={issueSummaryStyle}>
						{summary}
					</Box>
				</Inline>
			);
		},
		[formatMessage],
	);

	const renderContent = useCallback(() => {
		if (missingIssueIds.length > 0)
			return (
				<Box padding="space.200">
					<Inline alignBlock="center" alignInline="center" grow="fill">
						<Spinner size="medium" testId={`${testId}.loading-spinner`} />
					</Inline>
				</Box>
			);

		if (dependencies.length === 0)
			return formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? commonMessages.addWorkItem
					: commonMessages.addIssue,
				{ field: fieldName },
			);

		return dependencies.map(({ issue, otherIssue, linkType }, index) => (
			<Inline key={index} space="space.400" alignBlock="center" xcss={listItemStyle}>
				{renderIssue(issue)}
				<Box xcss={linkTypeColumnStyle}>{isIncoming ? linkType.inward : linkType.outward}</Box>
				{renderIssue(otherIssue)}
			</Inline>
		));
	}, [dependencies, missingIssueIds, fieldName, formatMessage, isIncoming, testId, renderIssue]);

	return (
		<Box xcss={flyoutStyle} paddingBlock="space.200" testId={testId} aria-label={ariaLabel}>
			<Box xcss={headerStyle}>
				<Text size="small" color="color.text.subtlest" weight="semibold">
					{formatMessage(
						isIncoming
							? commonMessages.incomingDependenciesTitle
							: commonMessages.outgoingDependenciesTitle,
					)}
				</Text>
			</Box>
			<Box xcss={tableContentSyle}>{renderContent()}</Box>
		</Box>
	);
};

export default DependenciesFlyout;

const listItemStyle = xcss({
	minHeight: '32px',
});

const issueColumnStyle = xcss({
	width: '292px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const issueSummaryStyle = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const linkTypeColumnStyle = xcss({
	width: '156px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const flyoutStyle = xcss({
	color: 'color.text.subtlest',
	width: '100%',
	boxSizing: 'border-box',
});

const headerStyle = xcss({
	paddingInline: 'space.300',
	paddingBottom: 'space.100',
});

const tableContentSyle = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.100',
	overflowY: 'auto',
	paddingInline: 'space.300',
	maxHeight: '200px',
});
