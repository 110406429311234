import type { Store } from 'redux';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { modifyView } from '../../command/views/index.tsx';
import { getViewSettings } from '../../query/view-settings/index.tsx';
import { getActiveView } from '../../query/views/index.tsx';
import type { State } from '../../state/types.tsx';
import { relaxedScheduler } from '../util.tsx';

export default function createObserver(store: Store<State>) {
	const observer = () => {
		const state = store.getState();
		const activeView = getActiveView(state);

		if (!activeView) {
			return;
		}

		const preferences = getViewSettings(state);

		if (R.equals(preferences, activeView.preferences)) {
			return;
		}

		store.dispatch(
			modifyView({
				id: activeView.id,
				preferences,
				shouldFetchBacklog:
					fg('move_plan_fe_filters_to_be') &&
					!R.equals(preferences.filtersV1, activeView.preferences.filtersV1),
			}),
		);
	};

	return store.subscribe(relaxedScheduler(observer));
}
