import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	percentage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.percentage',
		defaultMessage: '({percentage}%)',
		description: 'Text for percentage value of progress',
	},
	issueCountLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.issue-count-label',
		defaultMessage: '{count, plural, one {{count} issue} other {{count} issues}}',
		description: 'Issue count label',
	},
	issueCountLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.issue-count-label-issue-term-refresh',
		defaultMessage: '{count, plural, one {{count} work item} other {{count} work items}}',
		description: 'Issue count label',
	},
	issueCountWithTotalLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.issue-count-with-total-label',
		defaultMessage: '{count} of {total, plural, one {{total} issue} other {{total} issues}}',
		description: 'Issue count label that contains total number of issues',
	},
	workItemCountWithTotalLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.work-item-count-with-total-label',
		defaultMessage:
			'{count} of {total, plural, one {{total} work item} other {{total} work items}}',
		description: 'Issue count label that contains total number of issues',
	},
	total: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.total',
		defaultMessage: 'Total',
		description: 'Label for the total number',
	},
	teamTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.team-title',
		defaultMessage: 'Team',
		description: 'The header on the flyout after user clicks on the team field.',
	},
	priorityTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.priority-title',
		defaultMessage: 'Priority',
		description: 'The header on the flyout after user clicks on the priority field.',
	},
	statusTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.status-title',
		defaultMessage: 'Status',
		description: 'The header on the flyout after user clicks on the status field.',
	},
	breakdownTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.breakdown-title',
		defaultMessage: 'Progress (issue count)',
		description: 'The header on the flyout after user clicks on the progress field.',
	},
	breakdownTitleIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.breakdown-title-issue-term-refresh',
		defaultMessage: 'Progress (work item count)',
		description: 'The header on the flyout after user clicks on the progress field.',
	},
	goalTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.goal-title',
		defaultMessage: 'Goal',
		description: 'The header on the flyout after user clicks on the goal field.',
	},
	labelTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.label-title',
		defaultMessage: 'Labels',
		description: 'The header on the flyout after user clicks on the labels field.',
	},
	releaseTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.release-title',
		defaultMessage: 'Releases',
		description: 'The header on the flyout after user clicks on the release field.',
	},
	ideasTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.ideas-title',
		defaultMessage: 'Ideas',
		description: 'The header on the flyout after user clicks on the ideas field.',
	},
	dependenciesTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.dependencies-title',
		defaultMessage: 'Dependencies',
		description: 'The header on the flyout after user clicks on the dependencies field.',
	},
	incomingDependenciesTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.incoming-dependencies-title',
		defaultMessage: 'Dependencies (blocked by)',
		description: 'The header on the flyout after user clicks on the dependencies field.',
	},
	outgoingDependenciesTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.outgoing-dependencies-title',
		defaultMessage: 'Dependencies (blocks)',
		description: 'The header on the flyout after user clicks on the dependencies field.',
	},
	addIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.add-issue',
		defaultMessage: 'Add {field} to issues to see them here',
		description:
			'The message on the flyout when the field is empty, prompt user to add issue field.',
	},
	addWorkItem: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.add-work-item',
		defaultMessage: 'Add {field} to work items to see them here',
		description:
			'The message on the flyout when the field is empty, prompt user to add issue field.',
	},
	issuesWithoutField: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.issues-without-field',
		defaultMessage: 'Issues without {field}',
		description: 'The message on the flyout that call out how many issues do not have the field.',
	},
	workItemsWithoutField: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.common.ui.work-items-without-field',
		defaultMessage: 'Work items without {field}',
		description:
			'The message on the flyout that call out how many work items do not have the field.',
	},
});
