import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const SkeletonRow = () => (
	<Box xcss={SkeletonWrapper}>
		<Skeleton width="25%" height="20px" />
		<Skeleton width="70%" height="20px" />
	</Box>
);

export const PinnedFieldsLoader = () => (
	<>
		<SkeletonRow />
		<SkeletonRow />
		<SkeletonRow />
	</>
);

const SkeletonWrapper = xcss({
	marginBottom: 'space.100',
	display: 'flex',
	justifyContent: 'space-between',
});
