import type {
	Entity,
	SelectedChanges,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import type { IssueChangeQueueEntry } from './types.tsx';

export const START = 'state.domain.update-jira.commit.START' as const;
export const INCREMENT_NUMBER_OF_COMMITTED_CHANGES =
	'state.domain.update-jira.commit.INCREMENT_NUMBER_OF_COMMITTED_CHANGES' as const;
export const ADD_NUMBER_OF_COMMITTED_CHANGES =
	'state.domain.update-jira.commit.ADD_NUMBER_OF_COMMITTED_CHANGES' as const;
export const INTERRUPT = 'state.domain.update-jira.commit.INTERRUPT' as const;
export const STOP = 'state.domain.update-jira.commit.STOP' as const;
export const ADD_TO_ISSUE_CHANGE_QUEUE =
	'state.domain.update-jira.commit.ADD_TO_ISSUE_CHANGE_QUEUE' as const;
export const REMOVE_FROM_ISSUE_CHANGE_QUEUE =
	'state.domain.update-jira.commit.REMOVE_FROM_ISSUE_CHANGE_QUEUE' as const;
export const SET_PENDING_CHANGES = 'state.domain.update-jira.commit.SET_PENDING_CHANGES' as const;
export const REMOVE_FROM_PENDING_CHANGES =
	'state.domain.update-jira.commit.REMOVE_FROM_PENDING_CHANGES' as const;
export const CLEAR_PENDING_CHANGES =
	'state.domain.update-jira.commit.CLEAR_PENDING_CHANGES' as const;
export const UPDATE_ISSUE_REFERENCES =
	'state.domain.update-jira.commit.UPDATE_ISSUE_REFERENCES' as const;

export type TotalChanges = number;

export type StartActionPayload = TotalChanges;

export type AddActionPayload = number;

export type SetPendingChangesPayload = SelectedChanges;

export type RemoveFromPendingChangesPayload = { type: Entity; id: string };

export type StartAction = {
	type: typeof START;
	payload: StartActionPayload;
};

export type AddAction = {
	type: typeof ADD_NUMBER_OF_COMMITTED_CHANGES;
	payload: AddActionPayload;
};

export type IncrementAction = {
	type: typeof INCREMENT_NUMBER_OF_COMMITTED_CHANGES;
};

export type InterruptAction = {
	type: typeof INTERRUPT;
};

export type StopAction = {
	type: typeof STOP;
};

export type AddToIssueChangeQueueAction = {
	type: typeof ADD_TO_ISSUE_CHANGE_QUEUE;
	payload: { id: string; entry: IssueChangeQueueEntry };
};

export type UpdateIssueReferencesAction = {
	type: typeof UPDATE_ISSUE_REFERENCES;
	payload: { id: string; generatedId: string };
};

export type RemoveFromIssueChangeQueueAction = {
	type: typeof REMOVE_FROM_ISSUE_CHANGE_QUEUE;
	payload: string;
};

export type SetPendingChangesAction = {
	type: typeof SET_PENDING_CHANGES;
	payload: SetPendingChangesPayload;
};

export type RemoveFromPendingChangesAction = {
	type: typeof REMOVE_FROM_PENDING_CHANGES;
	payload: RemoveFromPendingChangesPayload;
};

export type ClearPendingChangesAction = {
	type: typeof CLEAR_PENDING_CHANGES;
};

export const start = (totalChanges: TotalChanges) => ({ type: START, payload: totalChanges });
export const incrementNumberOfCommittedChanges = () => ({
	type: INCREMENT_NUMBER_OF_COMMITTED_CHANGES,
});
export const addNumberOfCommittedChanges = (toAdd: number) => ({
	type: ADD_NUMBER_OF_COMMITTED_CHANGES,
	payload: toAdd,
});
export const interrupt = () => ({ type: INTERRUPT });
export const stop = () => ({ type: STOP });
export const addToIssueChangeQueue = (payload: { id: string; entry: IssueChangeQueueEntry }) => ({
	type: ADD_TO_ISSUE_CHANGE_QUEUE,
	payload,
});
export const removeFromIssueChangeQueue = (payload: string) => ({
	type: REMOVE_FROM_ISSUE_CHANGE_QUEUE,
	payload,
});
export const setPendingChanges = (payload: SelectedChanges) => ({
	type: SET_PENDING_CHANGES,
	payload,
});
export const removeFromPendingChanges = (payload: RemoveFromPendingChangesPayload) => ({
	type: REMOVE_FROM_PENDING_CHANGES,
	payload,
});
export const clearPendingChanges = () => ({ type: CLEAR_PENDING_CHANGES });

export const updateIssueReferences = (payload: { id: string; generatedId: string }) => ({
	type: UPDATE_ISSUE_REFERENCES,
	payload,
});
