import type { State as AssociatedIssueState } from './types.tsx';

type FetchMissingAssociatedIssuesStartAction = {
	type: 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_START';
};
type FetchMissingAssociatedIssuesSuccessPayload = Pick<
	AssociatedIssueState,
	'associatedIssues' | 'lastAssociatedIssueIds'
>;
type FetchMissingAssociatedIssuesSuccessAction = {
	type: 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_SUCCESS';
	payload: FetchMissingAssociatedIssuesSuccessPayload;
};
type FetchMissingAssociatedIssuesFailAction = {
	type: 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_FAIL';
};

export const fetchMissingAssociatedIssuesStart = (): FetchMissingAssociatedIssuesStartAction => ({
	type: 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_START',
});
export const fetchMissingAssociatedIssuesSuccess = (
	payload: FetchMissingAssociatedIssuesSuccessPayload,
): FetchMissingAssociatedIssuesSuccessAction => ({
	type: 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_SUCCESS',
	payload,
});
export const fetchMissingAssociatedIssuesFail = (): FetchMissingAssociatedIssuesFailAction => ({
	type: 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_FAIL',
});

export type AssociatedIssuesActions =
	| FetchMissingAssociatedIssuesStartAction
	| FetchMissingAssociatedIssuesSuccessAction
	| FetchMissingAssociatedIssuesFailAction;
