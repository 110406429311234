import isUndefined from 'lodash/fp/isUndefined';
import * as R from 'ramda';
import { getReleaseStatus } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/utils.tsx';
import type {
	SolutionVersionsById,
	EnrichedVersion,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import {
	OPTIMIZED,
	type Mode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';

import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import type { ChangeCustomDatesActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/actions.tsx';
import {
	OVERVIEW_RELEASES,
	type Mode as MarkerMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/types.tsx';
import type { ReleaseStatusValue } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type {
	TimelineRange,
	Timestamp,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	RELEASE,
	CROSS_PROJECT_RELEASE,
	RELEASE_STATUSES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { MappedVersions } from './marker/types.tsx';
import { ICON_TYPES } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ICON_TYPES } from './types';

const releaseStatusToIconType = {
	[RELEASE_STATUSES.UNRELEASED]: ICON_TYPES.UNRELEASED,
	[RELEASE_STATUSES.RELEASED]: ICON_TYPES.RELEASED,
	[RELEASE_STATUSES.OFFTRACK]: ICON_TYPES.OFFTRACK,
	[RELEASE_STATUSES.ONTRACK]: ICON_TYPES.ONTRACK,
} as const;

export function isProject(proj: Project | number | undefined): proj is Project {
	return !isUndefined(proj) && typeof proj !== 'number' && proj.id != null;
}

export function isVersionCrossProject(
	version: EnrichedVersion | EnrichedCrossProjectVersion,
): version is EnrichedCrossProjectVersion {
	return 'versions' in version;
}

export const getVersionEndDate = (
	mode: Mode,
	versionEndDate?: Timestamp | null,
	solutionEndDate?: Timestamp | null,
): Timestamp | null | undefined => {
	/** In this case, release has a fixed end date. We can't use calculated end date
	 * when user has set a fixed end date to it.
	 */
	if (isDefined(versionEndDate)) {
		return versionEndDate;
	}

	/** When user has set release to have dynamic release date then we can use
	 * calculated end date in OPTIMIZED mode.
	 */
	if (mode === OPTIMIZED) {
		return solutionEndDate || versionEndDate;
	}

	/** While without optimization we don't know the date. Hence we have to return
	 * version's end date, which is null.
	 */
	return versionEndDate;
};

export const getCrossProjectVersionEndDate = (
	mode: Mode,
	versions: Version[],
	solutionVersionsById: SolutionVersionsById,
): Timestamp | null | undefined => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const versionEndDates: Timestamp[] = versions.reduce<Array<any>>(
		(result, { id, end: versionEnd }) => {
			const solutionVersion = solutionVersionsById[id];
			let solutionEnd = null;
			if (isDefined(solutionVersion)) {
				solutionEnd = solutionVersion.end;
			}

			const versionEndDate: Timestamp | null | undefined = getVersionEndDate(
				mode,
				versionEnd,
				solutionEnd,
			);
			if (isDefined(versionEndDate)) {
				result.push(versionEndDate);
			}
			return result;
		},
		[],
	);

	if (versionEndDates.length === 0) {
		return null;
	}
	return versionEndDates.reduce((result, end) => Math.max(result, end));
};

export const calculateLeftPositionPercentage = (
	endDate: Timestamp,
	{ start: timelineStart, end: timelineEnd }: TimelineRange,
): number => ((endDate - timelineStart) / (timelineEnd - timelineStart)) * 100;

export const calculateSlippage = (
	solutionEndDate: Timestamp | null | undefined,
	versionEndDate: Timestamp,
): number => {
	if (isDefined(solutionEndDate)) {
		return solutionEndDate - versionEndDate;
	}
	return 0;
};

export const calculateNewTimelineRange = (
	nextDate: Timestamp,
	{ start, end }: TimelineRange,
): ChangeCustomDatesActionPayload => {
	const medianTimeLineDate = (end + start) / 2;
	const releasesDatesDiff = Math.abs(nextDate - medianTimeLineDate);

	if (nextDate < start) {
		return {
			fromDate: start - releasesDatesDiff,
			toDate: end - releasesDatesDiff,
		};
	}
	return {
		fromDate: start + releasesDatesDiff,
		toDate: end + releasesDatesDiff,
	};
};

export const calculateEndVersionsDate = (
	version: EnrichedVersion | EnrichedCrossProjectVersion,
	solutionVersionsById: SolutionVersionsById,
	mode: Mode,
) => {
	const solutionVersion = solutionVersionsById && solutionVersionsById[version.id];
	let solutionEnd = null;
	if (isDefined(solutionVersion)) {
		solutionEnd = solutionVersion.end;
	}

	let versionEndDate: Timestamp | null | undefined;
	if (!isVersionCrossProject(version)) {
		versionEndDate = getVersionEndDate(mode, version.end, solutionEnd);
	} else {
		versionEndDate = getCrossProjectVersionEndDate(mode, version.versions, solutionVersionsById);
	}

	return versionEndDate;
};

export const getOverviewReleaseStatus = ({
	crossProjectVersions,
	versions,
}: MappedVersions): ReleaseStatusValue => {
	const crossProjectVersionStatuses: string[] = crossProjectVersions.map((crossProjectVersion) =>
		getReleaseStatus(crossProjectVersion),
	);
	const versionStatuses: string[] = versions.map(({ releaseStatusId }) => releaseStatusId);

	const joinedVersions: string[] = [...crossProjectVersionStatuses, ...versionStatuses];
	if (R.all(R.equals<string>(RELEASE_STATUSES.RELEASED))(joinedVersions)) {
		return RELEASE_STATUSES.RELEASED;
	}
	if (R.any(R.equals<string>(RELEASE_STATUSES.OFFTRACK))(joinedVersions)) {
		return RELEASE_STATUSES.OFFTRACK;
	}
	return RELEASE_STATUSES.ONTRACK;
};

export const getMarkerTypeIcon = (
	mappedVersions: MappedVersions,
	mode: Mode,
	flyoutType: MarkerMode,
	solutionVersionsById: SolutionVersionsById,
): (typeof ICON_TYPES)[keyof typeof ICON_TYPES] => {
	let releaseStatusId;
	const { versions, crossProjectVersions } = mappedVersions;
	switch (flyoutType) {
		case RELEASE:
			releaseStatusId = versions[0] && versions[0].releaseStatusId;
			break;
		case CROSS_PROJECT_RELEASE:
			releaseStatusId = getReleaseStatus(crossProjectVersions[0]);
			break;
		default:
			releaseStatusId = getOverviewReleaseStatus(mappedVersions);
			break;
	}

	let iconType = releaseStatusToIconType[releaseStatusId];
	let slippage = null;

	if (flyoutType === RELEASE && mode === OPTIMIZED) {
		const { id, end: versionEnd } = versions[0];
		const solutionVersion = solutionVersionsById[id];
		const solutionEnd = isDefined(solutionVersion) ? solutionVersion.end : null;
		slippage = versionEnd ? calculateSlippage(solutionEnd, versionEnd) : 0;

		if (releaseStatusId !== RELEASE_STATUSES.RELEASED) {
			if (slippage > 0) {
				iconType = ICON_TYPES.OFFTRACK;
			} else {
				iconType = ICON_TYPES.ONTRACK;
			}
		}
	}
	return iconType;
};

export const getVersionFlyoutType = (
	versions: (EnrichedCrossProjectVersion | EnrichedVersion)[],
) => {
	if (versions.length > 1) {
		return OVERVIEW_RELEASES;
	}
	if (versions[0] && isVersionCrossProject(versions[0]) && versions[0].versions) {
		return CROSS_PROJECT_RELEASE;
	}
	return RELEASE;
};
