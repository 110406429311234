/** @jsx jsx */
import React, { useCallback, useMemo, useRef, forwardRef, type Ref, type ElementRef } from 'react';
import { css, jsx } from '@compiled/react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { token } from '@atlaskit/tokens';
import Lozenge from '@atlaskit/lozenge';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Section, HeadingItem } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import Checkbox from '@atlaskit/checkbox';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { sortAsLowerCaseByNameProp } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/composed.tsx';
import {
	values,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { NO_RELEASE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/release-filter/index.tsx';
import {
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DelayedAnnouncer } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/common/delayed-announcer/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { ContentWrapper, ItemWrapper } from '../common/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ReleaseFilterInner = (
	props: Props & { setInitialFocusRef?: (elem: HTMLElement | null) => void },
) => {
	const announcerRef = useRef<ElementRef<typeof DelayedAnnouncer>>(null);
	const { formatMessage } = useIntl();
	const {
		value,
		onQueryChange,
		crossProjectReleaseFilterValue,
		clearReleaseFilter,
		searchQuery,
		setInitialFocusRef,
		crossProjectVersions,
		versionsById,
		projects,
		changeReleaseFilter,
		crossProjectVersionsById,
	} = props;

	const isClearButtonVisible = useMemo(() => {
		if (!(value.length || crossProjectReleaseFilterValue.length)) {
			return false;
		}
		return true;
	}, [value, crossProjectReleaseFilterValue]);

	const onClearClick = useCallback(() => clearReleaseFilter(), [clearReleaseFilter]);

	const onSearchQueryChange = useCallback(
		(query: string) => {
			onQueryChange(query);
			isDefined(announcerRef.current) && announcerRef.current.announce();
		},
		[onQueryChange],
	);

	const onVersionClick = useCallback(
		(id: string, isSelected: boolean) => {
			if (isSelected) {
				changeReleaseFilter(RELEASE_FILTER_ID, [...value, id]);
			} else {
				changeReleaseFilter(
					RELEASE_FILTER_ID,
					value.filter((version) => version !== id),
				);
			}
		},
		[changeReleaseFilter, value],
	);

	const onCrossProjectVersionClick = useCallback(
		(id: string, isSelected: boolean): void => {
			if (isSelected) {
				changeReleaseFilter(CROSS_PROJECT_RELEASE_FILTER_ID, [
					...crossProjectReleaseFilterValue,
					id,
				]);
			} else {
				changeReleaseFilter(
					CROSS_PROJECT_RELEASE_FILTER_ID,
					crossProjectReleaseFilterValue.filter((version) => version !== id),
				);
			}
		},
		[changeReleaseFilter, crossProjectReleaseFilterValue],
	);

	const renderNoReleases = useCallback(() => {
		const name = formatMessage({ ...messages.noReleasesFilterText });
		const isSelected = (versionId: string) => includes(value, versionId);
		const selected = isSelected(NO_RELEASE_ID);

		return (
			<ItemWrapper>
				<Checkbox
					id={NO_RELEASE_ID}
					key={NO_RELEASE_ID}
					name={name}
					isChecked={selected}
					onChange={() => onVersionClick(NO_RELEASE_ID, !isSelected(NO_RELEASE_ID))}
					label={
						<Tooltip content={name}>
							<div css={releaseCheckboxLabelTitleStyles}>{name}</div>
						</Tooltip>
					}
				/>
			</ItemWrapper>
		);
	}, [value, onVersionClick, formatMessage]);

	const filteredCrossProjectVersions = useMemo(() => {
		return crossProjectVersions.filter(({ name }) =>
			name.toLowerCase().includes(searchQuery.toLowerCase()),
		);
	}, [crossProjectVersions, searchQuery]);

	const renderCrossProjectReleasesGroup = useCallback(() => {
		const isSelected = (versionId: string) => includes(crossProjectReleaseFilterValue, versionId);

		if (filteredCrossProjectVersions.length === 0) {
			return null;
		}

		return (
			<Section
				{...(fg('jfp_a11y_team_timeline_filter_group_form_control')
					? { titleId: `${CROSS_PROJECT_RELEASE_FILTER_ID}.title` }
					: { title: formatMessage(commonMessages.crossProjectReleases) })}
			>
				{fg('jfp_a11y_team_timeline_filter_group_form_control') && (
					<HeadingItem
						testId={`${CROSS_PROJECT_RELEASE_FILTER_ID}.title--heading`}
						id={`${CROSS_PROJECT_RELEASE_FILTER_ID}.title`}
					>
						{formatMessage(commonMessages.crossProjectReleases)}
					</HeadingItem>
				)}
				{filteredCrossProjectVersions.length > 0 &&
					filteredCrossProjectVersions.map((version) => {
						const { id, name } = version;

						return (
							<ItemWrapper key={id}>
								<Checkbox
									id={id}
									key={id}
									name={name}
									isChecked={isSelected(id)}
									onChange={() => onCrossProjectVersionClick(id, !isSelected(id))}
									label={
										<Tooltip content={name}>
											<div css={releaseCheckboxLabelWrapperStyles}>
												<div css={releaseCheckboxLabelTitleStyles}>{name}</div>
											</div>
										</Tooltip>
									}
								/>
							</ItemWrapper>
						);
					})}
			</Section>
		);
	}, [
		filteredCrossProjectVersions,
		onCrossProjectVersionClick,
		formatMessage,
		crossProjectReleaseFilterValue,
	]);

	const getVersion = useCallback(
		(versionId: string): Version => {
			return versionsById[versionId];
		},
		[versionsById],
	);

	const filterVersionsWithSearchQuery = useCallback(
		(version: Version) => {
			const { name, crossProjectVersion } = version;

			if (
				isDefined(crossProjectVersion) &&
				isDefined(crossProjectVersionsById[crossProjectVersion])
			) {
				return (
					name.toLowerCase().includes(searchQuery.toLowerCase()) ||
					crossProjectVersionsById[crossProjectVersion].name
						.toLowerCase()
						.includes(searchQuery.toLowerCase())
				);
			}
			return name.toLowerCase().includes(searchQuery.toLowerCase());
		},
		[crossProjectVersionsById, searchQuery],
	);

	const filteredProjects = useMemo(() => {
		const filterProjectsWithSearchQuery = (project: Project): boolean =>
			project.versions.map(getVersion).some(filterVersionsWithSearchQuery);

		return projects.filter(
			(project) => project.isReleasesEnabled && filterProjectsWithSearchQuery(project),
		);
	}, [filterVersionsWithSearchQuery, getVersion, projects]);

	const renderVersionOptions = useCallback(
		(versions: string[]) => {
			const isSelected = (versionId: string) => includes(value, versionId);
			const sortedVersionObjects = sortAsLowerCaseByNameProp(versions.map(getVersion));
			return sortedVersionObjects.filter(filterVersionsWithSearchQuery).map((version) => {
				const { id, name, crossProjectVersion } = version;
				const doesHaveCrossProjectVersionThatIsNotDeleted: boolean =
					isDefined(crossProjectVersion) &&
					isDefined(crossProjectVersionsById[crossProjectVersion]);
				const crossProjectVersionName: string = doesHaveCrossProjectVersionThatIsNotDeleted
					? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						crossProjectVersionsById[crossProjectVersion as string].name
					: '';

				return (
					<ItemWrapper key={id}>
						<Checkbox
							id={id}
							key={id}
							name={name}
							isChecked={isSelected(id)}
							onChange={() => onVersionClick(id, !isSelected(id))}
							label={
								<div css={releaseCheckboxLabelOuterWrapperStyles}>
									<Tooltip content={name}>
										<div css={releaseCheckboxLabelWrapperStyles}>
											<div css={releaseCheckboxLabelTitleStyles}>{name}</div>
										</div>
									</Tooltip>
									{isDefined(crossProjectVersion) && (
										<Lozenge maxWidth={100}>
											<Tooltip content={crossProjectVersionName}>
												<div css={releaseCheckboxLabelTitleStyles}>{crossProjectVersionName}</div>
											</Tooltip>
										</Lozenge>
									)}
								</div>
							}
						/>
					</ItemWrapper>
				);
			});
		},
		[crossProjectVersionsById, filterVersionsWithSearchQuery, getVersion, onVersionClick, value],
	);

	const renderProjectGroups = useCallback(
		() => (
			<>
				{filteredProjects.map(({ id, name, key, versions, avatarUrl }) => {
					return (
						<Section
							{...(fg('jfp_a11y_team_timeline_filter_group_form_control') && {
								titleId: `${RELEASE_FILTER_ID}.projects.title.${id}`,
							})}
							key={id}
						>
							<HeadingItem
								{...(fg('jfp_a11y_team_timeline_filter_group_form_control') && {
									id: `${RELEASE_FILTER_ID}.projects.title.${id}`,
									testId: `${RELEASE_FILTER_ID}.projects.title.${id}--heading`,
								})}
							>
								<TitleWithAvatar avatarUrl={avatarUrl} id={id} projectKey={key} name={name} />
							</HeadingItem>
							{renderVersionOptions(versions)}
						</Section>
					);
				})}
			</>
		),
		[filteredProjects, renderVersionOptions],
	);

	const searchResultsLength = useMemo(() => {
		return filteredProjects.length + filteredCrossProjectVersions.length;
	}, [filteredCrossProjectVersions.length, filteredProjects.length]);

	return (
		<ContentWrapper>
			<ClearFilterButton isVisible={isClearButtonVisible} onClearClick={onClearClick} />
			<SearchField
				placeholder={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchReleasesPlaceholderIssueTermRefresh
						: messages.searchReleasesPlaceholder,
				)}
				searchQuery={searchQuery}
				ariaLabel={formatMessage(messages.searchReleasesLabel)}
				onQueryChange={onSearchQueryChange}
				setInitialFocusRef={setInitialFocusRef}
			/>
			<DelayedAnnouncer
				ref={announcerRef}
				label={
					searchResultsLength > 0
						? formatMessage(messages.searchResultsCount, {
								totalCount: searchResultsLength,
							})
						: formatMessage(commonMessages.noMatchFoundText)
				}
			/>
			{!isEmpty(versionsById) && renderNoReleases()}
			{renderCrossProjectReleasesGroup()}
			{renderProjectGroups()}
			{searchResultsLength === 0 && <NoMatchFound />}
		</ContentWrapper>
	);
};

const ReleaseFilterTriggerButton = forwardRef(
	(
		props: Pick<
			Props,
			| 'isOpen'
			| 'value'
			| 'versionsById'
			| 'crossProjectVersions'
			| 'crossProjectReleaseFilterValue'
			| 'onOpenChange'
		> &
			TriggerProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { formatMessage } = useIntl();
		const {
			isOpen,
			value,
			versionsById,
			crossProjectVersions,
			crossProjectReleaseFilterValue,
			onOpenChange,
			...triggerProps
		} = props;
		const constructedFilterText = useMemo(() => {
			const versionNames = sortAsLowerCaseByNameProp(values(versionsById))
				.filter(({ id }) => includes(value, id))
				.map(({ name }) => name);

			const crossProjectVersionNames = sortAsLowerCaseByNameProp(crossProjectVersions)
				.filter(({ id }) => includes(crossProjectReleaseFilterValue, id))
				.map(({ name }) => name);
			const result = [...versionNames, ...crossProjectVersionNames];
			if (includes(value, NO_RELEASE_ID)) {
				result.push(formatMessage({ ...messages.noReleasesFilterText }));
			}
			return result.join(', ');
		}, [crossProjectReleaseFilterValue, crossProjectVersions, formatMessage, value, versionsById]);

		const filterText = useMemo(() => {
			return value.length > 0 || crossProjectReleaseFilterValue.length > 0 ? (
				<FilterText text={constructedFilterText} />
			) : (
				<FormattedMessage {...messages.emptyPlaceholder} />
			);
		}, [constructedFilterText, crossProjectReleaseFilterValue.length, value.length]);

		const ariaText = useMemo(() => {
			return `${formatMessage(filterMessages[RELEASE_FILTER_ID])}, ${
				value.length > 0 ? constructedFilterText : formatMessage(messages.emptyPlaceholder)
			} ${formatMessage(filterMessages.selected)}`;
		}, [constructedFilterText, formatMessage, value.length]);

		return (
			<TriggerButton
				{...triggerProps}
				ref={ref}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				triggerButtonText={filterText}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.trigger-btn"
				ariaLabel={ariaText}
			/>
		);
	},
);

const ReleaseFilter = (props: Props) => {
	const {
		isOpen,
		value,
		versionsById,
		crossProjectVersions,
		crossProjectReleaseFilterValue,
		onOpenChange,
	} = props;
	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			onClose={() => {
				onOpenChange({ isOpen: false });
			}}
			content={(contentProps) => (
				<ReleaseFilterInner {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter"
			trigger={(triggerProps) => (
				<ReleaseFilterTriggerButton
					{...triggerProps}
					isOpen={isOpen}
					value={value}
					versionsById={versionsById}
					crossProjectVersions={crossProjectVersions}
					crossProjectReleaseFilterValue={crossProjectReleaseFilterValue}
					onOpenChange={onOpenChange}
				/>
			)}
		/>
	);
};

export default ReleaseFilter;

const releaseCheckboxLabelTitleStyles = css({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const releaseCheckboxLabelWrapperStyles = css({
	alignItems: 'center',
	display: 'grid',
	gap: token('space.100'),
	gridTemplateColumns: 'fit-content(100%) fit-content(100%)',
});

const releaseCheckboxLabelOuterWrapperStyles = css({
	alignItems: 'center',
	display: 'grid',
	gap: token('space.100'),
	gridTemplateColumns: '1fr auto',
});
