import type { Dispatch } from 'redux';
import {
	bulkRankIssue,
	moveIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type {
	BulkRankIssueActionPayload,
	MoveIssuePayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import type { DispatchProps } from './types.tsx';

export const mapDispatchToPropsOld = (
	dispatch: Dispatch<{
		type: string;
	}>,
): DispatchProps => ({
	bulkUpdateRank: (payload: BulkRankIssueActionPayload) => {
		dispatch(bulkRankIssue(payload));
	},
});

export const mapDispatchToProps = (
	dispatch: Dispatch<{
		type: string;
	}>,
): DispatchProps => ({
	bulkUpdateRank: (payload: BulkRankIssueActionPayload) => {
		dispatch(bulkRankIssue(payload));
	},
	rankIssue: (payload: MoveIssuePayload) => {
		dispatch(moveIssue(payload));
	},
});
