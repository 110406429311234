/**
 * @jsx jsx
 */
import Popup from '@atlaskit/popup';
import { cssMap, jsx } from '@atlaskit/css';
import Tooltip from '@atlaskit/tooltip';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { FOCUS_AREA_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { FocusArea } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-focus-areas/types.tsx';
import { FocusArea as FocusAreaUI } from '@atlassian/jira-software-focus-areas/src/ui/focus-area/index.tsx';
import { ContentWrapper, ItemWrapper } from '../common/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { TriggerButton } from '../common/trigger-button/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import filterMessages from '../messages.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const styles = cssMap({
	item: {
		maxWidth: '400px',
		alignItems: 'center',
		display: 'flex',
		maxHeight: '16px',
	},
});
const FocusAreaFilter = ({
	clearFocusAreaFilter,
	value,
	searchQuery,
	onOpenChange,
	isOpen,
	onQueryChange,
	changeFocusAreaFilter,
	focusAreas,
	focusAreasById,
}: Props) => {
	const { formatMessage } = useIntl();
	const isFocusAreasSelected = value && value.length > 0;
	const isFocusAreaSelected = (focusId: string) => value.includes(focusId);

	const onFocusAreaClick = (id: string): void => {
		if (isFocusAreaSelected(id)) {
			changeFocusAreaFilter(value.filter((focusId) => focusId !== id));
		} else {
			changeFocusAreaFilter([...value, id]);
		}
	};

	const constructFilterText = () => {
		return value
			.reduce((acc: string[], focusId) => {
				const focus = focusAreasById[focusId];
				if (focus) {
					acc.push(focus.name);
				}
				return acc;
			}, [])
			.sort()
			.join(', ');
	};

	const renderFilterText = () => {
		if (isFocusAreasSelected) {
			const filterText = constructFilterText();
			return <FilterText text={filterText} />;
		}
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	};

	const ariaText = () => {
		const filterText = isFocusAreasSelected
			? constructFilterText()
			: formatMessage(messages.emptyPlaceholder);

		return `${formatMessage(filterMessages[FOCUS_AREA_FILTER_ID])}, ${filterText} ${formatMessage(
			filterMessages.selected,
		)}`;
	};

	const filterFocusAreaWithSearchQuery = (focusArea: FocusArea) => {
		return focusArea.name.toLowerCase().includes(searchQuery.toLowerCase());
	};
	const filteredFocusArea = focusAreas ? focusAreas.filter(filterFocusAreaWithSearchQuery) : [];

	return (
		<Popup
			placement="bottom-start"
			shouldReturnFocus
			shouldRenderToParent
			isOpen={isOpen}
			onClose={() => onOpenChange({ isOpen: false })}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.focus-area-filter.focus-filter"
			trigger={({ ref, ...triggerProps }) => (
				<TriggerButton
					{...triggerProps}
					ref={ref}
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					triggerButtonText={renderFilterText()}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.focus-area-filter.trigger-btn"
					ariaLabel={ariaText()}
				/>
			)}
			content={({ setInitialFocusRef }) => (
				<ContentWrapper>
					<ClearFilterButton isVisible={isFocusAreasSelected} onClearClick={clearFocusAreaFilter} />
					<SearchField
						setInitialFocusRef={setInitialFocusRef}
						placeholder={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchFocusAreaPlaceholderIssueTermRefresh
								: messages.searchFocusAreaPlaceholder,
						)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
					/>
					{filteredFocusArea.map((focusArea) => {
						const { id, name, status } = focusArea;
						return (
							<ItemWrapper key={id}>
								<Checkbox
									id={id}
									isChecked={isFocusAreaSelected(id)}
									onChange={() => onFocusAreaClick(id)}
									label={
										status !== undefined ? (
											<Tooltip content={name}>
												<div css={styles.item}>
													<FocusAreaUI status={status} name={name} isExportMode={false} />
												</div>
											</Tooltip>
										) : (
											name
										)
									}
								/>
							</ItemWrapper>
						);
					})}
					{filteredFocusArea.length === 0 && <NoMatchFound />}
				</ContentWrapper>
			)}
		/>
	);
};

export default FocusAreaFilter;
