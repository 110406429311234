import { token } from '@atlaskit/tokens';
import type { GoalStateValue } from '@atlassian/jira-shared-types/src/goal.tsx';

export const mapStateToBgColor = (state: GoalStateValue): string => {
	switch (state) {
		case 'off_track':
			return token('color.background.accent.red.subtlest');
		case 'at_risk':
			return token('color.background.accent.yellow.subtlest');
		case 'on_track':
			return token('color.background.accent.green.subtlest');
		case 'archived':
		case 'cancelled':
		case 'done':
		case 'paused':
		case 'pending':
		default:
			return token('color.background.accent.gray.subtlest');
	}
};
export const mapStateToColor = (state: GoalStateValue): string => {
	switch (state) {
		case 'off_track':
			return token('color.text.accent.red');
		case 'at_risk':
			return token('color.text.accent.orange');
		case 'on_track':
			return token('color.text.accent.green');
		case 'archived':
		case 'cancelled':
		case 'done':
		case 'paused':
		case 'pending':
		default:
			return token('color.text.accent.gray');
	}
};

export const mapScoreToBgColor = (score: number): string => {
	if (score < 0.4) {
		return token('color.background.danger.bold');
	}
	if (score < 0.7) {
		return token('color.background.accent.orange.bolder');
	}
	return token('color.background.success.bold');
};

export const extractStatusLabelWithoutScore = (fullLabel: string): string =>
	fullLabel.split(' - ')[0];
