import { RESET, type ResetAction } from './actions.tsx';
import type { FocusArea } from './types.tsx';

const initialState: FocusArea[] = [];

type Action = ResetAction;

const focusAreasReducer = (state: FocusArea[] = initialState, action: Action): FocusArea[] => {
	switch (action.type) {
		case RESET:
			return action.payload;
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};

export default focusAreasReducer;
