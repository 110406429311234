import React from 'react';
import { token } from '@atlaskit/tokens';

const OffTrackIcon = ({ totalCount }: { totalCount: number }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
		<g fill="none" fillRule="evenodd">
			<circle fill={token('color.icon.danger')} cx={12} cy={12} r={7} />
			{totalCount === 1 && (
				<rect fill={token('color.icon.inverse')} x="8" y="11" width="8" height="2" rx="1" />
			)}
		</g>
	</svg>
);
export { OffTrackIcon };
