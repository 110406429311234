/** @jsx jsx */
import React, { useCallback } from 'react';
import Heading from '@atlaskit/heading';
import { cssMap, cx, jsx } from '@atlaskit/css';
import { Box, Text, Stack, Flex } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import CloseIcon from '@atlaskit/icon/core/migration/close--cross';
import { IconButton } from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { IPH_IDS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ConfigurationDialog = (props: Props) => {
	const { onPreviewResults, onClose, autoScheduleConfiguration, selectedIssuesCount } = props;
	const { formatMessage } = useIntl();

	const onOpenInProductHelpArticle = useHelpPanelLink();

	const aboutButtonClicked = () => {
		onOpenInProductHelpArticle(IPH_IDS.AUTO_SCHEDULE, 'HELP_ARTICLE');
	};

	const hasSettingValues = useCallback(() => {
		return (
			autoScheduleConfiguration?.ignoreTeams === true ||
			autoScheduleConfiguration?.ignoreSprints === true ||
			autoScheduleConfiguration?.ignoreReleases === true
		);
	}, [autoScheduleConfiguration]);

	return (
		<div css={styles.dialog}>
			<Stack space="space.100" xcss={cx(styles.section)}>
				<Flex justifyContent="space-between">
					<Heading size="medium" as="h1" id="overwrite-issue-values-title">
						{formatMessage(messages.autoSchedulerDialogHeader)}
					</Heading>

					<IconButton
						appearance="subtle"
						spacing="compact"
						label={formatMessage(messages.closeButtonLabel)}
						icon={CloseIcon}
						onClick={onClose}
					/>
				</Flex>

				<Box xcss={cx(styles.description)}>
					<Text as="p">{formatMessage(messages.headerDesc)}</Text>
				</Box>

				{hasSettingValues() ? (
					<>
						<Box>
							<Text as="p">{formatMessage(messages.headerDescSecond)}</Text>
							<ul>
								{autoScheduleConfiguration?.ignoreSprints ? (
									<li>{formatMessage(messages.sprint)}</li>
								) : null}
								{autoScheduleConfiguration?.ignoreReleases ? (
									<li>{formatMessage(messages.release)}</li>
								) : null}
								{autoScheduleConfiguration?.ignoreTeams ? (
									<li>{formatMessage(messages.team)}</li>
								) : null}
							</ul>
						</Box>
						<Box>
							<Text as="p">{formatMessage(messages.headerDescThird)}</Text>
						</Box>
					</>
				) : (
					<Box>
						<Text as="p">{formatMessage(messages.headerDescNoFields)}</Text>
					</Box>
				)}

				<Flex justifyContent="end" gap="space.100" wrap="wrap">
					<Button
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.preview-results"
						appearance="default"
						onClick={aboutButtonClicked}
					>
						{formatMessage(messages.aboutAutoSchedulerButton)}
					</Button>
					<Button
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.preview-results"
						appearance="primary"
						onClick={onPreviewResults}
					>
						{selectedIssuesCount > 0
							? formatMessage(messages.previewResultsWithCount, {
									count: selectedIssuesCount,
								})
							: formatMessage(messages.previewResults)}
					</Button>
				</Flex>
			</Stack>
		</div>
	);
};

const styles = cssMap({
	dialog: {
		maxWidth: '400px',
	},
	selectedIssue: {
		paddingTop: token('space.100'),
		paddingRight: token('space.100'),
		paddingBottom: token('space.100'),
		paddingLeft: token('space.100'),
		borderRadius: token('border.radius'),
		marginTop: token('space.050'),
		marginBottom: token('space.050'),
	},
	button: {
		textAlign: 'end',
	},
	divide: {
		marginTop: token('space.100'),
		borderBottomStyle: 'solid',
		borderBottomColor: token('color.border'),
		borderBottomWidth: token('border.width'),
	},
	description: {
		marginTop: token('space.200'),
	},
	secondDescription: {
		marginTop: token('space.100'),
	},
	thirdDescription: {
		marginTop: token('space.100'),
	},
	section: {
		paddingTop: token('space.300'),
		paddingRight: token('space.300'),
		paddingBottom: token('space.300'),
		paddingLeft: token('space.300'),
	},
});

export default ConfigurationDialog;
