import log from '@atlassian/jira-common-util-logging/src/log.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { CTP_ONBOARDING_STAGE } from './constants.tsx';
import type { State, Stage } from './types.tsx';
import { update } from './utils.tsx';

const ctpOnboardingActions = {
	initCtpOnboarding:
		(planId: number | null): Action<State> =>
		async ({ getState, setState }) => {
			await getState()._rehydratePromise;

			const { ctpOnboarding } = getState();

			if (planId === null) return;

			if (!ctpOnboarding) {
				setState({
					ctpOnboarding: { stage: CTP_ONBOARDING_STAGE.TIMELINE, planId },
				});
				return;
			}

			if (ctpOnboarding.planId !== planId && ctpOnboarding.stage !== CTP_ONBOARDING_STAGE.CLOSED) {
				setState({
					ctpOnboarding: { stage: CTP_ONBOARDING_STAGE.TIMELINE, planId },
				});
			}
		},

	nextCtpStage:
		(skipTo?: Stage): Action<State> =>
		({ setState, getState, dispatch }) => {
			const { ctpOnboarding } = getState();

			if (!ctpOnboarding) {
				return;
			}

			const { planId, stage } = ctpOnboarding;

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const updateStage = (updateStage: Stage) => {
				dispatch(
					update({
						ctpOnboarding: { stage: updateStage, planId },
					}),
				);
			};

			if (skipTo) {
				Object.values(CTP_ONBOARDING_STAGE).includes(skipTo) && updateStage(skipTo);
				return;
			}

			switch (stage) {
				case CTP_ONBOARDING_STAGE.TIMELINE:
					updateStage(CTP_ONBOARDING_STAGE.CREATE_ISSUE);
					break;
				case CTP_ONBOARDING_STAGE.CREATE_ISSUE:
					updateStage(CTP_ONBOARDING_STAGE.TEAMS);
					break;
				case CTP_ONBOARDING_STAGE.TEAMS:
					updateStage(CTP_ONBOARDING_STAGE.BOARDS);
					break;
				case CTP_ONBOARDING_STAGE.BOARDS:
					updateStage(CTP_ONBOARDING_STAGE.CLOSED);
					break;
				case CTP_ONBOARDING_STAGE.CLOSED:
					break;
				default: {
					log.safeErrorWithoutCustomerData(
						'plans.ctp-onboarding.store.nextStageError',
						'Something went wrong going to the next stage, so closing onboarding',
						{
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							path: window.location.pathname.replace(/\d+/g, '{id}'),
							errorMessage: "Didn't expect to get here",
							stage,
						},
					);
					setState({
						ctpOnboarding: {
							...ctpOnboarding,
							stage: CTP_ONBOARDING_STAGE.CLOSED,
						},
					});
					break;
				}
			}
		},
};

export const actions = {
	...ctpOnboardingActions,
};
