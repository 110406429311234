import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { ISSUE_STATUS_CATEGORIES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MapStateToProps, Status, OwnProps } from './types.tsx';

const sortingArr = [
	ISSUE_STATUS_CATEGORIES.DONE,
	ISSUE_STATUS_CATEGORIES.INPROGRESS,
	ISSUE_STATUS_CATEGORIES.TODO,
];

export const getStatusesPure = (issueStatuses: IssueStatusesById, value: string[]): Status[] => {
	if (fg('aggregate_fields_improvements')) {
		const statusByName = value.reduce((acc: Record<string, Status>, curr: string) => {
			if (issueStatuses[curr]) {
				const status = issueStatuses[curr];
				acc[status.name] = {
					count: (acc[status.name]?.count || 0) + 1,
					id: status.id,
					name: status.name,
					categoryId: status.categoryId,
				};
			}
			return acc;
		}, {});

		return Object.values(statusByName).sort((a: Status, b: Status) => {
			const aIndex = sortingArr.indexOf(a.categoryId);
			const bIndex = sortingArr.indexOf(b.categoryId);

			if (aIndex !== bIndex) {
				return aIndex - bIndex;
			}
			return a.name.localeCompare(b.name);
		});
	}

	const counts = value.reduce((acc: Record<string, number>, curr: string) => {
		acc[curr] = (acc[curr] || 0) + 1;
		return acc;
	}, {});

	const statuses: Status[] = Object.keys(counts)
		.filter((statusId) => Boolean(issueStatuses[statusId]))
		.map((statusId) => ({
			id: statusId,
			name: issueStatuses[statusId].name,
			categoryId: issueStatuses[statusId].categoryId,
			count: counts[statusId],
		}))
		.sort((a: Status, b: Status) => {
			const aIndex = sortingArr.indexOf(a.categoryId);
			const bIndex = sortingArr.indexOf(b.categoryId);

			if (aIndex !== bIndex) {
				return aIndex - bIndex;
			}
			return a.name.localeCompare(b.name);
		});

	return statuses;
};

const getStatuses = createSelector(
	[getIssueStatusById, (_: State, { value }: OwnProps) => value],
	getStatusesPure,
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	statuses: getStatuses,
});

export default mapStateToProps;
