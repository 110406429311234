import * as R from 'ramda';
import type { Plan } from '../../state/domain/plan/types.tsx';
import type { Sequence } from '../../state/domain/sequence/types.tsx';
import { sequenceKeys } from '../update-jira/api.tsx';
import type { CommitBody, RevertBody } from './types.tsx';

export const commitBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	sequence: Sequence,
	itemKey: string,
	generatedItemId?: string,
	notifyWatchers?: boolean | undefined,
) => {
	if (!planId || !scenarioId) {
		throw new Error('Need both plan and scenario to commit or get changes');
	}
	const payload: CommitBody = {
		planId,
		scenarioId,
		sequence: R.fromPairs(
			sequenceKeys.map((key) => [key, { current: sequence[key]?.current || 0 }]),
		),
		itemKey,
		generatedItemId,
		notifyWatchers,
	};

	return payload;
};

export const revertBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	sequence: Sequence,
	itemKey: string,
) => {
	if (!planId || !scenarioId) {
		throw new Error('Need both plan and scenario to revert changes');
	}
	const payload: RevertBody = {
		itemKeys: [itemKey],
		planId,
		scenarioId,
		sequence: R.fromPairs(
			sequenceKeys.map((key) => [key, { current: sequence[key]?.current || 0 }]),
		),
	};
	return payload;
};

export const revertBulkBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	sequence: Sequence,
	itemKeys: string[],
) => {
	if (!planId || !scenarioId) {
		throw new Error('Need both plan and scenario to revert changes');
	}
	const payload: RevertBody = {
		itemKeys,
		planId,
		scenarioId,
		sequence: R.fromPairs(
			sequenceKeys.map((key) => [key, { current: sequence[key]?.current || 0 }]),
		),
	};
	return payload;
};
