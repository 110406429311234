import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { FocusAreaStatusValue } from '@atlassian/jira-shared-types/src/focus-area.tsx';
import { FocusAreaIcon as Icon } from '@atlassian/focus-area-icons';

const FocusAreaIcon = ({
	status,
	ariaLabel,
}: {
	status: FocusAreaStatusValue;
	ariaLabel?: string | undefined;
}) => {
	return (
		<Box xcss={iconContainerStyles} aria-label={ariaLabel}>
			<Icon level={0} status={status} size="small" />
		</Box>
	);
};

const iconContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '24px',
	minWidth: '24px',
	height: '24px',
	borderRadius: 'border.radius',
	marginRight: 'space.025',
});

export { FocusAreaIcon };
