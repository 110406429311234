import React, { forwardRef, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import type { PolarisTooltipProps } from './types.tsx';

const useTooltipContainer = (compact?: boolean, maxWidth?: string) =>
	useMemo(
		() =>
			forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => {
				const { children, ...rest } = props;
				return (
					<TooltipPrimitive {...rest} ref={ref}>
						<TooltipDialog maxWidth={compact ? maxWidth ?? '200px' : maxWidth}>
							{compact ? <Box xcss={compactTooltipInnerContainerStyles}>{children}</Box> : children}
						</TooltipDialog>
					</TooltipPrimitive>
				);
			}),
		[compact, maxWidth],
	);

export const PolarisTooltip = ({ children, compact, component, ...props }: PolarisTooltipProps) => {
	const TooltipContainer = useTooltipContainer(compact, props.maxWidth);

	return (
		<Tooltip component={component || TooltipContainer} {...props}>
			{children}
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipDialog = styled.div<{ maxWidth?: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	position: 'relative !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => (maxWidth ? `${maxWidth}` : 'none'),
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
	borderRadius: '4px',
	backgroundColor: token('elevation.surface.overlay'),
	boxShadow: token('elevation.shadow.overlay'),
	color: token('color.text.subtle'),
	overflowWrap: 'break-word',
});

const compactTooltipInnerContainerStyles = xcss({
	overflow: 'hidden',
	maxHeight: '38px',
	boxSizing: 'border-box',
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
});
