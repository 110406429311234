/** @jsx jsx */
import React, {
	useCallback,
	useState,
	useRef,
	type Ref,
	type FocusEvent,
	type KeyboardEvent,
	type MouseEvent,
} from 'react';
import { css, jsx } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import type { ContentProps, TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Pressable } from '@atlaskit/primitives/compiled';
import { cssMap, cx } from '@atlaskit/css';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import ScrollLock from '@atlassian/jira-portfolio-3-common/src/scroll-lock/index.tsx';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCellHighlight } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/common/index.tsx';
import { useIsRowFocused } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const cssStyles = cssMap({
	addDependencyButton: {
		backgroundColor: 'transparent',
		color: token('color.link'),
		font: token('font.body'),
		fontWeight: token('font.weight.medium'),
		alignItems: 'center',
		width: '100%',
		height: '100%',
		display: 'flex',
		whiteSpace: 'nowrap',
		borderRadius: token('border.radius.100'),
		'&:hover': {
			textDecoration: 'underline',
		},
		'&:focus': {
			outlineOffset: token('space.negative.025'),
		},
		'&:focus-visible': {
			outlineOffset: token('space.negative.025'),
		},
	},
	visuallyHiddenStyles: {
		opacity: 0,
	},
	iconStyles: {
		paddingRight: token('space.075'),
	},
});

const DependenciesCell = (props: Props) => {
	const {
		renderDialogContent,
		isChanged,
		isOptimizedMode,
		messages,
		isDialogLocked,
		externalIssueLinks,
		internalIssueLinks,
		isReadOnly,
	} = props;

	const [isHovered, setIsHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const triggerRef: Ref<HTMLButtonElement> | undefined = useRef(null);
	const { formatMessage } = useIntl();

	let highlight: undefined | boolean | string; // remove the type when the treegrid_keyboard_navigation feature flag is removed
	let isRowFocused: undefined | boolean;
	let row: undefined | number;

	if (fg('plan_timeline_a11y_dependencies_cell')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[highlight] = useCellHighlight();

		if (row !== undefined) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			[isRowFocused] = useIsRowFocused(row);
		}
	}

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onHoverChanged = useCallback((isHovered: boolean) => {
		setIsHovered(isHovered);
	}, []);

	const onClose = useCallback(
		(e: KeyboardEvent | MouseEvent) => {
			if (isDialogLocked) return;
			setIsOpen(false);
			if (fg('plan_timeline_a11y_dependencies_cell')) {
				// refocuses back on issue count button after adding the first dependency
				if (triggerRef?.current) {
					triggerRef.current.focus();
				}

				// if mouse event hide add dependency button trigger
				if (e.detail !== 0) {
					setIsFocused(false);
				}
			}
		},
		[isDialogLocked],
	);

	const getIssueLinksCount = useCallback(
		() => externalIssueLinks.length + internalIssueLinks.length,
		[externalIssueLinks.length, internalIssueLinks.length],
	);

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter' || e.key === ' ') {
				e.preventDefault();
				onToggle();
			}
		},
		[onToggle],
	);

	const onFocus = useCallback((e: FocusEvent<HTMLElement>) => {
		if (e.target?.matches(':focus-visible')) {
			setIsFocused(true);
		}
	}, []);

	const onBlur = useCallback(() => {
		if (!isOpen) {
			setIsFocused(false);
		}
	}, [isOpen]);

	const renderIssueCountNew = useCallback(
		(triggerProps: Partial<TriggerProps>, ref: Ref<HTMLButtonElement | undefined> | undefined) => (
			// eslint-disable-next-line @atlaskit/design-system/no-html-button
			<div
				css={issuesCountButtonStyles}
				onClick={onToggle}
				tabIndex={0}
				role="button"
				onKeyDown={onKeyDown}
				ref={mergeRefs(ref || null, triggerRef)}
				{...triggerProps}
			>
				<div css={issuesLabelStyles}>
					{formatMessage(messages.issues, { count: getIssueLinksCount() })}
				</div>
				{(isHovered || isFocused || isOpen || highlight === true) &&
					(isVisualRefreshEnabled() ? (
						<Box xcss={iconContainerStyle}>
							<ChevronDownIcon label="" color={token('color.icon')} />
						</Box>
					) : (
						<Box xcss={cssStyles.iconStyles}>
							<ChevronDownIcon label="" LEGACY_size="medium" color={token('color.icon')} />
						</Box>
					))}
			</div>
		),
		[
			getIssueLinksCount,
			isHovered,
			isOpen,
			messages.issues,
			onToggle,
			onKeyDown,
			isFocused,
			highlight,
			formatMessage,
		],
	);

	const renderIssueCountOld = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			// eslint-disable-next-line @atlaskit/design-system/no-html-button
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.issuesCount}
				onClick={onToggle}
				tabIndex={0}
				role="button"
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onKeyDown={() => {}}
				{...triggerProps}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issuesLabel}>
					<FormattedMessage {...messages.issues} values={{ count: getIssueLinksCount() }} />
				</div>

				{(isHovered || isOpen) &&
					(isVisualRefreshEnabled() ? (
						<Box xcss={iconContainerStyle}>
							<ChevronDownIcon label="" color={token('color.icon')} />
						</Box>
					) : (
						<ChevronDownIcon label="" LEGACY_size="large" color={token('color.icon')} />
					))}
			</div>
		),
		[getIssueLinksCount, isHovered, isOpen, messages.issues, onToggle],
	);

	const renderIssueCount = fg('plan_timeline_a11y_dependencies_cell')
		? renderIssueCountNew
		: renderIssueCountOld;

	const renderAddDependency = useCallback(
		(triggerProps: Partial<TriggerProps>, ref: Ref<HTMLButtonElement> | undefined) => {
			if (fg('plan_timeline_a11y_dependencies_cell')) {
				const showHiddenItems =
					isHovered || isFocused || highlight === true || isRowFocused || isOpen;
				const visuallyHideItems =
					!isHovered && isRowFocused && highlight !== true && !isFocused && !isOpen;
				return !isReadOnly && showHiddenItems ? (
					<Pressable
						xcss={cx(
							cssStyles.addDependencyButton,
							visuallyHideItems && cssStyles.visuallyHiddenStyles,
						)}
						role="button"
						onClick={onToggle}
						onKeyDown={onKeyDown}
						{...triggerProps}
						ref={ref}
					>
						<div css={addDependencyButtonInner}>{formatMessage(messages.addDependency)}</div>
					</Pressable>
				) : null;
			}
			return !isReadOnly && (isHovered || isOpen) ? (
				<Button appearance="link" onClick={onToggle} {...triggerProps}>
					<FormattedMessage {...messages.addDependency} />
				</Button>
			) : null;
		},
		[
			isHovered,
			isOpen,
			isReadOnly,
			messages.addDependency,
			onToggle,
			highlight,
			isFocused,
			isRowFocused,
			formatMessage,
			onKeyDown,
		],
	);

	const renderTrigger = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => {
			return (
				<HoverObserver onHoverChanged={onHoverChanged}>
					{fg('plan_timeline_a11y_dependencies_cell') ? (
						<div
							data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-old.dependencies"
							css={triggerContainerStyles}
						>
							{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
							<div css={addDependencyButtonWrapperStyles} onFocus={onFocus} onBlur={onBlur}>
								{getIssueLinksCount() > 0
									? renderIssueCount(triggerProps, ref)
									: renderAddDependency(triggerProps, ref)}
							</div>
							{isChanged && !isOptimizedMode ? <ChangeIndicator /> : null}
						</div>
					) : (
						/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
						<div ref={ref} className={styles.container}>
							{getIssueLinksCount() > 0
								? renderIssueCount(triggerProps, undefined)
								: renderAddDependency(triggerProps, undefined)}
							{isChanged && !isOptimizedMode ? <ChangeIndicator /> : null}
						</div>
					)}
				</HoverObserver>
			);
		},
		[
			getIssueLinksCount,
			isChanged,
			isOptimizedMode,
			onHoverChanged,
			renderAddDependency,
			renderIssueCount,
			onFocus,
			onBlur,
		],
	);

	const renderContent = useCallback(
		({ update }: ContentProps) => (
			<Box xcss={dialogStyles}>
				{renderDialogContent && renderDialogContent(update)}
				<ScrollLock />
			</Box>
		),
		[renderDialogContent],
	);

	return (
		<Popup
			messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-old.popup"
			messageType="transactional"
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
		/>
	);
};

const dialogStyles = xcss({
	maxWidth: '800px',
	maxHeight: '400px',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	boxSizing: 'border-box',
});

const iconContainerStyle = xcss({
	paddingTop: '0',
	paddingBottom: '0',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

const issuesCountButtonStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space -- makes outline visible within the cell
	outlineOffset: '-1px',
	outlineColor: token('color.border.focused'),
	cursor: 'pointer',
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	height: '100%',
	borderRadius: token('border.radius.100'),
	'&:focus-visible': {
		outlineOffset: token('space.negative.025'),
		outline: `2px solid ${token('color.border.focused')}`,
	},
});

const addDependencyButtonInner = css({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const triggerContainerStyles = css({
	width: '100%',
	maxWidth: '100%',
	overflow: 'hidden',
	height: '40px',
	display: 'flex',
	alignItems: 'center',
});

const issuesLabelStyles = css({
	flex: '1 1 auto',
	paddingLeft: token('space.100'),
	whiteSpace: 'nowrap',
});

const addDependencyButtonWrapperStyles = css({
	height: '100%',
	width: '100%',
});

export default DependenciesCell;
