import countBy from 'lodash/countBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getDefinedGoals } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals/index.tsx';
import type { GoalsByARI } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types.tsx';
import type { OwnProps, MapStateToProps, GoalValue } from './types.tsx';

const getValues = createSelector(
	[getDefinedGoals, (_: State, { rawValues }: OwnProps) => rawValues],
	(goalsByAri: GoalsByARI, rawValues: string[]): GoalValue[] => {
		const goalCounts = countBy(rawValues);
		const uniqueGoals = Object.keys(goalCounts).sort((a, b) => goalCounts[b] - goalCounts[a]);

		return uniqueGoals
			.map((id) => {
				if (!goalsByAri[id]) return undefined;

				const { name, state } = goalsByAri[id];
				return { name, state, count: goalCounts[id] };
			})
			.filter(Boolean);
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	values: getValues,
});

export default mapStateToProps;
