import React, { useLayoutEffect, useRef, useState } from 'react';

import Tooltip from '@atlaskit/tooltip';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import Button from '@atlaskit/button/new';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import type { CustomTriggerComponentProps } from '@atlassian/jira-portfolio-3-common/src/dropdown-menu/types.tsx';

export const HierarchyTriggerTooltip = ({
	triggerProps: { triggerRef, ...restTriggerProps },
	content,
	ariaLabel,
	testId,
}: CustomTriggerComponentProps) => {
	const tooltipRef = useRef<HTMLSpanElement>(null);
	const mergedRefs = useMergeRefs(triggerRef, tooltipRef);
	const [isTruncated, setIsTruncated] = useState(false);

	useLayoutEffect(() => {
		const labelChildren = tooltipRef.current?.children;
		if (!labelChildren) {
			return;
		}
		const shouldTruncate = Array.from(labelChildren).some(
			({ scrollWidth, clientWidth, textContent }) => {
				return content === textContent && scrollWidth > clientWidth;
			},
		);
		setIsTruncated(shouldTruncate);
	}, [content]);

	return (
		<Tooltip
			content={content}
			ignoreTooltipPointerEvents
			isScreenReaderAnnouncementDisabled
			hideTooltipOnClick
			testId={`${testId}-tooltip`}
			canAppear={() => {
				if (!isTruncated) {
					return false;
				}
				const isFocusVisible = tooltipRef.current?.matches(':focus-visible') ?? false;
				const isHovered = tooltipRef.current?.matches(':hover') ?? false;
				return isFocusVisible || isHovered;
			}}
		>
			<Button
				{...restTriggerProps}
				ref={mergedRefs}
				iconAfter={ChevronDownIcon}
				aria-label={ariaLabel}
			>
				{content}
			</Button>
		</Tooltip>
	);
};

export default HierarchyTriggerTooltip;
