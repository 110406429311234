import type {
	SortBy,
	SortDirection,
	SelectedChanges,
	FilterId,
	QuickSelectOption,
} from './types.tsx';

export const OPEN_UPDATE_JIRA_DIALOG =
	'state.ui.top.title-bar.update-jira.OPEN_UPDATE_JIRA_DIALOG' as const;

export const CLOSE_UPDATE_JIRA_DIALOG =
	'state.ui.top.title-bar.update-jira.CLOSE_UPDATE_JIRA_DIALOG' as const;

export const EXPAND_UPDATE_JIRA_DIALOG_CHANGE =
	'state.ui.top.title-bar.update-jira.EXPAND_UPDATE_JIRA_DIALOG_CHANGE' as const;

export const COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE =
	'state.ui.top.title-bar.update-jira.COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE' as const;

export const CLOSE_COMMIT_WARNING_FLAG =
	'state.ui.top.title-bar.update-jira.CLOSE_COMMIT_WARNING_FLAG' as const;

export const SELECT_CHANGE = 'state.ui.top.title-bar.update-jira.SELECT_CHANGE' as const;

export const DESELECT_CHANGE = 'state.ui.top.title-bar.update-jira.DESELECT_CHANGE' as const;

export const SELECT_ALL_CHANGES = 'state.ui.top.title-bar.update-jira.SELECT_ALL_CHANGES' as const;

export const DESELECT_ALL_CHANGES =
	'state.ui.top.title-bar.update-jira.DESELECT_ALL_CHANGES' as const;

export const REPLACE_SELECTED_CHANGES =
	'state.ui.top.title-bar.update-jira.REPLACE_SELECTED_CHANGES' as const;

export const SORT_CHANGES = 'state.ui.top.title-bar.update-jira.SORT_CHANGES' as const;

export const CHANGE_FILTER = 'state.ui.top.title-bar.update-jira.CHANGE_FILTER' as const;

export const CLEAR_FILTER = 'state.ui.top.title-bar.update-jira.CLEAR_FILTER' as const;

export const UPDATE_NOTIFICATION_PREFERENCE =
	'state.ui.top.title-bar.update-jira.UPDATE_NOTIFICATION_PREFERENCE' as const;

export const CHANGE_QUICK_SELECT = 'state.ui.top.title-bar.update-jira.SET_QUICK_SELECT' as const;

type ChangeFilterActionPayload = {
	id: FilterId;
	value: string[];
	visibleIssueIds: string[];
};

/** CLEAR_FILTER */
export type ClearFilterAction = {
	type: typeof CLEAR_FILTER;
	payload: FilterId;
};

/** CHANGE_FILTER */
export type ChangeFilterAction = {
	type: typeof CHANGE_FILTER;
	payload: ChangeFilterActionPayload;
};

export type OpenDialogActionPayload = {
	isSaving?: boolean;
};

/** OPEN_UPDATE_JIRA_DIALOG */
export type OpenDialogAction = {
	type: typeof OPEN_UPDATE_JIRA_DIALOG;
	payload: OpenDialogActionPayload;
};

/** CLOSE_UPDATE_JIRA_DIALOG */
export type CloseDialogAction = {
	type: typeof CLOSE_UPDATE_JIRA_DIALOG;
};

export type SelectExpandActionPaylod = {
	id: string;
	category: string;
};

/** EXPAND_UPDATE_JIRA_DIALOG_CHANGE */
export type ExpandChangeAction = {
	type: typeof EXPAND_UPDATE_JIRA_DIALOG_CHANGE;
	payload: SelectExpandActionPaylod;
};

/** COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE */
export type CollapseChangeAction = {
	type: typeof COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE;
	payload: SelectExpandActionPaylod;
};

/** CLOSE_COMMIT_WARNING_FLAG */
export type CloseCommitWarningFlagAction = {
	type: typeof CLOSE_COMMIT_WARNING_FLAG;
};

export type SortChangesPayload = {
	sortBy: SortBy;
	sortDirection: SortDirection;
};

/** SELECT_CHANGE */
export type SelectChangeAction = {
	type: typeof SELECT_CHANGE;
	payload: SelectExpandActionPaylod;
};

/** SELECT_ALL_CHANGES */
export type SelectAllChangesAction = {
	type: typeof SELECT_ALL_CHANGES;
	payload: SelectedChanges;
};

/** DESELECT_CHANGE */
export type DeselectChangeAction = {
	type: typeof DESELECT_CHANGE;
	payload: SelectExpandActionPaylod;
};

/** DESELECT_ALL_CHANGES */
export type DeselectAllChangesAction = {
	type: typeof DESELECT_ALL_CHANGES;
};

/** REPLACE_SELECTED_CHANGES */
export type ReplaceSelectedChangesAction = {
	type: typeof REPLACE_SELECTED_CHANGES;
	payload: SelectedChanges;
};

/** SORT_CHANGES */
export type SortChangesAction = {
	type: typeof SORT_CHANGES;
	payload: SortChangesPayload;
};

export type UpdateNotificationPreferenceActionPayload = {
	shouldNotifyWatchers: boolean;
};

/** UPDATE_NOTIFICATION_PREFERENCE */
export type UpdateNotificationPreferenceAction = {
	type: typeof UPDATE_NOTIFICATION_PREFERENCE;
	payload: UpdateNotificationPreferenceActionPayload;
};

export type ChangeQuickSelectAction = {
	type: typeof CHANGE_QUICK_SELECT;
	payload: QuickSelectOption;
};

export const expandChange = (changeId: string, category: string) => ({
	type: EXPAND_UPDATE_JIRA_DIALOG_CHANGE,
	payload: {
		id: changeId,
		category,
	},
});

export const collapseChange = (changeId: string, category: string) => ({
	type: COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE,
	payload: {
		id: changeId,
		category,
	},
});

export const selectChange = (changeId: string, category: string) => ({
	type: SELECT_CHANGE,
	payload: {
		id: changeId,
		category,
	},
});

export const selectAllChanges = (changes: Partial<SelectedChanges>) => ({
	type: SELECT_ALL_CHANGES,
	payload: changes,
});

export const deselectChange = (changeId: string, category: string) => ({
	type: DESELECT_CHANGE,
	payload: {
		id: changeId,
		category,
	},
});

export const deselectAllChanges = () => ({
	type: DESELECT_ALL_CHANGES,
});

export const replaceSelectedChanges = (changes: Partial<SelectedChanges>) => ({
	type: REPLACE_SELECTED_CHANGES,
	payload: changes,
});

// make isSaving parameter required when tidying unblock_ui_during_committing_changes feature gate
export const openUpdateJiraDialog = (isSaving?: boolean) => ({
	type: OPEN_UPDATE_JIRA_DIALOG,
	payload: { isSaving },
});

export const closeUpdateJiraDialog = () => ({
	type: CLOSE_UPDATE_JIRA_DIALOG,
});

export const closeCommitWarningFlag = () => ({
	type: CLOSE_COMMIT_WARNING_FLAG,
});

export const sortChanges = (sortBy: SortBy, sortDirection: SortDirection): SortChangesAction => ({
	type: SORT_CHANGES,
	payload: {
		sortBy,
		sortDirection,
	},
});

export const changeFilter = (payload: ChangeFilterActionPayload): ChangeFilterAction => ({
	type: CHANGE_FILTER,
	payload,
});

export const clearFilter = (payload: FilterId): ClearFilterAction => ({
	type: CLEAR_FILTER,
	payload,
});

export const updateNotificationPreference = (
	payload: UpdateNotificationPreferenceActionPayload,
): UpdateNotificationPreferenceAction => ({
	type: UPDATE_NOTIFICATION_PREFERENCE,
	payload,
});

export const changeQuickSelect = (payload: QuickSelectOption): ChangeQuickSelectAction => ({
	type: CHANGE_QUICK_SELECT,
	payload,
});
