import React, { useMemo } from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import Cell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/column/cell/view.tsx';
import { FocusAreaHoverCard } from '@atlassian/jira-software-focus-areas/src/ui/focus-area/index.tsx';
import type { FocusArea } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-focus-areas/types.tsx';
import type { Props } from './types.tsx';

const calculateFlexShrink = (focusAreas: FocusArea[]): number[] => {
	const focusAreaNameLengths = focusAreas.map((focusArea) =>
		focusArea.name === undefined ? 0 : focusArea.name.length,
	);
	const shrinkCoefficient = 0.2; // A smaller number will make the focusArea components shrink closer to a similar rate.
	return focusAreaNameLengths.map((length) => Math.pow(length, shrinkCoefficient));
};

export const FocusAreasCell = ({ issue, isScrolling = false, focusAreas, isExportMode }: Props) => {
	const issueFocusAreas = useMemo(() => {
		return issue.focusAreas?.map((focusAreaId) => focusAreas[focusAreaId]) ?? [];
	}, [focusAreas, issue.focusAreas]);
	if (isScrolling) {
		return <SelectWireframe />;
	}
	if (!issue.focusAreas || !issue.focusAreas.length || !issueFocusAreas.length) {
		return null;
	}
	const flexShrinkValues = calculateFlexShrink(issueFocusAreas);

	return (
		<Cell attribute="focusAreas" issue={issue} isScrolling={isScrolling}>
			<Inline xcss={cellStyles}>
				{issueFocusAreas.map((focusArea, i) => (
					<FocusAreaHoverCard
						key={`${issue.id}-${focusArea.id}`}
						focusArea={focusArea}
						isExportMode={isExportMode}
						flexShrink={flexShrinkValues[i]}
					/>
				))}
			</Inline>
		</Cell>
	);
};

export default FocusAreasCell;

const cellStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
});
