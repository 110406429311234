// Remove this file when cleaning up FG migrate_plan_filter_to_popup
import React, { Component, type SyntheticEvent } from 'react';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import type { IssuePriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types.tsx';
import { ISSUE_PRIORITIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import {
	fireUIAnalytics,
	type AnalyticsEvent,
	AnalyticsEventToProps,
} from '@atlassian/jira-product-analytics-bridge';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import '../common/styles.module.css';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { PropsOld as Props } from './types.tsx';

const CheckboxWithAnalytics = AnalyticsEventToProps('checkbox', {
	onChange: 'changed',
})(Checkbox);

// eslint-disable-next-line jira/react/no-class-components
class IssuePrioritiesFilter extends Component<Props> {
	constructFilterText = () => {
		const { issuePriorities, value } = this.props;
		return issuePriorities
			.filter(({ id }: { id: string }) => R.contains(id, value))
			.map(({ name }: { name: string }) => name)
			.join(', ');
	};

	renderFilterText = () => {
		const { value } = this.props;
		return value.length > 0 ? (
			<FilterText text={this.constructFilterText()} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl } = this.props;
		const filterText = this.constructFilterText();
		// Priorities, All
		return `${intl.formatMessage(filterMessages[ISSUE_PRIORITIES_FILTER_ID])}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	isIssuePrioritySelected = (issuePriorityId: string) =>
		R.contains(issuePriorityId, this.props.value);

	filterIssueTypesWithSearchQuery = (issuePriority: IssuePriority) =>
		issuePriority.name.toLowerCase().includes(this.props.searchQuery.toLowerCase());

	getIssuePrioritiesOrder = (issuePriorityIds: string[]) => {
		const { issuePriorities } = this.props;
		return issuePriorities
			.filter(({ id }) => issuePriorityIds.includes(id)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map((_: any, index: number) => index);
	};

	onIssuePriorityClick = (issuePriorityId: string, analyticsEvent: AnalyticsEvent): void => {
		const { value, changeIssuePrioritiesFilter } = this.props;
		let issuePriorityIds: string[];

		if (this.isIssuePrioritySelected(issuePriorityId)) {
			issuePriorityIds = R.filter((issuePriority) => issuePriority !== issuePriorityId, value);
			changeIssuePrioritiesFilter(issuePriorityIds);
		} else {
			issuePriorityIds = [...value, issuePriorityId];
			changeIssuePrioritiesFilter(issuePriorityIds);
		}

		// Send an analytics event when the user updates the Issue Priorities filter
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.UPDATED_ISSUE_PRIORITIES_FILTER.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), {
			issuePrioritiesOrder: this.getIssuePrioritiesOrder(issuePriorityIds),
		});
	};

	getIssuePrioritiesOptions = (issuePriorities: IssuePriority[]) =>
		issuePriorities.map(({ name, id, iconUrl }) => (
			<DialogMenuItem key={id}>
				<CheckboxWithAnalytics
					id={`${id}`}
					key={id}
					isChecked={this.isIssuePrioritySelected(id)}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onChange={(_: SyntheticEvent<any>, analyticsEvent: AnalyticsEvent) =>
						this.onIssuePriorityClick(id, analyticsEvent)
					}
					label={
						<TitleWithAvatar
							avatarUrl={iconUrl}
							id={id}
							name={name}
							appearance="square"
							size="xsmall"
						/>
					}
				/>
			</DialogMenuItem>
		));

	getIssuePriorities = () => {
		const { issuePriorities } = this.props;
		const filteredIssuePriorities = issuePriorities.filter(this.filterIssueTypesWithSearchQuery);

		return (
			<>
				{this.getIssuePrioritiesOptions(filteredIssuePriorities)}
				{filteredIssuePriorities.length === 0 && <NoMatchFound />}
			</>
		);
	};

	render() {
		const {
			intl,
			isOpen,
			value,
			searchQuery,
			onOpenChange,
			onQueryChange,
			clearIssuePrioritiesFilter,
		} = this.props;

		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				onClose={onOpenChange}
				isOpen={isOpen}
				content={
					<DialogMenuContainer>
						<ClearFilterButton
							isVisible={!!value.length}
							onClearClick={clearIssuePrioritiesFilter}
						/>
						<SearchField
							placeholder={intl.formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.searchPriorityPlaceholderIssueTermRefresh
									: messages.searchPriorityPlaceholder,
							)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
							ariaLabel={intl.formatMessage(messages.searchPriorityLabel)}
						/>
						{this.getIssuePriorities()}
					</DialogMenuContainer>
				}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter"
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					triggerButtonText={this.renderFilterText()}
					ariaLabel={this.ariaText()}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter.trigger-btn"
				/>
			</InlineDialog>
		);
	}
}

export default injectIntl(IssuePrioritiesFilter);
