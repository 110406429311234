import React from 'react';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ISSUE_INFERRED_DATE_SELECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import RollupIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/rollup.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { DefaultDateFormat } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, OptimizedField } from './types.tsx';
import { getOptimizedFields } from './utils.tsx';

export const FLYOUT_CONTENT_WIDTH = 400;

const { SPRINT, ROLL_UP } = ISSUE_INFERRED_DATE_SELECTION;

const None = () => {
	const intl = useIntl();

	return (
		<span
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.none}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.row-flyout-none"
		>
			{intl.formatMessage({ ...messages.none })}
		</span>
	);
};

const valueOrNone = (value?: string) => (isDefined(value) ? value : <None />);

// @ts-expect-error - TS7006 - Parameter 'inferred' implicitly has an 'any' type.
const currentValueCell = (inferred, key: undefined | string, currentValue?: string) => {
	if (inferred && key && inferred[key] === ROLL_UP) {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.valueWithIcon}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.rollUpIcon}>
					<RollupIcon primaryColor={token('color.icon.subtle')} size="medium" label="" />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.inferredText}>{valueOrNone(currentValue)}</div>
			</div>
		);
	}
	if (inferred && key && inferred[key] === SPRINT) {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.valueWithIcon}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.sprintIcon}>
					<Lozenge maxWidth={15}>S</Lozenge>
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.inferredText}>{valueOrNone(currentValue)}</div>
			</div>
		);
	}

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	return <div className={styles.valueWithIcon}>{valueOrNone(currentValue)}</div>;
};

const BarFlyout = ({
	issue,
	teams,
	sprints,
	versions,
	dateFormat = DefaultDateFormat,
	dateConfiguration,
	customFields,
}: Props) => {
	const intl = useIntl();
	const optimizedFields = getOptimizedFields(
		issue,
		teams,
		sprints,
		versions,
		dateFormat,
		dateConfiguration,
		customFields,
		intl,
	);

	const optimizedFieldsCount = optimizedFields.length;

	const style = {
		width: FLYOUT_CONTENT_WIDTH,
	};
	const getFieldRow = ({
		label,
		currentValue,
		optimizedValue,
		delta,
		inferred,
		key,
	}: OptimizedField) => (
		<tr key={label}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<td className={styles.label}>{label}</td>
			<td>{currentValueCell(inferred, key, currentValue)}</td>
			<td>
				<ArrowRightIcon label="" color={token('color.icon.subtle')} LEGACY_size="small" />
			</td>
			<td>
				{valueOrNone(optimizedValue)}
				{delta && (
					<div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<span className={styles.delta}>({delta})</span>
					</div>
				)}
			</td>
		</tr>
	);

	const rows = optimizedFields.map(getFieldRow);

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.barFlyout}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.bar-flyout"
		>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{intl.formatMessage({ ...messages.header }, { optimizedFieldsCount })}</p>

			<table>
				<tbody>
					<tr>
						<td />
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<td className={styles.label}>{intl.formatMessage({ ...messages.before })}</td>
						<td />
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<td className={styles.label}>{intl.formatMessage({ ...messages.after })}</td>
					</tr>
					{rows}
				</tbody>
			</table>
		</div>
	);
};

export default BarFlyout;
