import React, {
	type ReactElement,
	type ReactNode,
	memo,
	useMemo,
	useEffect,
	useRef,
	useState,
	useCallback,
	Children,
	isValidElement,
	type RefObject,
} from 'react';
import { MoreTooltip } from './more-tooltip/index.tsx';
import { Observer } from './observer/index.tsx';
import type { ObservableValuesListProps } from './types.tsx';

export const MORE_TAG_DEFAULT_WIDTH = 56;

export const ObservableValuesList = memo(
	({
		containerRef,
		listRef,
		isActive,
		children,
		previewChildren,
		hiddenCountExternalRef,
		setHiddenCountExternalRefValue,
		MoreTagWrapper,
		MoreTag,
	}: ObservableValuesListProps) => {
		const [hiddenCount, setHiddenCount] = useState(hiddenCountExternalRef?.current || 0);
		const [observer, setObserver] = useState<IntersectionObserver>();
		const hiddenCountRef = useRef<number>(hiddenCountExternalRef?.current || -1);
		const childrenCountRef = useRef<number>(0);
		const observerRef = useRef<IntersectionObserver>();
		const [moreTagRef, setMoreTagRef] = useState<HTMLDivElement | null>(null); // useState instead of useRef is needed here to trigger re-render when the ref is set
		const moreTagRefOffsetWidth = useRef<number>(MORE_TAG_DEFAULT_WIDTH);

		const containerDimensions = getDimensions(containerRef);
		const listDimensions = getDimensions(listRef);
		const isListOverflown =
			!listRef ||
			listDimensions.width > containerDimensions.width ||
			listDimensions.height > containerDimensions.height;

		const intersectionCallback = useCallback(
			(entries: IntersectionObserverEntry[]) => {
				// the initial render returns entries for all children, then we receive delta, e.g. only 1 entry if resizing slowly
				// in cases where the container is being re-initiated, num of children is the same as entries.length
				const isInitialRender = hiddenCountExternalRef
					? entries.length === Children.count(children)
					: hiddenCountRef.current === -1;
				// On initial render only hidden options are counted
				if (isInitialRender) {
					hiddenCountRef.current = entries.filter(
						({ isIntersecting, boundingClientRect }) =>
							!!boundingClientRect.height && !isIntersecting,
					).length;
				} else {
					hiddenCountRef.current += entries.reduce(
						(result, { isIntersecting }) => result + (isIntersecting ? -1 : 1),
						0,
					);
				}
				setHiddenCount(hiddenCountRef.current);
				setHiddenCountExternalRefValue?.(hiddenCountRef.current);
			},
			[hiddenCountExternalRef, setHiddenCountExternalRefValue, children],
		);
		const validChildren = useMemo(() => getValidChildren(children), [children]);

		const createIntersectionObserver = useCallback(
			(rootNode: HTMLElement) => {
				const moreTagWidthInPx = moreTagRef?.offsetWidth ?? MORE_TAG_DEFAULT_WIDTH;
				const observerParams = {
					root: rootNode,
					rootMargin: `0px -${moreTagWidthInPx}px -4px 0px`,
				};
				const newObserver = new IntersectionObserver(intersectionCallback, observerParams);

				return newObserver;
			},
			[intersectionCallback, moreTagRef],
		);

		// recreate intersection observer when more tag ref's width changes, skips initial render
		useEffect(() => {
			// check for existing more tag ref and changes in it's width
			if (
				moreTagRef === null ||
				moreTagRef.offsetWidth <= 0 ||
				moreTagRef.offsetWidth === moreTagRefOffsetWidth.current
			) {
				return;
			}
			// update current more tag's width
			moreTagRefOffsetWidth.current = moreTagRef.offsetWidth;

			// check for existing observer and container availability
			if (!observerRef.current || !containerRef?.current || hiddenCountRef.current === -1) {
				return;
			}
			// recreate observer
			const rootNode = containerRef.current;
			observerRef.current.disconnect();
			hiddenCountRef.current = -1;
			const newObserver = createIntersectionObserver(rootNode);
			observerRef.current = newObserver;
			setObserver(newObserver);
		}, [moreTagRef, containerRef, createIntersectionObserver]);

		// recreate intersection observer when it exists and the amount of children changes, skips initial render
		useEffect(() => {
			const childrenCount = Children.count(validChildren);
			if (childrenCountRef.current !== childrenCount) {
				childrenCountRef.current = childrenCount;

				if (observerRef.current && containerRef?.current && hiddenCountRef.current !== -1) {
					const rootNode = containerRef.current;

					observerRef.current.disconnect();

					hiddenCountRef.current = -1;

					const newObserver = createIntersectionObserver(rootNode);
					observerRef.current = newObserver;

					setObserver(newObserver);
				}
			}
		}, [validChildren, containerRef, createIntersectionObserver]);

		useEffect(() => {
			const definedObserver = observerRef.current;
			const rootNode = containerRef?.current;

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const hasIOSupport = !!window.IntersectionObserver;

			if (!hasIOSupport || !rootNode || definedObserver) {
				return;
			}
			const newObserver = createIntersectionObserver(rootNode);
			observerRef.current = newObserver;

			setObserver(newObserver);
		}, [containerRef, observerRef, createIntersectionObserver]);

		useEffect(
			() => () => {
				observerRef.current?.disconnect();
				setHiddenCountExternalRefValue?.(0);
			},
			[setHiddenCountExternalRefValue],
		);

		const moreTag = isActive ? (
			<MoreTooltip content={previewChildren || validChildren}>
				<MoreTag count={hiddenCount} ref={setMoreTagRef} />
			</MoreTooltip>
		) : (
			<MoreTag count={hiddenCount} ref={setMoreTagRef} />
		);

		return (
			<>
				{Array.isArray(validChildren)
					? validChildren.map((child) => (
							<Observer key={child.key} observer={observer}>
								{child}
							</Observer>
						))
					: null}
				{hiddenCount > 0 &&
					// as we use additional margin for intersection observer sometimes it returns isIntersecting=true for the last element
					// when it's actually visible so we need to check if the list is overflown to cover this case
					isListOverflown &&
					(MoreTagWrapper ? <MoreTagWrapper>{moreTag}</MoreTagWrapper> : moreTag)}
			</>
		);
	},
);

const getValidChildren = (children: ReactNode) => {
	const arr = Children.toArray(children);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const valid = arr.filter(isValidElement) as ReactElement[];
	if (valid.length === arr.length) {
		return children;
	}
	return valid;
};

const getDimensions = (ref?: RefObject<HTMLDivElement | null>) => ({
	width: ref?.current?.clientWidth ?? 0,
	height: ref?.current?.clientHeight ?? 0,
});
