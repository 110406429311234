import React from 'react';
import { getContainerWidth } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import ReleaseBar from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/release-bar/index.tsx';
import TimelineReleaseMarker from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/release-bar/timeline-marker/index.tsx';
import { useCell } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useColumnWidth } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';

interface Props {
	noMarker?: boolean;
}

export const TimelineReleaseBar = ({ noMarker }: Props) => {
	const [{ column }] = useCell();
	const [zoomLevel] = useZoomLevel();
	const [columnWidth = 0] = useColumnWidth({ column, preview: true });
	const width = zoomLevel ? getContainerWidth(zoomLevel) : columnWidth;

	return (
		<ReleaseBar
			width={width}
			renderMarker={
				noMarker
					? ({ date, mode, versionIdsMapByDate }) => (
							<TimelineReleaseMarker
								key={date}
								endDate={date}
								mode={mode}
								versionIdsMapByDate={versionIdsMapByDate}
							/>
						)
					: undefined
			}
		/>
	);
};
