import type { MessageDescriptor } from '@atlassian/jira-intl';
import type { SchedulingMode } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

interface CapacityPlanningSettings {
	projectType: SchedulingMode | undefined;
	teamAdded: boolean;
	teamHasIssueSource: boolean;
	planHasBoards: boolean;
}

export function getCapacityPlanningMessage(settings: CapacityPlanningSettings): MessageDescriptor {
	const { projectType, teamAdded, teamHasIssueSource, planHasBoards } = settings;

	if (projectType === 'Kanban') {
		if (!teamAdded) {
			return fg('jira-issue-terminology-refresh-m3')
				? messages.kanbanNoTeamIssueTermRefresh
				: messages.kanbanNoTeam;
		}
		if (!teamHasIssueSource) {
			return fg('jira-issue-terminology-refresh-m3')
				? messages.kanbanTeamNotLinkedIssueTermRefresh
				: messages.kanbanTeamNotLinked;
		}
	} else if (projectType === 'Scrum') {
		// If the team is scrum and there is no scrum board, redirect them to the issue sources
		if (!planHasBoards) {
			return fg('jira-issue-terminology-refresh-m3')
				? messages.scrumNoBoardTeamAddedIssueTermRefresh
				: messages.scrumNoBoardTeamAdded;
		}

		if (!teamAdded) {
			return messages.scrumNoBoardNoTeam;
		}

		if (!teamHasIssueSource) {
			return fg('jira-issue-terminology-refresh-m3')
				? messages.scrumBoardNoTeamIssueTermRefresh
				: messages.scrumBoardNoTeam;
		}
	}

	// Default message if no specific scenario is matched
	return fg('jira-issue-terminology-refresh-m3')
		? messages.associateTeamToIssueSourceIssueTermRefresh
		: messages.associateTeamToIssueSource;
}
