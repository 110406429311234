/** @jsx jsx */
import React, { forwardRef, type Ref } from 'react';
import { css, jsx } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Inline, xcss } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export function TriggerButtonOld(props: Props) {
	const { isOpen, testId, triggerButtonText, ariaLabel, onOpenChange } = props;

	const onOpenChangeHandle = () => {
		onOpenChange({ isOpen: !isOpen });
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.filterButtonWrapper}>
			<Button
				iconAfter={<ChevronDownIcon spacing="spacious" label="" />}
				isSelected={isOpen}
				onClick={onOpenChangeHandle}
				testId={testId}
				ariaLabel={ariaLabel}
			>
				{triggerButtonText}
			</Button>
		</div>
	);
}

export const TriggerButton = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
	const { isOpen, testId, triggerButtonText, ariaLabel, onOpenChange, ...restProps } = props;

	const onOpenChangeHandle = () => {
		onOpenChange({ isOpen: !isOpen });
	};

	return (
		<Inline xcss={buttonWrapperStyle}>
			<Button
				{...restProps}
				ref={ref}
				iconAfter={<ChevronDownIcon spacing="spacious" label="" />}
				isSelected={isOpen}
				onClick={onOpenChangeHandle}
				testId={testId}
				ariaLabel={ariaLabel}
				shouldFitContainer
			>
				<span css={buttonTextStyles}>{triggerButtonText}</span>
			</Button>
		</Inline>
	);
});

const buttonWrapperStyle = xcss({
	width: '278px',
});

const buttonTextStyles = css({
	textAlign: 'left',
	display: 'flex',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

export default componentWithFG('migrate_plan_filter_to_popup', TriggerButton, TriggerButtonOld);
