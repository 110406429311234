import React, { useState, type ReactElement } from 'react';
import classnames from 'classnames';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Text, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	endOfUtcDay,
	ONE_DAY,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import {
	convertCapacityByPlanningUnit,
	formatDateRange,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/utils.tsx';
import Label from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/unit/index.tsx';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
import {
	PLANNING_UNITS,
	SCHEDULE_MODE,
	SPRINT_STATES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import SprintFlyout from '../../sprint-flyout/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const Sprint = ({
	getPercentageOffsetFromToday,
	isContiguousWithNextSprint = false,
	isFiltered,
	isInconsistent,
	isUpdated,
	plan,
	plannedCapacity,
	showWarning,
	sprint,
	teamObject,
	timelineRange,
	workingHours,
	zoomLevel,
}: Props) => {
	const isHorizontalScrolling = zoomLevel;
	const intl = useIntl();
	const [state, setState] = useState({
		open: false,
		isBarHovered: false,
	});

	if (isFiltered === true) {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <div className={styles.filtered} style={{ width: `${sprint.widthPercentage}%` }} />;
	}

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const toggleFlyout = () => setState((state) => ({ ...state, open: !state.open }));
	const isKanban = sprint.schedulingMode === SCHEDULE_MODE.kanban;

	const getLabel = (label: {
		content?: ReactElement | null | undefined;
		display: string | React.ReactElement;
	}) => (
		<Label
			key={`sprint_unit_index_${sprint.startDate}`}
			gap={sprint.id === 'GAP'}
			label={label}
			noMarker={false}
			widthPercentage={100}
		/>
	);

	const getDashedSprintName = () => sprint.label.display.replace(/\s/g, '-');

	const getFlyoutLabelAndContent = () => {
		const { planningUnit } = plan;
		const { usedCapacity = 0, schedulingMode } = sprint;
		const availableCapacity = plannedCapacity
			? plannedCapacity.capacity || 0
			: sprint.availableCapacity || 0;

		const isPast = sprint.id === 'PAST';
		const isScrum = schedulingMode === SCHEDULE_MODE.scrum;
		let displayUsedCapacity = usedCapacity;
		let displayAvailableCapacity = availableCapacity;
		if (usedCapacity > 0 && availableCapacity === 0) {
			displayUsedCapacity = 1;
			displayAvailableCapacity = 1;
		} else if (usedCapacity === 0 && availableCapacity === 0) {
			displayUsedCapacity = 0;
			displayAvailableCapacity = 1;
		}

		const convertedUsedCapacity = convertCapacityByPlanningUnit({
			capacity: usedCapacity || 0,
			planningUnit,
			workingHours,
		});
		const convertedAvailableCapacity = convertCapacityByPlanningUnit({
			capacity: availableCapacity || 0,
			planningUnit,
			workingHours,
		});

		const displayTitle: string = (() => {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			let displayTitle: string = sprint.label.display;

			if (isKanban) {
				switch (planningUnit) {
					case PLANNING_UNITS.DAYS:
					case PLANNING_UNITS.HOURS:
						displayTitle = `${convertedUsedCapacity || 0}/${convertedAvailableCapacity || 0}`;

						break;

					default:
					// do nothing
				}
			} else if (isPast) {
				displayTitle = intl.formatMessage(messages.pastSprint);
			} else if (sprint.id === 'FUTURE') {
				displayTitle = intl.formatMessage(messages.futureProjectedSprint);
			} else {
				displayTitle = sprint.label.display;
			}
			return displayTitle;
		})();

		const display = (() => {
			let capacity: string;

			switch (planningUnit) {
				case PLANNING_UNITS.DAYS:
				case PLANNING_UNITS.HOURS:
					capacity = intl.formatMessage(messages.activeUnitSprint, {
						unit: intl.formatMessage(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							messages[planningUnit.toLowerCase() as 'days' | 'hours'],
							{
								used: convertedUsedCapacity,
								available: convertedAvailableCapacity,
							},
						),
					});
					break;
				default:
					capacity = intl.formatMessage(messages.activePointsSprint, {
						used: usedCapacity,
						available: availableCapacity,
					});
			}
			return (
				<Stack space="space.075" alignInline="center" xcss={stackStyles}>
					<Text color="inherit" size="UNSAFE_small">
						{!isKanban && (
							<>
								<Text weight="bold" color="inherit" size="UNSAFE_small">
									{displayTitle}
								</Text>
								<br />
							</>
						)}
						{formatDateRange(
							intl,
							sprint.rawStartDate || sprint.startDate || 0,
							sprint.rawEndDate || sprint.endDate || 0,
						)}
					</Text>
					<Text size="UNSAFE_small" color="inherit">
						{capacity}
					</Text>
				</Stack>
			);
		})();

		const labelClasses = classnames(styles.sprintBox, {
			[styles.overbooked]: usedCapacity > availableCapacity,
			[styles.inconsistent]: isInconsistent && showWarning,
			[styles.pastSprint]: isScrum && isPast,
			[styles.closedSprint]: sprint.state === SPRINT_STATES.CLOSED,
			[styles.activeSprint]:
				!(usedCapacity > availableCapacity) && sprint.state === SPRINT_STATES.ACTIVE,
			[styles.isContiguousWithNextSprint]: !state.isBarHovered && isContiguousWithNextSprint,
		});

		const content = ((): ReactElement | null | undefined => {
			if (sprint.id === 'GAP') {
				return null;
			}
			return (
				<HoverObserver onHoverChanged={(hover) => setState({ ...state, isBarHovered: hover })}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.sprintBoxWrapper}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={labelClasses}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.bar}>
								<div
									data-testid={`sprint-capacity-bar-${getDashedSprintName()}`}
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={usedCapacity > availableCapacity ? styles.overbooked : styles.okay}
									style={{
										height: `${Math.min(
											100,
											(100 * displayUsedCapacity) / displayAvailableCapacity,
										)}%`,
									}}
								/>
							</div>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.sprintName}>{displayTitle}</div>
							{isInconsistent && showWarning ? (
								<WarningIcon spacing="spacious" label="" color={token('color.icon.warning')} />
							) : null}
						</div>
					</div>
				</HoverObserver>
			);
		})();

		return {
			content,
			display,
		};
	};

	const label = getFlyoutLabelAndContent();

	// for scrum sprint, we don't display flyout for past sprints
	const ifShowScrumFlyout =
		sprint.schedulingMode !== SCHEDULE_MODE.kanban && sprint.id !== 'GAP' && sprint.id !== 'PAST';
	// for kanban iteration, we will show the flyout for all iterations
	const ifShowKanbanFlyout = isKanban && sprint.id !== 'GAP';

	const calcWidthPercentage = (start: number, end: number, timeline: TimelineRange) => {
		const unitLength = Math.min(timeline.end, end) - Math.max(timeline.start, start);
		const rangeLength = timeline.end - timeline.start;
		return (100 * unitLength) / rangeLength;
	};

	const style: React.CSSProperties =
		isHorizontalScrolling && getPercentageOffsetFromToday
			? {
					width: `${
						getPercentageOffsetFromToday(
							endOfUtcDay(sprint.rawEndDate || sprint.endDate) -
								(!state.isBarHovered && isContiguousWithNextSprint ? ONE_DAY : 0),
						) - getPercentageOffsetFromToday(startOfUtcDay(sprint.rawStartDate || sprint.startDate))
					}%`,

					left: `${getPercentageOffsetFromToday(
						startOfUtcDay(sprint.rawStartDate || sprint.startDate),
					)}%`,
					position: 'absolute',
					transition: 'width 0.1s ease-in-out 0s',
					animation: '0.2s ease-in-out',
					...(state.isBarHovered && { zIndex: '1' }),
				}
			: {
					width: `${calcWidthPercentage(
						startOfUtcDay(sprint.rawStartDate || sprint.startDate),
						endOfUtcDay(sprint.rawEndDate || sprint.endDate) -
							(!state.isBarHovered && isContiguousWithNextSprint ? ONE_DAY : 0),
						timelineRange,
					)}%`,
					position: 'absolute',

					left: `${sprint.offset}%`,
					transition: 'width 0.1s ease-in-out 0s',
					animation: '0.2s ease-in-out',
					...(state.isBarHovered && { zIndex: '1' }),
				};

	const sprintLabel = getLabel(label);

	if (ifShowScrumFlyout || ifShowKanbanFlyout) {
		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={style}
				data-name={`sprint-header-${getDashedSprintName()}`}
				data-testid={`sprint-header-${sprint.state}-${sprint.schedulingMode || ''}-${
					sprint.startDate
				}`}
			>
				{isUpdated && (
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.changeIndicator}
						style={{
							// TODO Delete this comment after verifying space token -> previous value `'2px'`
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							right: token('space.025'),
						}}
					>
						<ChangeIndicator appearance="triangle" />
					</div>
				)}
				<SprintFlyout
					isOpen={state.open}
					onToggle={toggleFlyout}
					sprint={sprint}
					plannedCapacity={plannedCapacity}
					teamObject={teamObject}
					plan={plan}
					isShowTimerangeInconsistent={isInconsistent && showWarning}
				>
					{sprintLabel}
				</SprintFlyout>
			</div>
		);
	}

	return (
		<div
			data-name={`sprint-header-${getDashedSprintName()}`}
			data-testid={`sprint-header-${sprint.state}-${sprint.schedulingMode || ''}-${
				sprint.startDate
			}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
		>
			{sprintLabel}
		</div>
	);
};

export default Sprint;
const stackStyles = xcss({ textAlign: 'center' });
