import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { xcss, Flex, Box } from '@atlaskit/primitives';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	symbol: ReactNode;
	label: string;
};

const LegendItem = ({ symbol, label }: Props) =>
	fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
		<Flex direction="row" gap="space.100" alignItems="center" xcss={containerStyles} as="li">
			<Box xcss={symbolStyles}>{symbol}</Box>
			{label}
		</Flex>
	) : (
		<Container>
			<Symbol>{symbol}</Symbol>
			{label}
		</Container>
	);

export default LegendItem;

const containerStyles = xcss({
	color: 'color.text.subtle',
	margin: '0',
});

const symbolStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: '24px',
	height: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span({
	display: 'flex',
	alignItems: 'center',
	color: token('color.text.subtle'),
	gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Symbol = styled.span({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: gridSize * 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2.5,
	alignItems: 'center',
	justifyContent: 'center',
});
