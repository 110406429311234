import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tableChangesAnnouncement: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.table-changes-announcement',
		defaultMessage:
			'{users, select, all {All users changed {changes}} one {{userName} changed {changes}} other {{userList} changed {changes}}}',
		description:
			'This is the text to indicate who are the authors who made the changes in the changes table, for visually hidden announcement after table content updated with lastmodified filter. {users} is a selection for all users or single user or userList,{userName} is single user who made the changes, {userList} is list of users, and {changes} is the user contributed changes in jira',
	},
	issueCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.issue-category-count',
		defaultMessage: '{issueCount, plural, one {# issue} other {# issues}}',
		description:
			'This is the text to indicate number of changes are made based on issue category, for visually hidden announcement. where {issueCount} is number of changes in a issue category',
	},
	issueCategoryCountIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.issue-category-count-issue-term-refresh',
		defaultMessage: '{issueCount, plural, one {# work item} other {# work items}}',
		description:
			'This is the text to indicate number of changes are made based on issue category, for visually hidden announcement. where {issueCount} is number of changes in a issue category',
	},
	teamCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.team-category-count',
		defaultMessage: '{teamCount, plural, one {# team} other {# teams}}',
		description:
			'This is the text to indicate number of changes are made based on team category, for visually hidden announcement. where {teamCount} is number of changes in a team category',
	},
	releaseCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.release-category-count',
		defaultMessage: '{releaseCount, plural, one {# release} other {# releases}}',
		description:
			'This is the text to indicate number of changes are made based on release category, for visually hidden announcement. where {releaseCount} is number of changes in a release category',
	},
	crossProjectReleaseCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.cross-project-release-category-count',
		defaultMessage:
			'{crossProjectReleaseCount, plural, one {# cross project release} other {# cross project releases}}',
		description:
			'This is the text to indicate number of changes are made based on Cross Project Release category, for visually hidden announcement. where {crossProjectReleaseCount} is number of changes in a Cross Project Release category',
	},
	resourceCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.resource-category-count',
		defaultMessage: '{resourceCount, plural, one {# resource} other {# resources}}',
		description:
			'This is the text to indicate number of changes are made based on resource category, for visually hidden announcement. where {resourceCount} is number of changes in a resource category',
	},
	sprintCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.sprint-category-count',
		defaultMessage: '{sprintCount, plural, one {# sprint} other {# sprints}}',
		description:
			'This is the text to indicate number of changes are made based on sprint category, for visually hidden announcement. where {sprintCount} is number of changes in a sprint category',
	},
	plannedCapacityCategoryCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.planned-capacity-category-count',
		defaultMessage:
			'{plannedCapacityCount, plural, one {# planned capacity} other {# planned capacities}}',
		description:
			'This is the text to indicate number of changes are made based on planned Capacity category, for visually hidden announcement. where {plannedCapacityCount} is number of changes in a planned Capacity category',
	},
});
