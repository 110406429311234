import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	committingChangesLabelNoEllipsis: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.modal-footer.committing-changes-label-no-ellipsis',
		defaultMessage: 'Saving in Jira',
		description: 'Label for saving progress card',
	},
	revertingChangesLabelNoEllipsis: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.modal-footer.reverting-changes-label-no-ellipsis',
		defaultMessage: 'Discarding changes',
		description: 'Label for discarding progress card',
	},
});
