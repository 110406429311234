import React from 'react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	MODAL,
	fireScreenAnalytics,
	ContextualAnalyticsData,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const getReparentTMPWarningFlag = ({ context }: { context: Record<string, string> }) => (
	<Flag
		key="reparent-tmp-warning"
		id="reparent-tmp-warning"
		title={
			<ContextualAnalyticsData sourceType={MODAL} sourceName="getReparentTMPWarningFlag">
				<MountEvent onMount={fireScreenAnalytics} />
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.titleIssueTermRefresh
						: messages.title)}
				/>
			</ContextualAnalyticsData>
		}
		description={<FormattedMessage {...messages.description} values={context} />}
		icon={<WarningIcon spacing="spacious" label="" color={token('color.icon.warning')} />}
	/>
);
