import React, { Component } from 'react';
import * as R from 'ramda';
import AddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { ColourLabel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { chain } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import ColourPicker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colour-picker/view.tsx';
import {
	DEFAULT_COLOR,
	colourPickerPaletteLabels,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import viewSettingsMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

const labelsToOptions = (labels: string[]): OptionType[] =>
	labels.map((label) => ({
		value: label,
		label,
	}));

const optionsToLabels = (options: OptionType[]): string[] => options.map((x) => x.value);

// eslint-disable-next-line jira/react/no-class-components
export class ColourByLabel extends Component<Props, State> {
	state = {
		// "keySuffix" was added to force re-render when all labels are removed from any Select to remove its whole row.
		// Reason: to avoid regression when clearing some Select the last Select with the same colour is removed.
		// Also this implementation lets keep focus on Select while editing it from keyboard -
		// https://stash.atlassian.com/plugins/servlet/jira-integration/issues/JPOS-4624
		keySuffix: 0,
	};

	// label select must not show labels already picked for any colour
	availableLabels = (): string[] => {
		const {
			issueLabels,
			colourByConfiguration: { colourLabels },
		} = this.props;

		const usedLabels = new Set(chain((x) => x.labels, colourLabels));
		return issueLabels.filter((label) => !usedLabels.has(label));
	};

	onLabelsChange = (index: number, options: OptionType[]) => {
		const { updateLabelColour, removeLabelColour } = this.props;
		if (R.isNil(options) || R.isEmpty(options)) {
			removeLabelColour({ index });
			this.setState((prevState) => ({ keySuffix: prevState.keySuffix + 1 }));
		} else {
			updateLabelColour({ index, labels: optionsToLabels(options) });
		}
	};

	onColourChange = (index: number) => (colour?: string | null) => {
		const { updateLabelColour, removeLabelColour } = this.props;
		if (colour) {
			updateLabelColour({ index, colour });
		} else {
			removeLabelColour({ index });
		}
	};

	renderRow = (colourLabel: ColourLabel, index: number) => {
		const { colour, labels } = colourLabel;
		const issueLabels = this.availableLabels();
		const { intl, onMenuToggle } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.row} key={`${index}-${colour || ''}-${this.state.keySuffix}`}>
				<ColourPicker colour={colour} onColourChange={this.onColourChange(index)} position="left" />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.labelSelect}>
					<AkSelect
						aria-label={intl.formatMessage(
							colour ? messages.selectVisibleLabel : messages.selectColour,
						)}
						classNamePrefix={`portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_colour-by-label_select-label-${index}`}
						defaultValue={labelsToOptions(labels)}
						isDisabled={!colour}
						isMulti
						menuPlacement="auto"
						menuPortalTarget={getBody()}
						onChange={(options: ValueType) =>
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							this.onLabelsChange(index, options as OptionType[])
						}
						onMenuClose={() => {
							onMenuToggle(false);
						}}
						onMenuOpen={() => {
							onMenuToggle(true);
						}}
						options={labelsToOptions(issueLabels)}
						placeholder={intl.formatMessage(colour ? messages.chooseLabel : messages.chooseColour)}
						styles={{
							// eslint-disable-next-line @typescript-eslint/no-shadow
							menuPortal: (styles) => ({
								...styles,
								zIndex: 1000,
							}),
						}}
						inputId="portfolio-3.simple-plans_main_tabs_roadmap.view-settings.colourByLabel_select"
					/>
				</div>
			</div>
		);
	};

	renderAddLabel = () => {
		const { addLabelColour } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={`${styles.addLabelButton} ${styles.row}`}>
				<Button
					appearance="link"
					iconBefore={<AddIcon spacing="spacious" label="" />}
					onClick={(e) => {
						e.preventDefault();
						addLabelColour();
					}}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-label.add-color-link"
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
					<span className={styles.addLabel}>
						<FormattedMessage {...messages.addLabel} />
					</span>
				</Button>
			</div>
		);
	};

	render() {
		const {
			colourByConfiguration: { colourLabels },
			intl,
		} = this.props;
		const isAddingNewLabelColour = colourLabels.some(({ colour }) => !colour);
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.list}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.row}>
					<Button
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.allOtherIssuesButton}
						isDisabled
						iconAfter={
							<Tooltip
								content={intl.formatMessage(colourPickerPaletteLabels[DEFAULT_COLOR])}
								position="top"
							>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
								<div className={styles.swatch} style={{ backgroundColor: DEFAULT_COLOR }} />
							</Tooltip>
						}
						ariaLabel={intl.formatMessage(viewSettingsMessages.allOtherIssuesButtonLabel)}
					/>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.allOtherIssues}>
						<FormattedMessage {...commonMessages.allOtherIssues} />
					</div>
				</div>
				{colourLabels.map(this.renderRow)}
				{isAddingNewLabelColour ? null : this.renderAddLabel()}
			</div>
		);
	}
}

export default injectIntl(ColourByLabel);
