import React from 'react';
import { ModalHeader, ModalTitle, ModalBody, ModalTransition } from '@atlaskit/modal-dialog';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import { CustomDateRangeModalContent } from '@atlassian/jira-portfolio-3-custom-date-range/src/ui/modal-content/index.tsx';
import messages from './messages.tsx';

interface Props {
	dateRange: CustomDateRange;
	isOpen: boolean;
	showTodayIndicator?: boolean;
	onConfirm: (dateRange: CustomDateRange) => void;
	onCancel: () => void;
}

export const DateRangeModal = ({
	dateRange,
	isOpen,
	showTodayIndicator = false,
	onConfirm,
	onCancel,
}: Props) => {
	const intl = useIntl();

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog
						messageId="portfolio-3-custom-date-range.ui.date-range-modal.modal-dialog"
						messageType="transactional"
						width="716px"
						autoFocus
						onClose={onCancel}
						{...(fg('smart_links_for_plans')
							? { label: intl.formatMessage(messages.modalHeader) }
							: {})}
					>
						<ModalHeader>
							<ModalTitle>{intl.formatMessage(messages.modalHeader)}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<CustomDateRangeModalContent
								dateRange={dateRange}
								showTodayIndicator={fg('smart_links_for_plans') ? showTodayIndicator : false}
								onConfirm={onConfirm}
								onCancel={onCancel}
							/>
						</ModalBody>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};
