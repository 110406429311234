import type { AssociatedIssue } from '../../state/domain/associated-issues/types.tsx';
import type { State } from '../../state/types.tsx';

export const getAssociatedIssues = (state: State): Record<string, AssociatedIssue> =>
	state.domain.associatedIssues.associatedIssues ?? {};

export const getLastAssociatedIssueIds = (state: State): string[] =>
	state.domain.associatedIssues.lastAssociatedIssueIds ?? [];

export const getMissingAssociatedIssuesFetchInProgress = (state: State): boolean =>
	state.domain.associatedIssues.missingAssociatedIssuesFetchInProgress;
