import countBy from 'lodash/countBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	getAllTeamsById,
	type Team,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import type { OwnProps, MapStateToProps, TeamValue } from './types.tsx';

const getValues = createSelector(
	[getAllTeamsById, (_: State, { rawValues }: OwnProps) => rawValues],
	(teamById: Record<string, Team>, rawValues: string[]): TeamValue[] => {
		const teamCounts = countBy(rawValues);

		const uniqueTeams = Object.keys(teamCounts).sort((a, b) => teamCounts[b] - teamCounts[a]);

		return uniqueTeams
			.map((id) => {
				if (!teamById[id]) return undefined;

				const { title, avatarUrl } = teamById[id];
				return { title, avatarUrl, count: teamCounts[id] };
			})
			.filter(Boolean);
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	values: getValues,
});

export default mapStateToProps;
