import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';

export const filterOnlyExistedAssociatedIssues = (
	issue: ScopeIssue,
	associatedIssues?: Record<string, AssociatedIssue>,
) => {
	if (!associatedIssues || !issue.associatedIssueIds) return [];

	return issue.associatedIssueIds
		.map((issueId) => associatedIssues[issueId])
		.filter((currentIssue) => !!currentIssue);
};

export const filterOnlyExistedOriginalAssociatedIssues = (
	issue: ScopeIssue,
	associatedIssues?: Record<string, AssociatedIssue>,
) => {
	if (!issue?.originals?.associatedIssueIds) return undefined;
	if (!associatedIssues) return [];
	const originalsAssociatedIssueIds: string[] = issue.originals.associatedIssueIds;

	return originalsAssociatedIssueIds
		.map((issueId) => associatedIssues[issueId])
		.filter((currentIssue) => !!currentIssue)
		.map((el) => el.id);
};
