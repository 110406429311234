import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.filters.user-filter.search-placeholder',
		defaultMessage: 'Choose users...',
		description: 'This is a placeholder for search',
	},
	searchLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.filters.user-filter.search-label',
		defaultMessage: 'Search for a user',
		description:
			'Label for the search field in last modified by filter of the review changes modal',
	},
});
