import React, { Component } from 'react';
import ShipIcon from '@atlaskit/icon/core/migration/release--ship';
import Tooltip from '@atlaskit/tooltip';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import { shortDateFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { preload as preloadFlyouts } from '../flyout/async.tsx';
import messages from './messages.tsx';
import { OffTrackIcon } from './off-track-icon/index.tsx';
import { OnTrackIcon } from './on-track-icon/index.tsx';
import { ReleasedIcon } from './released-icon/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import { ICON_TYPES, type PropsWithIntl as Props } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ICON_TYPES } from './types';

const typeToClassName = {
	[ICON_TYPES.UNRELEASED]: styles.unreleasedSpa,
	[ICON_TYPES.RELEASED]: styles.releasedSpa,
	[ICON_TYPES.ONTRACK]: styles.onTrackSpa,
	[ICON_TYPES.OFFTRACK]: styles.offTrackSpa,
} as const;

// eslint-disable-next-line jira/react/no-class-components
class ReleaseMarkerIcon extends Component<Props> {
	getIcon = () => {
		const { totalCount, type } = this.props;
		switch (type) {
			case ICON_TYPES.UNRELEASED:
			case ICON_TYPES.ONTRACK: {
				return <OnTrackIcon />;
			}
			case ICON_TYPES.RELEASED: {
				return <ReleasedIcon totalCount={totalCount} />;
			}
			case ICON_TYPES.OFFTRACK: {
				return <OffTrackIcon totalCount={totalCount} />;
			}
			default:
				return <OnTrackIcon />;
		}
	};

	onKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Escape') {
			e.stopPropagation();
		}
	};

	render() {
		const {
			innerRef,
			intl,
			isHighlighted,
			onClick,
			release,
			showTooltip = false,
			totalCount,
			type,
			triggerProps,
			releaseButtonRef,
		} = this.props;

		const styleName = typeToClassName[type];
		const releaseName = totalCount === 1 && isDefined(release) ? release.name : null;

		const date =
			this.props.endDate && fg('plan-timeline-a11y-rholloway')
				? formatTimestampWithIntl(intl, Number(this.props.endDate), shortDateFormat)
				: null;

		const icon = (
			// eslint-disable-next-line @atlaskit/design-system/no-html-button
			<button // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.button}
				aria-labelledby={type?.toLowerCase()}
				{...triggerProps}
				{...(fg('plan-timeline-a11y-rholloway')
					? {
							onClick,
							onFocus: preloadFlyouts,
							onMouseEnter: preloadFlyouts,
							ref: mergeRefs(releaseButtonRef ?? null, triggerProps?.ref ?? null),
							onKeyUp: this.onKeyUp,
							'data-name': release?.id && `release-marker-icon-${release.id}`,
							'data-testid': release?.id && `release-marker-icon-${release.id}`,
							'aria-label':
								totalCount > 1
									? intl.formatMessage(messages.releasesLabel, {
											numberOfReleases: totalCount,
											date,
										})
									: intl.formatMessage(messages.releaseLabel, { name: releaseName, date }),
						}
					: { onClick: () => onClick() })}
			>
				{this.getIcon()}
			</button>
		);

		return (
			<div
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.marker.icon"
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				ref={innerRef as { current: HTMLDivElement | null } | undefined}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.marker}
			>
				{totalCount > 1 && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<span className={fg('plan-timeline-a11y-rholloway') ? styles.number : styles.numberOld}>
						{Math.min(totalCount, 99)}
					</span>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.icon}>
					{showTooltip ? (
						<Tooltip
							content={
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<div className={styles.releaseTooltip}>
									<ShipIcon
										label={intl.formatMessage(commonMessages.release)}
										LEGACY_size="small"
									/>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles.releaseTooltipName}>
										{totalCount > 1
											? intl.formatMessage(messages.releases, {
													numberOfReleases: totalCount,
												})
											: releaseName}
									</span>
									<span
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className={styles.releaseTooltipDate}
									>{`(${fg('plan-timeline-a11y-rholloway') ? date : formatTimestampWithIntl(intl, Number(this.props.endDate), shortDateFormat)})`}</span>
								</div>
							}
							truncate
						>
							{icon}
						</Tooltip>
					) : (
						icon
					)}
				</span>
				{isHighlighted === true && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={`${styles['timeline-highlight']} ${styleName}`} />
				)}
			</div>
		);
	}
}

export default injectIntl(ReleaseMarkerIcon);
