import React, { useCallback, useMemo } from 'react';
import * as R from 'ramda';
import Heading from '@atlaskit/heading';
import { Box, Flex } from '@atlaskit/primitives';
import type { ActionMeta } from '@atlaskit/select/types';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type {
	OptionType,
	FormatOptionLabelMeta,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { doSprintSearchWithEmptySearchCache } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/sprint/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import { getFutureSprintGroups } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/utils.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import Cell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/column/cell/view.tsx';
import {
	getOptimizedValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { SPRINT_STATES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { AsyncCellSelectWithRollup } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/index.tsx';
import { MIN_SELECT_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
// delete this reference in JPO-19568 and copy the parent message to the current component
import messages from '../messages.tsx';
import { getSprintName } from '../utils.tsx';
import { DisplayLabel, MenuLabel } from './option-label/index.tsx';
import {
	type Props,
	issueAttributeName,
	type GroupedSelectOptions,
	type SprintSelectValue,
	type SprintSelectOption,
} from './types.tsx';

const sprintOfIssueIsCompleted = (issue: ScopeIssue): boolean =>
	isDefined(issue.sprint) &&
	Array.isArray(issue.completedSprints) &&
	R.contains(issue.sprint, issue.completedSprints);

const getOptionLabel = ({
	issue,
	issueStatuses,
	sprints,
	showOptimizations,
}: {
	issue: ScopeIssue;
	issueStatuses: IssueStatusesById;
	sprints: Sprint[];
	showOptimizations: boolean | undefined;
}) => {
	const issueIsDone = Boolean(
		issueStatuses &&
			issue.status &&
			issueStatuses[issue.status] &&
			issueStatuses[issue.status].categoryId === 3,
	);

	const issueIsCompleted = issueIsDone && sprintOfIssueIsCompleted(issue);

	const completedSprintIds = issue.completedSprints || [];

	const completedSprints = R.pipe<Sprint[], Sprint[], Sprint[], Sprint[]>(
		R.filter((sprint: Sprint) => R.includes(sprint.id, completedSprintIds)),
		R.sortBy(R.prop('completeDate')),
		R.reverse,
	)(sprints);

	const sprintIsOptimized =
		!!showOptimizations &&
		hasValueChanged(issue.sprint, getOptimizedValue(issue, issueAttributeName));

	return (opt: OptionType, { context }: FormatOptionLabelMeta) => {
		const sprintNotSet = !isDefined(issue.sprint);

		if (context === 'menu') {
			const sprintIsCompleted = opt.isDisabled;
			return (
				<MenuLabel
					sprintIsExternal={opt.isExternal}
					sprintIsCompleted={sprintIsCompleted}
					label={opt.label}
					boardName={opt.boardName}
				/>
			);
		}

		return (
			<DisplayLabel
				issueIsCompleted={issueIsCompleted}
				issueCompletedSprints={completedSprints}
				sprintIsOptimized={sprintIsOptimized}
				sprintIsExternal={opt.isExternal}
				sprintNotSet={sprintNotSet}
			>
				{opt.label}
			</DisplayLabel>
		);
	};
};

const filterInternalOptions = (query: string, options: GroupedSelectOptions[]) => {
	const filteredOptions: GroupedSelectOptions[] = [];
	options.forEach((group) => {
		const filteredGroupOptions = group.options.filter((option) =>
			optionsFilter(option.label, query),
		);
		if (filteredGroupOptions.length > 0) {
			filteredOptions.push({
				label: group.label,
				options: filteredGroupOptions,
			});
		}
	});
	return filteredOptions;
};

const optionsFilter = (label: string, query: string) =>
	label.toLowerCase().includes(query.toLowerCase());

export const SprintSelect = (props: Props) => {
	const {
		handleSelection,
		isScrolling,
		isReadOnly,
		issue,
		issue: { sprint: sprintOfIssue, team: teamOfIssue },
		showOptimizations,
		sprints,
		message,
		issueStatuses,
		sprintsByTeam,
		teams,
		sprintsByIssueSources,
		additionalTeamsById,
		teamsById,
		allSprints,
		externalSprintsById,
		externalSprintLabel,
		sprintRollUpForIssue,
		internalSprintIds,
	} = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const completedSprints = useMemo(() => issue.completedSprints || [], [issue.completedSprints]);

	const { formatMessage } = useIntl();

	const handleSelectionWithAnalytics = (
		newValue: SprintSelectValue | OptionType | null | undefined,
		actionMeta: ActionMeta<OptionType>,
	) => {
		fireUIAnalytics(createAnalyticsEvent({ action: 'selected', actionSubject: 'sprint' }), {
			isExternal: newValue?.isExternal ?? false,
		});
		handleSelection(newValue, actionMeta);
	};

	const mapIdToTitle = (_: ScopeIssue, value: string): string =>
		(externalSprintsById[value] && externalSprintsById[value].title) ||
		getSprintName(value, sprints);

	let value: string | null | undefined = showOptimizations
		? getOptimizedValue(issue, issueAttributeName)
		: sprintOfIssue;

	let isClearable = true;

	if (!isDefined(value) && completedSprints.length > 0) {
		value = completedSprints[0];
		isClearable = false;
	}
	if (sprintOfIssueIsCompleted(issue)) {
		isClearable = false;
	}

	const currentSprint = sprints.find((sprint) => sprint.id === value);

	let sprintValue: SprintSelectValue | undefined;

	if (currentSprint) {
		sprintValue = {
			label: currentSprint.title,
			value: currentSprint.id,
		};
	}
	// this is the case where the issue's sprint is external
	if (!isDefined(sprintValue) && isDefined(value)) {
		const externalSprintOption = externalSprintsById[value];

		if (externalSprintOption) {
			sprintValue = {
				value: externalSprintOption.id,
				label: externalSprintOption.title,
				isExternal: true,
			};
		}
	}

	let selectValue: SprintSelectValue | null = null; // by default the select box value is null, so nothing is selected
	if (isDefined(sprintValue)) {
		// if a sprint value is available, then we set it as the select box value
		selectValue = sprintValue;
	} else if (!isDefined(sprintValue) && completedSprints.length > 0) {
		// if sprint value is not available but if we have completed sprints, then we have to show completed sprints placeholder text so let's set a placeholder value
		selectValue = {
			value: null,
			label: null,
		};
	}

	const groupedInternalOptions: GroupedSelectOptions[] = useMemo(() => {
		const otherSprintsLabel = formatMessage(messages.otherSprints);
		const futureSprintsGroupByTeam = getFutureSprintGroups(
			teamOfIssue,
			sprintOfIssue,
			sprintsByIssueSources,
			sprintsByTeam,
			additionalTeamsById,
			teams,
			teamsById,
			otherSprintsLabel,
		);

		const completedSprintsForIssue = allSprints.filter(
			(sprint) => sprint.state === SPRINT_STATES.CLOSED && R.contains(sprint.id, completedSprints),
		);
		const completedSprintOptions = completedSprintsForIssue.map((sprint) => ({
			value: sprint.id,
			label: sprint.title,
			isDisabled: true,
			isExternal: isDefined(externalSprintsById[sprint.id]),
		}));

		const internalOptions: GroupedSelectOptions[] = [
			{
				label: formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.completedSprintsHeaderIssueTermRefresh
						: messages.completedSprintsHeader,
				),
				options: completedSprintOptions,
			},
		];
		futureSprintsGroupByTeam.forEach((futureSprints, label) => {
			internalOptions.push({
				label,
				options: futureSprints.map((s) => ({
					value: s.id,
					label: s.title,
				})),
			});
		});
		return internalOptions;
	}, [
		additionalTeamsById,
		allSprints,
		externalSprintsById,
		formatMessage,
		completedSprints,
		sprintOfIssue,
		sprintsByIssueSources,
		sprintsByTeam,
		teamOfIssue,
		teams,
		teamsById,
	]);

	const getOptions = useCallback(
		async (query: string) => {
			// internal sprints are already handled as they are present in the state, add the external ones here
			const externalOptions: SprintSelectOption[] = [];
			// first add the external sprint if the issue is set to one
			if (isDefined(value)) {
				const externalSprintOption = externalSprintsById[value];

				if (externalSprintOption && optionsFilter(externalSprintOption.title, query)) {
					externalOptions.push({
						value: externalSprintOption.id,
						label: externalSprintOption.title,
						isExternal: true,
					});
				}
			}

			const searchData = await doSprintSearchWithEmptySearchCache(query.trim().toLowerCase());
			// we only want external options that are not already in the dropdown
			searchData.forEach((sprint) => {
				const id = sprint.id.toString();
				if (!internalSprintIds.includes(id) && id !== value) {
					externalOptions.push({
						value: id,
						label: sprint.title,
						isExternal: true,
						sprint,
						boardName: sprint.boardName,
					});
				}
			});

			const filteredInternalOptions = filterInternalOptions(query, groupedInternalOptions);
			return filteredInternalOptions.concat({
				label: externalSprintLabel,
				options: externalOptions,
			});
		},
		[value, internalSprintIds, externalSprintsById, groupedInternalOptions, externalSprintLabel],
	);

	const getOptionsWithCallback = useCallback(
		(query: string, callback: (arg1: GroupedSelectOptions[]) => void) => {
			getOptions(query).then(callback);
		},
		[getOptions],
	);

	const formatOptionLabel = useMemo(
		() =>
			getOptionLabel({
				issue,
				issueStatuses,
				sprints: allSprints,
				showOptimizations,
			}),
		[allSprints, issue, issueStatuses, showOptimizations],
	);

	const rolledUpValues = useMemo(
		() =>
			R.sort(
				(a, b) => a.localeCompare(b),
				(sprintRollUpForIssue || []).map((sprintId) => {
					const externalSprint = externalSprintsById[sprintId];
					if (externalSprint) {
						return externalSprint.title;
					}
					return (
						getSprintName(sprintId, sprints) || formatMessage(messages.externalCompletedSprint)
					);
				}),
			),
		[externalSprintsById, formatMessage, sprintRollUpForIssue, sprints],
	);

	const withRollup = () => (
		<AsyncCellSelectWithRollup
			aria-label={formatMessage(
				{
					...(fg('jira-issue-terminology-refresh-m3')
						? messages.sprintSelectorLabelIssueTermRefresh
						: messages.sprintSelectorLabel),
				},
				{ issue: issue.summary },
			)}
			attribute="sprint"
			formatOptionLabel={formatOptionLabel}
			isClearable={isClearable}
			isDisabled={isReadOnly}
			issueId={issue.id}
			minSelectWidth={MIN_SELECT_WIDTH}
			onChange={handleSelectionWithAnalytics}
			placeholder={formatMessage(messages.placeholder)}
			rolledUpValues={rolledUpValues}
			value={selectValue}
			loadOptions={getOptionsWithCallback}
		/>
	);

	return (
		<Cell
			attribute={issueAttributeName}
			issue={issue}
			valueMapper={mapIdToTitle}
			isScrolling={isScrolling}
			showOptimizations={showOptimizations}
		>
			{isDefined(message) ? (
				<Tooltip
					content={
						<Box padding="space.100">
							<Flex alignItems="center">
								<Heading size="small" color="color.text.inverse">
									{message.header}
								</Heading>
								{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
								<p>{message.content}</p>
							</Flex>
						</Box>
					}
				>
					{withRollup()}
				</Tooltip>
			) : (
				withRollup()
			)}
		</Cell>
	);
};
