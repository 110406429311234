import * as R from 'ramda';
import { FieldAction } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/bulk-actions/dialog/set-label/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	RESET,
	type ResetAction,
	UPDATE,
	type UpdateAction,
	BULK_UPDATE,
	type BulkUpdateAction,
	BULK_LABEL_UPDATE,
	ADD,
	type AddAction,
	RANK,
	BULK_RANK,
	type RankAction,
	type BulkRankAction,
	REMOVE_WITHOUT_ISSUE_KEY,
	type RemoveWithoutIssueKeyAction,
	type UpdateRollupsAction,
	UPDATE_ROLLUPS,
} from './actions.tsx';
import type { Issue } from './types.tsx';

const initialState: Issue[] = [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeIssueValues = (key: any, stateValue: any, payloadValue: any) =>
	key === 'customFields' ? R.merge(stateValue, payloadValue) : payloadValue;

type Action =
	| ResetAction
	| UpdateAction
	| UpdateRollupsAction
	| BulkUpdateAction
	| AddAction
	| RankAction
	| BulkRankAction
	| RemoveWithoutIssueKeyAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Issue[] = initialState, action: Action): Issue[] => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		case UPDATE: {
			const { payload } = action;
			const index = state.findIndex(({ id }) => id === payload.id);

			return R.adjust(index, (issue) => R.mergeWithKey(mergeIssueValues, issue, payload), state);
		}
		case UPDATE_ROLLUPS: {
			const { payload } = action;
			return state.map((issue) => {
				if (payload.has(issue.id)) {
					return {
						...issue,
						rollups: { ...issue.rollups, ...payload.get(issue.id) },
					};
				}
				return issue;
			});
		}
		case BULK_LABEL_UPDATE: {
			const { payload } = action;
			const applyPatch = (issue: Issue) => {
				const issueUpdate = payload[issue.id];
				if (!isDefined(issueUpdate)) {
					return issue;
				}

				const patch = { labels: issueUpdate.labels?.value };

				switch (issueUpdate.labels?.fieldAction) {
					case FieldAction.ADD:
						patch.labels = R.union(patch.labels, issue.labels || []);
						break;
					case FieldAction.REMOVE:
						patch.labels = issue.labels?.filter((label) => !patch.labels.includes(label));
						break;
					default:
						break;
				}
				return R.mergeWithKey(mergeIssueValues, issue, patch);
			};

			return state.map(applyPatch);
		}
		case BULK_UPDATE: {
			const { payload } = action;
			const applyPatch = (issue: Issue) => {
				const patch = payload[issue.id];

				if (!isDefined(patch)) {
					return issue;
				}

				return R.mergeWithKey(mergeIssueValues, issue, patch);
			};

			return state.map(applyPatch);
		}
		case ADD: {
			const parentId = action.payload.parent;
			const position = parentId ? R.findIndex(R.propEq('id', parentId), state) : -1;
			if (position >= 0) {
				return R.insert(position + 1, action.payload, state);
			}
			return [...state, action.payload];
		}
		case RANK: {
			const {
				payload: {
					itemKeys: [id],
					anchor,
				},
			} = action;
			if (anchor === undefined) {
				return state;
			}
			const movedIssueIndex = state.findIndex((issue) => issue.id === id);
			const anchorIssue = state.find((issue) => issue.id === anchor);
			if (!anchorIssue) {
				throw new Error(`Anchor with id ${anchor} not found!`);
			}
			return R.adjust(movedIssueIndex, R.assoc('lexoRank', anchorIssue.lexoRank), state);
		}
		case BULK_RANK: {
			const {
				payload: { itemKeys, anchor },
			} = action;
			const itemKeysSet = new Set(itemKeys);
			if (anchor === undefined) {
				return state;
			}
			const anchorIssue = state.find((el) => el.id === anchor);
			if (!anchorIssue) {
				throw new Error(`Anchor with id ${anchor} not found!`);
			}
			const lexoRank = anchorIssue.lexoRank;
			const result: Array<Issue> = [];
			for (const issue of state) {
				if (itemKeysSet.has(issue.id)) {
					result.push({ ...issue, lexoRank });
				} else {
					result.push(issue);
				}
			}
			return result;
		}
		case REMOVE_WITHOUT_ISSUE_KEY:
			return state.filter(({ issueKey }) => issueKey);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
