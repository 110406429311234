import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import EditorLinkIcon from '@atlaskit/icon/core/migration/link--editor-link';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

type Props = {
	label: string;
	withWarning?: boolean;
};

const DependenciesIcon = ({ label, withWarning = false }: Props) =>
	withWarning ? (
		<Container backgroundColor={token('color.background.danger')}>
			<EditorLinkIcon
				LEGACY_size="medium"
				spacing="spacious"
				key="dependencies"
				label={label}
				color={token('color.icon.danger')}
			/>
		</Container>
	) : (
		<EditorLinkIcon LEGACY_size="medium" spacing="spacious" key="dependencies" label={label} />
	);

export default DependenciesIcon;

interface ContainerProps {
	backgroundColor: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span<ContainerProps>({
	color: token('color.text.inverse'),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: any) => props.backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: gridSize * 2.5,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2.5,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	boxSizing: 'border-box',
	overflow: 'hidden',
});
