import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import type { DependencyLine } from '@atlassian/jira-portfolio-3-dependency-lines/src/common/types.tsx';
import { getZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { isOptimizedMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getUniqueLinks,
	getDependencyIssueLinkTypesById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';
import {
	getTableItems,
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import {
	getDependencySettings,
	getTimeScaleViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { IssueLinkType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/system/types.tsx';
import type { DependencySettingsState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/dependency-settings/types.tsx';
import type { TimeScaleState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { swapIssueBaselineDatesIfInverted } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/issue-helper.tsx';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import {
	DEPENDENCY_SETTINGS,
	PACKAGE_NAME,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getPositionsForBar } from '../utils.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export function getLineWithinHorizontalBounds(
	timelineRange: TimelineRange,
	timeScale: TimeScaleState,
	from: TableItem,
	to: TableItem,
): boolean {
	// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
	const { baselineStart: fromStart, baselineEnd: fromEnd } =
		from.tag === TABLE_ISSUE ? from.value : {};
	// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
	const { baselineStart: toStart, baselineEnd: toEnd } = to.tag === TABLE_ISSUE ? to.value : {};

	// return false if either of the issues has both it's dates undefined
	// (lines can still be drawn between issues with partial dates)
	const fromHasDate = isDefined(fromStart) || isDefined(fromEnd);
	const toHasDate = isDefined(toStart) || isDefined(toEnd);
	if (!fromHasDate || !toHasDate) {
		return false;
	}

	const { mode: selectedZoomLevel } = timeScale;
	const isScrollableTimeline = getZoomLevel(selectedZoomLevel) !== undefined;

	if (isScrollableTimeline) {
		return true;
	}

	const fromPositions = getPositionsForBar(
		{
			...(from.tag === TABLE_ISSUE && {
				baselineStart: fromStart,
				baselineEnd: fromEnd,
			}),
		},
		timelineRange,
	);

	const toPositions = getPositionsForBar(
		{
			...(to.tag === TABLE_ISSUE && {
				baselineStart: toStart,
				baselineEnd: toEnd,
			}),
		},
		timelineRange,
	);

	return (
		toPositions.leftPositionPercentage < 100 &&
		toPositions.leftPositionPercentage > 0 &&
		100 - fromPositions.rightPositionPercentage < 100 &&
		100 - fromPositions.rightPositionPercentage > 0
	);
}

/**
 * This method is triggered whenever we find an inconsistency with
 * our issue types. Sometimes we have issueLinks for issues with a
 * non-existing issue type, probably coming from a migration.
 * This caused an unexpected error impeding the plan to load.
 */
const logDependencyLinesInconsistencyError = () => {
	const errorMessage = 'Incorrect issue type found when rendering dependency lines';
	fireErrorAnalytics({
		meta: {
			packageName: PACKAGE_NAME,
			id: ERROR_REPORTING_PACKAGE.ROADMAP,
			teamName: ERROR_REPORTING_TEAM,
		},
		attributes: { message: errorMessage },
		error: new Error(errorMessage),
		skipSentry: true,
	});
};

const getDependencyLinesPureFunc = (
	tableItems: TableItem[],
	uniqueLinks: IssueLink[],
	timelineRange: TimelineRange,
	timeScale: TimeScaleState,
	issueTypesById: {
		[id: number]: IssueType;
	},
	issueLinkTypes: {
		[id: number]: IssueLinkType;
	},
	projectsById: ProjectsById,
	dependencySettings: DependencySettingsState,
) => {
	if (dependencySettings.display !== DEPENDENCY_SETTINGS.LINES) {
		return [];
	}

	const getIssueId = (tableItem: TableItem) => {
		if (tableItem.tag !== TABLE_ISSUE) {
			return undefined;
		}

		return tableItem.value.id;
	};

	const tableItemsIndexMap = tableItems.reduce<Record<string, number[]>>(
		(map, tableItem, index) => {
			const issueId = getIssueId(tableItem);

			if (!issueId) {
				return map;
			}

			if (map[issueId]) {
				map[issueId].push(index);
			} else {
				// eslint-disable-next-line no-param-reassign
				map[issueId] = [index];
			}

			return map;
		},
		{},
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return uniqueLinks.reduce<Array<any>>((lines, currentLink) => {
		const { sourceItemKey, targetItemKey, type, itemKey } = currentLink;

		// There may be multiple occurrences of a source or target issue in the scope - e.g when grouped by sprint
		const issueItemFromIndexes = Object.prototype.hasOwnProperty.call(
			tableItemsIndexMap,
			sourceItemKey,
		)
			? tableItemsIndexMap[sourceItemKey]
			: [];

		const issueItemToIndexes = Object.prototype.hasOwnProperty.call(
			tableItemsIndexMap,
			targetItemKey,
		)
			? tableItemsIndexMap[targetItemKey]
			: [];

		// create pairs of the link occurrences
		const linkOccurrences = issueItemFromIndexes.flatMap((a: number) =>
			issueItemToIndexes.map((b: number) => ({ from: a, to: b })),
		);

		linkOccurrences.forEach(({ from, to }) => {
			const issueItemFrom: TableItem = tableItems[from];

			if (!issueItemFrom || issueItemFrom.tag !== TABLE_ISSUE) {
				return;
			}

			const issueItemTo: TableItem = tableItems[to];

			if (!issueItemTo || issueItemTo.tag !== TABLE_ISSUE) {
				return;
			}

			// the to and from points of a line should be withing the horizontal bounds of the timeline
			// for line rendering to be meaningful to a user
			const lineWithinHorizontalBounds = getLineWithinHorizontalBounds(
				timelineRange,
				timeScale,
				issueItemFrom,
				issueItemTo,
			);

			// we do not render lines between issues in groupBy views if they are in different groups
			const areInSameGroup = issueItemFrom.group === issueItemTo.group;

			const drawable = areInSameGroup && lineWithinHorizontalBounds;

			// Ensure correct order of baselineStart & baselineEnd for correct rendering of dependency lines
			const rawSourceIssue: ScopeIssue = swapIssueBaselineDatesIfInverted(issueItemFrom.value);
			const rawTargetIssue: ScopeIssue = swapIssueBaselineDatesIfInverted(issueItemTo.value);
			const sourceIssueType: IssueType | typeof undefined = issueTypesById[rawSourceIssue.type];
			const targetIssueType: IssueType | typeof undefined = issueTypesById[rawTargetIssue.type];

			// invalid issue type
			if (!sourceIssueType || !targetIssueType) {
				logDependencyLinesInconsistencyError();
				return;
			}

			lines.push({
				itemKey,
				fromIssue: {
					indexOnScope: from,
					id: toIssueId(sourceItemKey),
					issueKey: rawSourceIssue.issueKey,
					projectKey: projectsById[rawSourceIssue.project].key,
					title: rawSourceIssue.summary,
					typeName: sourceIssueType.name,
					iconUrl: sourceIssueType.iconUrl,
					startDate: rawSourceIssue.baselineStart || 0,
					dueDate: rawSourceIssue.baselineEnd || 0,
					isHidden: false,
				},
				toIssue: {
					indexOnScope: to,
					id: toIssueId(targetItemKey),
					issueKey: rawTargetIssue.issueKey,
					projectKey: projectsById[rawTargetIssue.project].key,
					title: rawTargetIssue.summary,
					typeName: targetIssueType.name,
					iconUrl: targetIssueType.iconUrl,
					startDate: rawTargetIssue.baselineStart || 0,
					dueDate: rawTargetIssue.baselineEnd || 0,
					isHidden: false,
				},
				issueLinkType: {
					...issueLinkTypes[type],
				},
				drawable,
			});
		});

		return lines;
	}, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDependencyLinesPure = monitor.timeFunction<any, any>(
	getDependencyLinesPureFunc,
	'roadmap_timeline_schedule_dependencyLines_provider_query_getDependencyLinesPure',
);

const getDependencyLines: (state: State, props: OwnProps) => DependencyLine[] = createSelector(
	[
		getTableItems,
		getUniqueLinks,
		getTimelineRange,
		getTimeScaleViewSettings,
		getIssueTypesById,
		getDependencyIssueLinkTypesById,
		getProjectsById,
		getDependencySettings,
		isOptimizedMode,
	],
	getDependencyLinesPure,
);

export type DependencySettings = ReturnType<typeof getDependencySettings>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps> = (state, props) => ({
	dependencyLines: getDependencyLines(state, props),
	dependencySettings: getDependencySettings(state),
});

export default mapStateToProps;
