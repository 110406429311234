import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import Heading from '@atlaskit/heading';

type Props = {
	letter: string;
};

const LetterIcon = ({ letter }: Props) => (
	<Lozenge>
		<Heading as="span" size="xsmall">
			{letter}
		</Heading>
	</Lozenge>
);

export const DateFieldIcon = () => <LetterIcon letter="D" />;
export const SprintDateIcon = () => <LetterIcon letter="S" />;
export const ReleaseDateIcon = () => <LetterIcon letter="R" />;

export default LetterIcon;
