import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getComponentsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/components/index.tsx';
import { getCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import { getCustomFieldsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getLazyGoalsByARI } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals/index.tsx';
import { getFocusAreasById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-focus-areas/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import {
	getAllTeams,
	getAdditionalTeamsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { getGroupDescriptor } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/group/index.tsx';
import { getEnrichedVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import {
	getComponentGroupsById,
	getViewMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, QueryProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, QueryProps>({
	additionalTeamsById: getAdditionalTeamsById,
	allTeams: getAllTeams,
	componentGroupsById: getComponentGroupsById,
	componentsById: getComponentsById,
	crossProjectVersionsById: getCrossProjectVersionsById,
	customFieldsById: getCustomFieldsById,
	enrichedVersionsById: getEnrichedVersionsById,
	isExportMode,
	projectsById: getProjectsById,
	sprints: getSprints,
	viewMode: getViewMode,
	lazyGoalsByARI: getLazyGoalsByARI,
	associatedIssues: (state) => state.domain.associatedIssues.associatedIssues,
	focusAreas: getFocusAreasById,
	groupDescriptor: getGroupDescriptor,
});

export const mapStateToPropsOld = createStructuredSelector<State, OwnProps, QueryProps>({
	additionalTeamsById: getAdditionalTeamsById,
	allTeams: getAllTeams,
	componentGroupsById: getComponentGroupsById,
	componentsById: getComponentsById,
	crossProjectVersionsById: getCrossProjectVersionsById,
	customFieldsById: getCustomFieldsById,
	enrichedVersionsById: getEnrichedVersionsById,
	isExportMode,
	projectsById: getProjectsById,
	sprints: getSprints,
	viewMode: getViewMode,
	lazyGoalsByARI: getLazyGoalsByARI,
	associatedIssues: (state) => state.domain.associatedIssues.associatedIssues,
	focusAreas: getFocusAreasById,
});
