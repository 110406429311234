import React, { useCallback, useRef, useState } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';

import { useIntl } from '@atlassian/jira-intl';
import PNGExportContent from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/share-report/components/png-export/index.tsx';
import type {
	ButtonState,
	PngExportRef,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/share-report/components/png-export/types.tsx';
import { getCsvIntlMessages } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/share-report/components/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export default function TimelineExportTab({
	noIssuesInPlan,
	definedGoalsByARI,
	associatedIssues,
	planTitle,
	planModeForAnalytics,
	getExportCsvScopeItems,
	onClose,
}: Props) {
	const { formatMessage } = useIntl();

	const [exportType, setExportType] = useState<string>('csv');
	const [buttonState, setButtonState] = useState<ButtonState>('enabled');

	const pngExportRef = useRef<PngExportRef>(null);

	const onChange = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			setButtonState('enabled');
			setExportType(value);
		},
		[],
	);

	const onExportClick = useCallback(
		(
			event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			if (exportType === 'csv') {
				setButtonState('downloading');
				const csvIntlMessages = getCsvIntlMessages(formatMessage, planTitle ?? '');
				getExportCsvScopeItems(csvIntlMessages, {
					goalsByARI: definedGoalsByARI,
					associatedIssues,
					onComplete: onClose,
				});
			} else {
				pngExportRef.current?.handleDownload(event, analyticsEvent);
			}
		},
		[
			associatedIssues,
			definedGoalsByARI,
			exportType,
			formatMessage,
			getExportCsvScopeItems,
			onClose,
			planTitle,
		],
	);

	return (
		<Box xcss={containerStyles}>
			<Stack space="space.150">
				<Stack>
					<Radio
						isChecked={exportType === 'csv'}
						onChange={onChange}
						label={formatMessage(messages.exportCsvOptionLabel)}
						name="exportType"
						value="csv"
						aria-describedby="portfolio-3-share-tabs-export-csv-option-description"
					/>
					<Box xcss={leftPaddingStyles}>
						<Text
							as="span"
							size="small"
							color="color.text.subtlest"
							id="portfolio-3-share-tabs-export-csv-option-description"
						>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.exportCsvDescriptionIssueTermRefresh
									: messages.exportCsvDescription,
							)}
						</Text>
					</Box>
				</Stack>
				<Stack space="space.100">
					<Radio
						isChecked={exportType === 'png'}
						onChange={onChange}
						label={formatMessage(messages.exportPngOptionLabel)}
						name="exportType"
						value="png"
					/>
					{exportType === 'png' && (
						<Box xcss={leftPaddingStyles}>
							<PNGExportContent
								planMode={planModeForAnalytics}
								ref={pngExportRef}
								updateButtonState={setButtonState}
								onClose={onClose}
							/>
						</Box>
					)}
				</Stack>
				<Flex justifyContent="end" xcss={footerStyles}>
					<Button
						appearance="primary"
						onClick={onExportClick}
						isDisabled={buttonState === 'disabled' || noIssuesInPlan}
						isLoading={buttonState === 'downloading'}
					>
						{formatMessage(messages.export)}
					</Button>
				</Flex>
			</Stack>
		</Box>
	);
}

const containerStyles = xcss({
	width: '552px',
	marginTop: 'space.100',
});

const leftPaddingStyles = xcss({
	marginLeft: 'space.300',
	paddingLeft: 'space.050',
});

const footerStyles = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});
