import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import mapStateToProps from './query.tsx';
import View from './view.tsx';
import ViewOld from './view-old.tsx';

export default componentWithFG(
	'jira_plan_timeline_dependency_flyout_migration',
	connect(mapStateToProps, {})(View),
	connect(mapStateToProps, {})(ViewOld),
);
