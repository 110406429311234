import * as R from 'ramda';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import {
	ONE_DAY as MILLIS_PER_DAY,
	endOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	type Annotation,
	type StaticScheduleWarnings,
	type IssueStatus,
	DATEFIELD_TYPES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

import {
	indexBy,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	SCHEDULE_MODE,
	ISSUE_STATUS_CATEGORIES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getFutureSprintsForIssue } from '../../query/sprints/common.tsx';
import type { TimelineSprint } from '../../query/sprints/types.tsx';
import { getTargetDatesFromSprints } from '../../query/sprints/utils.tsx';
import { type Mode, OPTIMIZED } from '../../state/domain/app/types.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { PlanInfo } from '../../state/domain/plan/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import {
	ENDS_AFTER_PARENT,
	STARTS_BEFORE_PARENT,
	UNKNOWN,
	NOT_FULLY_SCHEDULED,
	HORIZON_REACHED,
	RELEASE_CONFLICT,
	ASSIGNEE_IGNORED,
	EXTERNAL_TEAM,
	SUBTASK_VIOLATION,
	SUBTASK_ASSIGNMENT_VIOLATION,
	SPRINT_EXCEEDED,
	INVALID_SPRINT,
	EARLIEST_START_IGNORED,
	OVERBOOKED,
	RELEASE_SHIFTED,
	AVAILABILITY_VIOLATION,
	RESOURCE_LIMIT_VIOLATION,
	DEPENDENCIES_IGNORED_SPRINT,
	CYCLIC_DEPENDENCY,
	SUBTASK_CYCLIC_DEPENDENCY_IGNORED,
	SUBTASK_NO_SKILLED_RESOURCE,
	MISSING_SKILLS,
	TOO_SMALL_ESTIMATES,
	COMPLETED_PARENT,
	DEPENDENCIES_IGNORED_RELEASE,
	VIOLATION_IN_DEPENDENCY,
	CALCULATION_COMPLEXITY,
	TARGET_DATE_BEYOND_DUE_DATE,
	NOT_CLOSED_BEYOND_TARGET_END_DATE,
	IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE,
	TARGET_DATES_ARE_OUTSIDE_OF_SPRINT,
	ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT,
	START_DATE_AFTER_END_DATE,
	type Warnings,
	type WarningType,
	type Warning,
} from '../../state/domain/warnings/types.tsx';
import {
	headerMessages,
	detailedSummaryMessages,
	bodyMessages,
	shortDateMessages,
} from './messages.tsx';

type Message = MessageDescriptor;
export type WarningMessages = {
	header: Message;
	description: Message;
};
type MessageValues = Warning['messageValues'];
type AutoResolvePayload = Warning['autoResolvePayload'];

export const WarningTypes: {
	[x: string]: WarningMessages;
} = {
	[TARGET_DATE_BEYOND_DUE_DATE]: {
		header: headerMessages.dateAfterDueDate,
		description: detailedSummaryMessages.targetDateBeyondDueDate,
	},
	[TARGET_DATES_ARE_OUTSIDE_OF_SPRINT]: {
		header: headerMessages.issueScheduledOutsideOfSprint,
		description: detailedSummaryMessages.targetDateOutsideOfSprint,
	},
	[IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE]: {
		header: headerMessages.openIssuePastInferredEndDate,
		description: detailedSummaryMessages.inProgressBeyondInferredTargetEndDate,
	},
	[NOT_CLOSED_BEYOND_TARGET_END_DATE]: {
		header: headerMessages.openIssuePastAssignedEndDate,
		description: detailedSummaryMessages.notClosedBeyondTargetEndDate,
	},
	[ENDS_AFTER_PARENT]: {
		header: headerMessages.childIssueEndsAfterParent,
		description: detailedSummaryMessages.endsAfterParent,
	},
	[STARTS_BEFORE_PARENT]: {
		header: headerMessages.childIssueStartsBeforeParent,
		description: detailedSummaryMessages.startsBeforeParent,
	},
	[ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT]: {
		header: headerMessages.kanbanIssueAssignedToSprint,
		description: detailedSummaryMessages.issueAssignedKanbanTeamAndSprint,
	},
	[START_DATE_AFTER_END_DATE]: {
		header: headerMessages.startDateAfterEndDate,
		description: detailedSummaryMessages.startDateAfterEndDate,
	},
};

export const WarningTypeIds = Object.keys(WarningTypes);

export type CalculateWarnings = {
	isSolutionValid: boolean;
	mode: Mode;
	issues: Issue[];
	projects: Project[];
	sprints: Sprint[];
	issueStatuses: IssueStatus[];
	customFields: CustomField[];
	sprintsWithFutureDates: {
		team: {
			[key: string]: TimelineSprint[];
		};
		sprint: {
			[key: string]: TimelineSprint[];
		};
	};
	plan: PlanInfo;
	disabledWarnings: string[];
	teamsById: {
		[teamId: string]: Team;
	};
};

// TODO(JPOS-1940/tim) change to AtlasKit-provided heuristics before switching live.
// Or factor with distanceInWordsToNow from @atlassian/jira-portfolio-3-common?
const periodBetween = (a: number, b: number) => {
	const lo = Math.min(a, b);
	const hi = Math.max(a, b);
	const millis = hi - lo;
	const days = Math.ceil(millis / MILLIS_PER_DAY);
	if (days < 7) {
		return {
			key: shortDateMessages.days,
			days,
		};
	}

	const weeks = Math.floor(days / 7);
	if (weeks < 4) {
		return {
			key: shortDateMessages.weeks,
			weeks,
		};
	}

	const months = Math.ceil((days / 365) * 12);
	if (months < 12) {
		return {
			key: shortDateMessages.months,
			months,
		};
	}

	const years = Math.ceil(months / 12);
	return {
		key: shortDateMessages.years,
		years,
	};
};

export const transitiveRollUp = (
	issuesById: {
		[key: string]: Issue;
	},
	warningsByIssue: {
		[key: string]: Warning[];
	},
): string[] => {
	// Add parent warnings closure so that warnings are surfaced on otherwise correct collapsed parents
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const transitiveIssueIds: Record<string, any> = {};
	for (const [issueId, existingWarnings] of R.toPairs(warningsByIssue)) {
		if (existingWarnings && existingWarnings.length !== 0) {
			const issue = issuesById[issueId];
			const parent = issue && issue.parent && issuesById[issue.parent];
			if (parent) {
				transitiveIssueIds[parent.id] = true;
			}
		}
	}

	// Roll up for parents of parents of warned issues.
	for (const id of R.keys(transitiveIssueIds)) {
		let issue = issuesById[id];
		while (issue) {
			transitiveIssueIds[issue.id] = true;
			issue = issuesById[issue.parent || ''];
		}
	}

	// Don't include transitive ids if they have their own warnings.
	for (const id of R.keys(transitiveIssueIds)) {
		const warnings = warningsByIssue[id];
		if (warnings && warnings.length !== 0) {
			delete transitiveIssueIds[id];
		}
	}

	return R.keys(transitiveIssueIds);
};

const processWarnings = (
	issuesById: {
		[key: string]: Issue;
	},
	warningsByIssue: {
		[key: string]: Warning[];
	},
	allWarningsByIssue: {
		[key: string]: Warning[];
	},
): Warnings => ({
	byIssue: warningsByIssue,
	transitiveIssueIds: transitiveRollUp(issuesById, warningsByIssue),
	allWarningsByIssue,
});

const staticWarning = (
	type: WarningType,
	header: {
		id: string;
	},
	message: MessageDescriptor,
): Warning => ({
	type,
	header,
	message,
	messageValues: {},
});

const STATIC_SCHEDULE_WARNINGS: StaticScheduleWarnings = {
	'not-fully-scheduled': staticWarning(
		NOT_FULLY_SCHEDULED,
		headerMessages.notFullyScheduled,
		bodyMessages.notFullyScheduled,
	),
	'horizon-reached': staticWarning(
		HORIZON_REACHED,
		headerMessages.horizonReached,
		bodyMessages.horizonReached,
	),
	'release-shifted': staticWarning(
		RELEASE_SHIFTED,
		headerMessages.releaseShifted,
		bodyMessages.releaseShifted,
	),
	'release-conflict': staticWarning(
		RELEASE_CONFLICT,
		headerMessages.releaseConflict,
		bodyMessages.releaseConflict,
	),
	'assignee-ignored': staticWarning(
		ASSIGNEE_IGNORED,
		headerMessages.assigneeIgnored,
		bodyMessages.assigneeIgnored,
	),
	'external-team': staticWarning(
		EXTERNAL_TEAM,
		headerMessages.externalTeam,
		bodyMessages.externalTeam,
	),
	'subtask-violation': staticWarning(
		SUBTASK_VIOLATION,
		headerMessages.subtaskViolation,
		bodyMessages.subtaskViolation,
	),
	'subtask-assignment-violation': staticWarning(
		SUBTASK_ASSIGNMENT_VIOLATION,
		headerMessages.subtaskAssignmentViolation,
		bodyMessages.subtaskAssignmentViolation,
	),
	'sprint-exceeded': staticWarning(
		SPRINT_EXCEEDED,
		headerMessages.sprintExceeded,
		bodyMessages.sprintExceeded,
	),
	'invalid-sprint': staticWarning(
		INVALID_SPRINT,
		headerMessages.invalidSprint,
		bodyMessages.invalidSprint,
	),
	'earliest-start-ignored': staticWarning(
		EARLIEST_START_IGNORED,
		headerMessages.earliestStartIgnored,
		bodyMessages.earliestStartIgnored,
	),
	overbooked: staticWarning(OVERBOOKED, headerMessages.overbooked, bodyMessages.overbooked),
	'availability-violation': staticWarning(
		AVAILABILITY_VIOLATION,
		headerMessages.availabilityViolation,
		bodyMessages.availabilityViolation,
	),
	'violation-in-dependency': staticWarning(
		VIOLATION_IN_DEPENDENCY,
		headerMessages.violationInDependency,
		bodyMessages.violationInDependency,
	),
	'resource-limit-violation': staticWarning(
		RESOURCE_LIMIT_VIOLATION,
		headerMessages.resourceLimitViolation,
		bodyMessages.resourceLimitViolation,
	),
	'dependencies-ignored-sprint': staticWarning(
		DEPENDENCIES_IGNORED_SPRINT,
		headerMessages.dependenciesIgnoredSprint,
		bodyMessages.dependenciesIgnoredSprint,
	),
	'dependencies-ignored-release': staticWarning(
		DEPENDENCIES_IGNORED_RELEASE,
		headerMessages.dependenciesIgnoredRelease,
		bodyMessages.dependenciesIgnoredRelease,
	),
	'cyclic-dependency': staticWarning(
		CYCLIC_DEPENDENCY,
		headerMessages.cyclicDependency,
		bodyMessages.cyclicDependency,
	),
	'subtask-cyclic-dependency-ignored': staticWarning(
		SUBTASK_CYCLIC_DEPENDENCY_IGNORED,
		headerMessages.subtaskCyclicDependencyIgnored,
		bodyMessages.subtaskCyclicDependencyIgnored,
	),
	'subtask-no-skilled-resource': staticWarning(
		SUBTASK_NO_SKILLED_RESOURCE,
		headerMessages.subtaskNoSkilledResource,
		bodyMessages.subtaskNoSkilledResource,
	),
	'missing-skills': staticWarning(
		MISSING_SKILLS,
		headerMessages.missingSkills,
		bodyMessages.missingSkills,
	),
	'too-small-estimates': staticWarning(
		TOO_SMALL_ESTIMATES,
		headerMessages.tooSmallEstimates,
		bodyMessages.tooSmallEstimates,
	),
	'completed-parent': staticWarning(
		COMPLETED_PARENT,
		headerMessages.completedParent,
		bodyMessages.completedParent,
	),
	'calculation-complexity': staticWarning(
		CALCULATION_COMPLEXITY,
		headerMessages.calculationComplexity,
		bodyMessages.calculationComplexity,
	),
};

const mapAnnotationToWarning = ({ type }: Annotation): Warning => {
	const warning = STATIC_SCHEDULE_WARNINGS[type];
	if (warning) {
		return warning;
	}
	return {
		type: UNKNOWN,
		header: headerMessages.unknown,
		message: bodyMessages.unknown,
		messageValues: {},
	};
};

const extractSchedulerWarnings = (
	warningsByIssue: Record<string, Warning[]>,
	allWarningsByIssue: Record<string, Warning[]>,
	issues: Issue[],
): void => {
	for (const { id, annotations } of issues) {
		if (annotations && annotations.length) {
			for (const annotation of annotations || []) {
				const warning = mapAnnotationToWarning(annotation);
				addWarningToHash(warningsByIssue, id, warning);
				addWarningToHash(allWarningsByIssue, id, warning);
			}
		}
	}
};

const addWarningToHash = (
	warningIssueHash: Record<string, Warning[]>,
	issueId: string,
	warning: Warning,
) => {
	if (!warningIssueHash[issueId]) {
		// eslint-disable-next-line no-param-reassign
		warningIssueHash[issueId] = [];
	}
	warningIssueHash[issueId].push(warning);
};

export const calculateAllWarnings = ({
	isSolutionValid,
	mode,
	issues,
	projects,
	sprints,
	issueStatuses,
	sprintsWithFutureDates,
	plan,
	disabledWarnings,
	teamsById,
}: CalculateWarnings): Warnings => {
	const warningsByIssue: Record<string, Warning[]> = {};
	const allWarningsByIssue: Record<string, Warning[]> = {};
	const idProp = R.prop('id');
	const projectsById = indexBy(idProp, projects);
	const sprintsById = indexBy(idProp, sprints);
	const issueStatusesById = indexBy(R.prop('id'), issueStatuses);

	const isCompletedIssueStatuses = R.filter(
		(status) => status.categoryId === ISSUE_STATUS_CATEGORIES.DONE,
		issueStatusesById,
	);

	const isNotDone = (issue: Issue) => !isCompletedIssueStatuses[issue.status || ''];

	const addWarning = (
		issueId: string,
		type: WarningType,
		header: {
			id: string;
		},
		message: {
			id: string;
		},
		messageValues: MessageValues,
		autoResolvePayload?: AutoResolvePayload,
	): void => {
		const warning: Warning = {
			type,
			header,
			message,
			messageValues,
			autoResolvePayload,
		};

		addWarningToHash(allWarningsByIssue, issueId, warning);

		if (!disabledWarnings.includes(type)) {
			addWarningToHash(warningsByIssue, issueId, warning);
		}
	};

	const now = Date.now();
	const undone: Issue[] = R.filter(isNotDone, issues);
	const issuesById = indexBy(R.prop('id'), undone);

	if (mode === OPTIMIZED) {
		if (isSolutionValid) {
			extractSchedulerWarnings(warningsByIssue, allWarningsByIssue, issues);
		}
		return processWarnings(issuesById, warningsByIssue, allWarningsByIssue);
	}

	const key = (issue: Issue) => {
		const project = projectsById[issue.project];
		const issueKey = issue.issueKey;
		if (project && project.key && issueKey) {
			return `${project.key}-${issueKey}`;
		}

		return issue.summary;
	};

	for (const issue of undone) {
		const id = issue.id;
		const parent = issue.parent && issuesById[issue.parent];
		const issueStatusCategory = R.path([issue.status || '', 'categoryId'], issueStatusesById);

		const sprint = issue.sprint ? sprintsById[issue.sprint] : null;

		if (sprint) {
			// Issue has sprint and is assigned to Kanban team
			if (issue.team && teamsById[issue.team]) {
				const team = teamsById[issue.team];
				if (team.schedulingMode === SCHEDULE_MODE.kanban) {
					addWarning(
						id,
						ISSUE_ASSIGNED_KANBAN_TEAM_AND_SPRINT,
						headerMessages.kanbanIssueAssignedToSprint,
						bodyMessages.issueAssignedKanbanTeamAndSprint,
						{},
					);
				}
			}
		}

		if (isDefined(issue.dueDate)) {
			const endOfDayDueDate = endOfUtcDay(issue.dueDate);
			const isConfiguredAsDueDate = [plan.baselineStartField, plan.baselineEndField].some(
				(field) => field.key === 'dueDate' && field.type === DATEFIELD_TYPES.BUILT_IN,
			);
			// when built in dueDate is used as either start or end plan date, this check makes no sense and can be skipped
			if (!isConfiguredAsDueDate) {
				if (
					(isDefined(issue.baselineEnd) && issue.baselineEnd > endOfDayDueDate) ||
					(isDefined(issue.baselineStart) && issue.baselineStart > endOfDayDueDate)
				) {
					addWarning(
						id,
						TARGET_DATE_BEYOND_DUE_DATE,
						headerMessages.dateAfterDueDate,
						bodyMessages.dateBeyondDueDate,
						{},
					);
				}
			}

			const dueDateIsBaselineEndField =
				plan.baselineEndField.key === 'dueDate' &&
				plan.baselineEndField.type === DATEFIELD_TYPES.BUILT_IN;

			if (
				dueDateIsBaselineEndField &&
				isDefined(issue.baselineStart) &&
				issue.baselineStart > endOfDayDueDate
			) {
				addWarning(
					id,
					START_DATE_AFTER_END_DATE,
					headerMessages.startDateAfterEndDate,
					bodyMessages.startDateAfterEndDate,
					{},
				);
			}
		}

		if (isDefined(issue.targetEnd)) {
			const endOfDayTargetEnd = endOfUtcDay(issue.targetEnd);
			const targetEndIsBaselineEndField =
				plan.baselineEndField.key === 'targetEnd' &&
				plan.baselineEndField.type === DATEFIELD_TYPES.BUILT_IN;

			if (
				targetEndIsBaselineEndField &&
				isDefined(issue.baselineStart) &&
				issue.baselineStart > endOfDayTargetEnd
			) {
				addWarning(
					id,
					START_DATE_AFTER_END_DATE,
					headerMessages.startDateAfterEndDate,
					bodyMessages.startDateAfterEndDate,
					{},
				);
			}
		}

		if (isDefined(issue.targetStart)) {
			const endOfDayTargetStart = endOfUtcDay(issue.targetStart);
			const targetStartIsBaselineEndField =
				plan.baselineEndField.key === 'targetStart' &&
				plan.baselineEndField.type === DATEFIELD_TYPES.BUILT_IN;

			if (
				targetStartIsBaselineEndField &&
				isDefined(issue.baselineStart) &&
				issue.baselineStart > endOfDayTargetStart
			) {
				addWarning(
					id,
					START_DATE_AFTER_END_DATE,
					headerMessages.startDateAfterEndDate,
					bodyMessages.startDateAfterEndDate,
					{},
				);
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-shadow
		const sprints = getFutureSprintsForIssue(issue, sprintsWithFutureDates);
		if (
			issue.sprint &&
			sprints.length > 0 &&
			(isDefined(issue.baselineStart) || isDefined(issue.baselineEnd))
		) {
			const sprintDates = getTargetDatesFromSprints(issue, sprints);

			if (
				(isDefined(issue.baselineStart) &&
					sprintDates.rawEndDate &&
					issue.baselineStart > sprintDates.rawEndDate) ||
				(isDefined(issue.baselineEnd) &&
					sprintDates.rawStartDate &&
					issue.baselineEnd < sprintDates.rawStartDate)
			) {
				addWarning(
					id,
					TARGET_DATES_ARE_OUTSIDE_OF_SPRINT,
					headerMessages.issueScheduledOutsideOfSprint,
					bodyMessages.dateOutsideOfSprint,
					{},
				);
			}
		}

		const end = issue.baselineEnd;
		if (isDefined(end)) {
			const isInferred = issue.inferred && issue.inferred.baselineEnd;
			if (isInferred && now > end && issueStatusCategory === ISSUE_STATUS_CATEGORIES.INPROGRESS) {
				addWarning(
					id,
					IN_PROGRESS_BEYOND_INFERRED_TARGET_END_DATE,
					headerMessages.openIssuePastInferredEndDate,
					bodyMessages.inProgressBeyondInferredEndDate,
					{
						issue: key(issue),
					},
				);
			}
			if (!isInferred && now > end && (!issue.status || isNotDone(issue))) {
				addWarning(
					id,
					NOT_CLOSED_BEYOND_TARGET_END_DATE,
					headerMessages.openIssuePastAssignedEndDate,
					bodyMessages.notClosedBeyondEndDate,
					{},
				);
			}
		}

		if (parent) {
			const parentStart = parent.baselineStart;
			const parentEnd = parent.baselineEnd;
			const issueStart = issue.baselineStart;
			const issueEnd = issue.baselineEnd;

			// PA.end < A.end
			if (isDefined(parentEnd) && isDefined(issueEnd) && parentEnd < issueEnd) {
				addWarning(
					id,
					ENDS_AFTER_PARENT,
					headerMessages.childIssueEndsAfterParent,
					bodyMessages.endsAfterParent,
					{
						issue: key(issue),
						parent: key(parent),
						amount: periodBetween(parentEnd, issueEnd),
					},
					{
						issueId: parent.id,
						end: issueEnd,
					},
				);
			}

			// PA.start > A.start
			if (parentStart && issueStart && parentStart > issueStart) {
				addWarning(
					id,
					STARTS_BEFORE_PARENT,
					headerMessages.childIssueStartsBeforeParent,
					bodyMessages.startsBeforeParent,
					{
						issue: key(issue),
						parent: key(parent),
						amount: periodBetween(issueStart, parentStart),
					},
					{
						issueId: parent.id,
						start: issueStart,
					},
				);
			}
		}
	}

	return processWarnings(issuesById, warningsByIssue, allWarningsByIssue);
};
