import React from 'react';
import { Card } from '@atlaskit/smart-card';
import { xcss, Anchor } from '@atlaskit/primitives';
import type { CommonFieldComponentProps } from '../../common/types.tsx';

export const isHyperlinkFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is HyperlinkFieldComponentProps => 'value' in props && typeof props.value === 'string';

export type HyperlinkFieldComponentProps = CommonFieldComponentProps & {
	value: string;
	isInsideTooltip?: boolean;
};

export const HyperlinkField = ({ value, isInsideTooltip }: HyperlinkFieldComponentProps) => (
	<HyperlinkFieldDisplay value={value} isInsideTooltip={isInsideTooltip} />
);

type HyperlinkFieldDisplayProps = {
	value: string;
	isInsideTooltip?: boolean;
};

export const HyperlinkFieldDisplay = ({
	value,
	isInsideTooltip = false,
}: HyperlinkFieldDisplayProps) => (
	<Card
		key={value}
		url={value}
		appearance="inline"
		showHoverPreview={!isInsideTooltip}
		fallbackComponent={() => HyperlinkFallback(value)}
	/>
);

const HyperlinkFallback = (value: string) => (
	<Anchor href={value} xcss={hyperlinkFallbackStyles}>
		{value}
	</Anchor>
);

const hyperlinkFallbackStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	width: '100%',
});
