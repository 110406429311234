/** @jsx jsx */
import { Box, Text } from '@atlaskit/primitives/compiled';
import { jsx, cssMap, cx } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

import type { Props } from './types.tsx';
import { SettingsItemContent } from './content/index.tsx';

const styles = cssMap({
	container: {
		paddingTop: token('space.200'),
	},
	separator: {
		borderTopWidth: token('border.width.outline'),
		borderTopStyle: 'solid',
		borderTopColor: token('color.border'),
		marginTop: token('space.200'),
	},
	heading: {
		paddingTop: token('space.0'),
		paddingRight: token('space.0'),
		paddingBottom: token('space.0'),
		paddingLeft: token('space.0'),
	},
});

export const SettingsItem = ({ hasHeadingSeparator = false, heading, settings }: Props) => {
	const content = settings.map((settingsItemContentProps, index) => {
		return (
			<SettingsItemContent
				{...settingsItemContentProps}
				key={`settings-item-${heading || 'headingless'}-${settingsItemContentProps.title}-${index}`}
			/>
		);
	});

	return heading ? (
		<Box xcss={cx(styles.container, hasHeadingSeparator && styles.separator)}>
			<fieldset>
				<Box as="legend" xcss={styles.heading}>
					<Text weight="bold">{heading}</Text>
				</Box>
				{content}
			</fieldset>
		</Box>
	) : (
		content
	);
};
