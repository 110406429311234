import React from 'react';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer, createHook } from '@atlassian/react-sweet-state';
import { selectColspanOld } from './selectors.tsx';
import type { State } from './types.tsx';

const store = createStore<State, {}>({
	initialState: {
		viewMode: VIEW_MODES.TIMELINE,
		columns: [],
		columnWidths: [],
	},
	actions: {},
});

const ColspanProvider = createContainer(store, {
	onInit:
		() =>
		({ setState }, state: State) =>
			setState(state),
	onUpdate:
		() =>
		({ setState }, state: State) =>
			setState(state),
});

export const useColspan = createHook(store, {
	selector: selectColspanOld,
});

type Props = Pick<State, 'viewMode' | 'columns'> & { children: React.ReactNode };

export const Colspan = (props: Props) => {
	const [columnWidths] = useColumnWidths({ preview: true });

	return (
		<ColspanProvider {...props} columnWidths={columnWidths}>
			{props.children}
		</ColspanProvider>
	);
};
