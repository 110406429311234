import React, { Component } from 'react';
import * as R from 'ramda';
import AddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { ColourComponent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { chain } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import ColourPicker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colour-picker/view.tsx';
import {
	DEFAULT_COLOR,
	colourPickerPaletteLabels,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import viewSettingsMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

const optionsToComponents = (options: OptionType[]): string[] => options.map((x) => x.value);

// eslint-disable-next-line jira/react/no-class-components
export class ColourByComponent extends Component<Props, State> {
	state = {
		// "keySuffix" was added to force re-render when all components are removed from any Select to remove its whole row.
		// Reason: to avoid regression when clearing some Select the last Select with the same colour is removed.
		// Also this implementation lets keep focus on Select while editing it from keyboard -
		// https://stash.atlassian.com/plugins/servlet/jira-integration/issues/JPOS-4624
		keySuffix: 0,
	};

	// components select must not show components already picked for any colour
	availableComponents = (): string[] => {
		const {
			components,
			colourByConfiguration: { colourComponents },
		} = this.props;

		const usedComponents = new Set(chain((x) => x.components, colourComponents));
		return components
			.map((component) => component.id.toString())
			.filter((component) => !usedComponents.has(component));
	};

	componentIdsToOptions = (componentIds: string[]): OptionType[] => {
		const { components, projectsById } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return componentIds.reduce<Array<any>>((options, componentId) => {
			const component = components.find(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(component) => component.id.toString() === componentId,
			);
			if (component) {
				const option = {
					value: component.id.toString(),
					label: component.projectId
						? `${component.name} (${projectsById[component.projectId].key})`
						: component.name,
				};
				options.push(option);
			}
			return options;
		}, []);
	};

	onComponentsChange = (index: number, options: ValueType) => {
		const { updateComponentColour, removeComponentColour } = this.props;
		if (R.isNil(options) || R.isEmpty(options)) {
			removeComponentColour({ index });
			this.setState((prevState) => ({ keySuffix: prevState.keySuffix + 1 }));
		} else {
			updateComponentColour({
				index,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				components: optionsToComponents(options as OptionType[]),
			});
		}
	};

	onColourChange = (index: number) => (colour?: string | null) => {
		const { updateComponentColour, removeComponentColour } = this.props;
		if (colour) {
			updateComponentColour({ index, colour });
		} else {
			removeComponentColour({ index });
		}
	};

	renderRow = (colourComponent: ColourComponent, index: number) => {
		const { colour, components } = colourComponent;
		const availableComponents = this.availableComponents();
		const { intl, onMenuToggle } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.row} key={`${index}-${colour || ''}-${this.state.keySuffix}`}>
				<ColourPicker
					colour={colour}
					onColourChange={this.onColourChange(index)}
					position="left"
					ariaLabel={
						colour
							? intl.formatMessage(messages.editLabelColorAriaLabel)
							: intl.formatMessage(messages.chooseLabelColorAriaLabel)
					}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.componentSelect}>
					<AkSelect
						aria-label={intl.formatMessage(messages.selectComponentLabel)}
						classNamePrefix={`portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_colour-by-component_select-component-${index}`}
						defaultValue={this.componentIdsToOptions(components)}
						isDisabled={!colour}
						isMulti
						menuPlacement="auto"
						menuPortalTarget={getBody()}
						onChange={(options: ValueType) => this.onComponentsChange(index, options)}
						onMenuClose={() => {
							onMenuToggle(false);
						}}
						onMenuOpen={() => {
							onMenuToggle(true);
						}}
						options={this.componentIdsToOptions(availableComponents)}
						placeholder={intl.formatMessage(
							colour ? messages.findComponent : messages.chooseColour,
						)}
						styles={{
							// eslint-disable-next-line @typescript-eslint/no-shadow
							menuPortal: (styles) => ({
								...styles,
								zIndex: 1000,
							}),
						}}
						inputId="portfolio-3.simple-plans_main_tabs_roadmap.view-settings.colourByComponent.select-component"
					/>
				</div>
			</div>
		);
	};

	renderAddComponent = () => {
		const { addComponentColour } = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={`${styles.addLabelButton} ${styles.row}`}>
				<Button
					appearance="link"
					iconBefore={<AddIcon spacing="spacious" label="" />}
					onClick={(e) => {
						e.preventDefault();
						addComponentColour();
					}}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.add-color-link"
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
					<span className={styles.addLabel}>
						<FormattedMessage {...messages.addComponent} />
					</span>
				</Button>
			</div>
		);
	};

	render() {
		const {
			colourByConfiguration: { colourComponents },
			intl,
		} = this.props;
		const isAddingNewComponentColour = colourComponents.some(({ colour }) => !colour);
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.list}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.row}>
					<Button
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.allOtherIssuesButton}
						isDisabled
						ariaLabel={intl.formatMessage(viewSettingsMessages.allOtherIssuesButtonLabel)}
						iconAfter={
							<Tooltip
								content={intl.formatMessage(colourPickerPaletteLabels[DEFAULT_COLOR])}
								position="top"
							>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
								<div className={styles.swatch} style={{ backgroundColor: DEFAULT_COLOR }} />
							</Tooltip>
						}
					/>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.allOtherIssues}>
						{fg('jira-issue-terminology-refresh-m3') ? (
							<FormattedMessage {...commonMessages.allOtherIssuesIssueTermRefresh} />
						) : (
							<FormattedMessage {...commonMessages.allOtherIssues} />
						)}
					</div>
				</div>
				{colourComponents.map(this.renderRow)}
				{isAddingNewComponentColour ? null : this.renderAddComponent()}
			</div>
		);
	}
}

export default injectIntl(ColourByComponent);
