import React from 'react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const GoalsErrorFlag = () => {
	const { formatMessage } = useIntl();

	return (
		<Flag
			key="jpo-goals-error-flag"
			id="jpo-goals-error-flag"
			icon={
				<ErrorIcon
					spacing="spacious"
					label={formatMessage(messages.errorFlagIconLabel)}
					color={token('color.icon.danger')}
				/>
			}
			title={formatMessage(messages.errorFlagTitle)}
			description={formatMessage(messages.errorFlagDescription)}
		/>
	);
};
