import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps, { arrowsMapStateToProps } from './query.tsx';
import type { DispatchProps, OwnProps, ArrowsOwnProps, StateProps } from './types.tsx';
import View, { TransposedArrows as TransposedView } from './view.tsx';

export default connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);

export const Arrows = connect<StateProps, DispatchProps, ArrowsOwnProps, State>(
	arrowsMapStateToProps,
	mapDispatchToProps,
)(TransposedView);
