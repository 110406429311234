import React, { type ChangeEvent, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	isChecked?: boolean;
	isDisabled?: boolean;
	isIndeterminate?: boolean;
	name?: string;
	testId?: string;
	label?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onClick?: (e: MouseEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
	onChange,
	onClick,
	isChecked = false,
	testId,
	isDisabled = false,
	isIndeterminate = false,
	name,
	label,
}: Props) => (
	<CheckboxLabel tabIndex={0} isDisabled={isDisabled} data-component-selector="label-d2S2">
		<input
			data-testid={testId}
			type="checkbox"
			onChange={onChange}
			onClick={onClick}
			name={name}
			aria-checked={isChecked}
			disabled={isDisabled}
			ref={(input) => {
				if (input && isIndeterminate) {
					Object.assign(input, { indeterminate: true });
				}
			}}
		/>
		<CheckboxStyled
			isChecked={isChecked}
			isDisabled={isDisabled}
			isIndeterminate={isIndeterminate}
		/>
		{label !== undefined && <CheckboxText>{label}</CheckboxText>}
	</CheckboxLabel>
);

const THICKNESS = 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxLabel = styled.label<{ isDisabled?: boolean }>({
	minWidth: '2em',
	height: '2em',
	display: 'flex',
	justifyContent: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : 'default'),
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		boxShadow: 'none',
		outline: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxStyled = styled.div<{
	isDisabled: boolean;
	isChecked: boolean;
	isIndeterminate: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	alt?: any;
}>({
	width: '0.8em',
	height: '0.8em',
	borderRadius: '3px',
	borderWidth: '2px',
	borderStyle: 'solid',
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ isDisabled, isChecked, isIndeterminate }) =>
		isDisabled
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.disabled')
			: isChecked || isIndeterminate
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					colors.B400
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.border.input'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isDisabled, isChecked, isIndeterminate }) =>
		isDisabled
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.disabled')
			: isChecked || isIndeterminate
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					colors.B400
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.input'),
	/* eslint-enable no-nested-ternary */
	transition: 'border-color 0.2s ease-in-out, background-color 0.2s ease-in-out',
	position: 'relative',
	color: 'white',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::before, ::after': {
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ alt, isChecked, isIndeterminate }) =>
			isChecked || (isIndeterminate && !alt) ? 'block' : 'none',
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: ({ isDisabled }) => (isDisabled ? colors.N70 : 'white'),
		transformOrigin: `${THICKNESS / 2}px ${THICKNESS / 2}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ isIndeterminate }) => `${isIndeterminate ? 0.32 : 0.5}em`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		left: ({ isIndeterminate }) => `${isIndeterminate ? 0.1 : 0.25}em`,
		borderRadius: `${THICKNESS / 2}px`,
		height: `${THICKNESS}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::before': {
		width: '0.4em',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		transform: ({ isIndeterminate }) => `rotate(${isIndeterminate ? 0 : -135}deg)`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::after': {
		width: '0.6em',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		transform: ({ isIndeterminate }) => `rotate(${isIndeterminate ? 0 : -45}deg)`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="label-d2S2"]:hover &': {
		/* eslint-disable no-nested-ternary */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderColor: ({ isDisabled, isChecked, isIndeterminate }) =>
			isDisabled
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.disabled')
				: isChecked || isIndeterminate
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.B300
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.N60,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isDisabled, isChecked, isIndeterminate }) =>
			isDisabled
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.disabled')
				: isChecked || isIndeterminate
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.B300
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.input.hovered'),
		/* eslint-enable no-nested-ternary */
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="label-d2S2"]:active &': {
		/* eslint-disable no-nested-ternary */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderColor: ({ isDisabled, isChecked, isIndeterminate }) =>
			isDisabled
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.disabled')
				: isChecked || isIndeterminate
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.B400
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.B50,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isDisabled, isChecked, isIndeterminate }) =>
			isDisabled
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.disabled')
				: isChecked || isIndeterminate
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.B400
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						colors.B50,
		/* eslint-enable no-nested-ternary */
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="label-d2S2"]:focus &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderColor: ({ isDisabled }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isDisabled ? token('color.background.disabled') : colors.B200,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxText = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '0.4em',
});
