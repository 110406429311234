import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import type { Props } from './types.tsx';

export function FilterText({ text }: Props) {
	return (
		<EllipsedWithTooltip content={text}>
			<Box xcss={textStyles}>{text}</Box>
		</EllipsedWithTooltip>
	);
}

const textStyles = xcss({
	fontWeight: 'font.weight.bold',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
