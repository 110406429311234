import type {
	VersionValues,
	Issue as ApiIssue,
	PlannedCapacity,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type {
	ENTITY,
	ScenarioType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';
import type { CrossProjectVersion } from '../../state/domain/cross-project-versions/types.tsx';
import type { OriginalCrossProjectVersion } from '../../state/domain/original-cross-project-versions/types.tsx';
import type { OriginalPlannedCapacity } from '../../state/domain/original-planned-capacities/types.tsx';
import type { OriginalVersion } from '../../state/domain/original-versions/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { DateFormat } from '../../state/domain/system/types.tsx';
import type { VersionMetaInformation } from '../../state/domain/versions/types.tsx';
import type { SprintWithVelocities } from '../sprints/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CommitProgressStats } from '../../state/domain/update-jira/commit/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { RevertProgressStats } from '../../state/domain/update-jira/revert/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SortBy, SortDirection } from '../../state/ui/top/title-bar/update-jira/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	EntityMetadata,
	ChangeMetadata,
	ApiScenarioType,
} from '../../state/domain/update-jira/changes/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	SelectedChanges,
	ExpandedChanges,
} from '../../state/ui/top/title-bar/update-jira/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Entity, ScenarioType } from '../../../common/view/constant';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Issue as ApiIssue } from '../../../common/api/types';

export type LastModified = {
	date: number;
	personId: string;
	dateFormat: DateFormat;
	title?: string;
	avatarUrl?: string;
};

type BaseChange = {
	attributeName?: string | null | undefined;
	changeCount: number;
	id: string;
	lastModified?: LastModified;
	type: ScenarioType;
	warnings: Warning[];
};

export type TeamAndResourceDetails = {
	changes: {
		category: typeof ENTITY.TEAM | typeof ENTITY.RESOURCE;
		attributeName: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		originalValue: any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		currentValue: any;
		resourceId?: string;
		personTitle?: string;
	}[];
	title: {
		name: string;
		avatarUrl: string;
		verified: boolean;
	};
};

export type TeamAndResourceChange = BaseChange & {
	category: typeof ENTITY.TEAM | typeof ENTITY.RESOURCE;
	details: TeamAndResourceDetails;
};

export type IssueChange = BaseChange & {
	category: typeof ENTITY.ISSUE;
	details: ApiIssue;
};

export type VersionDetails = VersionMetaInformation & {
	projects: Project[];
	values: Readonly<VersionValues>;
	originals: OriginalVersion;
};

type VersionChange = BaseChange & {
	category: typeof ENTITY.RELEASE;
	details: VersionDetails;
};

export type CrossProjectVersionChangeDetails = {
	values: CrossProjectVersion;
	originals: OriginalCrossProjectVersion;
};

type CrossProjectVersionChange = BaseChange & {
	category: typeof ENTITY.CROSS_PROJECT_RELEASE;
	details: CrossProjectVersionChangeDetails;
};

type PlannedCapacityChangeDetails = {
	values: PlannedCapacity;
	originals: OriginalPlannedCapacity;
};

export type PlannedCapacityChange = BaseChange & {
	category: typeof ENTITY.PLANNED_CAPACITY;
	details: PlannedCapacityChangeDetails;
};

type SprintChangeDetails = {
	values: SprintWithVelocities;
	originals: Partial<SprintWithVelocities>; // Placeholder. When we do JPOS-4169 we will have an OriginalSprint type
};

export type SprintChange = BaseChange & {
	category: typeof ENTITY.SPRINT;
	details: SprintChangeDetails;
};

export function isTeamAndResourceDetails(change: Change): change is TeamAndResourceChange {
	return true;
}

export type Change =
	| IssueChange
	| VersionChange
	| CrossProjectVersionChange
	| TeamAndResourceChange
	| SprintChange
	| PlannedCapacityChange;
