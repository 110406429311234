import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	getIncomingLinksPure,
	getOutgoingLinksPure,
	getIssueLinks,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import type { OwnProps, ParamsProps, StateProps } from './types.tsx';

export const getLinks = createSelector(
	[
		getIssueLinks,
		(_: State, { rawValues }: ParamsProps) => rawValues,
		(_: State, { isIncoming }: ParamsProps) => isIncoming,
	],
	(issueLinks, rawValues, isIncoming) => {
		const groupLinks = pick(issueLinks, rawValues);
		const directedLinks = isIncoming
			? getIncomingLinksPure(groupLinks)
			: getOutgoingLinksPure(groupLinks);
		return Object.values(directedLinks).flat();
	},
);

const getCount = createSelector(
	[getLinks, (_: State, { isIncoming }: ParamsProps) => isIncoming],
	(links, isIncoming) => {
		const otherIssueKeys = links.map(({ sourceItemKey, targetItemKey }) =>
			isIncoming ? sourceItemKey : targetItemKey,
		);

		return uniq(otherIssueKeys).length;
	},
);

const mapStateToProps = createStructuredSelector<State, OwnProps & ParamsProps, StateProps>({
	count: getCount,
});

export default mapStateToProps;
