import React, { useCallback, useState } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/new';
import {
	DropdownItemGroup as DropMenuItemGroup,
	type CustomTriggerProps,
} from '@atlaskit/dropdown-menu';
import { Box, xcss } from '@atlaskit/primitives';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { useIntl } from '@atlassian/jira-intl';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { DroplistGroup } from '../common/types/droplist-group.tsx';
import type { DroplistItem } from '../common/types/droplist-item.tsx';
import DropMenu, { DropMenuItem } from '../drop-menu/index.tsx';
import type { Props, State } from './types.tsx';
import messages from './messages.tsx';
import { DropdownMenuLegacy } from './index-legacy.tsx';

export const DropdownMenu = ({
	defaultValue,
	defaultOpen,
	isLoading = false,
	placement = 'bottom-start',
	testId = '',
	onChange = noop,
	footerContent,
	placeholder,
	groups = [],
	ariaLabel,
	CustomTriggerComponent,
}: Props) => {
	const [selectedItem, setSelectedItem] = useState<State['selectedItem']>(defaultValue);

	const { formatMessage } = useIntl();

	const handleClick = (item: DroplistItem, group: DroplistGroup, e: Event) => {
		setSelectedItem(item);

		if (onChange) {
			onChange(item, group, e);
		}
	};

	const createGroupElement = (group: DroplistGroup) => {
		const itemElements = group.items.map((item) => (
			<DropMenuItem
				key={item.id}
				onClick={(e: Event) => handleClick(item, group, e)}
				title={item.tooltipDescription}
				{...item}
			>
				{item.content}
			</DropMenuItem>
		));

		return (
			<DropMenuItemGroup key={group.id} title={group.heading}>
				{itemElements}
			</DropMenuItemGroup>
		);
	};

	const buttonContent = selectedItem
		? selectedItem.content
		: placeholder ?? formatMessage(messages.defaultPlaceholder);

	const renderTrigger = useCallback(
		(triggerProps: CustomTriggerProps) => {
			const { triggerRef, ...restTriggerProps } = triggerProps;
			return CustomTriggerComponent ? (
				<CustomTriggerComponent
					triggerProps={triggerProps}
					content={buttonContent}
					ariaLabel={ariaLabel}
					testId={testId}
				/>
			) : (
				<Button
					{...restTriggerProps}
					ref={triggerRef}
					iconAfter={ChevronDownIcon}
					aria-label={ariaLabel}
				>
					{buttonContent}
				</Button>
			);
		},
		[CustomTriggerComponent, ariaLabel, buttonContent, testId],
	);

	return (
		<DropMenu
			defaultOpen={defaultOpen}
			isLoading={isLoading}
			placement={placement}
			testId={testId}
			trigger={renderTrigger}
		>
			{groups.map((group) => createGroupElement(group))}
			{footerContent && <Box xcss={FooterStyles}>{footerContent}</Box>}
		</DropMenu>
	);
};

export default componentWithFG(
	'portfolio-3-dropdown-menu-update',
	DropdownMenu,
	DropdownMenuLegacy,
);

const FooterStyles = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: 'border.width',
	position: 'sticky',
	backgroundColor: 'elevation.surface.overlay',
	bottom: 'space.0',
});
