import React, { Component, createRef, useEffect } from 'react';
import ButtonGroup from '@atlaskit/button/button-group';
import { Label, Field } from '@atlaskit/form';
import OfficeBuildingFilledIcon from '@atlaskit/icon/core/migration/office-building--office-building-filled';
import { ModalHeader, ModalBody, ModalTitle, ModalFooter } from '@atlaskit/modal-dialog';
import { Radio } from '@atlaskit/radio';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	FormatOptionLabelMeta,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import AsyncSmartUserPicker from '@atlassian/jira-portfolio-3-common/src/user-picker/async.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import type { TeamPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import { CREATE_MODE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/teams/list/actions.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { ModalDialog } from '@atlassian/jira-software-modal-dialog/src/ui/modal-dialog/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import atlassianHome from './atlassian-home.svg';
import CreateTWPTeamForm from './create-twp-team-form/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import TeamForm from './team-form/index.tsx';
import type { SubmitCreation, SubmitEdition } from './team-form/types.tsx';
import TWPSuccessMessage from './twp-success-message/index.tsx';
import {
	EXISTING_TEAM,
	NEW_TEAM,
	LANDING_STEP,
	CREATE_COMPANY_SCOPED_TEAM,
	SUCCESS_CONFIRMATION,
	TEAM_SETTINGS,
	type AtlassianTeamOption,
	type FormType,
	type FormStep,
	type Props,
	type PropsWithIntl,
	type State,
} from './types.tsx';

export const filterAtlassianTeamsOptions =
	(existingTeams: Team[]) =>
	(atlassianTeamOptions: AtlassianTeamOption[]): AtlassianTeamOption[] =>
		atlassianTeamOptions.filter(
			(atlassianTeam: AtlassianTeamOption) =>
				!existingTeams.some((existingTeam: Team) => existingTeam.externalId === atlassianTeam.id),
		);

export const toNumber = (value: number | null | undefined | string): number | null | undefined => {
	if (value === '') {
		return null;
	}
	if (typeof value === 'string') {
		return +value;
	}
	return value;
};

// request to include icon in atlaskit
// https://ecosystem.atlassian.net/jira/servicedesk/projects/DS/queues/issue/DS-7743
// https://jdog.jira-dev.com/browse/JPO-18296
const PlansIcon = () => (
	<svg height={32} width={32} fill="none" viewBox="0 0 32 32">
		<path
			fill="currentColor"
			d="M10 8a1 1 0 000 2h4a1 1 0 100-2h-4zM10 12a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zM10 14a1 1 0 100 2h2a1 1 0 100-2h-2z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M8 4a2 2 0 012-2h4a2 2 0 012 2h1a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V6a2 2 0 012-2h1zm6 3a2 2 0 001.732-1H17v12H7V6h1.268A2 2 0 0010 7h4zm0-3h-4v1h4V4z"
			clipRule="evenodd"
		/>
	</svg>
);

// eslint-disable-next-line jira/react/no-class-components
export class TeamDialog extends Component<PropsWithIntl, State> {
	// Remove when cleaning up r18_strict_mode_team_dialog
	// eslint-disable-next-line react/sort-comp
	modalFooterPortalRef = createRef<HTMLDivElement>();

	state: State = {
		formSelected: EXISTING_TEAM,
		selectedAtlassianTeam: null,
		selectedTeamScope: 'availableAcrossOrganisation',
		teamFormStep: LANDING_STEP,
		modalFooterDivElement: null,
	};

	componentDidMount() {
		// Cannot move setting the teamFormStep state into constructor
		// as it causes issue on rendering the team form footer
		if (!this.isCreatingMode()) {
			this.setState({
				teamFormStep: TEAM_SETTINGS,
			});

			// In open sites, users might not be allowed to view and/or create Atlassian teams. Therefore, we skip
			// the landing step and directly display the plan-only team creation form if the user is not allowed to create Atlassian teams.
			// Note: this logic might change in the future - a user might be allowed to view Atlassian teams but not create any (or vice-versa).
			// When/if this happens, we may need to reconsider the skip to plan-only form, and allow the user to:
			// * add an existing Atlassian team to a plan if he/she can view Atlassian teams but not create any
			// * create an Atlassian team from a plan if he/she can create Atlassian teams but not view any
			// in the landing step of the team add/create flow
		} else if (
			isDefined(this.props.teamPermissions.canCreateTeams) &&
			!this.props.teamPermissions.canCreateTeams
		) {
			this.setState({
				selectedTeamScope: 'planOnly',
				teamFormStep: TEAM_SETTINGS,
			});
		}
	}

	componentDidUpdate(prevProps: PropsWithIntl) {
		// Remove when cleaning up r18_strict_mode_team_dialog START
		if (
			this.modalFooterPortalRef &&
			this.modalFooterPortalRef.current &&
			!this.state.modalFooterDivElement
		) {
			this.setState({
				modalFooterDivElement: this.modalFooterPortalRef.current,
			});
		}
		// Remove when cleaning up r18_strict_mode_team_dialog END
		const isProcessingRequestChanged =
			isDefined(this.props.isProcessingRequest) &&
			!this.props.isProcessingRequest &&
			isDefined(prevProps.isProcessingRequest) &&
			prevProps.isProcessingRequest;
		const steps: FormStep[] = [LANDING_STEP, CREATE_COMPANY_SCOPED_TEAM];

		if (
			steps.includes(this.state.teamFormStep) &&
			isProcessingRequestChanged &&
			this.props.addedTWPInfo &&
			this.props.addedTWPInfo.teamId &&
			this.props.addedTWPInfo.teamTitle
		) {
			this.props.setEditMode({
				teamId: this.props.addedTWPInfo.teamId,
				title: this.props.addedTWPInfo.teamTitle,
			});
			this.goToNextStep();
		}
	}

	submitCreation: SubmitCreation = ({ iterationLength, weeklyHours, velocity, ...fields }) => {
		traceUFOPress(KEYS.CREATE_TEAM);
		this.props.createTeam({
			...fields,
			iterationLength: toNumber(iterationLength),
			weeklyHours: toNumber(weeklyHours),
			velocity: toNumber(velocity),
		});
	};

	submitEdition: SubmitEdition = (
		{ iterationLength, weeklyHours, velocity, ...payload },
		enhancedTeam,
	) => {
		const { editTeam, editedTeam } = this.props;

		if (isDefined(editedTeam) && isDefined(editedTeam.teamId)) {
			const formFields: TeamPayload = {
				iterationLength: toNumber(iterationLength),
				weeklyHours: toNumber(weeklyHours),
				velocity: toNumber(velocity),
				...payload,
			};
			traceUFOPress(KEYS.UPDATE_TEAM);
			editTeam({
				formFields,
				enhancedTeam,
				teamId: editedTeam.teamId,
			});
		}
	};

	handleChange = (selectedForm: FormType, form: FormType) => {
		if (selectedForm !== form) {
			this.setState({
				formSelected: form,
			});
		}
	};

	renderRadioSelect = (selectedForm: FormType, form: FormType) => {
		const {
			intl: { formatMessage },
		} = this.props;

		const radioLabel = form === NEW_TEAM ? 'createANewTeamRadioLabel' : 'addExistingTeamRadioLabel';

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.radioSelect}>
				<Radio
					isChecked={selectedForm === form}
					label={formatMessage(messages[radioLabel])}
					name="standalone"
					onChange={() => {
						this.handleChange(selectedForm, form);
					}}
					testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.${
						form === NEW_TEAM ? 'create-new-team' : 'add-existing-team'
					}`}
					value="single-radio"
				/>
			</div>
		);
	};

	isCreatingMode = (): boolean => this.props.dialogMode === CREATE_MODE;

	goToNextStep() {
		const { selectedTeamScope, selectedAtlassianTeam, teamFormStep, formSelected } = this.state;

		const createNewTeamNextStep =
			selectedTeamScope === 'availableAcrossOrganisation'
				? CREATE_COMPANY_SCOPED_TEAM
				: TEAM_SETTINGS;

		switch (teamFormStep) {
			case LANDING_STEP:
				return this.setState({
					teamFormStep:
						formSelected === EXISTING_TEAM && selectedAtlassianTeam
							? SUCCESS_CONFIRMATION
							: createNewTeamNextStep,
				});
			case CREATE_COMPANY_SCOPED_TEAM:
				return this.setState({
					teamFormStep: SUCCESS_CONFIRMATION,
				});
			case SUCCESS_CONFIRMATION:
				return this.setState({
					teamFormStep: TEAM_SETTINGS,
				});
			default:
				break;
		}
	}

	isCreateTwpFormLoaded() {
		const { teamFormStep } = this.state;
		const { isCreateTwpFormLoading } = this.props;
		return teamFormStep === CREATE_COMPANY_SCOPED_TEAM && !isCreateTwpFormLoading;
	}

	getCreateANewTeamSelectOptions = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		return [
			{
				description: formatMessage(messages.planOnlyLimitedTeamHelpText),
				icon: <PlansIcon />,
				label: formatMessage(messages.planOnlyTeam),
				value: 'planOnly',
			},
			{
				description: formatMessage(messages.availableAcrossOrgTeamHelpText),
				icon: (
					<OfficeBuildingFilledIcon
						spacing="spacious"
						label={formatMessage(messages.availableAcrossOrgTeam)}
					/>
				),
				label: formatMessage(messages.availableAcrossOrgTeam),
				value: 'availableAcrossOrganisation',
			},
		];
	};

	renderTeamTypeOption = (
		{ description, icon, label }: OptionType,
		{ context }: FormatOptionLabelMeta,
	) => {
		if (context === 'menu') {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.teamTypeOption}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.teamTypeOptionIcon}>{icon}</div>
					<div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.teamTypeOptionTitle}>{label}</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.teamTypeOptionDescription}>{description}</div>
					</div>
				</div>
			);
		}
		return label;
	};

	/**
	 * This is the landing step of the "Add a team to your plan" dialog where users can choose to:
	 * - Add an existing team from [org name]
	 * - Create a new team
	 *    * Plan-only
	 *    * Available across [org name]
	 */
	renderAddFormLandingStep = () => {
		const {
			cloudId,
			intl: { formatMessage },
			orgId,
			teams,
		} = this.props;
		const { formSelected, selectedAtlassianTeam } = this.state;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.addTeamDialogBody}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.imageIllustrationPanel}>
					<img src={atlassianHome} alt="" />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.teamSelectionPanel}>
					{formatMessage(messages.addTeamToPlanDescription)}
					{this.renderRadioSelect(formSelected, EXISTING_TEAM)}
					{formSelected === EXISTING_TEAM && (
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={styles.teamField}
							data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.twp-team-picker-field"
						>
							<Field name="atlassianTeam">
								{({ fieldProps }) => (
									<>
										<Label htmlFor="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-add-existing-team-select-field">
											{formatMessage(messages.selectAnExistingTeamLabel)}
										</Label>
										<AsyncSmartUserPicker
											{...fieldProps}
											debounceTime={400}
											fieldId="team"
											// @ts-expect-error - TS2322 - Type '(atlassianTeamOptions: AtlassianTeamOption[]) => AtlassianTeamOption[]' is not assignable to type 'FilterOptions'.
											filterOptions={filterAtlassianTeamsOptions(teams)}
											includeGroups={false}
											includeTeams
											includeUsers={false}
											menuPlacement="auto"
											menuPortalTarget={getBody()}
											onChange={(atlassianTeam) => {
												this.setState({
													// @ts-expect-error - TS2322 - Type 'Value' is not assignable to type 'AtlassianTeamOption | null | undefined'.
													selectedAtlassianTeam: isDefined(atlassianTeam)
														? atlassianTeam
														: /**
															 * when the user clears the UserPicker, "atlassianTeam" will be undefined, but we must set
															 * the value of the user picker to "null" instead of "undefined", otherwise the UserPicker
															 * does not get cleared.
															 * This boils down to the fact that when the value is "undefined", the UserPicker will control the value internally.
															 */
															null,
												});
											}}
											placeholder={formatMessage(messages.selectOrEnterTeamNamePlaceholder)}
											productKey="jira"
											siteId={cloudId}
											orgId={orgId}
											styles={{
												menuPortal: (base) => ({
													...base,
													zIndex: 9999,
												}),
												// These are overrides for @atlaskit/select@15.6 -> react-select@4.3.x
												// Once @atlaskit/select@15.6 resolution has been removed from package.json
												// these should be removed as well. See PTC-6238.
												placeholder: (base) => ({
													...base,
													position: 'unset',
													top: '',
													left: '',
													transform: '',
												}),
												valueContainer: (base) => ({
													...base,
													display: 'grid',
												}),
											}}
											// @ts-expect-error - Type 'string' is not assignable to type '"team" | "group" | "email" | "user" | undefined'.
											value={selectedAtlassianTeam}
											inputId="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-add-existing-team-select-field"
										/>
									</>
								)}
							</Field>
						</div>
					)}
					{this.renderRadioSelect(formSelected, NEW_TEAM)}
					{formSelected === NEW_TEAM && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.teamField}>
							<Field name="teamScope">
								{({ fieldProps }) => (
									<>
										<Label htmlFor="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-create-a-new-team-select-field">
											{formatMessage(messages.selectNewTeamType)}
										</Label>
										{/* @ts-expect-error - TS2322 - Type '{ classNamePrefix: string; defaultValue: { readonly description: string; readonly icon: Element; readonly label: string; readonly value: "availableAcrossOrganisation"; }; formatOptionLabel: ({ description, icon, label }: OptionType, { context }: FormatOptionLabelMeta) => any; ... 15 more ...; 'aria-labelledby': stri...' is not assignable to type 'Readonly<SelectProps<{ readonly description: string; readonly icon: Element; readonly label: string; readonly value: "planOnly"; } | { readonly description: string; readonly icon: Element; readonly label: string; readonly value: "availableAcrossOrganisation"; }, false>>'. */}
										<AkSelect
											{...fieldProps}
											classNamePrefix="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-dialog_team-scope"
											defaultValue={this.getCreateANewTeamSelectOptions()[1]}
											formatOptionLabel={this.renderTeamTypeOption}
											isSearchable={false}
											menuPlacement="auto"
											menuPortalTarget={getBody()}
											onChange={(selectedTeamScope: OptionType | null) => {
												this.setState({
													// @ts-expect-error - TS2531 - Object is possibly 'null'.
													selectedTeamScope: selectedTeamScope.value,
												});
											}}
											options={this.getCreateANewTeamSelectOptions()}
											styles={{
												/**
												 * we set the width to 350px to align with the default width of the Atlassian Team picker.
												 * that prevents the right pane of the modal dialog to move left and right
												 * when switching between adding an existing team or creating a new team
												 */
												// eslint-disable-next-line @typescript-eslint/no-shadow
												control: (styles) => ({
													...styles,
													width: '350px',
												}),
												menuPortal: (base) => ({
													...base,
													zIndex: 9999,
												}),
											}}
											inputId="portfolio-3-portfolio_app-simple-plans_main_tabs_teams_list_team-create-a-new-team-select-field"
										/>
									</>
								)}
							</Field>
						</div>
					)}
				</div>
			</div>
		);
	};

	renderLandingStepFooter = () => {
		const {
			intl: { formatMessage },
			isProcessingRequest,
			onAddAtlassianTeam,
			onClose,
			isCreateTwpFormLoading,
		} = this.props;
		const { formSelected, selectedAtlassianTeam, selectedTeamScope } = this.state;
		const isLoading = isDefined(isProcessingRequest) ? isProcessingRequest : false;

		return (
			<ButtonGroup>
				<Button appearance="subtle" onClick={onClose}>
					{formatMessage(commonMessages.cancel)}
				</Button>
				<Button
					appearance="primary"
					isDisabled={
						(formSelected === EXISTING_TEAM && !isDefined(selectedAtlassianTeam)) ||
						(formSelected === NEW_TEAM && selectedTeamScope === '')
					}
					isLoading={isLoading || isCreateTwpFormLoading}
					onClick={(e, analyticsEvent) => {
						if (formSelected === EXISTING_TEAM && isDefined(selectedAtlassianTeam)) {
							onAddAtlassianTeam({ id: selectedAtlassianTeam.id });

							// Fire analytics event
							const [actionSubject, action] =
								PRODUCT_ANALYTICS_EVENT_NAMES.ATLAS_TEAM_ADDED.split(' ');
							fireUIAnalytics(analyticsEvent.update({ action, actionSubject }));
						} else if (formSelected === NEW_TEAM) {
							this.goToNextStep();
						}
					}}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.next"
				>
					{formatMessage(
						formSelected === EXISTING_TEAM ? messages.addTeamButton : commonMessages.next,
					)}
				</Button>
			</ButtonGroup>
		);
	};

	getFormFooter = () => {
		const {
			intl: { formatMessage },
			onClose,
		} = this.props;
		const { teamFormStep } = this.state;

		switch (teamFormStep) {
			case TEAM_SETTINGS:
				return fg('r18_strict_mode_team_dialog') ? null : (
					<ModalFooter>
						<div id="modalFooterPortal" ref={this.modalFooterPortalRef} />
					</ModalFooter>
				);
			case SUCCESS_CONFIRMATION:
				return (
					<ModalFooter>
						<ButtonGroup>
							<Button appearance="subtle" onClick={onClose}>
								{formatMessage(messages.labelForViewPlanBtn)}
							</Button>
							<Button
								appearance="primary"
								onClick={() => {
									this.goToNextStep();
								}}
							>
								{formatMessage(messages.labelForConfigTeamBtn)}
							</Button>
						</ButtonGroup>
					</ModalFooter>
				);
			case LANDING_STEP:
			case CREATE_COMPANY_SCOPED_TEAM:
			default:
				return (
					!this.isCreateTwpFormLoaded() && (
						<ModalFooter>{this.renderLandingStepFooter()} </ModalFooter>
					)
				);
		}
	};

	getTeam = () => {
		const { editedTeam, getTeamById, addedTWPInfo } = this.props;
		const twpTeamId = addedTWPInfo ? addedTWPInfo.teamId : null;
		const teamId = editedTeam ? editedTeam.teamId : twpTeamId;
		return isDefined(teamId) ? getTeamById(teamId) : null;
	};

	getFormHeading = () => {
		const {
			intl: { formatMessage },
			teamDialogHeading,
		} = this.props;
		const { teamFormStep } = this.state;

		let heading = null;

		switch (teamFormStep) {
			case TEAM_SETTINGS:
				heading = formatMessage(
					this.isCreatingMode() ? messages.createPlanOnlyTeam : commonMessages.teamSettings,
				);
				break;
			case SUCCESS_CONFIRMATION:
				heading = '';
				break;
			case LANDING_STEP:
			case CREATE_COMPANY_SCOPED_TEAM:
			default:
				heading = this.isCreateTwpFormLoaded()
					? null
					: formatMessage(messages.addTeamToPlanHeading);
				break;
		}

		if (teamDialogHeading) {
			heading = formatMessage(teamDialogHeading);
		}
		if (isDefined(heading)) {
			return (
				<ModalHeader>
					<ModalTitle>{heading}</ModalTitle>
				</ModalHeader>
			);
		}
	};

	getFormWidth = () => {
		const { teamFormStep } = this.state;

		switch (teamFormStep) {
			case TEAM_SETTINGS:
				return 'medium';
			default:
				return 'large';
		}
	};

	getFormHeight = () => {
		const { teamFormStep } = this.state;
		switch (teamFormStep) {
			case SUCCESS_CONFIRMATION:
				// match the height of create TWP team form
				return 455;
			default:
				return undefined;
		}
	};

	getFormContent = () => {
		const { dialogMode, onClose, addedTWPInfo, teamDialogSubheading, teamDialogBody } = this.props;
		// Remove modalFooterDivElement when cleaning up r18_strict_mode_team_dialog
		const { teamFormStep, modalFooterDivElement } = this.state;
		const team = this.getTeam();

		const landingPage = this.renderAddFormLandingStep();

		switch (teamFormStep) {
			case TEAM_SETTINGS:
				return (
					<ModalBody>
						<TeamForm
							formBody={teamDialogBody}
							formHeading={teamDialogSubheading}
							dialogMode={dialogMode}
							onCloseForm={onClose}
							onSubmitForm={this.isCreatingMode() ? this.submitCreation : this.submitEdition}
							team={this.isCreatingMode() ? null : team}
							modalFooterDivElement={modalFooterDivElement}
						/>
					</ModalBody>
				);
			case SUCCESS_CONFIRMATION:
				return (
					<ModalBody>
						{/* Fallback value was required because getAddedTWPInfo could return undefined teamTitle */}
						<TWPSuccessMessage teamTitle={addedTWPInfo.teamTitle || ''} />
					</ModalBody>
				);
			case LANDING_STEP:
			case CREATE_COMPANY_SCOPED_TEAM:
			default:
				return (
					<>
						{!this.isCreateTwpFormLoaded() && <ModalBody>{landingPage}</ModalBody>}
						{teamFormStep === CREATE_COMPANY_SCOPED_TEAM && <CreateTWPTeamForm onClose={onClose} />}
					</>
				);
		}
	};

	render() {
		const { isProcessingRequest, onClose } = this.props;
		return (
			<ModalDialog
				messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.modal-dialog"
				messageType="transactional"
				autoFocus
				onClose={onClose}
				shouldCloseOnEscapePress={!isProcessingRequest}
				shouldCloseOnOverlayClick={false}
				width={this.getFormWidth()}
				height={this.getFormHeight()}
				shouldScrollInViewport
			>
				{this.getFormHeading()}
				{this.getFormContent()}
				{this.getFormFooter()}
			</ModalDialog>
		);
	}
}

const TeamDialogWithOrgId = (props: Props) => {
	const { data } = useOrgId();
	const intl = useIntl();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { editedTeam } = props;

	useEffect(() => {
		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.TEAM_DIALOG_OPEN.split(' ');

		const attributes = {
			recommendedSchedulingMode: editedTeam?.recommendedSchedulingMode,
		};

		fireUIAnalytics(
			createAnalyticsEvent({
				action,
				actionSubject,
			}),
			attributes,
		);

		return () => {
			const [actionSubjectClose, actionClose] =
				PRODUCT_ANALYTICS_EVENT_NAMES.TEAM_DIALOG_CLOSE.split(' ');
			fireUIAnalytics(
				createAnalyticsEvent({
					action: actionClose,
					actionSubject: actionSubjectClose,
				}),
				attributes,
			);
		};
	}, [createAnalyticsEvent, editedTeam?.recommendedSchedulingMode]);

	return <TeamDialog {...props} cloudId={cloudId} orgId={data} intl={intl} />;
};

export default TeamDialogWithOrgId;
