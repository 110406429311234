import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type MoreTagContentProps = {
	count: number;
};

export const MoreTag = forwardRef<HTMLDivElement, MoreTagContentProps>(({ count }, ref) => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={wrapperStyles} data-component-selector="more-tag-wrapper-93Qc" ref={ref}>
			<MoreTagContainer data-component-selector="more-tag-container-327a">
				{formatMessage(messages.optionsCount, { count })}
			</MoreTagContainer>
		</Box>
	);
});

const wrapperStyles = xcss({
	position: 'absolute',
	right: '0',
	bottom: 'space.050',
	background: `linear-gradient(270deg, ${token('elevation.surface')}, transparent)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const MoreTagContainer = styled.div({
	borderRadius: '3px',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),

	backgroundColor: token('color.background.accent.gray.subtler'),

	boxShadow: `-2px 0px 8px 4px ${token('elevation.surface')}`,

	color: token('color.text'),
	clipPath: 'inset(0px 0px 0px -12px)',
	whiteSpace: 'nowrap',
});
