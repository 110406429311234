// Note: We use block comment at top of the file because we get strange error in `SSR Image Build`
/* eslint-disable @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-dynamic-styles -- To migrate as part of go/ui-styling-standard */

import React, { Component } from 'react';
import { styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import ChartModal from '../chart-modal/index.tsx';
import Content from '../content/index.tsx';
import type { CommonProps } from './types.tsx';

export type APIProps = {
	position: {
		top: number;
		left: number;
	};
};

type Props = CommonProps & APIProps;

// eslint-disable-next-line jira/react/no-class-components
export class DependencyModal extends Component<Props> {
	componentDidUpdate() {
		const { links, onClose } = this.props;

		// close modal if all dependencies were removed
		if (links.length === 0) {
			onClose();
		}
	}

	render() {
		const { position, ...contentProps } = this.props;

		if (!fg('plan-timeline-non-transposed')) {
			return (
				<Popup
					isOpen
					strategy="absolute"
					onClose={contentProps.onClose}
					placement="bottom-start"
					content={() => <Content {...contentProps} />}
					trigger={({ ref }) => <Anchor top={position.top} left={position.left} ref={ref} />}
					testId="portfolio-3-dependency-line-detail.common.ui.modal.portfolio-dependency-line-detail-popup"
					messageId="portfolio-3-dependency-line-detail.common.ui.modal.popup"
					messageType="transactional"
				/>
			);
		}

		// Considering removing the chart modal component when cleaning plan-timeline-non-transposed
		return (
			<ChartModal
				position={position}
				content={<Content {...contentProps} onClose={contentProps.onClose} />}
				onClose={contentProps.onClose}
			/>
		);
	}
}

export default AnalyticsEventToProps('tooltip', {
	onUnlink: 'unlinked',
})(DependencyModal);

const Anchor = styled.div<{ top: number; left: number }>({
	position: 'fixed',
	left: ({ left }) => `${left}px`,
	top: ({ top }) => `${top}px`,
});
