/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import {
	DropdownItemGroup as DropMenuItemGroup,
	DropdownItem,
	DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';

import {
	RANK_BEFORE,
	RANK_AFTER,
	type Relation,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useVirtualized } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/virtualized/index.tsx';
import {
	useGridActions,
	useGridRef,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import { getIsReturningFocus } from '../../utils.tsx';
import messages from './messages.tsx';
import type { MoveType, Props, RankAnchor } from './types.tsx';
import { getRankAnchors } from './utils.tsx';

const RankIssueMenuGroup = ({
	bulkUpdateRank,
	rankIssue,
	tableItems,
	sortedIssues,
	issueId,
	issueLevel,
	issueGroup,
	parentId,
	isSelected,
	selectedIssueCount,
	onOptionSelect,
}: Props) => {
	const { formatMessage } = useIntl();
	const virtualized = useVirtualized();
	const { setFocusVisible, setFocusPosition } = useGridActions();
	const [gridRef] = useGridRef();
	const { row: issueRow } = useRow();

	const { anchors: rankAnchors } = useMemo(
		() => getRankAnchors({ issueRow, issueId, issueGroup, issueLevel, sortedIssues, tableItems }),
		[issueGroup, issueId, issueLevel, issueRow, sortedIssues, tableItems],
	);

	const currentIssueAnchor = useMemo(
		() => ({ id: issueId, rowIndex: issueRow }),
		[issueId, issueRow],
	);

	const hasMoveUpActions = rankAnchors.top;
	const hasMoveDownActions = rankAnchors.bottom;
	const hasMoveActions = !!hasMoveUpActions || !!hasMoveDownActions;

	const hasSelectedIssues = (selectedIssueCount && selectedIssueCount > 0) || false;
	const hasRelativeRankingActions = !isSelected && hasSelectedIssues;
	const hasAbsoluteRankingActions = isSelected && hasSelectedIssues;
	const hasRankingActions = hasRelativeRankingActions || hasAbsoluteRankingActions;

	const handleMoveOption = useCallback(
		(
			e: React.MouseEvent | React.KeyboardEvent,
			analyticsEvent: UIAnalyticsEvent,
			{
				anchor,
				relation,
				moveType,
				isBulkAction = false,
			}: {
				anchor?: RankAnchor;
				relation: Relation;
				moveType: MoveType;
				isBulkAction?: boolean;
			},
		) => {
			onOptionSelect?.(e, true, gridRef.current);
			const isReturningFocus = getIsReturningFocus(e);

			if (isBulkAction) {
				bulkUpdateRank({ operationType: relation, anchor: anchor?.id });
			} else {
				// Set to required on FG cleanup plan-timeline-a11y-meatball-menu
				rankIssue?.({
					id: issueId,
					relation,
					anchor: anchor?.id,
					parentId,
					group: issueGroup,
					usingMeatballMenu: true,
				});
			}

			fireUIAnalytics(analyticsEvent, 'issueMeatballRank', { moveType, isBulkAction });

			// Check whether new anchor row index is within virtual window
			// If not, focus the grid cell that was actioned on (will be a different issue)
			// If row index is within virtual window, do nothing
			if (anchor && isReturningFocus && !virtualized.includes(anchor.rowIndex)) {
				setFocusPosition(issueRow, 0);
				setFocusVisible(true);
			}
		},
		[
			bulkUpdateRank,
			gridRef,
			issueGroup,
			issueId,
			issueRow,
			onOptionSelect,
			parentId,
			rankIssue,
			setFocusPosition,
			setFocusVisible,
			virtualized,
		],
	);

	const onClickMoveToTop = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			handleMoveOption(e, analyticsEvent, {
				relation: 'BEFORE',
				anchor: rankAnchors.top,
				moveType: 'TOP',
			});
		},
		[handleMoveOption, rankAnchors.top],
	);

	const onClickMoveUp = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			handleMoveOption(e, analyticsEvent, {
				relation: 'BEFORE',
				anchor: rankAnchors.above,
				moveType: 'ABOVE',
			});
		},
		[handleMoveOption, rankAnchors.above],
	);

	const onClickMoveDown = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			handleMoveOption(e, analyticsEvent, {
				relation: 'AFTER',
				anchor: rankAnchors.below,
				moveType: 'BELOW',
			});
		},
		[handleMoveOption, rankAnchors.below],
	);

	const onClickMoveToBottom = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			handleMoveOption(e, analyticsEvent, {
				relation: 'AFTER',
				anchor: rankAnchors.bottom,
				moveType: 'BOTTOM',
			});
		},
		[handleMoveOption, rankAnchors.bottom],
	);

	const onClickRankAbove = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			handleMoveOption(e, analyticsEvent, {
				relation: 'BEFORE',
				anchor: currentIssueAnchor,
				moveType: 'ABOVE',
				isBulkAction: true,
			});
		},
		[currentIssueAnchor, handleMoveOption],
	);

	const onClickRankBelow = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			handleMoveOption(e, analyticsEvent, {
				relation: 'AFTER',
				anchor: currentIssueAnchor,
				moveType: 'BELOW',
				isBulkAction: true,
			});
		},
		[currentIssueAnchor, handleMoveOption],
	);

	const onClickRankToTop = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			const topSortedIssue = sortedIssues?.[0];

			// Fallback anchor is used when selected item is at the top of group/hierarchy
			const fallbackAnchor = topSortedIssue
				? { id: topSortedIssue.id, rowIndex: issueRow }
				: undefined;

			handleMoveOption(e, analyticsEvent, {
				relation: 'BEFORE',
				anchor: rankAnchors.top ?? fallbackAnchor,
				moveType: 'TOP',
				isBulkAction: true,
			});
		},
		[issueRow, handleMoveOption, rankAnchors, sortedIssues],
	);

	const onClickRankToBottom = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
			const lastSortedIssue = sortedIssues?.[sortedIssues?.length - 1];

			// Fallback anchor is used when selected item is at the bottom of group/hierarchy
			const fallbackAnchor = lastSortedIssue
				? { id: lastSortedIssue.id, rowIndex: issueRow }
				: undefined;

			handleMoveOption(e, analyticsEvent, {
				relation: 'AFTER',
				anchor: rankAnchors.bottom ?? fallbackAnchor,
				moveType: 'BOTTOM',
				isBulkAction: true,
			});
		},
		[handleMoveOption, issueRow, rankAnchors.bottom, sortedIssues],
	);

	const moveToTopMenuItem = (
		<DropMenuItem onClick={onClickMoveToTop} isDisabled={!hasMoveUpActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-to-top"
			>
				<FormattedMessage {...messages.moveToTop} />
			</Box>
		</DropMenuItem>
	);

	const moveUpMenuItem = (
		<DropMenuItem onClick={onClickMoveUp} isDisabled={!hasMoveUpActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-up"
			>
				<FormattedMessage {...messages.moveUp} />
			</Box>
		</DropMenuItem>
	);

	const moveDownMenuItem = (
		<DropMenuItem onClick={onClickMoveDown} isDisabled={!hasMoveDownActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-down"
			>
				<FormattedMessage {...messages.moveDown} />
			</Box>
		</DropMenuItem>
	);

	const moveToBottomMenuItem = (
		<DropMenuItem onClick={onClickMoveToBottom} isDisabled={!hasMoveDownActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-to-bottom"
			>
				<FormattedMessage {...messages.moveToBottom} />
			</Box>
		</DropMenuItem>
	);

	const rankAboveMenuItem = (
		<DropMenuItem onClick={onClickRankAbove} isDisabled={!hasRelativeRankingActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-above-option"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.rankAllSelectedIssuesAboveIssueTermRefresh
						: messages.rankAllSelectedIssuesAbove)}
					values={{ count: selectedIssueCount ?? 0 }}
				/>
			</Box>
		</DropMenuItem>
	);

	const rankBelowMenuItem = (
		<DropMenuItem onClick={onClickRankBelow} isDisabled={!hasRelativeRankingActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-below-option"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.rankAllSelectedIssuesBelowIssueTermRefresh
						: messages.rankAllSelectedIssuesBelow)}
					values={{ count: selectedIssueCount ?? 0 }}
				/>
			</Box>
		</DropMenuItem>
	);

	const rankToTopMenuItem = (
		<DropMenuItem onClick={onClickRankToTop} isDisabled={!hasAbsoluteRankingActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-to-top-option"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.rankAllSelectedIssuesToTopIssueTermRefresh
						: messages.rankAllSelectedIssuesToTop)}
					values={{ count: selectedIssueCount ?? 0 }}
				/>
			</Box>
		</DropMenuItem>
	);

	const rankToBottomMenuItem = (
		<DropMenuItem onClick={onClickRankToBottom} isDisabled={!hasAbsoluteRankingActions}>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-to-bottom-option"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.rankAllSelectedIssuesToBottomIssueTermRefresh
						: messages.rankAllSelectedIssuesToBottom)}
					values={{ count: selectedIssueCount ?? 0 }}
				/>
			</Box>
		</DropMenuItem>
	);

	const moveThisIssueMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.moveThisIssueLabelIssueTermRefresh
		: messages.moveThisIssueLabel;

	return (
		<>
			<DropMenu
				placement="right-start"
				shouldRenderToParent={false}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-drop-menu"
				trigger={({ triggerRef, ...triggerProps }) => (
					<DropdownItem
						{...triggerProps}
						ref={triggerRef}
						isDisabled={!hasMoveActions}
						elemAfter={<ChevronRightIcon label="" />}
					>
						{formatMessage(hasSelectedIssues ? moveThisIssueMessage : messages.moveLabel)}
					</DropdownItem>
				)}
			>
				<div css={contentWrapperStyles}>
					<DropdownItemGroup>
						{moveUpMenuItem}
						{moveDownMenuItem}
						{moveToTopMenuItem}
						{moveToBottomMenuItem}
					</DropdownItemGroup>
				</div>
			</DropMenu>
			{hasRankingActions ? (
				<DropMenu
					placement="right-start"
					shouldRenderToParent={false}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-drop-menu"
					trigger={({ triggerRef, ...triggerProps }) => (
						<DropdownItem
							{...triggerProps}
							ref={triggerRef}
							elemAfter={<ChevronRightIcon label="" />}
						>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.rankLabelIssueTermRefresh
									: messages.rankLabel,
								{ count: selectedIssueCount ?? 0 },
							)}
						</DropdownItem>
					)}
				>
					<div css={contentWrapperStyles}>
						<DropdownItemGroup>
							{hasRelativeRankingActions && (
								<>
									{rankAboveMenuItem}
									{rankBelowMenuItem}
								</>
							)}
							{hasAbsoluteRankingActions && (
								<>
									{rankToTopMenuItem}
									{rankToBottomMenuItem}
								</>
							)}
						</DropdownItemGroup>
					</div>
				</DropMenu>
			) : null}
		</>
	);
};

const RankIssueMenuGroupOld = ({ bulkUpdateRank, issueId, isSelected }: Props) => {
	const { formatMessage } = useIntl();
	const rankAboveMenuItem = (
		<DropMenuItem
			onClick={() => {
				bulkUpdateRank({ operationType: RANK_BEFORE, anchor: issueId });
			}}
			isDisabled={isSelected}
		>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-above-option"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.rankAllSelectedIssuesAboveOldIssueTermRefresh
						: messages.rankAllSelectedIssuesAboveOld)}
				/>
			</Box>
		</DropMenuItem>
	);
	const rankBelowMenuItem = (
		<DropMenuItem
			onClick={() => {
				bulkUpdateRank({ operationType: RANK_AFTER, anchor: issueId });
			}}
			isDisabled={isSelected}
		>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-below-option"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.rankAllSelectedIssuesBelowOldIssueTermRefresh
						: messages.rankAllSelectedIssuesBelowOld)}
				/>
			</Box>
		</DropMenuItem>
	);
	return (
		<DropMenuItemGroup>
			{isSelected ? (
				<Tooltip
					content={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.noRankItselfIssueTermRefresh
							: messages.noRankItself,
					)}
				>
					{rankAboveMenuItem}
				</Tooltip>
			) : (
				rankAboveMenuItem
			)}
			{isSelected ? (
				<Tooltip
					content={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.noRankItselfIssueTermRefresh
							: messages.noRankItself,
					)}
				>
					{rankBelowMenuItem}
				</Tooltip>
			) : (
				rankBelowMenuItem
			)}
		</DropMenuItemGroup>
	);
};

const contentWrapperStyles = css({
	minWidth: '160px',
});

export default componentWithFG(
	'plan-timeline-a11y-meatball-menu',
	RankIssueMenuGroup,
	RankIssueMenuGroupOld,
);
