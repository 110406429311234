import {
	getMode,
	isExportMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

const querySelector = createStructuredSelector<State, StateProps>({
	associatedIssues: (state) => state.domain.associatedIssues.associatedIssues,
	missingAssociatedIssuesFetchInProgress: (state) =>
		state.domain.associatedIssues.missingAssociatedIssuesFetchInProgress,
	isReadOnly: (state) => getMode(state) !== EDIT,
	isExportMode,
});

export default querySelector;
