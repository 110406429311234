import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import StatusBreakdown from '@atlassian/jira-portfolio-3-portfolio/src/common/view/status-breakdown/index.tsx';
import {
	PLANNING_UNITS,
	BREAKDOWN_SPRINT_CAPACITY_BY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

import { useIntl, type MessageDescriptorV2 } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const getProgressByEstimateTitle = (planningUnit: string): MessageDescriptorV2 => {
	switch (planningUnit) {
		case PLANNING_UNITS.STORY_POINTS:
			return messages.progressEstimateByPointsHeader;
		case PLANNING_UNITS.DAYS:
			return messages.progressEstimateByDaysHeader;
		case PLANNING_UNITS.HOURS:
		default:
			return messages.progressEstimateByHoursHeader;
	}
};

export const ProgressEstimateCell = ({
	breakdown,
	planningUnit,
	fieldKey,
	groupName,
	groupByLabel,
	swimlane,
}: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={containerStyles} paddingInlineStart="space.200" paddingInlineEnd="space.200">
			<StatusBreakdown
				planningUnit={planningUnit}
				breakdownBy={BREAKDOWN_SPRINT_CAPACITY_BY.ESTIMATE}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.progress-estimate-cell.${groupName}.${fieldKey}`}
				ariaLabel={formatMessage(messages.ariaLabel, {
					groupByLabel,
					swimlane,
				})}
				flyoutHeader={getProgressByEstimateTitle(planningUnit)}
				{...breakdown}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	background: 'none',
	height: '100%',
	width: '100%',
	':focus': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, Space does not mapped to token
		outlineOffset: token('space.negative.025'),
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, Space does not mapped to token
		outlineOffset: token('space.negative.025'),
	},
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
});

export default ProgressEstimateCell;
