import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SPA_APP_NAME } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import longTaskFactory from '@atlassian/jira-portfolio-3-common/src/long-task/index.tsx';
import {
	fireScreenAnalytics,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { InstanceUserRole, PlanUserRole, Grouping } from '../../common/view/constant.tsx';
import type { UrlPersistenceMetrics } from '../query/url-persistence/index.tsx';
import type { ColourByOption } from '../state/domain/view-settings/colour-by/types.tsx';
import type { RollupSettingsState } from '../state/domain/view-settings/rollup-settings/types.tsx';
import type { ColoursUsed } from '../state/middleware/analytics/types.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES, type GasV3AnalyticsEventTrigger } from './types.tsx';

export const longTask = longTaskFactory(SPA_APP_NAME);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createScrollReporter } from '@atlassian/jira-portfolio-3-common/src/long-task';

// This defines the context from where the event occurred, e.g. when viewing a plan or viewing a program.
export const context = {
	PLAN: 'plan',
	PROGRAM: 'program',
	TEAMS: 'teams',
} as const;

function getGasV3TrackEventTriggerFn(
	eventName: ActionSubjectAndAction,
	actionSubjectId?: string,
): GasV3AnalyticsEventTrigger {
	return function triggerTrackAnalyticsEvent(
		analyticsEvent: UIAnalyticsEvent,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		metaData: any = {},
	): undefined {
		if (actionSubjectId) {
			fireTrackAnalytics(analyticsEvent, eventName, actionSubjectId, metaData);
			return;
		}
		fireTrackAnalytics(analyticsEvent, eventName, metaData);
	};
}

const triggerCalculationDurationAnalytics = getGasV3TrackEventTriggerFn(
	PRODUCT_ANALYTICS_EVENT_NAMES.CALCULATE_PLAN,
);

const triggerCreateCrossProjectReleaseAnalytics = getGasV3TrackEventTriggerFn(
	PRODUCT_ANALYTICS_EVENT_NAMES.CREATE_CROSS_PROJECT_RELEASE,
);
const triggerUpdateJiraEventAnalytics = getGasV3TrackEventTriggerFn(
	PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_JIRA,
);

function triggerViewRoadmapEventAnalytics(
	analyticsEvent: UIAnalyticsEvent,
	group: Grouping | string,
	colourByOption: string,
	colours: Record<ColourByOption, ColoursUsed>,
	sortByField: string,
	teamsTotalCount: number,
	teamsScrumCount: number,
	teamsKanbanCount: number,
	teamsSharedCount: number,
	externalTeamsTotalCount: number,
	managedTeamsCount: number | undefined,
	sourcesTotalCount: number,
	sourcesBoardCount: number,
	sourcesProjectCount: number,
	sourcesFilterCount: number,
	rollupSettings: RollupSettingsState,
	showSprints: boolean,
	showWarnings: boolean,
	planPermission: PlanUserRole,
	instancePermission: InstanceUserRole,
	planningUnit: string,
	sprintActiveCount: number,
	sprintFutureCount: number,
	sprintFutureSetDates: number,
	sprintFutureInferredDates: number,
	hasSprintsWithInconsistentDates: boolean,
	hasAtlasPermissions: boolean,
	isAtlasConnectInstalled: boolean,
	timelineType: string | null | undefined,
	customTimelineType: string,
	fieldColumns: string[],
	issuesCount: number,
	dependenciesCount: number | null | undefined,
	dependenciesViewSetting: string | null | undefined,
	issuesWithFixedStartDateCount: number,
	issuesWithFixedEndDateCount: number,
	issuesWithSprintStartDateCount: number,
	issuesWithSprintEndDateCount: number,
	issuesWithReleaseStartDateCount: number,
	issuesWithReleaseEndDateCount: number,
	viewsInPlanCount: number,
	viewsWithDependencyBadgesCount: number,
	viewsWithDependencyLinesCount: number,
	viewsWithDependencyCountCount: number,
	planCreatedDate: string,
	teamsShownInPlan: number[],
	projectsShownInPlan: number[] | null | undefined,
	boardsShownInPlan: number[] | null | undefined,
	filtersShownInPlan: number[] | null | undefined,
	sourcesProjectCountJswCmpCount: number | null | undefined,
	sourcesProjectCountJswTmpCount: number | null | undefined,
	sourcesProjectCountJwmCmpCount: number | null | undefined,
	sourcesProjectCountJwmTmpCount: number | null | undefined,
	sourcesProjectCountJsmCmpCount: number | null | undefined,
	sourcesProjectCountCmpCount: number | null | undefined,
	sourcesProjectCountTmpCount: number | null | undefined,
	isMultiScenariosEnabled: boolean,
	scenariosCount: number,
	hierarchyLevels: {
		start: number;
		end: number;
	},
	selectedFilters: string[],
	isSamplePlan: boolean,
	maxOverlappingSprints: number,
	autoSchedulerEnabled: boolean,
	fiscalMonth: number,
	viewMode: string,
	urlPersistenceEstimate: UrlPersistenceMetrics | undefined,
	isMacroProxy: boolean,
	includeCompletedIssuesFor: number | null | undefined,
	excludeDoneStatusCategory: boolean | null | undefined,
	reportView: string | undefined,
	showAggregate: boolean,
	issuesCountPostHierarchyFiltering: number | undefined,
	issueCountPostFiltering: number | undefined,
	isFocusInstalled: boolean | undefined,
	hasFocusReadPermission: boolean | undefined,
	isJiraSiteConnectedToFocus: boolean | undefined,
	focusAreasCount: number | undefined,
	epicLevelIssuesCount: number | undefined,
	initiativeLevelIssuesCount: number | undefined,
	strategyLevelIssuesCount: number | undefined,
	goalLevelIssuesCount: number | undefined,
	levelAboveGoalLevelIssuesCount: number | undefined,
) {
	const { showingRolledUpDate, showRolledUpOthers } = rollupSettings;

	const payload: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[x: string]: any;
	} = {
		group,
		colourByOption,
		colours,
		sortByField,
		teamsTotalCount,
		teamsScrumCount,
		teamsKanbanCount,
		teamsSharedCount,
		externalTeamsTotalCount,
		managedTeamsCount,
		sourcesTotalCount,
		sourcesBoardCount,
		sourcesProjectCount,
		sourcesFilterCount,
		showingRolledUpDate,
		showRolledUpOthers,
		showSprints,
		showWarnings,
		planPermission,
		instancePermission,
		planningUnit,
		sprintActiveCount,
		sprintFutureCount,
		sprintFutureSetDates,
		sprintFutureInferredDates,
		hasSprintsWithInconsistentDates,
		hasAtlasPermissions,
		isAtlasConnectInstalled,
		customTimelineType,
		timelineType,
		fieldColumns,
		issuesCount,
		dependenciesCount,
		dependenciesViewSetting,
		issuesWithFixedStartDateCount,
		issuesWithFixedEndDateCount,
		issuesWithSprintStartDateCount,
		issuesWithSprintEndDateCount,
		issuesWithReleaseStartDateCount,
		issuesWithReleaseEndDateCount,
		viewsInPlanCount,
		viewsWithDependencyBadgesCount,
		viewsWithDependencyLinesCount,
		viewsWithDependencyCountCount,
		planCreatedDate,
		teamsShownInPlan,
		projectsShownInPlan,
		boardsShownInPlan,
		filtersShownInPlan,
		sourcesProjectCountJswCmpCount,
		sourcesProjectCountJswTmpCount,
		sourcesProjectCountJwmCmpCount,
		sourcesProjectCountJwmTmpCount,
		sourcesProjectCountJsmCmpCount,
		sourcesProjectCountCmpCount,
		sourcesProjectCountTmpCount,
		isMultiScenariosEnabled,
		scenariosCount,
		hierarchyLevels,
		selectedFilters,
		isSamplePlan,
		maxOverlappingSprints,
		autoSchedulerEnabled,
		fiscalMonth,
		viewMode,
		urlPersistenceEstimate,
		isMacroProxy,
		includeCompletedIssuesFor,
		excludeDoneStatusCategory,
		reportView,
		showAggregate,
		issuesCountPostHierarchyFiltering,
		issueCountPostFiltering,
		...(isFocusInstalled !== undefined && { isFocusInstalled }),
		...(hasFocusReadPermission !== undefined && { hasFocusReadPermission }),
		...(isJiraSiteConnectedToFocus !== undefined && { isJiraSiteConnectedToFocus }),
		...(focusAreasCount !== undefined && { focusAreasCount }),
		...(epicLevelIssuesCount !== undefined && { epicLevelIssuesCount }),
		...(initiativeLevelIssuesCount !== undefined && { initiativeLevelIssuesCount }),
		...(strategyLevelIssuesCount !== undefined && { strategyLevelIssuesCount }),
		...(goalLevelIssuesCount !== undefined && { goalLevelIssuesCount }),
		...(levelAboveGoalLevelIssuesCount !== undefined && { levelAboveGoalLevelIssuesCount }),
	};

	fireScreenAnalytics(analyticsEvent, payload);
}

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	triggerUpdateJiraEventAnalytics,
	triggerViewRoadmapEventAnalytics,
	triggerCalculationDurationAnalytics,
	triggerCreateCrossProjectReleaseAnalytics,
};
