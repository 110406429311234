/** @jsx jsx */
import React, { useMemo, type ReactNode, forwardRef, type Ref, useCallback } from 'react';
import { css, jsx } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import Lozenge from '@atlaskit/lozenge';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Section } from '@atlaskit/menu';
import { fg } from '@atlassian/jira-feature-gating';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
// Remove InlineDialog when cleaning up FG 'migrate_plan_filter_to_popup'
import { NO_SPRINT_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/sprint-filter/index.tsx';
import {
	getSprintStateLozengeContent,
	getSprintStateLabel,
	getLozengeAppearance,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/utils.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import { SPRINT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import {
	CHECKBOX_STATES,
	EXTERNAL_SPRINT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { ContentWrapper, ItemWrapper } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';

import type { Props } from './types.tsx';

export const { CHECKED, UNCHECKED, INDETERMINATE } = CHECKBOX_STATES;

const SprintFilterInner = (
	props: Props & { setInitialFocusRef?: (elem: HTMLElement | null) => void },
) => {
	const {
		allSprints,
		clearFilter,
		filterSprints,
		onQueryChange,
		searchQuery,
		setInitialFocusRef,
		changeFilter,
		externalSprints,
		sprintStatesCategories,
		sprintsAndTeamsByIssueSources: { sprintsWithTeams, sprintsWithoutTeams },
	} = props;
	const intl = useIntl();
	const { formatMessage } = intl;
	const filterSprintsWithSearchQuery = useCallback(
		(sprint: Sprint): boolean => {
			return sprint.title.toLowerCase().includes(searchQuery.toLowerCase());
		},
		[searchQuery],
	);

	const renderLozenge = useCallback(
		(sprintState: string) => {
			const lozengeContent = getSprintStateLozengeContent(intl);

			return (
				<Lozenge appearance={getLozengeAppearance(sprintState)}>
					{lozengeContent(sprintState)}
				</Lozenge>
			);
		},
		[intl],
	);

	const onItemGroupClick = useCallback(
		(sprintIds: string[], checkboxState: string) => {
			const newFilterSprint = new Set(filterSprints);
			if (checkboxState === UNCHECKED) {
				for (const id of sprintIds) {
					newFilterSprint.add(id);
				}
			} else {
				for (const id of sprintIds) {
					newFilterSprint.delete(id);
				}
			}
			changeFilter(Array.from(newFilterSprint));
		},
		[filterSprints, changeFilter],
	);

	const isSelected = useCallback(
		(id: string) => {
			return filterSprints.has(id);
		},
		[filterSprints],
	);

	const onItemClick = useCallback(
		(id: string): void => {
			const newFilterSprint = new Set(filterSprints);
			if (isSelected(id)) {
				newFilterSprint.delete(id);
			} else {
				newFilterSprint.add(id);
			}
			changeFilter(Array.from(newFilterSprint));
		},
		[filterSprints, changeFilter, isSelected],
	);

	// this function renders a "group" of sprints i.e. either:
	// - the group(s) of sprints associated with a team
	// - the (unique) group of sprints not associated with a team
	// each group rendered includes a heading and a list of sprints
	const renderSprintsGroup = useCallback(
		(
			heading: string,
			sprints: Array<Sprint>,
			renderSprintStateLozenge: (sprintState: string) => ReactNode,
		) => (
			<Section title={heading} key={heading}>
				{sprints.map((sprint) => (
					<ItemWrapper key={sprint.id}>
						<Checkbox
							isChecked={filterSprints.has(sprint.id)}
							label={
								<div css={sprintCheckboxLabelWrapperStyles}>
									<EllipsedWithTooltip content={sprint.title}>
										<span css={sprintCheckboxLabelTitleStyles}>{sprint.title}</span>
									</EllipsedWithTooltip>
									{renderSprintStateLozenge(sprint.state)}
								</div>
							}
							onChange={() => onItemClick(sprint.id)}
						/>
					</ItemWrapper>
				))}
			</Section>
		),
		[filterSprints, onItemClick],
	);

	// this function renders the main categories of sprints, right below the "No sprint" filter
	// i.e. "All [COMPLETED / ACTIVE / FUTURE / EXTERNAL] sprints"
	const renderSprintStateCategories = useCallback(() => {
		if (!sprintStatesCategories.length) {
			return null;
		}
		const checkboxContent = getSprintStateLabel(intl);

		return sprintStatesCategories.map(({ checkboxState, sprintIds, sprintState }) => (
			<ItemWrapper key={`${sprintState}-stateCheckbox`}>
				<Checkbox
					isChecked={checkboxState !== UNCHECKED}
					isDisabled={!sprintIds.length}
					isIndeterminate={checkboxState === INDETERMINATE}
					label={checkboxContent(sprintState)}
					onChange={() => onItemGroupClick(sprintIds, checkboxState)}
				/>
			</ItemWrapper>
		));
	}, [sprintStatesCategories, onItemGroupClick, intl]);

	// this function renders all sprints associated with a Team, for each Team
	const renderSprintsWithTeams = useCallback(() => {
		if (sprintsWithTeams.length) {
			return sprintsWithTeams.map((sprintsInGroup) => {
				// filtering sprints based on search query
				const sprints = searchQuery
					? sprintsInGroup.sprints.filter(filterSprintsWithSearchQuery)
					: sprintsInGroup.sprints;
				if (!sprints.length) {
					return null;
				}
				return renderSprintsGroup(sprintsInGroup.teams.join(', '), sprints, (sprintState) =>
					renderLozenge(sprintState),
				);
			});
		}
		return null;
	}, [
		renderLozenge,
		sprintsWithTeams,
		searchQuery,
		renderSprintsGroup,
		filterSprintsWithSearchQuery,
	]);

	// this function renders all sprints which are not associated with a Team, in one unique group
	const renderSprintsWithoutTeams = useCallback(() => {
		if (sprintsWithoutTeams.length) {
			// filtering sprints based on search query
			const sprints = searchQuery
				? sprintsWithoutTeams.filter(filterSprintsWithSearchQuery)
				: sprintsWithoutTeams;
			if (!sprints.length) {
				return null;
			}
			return renderSprintsGroup(
				formatMessage(commonMessages.noAssociatedTeams),
				sprints,
				(sprintState) => renderLozenge(sprintState),
			);
		}
		return null;
	}, [
		renderLozenge,
		formatMessage,
		searchQuery,
		sprintsWithoutTeams,
		filterSprintsWithSearchQuery,
		renderSprintsGroup,
	]);

	// this function renders all external sprints in one unique group
	const renderExternalSprints = useCallback(() => {
		// filtering external sprints based on search query
		const filteredExternalSprints = searchQuery
			? externalSprints.filter(filterSprintsWithSearchQuery)
			: externalSprints;

		if (!filteredExternalSprints.length) {
			return null;
		}

		return renderSprintsGroup(
			formatMessage(commonMessages.otherSprints),
			filteredExternalSprints,
			(sprintState) => renderLozenge(EXTERNAL_SPRINT || sprintState),
		);
	}, [
		renderLozenge,
		externalSprints,
		searchQuery,
		filterSprintsWithSearchQuery,
		formatMessage,
		renderSprintsGroup,
	]);

	return (
		<ContentWrapper>
			<ClearFilterButton isVisible={filterSprints.size > 0} onClearClick={clearFilter} />
			<SearchField
				placeholder={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchSprintPlaceholderIssueTermRefresh
						: messages.searchSprintPlaceholder,
				)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={intl.formatMessage(messages.searchSprintLabel)}
				setInitialFocusRef={setInitialFocusRef}
			/>
			<ItemWrapper key={`${NO_SPRINT_ID}-checkbox`}>
				<Checkbox
					id={NO_SPRINT_ID}
					key={NO_SPRINT_ID}
					isChecked={isSelected(NO_SPRINT_ID)}
					label={<FormattedMessage {...commonMessages.noSprintLabel} />}
					onChange={() => onItemClick(NO_SPRINT_ID)}
				/>
			</ItemWrapper>
			{(searchQuery && allSprints.some(filterSprintsWithSearchQuery)) || allSprints.length > 0 ? (
				<>
					{renderSprintStateCategories()}
					{renderSprintsWithTeams()}
					{renderSprintsWithoutTeams()}
					{renderExternalSprints()}
				</>
			) : (
				<NoMatchFound />
			)}
		</ContentWrapper>
	);
};

const SprintFilterTriggerButton = forwardRef(
	(
		props: Pick<Props, 'isOpen' | 'filterSprints' | 'onOpenChange' | 'sprintsByIdMap'> &
			TriggerProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { filterSprints, isOpen, onOpenChange, sprintsByIdMap } = props;
		const { formatMessage } = useIntl();

		const constructFilterText = useMemo(() => {
			const filteredSprintsTitles: Array<string> = [];

			// the "No sprint" filter is displayed first in the filter button text
			if (filterSprints.has(NO_SPRINT_ID)) {
				filteredSprintsTitles.push(formatMessage(commonMessages.noSprintLabel));
			}
			[...filterSprints].forEach((sprintId) => {
				if (sprintsByIdMap[sprintId] && sprintsByIdMap[sprintId].title) {
					filteredSprintsTitles.push(sprintsByIdMap[sprintId].title);
				}
			});
			return filteredSprintsTitles.join(', ');
		}, [filterSprints, formatMessage, sprintsByIdMap]);

		const renderFilterText = useMemo(() => {
			if (filterSprints.size > 0) {
				return <FilterText text={constructFilterText} />;
			}
			return <FormattedMessage {...messages.emptyPlaceholder} />;
		}, [constructFilterText, filterSprints]);

		const ariaText = useMemo(() => {
			const filterText = constructFilterText;
			// Sprints, All
			return `${formatMessage(filterMessages[SPRINT_FILTER_ID])}, ${
				filterSprints.size > 0 ? filterText : formatMessage(messages.emptyPlaceholder)
			} ${formatMessage(filterMessages.selected)}`;
		}, [constructFilterText, filterSprints, formatMessage]);

		return (
			<TriggerButton
				{...props}
				ref={ref}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.trigger-btn"
				triggerButtonText={renderFilterText}
				ariaLabel={ariaText}
			/>
		);
	},
);

const SprintFilter = (props: Props) => {
	const { filterSprints, isOpen, onOpenChange, sprintsByIdMap } = props;
	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			onClose={() => {
				onOpenChange({ isOpen: false });
			}}
			content={(contentProps) => (
				<SprintFilterInner {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter"
			trigger={(triggerProps) => (
				<SprintFilterTriggerButton
					isOpen={isOpen}
					filterSprints={filterSprints}
					onOpenChange={onOpenChange}
					sprintsByIdMap={sprintsByIdMap}
					{...triggerProps}
				/>
			)}
		/>
	);
};

export default SprintFilter;

const sprintCheckboxLabelTitleStyles = css({
	paddingRight: token('space.100'),
});

const sprintCheckboxLabelWrapperStyles = css({
	alignItems: 'center',
	display: 'flex',
	maxWidth: '380px',
	paddingRight: token('space.200'),
});
