import * as R from 'ramda';
import { type Effect, call, fork, join, put, select, takeLatest } from 'redux-saga/effects';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { extractStartDateField } from '../../common/api/plan-info.tsx';
import {
	type Sprint,
	type CustomField,
	DUE_DATE_FIELD,
	type PlanInfo,
	type Backlog,
	type SavedViewsInfo,
	DateFieldStatus,
	type DateField,
	DATEFIELD_WARNINGS,
	TARGET_START_END_API_MAP,
} from '../../common/api/types.tsx';
import fetch from '../../common/fetch/index.tsx';
import { isDefined } from '../../common/ramda/index.tsx';
import {
	DEPENDENCY_REPORT_SHARE_TYPE,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '../../common/view/constant.tsx';
import { redirect, userAgent } from '../../common/window/index.tsx';
import { getMode, isConfluenceMacroProxy } from '../query/app/index.tsx';
import { getIssueGoalARIs } from '../query/issue-goals/index.tsx';
import { afterResetBacklog, resetBacklog, setPlanInfo } from '../state/domain/actions.tsx';
import { setMode } from '../state/domain/app/actions.tsx';
import { getInitialMode } from '../state/domain/app/reducer.tsx';
import { type Mode, READONLY, EXPORT, REPORT, EDIT } from '../state/domain/app/types.tsx';
import { pushFlag } from '../state/domain/flags/actions.tsx';
import { NO_JSW_ACCESS_FOR_USER, PROJECT_LIMIT_EXCEPTION } from '../state/domain/flags/types.tsx';
import type { Issue } from '../state/domain/issues/types.tsx';
import { setPlanDefaults } from '../state/domain/plan-defaults/actions.tsx';
import { update as updatePlan } from '../state/domain/plan/actions.tsx';
import { reset as resetSelectOptions } from '../state/domain/select-options/actions.tsx';
import { setSystemInfo } from '../state/domain/system/actions.tsx';
import { setAdditionalTeams } from '../state/domain/teams-additional/actions.tsx';
import { updateUserIsOnboarded } from '../state/domain/user-is-onboarded/actions.tsx';
import { prepareTeam } from '../state/domain/util.tsx';
import { reset as resetCustomFieldFilter } from '../state/domain/view-settings/filters/custom-field-filter/actions.tsx';
import { setConfluenceMacroType } from '../state/ui/confluence-macro/actions.tsx';
import { setIsProjectLimitExceeded, showError } from '../state/ui/errors/actions.tsx';
import { setIsLoading } from '../state/ui/main/tabs/roadmap/scope/issues/actions.tsx';
import { cancelOnReset } from '../util/cancel-on-reset.tsx';
import { getPlanId, getReportHash, getScenarioId } from '../util/urls.tsx';
import { getSystemInfo } from '../query/system/index.tsx';
import type { ErrorDetails } from '../state/ui/errors/types.tsx';
import { reset as resetFocusAreas } from '../state/domain/issue-focus-areas/actions.tsx';
import { AFTER_INITIAL_LOAD, INITIAL_LOAD } from './actions.tsx';
import analytics from './analytics/index.tsx';
import assignees, { populateAssignees, populateAssigneesFromProfiles } from './assignees/index.tsx';
import { fetchBacklog } from './backlog/index.tsx';
import batch from './batch/index.tsx';
import crossProjectVersions, * as crossProjectVersionsCommands from './cross-project-versions/index.tsx';
import customFields, { doPopulateUserPickerOptions } from './custom-fields/index.tsx';
import { populateDependencyReportFilterSettings } from './dependencies/index.tsx';
import watchGenericError from './errors/index.tsx';
import exportRoadmap from './export-roadmap/index.tsx';
import externalIssues from './external-issues/index.tsx';
import watchSetInitialFilterValues from './filters/index.tsx';
import flags from './flags/index.tsx';
import inlineCreate from './inline-create/index.tsx';
import issueGoals, { fetchGoals } from './issue-goals/index.tsx';
import associatedIssues from './associated-issues/index.tsx';
import issueLinks from './issue-links/index.tsx';
import { issueRollupSagas } from './issue-rollups/index.tsx';
import issueStatus from './issue-status/index.tsx';
import issueTypes from './issue-type/index.tsx';
import issue from './issue/index.tsx';
import onboarding, { setIntroOpen } from './onboarding/index.tsx';
import optimize from './optimize/index.tsx';
import {
	type PageDataType,
	type GetPageDataResults,
	getPageDataFromInfoMetadata,
	type BasicRequiredPlanInfo,
} from './page-data/index.tsx';
import permissions from './permissions/index.tsx';
import plan from './plan/index.tsx';
import plannedCapacities from './planned-capacities/index.tsx';
import project from './project/index.tsx';
import { populateReportersFromProfiles } from './reporters/index.tsx';
import scenarios from './scenarios/index.tsx';
import scope from './scope/index.tsx';
import shareReport from './share-report/index.tsx';
import sprint, { populateExternalSprints } from './sprint/index.tsx';
import teams from './teams/index.tsx';
import timeToInteractive from './time-to-interactive/index.tsx';
import timelinePreview from './timeline-preview/index.tsx';
import type { AfterInitialLoadAction, InitialConfig, InitialLoadAction } from './types.tsx';
import updateJira from './update-jira/index.tsx';
import { addSagaSpan, toErrorID } from './util.tsx';
import versions, * as versionsCommands from './versions/index.tsx';
import views, {
	fetchHistoricalIssueForView,
	initializeViews,
	populateReportViewSettings,
	applyModificationsForInactiveViews,
} from './views/index.tsx';
import warnings, { calculateWarnings } from './warnings/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { resetReduxStore, restoreReduxStore } from '../state/actions';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { INITIAL_LOAD, AFTER_INITIAL_LOAD };

export const initialLoad = (payload: InitialConfig): InitialLoadAction => ({
	type: INITIAL_LOAD,
	payload,
});

export const afterInitialLoad = (): AfterInitialLoadAction => ({
	type: AFTER_INITIAL_LOAD,
});

const INVERTED_TARGET_START_END_API_MAP = R.invertObj(TARGET_START_END_API_MAP);
export const transformDateField = (field: DateField): DateField => {
	const newId = R.path<keyof Issue>([field.id], INVERTED_TARGET_START_END_API_MAP);
	return newId ? { ...field, id: newId, key: newId } : field;
};

const getWarnings = (
	dateField: DateField,
	dateFieldStatus: DateFieldStatus,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	customFields: CustomField[],
) => {
	if (dateFieldStatus === DateFieldStatus.UNAVAILABLE) {
		const apiDateField = customFields.find((field) => String(field.id) === dateField.key);
		return { code: DATEFIELD_WARNINGS.UNAVAILABLE, apiDateField };
	}
	if (dateFieldStatus === DateFieldStatus.NOT_EXIST) {
		return { code: DATEFIELD_WARNINGS.NOT_EXIST };
	}
	return undefined;
};

// converts baselineStart/End to targetStart/End
export const transformDateFields = (planInfo: PlanInfo) => ({
	baselineStartField: transformDateField(planInfo.plan.baselineStartField),
	baselineEndField: transformDateField(planInfo.plan.baselineEndField),
});

export function handleDateFieldWarnings(
	planInfo: PlanInfo,
): { baselineStartField?: DateField; baselineEndField?: DateField } | undefined {
	if (planInfo.dateFieldStatuses == null) {
		return undefined;
	}
	if (
		planInfo.dateFieldStatuses != null &&
		(planInfo.dateFieldStatuses.startFieldStatus !== DateFieldStatus.OK ||
			planInfo.dateFieldStatuses.endFieldStatus !== DateFieldStatus.OK)
	) {
		const dateWarnings: { baselineStartField?: DateField; baselineEndField?: DateField } = {};
		const startWarning = getWarnings(
			planInfo.plan.baselineStartField,
			planInfo.dateFieldStatuses.startFieldStatus,
			planInfo.customFields,
		);
		const endWarning = getWarnings(
			planInfo.plan.baselineEndField,
			planInfo.dateFieldStatuses.endFieldStatus,
			planInfo.customFields,
		);
		if (startWarning) {
			dateWarnings.baselineStartField = R.assocPath(
				['warning'],
				startWarning,
				extractStartDateField(planInfo),
			);
		}
		if (endWarning) {
			dateWarnings.baselineEndField = R.assocPath(['warning'], endWarning, DUE_DATE_FIELD);
		}
		if (startWarning || endWarning) {
			return dateWarnings;
		}
	}
	return undefined;
}

function* doBacklog(
	backlogResponse: Response,
	sprints: Sprint[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, Backlog | null, any> {
	let backlog: Backlog | null = null;
	if (backlogResponse.ok) {
		yield* batch(function* () {
			const parseJsonStart = performance.now();
			monitor.setMark('jira.fe-spa.advanced-roadmaps.product.parse-json:start');
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			backlog = (yield call(backlogResponse.json.bind(backlogResponse))) as Backlog;
			monitor.setMark('jira.fe-spa.advanced-roadmaps.product.parse-json:end');
			addSagaSpan('doBacklog.parseJson', parseJsonStart, performance.now());

			yield call(populateExternalSprints, backlog?.sprints ?? [], sprints);

			const resetBacklogStart = performance.now();
			yield put(resetBacklog(backlog));
			addSagaSpan('doBacklog.resetBacklog', resetBacklogStart, performance.now());

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const teams = backlog.teams?.map(prepareTeam) ?? [];
			yield put(setAdditionalTeams(teams));

			yield put(afterResetBacklog());
			yield put(versionsCommands.calculateStatusOfAllReleases());

			// Backlog data should only be falsy when we have already thrown an error
			yield call(populateAssigneesFromProfiles, backlog?.assigneeProfiles ?? {});
			yield call(populateReportersFromProfiles, backlog?.reporterProfiles ?? {});

			if (fg('focus_area_in_plans')) {
				yield put(resetFocusAreas(backlog?.focusAreas ?? []));
			}
			addSagaSpan('doBacklog', parseJsonStart, performance.now());
		});
	} else {
		yield call(populateAssignees);

		const error = new Error(yield call(backlogResponse.text.bind(backlogResponse)));

		fireErrorAnalytics({
			meta: {
				id: toErrorID(error, 'backlog-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
			// We're monitoring backlog endpoint in SFX and Splunk. No need to also send to Sentry.
			skipSentry: true,
		});
	}
	return backlog;
}

/* eslint-disable no-empty */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchJson(url: string): Generator<Effect, unknown, any> {
	try {
		const response = yield call(fetch, url, { method: 'GET' });
		if (response.ok) {
			return yield call(response.json.bind(response));
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {}
	throw new Error(`Failed to fetch page data for plan: ${url}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getBasicRequiredPlanInfo(): Generator<Effect, BasicRequiredPlanInfo, any> {
	const reportHash = yield call(getReportHash);

	if (isDefined(reportHash)) {
		let response;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const earlyPreloadBasicPlanInfoPromise = window.__ARJ_BASIC_PLAN_INFO_FOR_REPORT_PROMISE__;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		delete window.__ARJ_BASIC_PLAN_INFO_FOR_REPORT_PROMISE__;

		if (earlyPreloadBasicPlanInfoPromise) {
			// @ts-expect-error TS2322 - Type 'Promise<any>' is not assignable to type 'Effect'.
			response = yield earlyPreloadBasicPlanInfoPromise;
		}

		if (!isDefined(response)) {
			response = yield call(fetchJson, `/rest/jpo/1.0/reportinfo/generic/${reportHash}`);
		}

		yield put(updatePlan({ id: response.planId, currentScenarioId: response.scenarioId }));

		return {
			planId: response.planId,
			scenarioId: response.scenarioId,
			reportInfo: response.data,
		};
	}
	return {
		planId: yield call(getPlanId),
		scenarioId: yield call(getScenarioId),
		reportInfo: undefined,
	};
}

export function* initializeViewsSaga(
	savedViewsInfo: SavedViewsInfo,
	appMode: Mode, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	// in REPORT mode, there is only one view so there is no need to initialize views
	// however, we still want to get the historical issues if there is a progress column in the view
	if (appMode === REPORT) {
		yield put(fetchHistoricalIssueForView());
	} else if (savedViewsInfo) {
		yield call(initializeViews, savedViewsInfo);
	}
}

function* doInitialLoadSetPlanSystemInfo(
	pageData: PageDataType,
	payload: InitialConfig, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<any>, any, any> {
	const {
		planDefaultsInfo,
		planInfo,
		hierarchyInfo,
		issueStatusCategoryInfo,
		systemInfo,
		agileInfo,
		issueLinkTypesInfo,
		dependencySettingsInfo,
		loadingLimitsInfo,
		isAtlasConnectInstalled,
		hasAtlasPermissions,
		focusAreaContext,
	} = pageData;

	yield put.resolve(
		setSystemInfo({
			agileInfo,
			issueLinkTypesInfo,
			dependencySettingsInfo,
			systemInfo: { ...systemInfo, ...payload },
			loadingLimitsInfo,
			isAtlasConnectInstalled,
			hasAtlasPermissions,
			focusAreaContext,
		}),
	);
	yield put(setPlanDefaults(planDefaultsInfo));
	yield put.resolve(setPlanInfo({ planInfo, hierarchyInfo, issueStatusCategoryInfo }));
}
function* doInitialLoadDependencyReportConfluenceMacro(
	pageData: PageDataType,
	payload: InitialConfig,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	backlogTask: any, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<any>, any, any> {
	yield call(doInitialLoadSetPlanSystemInfo, pageData, payload);

	yield* doBacklog(yield join(backlogTask), pageData.planInfo.sprints);

	yield put(setIsLoading(false));
	yield put(afterInitialLoad());
}

function* doInitialLoadTimeline(
	pageData: PageDataType,
	payload: InitialConfig,
	appMode: Mode,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	backlogTask: any, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect | Promise<any>, any, any> {
	const {
		planInfo,
		userProperties,
		savedViewsInfoFull,
		customFieldsOptions,
		isAtlasConnectInstalled,
	} = pageData;

	yield call(doInitialLoadSetPlanSystemInfo, pageData, payload);

	yield put(updateUserIsOnboarded(Boolean(userProperties.onboadingShown)));

	if (!userProperties.onboadingShown) {
		// We only show the tour if the user is in EDIT mode
		if (appMode === EDIT) {
			yield put.resolve(setIntroOpen(true));
		}
	}

	const startWaitingForBacklog = performance.now();
	const backlogResponse = yield join(backlogTask);
	const backlogResponseFinishedTime = performance.now();
	addSagaSpan(
		'doInitialLoadTimeline.waiting-for-backlog-API',
		startWaitingForBacklog,
		backlogResponseFinishedTime,
	);

	yield* doBacklog(backlogResponse, planInfo.sprints);

	const isMacroProxy = yield select(isConfluenceMacroProxy);
	if (isAtlasConnectInstalled && !isMacroProxy) {
		const goalARIs: string[] = yield select(getIssueGoalARIs);
		yield put(fetchGoals(goalARIs));
	}

	yield call(doPopulateUserPickerOptions);

	yield put(resetCustomFieldFilter({ customFields: planInfo.customFields }));

	const initializeViewsStart = performance.now();
	yield call(initializeViewsSaga, savedViewsInfoFull, appMode);
	addSagaSpan('doInitialLoadTimeline.initializeViewsSaga', initializeViewsStart, performance.now());

	yield put(calculateWarnings());
	yield put(versionsCommands.calculateStatusOfAllReleases());

	yield put(setIsLoading(false));

	const dateWarnings = handleDateFieldWarnings(planInfo);
	if (dateWarnings) {
		yield put(updatePlan(dateWarnings));
	}

	yield put(afterInitialLoad());

	yield put(applyModificationsForInactiveViews(savedViewsInfoFull));

	if (customFieldsOptions) {
		const selectOptions = customFieldsOptions
			.map(({ customFieldId: fieldId, options }) =>
				options.map((option) => R.assoc('fieldId', fieldId, option)),
			)
			.reduce((a, b) => a.concat(b), []);
		yield put(resetSelectOptions(selectOptions));
	}
	addSagaSpan('doInitialLoadTimeline', backlogResponseFinishedTime, performance.now());
}

function* populateReportConfig(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	parameters: any,
	pageData: PageDataType,
	payload: InitialConfig,
	appMode: Mode,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	backlogTask: any, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	switch (parameters.view) {
		case DEPENDENCY_REPORT_SHARE_TYPE:
			yield call(populateDependencyReportFilterSettings, parameters.viewConfig.filtersV1);
			yield call(doInitialLoadDependencyReportConfluenceMacro, pageData, payload, backlogTask);
			break;
		default:
			yield call(populateReportViewSettings, parameters.viewConfig);
			yield call(doInitialLoadTimeline, pageData, payload, appMode, backlogTask);
	}
}

export function* doInitialLoad({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: InitialLoadAction): Generator<Effect | Promise<any>, any, any> {
	yield put(setIsLoading(true));
	yield put(setMode(getInitialMode()));

	const basicPlanInfo = yield call(getBasicRequiredPlanInfo);

	// Setting planId and scenarioId for TTI performance event.
	monitor.initializeContextualAnalyticsData({
		planId: basicPlanInfo.planId,
		scenarioId: basicPlanInfo.scenarioId,
	});

	const appMode = yield select(getMode);

	// Calling the backlog endpoint to get issue data.
	const backlogTask = yield fork(
		fetchBacklog,
		true,
		basicPlanInfo.planId,
		basicPlanInfo.scenarioId,
	);

	// Calling /rest/jpo/1.0/info/metadata endpoint to get page data.
	const pageData: GetPageDataResults = yield call(getPageDataFromInfoMetadata, basicPlanInfo);

	if (pageData === PROJECT_LIMIT_EXCEPTION) {
		yield put(setIsProjectLimitExceeded());
		// For Report/Confluence macro we do not want to redirect as the app is not mounted in SPA
		if (appMode === REPORT) return;

		yield call(redirect, proxyContextSafeUrl(`/jira/plans/${basicPlanInfo.planId}/setup`));
		return;
	}

	const { planInfo, reportInfo } = pageData;

	const { baselineStartField, baselineEndField } = transformDateFields(planInfo);
	planInfo.plan.baselineStartField = baselineStartField;
	planInfo.plan.baselineEndField = baselineEndField;

	// Reports are by default read-only. Hence we want set READONLY mode only if the default mode is not REPORT
	if (appMode === REPORT) {
		if (!reportInfo) {
			throw new Error('Failed to fetch report data');
		}
		yield put.resolve(setConfluenceMacroType(reportInfo.parameters.view));
		yield call(
			populateReportConfig,
			reportInfo.parameters,
			pageData,
			payload,
			appMode,
			backlogTask,
		);
	} else if (planInfo.readOnly && appMode !== EXPORT) {
		yield put(setMode(READONLY));
		yield call(doInitialLoadTimeline, pageData, payload, appMode, backlogTask);
	} else {
		yield call(doInitialLoadTimeline, pageData, payload, appMode, backlogTask);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* initialLoadWith401Handled(action: InitialLoadAction): Generator<Effect, void, any> {
	try {
		yield call(doInitialLoad, action);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		if (err instanceof Error && err.message.includes('no-jira-software-access-for-user')) {
			yield put(pushFlag({ key: NO_JSW_ACCESS_FOR_USER }));
			return;
		}
		throw err;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchInitialLoad(): Generator<Effect, any, any> {
	yield takeLatest(INITIAL_LOAD, initialLoadWith401Handled);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootSaga = function* (): Generator<Effect, any, any> {
	yield fork(flags);
	yield fork(assignees);
	yield fork(warnings);
	yield fork(crossProjectVersions(versionsCommands));
	yield fork(customFields);
	yield fork(externalIssues);
	yield fork(cancelOnReset, inlineCreate);
	yield fork(cancelOnReset, issue);
	yield fork(cancelOnReset, issueLinks);
	yield fork(cancelOnReset, issueTypes);
	yield fork(optimize);
	yield fork(permissions);
	yield fork(cancelOnReset, plan);
	yield fork(project);
	yield fork(scenarios);
	yield fork(scope);
	yield fork(exportRoadmap);
	yield fork(shareReport);
	yield fork(cancelOnReset, views);
	yield fork(teams);
	yield fork(timeToInteractive);
	yield fork(timelinePreview);
	yield fork(cancelOnReset, updateJira);
	yield fork(versions(crossProjectVersionsCommands));
	yield fork(watchInitialLoad);
	yield fork(watchSetInitialFilterValues);
	yield fork(onboarding);
	yield fork(sprint);
	yield fork(analytics);
	yield fork(plannedCapacities);
	yield fork(issueStatus);
	yield fork(issueRollupSagas);
	yield fork(issueGoals);
	yield fork(associatedIssues);
};

/**
 * Previously in the legacy page of the application, we had one reudx
 * store and associated redux-saga for the application. When an unhandled error occurs
 * in one plan, it used to break the root saga, but when we switch the plan, the application
 * still used to work because we were reloading the legacy page while loading the other plan.
 *
 * Because we are forking the child sagas, the root saga terminates if any one of the child sagas
 * throws an unhandled error and terminates.
 *
 * Now that we are in Jira SPA, this full page reload doesn't happen. When similar situation happens,
 * it kills the only one instance of redux saga and the app becomes unresponsive. The plan switching
 * also doesn't work because the saga doesn't respond until the full page reload happens.
 *
 * To fix this issue, we are doing following 2 things,
 * 1. In order to restart the failed child saga, we are catching any unhandled
 * error from all the child sagas in the root saga and restarting the root saga below.
 *
 * 2. In order to secure the app from any stale data, we are resetting the store to its initial state
 * when we switch the plan or scenario. To do so, we have introduced RESET_REDUX_STORE action.
 * We are resetting the store when we unmount the application in
 * src/packages/portfolio-3/portfolio/src/app-simple-plans/index.js#L23
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGenericError);

	while (true) {
		try {
			yield call(rootSaga);
			break;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: ERROR_REPORTING_PACKAGE.REDUX_SAGA,
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			const systemInfo = yield select(getSystemInfo);
			const details: ErrorDetails = {
				clientInfo: {
					userAgent,
					localTime: new Date().toString(),
				},
				systemInfo,
			};

			yield put(
				showError(
					error.message,
					error.stack,
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window?.location.hash,
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window?.location.pathname,
					details,
				),
			);
		}
	}
}
