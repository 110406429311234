import React, { Component } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { HelperMessage, Field } from '@atlaskit/form';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	AkSelect,
	selectIssueOption,
} from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import {
	MOVE_ISSUES,
	SET_ASSIGNEE,
	SET_CP_RELEASE,
	SET_RELEASE,
	SET_SPRINT,
	SET_TEAM,
	type UpdateAttributeFormProps as Props,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/bulk-actions/types.tsx';

import { NONE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { formatReleaseOptionLabel } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/versions/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import AssigneeSelect from '../custom-form-component/assignee-select/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class UpdateAttributeForm extends Component<Props, State> {
	state = {
		fieldValue: {},
	};

	submitForm = (
		analyticsEvent: UIAnalyticsEvent,
		event?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
	) => {
		const { onSubmit } = this.props;
		const {
			fieldValue,
		}: {
			fieldValue: { [key: string]: string };
		} = this.state;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const normalizedData = Object.keys(fieldValue).reduce<Record<string, any>>((acc, curr) => {
			if (curr === 'fixVersions') {
				// convert fixVersions value received from select to array.
				return Object.assign(acc, {
					[curr]: fieldValue[curr] && fieldValue[curr] !== NONE ? [fieldValue[curr]] : [],
				});
			}
			return Object.assign(acc, {
				[curr]: fieldValue[curr] === NONE ? null : fieldValue[curr],
			});
		}, {});

		if (fg('plan-timeline-a11y-meatball-menu')) {
			onSubmit(normalizedData, analyticsEvent, event);
		} else {
			onSubmit(normalizedData, analyticsEvent);
		}
	};

	handleOnChange = (fieldId: string, fieldValue: string) => {
		this.setState({
			fieldValue: {
				[fieldId]: fieldValue,
			},
		});
	};

	getFormField = () => {
		const {
			intl: { formatMessage },
			options = [],
			updatedAttributeType,
			warningMessage,
		} = this.props;

		const noneOption = {
			label: formatMessage(commonMessages.none),
			value: NONE,
		};

		switch (updatedAttributeType) {
			case SET_ASSIGNEE:
				return (
					<AssigneeSelect
						autofocus
						defaultValue={undefined}
						id="assignee"
						label={formatMessage(commonMessages.new)}
						name="assignee"
						onChange={(id: string, value: string) => {
							this.handleOnChange(id, value);
						}}
						placeholder={formatMessage(messages.chooseAssigneePlaceholder)}
					/>
				);

			case MOVE_ISSUES:
				return (
					<Field
						isRequired
						label={formatMessage(commonMessages.new)}
						name="parent"
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.common.update-attribute-form.move-issues-field"
					>
						{() => (
							<>
								<AkSelect
									defaultValue={undefined}
									formatOptionLabel={selectIssueOption}
									aria-label={formatMessage(messages.chooseParentPlaceholder)}
									classNamePrefix="move_issues_field"
									onChange={(option: OptionType | null) => {
										this.handleOnChange('parent', option?.value);
									}}
									options={options}
									placeholder={formatMessage(messages.chooseParentPlaceholder)}
									styles={{
										menu: (base) => ({
											...base,
											minWidth: '300px',
											maxWidth: '560px',
											width: 'auto',
										}),
										option: (base) => ({
											...base,
											width: 'auto',
										}),
										// eslint-disable-next-line @typescript-eslint/no-shadow
										singleValue: (styles) => ({
											...styles,
											width: '100%',
										}),
									}}
								/>
								{warningMessage && <HelperMessage>{warningMessage}</HelperMessage>}
							</>
						)}
					</Field>
				);

			case SET_RELEASE:
				return (
					<Field
						isRequired
						label={formatMessage(commonMessages.new)}
						name="fixVersions"
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.common.update-attribute-form.set-release-field"
					>
						{() => (
							<AkSelect
								autoFocus
								defaultValue={undefined}
								formatOptionLabel={formatReleaseOptionLabel}
								classNamePrefix="set_release_field"
								aria-label={formatMessage(messages.chooseReleasePlaceholder)}
								onChange={(option: OptionType | null) => {
									this.handleOnChange('fixVersions', option?.value);
								}}
								options={[
									{
										options: [noneOption],
									},
									...options,
								]}
								placeholder={formatMessage(messages.chooseReleasePlaceholder)}
							/>
						)}
					</Field>
				);

			case SET_CP_RELEASE:
				return (
					<Field
						isRequired
						label={formatMessage(commonMessages.new)}
						name="crossProjectVersion"
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.common.update-attribute-form.set-cp-release-field"
					>
						{() => (
							<AkSelect
								autoFocus
								defaultValue={undefined}
								aria-label={formatMessage(messages.chooseCrossProjectReleasePlaceholder)}
								classNamePrefix="cp_release_field"
								onChange={(option: OptionType | null) => {
									this.handleOnChange('crossProjectVersion', option?.value);
								}}
								options={[noneOption, ...options]}
								placeholder={formatMessage(messages.chooseCrossProjectReleasePlaceholder)}
							/>
						)}
					</Field>
				);

			case SET_SPRINT:
				return (
					<Field
						isRequired
						label={formatMessage(commonMessages.new)}
						name="sprint"
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.common.update-attribute-form.set-sprint-field"
					>
						{() => (
							<AkSelect
								autoFocus
								defaultValue={undefined}
								aria-label={formatMessage(messages.chooseSprintPlaceholder)}
								classNamePrefix="set_sprint_field"
								onChange={(option: OptionType | null) => {
									this.handleOnChange('sprint', option?.value);
								}}
								options={options}
								placeholder={formatMessage(messages.chooseSprintPlaceholder)}
							/>
						)}
					</Field>
				);

			case SET_TEAM:
				return (
					<Field
						isRequired
						label={formatMessage(commonMessages.new)}
						name="team"
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.common.update-attribute-form.set-team-field"
					>
						{() => (
							<AkSelect
								autoFocus
								defaultValue={undefined}
								aria-label={formatMessage(messages.chooseTeamPlaceholder)}
								classNamePrefix="set_team_field"
								onChange={(option: OptionType | null) => {
									this.handleOnChange('team', option?.value);
								}}
								options={[noneOption, ...options]}
								placeholder={formatMessage(messages.chooseTeamPlaceholder)}
							/>
						)}
					</Field>
				);

			default:
				return undefined;
		}
	};

	render() {
		const { onCancel, currentValue, currentIssueTypeIcon, avatarIcon, shouldFitContainerWidth } =
			this.props;

		const currentContent = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['value-placeholder']}>
				{currentIssueTypeIcon ? (
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles['issue-type-icon']}
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							backgroundSize: 'contain',
							backgroundImage: `url(${currentIssueTypeIcon})`,
						}}
					/>
				) : null}
				{avatarIcon && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<span className={styles.avatar}>
						<Avatar size="xsmall" src={avatarIcon} />
					</span>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['current-value-title']}>{currentValue}</div>
			</div>
		);

		const {
			intl: { formatMessage },
		} = this.props;
		const { fieldValue } = this.state;

		return (
			<>
				<section
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={`${styles['form-group']} ${
						shouldFitContainerWidth ? styles['should-fit-full-width'] : ''
					}`}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['current-value']}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles['value-label']}>
							<FormattedMessage {...commonMessages.current} />
						</div>
						{currentValue ? (
							<Tooltip content={currentValue}>{currentContent}</Tooltip>
						) : (
							currentContent
						)}
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.icon}>
						<ArrowRightIcon spacing="spacious" label="" />
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['new-value']}>{this.getFormField()}</div>
				</section>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<section className={styles['form-footer']}>
					<Button appearance="subtle" onClick={onCancel}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button
						appearance="primary"
						isDisabled={R.isEmpty(fieldValue)}
						onClick={(event, analyticsEvent: UIAnalyticsEvent) => {
							if (fg('plan-timeline-a11y-meatball-menu')) {
								this.submitForm(analyticsEvent, event);
							} else {
								this.submitForm(analyticsEvent);
							}
						}}
					>
						{formatMessage(commonMessages.apply)}
					</Button>
				</section>
			</>
		);
	}
}

export default injectIntl(UpdateAttributeForm);
