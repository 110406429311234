import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	distanceInWordsToNowWithPolarity,
	ONE_DAY,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function LeadTimeCell({
	row: { issue, leadTime, isOverlapping, syncStartEnabled },
}: Props) {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	let leadTimeMessage = '';

	if (isDefined(leadTime)) {
		// if "end date" of issue A is May 4, 2021 and "start date" of issue B is May 5, 2021, then leadTime = 1
		if (leadTime === 1) {
			// we display a leadTimeMessage "less than a day"
			// note: we don't use distanceInWordsToNowWithPolarity as it would return 'less than a minute'
			leadTimeMessage = formatMessage(messages.lessThanOneDayLeadTime);
		} else if (
			// if "end date" of issue A is May 4, 2021 and "start date" of issue B is May 4, 2021, then leadTime = -86399999
			Math.abs(leadTime) < ONE_DAY ||
			(leadTime < 0 && !isOverlapping && syncStartEnabled)
		) {
			// we display a leadTimeMessage of "0 days"
			leadTimeMessage = formatMessage(messages.zeroLeadTime);
		} else {
			leadTimeMessage = distanceInWordsToNowWithPolarity(locale, Date.now() + leadTime);
		}
	}
	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={
				isDefined(leadTime) && leadTime < 0 && !(!isOverlapping && syncStartEnabled)
					? styles.warning
					: ''
			}
			data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-old.flyout.cells.issue-${issue.id}.lead-time`}
		>
			{leadTimeMessage}
		</div>
	);
}
