import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moveLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-label',
		defaultMessage: 'Move',
		description:
			'Label for move single issue drop down menu item used to open a nested drop down menu in Jira Plans Timeline issue meatball menu.',
	},
	moveThisIssueLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-this-issue-label',
		defaultMessage: 'Move this issue',
		description:
			'Label for move single issue drop down menu item used to open a nested drop down menu in Jira Plans Timeline issue meatball menu.',
	},
	moveThisIssueLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-this-issue-label-issue-term-refresh',
		defaultMessage: 'Move this work item',
		description:
			'Label for move single work item drop down menu item used to open a nested drop down menu in Jira Plans Timeline issue meatball menu.',
	},
	rankLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-label',
		defaultMessage: 'Rank {count} selected {count, plural, one {issue} other {issues}}',
		description:
			'Label for ranking multiple issues drop down menu item used to open a nested drop down menu in Jira Plans Timeline issue meatball menu.',
	},
	rankLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-label-issue-term-refresh',
		defaultMessage: 'Rank {count} selected {count, plural, one {work item} other {work items}}',
		description:
			'Label for ranking multiple issues drop down menu item used to open a nested drop down menu in Jira Plans Timeline issue meatball menu.',
	},
	rankAllSelectedIssuesBelow: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-below',
		defaultMessage: 'Rank selected {count, plural, one {issue} other {issues}} below',
		description: 'Label for drop menu to rank selected issues below this',
	},
	rankAllSelectedIssuesBelowOld: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-below-old',
		defaultMessage: 'Rank selected issues below',
		description: 'Label for drop menu to rank selected issues below this',
	},
	rankAllSelectedIssuesAbove: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-above',
		defaultMessage: 'Rank selected {count, plural, one {issue} other {issues}} above',
		description: 'Label for drop menu to rank selected issues above this',
	},
	rankAllSelectedIssuesAboveOld: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-above-old',
		defaultMessage: 'Rank selected issues above',
		description: 'Label for drop menu to rank selected issues above this',
	},
	noRankItself: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.no-rank-itself',
		defaultMessage: 'Only unselected issues can be a reference for ranking.',
		description: 'Title for the no rank itself',
	},
	rankAllSelectedIssuesBelowIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-below-issue-term-refresh',
		defaultMessage: 'Rank selected {count, plural, one {work item} other {work items}} below',
		description: 'Label for drop menu to rank selected issues below this',
	},
	rankAllSelectedIssuesBelowOldIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-below-old-issue-term-refresh',
		defaultMessage: 'Rank selected work items below',
		description: 'Label for drop menu to rank selected issues below this',
	},
	rankAllSelectedIssuesAboveIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-above-issue-term-refresh',
		defaultMessage: 'Rank selected {count, plural, one {work item} other {work items}} above',
		description: 'Label for drop menu to rank selected issues above this',
	},
	rankAllSelectedIssuesAboveOldIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-above-old-issue-term-refresh',
		defaultMessage: 'Rank selected work items above',
		description: 'Label for drop menu to rank selected issues above this',
	},
	noRankItselfIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.no-rank-itself-issue-term-refresh',
		defaultMessage: 'Only unselected work items can be a reference for ranking.',
		description: 'Title for the no rank itself',
	},
	moveToTop: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-to-top',
		defaultMessage: 'Move to top',
		description:
			'Label for rank drop down menu item used move an issue to the top of the list in Jira Plans Timeline issue meatball menu.',
	},
	moveUp: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-up',
		defaultMessage: 'Move up',
		description:
			'Label for rank drop down menu item used move an issue up in the list in Jira Plans Timeline issue meatball menu.',
	},
	moveDown: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-down',
		defaultMessage: 'Move down',
		description:
			'Label for rank drop down menu item used move an issue down in the list in Jira Plans Timeline issue meatball menu.',
	},
	moveToBottom: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.move-to-bottom',
		defaultMessage: 'Move to bottom',
		description:
			'Label for rank drop down menu item used move an issue to the bottom of the list in Jira Plans Timeline issue meatball menu.',
	},
	rankAllSelectedIssuesToBottom: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-to-bottom',
		defaultMessage: 'Rank selected {count, plural, one {issue} other {issues}} to bottom',
		description: 'Label for drop menu to rank selected issues to bottom of plan',
	},
	rankAllSelectedIssuesToTop: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-to-top',
		defaultMessage: 'Rank selected {count, plural, one {issue} other {issues}} to top',
		description: 'Label for drop menu to rank selected issues to top of plan',
	},
	rankAllSelectedIssuesToBottomIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-to-bottom-issue-term-refresh',
		defaultMessage: 'Rank selected {count, plural, one {work item} other {work items}} to bottom',
		description: 'Label for drop menu to rank selected issues to bottom of plan',
	},
	rankAllSelectedIssuesToTopIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-to-top-issue-term-refresh',
		defaultMessage: 'Rank selected {count, plural, one {work item} other {work items}} to top',
		description: 'Label for drop menu to rank selected issues to top of plan',
	},
});
