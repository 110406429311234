import { fg } from '@atlassian/jira-feature-gating';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

// This function is based on an example provided here: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const isStorageAvailable = (type: 'sessionStorage' | 'localStorage') => {
	let storage: Storage | undefined;
	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		storage = window[type];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return (
			// NOTE: Flow is complaining it can't resolve name for DOMException (the commented line is the suggested one from MDN)
			// e instanceof DOMException &&
			e &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === 'QuotaExceededError' ||
				// Firefox
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage &&
			storage.length !== 0
		);
	}
};

const localStorageAvailable = isStorageAvailable('localStorage');
// TODO Add an app prefix to separate apps
const localStorageProvider = createLocalStorageProvider('');

// Create a simple object as a fallback for a lack of support for localstorage. This will allow data
const inMemoryStorage: {
	[key: string]: string;
} = {};

export const ignoredStorageErrors = [
	// Ignore localStorage.setItem() errors
	// ==================================
	// If localStorage is full, Portfolio behaves okay, but some non-JPO code can throw exceptions (e.g. atlassian-analytics).
	// So we ignore any localStorage write exceptions.

	// Chrome
	/Uncaught QuotaExceededError: Failed to execute 'setItem' on 'Storage': Setting the value of '.*' exceeded the quota/,

	// Firefox
	/NS_ERROR_DOM_QUOTA_REACHED: Persistent storage maximum size reached/,

	// Safari
	/QuotaExceededError: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota/,

	// IE 10, Edge
	/^QuotaExceededError$/,
];

const setItemInLocalStorage = (key: string, value: string): void => {
	try {
		if (localStorageAvailable) {
			fg('jfp-vulcan-browser-storage-migration')
				? localStorageProvider.set(key, value)
				: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.localStorage.setItem(key, value);
		} else {
			inMemoryStorage[key] = value;
		}
	} catch (e) {
		const message = e instanceof Error && e.message;
		if (message && ignoredStorageErrors.some((regex: RegExp) => regex.test(message))) return;
		throw e;
	}
};

const getItemFromLocalStorage = (key: string): string | null => {
	if (localStorageAvailable) {
		return fg('jfp-vulcan-browser-storage-migration')
			? localStorageProvider.get(key)
			: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.localStorage.getItem(key);
	}
	return inMemoryStorage[key];
};

const removeItemFromLocalStorage = (key: string): void => {
	if (localStorageAvailable) {
		fg('jfp-vulcan-browser-storage-migration')
			? localStorageProvider.remove(key)
			: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.localStorage.removeItem(key);
	} else {
		delete inMemoryStorage[key];
	}
};

export const storage = {
	getItem: (key: string): string | null => getItemFromLocalStorage(key),
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	setItem: (key: string, value: string): string | void => setItemInLocalStorage(key, value),
	removeItem: (key: string): void => removeItemFromLocalStorage(key),
} as const;

// A promise based storage based on the above. For use in redux-persist.
export const promiseStorage = {
	getItem: (key: string): Promise<string | null> => Promise.resolve(getItemFromLocalStorage(key)),
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	setItem: (key: string, value: string): Promise<string | void> =>
		Promise.resolve(setItemInLocalStorage(key, value)),
	removeItem: (key: string): Promise<void> => Promise.resolve(removeItemFromLocalStorage(key)),
} as const;
