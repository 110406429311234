import React, { useEffect } from 'react';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PinnedFieldsContainer } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ideas-cell/idea-issue/issue-card-pinned-fields/src/controllers/index.tsx';
import type { WrapperProps } from './types.tsx';

// isConfluenceMacroProxyMode pattern is a workaround for POL-12202
const Wrapper = ({
	associatedIssueIds,
	children,
	isConfluenceMacroProxyMode,
	fetchMissingAssociatedIssues,
	associatedIssues,
}: WrapperProps) => {
	const cloudId = useCloudId();

	// keep this useEffect, because we don't want to fetchMissingAssociatedIssues when associatedIssues are changed
	useEffect(() => {
		if (!fg('polaris-arj-eap-override') || isConfluenceMacroProxyMode) {
			return; // logic from "loadMissingAssociatedIssues" from "src/packages/portfolio-3/associated-issues/src/controllers/actions/index.tsx"
		}
		fetchMissingAssociatedIssues({ associatedIssueIds, cloudId });
	}, [associatedIssueIds, cloudId, fetchMissingAssociatedIssues, isConfluenceMacroProxyMode]);

	let projectIds: Array<string> = [];
	if (associatedIssues && fg('polaris-pinned-fields-for-ideas-in-plans')) {
		const allProjectIds = Object.keys(associatedIssues).map(
			(key) => associatedIssues[key].projectId || '',
		);
		projectIds = Array.from(new Set(allProjectIds));
	}

	return fg('polaris-pinned-fields-for-ideas-in-plans') ? (
		<PinnedFieldsContainer projectIds={projectIds}>
			<>{children}</>
		</PinnedFieldsContainer>
	) : (
		<>{children}</>
	);
};

export default Wrapper;
