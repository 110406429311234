import type { State as AssociatedIssueState } from './types.tsx';
import type { AssociatedIssuesActions } from './actions.tsx';

export const initialState: AssociatedIssueState = {
	associatedIssues: {},
	lastAssociatedIssueIds: [],
	missingAssociatedIssuesFetchInProgress: false,
};

export const associatedIssuesDomainReducer = (
	state: AssociatedIssueState = initialState,
	action: AssociatedIssuesActions,
): AssociatedIssueState => {
	switch (action.type) {
		case 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_START': {
			return {
				...state,
				missingAssociatedIssuesFetchInProgress: true,
			};
		}
		case 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_SUCCESS':
			return {
				...state,
				associatedIssues: { ...state.associatedIssues, ...action.payload.associatedIssues },
				lastAssociatedIssueIds: action.payload.lastAssociatedIssueIds,
				missingAssociatedIssuesFetchInProgress: false,
			};
		case 'state.domain.associated-issues.FETCH_MISSING_ASSOCIATED_ISSUES_FAIL':
			return {
				...state,
				missingAssociatedIssuesFetchInProgress: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
