// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

export const actions = {
	hover:
		(column: number | undefined, value: boolean): Action<State> =>
		({ getState, setState }) => {
			const state = getState();

			if (state.active && column !== state.column) {
				return;
			}

			setState({
				column,
				hovered: value,
			});
		},
	activate:
		(column: number | undefined, value: boolean): Action<State> =>
		({ setState }) => {
			setState({
				column,
				active: value,
			});
		},
};
