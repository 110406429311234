import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.create-label',
		defaultMessage: 'Create',
		description:
			'Label for create drop down menu item used to open a nested drop down menu in Jira Plan timeline issue meatball menu.',
	},
	createLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.create-label-issue-term-refresh',
		defaultMessage: 'Create',
		description:
			'Label for create drop down menu item used to open a nested drop down menu in Jira Plan timeline issue meatball menu.',
	},
	childIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.child-icon-label',
		defaultMessage: 'Child',
		description: 'Label for child icon',
	},
	createIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.create-issue',
		defaultMessage: 'Create {issueType}',
		description: 'Label for drop menu to create new issue from an issue',
	},
});
