import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import type { State } from './types.tsx';

const context = createContext<State>({
	column: -1,
	colSpan: 1,
});

export const CellProvider: React.FC<PropsWithChildren<State>> = ({ column, colSpan, children }) => {
	const value = useMemo(() => ({ column, colSpan }), [column, colSpan]);
	return <context.Provider value={value}>{children}</context.Provider>;
};

export const useCell = () => {
	const { column, colSpan } = useContext(context);
	return [{ column, colSpan }];
};
