import countBy from 'lodash/countBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	getAssociatedIssues,
	getMissingAssociatedIssuesFetchInProgress,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/associated-issues/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import type { OwnProps, MapStateToProps, IdeaValue } from './types.tsx';

export const getValuesPure = (
	associatedIssues: Record<string, AssociatedIssue>,
	rawValues: string[],
): IdeaValue[] => {
	const ideasCount = countBy(rawValues);
	const uniqueIdeas = Object.keys(ideasCount).sort((a, b) => ideasCount[b] - ideasCount[a]);

	return uniqueIdeas
		.map((id) => {
			if (!associatedIssues[id]) return undefined;

			return { id, data: associatedIssues[id], count: ideasCount[id] };
		})
		.filter(Boolean);
};

const getValues = createSelector(
	[getAssociatedIssues, (_: State, { rawValues }: OwnProps) => rawValues],
	getValuesPure,
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	values: getValues,
	loading: getMissingAssociatedIssuesFetchInProgress,
});

export default mapStateToProps;
