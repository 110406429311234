import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	selectedIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.selected-items.selected-issue-term-refresh',
		description:
			'Label displayed in the Jira plan timeline meatball menu to indicate the number of selected items.',
		defaultMessage:
			'<badge>{count}</badge> {count, plural, one {Work item} other {Work items}} selected',
	},
	selected: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.selected-items.selected',
		description:
			'Label displayed in the Jira plan timeline meatball menu to indicate the number of selected items.',
		defaultMessage: '<badge>{count}</badge> {count, plural, one {Issue} other {Issues}} selected',
	},
});

export default messages;
