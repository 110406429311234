import type {
	ExternalIssue,
	IssueStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ISSUE_STATUS_CATEGORIES,
	VALID_LEADING_TIME_FOR_DEPENDENCIES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { ScopeIssue } from '../../state/domain/scope/types.tsx';
import { swapIssueBaselineDatesIfInverted } from '../../util/issue-helper.tsx';

type IssueType = ScopeIssue | Issue | ExternalIssue;

const isIssueDone = (
	issue: IssueType,
	issueStatusById: {
		[id: string]: IssueStatus;
	},
): boolean => {
	if ('statusCategoryId' in issue && typeof issue.statusCategoryId === 'number') {
		return issue.statusCategoryId === ISSUE_STATUS_CATEGORIES.DONE;
	}
	if ('status' in issue && typeof issue.status === 'string') {
		// Note: issueStatusById[issue.status] should normally not be be absent, but a small number of customers
		// were hitting this case and a false default is better than the plan crashing
		return issueStatusById[issue.status]?.categoryId === ISSUE_STATUS_CATEGORIES.DONE;
	}
	return false;
};

export const getIssueLinkIfOverlapping = (
	sourceIssue: IssueType | null | undefined,
	targetIssue: IssueType | null | undefined,
	issueStatusById: {
		[id: string]: IssueStatus;
	},
	syncStartEnabled: boolean,
): boolean => {
	if (!isDefined(sourceIssue) || !isDefined(targetIssue)) {
		return false;
	}

	// if either sourceIssue or targetIssue is DONE, there is no blocking dependency
	if (isIssueDone(sourceIssue, issueStatusById) || isIssueDone(targetIssue, issueStatusById)) {
		return false;
	}

	let sourceIssueSprint: string | null | undefined;
	// Ensure baselineStart is before baselineEnd, for 'correct' dependency line color in UI
	const { baselineStart: sourceIssueStartTime, baselineEnd: sourceIssueEndTime } =
		swapIssueBaselineDatesIfInverted(sourceIssue);

	if ('sprint' in sourceIssue && sourceIssue.sprint !== 'undefined') {
		sourceIssueSprint = sourceIssue.sprint;
	}

	let targetIssueSprint;
	// Ensure baselineStart is before baselineEnd, for 'correct' dependency line color in UI
	const { baselineStart: targetIssueStartTime, baselineEnd: targetIssueEndTime } =
		swapIssueBaselineDatesIfInverted(targetIssue);

	if ('sprint' in targetIssue && targetIssue.sprint !== 'undefined') {
		targetIssueSprint = targetIssue.sprint;
	}

	// if the issues are in the same sprint and Plan Settings > Dependencies > Concurrent is selected
	if (
		isDefined(sourceIssueSprint) &&
		isDefined(targetIssueSprint) &&
		sourceIssueSprint === targetIssueSprint &&
		syncStartEnabled
	) {
		// there is a blocking dependency if the source issue starts after the target issue start time
		if (isDefined(sourceIssueStartTime)) {
			if (isDefined(targetIssueStartTime)) {
				return sourceIssueStartTime > targetIssueStartTime;
			}
			// if the target issue does not have a start time or finish time then there is no blocking dependency
			if (!isDefined(targetIssueEndTime)) return false;
			// There is a blocking dependency if the source issue starts after the target issue finishes
			return sourceIssueStartTime > targetIssueEndTime;
		}

		return false;
	}

	// if:
	// - the issues are in the same sprint AND Plan Settings > Dependencies > Sequential is selected; OR
	// - the issues are NOT in the same sprint AND Plan Settings > Dependencies > Sequential is selected; OR
	// - the issues are NOT in the same sprint AND Plan Settings > Dependencies > Concurrent is selected
	// then any issue A blocked by an issue B should be scheduled after issue A
	if (isDefined(targetIssueEndTime)) {
		// There is a blocking dependency if the source issue has no end date and the target issue has an end date
		if (!isDefined(sourceIssueEndTime)) return true;

		// There is a blocking dependency if the target issue has no start date and the source issue has an end date
		if (!isDefined(targetIssueStartTime)) return true;

		// there is a blocking dependency if:
		// - the source issue end time is higher than the target issue start time and the leading time (sourceIssueEndTime - targetIssueStartTime) is bigger than VALID_LEADING_TIME_FOR_DEPENDENCIES(always be positive);

		return sourceIssueEndTime - targetIssueStartTime > VALID_LEADING_TIME_FOR_DEPENDENCIES;
	}

	return false;
};

export const getIssueLinkLeadTime = (
	sourceIssue?: IssueType | null,
	targetIssue?: IssueType | null,
): number | null => {
	// Ensure baselineStart is before baselineEnd, for 'correct' lead time calculation in UI
	const sourceIssueOrderedDates = swapIssueBaselineDatesIfInverted(sourceIssue);
	const targetIssueOrderedDates = swapIssueBaselineDatesIfInverted(targetIssue);
	if (
		isDefined(sourceIssueOrderedDates) &&
		isDefined(sourceIssueOrderedDates.baselineEnd) &&
		isDefined(targetIssueOrderedDates) &&
		isDefined(targetIssueOrderedDates.baselineStart)
	) {
		return targetIssueOrderedDates.baselineStart - sourceIssueOrderedDates.baselineEnd;
	}
	return null;
};
