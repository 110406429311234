import React, { useCallback, useMemo } from 'react';
import type { TriggerProps, ContentProps } from '@atlaskit/popup';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type {
	AnyEntryPoint,
	RuntimePropsOfEntryPoint,
} from '@atlassian/jira-entry-point/src/common/types.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { Props } from './types.tsx';

const EntryPointPopup = <EntryPoint extends AnyEntryPoint>({
	trigger,
	content,
	fallback,
	entryPoint,
	entryPointParams,
	id,
	packageName,
	teamName,
	...props
}: Props<EntryPoint>) => {
	// eslint-disable-next-line @atlassian/react-entrypoint/prefer-entrypoint-file-import
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		entryPoint,
		entryPointParams,
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const renderTrigger = useCallback(
		({ ref, ...triggerProps }: TriggerProps) =>
			trigger({ ref: mergeRefs(ref, triggerRef), ...triggerProps }),
		[trigger, triggerRef],
	);

	// Render once and memoise to stablise the dependency
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoFallback = useMemo(() => fallback(), []);

	const renderComponent = useCallback(
		(runtimeProps: RuntimePropsOfEntryPoint<EntryPoint>) => {
			return (
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id={id}
					packageName={packageName}
					teamName={teamName}
					fallback={memoFallback}
					runtimeProps={runtimeProps}
				/>
			);
		},
		[entryPointReferenceSubject, id, packageName, teamName, memoFallback],
	);

	const renderContent = useCallback(
		(contentProps: ContentProps) =>
			content({
				Component: renderComponent,
				...contentProps,
			}),
		[content, renderComponent],
	);

	return <Popup trigger={renderTrigger} content={renderContent} {...props} />;
};

export default EntryPointPopup;
