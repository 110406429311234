import { type MessageDescriptor, defineMessages } from '@atlassian/jira-intl';
import {
	ConfigurableDateTypes,
	type ConfigurableDate,
} from '@atlassian/jira-portfolio-3-common/src/sections/types.tsx';
import { PLANNING_UNITS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';

export type Message = MessageDescriptor;
const planningUnits = {
	storyPoints: 'StoryPoints',
	days: 'Days',
	hours: 'Hours',
} as const;
const messages = defineMessages({
	targetStartColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.target-start-column-header',
		defaultMessage: 'Target start',
		description: 'This is a header for the target start column.',
	},
	targetEndColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.target-end-column-header',
		defaultMessage: 'Target end',
		description: 'This is a header for the target end column.',
	},
	unmappedColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.unmapped-column-header',
		defaultMessage: 'Unmapped',
		description: 'This is a header for the unmapped column.',
	},
	teamColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.team-column-header',
		defaultMessage: 'Team',
		description: 'This is a header for the team column.',
	},
	issueStatusColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.issue-status-column-header',
		defaultMessage: 'Status',
		description: 'This is a header for the issue status column.',
	},
	storyPointsEstimateColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.story-points-estimate-column-header',
		defaultMessage: 'Story points',
		description: 'This is a header for the estimate column.',
	},
	hoursEstimateColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.hours-estimate-column-header',
		defaultMessage: 'Estimates (h)',
		description: 'This is a header for the estimate column.',
	},
	daysEstimateColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.days-estimate-column-header',
		defaultMessage: 'Estimates (d)',
		description: 'This is a header for the estimate column.',
	},
	breakdownColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.breakdown-column-header',
		defaultMessage: 'Progress (issue count)',
		description: 'This is a header for the status breakdown column.',
	},
	progressEstimateByPointsColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.progress-estimate-by-points-column-header',
		defaultMessage: 'Progress (story points)',
		description: 'This is a header for the progress by story points column.',
	},
	progressEstimateByHoursColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.progress-estimate-by-hours-column-header',
		defaultMessage: 'Progress (hours)',
		description: 'This is a header for the progress by hours column.',
	},
	progressEstimateByDaysColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.progress-estimate-by-days-column-header',
		defaultMessage: 'Progress (days)',
		description: 'This is a header for the progress by days column.',
	},
	blockedByColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.blocked-by-column-header',
		defaultMessage: 'Blocked by',
		description: 'This is the field column header for "Blocked by", i.e. incoming dependencies',
	},
	blocksColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.blocks-column-header',
		defaultMessage: 'Blocks',
		description: 'This is the field column header for "Blocks", i.e. outgoing dependencies',
	},
	dependenciesColumnsHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.dependencies-columns-header',
		defaultMessage: 'Dependencies',
		description:
			'This is a text for the menu entry which toggles both incoming and outgoing dependencies columns',
	},
	componentsColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.components-column-header',
		defaultMessage: 'Components',
		description: 'This is a text for the components column header',
	},
	labelsColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.labels-column-header',
		defaultMessage: 'Labels',
		description: 'This is a text for the labels column header',
	},
	parentColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.parent-column-header',
		defaultMessage: 'Parent',
		description: 'This is a text for the parent column header',
	},
	priorityColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.priority-column-header',
		defaultMessage: 'Priority',
		description: 'This is a text for the priority column header',
	},
	goalColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.goal-column-header',
		defaultMessage: 'Goal',
		description: 'This is a text for the ATLAS goal column header',
	},
	ideasColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.ideas-column-header',
		defaultMessage: 'Idea',
		description: 'This is a text for the JPD ideas column header',
	},
	configuredDateStart: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.configured-date-start',
		defaultMessage: '{title} is being used as the start date for the issues in this plan.',
		description: 'Tooltip for the configured start date the timeline uses',
	},
	configuredDateEnd: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.configured-date-end',
		defaultMessage: '{title} is being used as the end date for the issues in this plan.',
		description: 'Tooltip for the configured end date the timeline uses',
	},
	addFieldsColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.add-fields-column-header',
		defaultMessage: 'Add fields',
		description: 'This is a text for the add-fields field',
	},
	breakdownColumnHeaderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.breakdown-column-header-issue-term-refresh',
		defaultMessage: 'Progress (work item count)',
		description: 'This is a header for the status breakdown column.',
	},
	configuredDateStartIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.configured-date-start-issue-term-refresh',
		defaultMessage: '{title} is being used as the start date for the work items in this plan.',
		description: 'Tooltip for the configured start date the timeline uses',
	},
	configuredDateEndIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.configured-date-end-issue-term-refresh',
		defaultMessage: '{title} is being used as the end date for the work items in this plan.',
		description: 'Tooltip for the configured end date the timeline uses',
	},
	focusAreasColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.focus-areas-column-header',
		defaultMessage: 'Focus area',
		description: 'This is a text for the focus areas field',
	},
});
export const getEstimateColumnTitle = (planningUnit: string): MessageDescriptor => {
	switch (planningUnit) {
		case planningUnits.storyPoints:
			return messages.storyPointsEstimateColumnHeader;
		case planningUnits.days:
			return messages.daysEstimateColumnHeader;
		case planningUnits.hours:
		default:
			return messages.hoursEstimateColumnHeader;
	}
};
export const getProgressByEstimateColumnTitle = (planningUnit: string): MessageDescriptor => {
	switch (planningUnit) {
		case planningUnits.storyPoints:
			return messages.progressEstimateByPointsColumnHeader;
		case planningUnits.days:
			return messages.progressEstimateByDaysColumnHeader;
		case planningUnits.hours:
		default:
			return messages.progressEstimateByHoursColumnHeader;
	}
};
export const getConfiguredDateTooltip = (
	configurableDate?: ConfigurableDate | null,
): undefined | MessageDescriptor => {
	switch (configurableDate) {
		case ConfigurableDateTypes.start:
			return messages.configuredDateStart;
		case ConfigurableDateTypes.end:
			return messages.configuredDateEnd;
		default:
	}
};
export const getHeaderMessage = (
	sectionId: string,
	planningUnit: string,
): undefined | MessageDescriptor => {
	switch (sectionId) {
		case 'targetStart':
			return messages.targetStartColumnHeader;
		case 'targetEnd':
			return messages.targetEndColumnHeader;
		case 'dueDate':
			return commonMessages.dueDate;
		case 'assignee':
			return commonMessages.assignee;
		case 'sprint':
			return commonMessages.sprint;
		case 'unmapped':
			return messages.unmappedColumnHeader;
		case 'team':
			return messages.teamColumnHeader;
		case 'issueStatus':
			return messages.issueStatusColumnHeader;
		case 'release':
			return commonMessages.releases;
		case 'breakdown':
			return messages.breakdownColumnHeader;
		case 'incomingDependencies':
			return messages.blockedByColumnHeader;
		case 'outgoingDependencies':
			return messages.blocksColumnHeader;
		case 'dependencies':
			return messages.dependenciesColumnsHeader;
		case 'components':
			return messages.componentsColumnHeader;
		case 'labels':
			return messages.labelsColumnHeader;
		case 'parent':
			return messages.parentColumnHeader;
		case 'priority':
			return messages.priorityColumnHeader;
		case 'estimate': {
			switch (planningUnit) {
				case PLANNING_UNITS.HOURS:
					return messages.hoursEstimateColumnHeader;
				case PLANNING_UNITS.STORY_POINTS:
					return messages.storyPointsEstimateColumnHeader;
				case PLANNING_UNITS.DAYS:
					return messages.daysEstimateColumnHeader;
				default:
					return undefined;
			}
		}
		case 'progressByEstimation': {
			switch (planningUnit) {
				case PLANNING_UNITS.HOURS:
					return messages.progressEstimateByHoursColumnHeader;
				case PLANNING_UNITS.STORY_POINTS:
					return messages.progressEstimateByPointsColumnHeader;
				case PLANNING_UNITS.DAYS:
					return messages.progressEstimateByDaysColumnHeader;
				default:
					return undefined;
			}
		}
		default:
	}
};
export default messages;
