/** @jsx jsx */
import React, { cloneElement, forwardRef, useCallback, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import { mergeRefs } from 'use-callback-ref';
import StandardButton from '@atlaskit/button/standard-button';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { fg } from '@atlassian/jira-feature-gating';
import DropMenu from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import BulkActions from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/bulk-actions/index.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { DraggableIconButton } from '../draggable-button/index.tsx';

import { BULK_ACTION } from '../constants.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Children, Props, PropsOld } from './types.tsx';
import RankIssue from './rank-issue/index.tsx';
import RemoveIssue from './remove-issue/index.tsx';
import CreateIssue from './create-issue/index.tsx';
import CreateIssueWithType from './create-issue-type/index.tsx';
import SelectIssue from './select-issue/view.tsx';

function Divider() {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	return <div className={styles.divider} />;
}

function withDivider(children: Children): Children {
	if (!Array.isArray(children)) {
		return children;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
	const result = children.reduce<Array<any>>((result, elem, idx) => {
		const child = cloneElement(elem, { ...elem.props, key: idx });
		const divider = <Divider key={`divider-${idx}`} />;
		if (idx === 0) {
			return [child];
		}

		return result.concat([divider, child]);
	}, []);

	return result;
}

export const IssueDropMenu = forwardRef<HTMLButtonElement, Props>(
	(
		{
			ariaLabel = '',
			handleBlurEvent,
			onOpenChange,
			hierarchyLevel,
			issue,
			projects,
			groupCombination,
			startInlineCreate,
			isDisabled,
			toggleAiWorkBreakdownPopup,
			hierarchyLevelsForType,
			typeToLevel,
			triggerClassName,
			onRequestSelect,
			setModalAction,
			handleModalClose,
			isSelected,
			selectedIssueCount,
			isDropMenuOpen,
			onOptionSelect,
			defaultIsOpen,
		},
		outerRef,
	) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const innerRef = useRef<HTMLButtonElement>(null);
		const ref = useMergeRefs(innerRef, outerRef);

		const handleOnOpenChange = useCallback(
			({ isOpen }: { isOpen: boolean }) => {
				if (isOpen) {
					const [actionSubject, eventAction] =
						PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_ISSUE_MORE_ACTIONS_MENU.split(' ');
					const analyticsEvent = createAnalyticsEvent({
						action: eventAction,
						actionSubject,
					});
					fireUIAnalytics(analyticsEvent, 'plansScopeIssueMoreActionsButton', {
						hierarchyLevel,
					});
				}
				onOpenChange({ isOpen });
			},
			[createAnalyticsEvent, hierarchyLevel, onOpenChange],
		);

		const showBulkActions = isSelected && selectedIssueCount > 0;

		const onBulkActionsModalOpen = useCallback(() => {
			setModalAction(BULK_ACTION);
		}, [setModalAction]);

		const content = useCallback(
			(renderBulkDropdown?: () => React.ReactNode) => (
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
				<div className={styles.root}>
					<DropMenu
						isOpen={isDropMenuOpen}
						defaultOpen={defaultIsOpen}
						trigger={({ triggerRef, onBlur, ...props }) =>
							!fg('plan-timeline-non-transposed') ? (
								<DraggableIconButton
									{...props}
									ref={mergeRefs([triggerRef, ref])}
									appearance="subtle"
									icon={MoreIcon}
									label={ariaLabel}
									onBlur={() => {
										onBlur?.();
										handleBlurEvent?.();
									}}
								/>
							) : (
								<StandardButton
									{...props}
									ref={mergeRefs([triggerRef, ref])}
									appearance="subtle"
									iconAfter={<span />}
									spacing="none"
									onBlur={() => {
										onBlur?.();
										handleBlurEvent?.();
									}}
									aria-label={ariaLabel}
								>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<div className={styles.moreIconWrapper}>
										<MoreIcon label="" color="currentColor" />
									</div>
								</StandardButton>
							)
						}
						shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
						onOpenChange={handleOnOpenChange}
						placement="bottom-end"
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.more-meatball"
					>
						<div css={[showBulkActions ? contentWrapperLargeStyles : contentWrapperStyles]}>
							<DropdownItemGroup>
								{fg('plan_timeline_issue_type_create_inline') ? (
									<CreateIssueWithType
										key="create-issue-type-dropdown-menu-item"
										issue={issue}
										projects={projects}
										groupCombination={groupCombination}
										startInlineCreate={startInlineCreate}
										isDisabled={isDisabled}
										toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
										onOptionSelect={onOptionSelect}
									/>
								) : (
									<CreateIssue
										key="create-issue-dropdown-menu-item"
										issue={issue}
										hierarchyLevelsForType={hierarchyLevelsForType}
										projects={projects}
										typeToLevel={typeToLevel}
										startInlineCreate={startInlineCreate}
										triggerClassName={triggerClassName}
										groupCombination={groupCombination}
										isDisabled={isDisabled}
										toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
									/>
								)}
								<RankIssue
									key="rank-issue-dropdown-menu-item"
									issueId={issue.id}
									issueLevel={issue.level}
									issueGroup={issue.group}
									selectedIssueCount={selectedIssueCount}
									parentId={issue.parent}
									isSelected={isSelected}
									onOptionSelect={onOptionSelect}
								/>
								<SelectIssue
									key="select-issue-dropdown-menu-item"
									onRequestSelect={onRequestSelect}
									onOptionSelect={onOptionSelect}
								/>
							</DropdownItemGroup>
							<DropdownItemGroup hasSeparator>
								<RemoveIssue
									key="remove-issue-dropdown-menu-item"
									issue={issue}
									isDisabled={isDisabled}
									setModalAction={setModalAction}
								/>
							</DropdownItemGroup>
							{showBulkActions ? (
								<DropdownItemGroup hasSeparator>{renderBulkDropdown?.()}</DropdownItemGroup>
							) : null}
						</div>
					</DropMenu>
				</div>
			),
			[
				ariaLabel,
				defaultIsOpen,
				groupCombination,
				handleBlurEvent,
				handleOnOpenChange,
				hierarchyLevelsForType,
				isDisabled,
				isDropMenuOpen,
				isSelected,
				issue,
				onOptionSelect,
				onRequestSelect,
				projects,
				ref,
				selectedIssueCount,
				setModalAction,
				showBulkActions,
				startInlineCreate,
				toggleAiWorkBreakdownPopup,
				triggerClassName,
				typeToLevel,
			],
		);

		const renderContentWithBulkActions = useCallback(
			(renderBulkDropdown: () => React.ReactNode, renderBulkDialog: () => React.ReactNode) => (
				<>
					{content(renderBulkDropdown)}
					{renderBulkDialog()}
				</>
			),
			[content],
		);

		return showBulkActions ? (
			<BulkActions
				meatballTriggerRef={innerRef}
				onOptionSelect={onOptionSelect}
				onModalOpen={onBulkActionsModalOpen}
				onModalClose={handleModalClose}
			>
				{renderContentWithBulkActions}
			</BulkActions>
		) : (
			content()
		);
	},
);

export const IssueDropMenuOld = ({
	ariaLabel = '',
	handleBlurEvent,
	onOpenChange,
	children,
	hierarchyLevel,
}: PropsOld) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnOpenChange = ({ isOpen }: { isOpen: boolean }) => {
		if (isOpen) {
			const [actionSubject, eventAction] =
				PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_ISSUE_MORE_ACTIONS_MENU.split(' ');
			const analyticsEvent = createAnalyticsEvent({
				action: eventAction,
				actionSubject,
			});
			fireUIAnalytics(analyticsEvent, 'plansScopeIssueMoreActionsButton', {
				hierarchyLevel,
			});
		}
		onOpenChange({ isOpen });
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.root}>
			<DropMenu
				trigger={({ triggerRef, onBlur, ...props }) =>
					!fg('plan-timeline-non-transposed') ? (
						<DraggableIconButton
							{...props}
							ref={triggerRef}
							appearance="subtle"
							icon={MoreIcon}
							label={ariaLabel}
							onBlur={handleBlurEvent}
						/>
					) : (
						<StandardButton
							{...props}
							ref={triggerRef}
							appearance="subtle"
							iconAfter={<span />}
							spacing="none"
							onBlur={handleBlurEvent}
						>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.moreIconWrapper}>
								<MoreIcon label={ariaLabel} color="currentColor" />
							</div>
						</StandardButton>
					)
				}
				shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
				onOpenChange={handleOnOpenChange}
				placement="bottom-end"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.more-meatball"
			>
				{withDivider(children)}
			</DropMenu>
		</div>
	);
};

const contentWrapperStyles = css({
	minWidth: '240px',
});

const contentWrapperLargeStyles = css({
	minWidth: '280px',
});
