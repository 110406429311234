import type { Effect } from 'redux-saga';
import { call, fork, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	SET_INTRO_OPEN,
	SET_ONBOARDING_SHOWN,
	type SetOnBoardingShownAction,
} from '../../state/ui/onboarding/actions.tsx';
import { POST } from '../api.tsx';
import { toErrorID } from '../util.tsx';
import { urls, setUserPropertyBody } from './api.tsx';

export const setIntroOpen = (payload: boolean) => ({
	type: SET_INTRO_OPEN,
	payload,
});

export function* doSetOnboardingShown({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SetOnBoardingShownAction): Generator<Effect, any, any> {
	const url = urls.set;
	const body = setUserPropertyBody('onboadingShown', payload);
	const response = yield call(fetch, url, {
		method: POST,
		body,
	});
	if (!response.ok) {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'set-onboarding-shown-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSetOnboarding(): Generator<Effect, any, any> {
	yield takeEvery(SET_ONBOARDING_SHOWN, doSetOnboardingShown);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchSetOnboarding);
}
