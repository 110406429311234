import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { GROUP_DESCRIPTOR } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/group/constants.tsx';
import { DateCell } from './date-cell/index.tsx';
import EstimateCell from './estimate-cell/index.tsx';
import StatusCell from './status-cell/index.tsx';
import UserCell from './user-cell/index.tsx';
import TeamCell from './team-cell/index.tsx';
import GoalCell from './goal-cell/index.tsx';
import ReleaseCell from './release-cell/index.tsx';
import PriorityCell from './priority-cell/index.tsx';
import LabelCell from './label-cell/index.tsx';
import IdeasCell from './ideas-cell/index.tsx';
import ProgressEstimateCell from './progress-estimate-cell/index.tsx';
import { CUSTOM, type Props, type PropsOld } from './types.tsx';
import DependenciesCell from './dependencies-cell/index.tsx';

export const AggregateFieldOld = ({ field, groupName }: PropsOld) => {
	if (!field || field?.fieldValue === undefined) {
		// Leave blank if the value is not defined
		return null;
	}
	const { fieldValue, fieldKey, type } = field;

	switch (fieldKey) {
		case 'dueDate':
		case 'startDate':
		case 'targetStart':
		case 'targetEnd':
			return <DateCell fieldKey={fieldKey} groupName={groupName} value={fieldValue} type={type} />;
		case 'storyPoints':
		case 'timeEstimate':
			return (
				<EstimateCell fieldKey={fieldKey} groupName={groupName} rawValue={fieldValue} type={type} />
			);
		default:
			return null;
	}
};

export const AggregateField = ({
	field,
	groupName,
	totalIssues,
	groupDescriptor,
	groupByLabel: groupByLabelRaw,
}: Props) => {
	const { formatMessage } = useIntl();
	const swimlane = useMemo((): string | undefined => {
		return 'groupName' in groupDescriptor
			? groupDescriptor.groupName
			: formatMessage(GROUP_DESCRIPTOR[groupDescriptor.messageKey], {
					count: groupDescriptor.count,
				});
	}, [formatMessage, groupDescriptor]);

	const groupByLabel = useMemo(() => {
		if (groupByLabelRaw.type === CUSTOM) {
			return groupByLabelRaw.message;
		}
		return formatMessage(groupByLabelRaw.message);
	}, [groupByLabelRaw, formatMessage]);

	if (!field) {
		return null;
	}
	const { fieldValue, fieldKey, type } = field;

	switch (fieldKey) {
		case 'dueDate':
		case 'startDate':
		case 'targetStart':
		case 'targetEnd':
			return <DateCell fieldKey={fieldKey} groupName={groupName} value={fieldValue} type={type} />;
		case 'storyPoints':
		case 'timeEstimate':
			return (
				<EstimateCell fieldKey={fieldKey} groupName={groupName} rawValue={fieldValue} type={type} />
			);

		// second iteration
		case 'status':
		case 'breakdown':
			return (
				<StatusCell
					fieldKey={fieldKey}
					groupName={groupName}
					value={fieldValue}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'progressByEstimation':
			return (
				<ProgressEstimateCell
					fieldKey={fieldKey}
					groupName={groupName}
					value={fieldValue}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'assignee':
			return (
				<UserCell
					fieldKey={fieldKey}
					groupName={groupName}
					rawValues={fieldValue}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'team':
			return (
				<TeamCell
					fieldKey={fieldKey}
					groupName={groupName}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
					rawValues={fieldValue.values}
					unassignedCount={fieldValue.unassignedCount}
					totalIssues={totalIssues}
				/>
			);

		case 'priority':
			return (
				<PriorityCell
					fieldKey={fieldKey}
					groupName={groupName}
					value={fieldValue}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'goals':
			return (
				<GoalCell
					fieldKey={fieldKey}
					groupName={groupName}
					rawValues={fieldValue.values}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
					unassignedCount={fieldValue.unassignedCount}
					totalIssues={totalIssues}
				/>
			);

		case 'fixVersions':
			return (
				<ReleaseCell
					fieldKey={fieldKey}
					groupName={groupName}
					totalIssues={totalIssues}
					rawValues={fieldValue.values}
					unassignedCount={fieldValue.unassignedCount}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'labels':
			return (
				<LabelCell
					fieldKey={fieldKey}
					groupName={groupName}
					rawValues={fieldValue.values}
					unassignedCount={fieldValue.unassignedCount}
					totalIssues={totalIssues}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'ideas':
			return (
				<IdeasCell
					fieldKey={fieldKey}
					groupName={groupName}
					rawValues={fieldValue.values}
					unassignedCount={fieldValue.unassignedCount}
					totalIssues={totalIssues}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		case 'incomingDependencies':
		case 'outgoingDependencies':
			return (
				<DependenciesCell
					fieldKey={fieldKey}
					groupName={groupName}
					rawValues={fieldValue}
					isIncoming={fieldKey === 'incomingDependencies'}
					groupByLabel={groupByLabel}
					swimlane={swimlane}
				/>
			);

		default:
			return null;
	}
};

export default AggregateField;
