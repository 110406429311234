import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import mapDispatchToProps from './command.tsx';

import mapStateToProps from './query.tsx';
import View from './view.tsx';
import ViewOld from './view-old.tsx';

export default componentWithFG(
	'jira_plan_refactor_class_to_function_component',
	connect(mapStateToProps, mapDispatchToProps)(View),
	connect(mapStateToProps, mapDispatchToProps)(ViewOld),
);
