import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';

import messages from './messages.tsx';
import type { CommitNotificationMessages } from './types.tsx';

export function useCommitNotificationMessages() {
	const { formatMessage } = useIntl();

	const getCommitNotificationMessages = useCallback(
		(
			show: boolean,
			failedCommitCount: number,
			successfulCommitCount: number,
		): CommitNotificationMessages => {
			if (!show) return null;

			let title;
			let description;
			const onlySuccessful = successfulCommitCount > 0 && !failedCommitCount;
			if (onlySuccessful) {
				title = formatMessage(messages.successHeaderText);
				description = [formatMessage(messages.successContentText, { successfulCommitCount })];
			} else {
				title = formatMessage(messages.warningHeaderText, { successfulCommitCount });
				if (!successfulCommitCount) {
					description = [formatMessage(messages.warningContentText, { failedCommitCount })];
				} else {
					description = [
						formatMessage(messages.warningWithSuccessContentSuccessText, {
							successfulCommitCount,
						}),
						formatMessage(messages.warningWithSuccessContentWarningText, { failedCommitCount }),
					];
				}
			}

			return {
				onlySuccessful,
				title,
				description,
			};
		},
		[formatMessage],
	);

	return { getCommitNotificationMessages };
}
