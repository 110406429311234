import React from 'react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	MISSING_ASSOCIATED_ISSUES_FETCH_LIMIT_ERROR,
	type MISSING_ASSOCIATED_ISSUES_GENERAL_ERROR,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/flags/types.tsx';
import messages from './messages.tsx';

type MissingAssociatedIssuesErrorFlagProps = {
	variant:
		| typeof MISSING_ASSOCIATED_ISSUES_FETCH_LIMIT_ERROR
		| typeof MISSING_ASSOCIATED_ISSUES_GENERAL_ERROR;
};

export const MissingAssociatedIssuesErrorFlag = ({
	variant,
}: MissingAssociatedIssuesErrorFlagProps) => {
	const { formatMessage } = useIntl();

	return (
		<Flag
			key={variant}
			id={variant}
			icon={
				<ErrorIcon
					color={token('color.icon.danger')}
					label={formatMessage(messages.errorFlagIconLabel)}
				/>
			}
			title={formatMessage(
				variant === MISSING_ASSOCIATED_ISSUES_FETCH_LIMIT_ERROR
					? messages.fetchLimitErrorFlagTitle
					: messages.errorFlagTitle,
			)}
			description={formatMessage(
				variant === MISSING_ASSOCIATED_ISSUES_FETCH_LIMIT_ERROR
					? messages.fetchLimitErrorFlagDescription
					: messages.errorFlagDescription,
			)}
		/>
	);
};
