import React, { Component } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import type { OptionsGroup } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { getFutureSprintGroups } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/utils.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MODAL_DIALOG_WIDTH, SET_SPRINT } from '../../types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SetSprintDialog extends Component<Props> {
	getCurrentValue = (): string => {
		const {
			intl: { formatMessage },
			selectedIssues,
			sprints,
		} = this.props;
		const firstValue = selectedIssues[0].sprint;
		const isSameValue = !selectedIssues.some((issue) => !R.equals(issue.sprint, firstValue));

		if (isSameValue && !firstValue) {
			return formatMessage(commonMessages.none);
		}

		const sprint = sprints.find((el) => el.id === firstValue);
		if (isSameValue && firstValue && sprint) {
			return sprint.title;
		}

		return formatMessage(messages.variousValues);
	};

	getGroupedOptions = (): OptionsGroup[] => {
		const {
			additionalTeamsById,
			intl: { formatMessage },
			selectedIssues,
			sprintsByTeam,
			sprintsByIssueSources,
			teams,
			teamsById,
		} = this.props;
		const { sprint: sprintOfIssue, team: teamOfIssue } = selectedIssues[0];

		const otherSprintsLabel = formatMessage(messages.otherSprints);

		const futureSprintsGroupByTeam = getFutureSprintGroups(
			teamOfIssue,
			sprintOfIssue,
			sprintsByIssueSources,
			sprintsByTeam,
			additionalTeamsById,
			teams,
			teamsById,
			otherSprintsLabel,
		);

		const groupedOptions: OptionsGroup[] = [
			{
				options: [{ label: formatMessage(commonMessages.none), value: 'NONE' }],
			},
		];

		futureSprintsGroupByTeam.forEach((sprints, label) => {
			groupedOptions.push({
				label,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				options: (sprints as Sprint[]).map((s) => ({
					value: s.id,
					label: s.title,
				})),
			});
		});

		return groupedOptions;
	};

	render() {
		const {
			intl: { formatMessage },
			toggleModalDialog,
			bulkUpdateAttribute,
			UpdateAttributeForm,
			bulkActionSuccess,
			shouldReturnFocus,
		} = this.props;
		const groupedOptions = this.getGroupedOptions();
		return (
			<ShortcutScope>
				<ModalDialog
					messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-sprint.modal-dialog"
					messageType="transactional"
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-sprint"
					{...(fg('plan_timeline_layering_wrapper') ? { shouldReturnFocus } : {})}
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.setHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<UpdateAttributeForm
							updatedAttributeType={SET_SPRINT}
							currentValue={this.getCurrentValue()}
							options={groupedOptions}
							onCancel={toggleModalDialog}
							onSubmit={(
								formData: { [key: string]: string },
								analyticsEvent: UIAnalyticsEvent,
								event?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
							) => {
								bulkUpdateAttribute(formData);
								if (fg('plan-timeline-a11y-meatball-menu')) {
									toggleModalDialog(event);
								} else {
									toggleModalDialog();
								}
								bulkActionSuccess(analyticsEvent);
							}}
						/>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetSprintDialog);
