/** @jsx jsx */
import { Box, Flex, Stack, Text } from '@atlaskit/primitives/compiled';
import Toggle from '@atlaskit/toggle';
import { jsx, cssMap, cx } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

import type { Props } from './types.tsx';

const styles = cssMap({
	container: {
		paddingTop: token('space.150'),
	},
	separator: {
		borderTopWidth: token('border.width.outline'),
		borderTopStyle: 'solid',
		borderTopColor: token('color.border'),
		marginTop: token('space.200'),
	},
	title: {
		flexGrow: 0,
		marginTop: token('space.050'),
	},
	toggle: {
		flexShrink: 0,
	},
});

export const SettingsItemContent = ({
	hasSeparator = false,
	isChecked,
	testId,
	descriptionId,
	title,
	description = '',
	toggleLabel,
	onChange,
}: Props) => {
	return (
		<Flex
			justifyContent="space-between"
			alignItems="start"
			gap="space.200"
			xcss={cx(styles.container, hasSeparator && styles.separator)}
		>
			<Stack space="space.100">
				<Box xcss={styles.title}>
					<Text>{title}</Text>
				</Box>
				{description ? (
					<Text id={descriptionId} color="color.text.subtlest">
						{description}
					</Text>
				) : null}
			</Stack>
			<Box xcss={styles.toggle}>
				<Toggle
					testId={testId}
					descriptionId={descriptionId}
					label={toggleLabel}
					isChecked={isChecked}
					onChange={onChange}
				/>
			</Box>
		</Flex>
	);
};
