import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	scope: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.scope',
		defaultMessage: 'Scope',
		description: 'Header for the Scope region',
	},
	selectedIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.selected-issues',
		defaultMessage: '{totalSelected, plural, one {# issue} other {# issues}} selected',
		description: 'Bulk actions dropdown button',
	},
	selectAllIssueLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.select-all-issue-label',
		defaultMessage: 'Select all issues',
		description: 'AriaLabel text for checkbox which selects all issues.',
	},
	selectedIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.selected-issues-issue-term-refresh',
		defaultMessage: '{totalSelected, plural, one {# work item} other {# work items}} selected',
		description: 'Bulk actions dropdown button',
	},
	selectAllIssueLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.select-all-issue-label-issue-term-refresh',
		defaultMessage: 'Select all work items',
		description: 'AriaLabel text for checkbox which selects all issues.',
	},
	noIssuesSelected: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.no-issues-selected',
		defaultMessage: 'No issues selected',
		description: 'Aria live announcement when no issues are selected on the Jira Plan Timeline',
	},
	noIssuesSelectedIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.scope.no-issues-selected-issue-term-refresh',
		defaultMessage: 'No work items selected',
		description: 'Aria live announcement when no work items are selected on the Jira Plan Timeline',
	},
});
