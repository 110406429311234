import * as R from 'ramda';
import {
	UNSENT,
	LOADING,
	ERROR,
	SUCCESS,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/request.tsx';
import type { Issue as ApiIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	valuesLength,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	SCENARIO_TYPE,
	ISSUE_STATUS_CATEGORIES,
	type ScenarioType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

import type { Issue as NonScenarioIssue } from '@atlassian/jira-portfolio-3-plan-wizard/src/common/types/index.tsx';
import type { Person } from '../../state/domain/assignees/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { DateFormat } from '../../state/domain/system/types.tsx';
import type {
	ApiScenarioType,
	EntityMetadata,
	ChangeMetadata,
} from '../../state/domain/update-jira/changes/types.tsx';
import type {
	CommitProgressStats,
	IssueChangeQueue,
} from '../../state/domain/update-jira/commit/types.tsx';
import type { State as WarningsState } from '../../state/domain/update-jira/warnings/types.tsx';
import type { State } from '../../state/types.tsx';
import type {
	UserFilter,
	SelectedChanges,
} from '../../state/ui/top/title-bar/update-jira/types.tsx';
import { getAssigneesById } from '../assignees/index.tsx';
import {
	getCrossProjectVersionChanges,
	getCrossProjectVersionChangeCount,
} from '../cross-project-versions/index.tsx';
import type { CrossProjectVersionChange } from '../cross-project-versions/types.tsx';
import { getIssueStatusById } from '../issue-statuses/index.tsx';
import type { IssueStatusesById } from '../issue-statuses/types.tsx';
import { getIssueChanges, getIssueChangeCount, getIssueChangesData } from '../issues/index.tsx';
import type { IssueChange } from '../issues/types.tsx';
import { getAllIssues } from '../raw-issues/index.tsx';
import { getSprintChanges, getSprintChangeCount } from '../sprints/index.tsx';
import type { SprintChange } from '../sprints/types.tsx';
import { getDateFormat } from '../system/index.tsx';
import { getTeamAndResourceChanges, getTeamOrResourceChangeCount } from '../teams/changes.tsx';
import type { TeamAndResourceChange } from '../teams/types.tsx';
import { getVersionChanges, getVersionChangeCount } from '../versions/index.tsx';
import type { VersionChange } from '../versions/types.tsx';
import { getUserFilter } from './filters.tsx';
import type { Change } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Sequence } from '../../state/domain/sequence/types';

export const OUT_OF_SYNC_ERROR = 'out-of-sync';

export function getLastModified(
	metaData: ChangeMetadata,
	assigneeMapById: {
		[key: string]: Person;
	} = {},
	dateFormat: string,
) {
	const personId = metaData.lastChangeUser;
	const person = assigneeMapById[personId];

	return {
		date: metaData.lastChangeTimestamp,
		personId,
		dateFormat,
		...(person
			? {
					title: person.jiraUser.title,
					avatarUrl: person.jiraUser.avatarUrl,
				}
			: {}),
	};
}

export const getScenarioType = (
	scenarioType: ApiScenarioType,
	changeCount: number,
): ScenarioType => {
	if (scenarioType === 'ADDED') {
		return 'ADDED';
	}
	if (scenarioType === 'UPDATED') {
		if (changeCount === 0) {
			// This would be unexpected, but let's account for it!
			return 'NONE';
		}
		if (changeCount === 1) {
			return 'UPDATED';
		}
		return 'MULTIPLE';
	}
	if (scenarioType === 'DELETED') {
		return 'DELETED';
	}
	// Couldn't figure out the change!
	return 'UNKNOWN';
};

export const getReviewChangesPure = (
	issueChanges: IssueChange[],
	versionChanges: VersionChange[],
	crossProjectVersionChanges: CrossProjectVersionChange[],
	teamAndResourceChanges: TeamAndResourceChange[],
	sprintChanges: SprintChange[],
	dateFormat: DateFormat,
	assigneeMapById: {
		[key: string]: Person;
	},
): Change[] =>
	[
		...issueChanges,
		...versionChanges,
		...crossProjectVersionChanges,
		...teamAndResourceChanges,
		...sprintChanges,
	].map(
		// NOTE due to Flow limitations in handling sum types we need to do unsafe cast here
		// therefore please pay extra attention to keep this code correct
		({ metaData, metaData: { scenarioType }, ...rest }) =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({
				type: getScenarioType(scenarioType, rest.changeCount),
				lastModified: getLastModified(metaData, assigneeMapById, dateFormat),
				...rest,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}) as any,
	);

export const getReviewChanges = createSelector(
	[
		getIssueChanges,
		getVersionChanges,
		getCrossProjectVersionChanges,
		getTeamAndResourceChanges,
		getSprintChanges,
		getDateFormat,
		getAssigneesById,
	],
	getReviewChangesPure,
);

// NOTE why do we need separate selector for changes count? because we have two types of changes count:
// * one is derived from state.domain.original{Issues,Versions,...} and is relatively up-to-date
//   and available at any point of time after app initialization
// * another is just a getReviewChanges length which is taken from scenario changes response
// this selector calculates first type of changes count which we show in the Review changes button
// badge before we even do the scenario changes request and want it to react on user edits
// without need to do such request each time
export const getReviewChangesCount = createSelector(
	[
		getIssueChangeCount,
		getVersionChangeCount,
		getCrossProjectVersionChangeCount,
		getTeamOrResourceChangeCount,
		getSprintChangeCount,
	],
	(
		issueChangeCount,
		versionChangeCount,
		crossProjectVersionChangeCount,
		resourceChangeCount,
		plannedCapacityChangeCount,
	) =>
		issueChangeCount +
		versionChangeCount +
		crossProjectVersionChangeCount +
		resourceChangeCount +
		plannedCapacityChangeCount,
);

export const getSelectedChanges = (state: State) =>
	state.ui.Top.TitleBar.UpdateJira.selectedChanges;

export const getIssueChangeQueue = (state: State): IssueChangeQueue =>
	state.domain.updateJira.commit.issueChangeQueue ?? new Map();

export const getSortedByHierarchySelectedIssuesPure = (
	issues: Issue[],
	{ ISSUE: selectedIssues }: SelectedChanges,
): string[] => {
	const removedIssuesIds: Array<string> = [];
	const sortedIds = selectedIssues // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
		.reduce<Array<any>>((selectedIssues, selectedIssueId) => {
			const existingIssue = issues.find((issue) => selectedIssueId === issue.id);
			if (!existingIssue) {
				// We don't sort issues that do not exist, but we want to keep their ids, so we can commit these changes.
				removedIssuesIds.push(selectedIssueId);
				return selectedIssues;
			}
			return selectedIssues.concat(existingIssue);
		}, [])
		.sort((issueA, issueB) => (issueB && issueA ? issueB.level - issueA.level : 0))
		.map((issue) => (issue ? issue.id : ''));
	return sortedIds.concat(removedIssuesIds);
};

// It is impossible to have "Scenario removed" "Scenario Issues", because they dont go into review dialog
// The type in AFE is therefore correct we need to transpose it here
export const getScenarioRemovedIssueChangesPure = (issues: ApiIssue[]): NonScenarioIssue[] =>
	issues.reduce((result, issue) => {
		const isExcluded = issue.values.excluded;
		const issueKey = issue.issueKey;
		if (!isExcluded || !isDefined(issueKey) || !isDefined(issue.values.project)) {
			return result;
		}
		return result.concat({
			...issue,
			issueSources: issue.issueSources || [],
			issueKey,
		});
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, [] as NonScenarioIssue[]);

export const getScenarioRemovedIssueChanges = createSelector(
	[getIssueChangesData],
	getScenarioRemovedIssueChangesPure,
);

export const getSortedByHierarchySelectedIssues = createSelector(
	[getAllIssues, getSelectedChanges],
	getSortedByHierarchySelectedIssuesPure,
);

export const getExpandedChanges = (state: State) =>
	state.ui.Top.TitleBar.UpdateJira.expandedChanges;

export const getSelectedChangesCount = createSelector([getSelectedChanges], valuesLength);

export const getIsLoading: (arg1: State) => boolean = ({
	domain: {
		updateJira: {
			changes: { status },
		},
	},
}: State) => status === UNSENT || status === LOADING;

export const getIsOutOfSync = ({
	domain: {
		updateJira: {
			changes: { status, error },
		},
	},
}: State) => status === ERROR && error && error.message.error === OUT_OF_SYNC_ERROR;

export const getIsLoaded = ({
	domain: {
		updateJira: {
			changes: { status },
		},
	},
}: State) => status === SUCCESS || status === ERROR;

export const getIsSaving = (state: State): boolean => state.domain.updateJira.commit.inProgress;
export const getIsSavingInterrupted = (state: State): boolean =>
	state.domain.updateJira.commit.isInterrupted;
export const getCommitProgressStats = (state: State): CommitProgressStats =>
	state.domain.updateJira.commit.progressStats;

export const getIsReverting = (state: State) => state.domain.updateJira.revert.inProgress;
export const getIsRevertingInterrupted = (state: State) =>
	state.domain.updateJira.revert.isInterrupted;
export const getRevertProgressStats = (state: State) =>
	state.domain.updateJira.revert.progressStats;

export const getHiddenIssues = ({
	domain: {
		updateJira: { hiddenIssues },
	},
}: State) => hiddenIssues;

export const getIsCommitWarningFlagClosed = ({
	ui: {
		Top: {
			TitleBar: {
				UpdateJira: { isCommitWarningFlagClosed },
			},
		},
	},
}: State) => isCommitWarningFlagClosed;

export const getCommitWarnings = ({
	domain: {
		updateJira: { warnings },
	},
}: State): WarningsState => warnings;

export const getChangesWarnings = ({
	domain: {
		updateJira: { changesWarnings },
	},
}: State) => changesWarnings;

export const getCommittedChangesCount = (state: State) =>
	state.domain.updateJira.commit.changesCommitted;

export const getMetaDataByIssue = ({
	domain: {
		updateJira: {
			changes: {
				data = {
					metaData: {
						issues: {},
						resources: {},
						teams: {},
						versions: {},
						crossProjectVersions: {},
						plannedcapacity: {},
						issueLinks: {},
					},
					issues: [],
					issueLinks: [],
				},
			},
		},
	},
}: State) => data.metaData.issues;

export const getAllChangesMetaData = (state: State): ChangeMetadata[] => {
	const metaDataByType: Record<string, EntityMetadata> =
		R.path(['domain', 'updateJira', 'changes', 'data', 'metaData'], state) || {};

	const changes: ChangeMetadata[] = [];
	const nestedChanges: ChangeMetadata[][] = R.pipe<
		Record<string, EntityMetadata>,
		EntityMetadata[],
		ChangeMetadata[][]
	>(
		R.values,
		R.map(R.values),
	)(metaDataByType);

	return changes.concat(...nestedChanges);
};

export const getSortBy = (state: State) => state.ui.Top.TitleBar.UpdateJira.sortBy;
export const getSortDirection = (state: State) => state.ui.Top.TitleBar.UpdateJira.sortDirection;

export const getSequence = (state: State) => state.domain.sequence;

export const getFilteredReviewChangesPure = (changes: Change[], filter: UserFilter): Change[] =>
	filter.value.length
		? changes.filter((change: Change) =>
				filter.value.some((el) => change.lastModified && el === change.lastModified.personId),
			)
		: changes;

export const getFilteredReviewChanges = createSelector(
	[getReviewChanges, getUserFilter],
	getFilteredReviewChangesPure,
);

export const getNotificationPreference = (state: State) =>
	state.ui.Top.TitleBar.UpdateJira.shouldNotifyWatchers;

const getCountForStatusCategoryPure = (
	issueChanges: IssueChange[],
	selectedChanges: SelectedChanges,
	statusById: IssueStatusesById,
) => {
	const filteredIssues = issueChanges.filter((issue) => {
		const selectedIssueIds = selectedChanges.ISSUE || [];
		return (
			issue.metaData.scenarioType === SCENARIO_TYPE.UPDATED &&
			isDefined(issue?.details?.originals?.status) &&
			selectedIssueIds.includes(`${issue.id}`)
		);
	});
	const result = {
		TODO: 0,
		INPROGRESS: 0,
		DONE: 0,
		UNDEFINED: 0,
	};
	filteredIssues.forEach((issue) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const currentStatus = issue?.details?.values?.status as string;
		const currentStatusCategoryId: number | undefined = statusById[currentStatus]?.categoryId;
		switch (Number(currentStatusCategoryId)) {
			case ISSUE_STATUS_CATEGORIES.TODO:
				result.TODO++;
				break;
			case ISSUE_STATUS_CATEGORIES.INPROGRESS:
				result.INPROGRESS++;
				break;
			case ISSUE_STATUS_CATEGORIES.DONE:
				result.DONE++;
				break;
			default:
				result.UNDEFINED++;
		}
		return result;
	});
	return result;
};

export const getCountForStatusCategory = createSelector(
	[getIssueChanges, getSelectedChanges, getIssueStatusById],
	getCountForStatusCategoryPure,
);

const getCountGoalsPure = (
	issueChanges: IssueChange[],
	selectedChanges: SelectedChanges,
): { numGoalsAdded: number; numGoalsRemoved: number } =>
	issueChanges
		.filter((issue) => {
			const selectedIssueIds = selectedChanges.ISSUE || [];
			return (
				issue.metaData.scenarioType === SCENARIO_TYPE.UPDATED &&
				selectedIssueIds.includes(`${issue.id}`)
			);
		})
		.reduce<{ numGoalsAdded: number; numGoalsRemoved: number }>(
			(goalsCount, issue) => {
				const currentGoals = issue.details.values.goals || [];
				const previousGoals = issue.details.originals.goals || [];

				const numGoalsRemoved = previousGoals.filter((goal) => !currentGoals.includes(goal)).length;
				const numGoalsAdded = currentGoals.filter((goal) => !previousGoals.includes(goal)).length;
				return {
					numGoalsRemoved: goalsCount.numGoalsRemoved + numGoalsRemoved,
					numGoalsAdded: goalsCount.numGoalsAdded + numGoalsAdded,
				};
			},
			{ numGoalsRemoved: 0, numGoalsAdded: 0 },
		);

export const getCountForGoals = createSelector(
	[getIssueChanges, getSelectedChanges],
	getCountGoalsPure,
);
