import React, { type ComponentPropsWithoutRef } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CollapseHorizontalIcon from '@atlaskit/icon/core/collapse-horizontal';
import EditorCollapseIcon from '@atlaskit/icon/core/migration/shrink-horizontal--editor-collapse';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

import messages from './messages.tsx';

type Props = {
	onClick: () => void;
};

const CollapseButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();

	const onHandleClick: ComponentPropsWithoutRef<typeof Button>['onClick'] = (_, analyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'collapseFields');
		onClick();
	};

	const collapseFieldsFormatted = formatMessage(messages.advancedFieldsMenuCollapseFields);

	return isVisualRefreshEnabled() ? (
		<Tooltip content={collapseFieldsFormatted}>
			<IconButton
				icon={CollapseHorizontalIcon}
				appearance="subtle"
				onClick={onHandleClick}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.collapse-button"
				label={collapseFieldsFormatted}
				{...(fg('chronos_a11y_fixes_jtran2') ? {} : { 'aria-expanded': true })}
			/>
		</Tooltip>
	) : (
		<Button
			appearance="subtle"
			spacing="none"
			onClick={onHandleClick}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.collapse-button"
			ariaLabel={collapseFieldsFormatted}
			{...(fg('chronos_a11y_fixes_jtran2') ? {} : { 'aria-expanded': true })}
		>
			<Tooltip content={collapseFieldsFormatted}>
				<EditorCollapseIcon spacing="spacious" label="" />
			</Tooltip>
		</Button>
	);
};

export default CollapseButton;
