import React, { useRef, useCallback, type ReactNode } from 'react';
import cx from 'classnames';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useDependenciesFlyout } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout-old/index.tsx';
import { useDepLinesDrag } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/dependency-lines/drag-preview/provider/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import MarkerBadgePosition from './position/view.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import { useDragPreview, useDragHandling, useDropHandling, useIsActive } from './utils.tsx';

/**
 * Opens the dependencies flyout on click.
 */
export default function MarkerBadge({
	containerWidth,
	active,
	visibleWhenInactive,
	issue,
	issueBarPosition,
	direction,
	appearance,
	position,
	badgeColor,
	textColor,
	children,
	iconType,
	issueLinkType,
	issueCountByLinkType,
	isEditMode,
	isExportMode,
	onBadgeClick,
	triggerRef,
	markerBadgeRef,
}: Props) {
	const hitboxRef = useRef<HTMLDivElement>(null);
	const domRef = useRef<HTMLDivElement>(null);
	const { open: openFlyout } = useDependenciesFlyout();

	// Remove dragging logic when cleaning up plan-timeline-non-transposed
	const { dragSource } = useDepLinesDrag();
	const handleDrop = useDropHandling({ issueCountByLinkType, issueLinkType });
	const { handleMouseDown } = useDragHandling({
		direction,
		onDrop: handleDrop,
		isEditMode,
	});
	const isActive = useIsActive({ issue, direction, force: active });

	const handleClick = useCallback(() => {
		if (!fg('jira_plan_timeline_dependency_flyout_migration')) {
			const triggerEl = domRef.current;

			if (triggerEl == null) {
				return;
			}

			openFlyout({ direction, issue }, triggerEl);
		} else {
			onBadgeClick && onBadgeClick({ direction, issue });
		}
	}, [openFlyout, onBadgeClick, direction, issue]);

	// On hover, our badge background color for multicolour bars needs to be distinguishable from our text
	const backgroundColor =
		badgeColor === 'transparent' && active ? token('color.icon.subtle') : badgeColor;
	const pointerEvents = isActive ? 'inherit' : 'none';
	const opacity = isActive || visibleWhenInactive ? 1 : 0;
	const cursor = 'pointer';

	// Consider remove this function when cleaning up plan-timeline-non-transposed
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const withZIndexOverride = (children: (zIndex: number | string | undefined) => ReactNode) => {
		if (fg('plan-timeline-non-transposed')) {
			return children(undefined);
		}

		return (
			<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS}>{(zIndex) => children(zIndex ?? 'initial')}</ZIndex>
		);
	};

	if (!fg('plan-timeline-non-transposed')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const dragPreview = useDragPreview(
			hitboxRef.current,
			containerWidth,
			position,
			issueBarPosition,
			issueLinkType,
			isEditMode,
		);

		if (dragPreview) {
			return null;
		}
	}

	return (
		<MarkerBadgePosition
			containerWidth={containerWidth ?? 0}
			startOrEnd={position}
			issueBarPosition={issueBarPosition}
			active={isActive}
		>
			{({ translateX, left }) =>
				withZIndexOverride((zIndex) => (
					<div
						ref={hitboxRef}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={cx({
							[styles['hitbox-left']]: position === 'start',
							[styles['hitbox-right']]: position === 'end',
						})}
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							opacity,
							transform: translateX !== null ? `translateX(${translateX}px)` : undefined,
							transition: translateX !== null ? '0.2s' : undefined,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							transitionProperty: 'opacity, transform',
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							position: 'absolute',
							left,
							marginTop: isVisualRefreshEnabled() ? undefined : token('space.025'),
							zIndex:
								zIndex ??
								(isExportMode
									? 4 /* Need to increase the z-index of the dependency badge so it's visible in the PNG export. */
									: 3),
						}}
					>
						{/* eslint-disable-next-line @atlaskit/design-system/no-html-button */}
						<div
							ref={
								fg('jira_plan_timeline_dependency_flyout_migration')
									? mergeRefs(triggerRef ?? null, markerBadgeRef ?? null)
									: domRef
							}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={cx(
								isVisualRefreshEnabled() ? styles.marker : styles.markerOld,
								styles[`marker-${appearance}`],
								{
									[styles['marker-dragging']]: !!dragSource,
								},
							)}
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								pointerEvents,
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								backgroundColor,
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								cursor,
								color: textColor,
							}}
							onMouseDown={handleMouseDown}
							onClick={handleClick}
							role="button"
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onKeyDown={() => {}}
							tabIndex={0}
							data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.dependency-marker.marker-badge.${issue.id}-${position}-${iconType}`}
						>
							{children}
						</div>
					</div>
				))
			}
		</MarkerBadgePosition>
	);
}
