import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import { fetchAssociatedIssuesForSelectOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/associated-issues/api/index.tsx';
import { PACKAGE_NAME, ERROR_REPORTING_TEAM } from '../../constant.tsx';

export const createFetchAssociatedIssuesForSelectOptions =
	(
		cloudId: string,
		setIsSearchInProgress: (value: boolean) => void,
		setAllAssociatedIssuesToRender: (issues: AssociatedIssue[]) => void,
	) =>
	async (query: string) => {
		try {
			setIsSearchInProgress(true);
			const fetchedIssues = await fetchAssociatedIssuesForSelectOptions(cloudId, query);
			const currentSelectOptions = Object.keys(fetchedIssues).map((id) => fetchedIssues[id]);
			setAllAssociatedIssuesToRender(currentSelectOptions);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					packageName: PACKAGE_NAME,
					id: 'fetchAssociatedIssuesForSelectOptions',
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: false,
			});
		} finally {
			setIsSearchInProgress(false);
		}
	};
