import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import ShareButton from '@atlassian/jira-horizontal-nav-plans/src/ui/plans-navigation/header-menu-far/share-button/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { FeedbackButtonLazyComponent } from '@atlassian/jira-portfolio-3-feedback-collector/src/ui/async.tsx';
import { OnboardingHeaderModeUpdater } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights/index.tsx';
import AutoSchedulerSwitch from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/autoscheduler-switch/index.tsx';
import HeaderToggle from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/header-toggle/index.tsx';
import type { HeaderMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/header-toggle/types.tsx';
import PageHeader from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/index.tsx';
import Toolbar from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/toolbar/index.tsx';
import Optimize from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/optimize/index.tsx';
import ShareReport from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/share-report/index.tsx';
import UpdateJira from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/index.tsx';
import { TIMELINE_SHARE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from './messages.tsx';

export const HeaderBar = () => {
	const [headerMode, setHeaderMode] = useState<HeaderMode>('visible');

	return (
		<>
			{headerMode !== 'hidden' && (
				<HeaderContent>
					{headerMode !== 'toolbar' && (
						<PageHeader
							title={<FormattedMessage {...messages.timeline} />}
							actions={
								<>
									<FeedbackButtonLazyComponent />
									{!fg('smart_links_for_plans') ? (
										<ShareReport confluenceMacroType={TIMELINE_SHARE_TYPE} />
									) : null}
									<AutoSchedulerSwitch>{(enabled) => enabled && <Optimize />}</AutoSchedulerSwitch>
									{fg('smart_links_for_plans') ? (
										<SpotlightManager>
											<SpotlightTarget name="jira-plans-share-button">
												<ShareButton routeName="software-plan-timeline" />
											</SpotlightTarget>
											<EngagementSpotlight engagementId="jira-plans-share-button" />
										</SpotlightManager>
									) : null}
									<UpdateJira />
								</>
							}
						/>
					)}
					<Toolbar headerMode={headerMode} />
				</HeaderContent>
			)}
			<HeaderToggle mode={headerMode} setMode={setHeaderMode} />
			<OnboardingHeaderModeUpdater headerMode={headerMode} setHeaderMode={setHeaderMode} />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContent = styled.div({
	paddingTop: token('space.300'),
	paddingRight: token('space.300'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginTop: 0,
	},
});
