import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import StatusBreakdown from '@atlassian/jira-portfolio-3-portfolio/src/common/view/status-breakdown/index.tsx';
import { BREAKDOWN_SPRINT_CAPACITY_BY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import commonMessages from '../common/ui/messages.tsx';
import StatusFlyout from './status-flyout/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const getAriaLabel = (fieldKey: string): MessageDescriptor =>
	fieldKey === 'status' ? messages.ariaLabelStatus : messages.ariaLabelBreakdown;

export const StatusCell = ({
	value,
	fieldKey,
	groupName,
	groupByLabel,
	swimlane,
	byCategoryId,
}: Props) => {
	const { formatMessage } = useIntl();

	const flyout = fieldKey === 'status' ? <StatusFlyout value={value} /> : undefined;
	const getFlyoutHeader = () => {
		if (fieldKey === 'status') {
			return commonMessages.statusTitle;
		}
		return fg('jira-issue-terminology-refresh-m3')
			? commonMessages.breakdownTitleIssueTermRefresh
			: commonMessages.breakdownTitle;
	};
	const flyoutHeader = getFlyoutHeader();

	const ariaLabel = getAriaLabel(fieldKey);

	return (
		<Box xcss={containerStyles} paddingInlineStart="space.200" paddingInlineEnd="space.200">
			<StatusBreakdown
				planningUnit="issueCount"
				breakdownBy={BREAKDOWN_SPRINT_CAPACITY_BY.STATUS}
				byCategoryId={byCategoryId}
				total={value.length}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.status-cell.${groupName}.${fieldKey}`}
				flyout={flyout}
				flyoutHeader={flyoutHeader}
				ariaLabel={formatMessage(ariaLabel, {
					groupByLabel,
					swimlane,
				})}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	background: 'none',
	height: '100%',
	width: '100%',
	':focus': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, Space does not mapped to token
		outlineOffset: token('space.negative.025'),
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, Space does not mapped to token
		outlineOffset: token('space.negative.025'),
	},
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
});

export default StatusCell;
