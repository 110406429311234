/* eslint-disable @atlaskit/ui-styling-standard/no-classname-prop */
/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import cx from 'classnames';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ColumnConfig } from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import { Cell as CellPrimitive } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell/index.tsx';
import { Cell as CellCompiled } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell-compiled/index.tsx';
import { Row as RowPrimitive } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row/index.tsx';
import { Row as RowCompiled } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row-compiled/index.tsx';
import messages from '../messages.tsx';
import SelectChanges from '../select-change/header/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles
import * as styles from '../styles.module.css';
import { VALUE_TYPE } from '../value-change/types.tsx';
import type { Props } from './types.tsx';

export default function HeaderRow({
	changes,
	sortBy,
	columnConfig,
	sortDirection,
	isUpdating,
	index,
	columns,
	sortChanges,
	SelectChangeHeader = SelectChanges,
}: Props) {
	const { formatMessage } = useIntl();
	const ariaSortDirection = (columnId: string) => {
		if (columnId !== sortBy) return undefined;
		return sortDirection === 'ASC' ? 'ascending' : 'descending';
	};

	const selectColumnHeaderRenderer = () => (
		<SelectChangeHeader changes={changes} isUpdating={isUpdating} />
	);

	const oldValueColumnHeaderRenderer = () => (
		<div className={cx(styles.oldColumnHeader, styles.columnHeader)}>
			<Text size="UNSAFE_small" weight="semibold" color="color.text.subtlest">
				{formatMessage(messages.oldValueColumnHeader)}
			</Text>
			<span className={styles.oldColumnHeaderArrow}>
				<ArrowRightIcon label="" LEGACY_size="small" />
			</span>
		</div>
	);

	const sort = () => {
		sortChanges(sortBy, sortDirection === 'ASC' ? 'DESC' : 'ASC');
	};

	const lastModifiedColumnHeaderRenderer = () => (
		<button
			aria-label={formatMessage(messages.lastModifiedColumnAriaLabel)}
			className={styles.modifiedGridColumnHeader}
			onClick={sort}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<span data-sortdir={sortDirection}>{formatMessage(messages.lastModifiedColumnHeader)}</span>
		</button>
	);

	const columnHeaderRenderer = (title: string) => (
		<div className={cx(styles.commonColumnHeader, styles.columnHeader)}>
			<span>{title}</span>
		</div>
	);

	const headerCellRenderer = (column: ColumnConfig): ReactNode => {
		switch (column.id) {
			case 'isSelected':
				return selectColumnHeaderRenderer();
			case VALUE_TYPE.CURRENT:
				return oldValueColumnHeaderRenderer();
			case 'lastModified':
				return lastModifiedColumnHeaderRenderer();
			default: {
				const col = columnConfig.find((x) => x.id === column.id);
				return columnHeaderRenderer(col?.name || '');
			}
		}
	};

	return fg('arj_compiled_treegrid_for_review_changes_table') ? (
		<RowCompiled index={index}>
			{columns.map((column, i) => (
				<CellCompiled
					css={cellStylesCompiled}
					key={i}
					column={i}
					top={0}
					as={fg('review_changes_accessibility_fixes') ? 'th' : 'td'}
					aria-sort={
						fg('review_changes_accessibility_fixes') ? ariaSortDirection(column.id) : undefined
					}
				>
					<div css={cellContainerStylesCompiled}>{headerCellRenderer(column)}</div>
				</CellCompiled>
			))}
		</RowCompiled>
	) : (
		<RowPrimitive index={index}>
			{columns.map((column, i) => (
				<CellPrimitive
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
					style={{ zIndex: 2 }}
					xcss={cellStyles}
					key={i}
					column={i}
					top={0}
					as="th"
					aria-sort={ariaSortDirection(column.id)}
				>
					<Box xcss={cellContainer}>{headerCellRenderer(column)}</Box>
				</CellPrimitive>
			))}
		</RowPrimitive>
	);
}

const cellStyles = xcss({
	backgroundColor: 'elevation.surface',
	verticalAlign: 'top',
});

const cellStylesCompiled = css({
	backgroundColor: token('elevation.surface'),
	verticalAlign: 'top',
	zIndex: 2,
});

const cellContainer = xcss({
	position: 'absolute',
	inset: 'space.0',
	alignContent: 'center',
	paddingRight: 'space.100',
	borderBottom: `2px solid ${token('color.background.accent.gray.subtler')}`,
	backgroundColor: 'elevation.surface',
	overflow: 'hidden',
	transition: 'background-color 0.1s ease',
	':focus-within': {
		overflow: 'initial',
	},
});

const cellContainerStylesCompiled = css({
	position: 'absolute',
	inset: 0,
	alignContent: 'center',
	paddingRight: token('space.100'),
	borderBottom: `2px solid ${token('color.background.accent.gray.subtler')}`,
	backgroundColor: token('elevation.surface'),
	overflow: 'hidden',
	transition: 'background-color 0.1s ease',
	'&:focus-within': {
		overflow: 'initial',
	},
});
