.afm-header-left {
	display: flex;
	align-items: center;
	cursor: default;
	margin: calc(var(--jpo-common-base-spacing) / 2);
	margin-left: var(--jpo-common-base-spacing);
}

.afm-header-left-old {
	display: flex;
	align-items: center;
	cursor: default;
	margin-left: var(--jpo-common-base-spacing);
}

.afm-header-left div[data-role='droplistContent'] {
	padding: 0;
	max-height: 55vh;
	overflow: visible;
}

.afm-header-left div[data-role='droplistContent'] > div > div {
	max-width: none;
}

.afm-trigger-button {
	display: flex;
	align-items: center;
}

.afm-trigger-button-text {
	flex: 1 0 auto;
}

.afm-trigger-button-icon {
	display: flex;
	align-items: center;
	flex-shrink: 1;
}

.afm-dropdown-container-old {
	width: 400px;
}

.afm-dropdown-container {
	width: 400px;
	display: flex;
	flex-direction: column;
	gap: var(--jpo-common-base-spacing);
	margin: var(--jpo-common-base-spacing) 0;
}

.afm-dropdown-menu-header-old {
	padding: calc(2 * var(--jpo-common-base-spacing));
	border-bottom: 1px solid var(--ds-border, var(--adg3-color-N40));
}

.afm-dropdown-menu-header {
	padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-dropdown-menu-body {
	max-height: calc(55vh - 180px);
	overflow-y: auto;
	padding: calc(2 * var(--jpo-common-base-spacing));
	background-color: var(--ds-background-neutral, var(--adg3-color-N20));
	border-bottom: 1px solid var(--ds-border, var(--adg3-color-N40));
}

.afm-dropdown-menu-footer {
	padding: 6px 0;
}

.afm-dropdown-menu-divider {
	height: 1px;
	margin: 0 calc(2 * var(--jpo-common-base-spacing));
	background: var(--ds-border, var(--adg3-color-N40));
}

.afm-dropdown-menu-footer-old {
	padding: calc(2 * var(--jpo-common-base-spacing));
}

/*
    This ensures that the selected fields remain visible when the user drags and drops them
    See https://github.com/clauderic/react-sortable-hoc/issues/87
*/
/* can be deleted when cleaning up isSidebarVisible */
.afm-sortable-helper {
	z-index: 1200;
}

.afm-select-option-label {
	display: flex;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-custom-field-label {
	flex-grow: 2;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-custom-field-lozenge {
	flex-grow: 1;
	text-align: right;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-selected-field-item {
	display: flex;
	height: 38px;
	align-items: center;
	cursor: move;
	background-color: var(--ds-surface, var(--adg3-color-N0));
	box-shadow: var(--ds-shadow-raised, 0 0 1px 0 rgba(9, 30, 66, 0.31)),
		var(--ds-shadow-raised, 0 2px 4px -1px rgba(9, 30, 66, 0.25));
	border-radius: 2px;
	margin-bottom: 3px;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-selected-field-item:hover {
	background: var(--ds-background-selected, var(--adg3-color-B50));
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-selected-field-item-drag-icon {
	flex-shrink: 0;
	margin-top: 2px;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-selected-field-item-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 2;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-selected-field-item-custom-lozenge {
	margin-bottom: 3px;
	margin-left: 3px;
}

/* can be deleted when cleaning up isSidebarVisible */
.afm-selected-field-item-remove-icon {
	flex-shrink: 0;
	margin: 2px 4px 0;
	cursor: pointer;
}

.afm-field-option {
	display: flex;
	align-items: center;
	width: 100%;
}

.afm-field-option-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 2;
}

.afm-field-option-custom-lozenge {
	margin-left: 3px;
	line-height: 1;
}
