import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	numberOfIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.dependencies-cell.number-of-issues',
		defaultMessage: '{count} <qualifier>{count, plural, one {Issue} other {Issues}}</qualifier>',
		description:
			'Text to show the count of the total number of dependencies associated with a group of issues.',
	},
	numberOfWorkItems: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.dependencies-cell.number-of-work-items',
		defaultMessage:
			'{count} <qualifier>{count, plural, one {Work item} other {Work items}}</qualifier>',
		description:
			'Text to show the count of the total number of dependencies associated with a group of work items.',
	},
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.dependencies-cell.aria-label',
		defaultMessage:
			'{count} {count, plural, one {issue} other {issues}} for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Priority field in the group header row',
	},
});
