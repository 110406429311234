import React, { useEffect } from 'react';
import { Stack, Box, Grid, xcss, Inline } from '@atlaskit/primitives';
import {
	useFieldKeysForProject,
	useFieldsActions,
	useFieldsRaw,
	useFieldType,
} from '@atlassian/jira-polaris-component-jpd-implant-kit/src/controllers/fields/index.tsx';
import { FieldLabel } from '@atlassian/jira-polaris-component-jpd-implant-kit/src/ui/field-label/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import {
	isDisabledField,
	isVisibleField,
} from '@atlassian/jira-polaris-component-jpd-implant-kit/src/ui/utils.tsx';
import { FieldComponent } from '@atlassian/jira-polaris-component-jpd-implant-kit/src/ui/index.tsx';
import { EmojiContainer } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useIdeaActions,
	useIdeaFieldValueByKey,
	useIdeaUsers,
	useIdeaKey,
	useIdeaId,
	useIdeaStatuses,
	useIdeaLoaded,
	useConfigurationOverridenLoaded,
	useConfigurationOverridenIsLoading,
} from '@atlassian/jira-polaris-component-jpd-implant-kit/src/controllers/ideas/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { usePinnedFieldsByProjectId } from '../controllers/index.tsx';
import type {
	IdeaViewFieldListProps,
	IdeaViewFieldItemRendererProps,
	IdeaViewFieldListSectionProps,
	IssueCardPinnedFieldsProps,
} from './types.tsx';
import { PinnedFieldsLoader } from './pinned-fields-loader/index.tsx';

export const IssueCardPinnedFields = ({
	projectId,
	issueKey,
	issueId,
}: IssueCardPinnedFieldsProps) => {
	const cloudId = useCloudId();
	const accountId = useAccountId();

	return (
		<EmojiContainer cloudId={cloudId} currentUser={accountId ?? undefined}>
			<IdeaViewFieldList issueKey={issueKey} projectId={projectId} issueId={issueId} />
		</EmojiContainer>
	);
};

const IdeaViewFieldList = ({ issueKey, projectId, issueId }: IdeaViewFieldListProps) => {
	const { loadFieldsForProject } = useFieldsActions();
	const { loadIdeaByIssueId, loadDeliveryData } = useIdeaActions();
	const [fieldKeys] = useFieldKeysForProject(projectId);
	const [pinnedFields] = usePinnedFieldsByProjectId(projectId);
	const [isIdeaLoaded] = useIdeaLoaded();
	const [isConfigurationLoading] = useConfigurationOverridenIsLoading();
	const [fieldsRaw] = useFieldsRaw();
	const [configurationOverridenLoaded] = useConfigurationOverridenLoaded();

	const isLoaded = fg('polaris-overriden-dates-for-arjxideas-and-bento')
		? fieldKeys && isIdeaLoaded && pinnedFields && configurationOverridenLoaded
		: fieldKeys && isIdeaLoaded && pinnedFields;

	useEffect(() => {
		if (projectId) {
			loadFieldsForProject(projectId);
			loadIdeaByIssueId(issueKey, projectId);
		}
	}, [issueKey, loadFieldsForProject, loadIdeaByIssueId, projectId]);

	useEffect(() => {
		if (
			projectId &&
			isIdeaLoaded &&
			fieldKeys &&
			issueId &&
			pinnedFields &&
			!isConfigurationLoading &&
			!configurationOverridenLoaded &&
			fg('polaris-overriden-dates-for-arjxideas-and-bento')
		) {
			loadDeliveryData(issueId, projectId, fieldsRaw, fieldKeys);
		}
	}, [
		loadDeliveryData,
		issueKey,
		projectId,
		isIdeaLoaded,
		fieldKeys,
		isConfigurationLoading,
		configurationOverridenLoaded,
		fieldsRaw,
		issueId,
		pinnedFields,
	]);

	return (
		<Box xcss={fieldListStyles}>
			{isLoaded ? (
				<IdeaViewFieldListSection fieldKeys={fieldKeys || []} pinnedFields={pinnedFields || []} />
			) : (
				<PinnedFieldsLoader />
			)}
		</Box>
	);
};

const IdeaViewFieldListSection = ({ fieldKeys, pinnedFields }: IdeaViewFieldListSectionProps) => (
	<Stack space="space.100">
		{pinnedFields
			.filter((key) => fieldKeys.includes(key))
			.map((key) => <IdeaViewFieldItemRenderer key={key} fieldKey={key} />)
			.filter(Boolean)}
	</Stack>
);

const IdeaViewFieldItemRenderer = ({ fieldKey }: IdeaViewFieldItemRendererProps) => {
	const [users] = useIdeaUsers();
	const [statuses] = useIdeaStatuses();
	const [ideaKey] = useIdeaKey();
	const [ideaId] = useIdeaId();
	const [fieldType] = useFieldType(fieldKey);
	const isDisabled = isDisabledField(fieldType);
	const { data } = useCurrentUser();
	const currentUserTimezone = 'timeZone' in data?.user ? data.user.timeZone : undefined;
	const locale = useLocale();
	const [value] = useIdeaFieldValueByKey({ fieldKey, locale, currentUserTimezone });

	if (!fieldType) {
		return null;
	}

	if (!isVisibleField(fieldType)) {
		return null;
	}

	return (
		<Grid
			templateColumns="[title] minmax(100px, 0.6fr) [content] 1fr"
			gap="space.100"
			alignItems="center"
			xcss={gridStyling}
		>
			<Inline space="space.050" alignBlock="center">
				<FieldLabel
					color={isDisabled ? 'color.text.disabled' : 'color.text.subtle'}
					fieldKey={fieldKey}
				/>
			</Inline>

			<Box xcss={noOverflow}>
				<FieldComponent
					fieldKey={fieldKey}
					value={value}
					users={users}
					statuses={statuses}
					isMultiline={!fg('polaris-ideas-in-plans-single-line-fields')}
					ideaId={ideaId}
					ideaKey={ideaKey}
					isInsideTooltip
				/>
			</Box>
		</Grid>
	);
};

const gridStyling = xcss({
	gridAutoRows: 'auto',
});

const fieldListStyles = xcss({
	paddingTop: 'space.200',
});

const noOverflow = xcss({
	overflow: 'hidden',
});
