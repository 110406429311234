/** @jsx jsx */

import React, {
	useMemo,
	useEffect,
	useCallback,
	useRef,
	useState,
	type FormEventHandler,
	type KeyboardEventHandler,
} from 'react';
import { css, jsx } from '@compiled/react';
import intersection from 'lodash/fp/intersection';
import head from 'lodash/fp/head';
import Button from '@atlaskit/button/new';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useFlagService } from '@atlassian/jira-flags';
import { fg } from '@atlassian/jira-feature-gating';

import { useIntl } from '@atlassian/jira-intl';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants.tsx';
import {
	isDefined,
	values,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import ReturnIcon from './return-icon/index.tsx';

import messages from './messages.tsx';

import type { InlineCreateProps as Props } from './types.tsx';

export const InlineCreate: React.FC<Props> = ({
	id,
	setProjectId,
	setDefaultProjectId,
	issueTypeId,
	hierarchyLevel,
	projectId,
	issueTypes,
	orderedIssueTypes,
	projects,
	cancelInlineCreate,
	setIssueTypeId,
	isReadOnly = false,
	setIssueTypeIdForHierarchy,
	isSavingIssue,
	fixVersions,
	components,
	isGlobalCreate = false,
	matchFilter,
	createIssueWithTitle,
}) => {
	const { formatMessage } = useIntl();
	const [summary, setSummary] = useState('');
	const [textInputFocused, setTextInputFocused] = useState(false);
	const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
	const { showFlag } = useFlagService();
	const containerRef = useRef<HTMLFormElement>(null);
	const summaryInputRef = useRef<HTMLInputElement>(null);
	const projectMenuRef = useRef<HTMLElement>(null);
	const issueTypeMenuRef = useRef<HTMLElement>(null);

	const issueType = issueTypes[issueTypeId];
	const currentLevelIssueTypes = orderedIssueTypes.map((orderedIssueType) => orderedIssueType.id);
	const projectsForCurrentIssueTypeLevel = values(projects).filter(
		(project) => intersection(project.issueTypeIds, currentLevelIssueTypes).length > 0,
	);

	const filteredProjects = useMemo(() => {
		let result = projectsForCurrentIssueTypeLevel;

		if (fixVersions && fixVersions.length) {
			// Filter by projects by fix versions if group by "release" is enabled
			const projectsForCurrentReleaseGroup: Project[] = result.filter(
				(project) =>
					project.id === projectId ||
					(project.versions &&
						intersection(
							project.versions,
							fixVersions.map((fixVersion) => fixVersion.toString()),
						).length > 0),
			);

			result = projectsForCurrentReleaseGroup.length > 0 ? projectsForCurrentReleaseGroup : result;
		} else if (components && components.length) {
			// Filter by projects by components if group by "component" is enabled
			const projectsForCurrentComponentGroup: Project[] = result.filter(
				(project) =>
					project.id === projectId ||
					(project.components &&
						project.components.find((component) => components.includes(component.id))),
			);
			result =
				projectsForCurrentComponentGroup.length > 0 ? projectsForCurrentComponentGroup : result;
		}

		return result;
	}, [components, fixVersions, projectId, projectsForCurrentIssueTypeLevel]);

	const setSelectedProjectId = useCallback(
		(nextProjectId: number) => {
			const project = projects[nextProjectId];
			if (!project) {
				throw new Error(`Project with id ${nextProjectId} is not found in plan`);
			}
			setProjectId(nextProjectId);
			if (isGlobalCreate === true) {
				setDefaultProjectId(nextProjectId);
			}
			if (!project.issueTypeIds.includes(issueTypeId)) {
				const newIssueType = head(intersection(project.issueTypeIds, currentLevelIssueTypes));
				if (!isDefined(newIssueType)) {
					throw new Error(
						`Available project with id ${
							project.id
						} must contain an issue type of current level (one of ${currentLevelIssueTypes.join(
							', ',
						)})`,
					);
				}
				setIssueTypeId(newIssueType);
			}
		},
		[
			currentLevelIssueTypes,
			isGlobalCreate,
			issueTypeId,
			projects,
			setDefaultProjectId,
			setIssueTypeId,
			setProjectId,
		],
	);

	const handleSummaryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSummary(event.target.value);
	}, []);

	const handleTextFocus = useCallback(() => {
		setTextInputFocused(true);
	}, []);

	const handleTextBlur = useCallback(() => {
		setTextInputFocused(false);
	}, []);

	useEffect(() => {
		summaryInputRef.current?.focus();
	}, []);

	useEffect(() => {
		const handleDocumentClick = (event: MouseEvent | FocusEvent) => {
			if (!(event.target instanceof Node) || !containerRef.current) {
				return;
			}

			if (
				!containerRef.current.contains(event.target) &&
				global.document.body.contains(event.target)
			) {
				cancelInlineCreate();
			}
		};

		global.document.addEventListener('click', handleDocumentClick);

		return () => {
			global.document.removeEventListener('click', handleDocumentClick);
		};
	}, [cancelInlineCreate, id]);

	const focusOnSummaryField = useCallback(() => {
		setIsProjectMenuOpen(false);
		summaryInputRef.current?.focus();
	}, []);

	// if the plan is grouped by component or release AND:
	// - if there are several relevant projects in the group, the user has to select a project in the projects dropdown, which then updates the current project in the state.
	// - if there is only one relevant project in the group, the projects dropdown only renders one project and is disabled, so the user cannot select a project.
	// Therefore we need to set the project ourselves in the state otherwise creating an issue will fail since the project is undefined.
	if (
		((components && components.length) || (fixVersions && fixVersions.length)) &&
		filteredProjects.length === 1
	) {
		setSelectedProjectId(filteredProjects[0].id);
	}

	const project = projects[projectId] || (filteredProjects.length === 1 && filteredProjects[0]);

	const initialProjectIsDefined = !!project;

	const savingIssuePlaceholderMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.savingIssuePlaceholderIssueTermRefresh
		: messages.savingIssuePlaceholder;

	const titlePlaceholderMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.titlePlaceholderIssueTermRefresh
		: messages.titlePlaceholder;

	const canSubmit = !isSavingIssue && summary.trim().length > 0;

	const handleSubmit: FormEventHandler = useCallback(
		(e) => {
			e.preventDefault();
			const trimmed = summary.trim();
			traceUFOPress(KEYS.CREATE_ISSUE);
			if (isDefined(trimmed) && trimmed.length > 0) {
				createIssueWithTitle(trimmed);
			}

			if (!matchFilter(trimmed)) {
				showFlag({
					id: 'jira-portfolio-3-inline-create-issue-warning',
					type: 'warning',
					testId: 'jira-portfolio-3-inline-create-issue-warning',
					title: formatMessage(messages.notMatchFilterMessageTitle),
					description: formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.notMatchFilterMessageIssueTermRefresh
							: messages.notMatchFilterMessage,
					),
					messageId:
						'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.show-flag.warning.info.jira-portfolio-3-inline-create-issue-not-match-filter',
					messageType: 'transactional',
				});
			}

			setSummary('');
			summaryInputRef.current?.focus();
		},
		[createIssueWithTitle, formatMessage, matchFilter, showFlag, summary],
	);

	const handleKeyDown: KeyboardEventHandler = useCallback(
		(e) => {
			if (e.key === 'Escape') {
				cancelInlineCreate();
			}
		},
		[cancelInlineCreate],
	);

	return (
		<form
			ref={containerRef}
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.form"
			data-name="issue-inline-create"
			css={containerStyles}
			data-issue={id}
			onSubmit={handleSubmit}
		>
			<div css={dropdownWrapperStyles} data-name="issueTypes">
				<DropMenu
					trigger={({ triggerRef, ...props }) => (
						<Button
							ref={triggerRef}
							{...props}
							isDisabled={isReadOnly || orderedIssueTypes.length < 2 || !initialProjectIsDefined}
							iconAfter={ChevronDownIcon}
							aria-label={
								issueType
									? issueType.name
									: formatMessage(
											fg('jira-issue-terminology-refresh-m3')
												? messages.selectIssueTypeIssueTermRefresh
												: messages.selectIssueType,
										)
							}
						>
							<div
								css={issueTypeIconStyles}
								style={{
									backgroundImage: issueType && `url(${issueType.iconUrl})`,
								}}
								data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.issue-type-icon.${
									issueType && issueType.id
								}`}
							/>
						</Button>
					)}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.issue-types-select"
				>
					{initialProjectIsDefined ? (
						<DropMenuItemGroup ref={issueTypeMenuRef}>
							{orderedIssueTypes
								.filter((item) => project.issueTypeIds.includes(item.id))
								.map((item: IssueType) => (
									<DropMenuItem
										id={item.id}
										onClick={() => {
											setIssueTypeIdForHierarchy(hierarchyLevel, item.id);
											setIssueTypeId(item.id);
										}}
										key={item.id}
									>
										{item.name}
									</DropMenuItem>
								))}
						</DropMenuItemGroup>
					) : null}
				</DropMenu>
			</div>
			{values(projects).length > 1 && (
				<div css={dropdownWrapperStyles} data-name="projects">
					<DropMenu
						defaultOpen={!initialProjectIsDefined}
						trigger={({ triggerRef, ...props }) => (
							<Button
								ref={triggerRef}
								{...props}
								isDisabled={isReadOnly || filteredProjects.length === 1}
								iconAfter={ChevronDownIcon}
							>
								<div
									css={projectIconStyles}
									style={{
										backgroundImage: initialProjectIsDefined
											? `url(${project.avatarUrl})`
											: undefined,
									}}
								>
									{initialProjectIsDefined ? project.key : formatMessage(messages.selectProject)}
								</div>
							</Button>
						)}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.select-project"
						isOpen={isProjectMenuOpen}
					>
						<DropMenuItemGroup ref={projectMenuRef}>
							{filteredProjects.map((item: Project) => (
								<DropMenuItem
									id={item.id}
									onClick={() => {
										setSelectedProjectId(item.id);
										focusOnSummaryField();
									}}
									key={item.id}
									isSelected={item.id === project.id}
								>
									{item.name}
								</DropMenuItem>
							))}
						</DropMenuItemGroup>
					</DropMenu>
				</div>
			)}
			<div
				css={[
					summaryWrapperStyles,
					(canSubmit || textInputFocused) && summaryWrapperExpandedStyles,
				]}
			>
				<TextField
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.summary"
					onFocus={handleTextFocus}
					onBlur={handleTextBlur}
					isCompact
					isReadOnly={!initialProjectIsDefined || isSavingIssue}
					maxLength={256}
					value={summary}
					onChange={handleSummaryChange}
					placeholder={formatMessage(
						isSavingIssue ? savingIssuePlaceholderMessage : titlePlaceholderMessage,
					)}
					onKeyDown={handleKeyDown}
					aria-label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.ariaLabelIssueTermRefresh
							: messages.ariaLabel,
					)}
					ref={summaryInputRef}
				/>
			</div>
			<Button
				appearance="primary"
				type="submit"
				isDisabled={!canSubmit}
				isLoading={isSavingIssue}
				iconAfter={ReturnIcon}
			>
				{formatMessage(messages.create)}
			</Button>
		</form>
	);
};

export default InlineCreate;

const containerStyles = css({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
});

const dropdownWrapperStyles = css({
	flexShrink: 0,
});

const issueTypeIconStyles = css({
	backgroundPosition: 0,
	backgroundRepeat: 'no-repeat',
	backgroundSize: token('space.200'),
	width: token('space.250'),
	height: token('space.250'),
});

const projectIconStyles = css({
	backgroundPosition: 0,
	backgroundRepeat: 'no-repeat',
	backgroundSize: token('space.200'),
	paddingLeft: token('space.300'),
});

const summaryWrapperStyles = css({
	width: 180,
	transition: 'width 0.2s',
});

const summaryWrapperExpandedStyles = css({
	width: 240,
});
