import React, { memo, useCallback } from 'react';
import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import ChildIcon from '../child-icon/index.tsx';
import type { IssueTypeWithProjectId } from '../types.tsx';
import messages from './messages.tsx';

type Props = {
	onItemClick: (
		issueType: IssueTypeWithProjectId,
		relation: 'parent' | 'child',
		e?: React.KeyboardEvent | React.MouseEvent,
	) => void;
	issueType: IssueTypeWithProjectId;
	relation: 'parent' | 'child';
} & Omit<DropdownItemProps, 'onClick'>;

const IssueTypeItem = memo<Props>(({ issueType, relation, onItemClick, children, ...props }) => {
	const { formatMessage } = useIntl();
	const handleClick = useCallback(
		(e?: React.KeyboardEvent | React.MouseEvent) => {
			if (fg('plan-timeline-a11y-meatball-menu')) {
				onItemClick(issueType, relation, e);
			} else {
				onItemClick(issueType, relation);
			}
		},
		[issueType, onItemClick, relation],
	);

	return (
		<DropdownItem
			testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.issue-type-item.${issueType.id}`}
			onClick={handleClick}
			{...(fg('plan-timeline-a11y-meatball-menu')
				? {}
				: {
						elemBefore:
							relation === 'child' ? (
								<ChildIcon label={formatMessage(messages.childIconLabel)} />
							) : undefined,
					})}
			{...props}
		>
			{children}
		</DropdownItem>
	);
});

export default IssueTypeItem;
