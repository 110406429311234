import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getRollupMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import { getShowRolledUpOthers } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const querySelector: MapStateToProps<StateProps, OwnProps> = (state, props) => ({
	plan: getPlan(state),
	estimateRollUpForIssue: estimateRollUpForIssue(state, props),
	showEstimateRollup: getShowRolledUpOthers(state),
	workingHours: getJiraHoursPerDay(state),
});

const estimateRollUpForIssue = (state: State, props: OwnProps) => {
	if (fg('plans-timeline-be-rollups')) {
		return getRollupMap(state)[props.issue.id]?.estimate ?? null;
	}
	return isDefined(getRollupMap(state)[props.issue.id])
		? getRollupMap(state)[props.issue.id].estimate
		: null;
};

export default querySelector;
