export const START = 'state.domain.update-jira.revert.START' as const;
export const INCREMENT_NUMBER_OF_REVERTED_CHANGES =
	'state.domain.update-jira.revert.INCREMENT_NUMBER_OF_REVERTED_CHANGES' as const;
export const ADD_NUMBER_OF_REVERTED_CHANGES =
	'state.domain.update-jira.revert.ADD_NUMBER_OF_REVERTED_CHANGES' as const;
export const INTERRUPT = 'state.domain.update-jira.revert.INTERRUPT' as const;
export const STOP = 'state.domain.update-jira.revert.STOP' as const;

export type TotalChanges = number;

export type StartActionPayload = TotalChanges;

export type AddActionPayload = number;

export type StartAction = {
	type: typeof START;
	payload: StartActionPayload;
};

export type AddAction = {
	type: typeof ADD_NUMBER_OF_REVERTED_CHANGES;
	payload: AddActionPayload;
};

export type IncrementAction = {
	type: typeof INCREMENT_NUMBER_OF_REVERTED_CHANGES;
};

export type InterruptAction = {
	type: typeof INTERRUPT;
};

export type StopAction = {
	type: typeof STOP;
};

export const start = (totalChanges: TotalChanges) => ({ type: START, payload: totalChanges });
export const incrementNumberOfRevertedChanges = () => ({
	type: INCREMENT_NUMBER_OF_REVERTED_CHANGES,
});
export const addNumberOfRevertedChanges = (toAdd: number) => ({
	type: ADD_NUMBER_OF_REVERTED_CHANGES,
	payload: toAdd,
});
export const interrupt = () => ({ type: INTERRUPT });
export const stop = () => ({ type: STOP });
