import isNil from 'lodash/isNil';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import type {
	SuggestedChildIssue,
	ChildIssue,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	JiraSuggestedChildIssueStatusType,
	JiraSuggestedIssueErrorType,
} from '@atlassian/jira-relay/src/__generated__/issueBreakdownSubscription.graphql';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { type Action, createStore, createHook } from '@atlassian/react-sweet-state';
import { aiwbAnalyticsAttrs } from '../common/analytics/index.tsx';
import type { SubscriptionError } from '../services/issue-breakdown/types.tsx';

export const steps = {
	defaultStep: 'defaultStep',
	draftListStep: 'draftListStep',
	errorStep: 'errorStep',
} as const;
export type Step = (typeof steps)[keyof typeof steps];

export const reasons = {
	accept: 'accept',
	reject: 'reject',
} as const;
export type Reason = (typeof reasons)[keyof typeof reasons];

type State = {
	acceptedChildIssues: ChildIssue[];
	aiOptInEnabledByProject: Record<string, boolean>;
	channelId: string | null;
	clearListWhenAddSuggestion: boolean;
	currentStep: Step;
	headerPromptValue: string;
	isStreaming: boolean;
	promptValue: string;
	rejectedChildIssues: ChildIssue[];
	retryAttempts: number;
	selectedIssueId: string | null;
	startTime: Date | null;
	status: JiraSuggestedChildIssueStatusType | null;
	streamingError: JiraSuggestedIssueErrorType | SubscriptionError | null;
	suggestedChildIssues: SuggestedChildIssue[];
};

const initialState: State = {
	currentStep: steps.defaultStep,
	retryAttempts: 0,
	suggestedChildIssues: [],
	promptValue: '',
	headerPromptValue: '',
	selectedIssueId: null,
	channelId: null,
	acceptedChildIssues: [],
	rejectedChildIssues: [],
	isStreaming: false,
	clearListWhenAddSuggestion: false,
	startTime: null,
	status: null,
	streamingError: null,
	aiOptInEnabledByProject: {},
};

const setIssueBreakdownStep =
	(step: Step): Action<State> =>
	({ setState }) => {
		setState({ currentStep: step });
	};

const setAiOptInEnabled =
	(projectKey: string, isEnabled: boolean): Action<State> =>
	({ getState, setState }) => {
		const { aiOptInEnabledByProject } = getState();
		setState({ aiOptInEnabledByProject: { ...aiOptInEnabledByProject, [projectKey]: isEnabled } });
	};

const setSuggestedChildIssues =
	(suggestedChildIssues: SuggestedChildIssue[]): Action<State> =>
	({ setState }) => {
		setState({ suggestedChildIssues });
	};

const setSelectedIssueId =
	(selectedIssueId: string | null): Action<State> =>
	({ setState }) => {
		setState({ selectedIssueId });
	};

const unselectCurrentId =
	(): Action<State> =>
	({ setState }) => {
		setState({ selectedIssueId: null });
	};

const setChannelId =
	(channelId: string | null): Action<State> =>
	({ setState }) => {
		setState({ channelId });
	};

const resetIssueBreakdownState =
	(analyticsEvent?: UIAnalyticsEvent): Action<State> =>
	({ getState, setState }) => {
		if (analyticsEvent) {
			const {
				channelId,
				isStreaming,
				streamingError,
				suggestedChildIssues,
				acceptedChildIssues,
				startTime,
			} = getState();
			const timeSinceStart = startTime && differenceInSeconds(new Date(), startTime);
			const attrs = {
				...aiwbAnalyticsAttrs,
				singleInstrumentationID: channelId,
				suggestedNumber: suggestedChildIssues.length,
				acceptedNumber: acceptedChildIssues.length,
				isStreaming,
				streamingError,
				timeSinceStart,
			};
			fireTrackAnalytics(
				analyticsEvent,
				'aiInteraction dismissed',
				'plansTimelineAIWorkBreakdownCancel',
				attrs,
			);
		}
		setState(initialState);
	};

const setPromptValue =
	(promptValue: string): Action<State> =>
	({ setState }) => {
		setState({ promptValue });
	};

const setHeaderPromptValue =
	(headerPromptValue: string): Action<State> =>
	({ setState }) => {
		setState({ headerPromptValue });
	};

const isIssueBreakdownOpen =
	(): Action<State, void, Boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep !== steps.defaultStep;
	};

const getSuggestedLength =
	(): Action<State, void, number> =>
	({ getState }) => {
		const { suggestedChildIssues } = getState();
		return suggestedChildIssues.length;
	};

const getAiOptInEnabled =
	(projectKey: string): Action<State, void, boolean> =>
	({ getState }) => {
		const { aiOptInEnabledByProject } = getState();
		if (!isNil(aiOptInEnabledByProject[projectKey])) {
			return aiOptInEnabledByProject[projectKey];
		}
		// we hide the "Suggest child issues" menu item by default. We only show it if Atlassian Intelligence is enabled on the Cloud instance.
		return false;
	};

const addSuggestedIssue =
	(newSuggestedIssue: SuggestedChildIssue): Action<State> =>
	({ getState, setState }) => {
		const { suggestedChildIssues: oldIssues, status, clearListWhenAddSuggestion } = getState();
		const suggestedChildIssues = clearListWhenAddSuggestion
			? [newSuggestedIssue]
			: oldIssues.concat(newSuggestedIssue);
		if (status) {
			setState({ suggestedChildIssues, status: null, clearListWhenAddSuggestion: false });
		} else {
			setState({ suggestedChildIssues, clearListWhenAddSuggestion: false });
		}
	};

const removeSuggestedIssue =
	(id: string | string[], reason: Reason): Action<State> =>
	({ getState, setState }) => {
		const { suggestedChildIssues, acceptedChildIssues, rejectedChildIssues } = getState();
		const removeIdSet = new Set(Array.isArray(id) ? id : [id]);

		const initialValue: { issues: SuggestedChildIssue[]; removed: ChildIssue[] } = {
			issues: [],
			removed: [],
		};
		const { issues, removed } = suggestedChildIssues.reduce((result, current) => {
			if (removeIdSet.has(current.id)) {
				const { summary, description } = current;
				return Object.assign(result, { removed: result.removed.concat({ summary, description }) });
			}
			return Object.assign(result, { issues: result.issues.concat(current) });
		}, initialValue);

		if (reason === reasons.accept) {
			setState({
				suggestedChildIssues: issues,
				acceptedChildIssues: acceptedChildIssues.concat(removed),
			});
		} else {
			setState({
				suggestedChildIssues: issues,
				rejectedChildIssues: rejectedChildIssues.concat(removed),
			});
		}
	};

const setStartTime =
	(): Action<State> =>
	({ setState }) => {
		setState({ startTime: new Date() });
	};

const updateStreamingStatus =
	(isStreaming: boolean, status: State['status'] = null): Action<State> =>
	({ setState }) => {
		setState({ isStreaming, clearListWhenAddSuggestion: isStreaming, status });
	};

const setStreamingError =
	(error: JiraSuggestedIssueErrorType | SubscriptionError): Action<State> =>
	({ setState, getState }) => {
		const { suggestedChildIssues, promptValue } = getState();

		if (suggestedChildIssues.length > 0 || promptValue) {
			// If it's a refine request,
			// for example, after the initial request has succeeded or the user has typed in text
			// then want to keep the channel ID.
			setState({ streamingError: error });
		} else {
			// If the initial request fails, we want to start a new one
			setState({ channelId: null, streamingError: error });
		}
	};

const clearError =
	(): Action<State> =>
	({ setState }) => {
		setState({ streamingError: null });
	};

const incrementRetryCount =
	(): Action<State> =>
	({ getState, setState }) => {
		const { retryAttempts } = getState();
		setState({ retryAttempts: retryAttempts + 1 });
	};

export const actions = {
	addSuggestedIssue,
	clearError,
	getAiOptInEnabled,
	getSuggestedLength,
	incrementRetryCount,
	isIssueBreakdownOpen,
	removeSuggestedIssue,
	resetIssueBreakdownState,
	setAiOptInEnabled,
	setChannelId,
	setHeaderPromptValue,
	setIssueBreakdownStep,
	setPromptValue,
	setSelectedIssueId,
	setStartTime,
	setStreamingError,
	setSuggestedChildIssues,
	unselectCurrentId,
	updateStreamingStatus,
};

type ActionType = typeof actions;

const Store = createStore<State, ActionType>({
	initialState,
	actions,
	name: 'portfolio-3-ai-issue-breakdown',
});

export const useIssueBreakdown = createHook(Store);
