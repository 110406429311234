import { useMemo, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import {
	type Entity,
	ENTITY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type EntityCount = {
	[key in Entity]?: number;
} & {
	[key: string]: number;
};

export const useChangesInfo = () => {
	const { formatMessage } = useIntl();

	const stringFormatter = useMemo(
		() => new Intl.ListFormat('en-US', { style: 'long', type: 'conjunction' }),
		[],
	);

	const getCategoryCounts = (tableChanges: Change[]) => {
		// object to hold the count of each category changed
		const categoryCounts: EntityCount = {};
		tableChanges.forEach((item) => {
			if (item.category) {
				categoryCounts[item.category] = (categoryCounts[item.category] || 0) + 1;
			}
		});
		return categoryCounts;
	};

	const listChangeAuthors = (tableChanges: Change[]) => {
		const changeAuthorsSet = new Set<string>();
		tableChanges.forEach((item) => {
			if (item?.lastModified?.title) {
				changeAuthorsSet.add(item.lastModified.title);
			}
		});

		const isSingleUser = changeAuthorsSet.size === 1;

		return {
			userName: isSingleUser ? Array.from(changeAuthorsSet)[0] : undefined,
			userList: !isSingleUser ? stringFormatter.format(Array.from(changeAuthorsSet)) : undefined,
			isSingleUser,
		};
	};

	const countCategories = useCallback(
		(tableChanges: Change[]) => {
			const categoriesObj = getCategoryCounts(tableChanges);
			const categoryCounts: string[] = [];

			for (const [key] of Object.entries(categoriesObj)) {
				let message;
				let countKey;

				switch (key) {
					case ENTITY.ISSUE:
						message = fg('jira-issue-terminology-refresh-m3')
							? messages.issueCategoryCountIssueTermRefresh
							: messages.issueCategoryCount;
						countKey = 'issueCount';
						break;
					case ENTITY.TEAM:
						message = messages.teamCategoryCount;
						countKey = 'teamCount';
						break;
					case ENTITY.RELEASE:
						message = messages.releaseCategoryCount;
						countKey = 'releaseCount';
						break;
					case ENTITY.CROSS_PROJECT_RELEASE:
						message = messages.crossProjectReleaseCategoryCount;
						countKey = 'crossProjectReleaseCount';
						break;
					case ENTITY.RESOURCE:
						message = messages.resourceCategoryCount;
						countKey = 'resourceCount';
						break;
					case ENTITY.SPRINT:
						message = messages.sprintCategoryCount;
						countKey = 'sprintCount';
						break;
					case ENTITY.PLANNED_CAPACITY:
						message = messages.plannedCapacityCategoryCount;
						countKey = 'plannedCapacityCount';
						break;
					default:
						break;
				}

				if (message && countKey) {
					categoryCounts.push(
						formatMessage(message, {
							[countKey]: categoriesObj[key],
						}),
					);
				}
			}

			return stringFormatter.format(categoryCounts);
		},
		[formatMessage, stringFormatter],
	);

	return { listChangeAuthors, countCategories };
};
