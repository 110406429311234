import React from 'react';
import cx from 'classnames';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, DialogMenuGroupProps } from './types.tsx';

export function DialogMenuContainer({ children, style, className }: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div style={style || {}} className={cx(styles.container, className)}>
			{children}
		</div>
	);
}

export function DialogMenuItem({ children, style, className }: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div style={style || {}} className={cx(styles.item, className)}>
			{children}
		</div>
	);
}

export function DialogMenuGroup({ children, heading, style, titleId }: DialogMenuGroupProps) {
	return (
		<div
			{...(fg('jfp_a11y_team_timeline_filter_group_form_control') && {
				role: 'group',
				'aria-labelledby': titleId,
			})}
		>
			{heading && (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles['group-heading']}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style || {}}
					{...(titleId &&
						fg('jfp_a11y_team_timeline_filter_group_form_control') && {
							'data-testId': `${titleId}--heading`,
							id: titleId,
						})}
				>
					{heading}
				</div>
			)}
			{children}
		</div>
	);
}
