import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.focus-area-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchFocusAreaPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.focus-area-filter.search-focus-area-placeholder',
		defaultMessage: 'Show issues from focus areas',
		description: 'This is a placeholder for search',
	},
	searchFocusAreaPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.focus-area-filter.search-focus-area-placeholder-issue-term-refresh',
		defaultMessage: 'Show work items from focus areas',
		description: 'This is a placeholder for search',
	},
});
