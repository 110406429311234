import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	convertSecondsToDays,
	convertSecondsToHours,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/estimation/index.tsx';
import { getPlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	PlanningUnits,
	type PlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import type { OwnProps, MapStateToProps } from './types.tsx';

// Remove rawValue default when cleaning up aggregate_fields_for_plan_m2
const getValue = createSelector(
	[getPlanningUnit, getJiraHoursPerDay, (_: State, { rawValue }: OwnProps) => rawValue],
	(planningUnit: PlanningUnit, hoursPerDay: number, rawValue: number | undefined = 0): number => {
		if (!rawValue && expValEquals('aggregate_fields_for_plan_m2_experiment', 'cohort', 'variation'))
			return 0;

		switch (planningUnit) {
			case PlanningUnits.days:
				// working days
				return convertSecondsToDays(rawValue, hoursPerDay);
			case PlanningUnits.hours:
				// working hours
				return convertSecondsToHours(rawValue);
			default:
				// story points
				return rawValue;
		}
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	value: getValue,
});

export default mapStateToProps;
