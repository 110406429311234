import React, { type ChangeEvent, useRef, useEffect, useCallback } from 'react';
import CrossCircleIcon from '@atlaskit/icon/utility/cross-circle';
import { Box } from '@atlaskit/primitives';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { Label } from '@atlaskit/form';
import { useIntl } from '@atlassian/jira-intl';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const SearchField = (props: Props) => {
	const intl = useIntl();
	const inputRef = useRef(null);
	const {
		onQueryChange,
		placeholder,
		searchQuery,
		autoFocus = true,
		ariaLabel,
		setInitialFocusRef,
		id,
		label,
	} = props;

	useEffect(() => {
		if (setInitialFocusRef) {
			setInitialFocusRef(inputRef.current);
		}
	}, [setInitialFocusRef]);

	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onQueryChange(e.target.value);
		},
		[onQueryChange],
	);

	const onClear = useCallback(() => {
		onQueryChange('');
	}, [onQueryChange]);

	const hasInput = searchQuery.length > 0;

	return (
		<Box paddingInline="space.200" paddingBlock="space.100">
			{id && label && <Label htmlFor={id}>{label}</Label>}
			<AkTextField
				autoFocus={autoFocus}
				onChange={onChange}
				placeholder={placeholder}
				value={searchQuery}
				aria-label={ariaLabel}
				ref={inputRef}
				id={id}
				elemAfterInput={
					hasInput ? (
						<Box padding="space.100">
							<IconButton
								onClick={onClear}
								tabIndex={-1}
								ariaLabel={intl.formatMessage(messages.clearButtonLabel)}
							>
								<CrossCircleIcon color={token('color.icon.subtle')} label="" />
							</IconButton>
						</Box>
					) : null
				}
			/>
		</Box>
	);
};
