import React from 'react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	MODAL,
	fireScreenAnalytics,
	ContextualAnalyticsData,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const getJSWNoAccessErrorFlag = () => (
	<Flag
		key="jsw-no-access-error"
		id="jsw-no-access-error"
		title={
			<ContextualAnalyticsData sourceType={MODAL} sourceName="nswNoAccessError">
				<MountEvent onMount={fireScreenAnalytics} />
				<FormattedMessage {...messages.title} />
			</ContextualAnalyticsData>
		}
		description={<FormattedMessage {...messages.descriptionSpork} />}
		icon={<ErrorIcon spacing="spacious" label="" color={token('color.icon.danger')} />}
	/>
);
