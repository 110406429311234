import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { mapStateToPropsIncoming, mapStateToPropsOutgoing } from './query.tsx';
import DependenciesCell from './view.tsx';
import type { StateProps, OwnProps } from './types.tsx';

export const IncomingDependenciesCell = connect<StateProps, {}, OwnProps, State>(
	mapStateToPropsIncoming,
)(DependenciesCell);

export const OutgoingDependenciesCell = connect<StateProps, {}, OwnProps, State>(
	mapStateToPropsOutgoing,
)(DependenciesCell);
