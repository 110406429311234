import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import type { CommitNotificationMessages } from '../commit-warning-notification/types.tsx';
import messages from './messages.tsx';
import { useChangesInfo } from './utils.tsx';

export type Props = {
	changes: Change[];
	changesCount: number | undefined;
	commitNotificationMessages: CommitNotificationMessages;
	isUpdating: boolean;
};

export const VisuallyHiddenAnnouncement = ({
	changes,
	changesCount,
	commitNotificationMessages,
	isUpdating,
}: Props) => {
	const { formatMessage } = useIntl();
	const { listChangeAuthors, countCategories } = useChangesInfo();
	const { userName, userList, isSingleUser } = listChangeAuthors(changes);
	const previousCommitAlert = useRef<CommitNotificationMessages>(null);
	const [message, setMessage] = useState('');
	const [ariaLive, setAriaLive] = useState<'off' | 'assertive' | 'polite' | undefined>('polite');

	const isAllUsers = changes.length === changesCount;
	const userSelectOptions = isAllUsers ? 'all' : isSingleUser && 'one';

	useEffect(() => {
		if (isUpdating) return;
		let m = '';

		if (commitNotificationMessages) {
			m = `${commitNotificationMessages.title} ${commitNotificationMessages.description.join(' ')} `;
		}

		setAriaLive(previousCommitAlert.current && !commitNotificationMessages ? 'off' : 'polite');
		previousCommitAlert.current = commitNotificationMessages;

		m += formatMessage(messages.tableChangesAnnouncement, {
			users: userSelectOptions,
			userName,
			userList,
			changes: countCategories(changes),
		});

		setMessage(m);
	}, [
		changes,
		commitNotificationMessages,
		countCategories,
		formatMessage,
		userList,
		userName,
		userSelectOptions,
		isUpdating,
	]);

	return (
		<Box
			aria-live={ariaLive}
			testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.visually-hidden-announcement.box"
		>
			<VisuallyHidden>{message}</VisuallyHidden>
		</Box>
	);
};
