/** @jsx jsx */
import { cssMap, jsx } from '@atlaskit/css';
import Lozenge from '@atlaskit/lozenge';
import type { FocusAreaStatusValue } from '@atlassian/jira-shared-types/src/focus-area.tsx';
import type { FocusAreaTrackerProps } from './types.tsx';

const mapStatusToAppearance = (status: FocusAreaStatusValue) => {
	switch (status) {
		case 'off_track':
			return 'removed';
		case 'at_risk':
			return 'moved';
		case 'on_track':
			return 'success';
		case 'completed':
		case 'paused':
		case 'pending':
		default:
			return 'default';
	}
};

export const FocusAreaTracker = ({
	displayName,
	status,
	isExportMode = false,
}: FocusAreaTrackerProps) => {
	const appearance = mapStatusToAppearance(status);
	const styleName = isExportMode ? 'wrapperExportMode' : 'wrapper';
	return (
		<div css={styles[styleName]}>
			<Lozenge isBold appearance={appearance}>
				{displayName}
			</Lozenge>
		</div>
	);
};
const styles = cssMap({
	wrapper: { width: 'auto' },
	wrapperExportMode: { width: 'auto', display: 'inline-flex' },
});
