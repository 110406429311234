import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.warning-center.title',
		defaultMessage: 'Warn me about scheduling errors in my plan',
		description: 'Title for the warning center setting',
	},
	toggleLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.warning-center.toggle-label',
		defaultMessage: 'Toggle for warning center',
		description: 'Toggle label for the warning center setting',
	},
});
