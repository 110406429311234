import React, { useState, useCallback, useMemo } from 'react';
import { xcss, Pressable, Box } from '@atlaskit/primitives';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import Lozenge from '@atlaskit/lozenge';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '../common/ui/messages.tsx';
import FlyoutContent from '../common/ui/flyout-content/index.tsx';
import FieldBase from '../common/ui/index.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const LabelsCell = ({
	fieldKey,
	values,
	groupName,
	totalIssues,
	unassignedCount,
	groupByLabel,
	swimlane,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { formatMessage } = useIntl();
	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const label = useMemo(
		() =>
			fg('jira-issue-terminology-refresh-m3')
				? commonMessages.workItemCountWithTotalLabel
				: commonMessages.issueCountWithTotalLabel,
		[],
	);

	const content = useMemo(
		() =>
			values.map(({ label: labelText, count }) => ({
				title: (
					<Lozenge>
						<Box as="span" xcss={labelTextStyle}>
							{labelText}
						</Box>
					</Lozenge>
				),
				count,
			})),
		[values],
	);

	const renderContent = useCallback(() => {
		return (
			<FlyoutContent
				fieldName={commonMessages.labelTitle}
				label={label}
				content={content}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.label-cell.label-flyout"
				issuesWithoutField={unassignedCount}
				total={totalIssues}
				showTotal={false}
				ariaLabel={formatMessage(messages.ariaLabel, {
					count: content.length,
					groupByLabel,
					swimlane,
				})}
			/>
		);
	}, [content, label, totalIssues, unassignedCount, groupByLabel, swimlane, formatMessage]);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<FieldBase
				component={Pressable}
				onClick={onToggle}
				triggerProps={triggerProps}
				tabIndex={0}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.label-cell.${groupName}.${fieldKey}`}
				message={messages.numberOfLabels}
				messageValues={{ count: values.length }}
				fieldKey={fieldKey}
			/>
		),
		[onToggle, values.length, groupName, fieldKey],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default LabelsCell;

const labelTextStyle = xcss({
	textTransform: 'none',
});
