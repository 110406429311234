import React, { type ComponentPropsWithoutRef } from 'react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type ToggleProps = {
	columnName?: string;
} & ComponentPropsWithoutRef<typeof Button>;

export const Toggle = ({ style, columnName, ...props }: ToggleProps) => {
	const { formatMessage } = useIntl();
	return (
		<Button
			appearance="subtle"
			aria-label={
				fg('jfp_a11y_team_timeline_column_subheader')
					? formatMessage(messages.collapseAriaLabelWithColumn, {
							column: columnName,
						})
					: formatMessage(messages.collapseAriaLabel)
			}
			{...(fg('jfp_a11y_team_timeline_column_subheader') ? { 'aria-expanded': 'true' } : {})}
			{...props}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				position: 'absolute',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				inset: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: 'auto',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: 'auto',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...{
					...(fg('plan-timeline-a11y-rholloway')
						? {
								outlineOffset: '-2px',
								borderRadius: token('border.radius'),
							}
						: { borderRadius: 0 }),
					...style,
				},
			}}
		/>
	);
};
