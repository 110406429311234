import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button/new';
import { SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { Manager, Reference } from '@atlaskit/popper';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { JiraPopper as Popper } from '@atlassian/jira-popper/src/ui/jira-popper.tsx';
import { useIntl } from '@atlassian/jira-intl';
import KeyboardShortcutTooltip from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/ui/keyboard-shortcut-tooltip/index.tsx';
import { CttOnboardingProvider } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/index.tsx';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights/index.tsx';
import { Spotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights/index.tsx';
import { colourPaletteTextColoursMap } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import messages from './messages.tsx';
import ReviewChangesSpotlightManager from './review-changes-spotlight-manager/index.tsx';
import type { Props } from './types.tsx';

const AK_AUTO_DISMISS_FLAG_MS = 8000;

const ReviewChangesButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			hasCountChanged,
			changesCount,
			isSpotlightActive,
			isOptimizing,
			currentScenarioColor,
			openDialog,
			closeSpotlight,
			activateSpotlight,
			toggleSetHasCountChanged,
			commitNotificationMessages,
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const accountId = useAccountId();
		const [renderCommitNotificationMessages, setRenderCommitNotificationMessages] = useState(false);
		const commitNotificationTimeout = useRef<NodeJS.Timeout | null>(null);

		let isUpdateInProgress = false;
		let numberOfCommittedChanges = 0;
		let totalChanges = 0;
		if (fg('unblock_ui_during_committing_changes')) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isUpdateInProgress = useSelector((state: State) => state.domain.updateJira.commit.inProgress);

			// eslint-disable-next-line react-hooks/rules-of-hooks
			const progressStats = useSelector(
				(state: State) => state.domain.updateJira.commit.progressStats,
			);
			numberOfCommittedChanges = progressStats.numberOfCommittedChanges;
			totalChanges = progressStats.totalChanges;
		}

		useEffect(() => {
			// This is to ensure that the notification messages do not remain prepended to the button text if the user does not dismiss the error notification
			if (commitNotificationTimeout.current) clearTimeout(commitNotificationTimeout.current);
			if (commitNotificationMessages) {
				setRenderCommitNotificationMessages(true);
				commitNotificationTimeout.current = setTimeout(() => {
					setRenderCommitNotificationMessages(false);
				}, AK_AUTO_DISMISS_FLAG_MS);
			} else {
				setRenderCommitNotificationMessages(false);
			}
		}, [commitNotificationMessages]);

		if (isUpdateInProgress && fg('unblock_ui_during_committing_changes')) {
			return (
				<Button onClick={openDialog} appearance="primary">
					<Flex
						gap="space.050"
						alignItems="start"
						justifyContent="space-between"
						xcss={inProgressStyles}
					>
						<Inline>{formatMessage(messages.inProgressButtonTextLeft)}</Inline>
						<Inline>
							{formatMessage(messages.inProgressButtonTextRight, {
								percentage: Math.min(
									Math.round((numberOfCommittedChanges / totalChanges) * 100),
									99,
								),
							})}
						</Inline>
					</Flex>
				</Button>
			);
		}

		return (
			<Manager>
				<Reference>
					{({ ref: popperRef }) => (
						<SpotlightTarget name={CttSpotlights.ReviewChanges}>
							<SpotlightTarget name={Spotlights.SaveYourChanges}>
								<Tooltip content={<KeyboardShortcutTooltip letter="R" />} position="bottom">
									<Box xcss={buttonContainerStyles} ref={popperRef}>
										<SpotlightPulse radius={4} pulse={hasCountChanged && isSpotlightActive}>
											<Button
												onClick={openDialog}
												isDisabled={isOptimizing}
												appearance="primary"
												testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-button"
												ref={ref}
											>
												<Box xcss={buttonLabelStyles}>
													{renderCommitNotificationMessages && commitNotificationMessages && (
														<VisuallyHidden>
															<Box>{`${commitNotificationMessages.title} ${commitNotificationMessages.description.join(' ')}`}</Box>
														</VisuallyHidden>
													)}
													{formatMessage(messages.updateJiraButtonText)}
													{changesCount > 0 && (
														<Box xcss={[changeCountBadgeStyles]}>
															<Badge
																style={{
																	backgroundColor: currentScenarioColor,
																	color: currentScenarioColor
																		? colourPaletteTextColoursMap[
																				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Ignored via go/DSP-18766
																				currentScenarioColor as keyof typeof colourPaletteTextColoursMap
																			] || token('color.text.inverse')
																		: token('color.text.inverse'),
																}}
																testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.change-count"
															>
																{changesCount}
															</Badge>
														</Box>
													)}
												</Box>
											</Button>
										</SpotlightPulse>
									</Box>
								</Tooltip>
							</SpotlightTarget>
						</SpotlightTarget>
					)}
				</Reference>

				<Popper
					messageId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.popper"
					messageType="transactional"
					placement="bottom-end"
				>
					{({ ref: popperRef, style }) =>
						hasCountChanged && accountId ? (
							<CttOnboardingProvider isGlobal accountId={accountId}>
								{/*  eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop */}
								<Box ref={popperRef} style={style} xcss={spotlightContainerStyles}>
									<ReviewChangesSpotlightManager
										closeSpotlight={closeSpotlight}
										activateSpotlight={activateSpotlight}
										isSpotlightActive={isSpotlightActive}
										toggleSetHasCountChanged={toggleSetHasCountChanged}
									/>
								</Box>
							</CttOnboardingProvider>
						) : null
					}
				</Popper>
			</Manager>
		);
	},
);

const buttonLabelStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const changeCountBadgeStyles = xcss({
	display: 'flex',
	marginTop: '0',
	marginRight: 'space.025',
	marginLeft: 'space.075',
	marginBottom: '0',
	outline: 0,
	borderRadius: '2em',
});

const inProgressStyles = xcss({
	width: '100%',
	minWidth: '130px',
});

const buttonContainerStyles = xcss({
	width: 'min-content',
});

const spotlightContainerStyles = xcss({
	zIndex: 'spotlight',
});

export default ReviewChangesButton;
