import tabbable from 'tabbable';

/**
 * Finds the next focusable element in a given direction within a table row.
 *
 * @param direction - The direction to search for the next focusable element. Can be 'forward' or 'backward'.
 * @param rowEl - The table row element to search within.
 * @param cellEl - Optional. The current table cell element. If provided, the search will start after this cell.
 * @returns The next focusable element in the specified direction, or undefined if no focusable element is found.
 */
export const findNextFocusable = (
	direction: 'forward' | 'backward',
	rowEl: HTMLTableRowElement,
	cellEl?: HTMLTableCellElement,
) => {
	const elList = rowEl?.querySelectorAll('*');
	const elementsInRow = Array.from(elList);

	const tabbableEls = tabbable(rowEl);

	if (direction === 'backward') {
		// we want the reverse orders to simplify the search on the next step
		tabbableEls.reverse();
		elementsInRow.reverse();
	}

	const cellIndex = cellEl ? elementsInRow.indexOf(cellEl) : -1;

	return tabbableEls.find((el) => {
		return elementsInRow.indexOf(el) > cellIndex;
	});
};

/**
 * Moves focus to the next or previous tabbable element outside the treegrid.
 *
 * @param direction - The direction to move focus, either 'forward' or 'backward'.
 * @param treegridEl - The HTMLTableElement representing the treegrid.
 * @returns True if focus was successfully moved, otherwise false.
 */
export const exitTreegrid = (direction: 'forward' | 'backward', treegridEl: HTMLTableElement) => {
	const tabbableEls = tabbable(document).filter((el) => {
		const isGridOrOutside = el === treegridEl || !treegridEl.contains(el);
		const isPassthrough = el.getAttribute('data-focus-passthrough') === 'true';
		return isGridOrOutside && !isPassthrough;
	});

	const gridIndex = tabbableEls.indexOf(treegridEl);

	if (gridIndex === -1) {
		// Something went wrong, the grid should always be in the tabbable elements list
		return false;
	}

	const nextFocusable = tabbableEls[gridIndex + (direction === 'forward' ? 1 : -1)];

	if (!nextFocusable) {
		return false;
	}

	nextFocusable.focus();
	return true;
};

/**
 * Calculates the number of cells to skip when there is a colspan.
 *
 * @param step - The number of steps to move, either 'single', 'multiple' or 'all'.
 * @param direction - The direction to move focus, either 'forward' or 'backward'.
 * @param row- The row id.
 * @param column- The column id.
 * @param getColspan- Function that returns the colspan of a cell.
 * @param numOfColumns- Number of columns in the table.
 * @returns Number of cells to skip.
 */
export const getHorizontalSkipValue = (
	step: 'single' | 'multiple' | 'all',
	direction: 'forward' | 'backward',
	row: number,
	column: number,
	getColspan: ({ row, column }: { row: number; column: number }) => number,
	numOfColumns: number,
) => {
	const currentCellColspan = getColspan({ row, column });
	const validCells = []; // cells with a colspan > 0 that user can navigate to
	let targetColumn;

	if (direction === 'forward') {
		if (step === 'all') {
			for (let i = numOfColumns - 1; i > column; i--) {
				const cellColspan = getColspan({ row, column: i });
				if (cellColspan > 0) {
					validCells.push({ colspan: cellColspan, column: i });
				}
				if (validCells.length >= 1) {
					break;
				}
			}

			if (validCells.length) {
				targetColumn = validCells[validCells.length - 1].column;
				return targetColumn - (numOfColumns - 1);
			}
			return undefined;
		}
		if (step === 'single') {
			// if current focused cell has a colspan go to the next immediate cell
			if (currentCellColspan > 0) {
				// if immediate cell doesn't exist, stay on same cell
				if (column + currentCellColspan > numOfColumns - 1) {
					return undefined;
				}
				return currentCellColspan - 1;
			}

			// if current focused cell is enveloped by a colspan then go to the next valid cell
			for (let i = column + 1; i <= numOfColumns - 1; i++) {
				const cellColspan = getColspan({ row, column: i });
				if (cellColspan > 0) {
					validCells.push({ colspan: cellColspan, column: i });
				}
				if (validCells.length >= 1) {
					break;
				}
			}

			targetColumn = validCells[0]?.column;
		}
	}

	if (direction === 'backward') {
		for (let i = column - 1; i >= 0; i--) {
			const cellColspan = getColspan({ row, column: i });
			if (cellColspan > 0) {
				validCells.push({ colspan: cellColspan, column: i });
			}
			if (validCells.length >= 2) {
				break;
			}
		}

		// if current focused cell has a colspan go to the previous valid cell
		if (currentCellColspan > 0) {
			targetColumn = validCells[0]?.column;
		} else {
			// if current focused cell is enveloped by colspan go to the previous valid cell
			targetColumn = validCells[1]?.column;
		}
	}

	// subtract number of steps, as this is added in the navigate() actions
	return targetColumn !== undefined
		? Math.abs(column - targetColumn) -
				(step === 'all' && direction === 'forward' ? numOfColumns - 1 : 1)
		: 0;
};

/**
 * Calculates the number of cells to skip when there is a rowspan.
 *
 * @param step - The number of steps to move, either 'single', 'multiple' or 'all'.
 * @param direction - The direction to move focus, either 'forward' or 'backward'.
 * @param row- The row id.
 * @param column- The column id.
 * @param getRowspan- Function that returns the rowspan of a cell.
 * @param numOfRows- Number of rows in the table.
 * @returns Number of cells to skip.
 */
export const getVerticalSkipValue = (
	step: 'single' | 'multiple' | 'all',
	direction: 'forward' | 'backward',
	row: number,
	column: number,
	getRowspan: ({ row, column }: { row: number; column: number }) => number,
	numOfRows: number,
) => {
	const currentCellRowspan = getRowspan({ row, column });
	const validCells = []; // cells with a rowspan > 0 that user can navigate to
	let targetRow;

	if (direction === 'forward') {
		if (step === 'single') {
			// if current focused cell has a rowspan go to the next immediate cell
			if (currentCellRowspan > 0) {
				// if immediate cell doesn't exist, stay on same cell
				if (row + currentCellRowspan > numOfRows - 1) {
					return undefined;
				}
				return currentCellRowspan - 1;
			}

			// if current focused cell is enveloped by a rowspan then go to the next valid cell
			for (let i = row + 1; i <= numOfRows - 1; i++) {
				const cellRowspan = getRowspan({ row: i, column });
				if (cellRowspan > 0) {
					validCells.push({ rowspan: cellRowspan, row: i });
				}
				if (validCells.length >= 1) {
					break;
				}
			}

			targetRow = validCells[0]?.row;

			if (targetRow === undefined) {
				return undefined;
			}
		} else if (step === 'all') {
			for (let i = numOfRows - 1; i > column; i--) {
				const cellRowspan = getRowspan({ row: i, column });
				if (cellRowspan > 0) {
					validCells.push({ rowspan: cellRowspan, row: i });
				}
				if (validCells.length >= 1) {
					break;
				}
			}

			if (validCells.length) {
				targetRow = validCells[validCells.length - 1].row;
			} else {
				return undefined;
			}
		} else if (step === 'multiple') {
			for (let i = row + 1; i <= numOfRows - 1; i++) {
				const cellRowspan = getRowspan({ row: i, column });
				if (cellRowspan > 0) {
					validCells.push({ rowspan: cellRowspan, row: i });
				}
				if (validCells.length >= 5) {
					break;
				}
			}
			if (validCells.length) {
				targetRow = validCells[validCells.length - 1].row;
			} else {
				return undefined;
			}
		}
	}

	if (direction === 'backward') {
		for (let i = row - 1; i >= 0; i--) {
			const cellRowspan = getRowspan({ row: i, column });
			if (cellRowspan > 0) {
				validCells.push({ rowspan: cellRowspan, row: i });
			}
			if (validCells.length >= 2) {
				break;
			}
		}

		// if current focused cell has a rowspan go to the previous valid cell
		if (currentCellRowspan > 0) {
			targetRow = validCells[0]?.row;
		} else {
			// if current focused cell is enveloped by rowspan go to the previous valid cell
			targetRow = validCells[1]?.row;
		}
	}

	const otherSteps = step === 'multiple' && direction === 'forward' ? 5 : 1;
	// subtract number of steps, as this is added in the navigate() actions
	return targetRow !== undefined
		? Math.abs(row - targetRow) -
				(step === 'all' && direction === 'forward' ? numOfRows - 1 : otherSteps)
		: 0;
};
