import React, { Fragment, useState } from 'react';
import * as R from 'ramda';
import AddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { ALL_OTHER_ISSUES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/types.tsx';
import type { CustomFieldValuesGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/custom-field-values-groups/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { CustomFieldTypes } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import Group from '../common/group/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function GroupByCustomFieldValues({
	addGroup,
	customFieldValuesGroups,
	customLabelFieldOptions,
	grouping,
	groupingCustomFieldType,
	onMenuToggle,
	selectOptions,
	removeGroup,
	updateGroup,
}: Props) {
	const [isCreating, setIsCreating] = useState<boolean>(false);

	const { formatMessage } = useIntl();

	const trimName = (name: string): string => {
		const maxLength = 254;
		return name.length > maxLength ? name.substring(0, maxLength) : name;
	};

	const isNewGroupNameValid = (newName: string): boolean => {
		const allGroupNames = new Set(customFieldValuesGroups.map(({ name }) => name));
		return !allGroupNames.has(newName);
	};

	const onCreateGroup = () => {
		setIsCreating(true);
	};

	const onCancelCreateGroup = () => {
		setIsCreating(false);
	};

	const onConfirmingCreateGroup = (newName: string): void => {
		onCancelCreateGroup();

		if (newName.length === 0 || !isNewGroupNameValid(newName)) {
			return;
		}

		addGroup(grouping, {
			id: trimName(newName).toLowerCase(),
			name: trimName(newName),
			isExpanded: true,
			[grouping]: [],
		});
	};

	const onConfirmingUpdateGroup = (id: string, newName: string): void => {
		if (newName.length === 0 || !isNewGroupNameValid(newName)) {
			return;
		}

		updateGroup(grouping, id, {
			id: trimName(newName).toLowerCase(),
			name: trimName(newName),
		});
	};

	const onUpdatingValuesInGroup = (id: string, selectValue: ValueType): void => {
		if (isDefined(selectValue)) {
			updateGroup(grouping, id, {
				[grouping]: selectValue.map(({ value }: { value: string }) => value),
			});
		}
	};

	const toggleExpanded = (id: string, isExpanded: boolean): void => {
		updateGroup(grouping, id, {
			isExpanded,
		});
	};

	const renderComponentSelect = (groupId: string, values: string[]) => {
		let options = [];
		let valueOptions = [];

		if (groupingCustomFieldType === CustomFieldTypes.Labels) {
			options = customLabelFieldOptions.map((label) => ({
				value: label,
				label,
			}));
			valueOptions = options.filter(({ value }) => values.includes(value));
		} else {
			options = R.sortBy(R.compose(R.toLower, R.prop('value')))(selectOptions).map(
				({ id, value }): OptionType => ({
					value: id,
					label: value,
				}),
			);

			valueOptions = options.filter(({ value }) => values.includes(value));
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<tr className={styles.row}>
				<td />
				<td colSpan={2}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.customFieldValueSelect}>
						<AkSelect
							autoFocus
							classNamePrefix="group-by-custom-options-select"
							defaultValue={valueOptions}
							isMulti
							menuPortalTarget={getBody()}
							onChange={(value: ValueType) => onUpdatingValuesInGroup(groupId, value)}
							onMenuClose={() => {
								onMenuToggle(false);
							}}
							onMenuOpen={() => {
								onMenuToggle(true);
							}}
							options={options}
							placeholder={formatMessage(messages.componentPlaceholder)}
							aria-label={formatMessage(messages.componentPlaceholder)}
							// eslint-disable-next-line @typescript-eslint/no-shadow
							styles={{ menuPortal: (styles) => ({ ...styles, zIndex: 1000 }) }}
						/>
					</div>
				</td>
			</tr>
		);
	};

	const renderGroup = (group: CustomFieldValuesGroup) => {
		const { id, isExpanded, [grouping]: values } = group;
		return (
			<Fragment key={id}>
				<tr
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.row}
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.custom-fields.group"
				>
					<td />
					<td colSpan={2}>
						<Group
							groupId={id}
							groupName={group.name}
							isExpanded={isExpanded}
							onCancel={onCancelCreateGroup}
							onConfirm={(newName: string) => {
								onConfirmingUpdateGroup(id, newName);
							}}
							onDelete={(groupId: string) => removeGroup(grouping, groupId)}
							onToggleExpanded={toggleExpanded}
						/>
					</td>
				</tr>
				{id !== ALL_OTHER_ISSUES &&
					isExpanded &&
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					renderComponentSelect(id, values as string[])}
			</Fragment>
		);
	};

	return (
		<>
			{customFieldValuesGroups.map(renderGroup)}
			{isCreating && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<tr className={styles.row}>
					<td />
					<td colSpan={2}>
						<Group
							onCancel={onCancelCreateGroup}
							onConfirm={onConfirmingCreateGroup}
							shouldCreateGroup
						/>
					</td>
				</tr>
			)}
			<tr>
				<td />
				<td colSpan={2}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.customFieldValuesGroup}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.icon}>
							<AddIcon spacing="spacious" label="" color={token('color.text.brand')} />
						</div>
						<Button
							appearance="link"
							onClick={onCreateGroup}
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.custom-fields.create-custom-field-values-group"
						>
							{formatMessage(messages.createGroup)}
						</Button>
					</div>
				</td>
			</tr>
		</>
	);
}
