import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.progress-estimate-cell.aria-label',
		defaultMessage: 'Issue estimate breakdown for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Progress field in the group header row',
	},
	progressEstimateByPointsHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.progress-estimate-cell.progress-estimate-by-points-header',
		defaultMessage: 'Progress (story points)',
		description: 'This is a header for the progress by story points flyout.',
	},
	progressEstimateByHoursHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.progress-estimate-cell.progress-estimate-by-hours-header',
		defaultMessage: 'Progress (hours)',
		description: 'This is a header for the progress by hours flyout.',
	},
	progressEstimateByDaysHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.progress-estimate-cell.progress-estimate-by-days-header',
		defaultMessage: 'Progress (days)',
		description: 'This is a header for the progress by days flyout.',
	},
});
