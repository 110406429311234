import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	TABLE_GROUP,
	type GroupTableItem,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import {
	GROUPING,
	COMPLETED_SPRINTS_GROUP,
	COMPLETED_RELEASES_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isRoadmapGroupedByCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

import { getAllTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { getSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import type { GroupDescriptor } from './types.tsx';
import {
	UNASSIGNED,
	OTHERS,
	UNKNOWN_TEAM,
	COMPLETED_RELEASE,
	COMPLETED_SPRINT,
} from './constants.tsx';

/**
 * getGroupDescriptorPure return the group name OR an identifier of a message descriptor map;
 * Map is used for retrieving the message for undefined group;
 * Group name is undefined when group is either unknown, unassigned, or completed etc.
 */
// exported only for testing
export const getGroupDescriptorPure = (
	allTeams: Team[],
	sprints: Sprint[],
	item: TableItem,
): GroupDescriptor => {
	if (item.tag === TABLE_GROUP) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const tableItem = item as GroupTableItem;
		const { value } = tableItem;

		if (item.group === UNDEFINED_GROUP) {
			return {
				messageKey: UNASSIGNED,
				count: undefined,
			};
		}
		if (item.grouping === GROUPING.SPRINT) {
			if (item.groupCombination.sprint === COMPLETED_SPRINTS_GROUP) {
				return {
					messageKey: COMPLETED_SPRINT,
					count: item.childGroups
						? Object.keys(
								groupBy(
									item.childGroups,
									(childGroup) =>
										(childGroup.groupCombination && childGroup.groupCombination.sprint) || '',
								),
							).length
						: undefined,
				};
			}
			const sprint = sprints.find(({ id }) => {
				const sprintId = get(item, ['groupCombination', 'sprint']);
				return id === sprintId;
			});
			return sprint
				? {
						groupName: sprint.title,
					}
				: {
						messageKey: UNASSIGNED,
						count: undefined,
					};
		}
		if (!value) {
			const { grouping, childGroups } = item;

			switch (grouping) {
				case GROUPING.PROJECT:
					return {
						messageKey: UNASSIGNED,
						count: undefined,
					};
				case GROUPING.COMPONENT:
				case GROUPING.LABEL:
				case GROUPING.GOALS:
				case GROUPING.IDEAS:
				case isRoadmapGroupedByCustomField(grouping) && grouping: {
					return {
						messageKey: OTHERS,
						count: undefined,
					};
				}
				case GROUPING.TEAM: {
					const teamId = get(item, ['groupCombination', 'team']);
					const teamName = allTeams.find((team) => team.id === teamId);
					return teamName
						? {
								groupName: teamName.title,
							}
						: {
								messageKey: UNKNOWN_TEAM,
								count: undefined,
							};
				}
				case GROUPING.RELEASE: {
					if (item.groupCombination.release === COMPLETED_RELEASES_GROUP) {
						return {
							messageKey: COMPLETED_RELEASE,
							count: childGroups?.length ?? 0,
						};
					}
					break;
				}
				default: {
					return {
						messageKey: UNASSIGNED,
						count: undefined,
					};
				}
			}
		}
		return {
			groupName: value,
		};
	}

	return {
		messageKey: UNASSIGNED,
		count: undefined,
	};
};

export const getGroupDescriptor = createSelector(
	[getAllTeams, getSprints, (_: State, { item }: { item: TableItem }) => item],
	getGroupDescriptorPure,
);
