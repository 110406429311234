import type { MessageDescriptor } from '@atlassian/jira-intl';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import messages from './messages.tsx';

type RouteMappingObject = {
	integrationType: string;
	shareTitle: MessageDescriptor;
	shareFormTitle: MessageDescriptor;
};

export const routeMapping: Partial<Record<RouteName, RouteMappingObject>> = {
	'software-plan-timeline': {
		integrationType: integrationTypes.TIMELINE,
		shareTitle: messages.timelineTitle,
		shareFormTitle: messages.timelineFormTitle,
	},
	'software-plan-dependencies': {
		integrationType: integrationTypes.DEPENDENCIES,
		shareTitle: messages.dependenciesTitle,
		shareFormTitle: messages.dependenciesFormTitle,
	},
	'software-plan-calendar': {
		integrationType: integrationTypes.CALENDAR,
		shareTitle: messages.calendarTitle,
		shareFormTitle: messages.calendarFormTitle,
	},
	'software-plan-increment': {
		integrationType: integrationTypes.INCREMENT,
		shareTitle: messages.programTitle,
		shareFormTitle: messages.programFormTitle,
	},
	'software-plan-summary': {
		integrationType: integrationTypes.SUMMARY,
		shareTitle: messages.summaryTitle,
		shareFormTitle: messages.summaryFormTitle,
	},
};
