import React from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useFieldType } from '../controllers/fields/index.tsx';

import { CheckboxField, isCheckboxFieldComponentProps } from './checkbox/index.tsx';
import { DatetimeField, isDatetimeFieldComponentProps } from './datetime/index.tsx';
import { HyperlinkField, isHyperlinkFieldComponentProps } from './hyperlink/index.tsx';
import { IntervalField, isIntervalFieldComponentProps } from './interval/index.tsx';
import { LabelsField, isLabelsFieldComponentProps } from './labels/index.tsx';
import messages from './messages.tsx';
import { MultiSelectField, isMultiSelectFieldComponentProps } from './multiselect/index.tsx';
import { NumberField, isNumberFieldComponentProps } from './number/index.tsx';
import { PeopleField, isPeopleFieldComponentProps } from './people/index.tsx';
import { RatingField, isRatingFieldComponentProps } from './rating/index.tsx';
import { ShortTextField, isShortTextFieldComponentProps } from './short-text/index.tsx';
import { SingleSelectField, isSingleSelectFieldComponentProps } from './singleselect/index.tsx';
import { StatusField, isStatusFieldComponentProps } from './status/index.tsx';
import type { FieldComponentProps } from './types.tsx';
import { UserField, isUserFieldComponentProps } from './user/index.tsx';

function isStringArray(value: unknown): value is string[] {
	return Array.isArray(value) && value.every((item) => typeof item === 'string');
}
function isNumberArray(value: unknown): value is number[] {
	return Array.isArray(value) && value.every((item) => typeof item === 'number');
}

export const FieldComponent = (props: FieldComponentProps) => {
	const { fieldKey, value, users, statuses, ideaId, ideaKey } = props;
	const [fieldType] = useFieldType(fieldKey);

	switch (fieldType) {
		case FIELD_TYPES.STATUS:
			if (!value || !statuses || !ideaId || !ideaKey) {
				return <NoneBox />;
			}
			if (isStatusFieldComponentProps(props)) {
				return <StatusField {...props} />;
			}
			break;
		case FIELD_TYPES.SINGLE_SELECT:
			if (!value) {
				return <NoneBox />;
			}
			if (isSingleSelectFieldComponentProps(props)) {
				return <SingleSelectField {...props} />;
			}
			break;
		case FIELD_TYPES.RATING:
			if (isRatingFieldComponentProps(props)) {
				return <RatingField {...props} />;
			}
			break;
		case FIELD_TYPES.CHECKBOX:
			if (isCheckboxFieldComponentProps(props)) {
				return <CheckboxField {...props} />;
			}
			break;
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			if (isMultiSelectFieldComponentProps(props) && isNumberArray(value)) {
				if (!value.length) {
					return <NoneBox />;
				}
				return <MultiSelectField {...props} />;
			}
			break;
		case FIELD_TYPES.INTERVAL:
			if (!value) {
				return <NoneBox />;
			}
			if (isIntervalFieldComponentProps(props)) {
				return <IntervalField {...props} />;
			}
			break;
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
			if (isNumberFieldComponentProps(props)) {
				return <NumberField {...props} />;
			}
			break;
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			if (isLabelsFieldComponentProps(props) && isStringArray(value)) {
				if (!value || !value.length) {
					return <NoneBox />;
				}
				return <LabelsField {...props} />;
			}
			break;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			if (isPeopleFieldComponentProps(props) && isStringArray(value)) {
				if (!users || !value || !value.length) {
					return <NoneBox />;
				}
				return <PeopleField {...props} />;
			}
			break;
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
			if (isUserFieldComponentProps(props)) {
				if (!users || !value) {
					return <NoneBox />;
				}
				return <UserField {...props} />;
			}
			break;
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
			if (isDatetimeFieldComponentProps(props)) {
				return <DatetimeField {...props} />;
			}
			break;
		case FIELD_TYPES.SHORT_TEXT:
			if (!value) {
				return <NoneBox />;
			}
			if (isShortTextFieldComponentProps(props)) {
				return <ShortTextField {...props} />;
			}
			break;
		case FIELD_TYPES.HYPERLINK:
			if (!value) {
				return <NoneBox />;
			}
			if (isHyperlinkFieldComponentProps(props)) {
				return <HyperlinkField {...props} />;
			}
			break;

		default:
			return <DefaultField />;
	}
};

const DefaultField = () => {
	return null;
};

const NoneBox = () => {
	const { formatMessage } = useIntl();
	return <Box xcss={noneBoxStyles}>{formatMessage(messages.noValuePlaceholder)}</Box>;
};

const noneBoxStyles = xcss({
	color: 'color.text.subtle',
	padding: 'space.050',
});

export default FieldComponent;
