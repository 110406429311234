/** @jsx jsx */
import React, { Component } from 'react';
import { jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import LockLockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-circle';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { Text } from '@atlaskit/primitives';
import AkTextField from '@atlaskit/textfield';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { EXPORT_EVENT_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/index.tsx';
import type { AdditionalData } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/types.tsx';
import {
	type ShareAs,
	DEPENDENCY_REPORT_SHARE_TYPE,
	SHARE_AS,
	IPH_IDS,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../styles.module.css';
import { CSVExport } from './csv-export/index.tsx';
import messages from './messages.tsx';
import PNGExportContent from './png-export/index.tsx';
import { getCsvIntlMessages } from './utils.tsx';

type State = {
	isCopied: boolean;
	didCsvDownload: boolean;
};

// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
export default class Content extends Component<any, State> {
	state = {
		isCopied: false,
		didCsvDownload: false,
	};

	componentDidUpdate = () => {
		this.selectTextInUrlField();
	};

	componentWillUnmount = () => {
		const { shareAs, analyticsEvent } = this.props;
		if (analyticsEvent && shareAs === SHARE_AS.CSV) {
			const { planMode } = this.props;
			const { didCsvDownload } = this.state;

			const [actionSubject, action, actionSubjectId] =
				PRODUCT_ANALYTICS_EVENT_NAMES.EXPORT_CSV_MODAL_CLOSED.split(' ');

			fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), actionSubjectId, {
				didDownload: didCsvDownload,
				planMode,
			});
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	input: any;

	selectTextInUrlField = () => {
		if (this.input) {
			this.input.select();
		}
	};

	copyToClipboard = () => {
		const { planExportedAnalytics, shareAs } = this.props;
		this.selectTextInUrlField();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.execCommand('copy');
		if (this.input) {
			this.input.focus();
		}
		this.setState(
			{
				isCopied: true,
			},
			() => {
				/** This is a temporary workaround to have 'Copied' disappear after sometime.
				 * We added this because there is no AK comp that provides this feature. Once we have
				 * this feature in AK we can replace this code.
				 */
				setTimeout(() => {
					if (this.input) {
						this.input.blur();
					}
					this.setState({
						isCopied: false,
					});
				}, 5000);
			},
		);

		const exportFormats = Object.values(EXPORT_EVENT_TYPE);
		if (exportFormats.includes(shareAs)) {
			planExportedAnalytics({
				exportFormat: shareAs,
			});
		}
	};

	getUrl = () => {
		const { url, shareAs } = this.props;
		if (shareAs === SHARE_AS.EMBED) {
			return `<iframe src='${url}' width='1024' height='640' style='border:1px solid #ccc;'></iframe>`;
		}
		return url;
	};

	getConfluenceMacroSharingMessage = () => {
		const { confluenceMacroType } = this.props;
		switch (confluenceMacroType) {
			case DEPENDENCY_REPORT_SHARE_TYPE:
				return <FormattedMessage {...messages.confluenceDependencyMacroSharingMessage} />;
			default:
				return <FormattedMessage {...messages.confluenceTimelineMacroSharingMessage} />;
		}
	};

	confluenceSharingIntro = () => {
		const { intl, onClose } = this.props;

		return (
			<>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{this.getConfluenceMacroSharingMessage()}</p>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>
					<FormattedMessage
						{...messages.confluenceMacroSharingInstructions}
						values={{
							link: (
								<HelpPanelLink
									articleId={IPH_IDS.SHARE_CONFLUENCE}
									fallbackComponent={
										<DocLink href={DocLink.URLs.schedulingWork}>
											{intl.formatMessage(messages.confluenceMacroIph)}
										</DocLink>
									}
									appearance="link"
									spacing="none"
									onClick={onClose}
								>
									{intl.formatMessage(messages.confluenceMacroIph)}
								</HelpPanelLink>
							),
						}}
					/>
				</p>
			</>
		);
	};

	textFieldValue = () => {
		const { intl, isLoading } = this.props;

		return isLoading ? intl.formatMessage({ ...messages.generatingUrl }) : this.getUrl();
	};

	isConfluenceSharingType = (shareAs: ShareAs) =>
		shareAs === SHARE_AS.CONFLUENCE || shareAs === SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE;

	render() {
		const {
			intl: { formatMessage },
			isLoading,
			isReportMode,
			onClose,
			shareAs,
			planTitle,
		} = this.props;

		const { isCopied } = this.state;

		const isConfluenceSharing =
			shareAs === SHARE_AS.CONFLUENCE || this.isConfluenceSharingType(shareAs);

		if (shareAs === SHARE_AS.CSV) {
			const { getExportCsvScopeItems, noIssuesInPlan, definedGoalsByARI, associatedIssues } =
				this.props;
			const csvIntlMessages = getCsvIntlMessages(formatMessage, planTitle);
			const onClickExport = (
				additionalData: AdditionalData = { goalsByARI: {}, associatedIssues: {} },
			) => {
				if (!noIssuesInPlan) {
					getExportCsvScopeItems(csvIntlMessages, additionalData);
					this.setState({
						didCsvDownload: true,
					});
				}
			};

			return (
				<CSVExport
					isReportMode={isReportMode}
					noIssuesInPlan={noIssuesInPlan}
					onClickExport={onClickExport}
					onClose={onClose}
					definedGoalsByARI={definedGoalsByARI}
					associatedIssues={associatedIssues}
				/>
			);
		}

		if (shareAs === SHARE_AS.PNG) {
			const { planMode } = this.props;
			let { PNGExportContentComponent } = this.props;
			PNGExportContentComponent = PNGExportContentComponent || PNGExportContent;
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.content}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.contentHeading}>
						<Heading size="medium" as="h2">
							{formatMessage(messages.shareDialogHeader)}
						</Heading>
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.contentBody}>
						<PNGExportContentComponent onClose={onClose} planMode={planMode} />
					</div>
				</div>
			);
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.content}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.contentHeading}>
					<Heading size="medium" as="h2">
						{formatMessage({ ...messages.shareDialogHeader })}
					</Heading>
				</div>
				{isConfluenceSharing ? (
					this.confluenceSharingIntro()
				) : (
					<Text>{formatMessage({ ...messages.urlLabel })}</Text>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.urlForm}>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.urlTextfield}
						data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.url"
					>
						<AkTextField
							aria-label={formatMessage({ ...messages.confluenceUrlLabel })}
							isReadOnly
							ref={(fieldRef) => {
								this.input = fieldRef;
							}}
							value={this.textFieldValue()}
						/>
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.copyButton}>
						<Button
							appearance="primary"
							onClick={this.copyToClipboard}
							isDisabled={isLoading}
							testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.copy-button"
						>
							{formatMessage({ ...messages.copyButtonText })}
						</Button>
					</div>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.copiedMessage}
						role="alert"
						aria-live="polite"
						style={{ visibility: isCopied ? 'visible' : 'hidden' }}
					>
						{formatMessage({ ...messages.copiedButtonText })}
					</div>
				</div>
				{isConfluenceSharing ? (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.accessControl}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.accessControlMacroIcon}>
							<InfoIcon
								label={formatMessage(messages.accessControlNoteIcon)}
								LEGACY_size="medium"
								spacing="spacious"
							/>
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.accessControlMessage}>
							<FormattedMessage {...messages.accessControlMacroDescription} />
						</div>
					</div>
				) : (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.accessControl}>
						<LockLockedIcon
							label={formatMessage({ ...messages.accessControlIcon })}
							color="currentColor"
							spacing="spacious"
						/>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.accessControlMessage}>
							<FormattedMessage
								{...messages.accessControlDescriptionTimeline}
								values={{
									action: <Text as="strong">{formatMessage(messages.accessControlView)}</Text>,
								}}
							/>
						</div>
					</div>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.contentFooter}>
					<Button onClick={onClose} appearance="subtle">
						{formatMessage(commonMessages.cancel)}
					</Button>
				</div>
			</div>
		);
	}
}
