import React from 'react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { IssueCardPinnedFields } from '../issue-card-pinned-fields/src/ui/index.tsx';

type Props = {
	data: AssociatedIssue;
	projectId: string;
	issueKey: string;
	issueId: string;
};

export const IssueCardContent = ({ data, projectId, issueKey, issueId }: Props) => (
	<Box>
		<Box xcss={fg('aggregate_fields_for_plan_m2') ? ideaContentCardTitle : ideaContentCardTitleOld}>
			<Box
				xcss={fg('aggregate_fields_for_plan_m2') ? ideaContentCardAvatar : ideaContentCardAvatarOld}
			>
				{fg('aggregate_fields_for_plan_m2') ? (
					<AsyncIcon alt="" url={data.iconUrl} width={16} height={16} />
				) : (
					<Avatar src={data.iconUrl} size="xsmall" />
				)}
			</Box>
			<Anchor href={`/browse/${data.key}`} target="_blank" xcss={anchorStyles}>
				{data.key}
			</Anchor>
		</Box>
		<Heading as="div" size="xsmall">
			{data.summary}
		</Heading>

		<IssueCardPinnedFields projectId={projectId} issueKey={issueKey} issueId={issueId} />
	</Box>
);

const ideaContentCardTitle = xcss({
	display: 'flex',
	marginBottom: 'space.150',
	alignItems: 'center',
});

const ideaContentCardTitleOld = xcss({
	display: 'flex',
	marginBottom: 'space.150',
});

const ideaContentCardAvatar = xcss({
	display: 'flex',
	marginRight: 'space.100',
});

const ideaContentCardAvatarOld = xcss({
	marginRight: 'space.050',
});

const anchorStyles = xcss({
	textDecoration: 'none',

	':hover': {
		textDecoration: 'underline',
	},
});
