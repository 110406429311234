import React from 'react';
import Heading from '@atlaskit/heading';

import { useModal } from '@atlaskit/modal-dialog';

interface ModalHeaderProps {
	children: string;
}

const ModalHeader = ({ children }: ModalHeaderProps) => {
	const { titleId } = useModal();

	return (
		<Heading as="h1" size="medium" id={titleId}>
			{children}
		</Heading>
	);
};

export default ModalHeader;
