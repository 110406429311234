import React, { Component } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { MODAL_DIALOG_WIDTH } from '../../types.tsx';
import messages from './messages.tsx';
import SetRankForm from './set-rank-form/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SetRankDialog extends Component<Props> {
	static defaultProps = { SetRankForm };

	submitForm = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		formData: any,
		analyticsEvent: UIAnalyticsEvent,
		event?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
	) => {
		const { bulkUpdateRank, toggleModalDialog, bulkActionSuccess } = this.props;
		bulkUpdateRank(formData);
		if (fg('plan-timeline-a11y-meatball-menu')) {
			toggleModalDialog(event);
		} else {
			toggleModalDialog();
		}
		bulkActionSuccess(analyticsEvent);
	};

	render() {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { intl, toggleModalDialog, SetRankForm, shouldReturnFocus } = this.props;
		return (
			<ShortcutScope>
				<ModalDialog
					messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.modal-dialog"
					messageType="transactional"
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank"
					{...(fg('plan_timeline_layering_wrapper') ? { shouldReturnFocus } : {})}
				>
					<ModalHeader>
						<ModalTitle>{intl.formatMessage(messages.setHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<SetRankForm onCancel={toggleModalDialog} onSubmit={this.submitForm} />
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetRankDialog);
