// Note: We use block comment at top of the file because we get strange error in `SSR Image Build`
/* eslint-disable @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-unsafe-style-overrides */

import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { IssueCardContent } from './issue-card-content/index.tsx';

const IssueCardContentOld = ({ data }: { data: AssociatedIssue }) => (
	<Box>
		<Box xcss={ideaContentCardTitle}>
			<Box
				xcss={fg('aggregate_fields_for_plan_m2') ? ideaContentCardAvatar : ideaContentCardAvatarOld}
			>
				{fg('aggregate_fields_for_plan_m2') ? (
					<AsyncIcon alt="" url={data.iconUrl} width={16} height={16} />
				) : (
					<Avatar src={data.iconUrl} size="xsmall" />
				)}
			</Box>
			<Anchor href={`/browse/${data.key}`} target="_blank" xcss={anchorStyles}>
				{data.key}
			</Anchor>
		</Box>
		<Heading as="div" size="xsmall">
			{data.summary}
		</Heading>
	</Box>
);

type IdeaIssueProps = {
	data: AssociatedIssue;
	flexShrink: number;
	projectId: string;
};

const IdeaIssue = ({ data, flexShrink, projectId }: IdeaIssueProps) => (
	<Box xcss={fg('aggregate_fields_for_plan_m2') ? container : containerOld} style={{ flexShrink }}>
		<Tooltip
			content={() =>
				fg('polaris-pinned-fields-for-ideas-in-plans') ? (
					<IssueCardContent
						data={data}
						projectId={projectId}
						issueKey={data.key}
						issueId={data.id}
					/>
				) : (
					<IssueCardContentOld data={data} />
				)
			}
			component={
				fg('polaris-pinned-fields-for-ideas-in-plans')
					? TooltipLikeInlineDialog
					: TooltipLikeInlineDialogOld
			}
		>
			{(tooltipProps) => (
				<Box
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...tooltipProps}
					xcss={fg('aggregate_fields_for_plan_m2') ? contentWrapper : contentWrapperOld}
				>
					<Box xcss={fg('aggregate_fields_for_plan_m2') ? avatar : avatarOld}>
						{fg('aggregate_fields_for_plan_m2') ? (
							<AsyncIcon alt="" url={data.iconUrl} width={16} height={16} />
						) : (
							<Avatar src={data.iconUrl} size="xsmall" />
						)}
					</Box>
					<Box xcss={summary}>{data.summary}</Box>
				</Box>
			)}
		</Tooltip>
	</Box>
);

export default IdeaIssue;

const container = xcss({
	display: 'flex',
	alignItems: 'center',
	minWidth: 'size.300',
	height: '100%',
	flexBasis: 'content',
});

const containerOld = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	minWidth: 'size.300',
	height: '100%',
	flexBasis: 'content',
});

const avatar = xcss({
	display: 'flex',
	paddingRight: 'space.075',
	flexShrink: 0,
});

const avatarOld = xcss({
	paddingLeft: 'space.050',
	flexShrink: 0,
});

const summary = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const contentWrapper = xcss({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
});

const contentWrapperOld = xcss({
	display: 'flex',
	width: '100%',
});

const ideaContentCardTitle = xcss({
	display: 'flex',
	marginBottom: 'space.150',
});

const ideaContentCardAvatar = xcss({
	display: 'flex',
	marginRight: 'space.100',
});

const ideaContentCardAvatarOld = xcss({
	marginLeft: 'space.050',
});

const TooltipLikeInlineDialog = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	backgroundColor: token('elevation.surface.overlay'),
	borderRadius: token('border.radius'),
	boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
	boxSizing: 'content-box',
	paddingTop: token('space.100'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.150'),
	width: '308px',
});

const TooltipLikeInlineDialogOld = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	backgroundColor: 'white',
	borderRadius: token('border.radius'),
	boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
	boxSizing: 'content-box',
	paddingTop: token('space.100'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.150'),
	width: '308px',
});

const anchorStyles = xcss({
	textDecoration: 'none',

	':hover': {
		textDecoration: 'underline',
	},
});
