import type { FocusArea } from './types.tsx';

export const RESET = 'state.domain.focusAreas.RESET' as const;

export type ResetActionPayload = FocusArea[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
