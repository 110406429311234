import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import mapStateToProps, { mapStateToPropsOld } from './query.tsx';
import View, { AggregateFieldOld as ViewOld } from './view.tsx';
import type { StateProps, StatePropsOld, OwnProps, OwnPropsOld } from './types.tsx';

const AggregateField = connect<StateProps, {}, OwnProps, State>(mapStateToProps, null)(View);

export const AggregateFieldOld = connect<StatePropsOld, {}, OwnPropsOld, State>(
	mapStateToPropsOld,
	null,
)(ViewOld);

export default AggregateField;
