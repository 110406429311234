import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	selectiveSchedulingDescriptionParagraphOne: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-description-paragraph-one',
		defaultMessage:
			'Select which estimated issues you want to auto-schedule using the checkboxes in the Scope section of your plan.',
		description:
			'The first paragraph of the description text for the auto-schedule dialog with selective scheduling functionality highlighted.',
	},
	selectiveSchedulingDescriptionParagraphTwo: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-description-paragraph-two',
		defaultMessage:
			'If you select none, the auto-scheduler will create a plan for all of your estimated issues based on their ranking, the velocity of teams in your plan, and any dependencies you set.',
		description:
			'The second paragraph of the description text for the auto-schedule dialog with selective scheduling functionality highlighted.',
	},
	iphLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.iph-link',
		defaultMessage: 'See what the auto-scheduler does',
		description: 'Subscript link to the IPH article about the auto-scheduler',
	},
	selectiveSchedulingSelectedIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-selected-issues',
		defaultMessage: '{count, plural, one {# issue} other {# issues}} selected',
		description: 'The description text to indicate how many issues are being auto-scheduled.',
	},
	overwriteTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-title',
		defaultMessage: 'Overwriting issue values',
		description: 'This is the title of the configuration section.',
	},
	overwriteDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-description',
		defaultMessage:
			'You can choose to overwrite fields that have existing values, or to set values only if the fields are empty.',
		description: 'This is the description of the configuration section.',
	},
	overwriteAllValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-all-values',
		defaultMessage: 'All values',
		description: 'It is a label of the option for overwriting all values.',
	},
	overwriteEmptyValuesOnly: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-empty-values-only',
		defaultMessage: 'Empty values only',
		description: 'It is a label of the option for overwriting empty values only.',
	},
	previewResultsWithCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.preview-results-with-count',
		defaultMessage: 'Preview {count, plural, one {{count} work item} other {{count} work items}}',
		description:
			'It is a label of preview results button after auto-scheduler function is applied for selected issues',
	},
	previewResults: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.preview-results',
		defaultMessage: 'Preview results',
		description:
			'It is a label of preview results button after auto-scheduler function is applied for all issues in timeline',
	},
	selectiveSchedulingDescriptionParagraphOneIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-description-paragraph-one-issue-term-refresh',
		defaultMessage:
			'Select which estimated work items you want to auto-schedule using the checkboxes in the scope section of your plan.',
		description:
			'The first paragraph of the description text for the auto-schedule dialog with selective scheduling functionality highlighted.',
	},
	selectiveSchedulingDescriptionParagraphTwoIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-description-paragraph-two-issue-term-refresh',
		defaultMessage:
			'If you select none, the auto-scheduler will create a plan for all of your estimated work items based on their ranking, the velocity of teams in your plan, and any dependencies you set.',
		description:
			'The second paragraph of the description text for the auto-schedule dialog with selective scheduling functionality highlighted.',
	},
	selectiveSchedulingSelectedIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-selected-issues-issue-term-refresh',
		defaultMessage: '{count, plural, one {# work item} other {# work items}} selected',
		description: 'The description text to indicate how many issues are being auto-scheduled.',
	},
	overwriteTitleIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-title-issue-term-refresh',
		defaultMessage: 'Overwriting work item values',
		description: 'This is the title of the configuration section.',
	},
	sprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.sprint',
		defaultMessage: 'Sprints',
		description: 'This is the table column name of the configuration section for the sprint.',
	},
	release: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.release',
		defaultMessage: 'Releases',
		description: 'This is the table column name of the configuration section for the release.',
	},
	team: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.team',
		defaultMessage: 'Teams',
		description: 'This is the table column name of the configuration section for the team.',
	},
	headerDesc: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.header-desc',
		defaultMessage:
			'By default, the Auto-scheduler schedules all work items in your plan. To focus it on a few work items, select them from the timeline view.',
		description: 'This is the header description of the configuration section.',
	},
	headerDescSecond: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.header-desc-second',
		defaultMessage: 'The Auto-scheduler will overwrite existing values in the following fields:',
		description: 'This is the second paragraph description of the configuration section.',
	},
	headerDescThird: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.header-desc-third',
		defaultMessage: 'You can change which fields it edits in your plan settings.',
		description: 'This is the third paragraph description of the configuration section.',
	},
	headerDescNoFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.header-desc-no-fields',
		defaultMessage:
			'Based on your current settings, the Auto-scheduler will only add data to empty fields in your plan. You can change this in your plan settings.',
		description:
			'This text is displayed when the user does not select any overwriting fields so the auto-scheduler only write values to empty fields.',
	},
	autoSchedulerDialogHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.auto-scheduler-dialog-header',
		defaultMessage: 'Schedule your work automatically',
		description: 'The title of the auto-scheduler dialog.',
	},
	aboutAutoSchedulerButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.about-auto-scheduler-button',
		defaultMessage: 'Read more',
		description: 'The button that opens a side panel that shows up details about auto-scheduler.',
	},
	closeButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.close-button-label',
		defaultMessage: 'Close Auto-scheduler pop-up',
		description: 'The close label for auto-scheduler setting dialog.',
	},
});
