/** @jsx jsx */
import React, { useCallback } from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import { Pressable, xcss } from '@atlaskit/primitives';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const TodayButton = ({ onClick, testId, isHidden }: Props) => {
	const { formatMessage } = useIntl();

	const onClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (fg('plan_timeline_core_action_analytics')) {
				fireUIAnalytics(analyticsEvent, 'today');
			}
			onClick(e, analyticsEvent);
		},
		[onClick],
	);

	if (isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) {
		return (
			<Pressable
				testId={testId}
				aria-hidden={isHidden || undefined}
				tabIndex={isHidden ? -1 : 0}
				onClick={onClickHandler}
				aria-label={formatMessage(messages.today)}
				interactionName={
					fg('platform_button_item-add-ufo-metrics') ? 'timeline-today-clicked' : undefined
				}
				paddingBlock="space.075"
				paddingInline="space.150"
				xcss={buttonBaseStyles}
				backgroundColor="color.background.neutral.subtle"
			>
				{formatMessage(messages.today)}
			</Pressable>
		);
	}

	return (
		<Button
			testId={testId}
			appearance="subtle"
			onClick={onClickHandler}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-today-clicked' }
				: {})}
			{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
				? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
				: {})}
		>
			{formatMessage(messages.today)}
		</Button>
	);
};

export default TodayButton;

const buttonBaseStyles = xcss({
	color: 'color.text.subtle',
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	borderRadius: 'border.radius',
	':hover': {
		backgroundColor: 'color.background.neutral',
	},
	':focus': {
		// @ts-expect-error - outlineOffset currently does not support negative tokens - see https://atlassian.slack.com/archives/CFJ9DU39U/p1733863467395999
		outlineOffset: token('space.negative.025'),
	},
	':focus-visible': {
		// @ts-expect-error - outlineOffset currently does not support negative tokens - see https://atlassian.slack.com/archives/CFJ9DU39U/p1733863467395999
		outlineOffset: token('space.negative.025'),
	},
	':active': {
		// @ts-expect-error - outlineOffset currently does not support negative tokens - see https://atlassian.slack.com/archives/CFJ9DU39U/p1733863467395999
		outlineOffset: token('space.negative.025'),
	},
});
