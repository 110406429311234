import React, { forwardRef, type ReactNode, type Ref } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import FieldBase from '../common/ui/index.tsx';
import { MIN, MAX, type AggregateType } from '../types.tsx';

import messages from './messages.tsx';

type Props = {
	fieldKey: string;
	groupName: string;
	value?: number;
	type: AggregateType;
};

const getMessageKey = (fieldKey: string, type: AggregateType): MessageDescriptor => {
	switch (fieldKey) {
		case 'startDate':
			return type === MAX ? messages.latestStartDateTooltip : messages.earliestStartDateTooltip;
		case 'targetStart':
			return type === MAX ? messages.latestTargetStartTooltip : messages.earliestTargetStartTooltip;
		case 'dueDate':
			return type === MIN ? messages.earliestDueDateTooltip : messages.latestDueDateTooltip;
		case 'targetEnd':
			return type === MIN ? messages.earliestTargetEndTooltip : messages.latestTargetEndTooltip;
		default:
			// Not possible to run into this case
			return messages.earliestStartDateTooltip;
	}
};

const getDateCellDescriptor = (type: AggregateType): MessageDescriptor =>
	type === MAX ? messages.latest : messages.earliest;

const ToolTipContainer = forwardRef<HTMLElement, { children?: ReactNode }>((props, ref) => (
	<Box
		xcss={containerStylesOld}
		padding="space.100"
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		ref={ref as Ref<HTMLDivElement>}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		tabIndex={0}
	/>
));

export const DateCell = ({ value, fieldKey, type, groupName }: Props) => {
	const { formatTimestamp } = useDateFormatter();
	const { formatMessage } = useIntl();

	const messageKey = getMessageKey(fieldKey, type);
	const formattedDate =
		!expValEquals('aggregate_fields_for_plan_m2_experiment', 'cohort', 'variation') || value
			? // @ts-expect-error no overload matches this call - remove when cleaning up aggregate_fields_for_plan_m2
				formatTimestamp(value)
			: '-';
	const dateCellDescriptor = expValEquals(
		'aggregate_fields_for_plan_m2_experiment',
		'cohort',
		'variation',
	)
		? getDateCellDescriptor(type)
		: undefined;

	return expValEquals('aggregate_fields_for_plan_m2_experiment', 'cohort', 'variation') ? (
		<FieldBase
			testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.${groupName}.${fieldKey}`}
			message={dateCellDescriptor}
			messageValues={{ date: formattedDate }}
			fieldKey={fieldKey}
		/>
	) : (
		<Tooltip
			position="bottom"
			content={formatMessage(messageKey, { date: formattedDate })}
			tag={ToolTipContainer}
		>
			<Box
				as="span"
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.date-cell.${groupName}.${fieldKey}`}
				xcss={valueStyles}
			>
				{formattedDate}
			</Box>
		</Tooltip>
	);
};

const containerStylesOld = xcss({
	display: 'flex',
	flex: '1',
	cursor: 'default',
	':focus': {
		outlineColor: 'color.border.focused',
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
	},
});

const valueStyles = xcss({
	flex: '1',
	fontWeight: token('font.weight.semibold'),
});
