import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unknownTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.query.group.unknown-team',
		defaultMessage: 'Unknown team (not included in plan)',
		description:
			'Label to show for a group of issues that are assigned to a team outside of the plan',
	},
	completedSprintsGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.query.group.completed-sprints-group',
		defaultMessage: '{count} completed {count, plural, one {sprint} other {sprints}}',
		description: 'Label for completed sprints group',
	},
	completedReleasesGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.query.group.completed-releases-group',
		defaultMessage: '{count} completed {count, plural, one {release} other {releases}}',
		description: 'Label for completed releases group',
	},
});
