import React from 'react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '../messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export function ChangeIndicator({
	currentScenarioColor = token('color.text.warning'),
	appearance = 'triangle',
	borderRadius,
}: Props) {
	const intl = useIntl();

	return (
		<Tooltip
			content={intl.formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? commonMessages.changeIndicatorTooltipIssueTermRefresh
					: commonMessages.changeIndicatorTooltip,
			)}
			position="mouse"
		>
			{/* Will be removed on FG cleanup */}
			{/* eslint-disable-next-line no-nested-ternary */}
			{appearance === 'triangle' ? (
				fg('plan-timeline-a11y-rholloway') ? (
					<Box
						testId="portfolio-3-portfolio.common.change-indicator"
						xcss={triangleStyles}
						tabIndex={0}
						/* eslint-disable-next-line react/jsx-props-no-spreading -- remove with fg cleanup */
						{...(fg('chronos_a11y_fixes_jtran2')
							? { role: 'button', 'aria-label': intl.formatMessage(commonMessages.unsavedChanges) }
							: {})}
					>
						<svg
							viewBox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							width="10" // Seems to be a minimum width/height else the svg doesn't render properly in chrome
							height="10"
							style={{ borderTopRightRadius: borderRadius }}
						>
							<polygon points="0,0  100,0  100,100" style={{ fill: currentScenarioColor }} />
						</svg>
					</Box>
				) : (
					<div
						data-testid="portfolio-3-portfolio.common.change-indicator"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.triangle}
					>
						<svg
							viewBox="0 0 100 100"
							xmlns="http://www.w3.org/2000/svg"
							width="10" // Seems to be a minimum width/height else the svg doesn't render properly in chrome
							height="10"
							style={{ borderTopRightRadius: borderRadius }}
						>
							<polygon points="0,0  100,0  100,100" style={{ fill: currentScenarioColor }} />
						</svg>
					</div>
				)
			) : (
				<div
					data-testid="portfolio-3-portfolio.common.change-indicator"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles[appearance]}
					style={{
						backgroundColor: currentScenarioColor,
						borderTopLeftRadius: borderRadius,
						borderBottomLeftRadius: borderRadius,
					}}
				/>
			)}
		</Tooltip>
	);
}

const triangleStyles = xcss({
	position: 'absolute',
	display: 'flex',
	width: '10px',
	top: '0',
	right: '0',
	overflow: 'hidden',

	':focus-visible': {
		outlineOffset: 'space.025',
	},
});

export default ChangeIndicator;
