import React from 'react';
import { styled } from '@compiled/react';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { token } from '@atlaskit/tokens';
import UnlinkButton from '../common/components/unlink-button/index.tsx';

export type Props = {
	isOverlapping: boolean;
	label: string;
	// optional handler, controls visibility of the unlink button
	onUnlink?: () => void;
};

const Separator = ({ label, isOverlapping, onUnlink }: Props) => (
	<Container>
		<SeparatorContainer>
			<Line isOverlapping={isOverlapping} />
			<IconWrapper isOverlapping={isOverlapping}>
				<LinkIcon LEGACY_size="small" label={label} color={token('color.text.inverse')} />
			</IconWrapper>
			<Line isOverlapping={isOverlapping} />
		</SeparatorContainer>

		<TextContainer>{label}</TextContainer>

		{onUnlink && (
			<UnlinkButtonWrapper>
				<UnlinkButton onClick={onUnlink} />
			</UnlinkButtonWrapper>
		)}
	</Container>
);

export default Separator;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'row',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SeparatorContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: token('space.200'),
	marginRight: token('space.100'),
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	display: 'flex',
	flexGrow: 1,
	alignItems: 'center',
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '14px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '20px',
	height: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isOverlapping }: { isOverlapping?: boolean }) =>
		isOverlapping
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.icon.danger')
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.icon.subtle'),
	boxShadow: token('elevation.shadow.raised'),
	borderRadius: '50%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	height: '10px',
	borderWidth: '1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ isOverlapping }: { isOverlapping?: boolean }) =>
		isOverlapping
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.icon.danger')
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.icon.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnlinkButtonWrapper = styled.div({
	display: 'flex',
	alignContent: 'center',
	flexWrap: 'wrap',
});
