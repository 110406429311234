import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	PLUGIN_PERMISSION,
	type PluginPermission,
	INSTANCE_USER_ROLE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type {
	AgileInfoType,
	DependencySettingsInfo,
	IssueLinkTypesInfo,
	SystemInfoType,
	LoadingLimitsInfo,
} from '../../state/domain/system/types.tsx';
import type { State } from '../../state/types.tsx';
import { isConfluenceMacroProxy } from '../app/index.tsx';

export const getSystemInfo = (state: State): SystemInfoType => state.domain.system.systemInfo;

export const getAgileInfo = (state: State): AgileInfoType => state.domain.system.agileInfo;

export const getJiraHoursPerDay = (state: State): number =>
	state.domain.system.systemInfo.jiraHoursPerDay;

export const getLoadingLimitsInfo = (state: State): LoadingLimitsInfo =>
	state.domain.system.loadingLimitsInfo;

export const getDateFormat = (state: State): string =>
	state.domain.system.systemInfo.lookAndFeel.dateFormat;

export const getIssueLinkTypesInfo = (state: State): IssueLinkTypesInfo =>
	state.domain.system.issueLinkTypesInfo;

export const getDependencySettingsInfo = (state: State): DependencySettingsInfo =>
	state.domain.system.dependencySettingsInfo;

export const getFiscalMonth = (state: State): number => state.domain.system.systemInfo.fiscalMonth;

export const getPermission = createSelector(
	[getSystemInfo],
	(systemInfo: SystemInfoType): PluginPermission => systemInfo.permission,
);

// If restricted User has Jira Admin permission he should be able to commit changes
export const isNonPublishingEditor = createSelector(
	[getSystemInfo],
	(systemInfo: SystemInfoType): boolean =>
		systemInfo.permission === PLUGIN_PERMISSION.NON_PUBLISHING_EDITOR && !systemInfo.isAdmin,
);

export const getInstanceUserRole = createSelector([getSystemInfo], (systemInfo: SystemInfoType) => {
	if (systemInfo.isAdmin) {
		return INSTANCE_USER_ROLE.ADMIN;
	}
	if (systemInfo.permission === PLUGIN_PERMISSION.FULL_EDITOR) {
		return INSTANCE_USER_ROLE.EDITOR;
	}
	if (systemInfo.permission === PLUGIN_PERMISSION.VIEWER) {
		return INSTANCE_USER_ROLE.VIEWER;
	}
	if (systemInfo.permission === PLUGIN_PERMISSION.NON_PUBLISHING_EDITOR) {
		return INSTANCE_USER_ROLE.RESTRICTED_USER;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return '' as never;
});

export const isAtlasConnectInstalledSystem = (state: State): boolean =>
	state.domain.system.isAtlasConnectInstalled;

export const isAtlasConnectInstalled = createSelector(
	[isConfluenceMacroProxy, isAtlasConnectInstalledSystem],
	(isMacroProxy: boolean, isInstalled: boolean): boolean => !isMacroProxy && isInstalled,
);

export const getHasAtlasPermissions = (state: State): boolean =>
	state.domain.system.hasAtlasPermissions;

// remove optional chaining when focus_area_in_plans FG is cleaned up
export const isJiraSiteConnectedToFocus = (state: State): boolean =>
	!!state.domain.system.focusAreaContext?.isJiraSiteConnectedToFocus;
export const getHasFocusReadPermission = (state: State): boolean =>
	!!state.domain.system.focusAreaContext?.hasFocusReadPermission;
export const isFocusInstalled = (state: State): boolean =>
	!!state.domain.system.focusAreaContext?.isFocusInstalled;

export const isFocusConnectedWithReadPermissions = createSelector(
	[getHasFocusReadPermission, isJiraSiteConnectedToFocus],
	(hasFocusReadPermission: boolean, isSiteConnected: boolean): boolean =>
		hasFocusReadPermission && isSiteConnected,
);
