import { timestampAggDateToInterval } from '@atlassian/jira-polaris-domain-delivery/src/dates/index.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	createContainer,
	createHook,
	createSelector,
} from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';
import { loadIdeaByIssueId, loadDeliveryData } from './actions/index.tsx';

const initialState: State = {
	idea: undefined,
	users: {},
	statuses: {},
	meta: {
		isLoading: false,
		error: undefined,
		loaded: false,
	},

	configurationOverriden: {},
	configurationOverridenMeta: {
		isLoading: false,
		error: undefined,
		loaded: false,
	},
};

export const IdeaContainer = createContainer();

const Store = createStore({
	containedBy: IdeaContainer,
	initialState,
	actions: {
		loadIdeaByIssueId,
		loadDeliveryData,
	},
	name: 'jpd-implant-kit-container',
});

export const useIdeaActions = createActionsHook(Store);

const getIsLoading = (state: State) => state.meta.isLoading;
const getLoadError = (state: State) => state.meta.error;
const getIdeaLoaded = (state: State) => state.meta.loaded;

const getIdeaUsers = (state: State) => state.users;
const getIdeaStatues = (state: State) => state.statuses;
const getIdeaUserByAccountId = (state: State, accountId: string) => state.users[accountId];

const getIdeaIssueType = (state: State) => state.idea?.fields.issuetype;
const getIdeaIssueTypeIconUrl = createSelector(getIdeaIssueType, (issueType) => issueType?.iconUrl);

// if there is an overriden date for a field, use it instead
export const getIdeaFieldValueByKey = (
	state: State,
	{
		fieldKey,
		currentUserTimezone,
		locale,
	}: {
		fieldKey: string;
		currentUserTimezone?: TimeZone;
		locale?: Locale;
	},
) => {
	const overridenDate = state.configurationOverriden[fieldKey];
	// For now we only override a Delivery date, so a different format is needed in this case
	if (overridenDate) {
		const intervalValue = timestampAggDateToInterval(overridenDate, locale, currentUserTimezone);
		if (intervalValue) {
			return JSON.stringify(intervalValue);
		}
	}

	return state.idea?.fields[fieldKey];
};

const getIdeaKey = (state: State) => state.idea?.key;
const getIdeaId = (state: State) => state.idea?.id;
const getIdea = (state: State) => state.idea;

const getIdeaNonEmptyFieldKeys = createSelector(getIdea, (idea) => {
	if (!idea) {
		return [];
	}

	return Object.keys(idea.fields).filter((fieldKey) => {
		const fieldValue = idea.fields[fieldKey];
		return fieldValue !== null && fieldValue !== undefined;
	});
});

const getConfigurationOverridenLoaded = (state: State) => state.configurationOverridenMeta.loaded;
const getConfigurationOverridenIsLoading = (state: State) =>
	state.configurationOverridenMeta.isLoading;

export const useIdeaIsLoading = createHook(Store, {
	selector: getIsLoading,
});

export const useIdeaLoadError = createHook(Store, {
	selector: getLoadError,
});

export const useIdeaLoaded = createHook(Store, {
	selector: getIdeaLoaded,
});

export const useIdeaKey = createHook(Store, {
	selector: getIdeaKey,
});

export const useIdeaId = createHook(Store, {
	selector: getIdeaId,
});

export const useIdeaNonEmptyFieldKeys = createHook(Store, {
	selector: getIdeaNonEmptyFieldKeys,
});

export const useIdeaFieldValueByKey = createHook(Store, {
	selector: getIdeaFieldValueByKey,
});

export const useIdeaIssueTypeIconUrl = createHook(Store, {
	selector: getIdeaIssueTypeIconUrl,
});

export const useIdeaUsers = createHook(Store, {
	selector: getIdeaUsers,
});

export const useIdeaStatuses = createHook(Store, {
	selector: getIdeaStatues,
});

export const useIdeaUserByAccountId = createHook(Store, {
	selector: getIdeaUserByAccountId,
});

export const useIdeaDescription = () => useIdeaFieldValueByKey({ fieldKey: 'description' });

export const useIdeaSummary = () => useIdeaFieldValueByKey({ fieldKey: 'summary' });

export const useConfigurationOverridenLoaded = createHook(Store, {
	selector: getConfigurationOverridenLoaded,
});

export const useConfigurationOverridenIsLoading = createHook(Store, {
	selector: getConfigurationOverridenIsLoading,
});
