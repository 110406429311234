import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { IDEA_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps: MapStateToProps<StateProps> = createStructuredSelector<State, StateProps>({
	associatedIssues: (state) => state.domain.associatedIssues.associatedIssues,
	filter: createSelector(getFilters, ({ [IDEA_FILTER_ID]: { id, value } }) => ({
		id,
		value,
	})),
});

export default mapStateToProps;
