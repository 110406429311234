/** @jsx jsx */

/* eslint-disable jira/react/no-style-attribute, @atlaskit/ui-styling-standard/no-classname-prop */
import React, {
	type Ref,
	useRef,
	forwardRef,
	useEffect,
	useCallback,
	type FocusEventHandler,
} from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { CellProvider } from '../../controllers/cell/index.tsx';
import {
	useActiveDescendantId,
	useColumnStickyOffset,
	useFocusCellEffect,
	useFocusVisible,
	useGridActions,
} from '../../controllers/grid/index.tsx';
import { useCellHighlight } from '../../controllers/common/index.tsx';
import { useRow } from '../../controllers/row/index.tsx';
import type { Props } from './types.tsx';

export const Cell = forwardRef(
	(
		{ as: Tag = 'td', column, colSpan, style, top, className, ...restProps }: Props,
		externalRef: Ref<HTMLTableCellElement>,
	) => {
		const internalRef = useRef<HTMLTableCellElement>(null);
		const ref = useMergeRefs(externalRef, internalRef);
		const [left] = useColumnStickyOffset({ column, preview: true });

		let focusVisible;

		if (fg('treegrid_keyboard_navigation')) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			[focusVisible] = useFocusVisible();
		}

		const isSticky = top !== undefined || left !== undefined;

		let handleFocus: FocusEventHandler | undefined;
		let id: string | undefined;

		if (fg('treegrid_keyboard_navigation')) {
			/* eslint-disable react-hooks/rules-of-hooks */
			const { row } = useRow();
			[id] = useActiveDescendantId({ row, column });
			const { setFocusPosition } = useGridActions();
			const [effect] = useFocusCellEffect({ row, column });

			useEffect(() => {
				if (effect && internalRef.current) effect?.(internalRef.current);
			}, [effect, internalRef]);

			handleFocus = useCallback(() => {
				setFocusPosition(row, column);
			}, [row, column, setFocusPosition]);
			/* eslint-enable react-hooks/rules-of-hooks */
		}

		return (
			<CellProvider column={column} colSpan={colSpan ?? 1}>
				<Tag
					id={id}
					ref={fg('treegrid_keyboard_navigation') ? ref : externalRef}
					colSpan={colSpan}
					className={className}
					css={[cellStyles, isSticky && stickyStyles]}
					onFocus={handleFocus}
					style={{
						top,
						left,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					// This spreading is to allow the consumer to customize the tr attributes

					{...restProps}
				>
					{restProps.children}
					{focusVisible && fg('treegrid_keyboard_navigation') && <FocusHighlight />}
				</Tag>
			</CellProvider>
		);
	},
);

const FocusHighlight: React.FC = () => {
	const [highlighted] = useCellHighlight();

	switch (highlighted) {
		case true:
			return <div css={[highlightBaseStyles, highlightStartStyles, highlightEndStyles]} />;

		case 'start':
			return <div css={[highlightBaseStyles, highlightStartStyles]} />;

		case 'end':
			return <div css={[highlightBaseStyles, highlightEndStyles]} />;

		case 'middle':
			return <div css={[highlightBaseStyles]} />;

		default:
			return null;
	}
};

const cellStyles = css({
	marginTop: token('space.0'),
	marginRight: token('space.0'),
	marginBottom: token('space.0'),
	marginLeft: token('space.0'),
	paddingTop: token('space.0'),
	paddingRight: token('space.0'),
	paddingBottom: token('space.0'),
	paddingLeft: token('space.0'),
	position: 'relative',
});

const stickyStyles = css({
	position: 'sticky',
});

const highlightBaseStyles = css({
	position: 'absolute',
	inset: token('space.0'),
	borderTop: `2px solid ${token('color.border.focused')}`,
	borderBottom: `2px solid ${token('color.border.focused')}`,
	pointerEvents: 'none',
});

const highlightStartStyles = css({
	borderLeft: `2px solid ${token('color.border.focused')}`,
});

const highlightEndStyles = css({
	borderRight: `2px solid ${token('color.border.focused')}`,
});
