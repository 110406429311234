import React, { type ReactNode, type Ref, useRef, useEffect, useState } from 'react';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Box, xcss } from '@atlaskit/primitives';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import { COLUMN } from './constants.tsx';
import { ColumnDropTarget } from './drop-target/index.tsx';
import { useIsColumnDragging } from './hooks/use-is-column-dragging.tsx';

export type Props = {
	columnId: string;
	children: ReactNode;
	renderTrigger: (props: {
		ref: Ref<HTMLButtonElement>;
		isVisible: boolean;
		isHidden: boolean;
	}) => ReactNode;
	isDisabled?: boolean;
};

export const DraggableColumn = ({ columnId, children, renderTrigger, isDisabled }: Props) => {
	const [isDragging, setIsDragging] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const isColumnDragging = useIsColumnDragging();

	const triggerRef = useRef<HTMLButtonElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!containerRef.current) return;

		const container = containerRef.current;
		const trigger = triggerRef.current;

		const data = { type: COLUMN, columnId };

		return draggable({
			element: container,
			dragHandle: trigger ?? container,
			getInitialData: () => data,
			onDragStart: () => setIsDragging(true),
			onDrop: () => setIsDragging(false),
		});
	}, [columnId]);

	return (
		<HoverObserver onHoverChanged={setIsHovered}>
			<Box
				ref={containerRef}
				xcss={containerStyles}
				data-column-id={columnId}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column"
			>
				{children}
				{isColumnDragging && <ColumnDropTarget columnId={columnId} isDragging={isDragging} />}
				{renderTrigger({
					ref: triggerRef,
					isVisible: isHovered,
					isHidden: Boolean(isDragging || isDisabled),
				})}
			</Box>
		</HoverObserver>
	);
};

const containerStyles = xcss({
	width: '100%',
	height: '100%',
});
