import type { ComponentType, FC } from 'react';
import type React from 'react';
// eslint-disable-next-line jira/restricted/react-redux
import type { ConnectedComponentClass } from 'react-redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IntlShape, MessageDescriptor } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { UpdateIssuePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import type { HierarchyRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { JiraModalProps } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import type { OnOptionSelectHandler } from '../../issues/issue/types.tsx';

export const MODAL_DIALOG_WIDTH = 640;

export const SET_ASSIGNEE = 'roadmap.scope.header.bulk-actions.SET_ASSIGNEE' as const;

export const SET_ESTIMATE = 'roadmap.scope.header.bulk-actions.SET_ESTIMATE' as const;

export const SET_LABEL = 'roadmap.scope.header.bulk-actions.SET_LABEL' as const;

export const REMOVE_FROM_PLAN = 'roadmap.scope.header.bulk-actions.REMOVE_FROM_PLAN' as const;

export const SET_RELEASE = 'roadmap.scope.header.bulk-actions.SET_RELEASE' as const;

export const SET_CP_RELEASE = 'roadmap.scope.header.bulk-actions.SET_CP_RELEASE' as const;

export const SET_TEAM = 'roadmap.scope.header.bulk-actions.SET_TEAM' as const;

export const SET_DATES = 'roadmap.scope.header.bulk-actions.SET_DATES' as const;

export const SET_RANK = 'roadmap.scope.header.bulk-actions.SET_RANK' as const;

export const SET_SPRINT = 'roadmap.scope.header.bulk-actions.SET_SPRINT' as const;

export const MOVE_ISSUES = 'roadmap.scope.header.bulk-actions.MOVE_ISSUES' as const;

export const BULK_CHANGE = 'roadmap.scope.header.bulk-actions.BULK_CHANGE' as const;

export type StateProps = {
	cpVersionsForSelectedIssues: string[];
	hierarchyRange: HierarchyRange;
	projectsById: ProjectsById;
	selectedIssues: Issue[];
};

export type OwnProps = {
	isDefaultMenuOpen?: boolean;
	meatballTriggerRef?: React.RefObject<HTMLButtonElement>;
	shouldReturnFocus?: JiraModalProps['shouldReturnFocus'];
	onOptionSelect?: OnOptionSelectHandler;
	onModalOpen?: () => void;
	onModalClose?: (e?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent) => void;
	children?: (
		renderDropdown: () => React.ReactNode,
		renderDialog: () => React.ReactNode,
	) => React.ReactNode;
};

export type Props = StateProps & OwnProps;

export type BulkFormActionType =
	| typeof SET_RELEASE
	| typeof SET_CP_RELEASE
	| typeof MOVE_ISSUES
	| typeof SET_TEAM
	| typeof SET_ASSIGNEE
	| typeof SET_ESTIMATE
	| typeof SET_LABEL
	| typeof SET_SPRINT;

export type BulkActionType =
	| BulkFormActionType
	| typeof REMOVE_FROM_PLAN
	| typeof SET_RANK
	| typeof SET_DATES
	| typeof BULK_CHANGE;

export type UpdateAttributeFormProps = {
	intl: IntlShape;
	options?: OptionType[];
	updatedAttributeType: BulkFormActionType;
	currentValue?: string;
	currentIssueTypeIcon?: string;
	avatarIcon?: string;
	shouldFitContainerWidth?: boolean;
	warningMessage?: string;
	onSubmit: (
		arg1: UpdateIssuePayload,
		analyticsEvent: UIAnalyticsEvent,
		e?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
	) => void;
	onCancel: (e?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent) => void;
};

type DropOptionDialogProps<P> = P & {
	toggleModalDialog: (e?: React.MouseEvent | React.KeyboardEvent | KeyboardEvent) => void;
	UpdateAttributeForm?: ComponentType<
		Flow.Diff<
			UpdateAttributeFormProps,
			{
				intl: IntlShape;
			}
		>
	>;
	bulkActionSuccess: (analyticsEvent: UIAnalyticsEvent) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DropOptionDialog<P> = ConnectedComponentClass<FC<DropOptionDialogProps<P>>, any>;

export type DropOption = {
	label: MessageDescriptor;
	type: BulkActionType;
	disabled?: boolean;
	disabledTooltip?: MessageDescriptor;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dialog?: DropOptionDialog<any>;
	analyticsId: string;
};

export type GetDropOption = (arg1: Props) => DropOption;
