import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { IssueId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { State } from '../types.tsx';
import {
	extractFieldsForOveriddenDates,
	createTmpIds,
	extractConfigurationForFields,
	mapResponseToTheCustomField,
} from './utils.tsx';
import type { DeliveryDateResponse } from './types.tsx';

export const loadIdeaByIssueId =
	(issueId: IssueId, projectId: ProjectId) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		if (getState().meta.loaded) {
			// if already loaded, do not load again. Use refresh action instead.
			return;
		}

		setState({
			...getState(),
			meta: {
				isLoading: true,
				error: undefined,
				loaded: false,
			},
		});

		try {
			const ideaResponse = await fetchJson('/rest/polaris/issues/get', {
				// does not include field configurations/decorations etc.
				method: 'POST',
				body: JSON.stringify({
					includeAllFields: true,
					includeProjectData: true,
					issueIdOrKey: issueId,
					projectId,
				}),
			});

			setState({
				...getState(),
				idea: ideaResponse.issue,
				users: ideaResponse.users,
				statuses: ideaResponse.statuses,
				meta: {
					isLoading: false,
					error: undefined,
					loaded: true,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (!isClientFetchError(error)) {
				experience.ideaViewBentoSidebar.pageSegmentLoad.failure(error);
			}

			setState({
				...getState(),
				meta: {
					isLoading: false,
					error,
					loaded: false,
				},
			});
		}
	};

export const loadDeliveryData =
	(issueId: IssueId, projectId: ProjectId, fieldsRaw: FieldMap, fieldKeys: string[]) =>
	async ({ setState }: StoreActionApi<State>) => {
		// do it only for delivery tickets, not for Atlas!
		const fieldsForOverridenDates = extractFieldsForOveriddenDates(fieldsRaw, fieldKeys);
		const tmpIds = createTmpIds(fieldsForOverridenDates);
		const configurationForFields = extractConfigurationForFields(fieldsForOverridenDates);

		// dont do request if empty
		if (!configurationForFields.length) {
			setState({
				configurationOverridenMeta: {
					isLoading: false,
					error: undefined,
					loaded: true,
				},
			});
			return;
		}

		setState({
			configurationOverridenMeta: {
				isLoading: true,
				error: undefined,
				loaded: false,
			},
		});

		try {
			const response = await fetchJson<DeliveryDateResponse>('/rest/polaris/delivery-data/data', {
				method: 'POST',
				body: JSON.stringify({
					projectId,
					issueId,
					type: 'default',
					calculationMode: 'issue_count',
					dateFieldConfigurations: configurationForFields,
				}),
			});

			if (response?.ideaDateAggregation) {
				const currentConfig = response.ideaDateAggregation[issueId];
				setState({
					configurationOverriden: mapResponseToTheCustomField(tmpIds, currentConfig),
					configurationOverridenMeta: {
						isLoading: false,
						error: undefined,
						loaded: true,
					},
				});
			} else {
				setState({
					configurationOverridenMeta: {
						isLoading: false,
						error: undefined,
						loaded: true,
					},
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				configurationOverridenMeta: {
					isLoading: false,
					error,
					loaded: true,
				},
			});
		}
	};
