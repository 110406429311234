import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { mapDispatchToProps, mapDispatchToPropsOld } from './command.tsx';
import View from './view.tsx';
import mapStateToProps from './query.tsx';

const connectedOld = connect(null, mapDispatchToPropsOld)(View);
const connected = connect(mapStateToProps, mapDispatchToProps)(View);

export default componentWithFG('plan-timeline-a11y-meatball-menu', connected, connectedOld);
