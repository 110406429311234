import { fg } from '@atlassian/jira-feature-gating';
import {
	CustomFieldTypes,
	DEFAULT_FIELD_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

export const ADD_FIELDS_COLUMN_ID = 'addFields';

const columnIds = [
	'targetStart',
	'targetEnd',
	'assignee',
	'team',
	'issueStatus',
	'release',
	'estimate',
	'breakdown',
	'progressByEstimation',
	'sprint',
	'incomingDependencies',
	'outgoingDependencies',
	'components',
	'labels',
	'dueDate',
	'parent',
	'priority',
	'reporter',
	'goal',
	ADD_FIELDS_COLUMN_ID,
] as const;

const columnIdsWithIdeas = [...columnIds, 'ideas'] as const;

const columnIdsWithFocusAreas = [...columnIds, 'focusAreas'];
const columnIdsWithFocusAreasAndIdeas = [...columnIds, 'ideas', 'focusAreas'];

export const getColumnIds = (
	isConfluenceMacroProxyMode: boolean,
	focusConnectedWithReadPermissions: boolean,
) => {
	if (!isConfluenceMacroProxyMode && fg('polaris-arj-eap-override')) {
		if (focusConnectedWithReadPermissions && fg('focus_area_in_plans')) {
			return columnIdsWithFocusAreasAndIdeas;
		}
		return columnIdsWithIdeas;
	}
	if (focusConnectedWithReadPermissions && fg('focus_area_in_plans')) {
		return columnIdsWithFocusAreas;
	}

	return columnIds;
};

export const defaultWidthOfColumnTimeline = {
	targetStart: DEFAULT_FIELD_WIDTH.MEDIUM,
	targetEnd: DEFAULT_FIELD_WIDTH.MEDIUM,
	assignee: DEFAULT_FIELD_WIDTH.LARGE,
	team: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	issueStatus: DEFAULT_FIELD_WIDTH.MEDIUM,
	release: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	estimate: DEFAULT_FIELD_WIDTH.MEDIUM,
	breakdown: DEFAULT_FIELD_WIDTH.LARGE,
	progressByEstimation: DEFAULT_FIELD_WIDTH.LARGE,
	sprint: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	incomingDependencies: DEFAULT_FIELD_WIDTH.MEDIUM,
	outgoingDependencies: DEFAULT_FIELD_WIDTH.MEDIUM,
	components: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	ideas: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	labels: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	dueDate: DEFAULT_FIELD_WIDTH.MEDIUM,
	parent: DEFAULT_FIELD_WIDTH.LARGE,
	priority: DEFAULT_FIELD_WIDTH.MEDIUM,
	reporter: DEFAULT_FIELD_WIDTH.LARGE,
	addFields: DEFAULT_FIELD_WIDTH.MEDIUM,
	[CustomFieldTypes.TextField]: DEFAULT_FIELD_WIDTH.MEDIUM,
	[CustomFieldTypes.MultiSelect]: DEFAULT_FIELD_WIDTH.LARGE,
	[CustomFieldTypes.AssetObject]: DEFAULT_FIELD_WIDTH.LARGE,
	[CustomFieldTypes.SingleSelect]: DEFAULT_FIELD_WIDTH.LARGE,
	DEFAULT: DEFAULT_FIELD_WIDTH.MEDIUM,
} as const;

export const defaultWidthOfColumnList = {
	assignee: DEFAULT_FIELD_WIDTH.EXTRALARGE,
	targetStart: DEFAULT_FIELD_WIDTH.LARGE,
	targetEnd: DEFAULT_FIELD_WIDTH.LARGE,
	issueStatus: DEFAULT_FIELD_WIDTH.LARGE,
	dueDate: DEFAULT_FIELD_WIDTH.LARGE,
	[CustomFieldTypes.DatePicker]: DEFAULT_FIELD_WIDTH.LARGE,
	estimate: DEFAULT_FIELD_WIDTH.MEDIUM,
	priority: DEFAULT_FIELD_WIDTH.MEDIUM,
	DEFAULT: DEFAULT_FIELD_WIDTH.LARGE,
} as const;

// Restrict the ColumnId type to be a string that is inside columnIds
export type ColumnIdV0 = ReturnType<typeof getColumnIds>[number];

export function isColumnId(
	key: string,
	isConfluenceMacroProxyMode: boolean,
	focusConnectedWithReadPermissions: boolean,
): key is ColumnId {
	return (
		/* eslint-disable @typescript-eslint/consistent-type-assertions */
		(
			getColumnIds(
				isConfluenceMacroProxyMode,
				focusConnectedWithReadPermissions,
			) as readonly string[]
		).includes(key)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
	);
}

export type ColumnId = ColumnIdV0;

export type IssueColumnStateV0 = {
	id: ColumnIdV0;
	isVisible: boolean;
};

export type IssueColumnState = IssueColumnStateV0;

export type FieldColumnsStateV0 = {
	columns: IssueColumnStateV0[];
};

export type FieldColumnsState = FieldColumnsStateV0;
