import countBy from 'lodash/countBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { OwnProps, MapStateToProps, LabelValue } from './types.tsx';

const getValues = createSelector(
	[(_: State, { rawValues }: OwnProps) => rawValues],
	(rawValues: string[]): LabelValue[] => {
		const labelsCount = countBy(rawValues);
		const uniqueLabels = Object.keys(labelsCount).sort((a, b) => labelsCount[b] - labelsCount[a]);

		return uniqueLabels
			.map((label) => {
				return { label, count: labelsCount[label] };
			})
			.filter(Boolean);
	},
);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	values: getValues,
});

export default mapStateToProps;
