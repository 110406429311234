import React, { type KeyboardEvent, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { isScenarioIssue as getIsScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const RemoveIssueDialog = ({
	issueId,
	issueKey,
	projectKey,
	onClose,
	removeFromPlan,
	shouldReturnFocus,
}: Props) => {
	const { formatMessage } = useIntl();
	const isScenarioIssue = getIsScenarioIssue(issueId);

	const issueIdentifier = projectKey && issueKey ? `${projectKey}-${issueKey}` : undefined;

	const removeScenarioIssueTitle = fg('jira-issue-terminology-refresh-m3')
		? messages.removeScenarioIssueTitleIssueTermRefresh
		: messages.removeScenarioIssueTitle;
	const removeSavedIssueTitle = fg('jira-issue-terminology-refresh-m3')
		? messages.removeSavedIssueTitleIssueTermRefresh
		: messages.removeSavedIssueTitle;

	const removeScenarioIssueMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.removeScenarioIssueMessageIssueTermRefresh
		: messages.removeScenarioIssueMessage;
	const removeSavedIssueMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.removeSavedIssueMessageIssueTermRefresh
		: messages.removeSavedIssueMessage;

	return (
		<ShortcutScope>
			<ModalDialog
				messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.modal-dialog"
				messageType="transactional"
				shouldScrollInViewport
				onClose={onClose}
				{...(fg('plan-timeline-a11y-meatball-menu')
					? {
							label: formatMessage(messages.removeSavedIssueTitleIssueTermRefresh),
							shouldReturnFocus,
						}
					: { autoFocus: false })}
			>
				<ModalHeader>
					<ModalTitle appearance="danger">
						{formatMessage(isScenarioIssue ? removeScenarioIssueTitle : removeSavedIssueTitle)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text>
						<FormattedMessage
							{...(isScenarioIssue ? removeScenarioIssueMessage : removeSavedIssueMessage)}
							values={{
								issueIdentifier,
								b: (chunk) => <b>{chunk}</b>,
							}}
						/>
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={onClose}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button
						appearance="primary"
						onClick={(_: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
							removeFromPlan({ issueId });
							fireUIAnalytics(analyticsEvent, 'confirmInlineRemoveIssue', { isScenarioIssue });
							onClose();
						}}
					>
						{formatMessage(
							isScenarioIssue
								? messages.removeScenarioIssueActionButton
								: messages.removeSavedIssueActionButton,
						)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default RemoveIssueDialog;
