/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Manager, Reference } from '@atlaskit/popper';
import { JiraPopper as Popper } from '@atlassian/jira-popper/src/ui/jira-popper.tsx';
import InlineCreate from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/inline-create/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';

interface Props {
	issue: ScopeIssue;
}

export const InlineCreatePopper: React.FC<Props> = ({ issue }) => {
	const { row } = useRow();
	return (
		<>
			<Manager>
				<Reference>{({ ref }) => <div ref={ref} key={row} css={referenceStyles} />}</Reference>
				<Popper
					placement="right"
					messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.body.inline-create-popper"
					messageType="transactional"
				>
					{({ ref, style: { position, top, bottom, left, right, transform } }) => (
						<div
							ref={ref}
							style={{ position, top, bottom, left, right, transform }}
							css={popupStyles}
						>
							<InlineCreate
								fixVersions={issue.fixVersions?.map((x) => Number(x))}
								components={issue.components?.map((x) => Number(x))}
							/>
						</div>
					)}
				</Popper>
			</Manager>
		</>
	);
};

const referenceStyles = css({
	width: 0,
	height: 40,
	marginLeft: token('space.negative.100'),
});

const popupStyles = css({
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	backgroundColor: token('elevation.surface.overlay'),
	borderRadius: token('border.radius'),
	boxShadow: token('elevation.shadow.overflow'),
});
