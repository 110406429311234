import type { IssueRollups } from '../../state/domain/issues/types.tsx';

// checks whether two arrays contain the same elements in linear time, doesn't care about order
export const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
	if (arr1.length !== arr2.length) {
		return false;
	}
	const counts1: Record<string, number> = {};
	const counts2: Record<string, number> = {};

	arr1.forEach((key) => {
		if (counts1[key]) {
			counts1[key] += 1;
		} else {
			counts1[key] = 1;
		}
	});

	arr2.forEach((key) => {
		if (counts2[key]) {
			counts2[key] += 1;
		} else {
			counts2[key] = 1;
		}
	});
	for (const key in counts1) {
		if (counts1[key] !== counts2[key]) {
			return false;
		}
	}
	return true;
};

export const mergeChangesMaps = (
	existingMap: Map<string, Partial<IssueRollups>>,
	newChanges: Map<string, Partial<IssueRollups>>,
) => {
	newChanges.forEach((value, key) => {
		if (existingMap.has(key)) {
			existingMap.set(key, {
				...existingMap.get(key),
				...value,
			});
		} else {
			existingMap.set(key, value);
		}
	});
	return existingMap;
};
