import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import type { CrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/types.tsx';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	TABLE_GROUP,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import type { EnrichedVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import type { FormatMessage } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	GROUPING,
	COMPLETED_SPRINTS_GROUP,
	COMPLETED_RELEASES_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isRoadmapGroupedByCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';

const getTeamName = (id: string | null | undefined, allTeams: Team[]) => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const team = allTeams.find((team) => team.id === id);
	return team ? team.title : null;
};

// When cleaning up refactor_plan_time_group_name, getGroupName can be removed
export const getGroupName = ({
	item,
	allTeams,
	sprints,
	formatMessage,
}: {
	item: TableItem;
	allTeams: Team[];
	sprints: Sprint[];
	formatMessage: FormatMessage;
}) => {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	let { value: groupName } = item;
	const { grouping, group, childGroups } = item;

	if (group === UNDEFINED_GROUP) {
		groupName = formatMessage(commonMessages.unassigned);
	} else if (!groupName) {
		switch (grouping) {
			case GROUPING.COMPONENT: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}
			case GROUPING.LABEL: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}

			case GROUPING.GOALS: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}

			case GROUPING.IDEAS: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}

			case GROUPING.TEAM: {
				const teamId = get(item, ['groupCombination', 'team']);
				const teamName = getTeamName(teamId, allTeams);
				groupName = !isNil(teamName) ? teamName : formatMessage(messages.unknownTeam);
				break;
			}
			case GROUPING.RELEASE: {
				if (item.groupCombination.release === COMPLETED_RELEASES_GROUP) {
					groupName = formatMessage(messages.completedReleasesGroup, {
						count: childGroups ? childGroups.length : '',
					});
				}
				break;
			}
			case GROUPING.PROJECT: {
				groupName = formatMessage(commonMessages.unassigned);
				break;
			}
			case isRoadmapGroupedByCustomField(grouping) && grouping: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}
			default: {
				groupName = formatMessage(commonMessages.unassigned);
				break;
			}
		}
	}
	if (grouping === GROUPING.SPRINT) {
		if (item.groupCombination.sprint === COMPLETED_SPRINTS_GROUP) {
			groupName = formatMessage(messages.completedSprintsGroup, {
				count: childGroups
					? Object.keys(
							groupBy(
								childGroups,
								// eslint-disable-next-line @typescript-eslint/no-shadow
								(group) => (group.groupCombination && group.groupCombination.sprint) || '',
							),
						).length
					: '',
			});
		} else {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const sprint = sprints.find((sprint) => {
				const sprintId = get(item, ['groupCombination', 'sprint']);
				return sprint.id === sprintId;
			});
			groupName = sprint && sprint.title ? sprint.title : formatMessage(commonMessages.unassigned);
		}
	}
	return groupName;
};

export const getSecondaryGroupName = (
	item: TableItem,
	enrichedVersionsById: EnrichedVersionsById,
	allTeams: Team[],
) => {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	let secondaryGroupName;
	const { grouping } = item;
	if (grouping === GROUPING.RELEASE) {
		const versionId = get(item, ['groupCombination', 'release']);
		const release = !isNil(versionId) ? enrichedVersionsById[versionId] : undefined;
		secondaryGroupName =
			release &&
			(release.projects
				.filter(Boolean)
				.map((project: number | Project) => (typeof project !== 'number' ? project.name : null))
				.join(', ') ||
				null);
	}
	if (grouping === GROUPING.SPRINT && item.groupCombination.sprint !== COMPLETED_SPRINTS_GROUP) {
		secondaryGroupName = getTeamName(item.groupCombination.team, allTeams);
	}
	return secondaryGroupName;
};

export const getLozengeTextForGroupByRelease = (
	item: TableItem,
	enrichedVersionsById: EnrichedVersionsById,
	crossProjectVersionsById: CrossProjectVersionsById,
) => {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	const versionId = get(item, ['groupCombination', 'release']);
	const release = !isNil(versionId) ? enrichedVersionsById[versionId] : undefined;
	return (
		release?.crossProjectVersion &&
		get(crossProjectVersionsById, [release.crossProjectVersion, 'name'])
	);
};
