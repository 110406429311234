/** @jsx jsx */
import React, { useRef } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsDragging } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import { Z_INDEX_LEVEL } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { useZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { Cell } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell-compiled/index.tsx';
import { useColspan } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { useColspan as useColspanOld } from '../colspan/index.tsx';
import { useColumnCollapsed } from '../column-collapse/utils/state.tsx';
import { DragIndicator } from '../drag-and-drop/drag-indicator/index.tsx';
import { DropIndicator } from '../drag-and-drop/drop-indicator/index.tsx';
import { ColumnDragHandle } from '../draggable-column/drag-handle/index.tsx';
import { ColumnDropIndicator } from '../draggable-column/drop-indicator/index.tsx';
import { DraggableColumn } from '../draggable-column/index.tsx';
import type { Props } from './types.tsx';
import { useFocusWithin } from './utils.tsx';

export const EnhancedCell = ({
	hasBorderTop = false,
	hasBorderBottom = false,
	hasDragIndicator = false,
	hasDropIndicator = false,
	hasBackground = true,
	clipped = true,
	isDraggableColumn = false,
	hasColumnDropIndicator = false,
	columnText,
	resizeHandle,
	columnId,
	children,
	rowType = 'body',
	hasRowSpan = false,
	testId,
	...restProps
}: Props) => {
	const { column } = restProps;

	const cellRef = useRef<HTMLTableCellElement>(null);
	const isFocusWithin = useFocusWithin(cellRef);
	const isDragging = useIsDragging();
	const zIndex = useZIndex({
		rowType,
		column,
		level: (() => {
			if (hasRowSpan) {
				return Z_INDEX_LEVEL.STICKY;
			}

			if (isFocusWithin) {
				return Z_INDEX_LEVEL.FOCUSED;
			}

			if (rowType === 'body') {
				return Z_INDEX_LEVEL.DEFAULT;
			}

			return undefined;
		})(),
	});

	const [collapsed] = useColumnCollapsed({ isHeader: rowType === 'header', column });

	const [getColspan] = useColspan();
	const [colSpan] = fg('plan_timeline_colspan')
		? [
				getColspan({
					/* Rows other than 0 (header) share the same strategy on colspan */
					row: rowType === 'header' ? 0 : 1,
					column,
				}),
			]
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useColspanOld({
				row: rowType ?? 'body',
				column,
			});
	if (colSpan === 0) {
		return null;
	}

	const rowSpan = collapsed && hasRowSpan ? 9999 : 1;

	if (collapsed && !hasRowSpan && rowType !== 'header' && rowType !== 'subheader') {
		// do not render cell if behind the collapsed fields subheader columns
		return null;
	}

	const contents = (
		<div
			css={[
				wrapperStyles,
				hasBorderTop && borderTopStyles,
				hasBorderBottom && borderBottomStyles,
				collapsed && collapsedStyles,
				clipped && clippedStyles,
				!hasBackground && stripBackgroundStyles,
				focusStyles,
			]}
		>
			{!collapsed && children}
		</div>
	);

	const inner = (
		<>
			{isDraggableColumn && columnId && fg('plan_timeline_drag_and_drop_field_columns') ? (
				<DraggableColumn
					columnId={columnId}
					renderTrigger={({ ref, ...props }) => (
						<ColumnDragHandle ref={ref} columnId={columnId} {...props} />
					)}
					isDisabled={collapsed}
				>
					{contents}
				</DraggableColumn>
			) : (
				contents
			)}
			{collapsed && columnText}
			{resizeHandle}
			{hasColumnDropIndicator && fg('plan_timeline_drag_and_drop_field_columns') && (
				<ColumnDropIndicator columnId={columnId} />
			)}
			{isDragging && hasDragIndicator && <DragIndicator />}
			{isDragging && hasDropIndicator && <DropIndicator />}
		</>
	);

	return (
		<Cell
			{...restProps}
			colSpan={colSpan}
			{...(rowSpan !== 1 ? { rowSpan } : {})}
			css={cellStyles}
			style={{ zIndex }}
			ref={cellRef}
			data-testid={testId}
		>
			{inner}
		</Cell>
	);
};

const cellStyles = css({
	backgroundColor: token('elevation.surface'),
	verticalAlign:
		'top' /* Some content (e.g. group header) is hard-coded 39px height and meant to be aligned at top. */,
});

const wrapperStyles = css({
	position: 'absolute',
	inset: token('space.0'),
	borderRight: `1px solid ${token('color.background.accent.gray.subtler')}`,
	background: 'var(--plan-grid-row-background)',
	overflow: 'initial',
	transition: 'background-color 0.1s ease',
});

const borderTopStyles = css({
	borderTop: `1px solid ${token('color.background.accent.gray.subtler')}`,
});

const borderBottomStyles = css({
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler')}`,
});

const collapsedStyles = css({
	background: 'unset',
});

const clippedStyles = css({
	overflow: 'hidden',
});

const focusStyles = css({
	/*
	 * The `overflow: hidden` need to be reset when focusing on cells, such as Status Fields,
	 * Otherwise, the menu which opened when editing the cells would be clipped.
	 */
	'&:focus-within': {
		overflow: 'initial',
	},
});

const stripBackgroundStyles = css({
	'--plan-grid-row-background': token('elevation.surface'),
	'&:hover': {
		'--plan-grid-row-background': token('elevation.surface'),
	},
});
