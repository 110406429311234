import React, { Component, type KeyboardEvent, type MouseEvent } from 'react';
import DeleteIcon from '@atlaskit/icon/core/migration/delete--trash';
import ChevronRightLargeIcon from '@atlaskit/icon/utility/migration/chevron-right--chevron-right-large';
import FolderIcon from '@atlaskit/icon/core/migration/folder-closed--folder';
import ChevronDownLargeIcon from '@atlaskit/icon/utility/migration/chevron-down--hipchat-chevron-down';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import InlineEdit from '@atlassian/jira-portfolio-3-common/src/inline-edit/index.tsx';
import { ALL_OTHER_ISSUES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/component-groups/types.tsx';
import { injectIntlWithDefaultProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/inject-intl-with-default-props/index.tsx';
import { DEFAULT_COLOR } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { DefaultProps, Props } from './types.tsx';

const COMPONENT_GROUP_TEMPORARY_ID = 'jpo_group_temp_id';

// eslint-disable-next-line jira/react/no-class-components
class Group extends Component<Props> {
	static defaultProps: DefaultProps = {
		groupId: COMPONENT_GROUP_TEMPORARY_ID,
		groupName: '',
		isExpanded: false,
		shouldCreateGroup: false,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onDelete: () => {},
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onToggleExpanded: () => {},
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onTrashButtonClick = (e: KeyboardEvent<any> | MouseEvent<any>) => {
		e.preventDefault();

		const { groupId, onDelete } = this.props;

		onDelete(groupId);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChevronDownClick = (e: MouseEvent<any>) => {
		e.preventDefault();

		const { groupId, onToggleExpanded } = this.props;
		onToggleExpanded(groupId, false);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChevronRightClick = (e: MouseEvent<any>) => {
		e.preventDefault();

		const { groupId, onToggleExpanded } = this.props;
		onToggleExpanded(groupId, true);
	};

	isAllOtherIssuesGroup = (groupId: string) => groupId === ALL_OTHER_ISSUES;

	validateGroupTitle = (title: string) => {
		if (title.length < 256) {
			return;
		}
		return this.props.intl.formatMessage(messages.tooLongInputErrorMessage);
	};

	getHeaderMessage = () => {
		if (fg('jira-issue-terminology-refresh-m3')) {
			return <FormattedMessage {...commonMessages.allOtherIssuesIssueTermRefresh} />;
		}
		return <FormattedMessage {...commonMessages.allOtherIssues} />;
	};

	render() {
		const { intl, groupId, groupName, isExpanded, shouldCreateGroup, onConfirm, onCancel } =
			this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.group}>
				{!(this.isAllOtherIssuesGroup(groupId) || shouldCreateGroup) && (
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.expandCollapseChevron}
						data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.expand-group-wrapper"
					>
						{isExpanded ? (
							<IconButton onClick={this.onChevronDownClick}>
								<ChevronDownLargeIcon
									spacing="spacious"
									label={intl.formatMessage(messages.collapseButtonLabel)}
								/>
							</IconButton>
						) : (
							<IconButton onClick={this.onChevronRightClick}>
								<ChevronRightLargeIcon
									spacing="spacious"
									label={intl.formatMessage(messages.expandButtonLabel)}
								/>
							</IconButton>
						)}
					</div>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.icon}>
					<FolderIcon spacing="spacious" label="" />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.groupTitleSection}>
					{this.isAllOtherIssuesGroup(groupId) ? (
						this.getHeaderMessage()
					) : (
						<>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.groupTitle}>
								<InlineEdit
									defaultValue={groupName}
									editView={({ errorMessage, ...fieldProps }) => (
										<InlineDialog
											content={errorMessage}
											isOpen={fieldProps.isInvalid}
											placement="right"
										>
											<AkTextField
												{...fieldProps}
												autoFocus={shouldCreateGroup}
												elemAfterInput={
													fieldProps &&
													fieldProps.isInvalid && (
														<WarningIcon
															spacing="spacious"
															label=""
															color={token('color.icon.warning')}
														/>
													)
												}
												aria-label={intl.formatMessage(messages.groupPlaceholder)}
												maxLength={256}
												placeholder={intl.formatMessage(messages.groupPlaceholder)}
												testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.group-name-input"
											/>
										</InlineDialog>
									)}
									onCancel={onCancel}
									onConfirm={onConfirm}
									readView={() => (
										<Tooltip content={groupName}>
											{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
											<div className={styles.groupTitleReadViewContainer}>
												<span>{groupName}</span>
											</div>
										</Tooltip>
									)}
									startWithEditViewOpen={shouldCreateGroup}
									validate={this.validateGroupTitle}
								/>
							</div>
							{!shouldCreateGroup && (
								<div
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={styles.trashButton}
									data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.delete-group-button"
								>
									<Button appearance="subtle" onClick={this.onTrashButtonClick}>
										<DeleteIcon
											label={intl.formatMessage(messages.deleteButtonLabel)}
											LEGACY_size="small"
											LEGACY_primaryColor={DEFAULT_COLOR}
											color={token('color.icon.subtle')}
										/>
									</Button>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

export default injectIntlWithDefaultProps(Group);
