import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	numberOfTeams: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.team-cell.number-of-teams',
		defaultMessage: '{count} <qualifier>{count, plural, one {Team} other {Teams}}</qualifier>',
		description:
			'Text to show the count of the total number of teams associated with a group of issues.',
	},
	unassigned: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.team-cell.unassigned',
		defaultMessage: 'Unassigned',
		description: 'Label for the count of the issues that have teams unassigned.',
	},
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.team-cell.aria-label',
		defaultMessage:
			'{count} {count, plural, one {team} other {teams}} for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Priority field in the group header row',
	},
});
