import React, { useState, useMemo, useCallback } from 'react';
import { Box, xcss, Inline, Pressable } from '@atlaskit/primitives';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import Image from '@atlaskit/image';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import FlyoutContent from '../common/ui/flyout-content/index.tsx';
import FieldBase from '../common/ui/index.tsx';
import commonMessages from '../common/ui/messages.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export const PriorityCell = ({
	priorities,
	groupName,
	fieldKey,
	value,
	groupByLabel,
	swimlane,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { formatMessage } = useIntl();

	const label = useMemo(
		() =>
			fg('jira-issue-terminology-refresh-m3')
				? commonMessages.issueCountLabelIssueTermRefresh
				: commonMessages.issueCountLabel,
		[],
	);

	const content = useMemo(
		() =>
			priorities.map(({ count, name, iconUrl }) => ({
				title: (
					<Inline space="space.050" alignBlock="center">
						<Box as="span" xcss={iconWrapperStyles}>
							<Image src={iconUrl} alt={name} />
						</Box>
						{name}
					</Inline>
				),
				count,
			})),
		[priorities],
	);

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const renderContent = useCallback(
		() => (
			<FlyoutContent
				fieldName={commonMessages.priorityTitle}
				label={label}
				content={content}
				total={value.length}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.priority-flyout"
				ariaLabel={formatMessage(messages.ariaLabel, {
					count: content.length,
					priorityName: priorities[0].name,
					groupByLabel,
					swimlane,
				})}
				titleWidth={100}
			/>
		),
		[value, content, label, groupByLabel, swimlane, formatMessage, priorities],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			if (priorities.length === 0) {
				return null;
			}
			return (
				<FieldBase
					component={Pressable}
					onClick={onToggle}
					triggerProps={triggerProps}
					tabIndex={0}
					testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.priority-cell.${groupName}.${fieldKey}`}
					message={messages.priorities}
					messageValues={{
						count: priorities[0].count,
						priorityName: priorities[0].name,
					}}
					fieldKey={fieldKey}
				/>
			);
		},
		[onToggle, groupName, fieldKey, priorities],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default PriorityCell;

const iconWrapperStyles = xcss({
	width: '16px',
	height: '16px',
});
