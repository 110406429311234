import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	numberOfIdeas: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.ideas-cell.number-of-ideas',
		defaultMessage: '{count} <qualifier>{count, plural, one {Idea} other {Ideas}}</qualifier>',
		description:
			'Text to show the count of the total number of ideas associated with a group of issues.',
	},
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.ideas-cell.aria-label',
		defaultMessage:
			'{count} {count, plural, one {idea} other {ideas}} for {groupByLabel} {swimlane}',
		description: 'Aria label shown on the Ideas field in the group header row',
	},
});
