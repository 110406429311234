import groupBy from 'lodash/groupBy';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getDependencyIssueLinkTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import {
	getIssueMapById,
	type IssueMap,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/external-issues/index.tsx';
import type { ExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/external-issues/types.tsx';
import {
	type ProjectsById,
	getProjectsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import {
	getIssueTypesById,
	type IssueTypesById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { DependencyValue, DependencyIssue, ParamsProps } from '../types.tsx';
import { getLinks } from '../query.tsx';

import type { StateProps } from './types.tsx';

const getMissingIssueIds = createSelector(
	[getLinks, getIssueMapById, getExternalIssues],
	(links, issuesById, externalIssues) => {
		const issueKeys = links.flatMap(({ sourceItemKey, targetItemKey }) => [
			sourceItemKey,
			targetItemKey,
		]);

		return issueKeys.filter((issueKey) => {
			return !issuesById[issueKey] && !externalIssues[issueKey];
		});
	},
);

const getDependencyIssue = (
	issueKey: string,
	issuesById: IssueMap,
	externalIssues: ExternalIssues,
	projectsById: ProjectsById,
	issueTypesById: IssueTypesById,
): DependencyIssue | undefined => {
	const issue = issuesById[issueKey];
	if (issue) {
		const project = projectsById[issue.project];
		const issueType = issueTypesById[issue.type];
		return {
			id: issue.id,
			issueKey: issue.issueKey,
			summary: issue.summary,
			projectKey: project.key,
			typeIconUrl: issueType.iconUrl,
		};
	}

	const externalIssue = externalIssues[issueKey];
	if (externalIssue) {
		return {
			id: String(externalIssue.id),
			issueKey: Number(externalIssue.issueKey),
			summary: externalIssue.summary,
			projectKey: externalIssue.projectKey,
			typeIconUrl: externalIssue.typeIconUrl,
		};
	}

	return undefined;
};

const sortLinksByCount = (links: IssueLink[], isIncoming: boolean) => {
	const groupedLinks = groupBy(links, ({ sourceItemKey, targetItemKey }) =>
		isIncoming ? sourceItemKey : targetItemKey,
	);

	const sortedKeys = Object.keys(groupedLinks).sort(
		(a, b) => groupedLinks[b].length - groupedLinks[a].length,
	);

	return sortedKeys.flatMap((key) => groupedLinks[key]);
};

const getDependencies = createSelector(
	[
		getLinks,
		getDependencyIssueLinkTypesById,
		getIssueMapById,
		getExternalIssues,
		getProjectsById,
		getIssueTypesById,
		getMissingIssueIds,
		(_: State, { isIncoming }: ParamsProps) => isIncoming,
	],
	(
		links,
		issueLinkTypesById,
		issuesById,
		externalIssues,
		projectsById,
		issueTypesById,
		missingIssueIds,
		isIncoming,
	): DependencyValue[] => {
		if (missingIssueIds.length > 0) return [];

		const sortedLinks = sortLinksByCount(links, isIncoming);

		return sortedLinks.map((link) => {
			const { sourceItemKey, targetItemKey, type } = link;

			const issueKey = isIncoming ? targetItemKey : sourceItemKey;
			const otherIssueKey = isIncoming ? sourceItemKey : targetItemKey;

			const issue = getDependencyIssue(
				issueKey,
				issuesById,
				externalIssues,
				projectsById,
				issueTypesById,
			);
			const otherIssue = getDependencyIssue(
				otherIssueKey,
				issuesById,
				externalIssues,
				projectsById,
				issueTypesById,
			);
			const linkType = issueLinkTypesById[type];

			return {
				issue,
				otherIssue,
				linkType,
			};
		});
	},
);

const mapStateToProps = createStructuredSelector<State, ParamsProps, StateProps>({
	dependencies: getDependencies,
	missingIssueIds: getMissingIssueIds,
});

export default mapStateToProps;
