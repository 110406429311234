import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	managePortfolio: proxyContextSafeUrl('/jira/plans'),
	administration: proxyContextSafeUrl('/secure/PortfolioPermissions.jspa'),
	jiraHome: proxyContextSafeUrl('/jira/dashboards/last-visited'),
	manageCustomFieldsOld: proxyContextSafeUrl('/secure/admin/ViewCustomFields.jspa'),
	manageCustomFields: proxyContextSafeUrl('/jira/settings/issues/fields'),
};
