import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	meatballTriggerLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.meatball-trigger-label',
		defaultMessage: 'More actions for {issueKey}',
		description:
			'Aria label for the more actions (meatball button) attached to each issue in the plan timeline.',
	},
});
export default messages;
