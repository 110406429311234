import React, { type ReactNode } from 'react';
import { Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	MODAL,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCtpOnboardingTour } from '../../../controllers/ctp-onboarding/index.tsx';
import { CtpSpotlights } from '../../../controllers/ctp-spotlights/index.tsx';
import { messages } from './messages.tsx';

export const CreateIssueSpotlight = () => {
	const { formatMessage } = useIntl();
	const [, { nextCtpStage }] = useCtpOnboardingTour();

	return (
		<ContextualAnalyticsData sourceName="crossTeamPlanningCreateIssueSpotlight" sourceType={MODAL}>
			<JiraSpotlight
				actionsBeforeElement="2/4"
				actions={[
					{
						text: formatMessage(messages.next),
						onClick: () => nextCtpStage(),
					},
				]}
				heading={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.headingIssueTermRefresh
						: messages.heading,
				)}
				target={CtpSpotlights.CreateIssue}
				key={CtpSpotlights.CreateIssue}
				targetBgColor={token('elevation.surface')}
				targetRadius={3}
				dialogPlacement="bottom left"
				dialogWidth={350}
				messageId="portfolio-3-onboarding.ui.ctp-onboarding.create-issue-spotlight.jira-spotlight"
				messageType="engagement"
			>
				<Stack space="space.100">
					<Text as="p">
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.bodyFirstIssueTermRefresh
								: messages.bodyFirst)}
						/>
					</Text>

					<Text as="p">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.bodyUnsavedChangesIssueTermRefresh
								: messages.bodyUnsavedChanges,
							{
								b: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
							},
						)}
					</Text>

					<Text as="p">
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.bodyThirdIssueTermRefresh
								: messages.bodyThird)}
						/>
					</Text>
				</Stack>
			</JiraSpotlight>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
