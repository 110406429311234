/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { type Option, UNGROUPED } from '../types.tsx';
import { optionSortComparator } from '../utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const TeamOptionsProvider = ({
	additionalTeamsById,
	children,
	groups,
	teams,
	teamsById,
}: Props) => {
	const { formatMessage } = useIntl();

	const isTeamDisabled = (teamId: string) => !isNil(additionalTeamsById[teamId]);

	const getElemAfterLabel = (teamId: string) => {
		if (teamId && fg('plans_managed_teams_indicator_timeline_enabled')) {
			const team = teamsById[teamId] || additionalTeamsById[teamId];
			if (!team || !team.verified) return null;

			return (
				<span css={verifiedTeamIconWrapperStyles}>
					<VerifiedTeamIcon showTooltip />
				</span>
			);
		}
		return null;
	};

	const createOptionFromGroup = (group: GroupOption): Option<GroupCombination> => ({
		id: group.group,
		name: group.groupName,
		value: { team: group.groupCombination?.team },
		url: group.groupUrl || '',
		urlType: 'avatar',
		isDisabled: !isNil(group.groupCombination?.team) && isTeamDisabled(group.groupCombination.team),
		disabledTooltip: formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.teamTooltipIssueTermRefresh
				: messages.teamTooltip,
		),
		elemAfterLabel: getElemAfterLabel(group.groupCombination?.team || ''),
	});

	const createOptionFromTeam = (team: Team): Option<GroupCombination> => ({
		id: `team-${team.id}`,
		name: team.title,
		value: { team: team.id },
		url: team.avatarUrl || '',
		urlType: 'avatar',
		isDisabled: isTeamDisabled(team.id),
		disabledTooltip: formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.teamTooltipIssueTermRefresh
				: messages.teamTooltip,
		),
		elemAfterLabel: getElemAfterLabel(team.id),
	});

	const getOptionsFromTeams = (): Option<GroupCombination>[] => {
		const unassigned: Option<GroupCombination> = {
			name: formatMessage(commonMessages.unassigned),
			id: UNDEFINED_GROUP,
			url: '',
			urlType: 'avatar',
			value: { team: null },
		};

		const teamGroups = new Set();
		const options = [];

		groups.forEach((group) => {
			if (isNil(group.groupCombination) || isNil(group.groupCombination.team)) return;

			teamGroups.add(group.groupCombination.team);

			const option = createOptionFromGroup(group);

			options.push(option);
		});

		teams.forEach((team) => {
			if (teamGroups.has(team.id)) return;

			const option = createOptionFromTeam(team);

			options.push(option);
		});

		options.push(unassigned);

		return options.sort(optionSortComparator);
	};

	return children({
		options: {
			type: UNGROUPED,
			options: getOptionsFromTeams(),
		},
		menuTitle: formatMessage(messages.teamMenuTitle),
		searchPlaceholder: formatMessage(messages.teamSearchPlaceholder),
	});
};

export default TeamOptionsProvider;

const verifiedTeamIconWrapperStyles = css({
	display: 'inline-flex',
	verticalAlign: 'middle',
});
