/* eslint-disable jira/react/no-style-attribute */
import React, { useRef, useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useRowConfig,
	useActiveDescendantId,
	useFocusRowEffect,
} from '../../controllers/grid/index.tsx';
import { RowProvider } from '../../controllers/row/index.tsx';
import type { Props } from './types.tsx';

export const Row = ({ index, height, style, ...restProps }: Props) => {
	const ref = useRef<HTMLTableRowElement>(null);
	const [rowConfig] = useRowConfig(index);
	const finalHeight = height ?? rowConfig?.height ?? 0;

	let id: string | undefined;

	if (fg('treegrid_keyboard_navigation')) {
		/* eslint-disable react-hooks/rules-of-hooks */
		[id] = useActiveDescendantId({ row: index, column: -1 });
		const [effect] = useFocusRowEffect({ row: index });

		useEffect(() => {
			if (effect && ref.current) effect?.(ref.current);
		}, [effect, ref]);
	}

	let ariaRowIndex: number | undefined;

	if (fg('treegrid_keyboard_navigation')) {
		// index can be -1 if the row is non-representational row (such as placeholder one)
		ariaRowIndex = index >= 0 ? index + 1 : undefined;
	}

	return (
		<RowProvider row={index} height={finalHeight}>
			<tr
				id={id}
				ref={ref}
				aria-rowindex={ariaRowIndex}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ height: finalHeight, ...style }}
				// This spreading is to allow the consumer to customize the tr attributes
				{...restProps}
			/>
		</RowProvider>
	);
};
