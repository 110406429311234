/** @jsx jsx */
import React, { type ReactNode, useState, useCallback, useRef, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import EditorLinkIcon from '@atlaskit/icon/core/migration/link--editor-link';
import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import {
	useIsScrollingY,
	useIsScrollingX,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	type IssueLinkDirectionType,
	LINES,
	COUNT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';

import {
	useDependencySettings,
	useDependencyLines,
} from '@atlassian/jira-portfolio-3-dependency-lines/src/controllers/index.tsx';
import type { IssueMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/types.tsx';
import type { ExternalIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/external-issues/types.tsx';

import type {
	IssueLink,
	IssueStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

import DependencyLinkFlyoutOld from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout-old/dialog-content/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import EntryPointPopup from '@atlassian/jira-portfolio-3-common/src/entry-point-popup/index.tsx';
import { dependenciesFlyoutEntryPoint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout/entrypoint.tsx';
import DependenciesSkeleton from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout/skeleton.tsx';
import {
	isDependencyOverlapping,
	isExternalDependencyOverlapping,
} from '../../dependency-lines/layer/utils.tsx';
import MarkerBadge from './marker-badge/index.tsx';
import type { MarkerBadgeAppearance } from './marker-badge/types.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';
import { getDefaultIssueLinkTypeId, getDependencyLabel } from './utils.tsx';

const BADGE_WIDTH = 16;
const MINIMUM_BAR_FREE_SPACE = 2; // Dedicate 2px for space between badge and end of bar
const BADGE_MAX = 99;

const getBadgeColouring = (
	barColor: string,
	barColors:
		| undefined
		| Array<{
				colour: string;
				ratio: number;
		  }>,
	badgeAppearance: MarkerBadgeAppearance,
) => {
	// default icon bg is grey
	let badgeColor = barColor || token('color.icon.subtle');

	// only apply bar color if we have a single bar color value... we're not handling rainbows for labels & components
	if (barColors && barColors.length === 1) {
		badgeColor = barColors[0].colour;
	} else if (barColors && barColors.length > 1) {
		badgeColor = 'transparent';
	}

	let textColor: string = token('color.text.inverse');
	// always red bg if off track
	if (badgeAppearance === 'offtrack') {
		badgeColor = token('color.background.danger');
		textColor = token('color.text.danger');
	}

	return { badgeColor, textColor };
};

export const getBadgeAppearance = (
	externalIssues: ExternalIssues,
	issue: ScopeIssue,
	issuesWithOptimizedById: IssueMap,
	issueStatusById: {
		[id: string]: IssueStatus;
	},
	isOptimizedMode: boolean,
	startOrEnd: string | 'end' | 'start',
	syncStartEnabled: boolean,
	internalIssueLinks: Array<IssueLink>,
	externalIssueLinks: Array<IssueLink>,
) => {
	let badgeAppearance: MarkerBadgeAppearance = 'none';
	if (internalIssueLinks.length + externalIssueLinks.length) {
		badgeAppearance =
			isDependencyOverlapping(
				startOrEnd,
				issue,
				internalIssueLinks,
				issuesWithOptimizedById,
				issueStatusById,
				isOptimizedMode,
				syncStartEnabled,
			) ||
			isExternalDependencyOverlapping(
				startOrEnd,
				issue,
				issueStatusById,
				externalIssueLinks,
				externalIssues,
				issuesWithOptimizedById,
				isOptimizedMode,
				syncStartEnabled,
			)
				? 'offtrack'
				: 'default';
	}
	return badgeAppearance;
};

const getLinkCount = (
	startOrEnd: 'end' | 'start' | string,
	internalIncomingLinks: undefined | Array<IssueLink>,
	externalIncomingLinks: undefined | Array<IssueLink>,
	internalOutgoingLinks: undefined | Array<IssueLink>,
	externalOutgoingLinks: undefined | Array<IssueLink>,
) => {
	let linkCount = 0;
	if (startOrEnd === 'start') {
		linkCount += internalIncomingLinks ? internalIncomingLinks.length : 0;
		linkCount += externalIncomingLinks ? externalIncomingLinks.length : 0;
	} else {
		linkCount += internalOutgoingLinks ? internalOutgoingLinks.length : 0;
		linkCount += externalOutgoingLinks ? externalOutgoingLinks.length : 0;
	}
	return linkCount;
};

const getBarWideEnough = (
	hasHiddenIncomingDependency: boolean,
	hasHiddenOutgoingDependency: boolean,
	barWidth: number,
) => {
	// Ensuring that the bar has minimum width to fit the necessary badges
	let barWideEnoughForVisibleBadge = true;
	if (
		hasHiddenIncomingDependency &&
		hasHiddenOutgoingDependency &&
		barWidth < (BADGE_WIDTH + MINIMUM_BAR_FREE_SPACE) * 2
	) {
		barWideEnoughForVisibleBadge = false;
	} else if (
		(hasHiddenIncomingDependency || hasHiddenOutgoingDependency) &&
		barWidth < BADGE_WIDTH + MINIMUM_BAR_FREE_SPACE
	) {
		barWideEnoughForVisibleBadge = false;
	}
	return barWideEnoughForVisibleBadge;
};

export default function DependencyMarkers({
	startOrEnd,
	datePosition,
	width,
	issue,
	externalIssues,
	externalOutgoingLinks,
	externalIncomingLinks,
	internalOutgoingLinks,
	internalIncomingLinks,
	direction,
	issuesWithOptimizedById,
	issueStatusById,
	isHovered,
	isEditMode,
	isExportMode,
	isOptimizedMode,
	syncStartEnabled,
	issueCountByLinkType,
	issueLinkTypes,
	barColors,
	barColor,
}: Props) {
	const { formatMessage } = useIntl();
	const markerBadgeRef = useRef<HTMLDivElement | null | undefined>();
	const [isScrollingY] = useIsScrollingY();
	const [isScrollingX] = useIsScrollingX();
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [popupPayload, setPopupPayload] = useState<{
		issue: ScopeIssue;
		direction: IssueLinkDirectionType;
	} | null>(null);
	const [dependencyLines] = useDependencyLines();
	const [{ display }] = useDependencySettings();
	const externalIssueLinks = externalOutgoingLinks || externalIncomingLinks || [];
	const internalIssueLinks = internalOutgoingLinks || internalIncomingLinks || [];
	const addIconShowing = internalIssueLinks.length + externalIssueLinks.length === 0;
	const canAddDependency = isEditMode;
	const defaultIssueLinkType = getDefaultIssueLinkTypeId(issueLinkTypes);

	const onDependencyMarkerClick = useCallback(
		(payload: { issue: ScopeIssue; direction: IssueLinkDirectionType }) => {
			if (!isPopupOpen) {
				setPopupPayload(payload);
			} else {
				setPopupPayload(null);
			}
			setIsPopupOpen(!isPopupOpen);
		},
		[setPopupPayload, isPopupOpen, setIsPopupOpen],
	);

	const onPopupClose = useCallback(() => {
		setIsPopupOpen(false);
		setPopupPayload(null);
		// when the popup closed, the focus should go back to the marker badge
		if (isDefined(markerBadgeRef?.current?.focus)) {
			markerBadgeRef?.current?.focus();
		}
	}, [setIsPopupOpen, setPopupPayload]);

	useEffect(() => {
		if (isPopupOpen && (isScrollingY || isScrollingX)) {
			setIsPopupOpen(false);
			setPopupPayload(null);
		}
	}, [isPopupOpen, isScrollingY, isScrollingX, setIsPopupOpen, setPopupPayload]);

	// If we don't have any dependency links, the add badge will show
	// If we are in READONLY mode, it should not show (so we can't add dependencies)
	// Therefore return null
	if (addIconShowing && !canAddDependency) {
		return null;
	}

	const badgeAppearance: MarkerBadgeAppearance = getBadgeAppearance(
		externalIssues,
		issue,
		issuesWithOptimizedById,
		issueStatusById,
		isOptimizedMode,
		startOrEnd,
		syncStartEnabled,
		internalIssueLinks,
		externalIssueLinks,
	);

	const linkCount = getLinkCount(
		startOrEnd,
		internalIncomingLinks,
		externalIncomingLinks,
		internalOutgoingLinks,
		externalOutgoingLinks,
	);

	const isLinked = linkCount > 0;

	// the number of drawable outgoing lines is less than the number of outgoing links
	const hasHiddenOutgoingDependency =
		dependencyLines !== null &&
		dependencyLines.filter(({ fromIssue, drawable }) => fromIssue.id === issue.id && drawable)
			.length < linkCount;

	// the number of drawable incoming lines is less than the number of incoming links
	const hasHiddenIncomingDependency =
		dependencyLines !== null &&
		dependencyLines.filter(({ toIssue, drawable }) => toIssue.id === issue.id && drawable).length <
			linkCount;

	const { leftPositionPercentage, rightPositionPercentage } = datePosition;

	const rightPositionPercentageFromLeftPerspective = rightPositionPercentage * -1 + 100;

	const barWidth =
		(Math.abs(rightPositionPercentageFromLeftPerspective - leftPositionPercentage) * width) / 100;

	const barWideEnoughForVisibleBadge = getBarWideEnough(
		hasHiddenIncomingDependency,
		hasHiddenOutgoingDependency,
		barWidth,
	);

	const hasHiddenDependency =
		(startOrEnd === 'start' && hasHiddenIncomingDependency) ||
		(startOrEnd === 'end' && hasHiddenOutgoingDependency);

	/** The visibility status of the marker when it's inactive (not hovered), it's visible when
	 * - It has any external link (link to the issue which is not on the plan) or
	 * - It has any hidden dependency (i.e. in LINES mode, line is not drawable) or
	 * - The dependency setting is not LINES.
	 */
	const visibleWhenInactive =
		isLinked &&
		barWideEnoughForVisibleBadge &&
		(externalIssueLinks.length > 0 || hasHiddenDependency || display !== LINES);

	const { badgeColor, textColor } = getBadgeColouring(barColor, barColors, badgeAppearance);

	const badgeContent = ((): ReactNode => {
		if (!isLinked) {
			return <EditorAddIcon label={formatMessage(messages.editDependency)} LEGACY_size="small" />;
		}

		if (display === COUNT) {
			return (
				<span
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles['badge-count']}
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.dependency-marker.badge-count"
				>
					{linkCount > BADGE_MAX ? `${BADGE_MAX}+` : linkCount}
				</span>
			);
		}

		return (
			<EditorLinkIcon label={getDependencyLabel(startOrEnd, formatMessage)} LEGACY_size="small" />
		);
	})();

	if (fg('plan_timeline_dependencies_field_improvements')) {
		return (
			<EntryPointPopup
				entryPoint={dependenciesFlyoutEntryPoint}
				entryPointParams={{}}
				id="dependencies-flyout"
				packageName="jira-portfolio-3-portfolio"
				teamName="jira-portfolio"
				messageType="transactional"
				messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.dependency-marker"
				isOpen={isPopupOpen}
				onClose={onPopupClose}
				content={({ Component: DependencyFlyout, ...contentProps }) => {
					return (
						popupPayload !== null &&
						isDefined(markerBadgeRef?.current) && (
							<DependencyFlyout
								{...popupPayload}
								context="badge"
								updatePopup={contentProps.update}
								onClose={contentProps.onClose}
							/>
						)
					);
				}}
				trigger={({ ref }) => {
					return (
						<MarkerBadge
							containerWidth={width}
							direction={direction}
							issue={issue}
							issueBarPosition={datePosition}
							appearance={badgeAppearance}
							position={startOrEnd}
							visibleWhenInactive={visibleWhenInactive}
							active={isHovered}
							badgeColor={badgeColor}
							textColor={textColor}
							issueCountByLinkType={issueCountByLinkType}
							iconType={!isLinked ? 'add-icon' : 'link-icon'}
							issueLinkType={defaultIssueLinkType}
							isEditMode={isEditMode}
							isExportMode={isExportMode}
							onBadgeClick={onDependencyMarkerClick}
							triggerRef={ref}
							markerBadgeRef={markerBadgeRef}
						>
							{badgeContent}
						</MarkerBadge>
					);
				}}
				fallback={() => <DependenciesSkeleton issueCount={linkCount} />}
			/>
		);
	}

	return (
		<Popup
			role="dialog"
			placement="bottom-start"
			strategy="absolute"
			isOpen={isPopupOpen}
			onClose={onPopupClose}
			content={() => {
				return (
					popupPayload !== null &&
					isDefined(markerBadgeRef?.current) && (
						<div css={flyoutWrapStyles}>
							<DependencyLinkFlyoutOld {...popupPayload} />
						</div>
					)
				);
			}}
			trigger={({ ref }) => {
				return (
					<MarkerBadge
						containerWidth={width}
						direction={direction}
						issue={issue}
						issueBarPosition={datePosition}
						appearance={badgeAppearance}
						position={startOrEnd}
						visibleWhenInactive={visibleWhenInactive}
						active={isHovered}
						badgeColor={badgeColor}
						textColor={textColor}
						issueCountByLinkType={issueCountByLinkType}
						iconType={!isLinked ? 'add-icon' : 'link-icon'}
						issueLinkType={defaultIssueLinkType}
						isEditMode={isEditMode}
						isExportMode={isExportMode}
						onBadgeClick={onDependencyMarkerClick}
						triggerRef={ref}
						markerBadgeRef={markerBadgeRef}
					>
						{badgeContent}
					</MarkerBadge>
				);
			}}
		/>
	);
}

const flyoutWrapStyles = css({
	maxWidth: '800px',
	paddingBlock: token('space.300'),
	paddingInline: token('space.200'),
});
