import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';

export const isVisibleField = (fieldType: string) => {
	switch (fieldType) {
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
		case FIELD_TYPES.INTERVAL:
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.SHORT_TEXT:
		case FIELD_TYPES.HYPERLINK:
		case FIELD_TYPES.REACTIONS:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.STATUS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return true;
		default:
			return false;
	}
};

export const isDisabledField = (fieldType: string) => {
	switch (fieldType) {
		case FIELD_TYPES.REACTIONS:
		case FIELD_TYPES.FORMULA:
			return true;
		default:
			return false;
	}
};
