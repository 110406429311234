import keyBy from 'lodash/keyBy';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '../../state/types.tsx';

export const getFocusAreas = (state: State) => state.domain.focusAreas;

export const getFocusAreasById = createSelector([getFocusAreas], (focusAreas) =>
	keyBy(focusAreas, 'id'),
);

export const getFocusAreasIdList = createSelector([getFocusAreas], (focusAreas) => {
	const focusAreaIdList = focusAreas.map(({ id }) => id);
	return focusAreaIdList.length ? focusAreaIdList : null;
});
