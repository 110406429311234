import without from 'lodash/without';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldValueDecorations } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	FIELD_TYPES,
	JIRA_API_FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import type { JiraApiFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type {
	FieldMap,
	FieldKey,
	Field,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraField } from '../../../../services/types.tsx';
import type { FetchResponse } from '../../types.tsx';
import { getFieldType } from '../../utils.tsx';
import { getFormulaData } from './formulas/index.tsx';
import {
	getArchivedOption,
	getFieldConfiguration,
	getOptions,
	getPlay,
	getPresentationType,
	isEditable,
	transformDecorations,
} from './utils.tsx';

type TransformOptions = {
	overrideAllowList?: JiraApiFieldType[];

	/**
	 * Filtering global system fields out from the response
	 * only for Roadmaps until it supports them or they are completely migrated so Roadmaps can use them.
	 *
	 * all fields filters out all global system fields
	 * Atlas fields only filters out the Atlas fields (Goals, Atlas project, Atlas project status)
	 */
	filterOutAllGlobalSystemFields?: boolean;
	filterOutAtlasGlobalSystemFields?: boolean;
};

const HIDDEN_FIELD_TYPES: JiraApiFieldType[] = [JIRA_API_FIELD_TYPES.PROJECT];

export const transformResponse = (
	fields: JiraField[],
	issueTypesPerFieldKeys: Record<FieldKey, IssueTypeId[]>,
	transformOptions: TransformOptions = {},
): FetchResponse => {
	const resolvedFieldMap: FieldMap = {};
	const resolvedFieldValueDecorations: FieldValueDecorations = {};

	// Jira always returns these fields, we don't want them unless explicitly requested by the consumer!
	const denyList = without(
		[
			'com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf',
			'com.pyxis.greenhopper.jira:gh-lexo-rank',
			'comment',
			'issuelinks',
			'priority',
			'issuerestriction',
			'attachment',
			'resolution',
			'fixVersions',
			'timetracking',
			'environment',
			'duedate',
			...(fg('jpd-aurora-ignore-parent-field') ? ['parent'] : []),
			...(fg('jpd-aurora-roadmap-inline-edit') || fg('jpd_cross_project_connecting')
				? []
				: [JIRA_API_FIELD_TYPES.PROJECT]),
			...(fg('jpd_issues_relationships') ? [] : [JIRA_API_FIELD_TYPES.CONNECTION]),
		],
		...(fg('jpd-aurora-roadmap-inline-edit') ? [] : transformOptions.overrideAllowList || []),
	);

	const hiddenFields = without(HIDDEN_FIELD_TYPES, ...(transformOptions.overrideAllowList ?? []));

	const filteredFilters = fields.filter(
		({ key, type }) => !denyList.includes(key) && !denyList.includes(type),
	);

	/*
        TODO: Refactor to issuekey
        These fields need to be remapped because their key and type in polaris does not match the type in the Jira api,
        but we can't easily adapt to the new type, because many parts of the code rely on the old type.
     */
	const remapList: { [key: string]: JiraApiFieldType } = {
		issuekey: JIRA_API_FIELD_TYPES.ISSUE_KEY,
	};
	const remappedFields = filteredFilters.map((field) =>
		remapList[field.key]
			? {
					...field,
					originalKey: field.key,
					key: remapList[field.key],
					type: remapList[field.key],
				}
			: field,
	);

	const formulaData = getFormulaData(remappedFields);

	remappedFields.forEach((field) => {
		const { key, name: label } = field;
		const originalKey = 'originalKey' in field ? field.originalKey : key;

		const type = getFieldType(field);
		const editable = isEditable(field);
		const presentation = getPresentationType(field);
		const options = getOptions(field);
		const description = field.description || '';
		const emoji = field.configuration?.emoji ?? undefined;
		const configuration = getFieldConfiguration(field, hiddenFields);
		const archivedOption = getArchivedOption(field);
		const hasRestrictedContext = field.hasRestrictedContext;
		const issueTypes = issueTypesPerFieldKeys[originalKey];
		const play = getPlay(field);
		const global = field.global || false;
		const custom = field.custom || false;
		const stableId = field.stableId;

		const mappedField: Field = {
			key,
			type,
			options,
			label,
			editable,
			presentation,
			description,
			emoji,
			configuration,
			archivedOption,
			play,
			global,
			custom,
			issueTypes,
			hasRestrictedContext,
			stableId,
			formula: formulaData.formulas[key] || undefined,
			...(formulaData.cyclicDependencies[key] ? { hasCyclicFormulaDependency: true } : {}),
		};

		if (!shouldFilterFieldOut(mappedField, transformOptions)) {
			resolvedFieldMap[key] = mappedField;
			resolvedFieldValueDecorations[key] = transformDecorations(field);
		}
	});

	return {
		fields: resolvedFieldMap,
		fieldValueDecorations: resolvedFieldValueDecorations,
	};
};

const shouldFilterFieldOut = (field: Field, transformOptions: TransformOptions): boolean => {
	// If it's not global system field, then do not filter out
	if (!isGlobalSystemField(field)) return false;

	// if it's a global system field and we want to filter them out, then filter it out
	if (transformOptions.filterOutAllGlobalSystemFields) {
		return true;
	}

	// otherwise, we might want to filter out only atlas fields
	if (transformOptions.filterOutAtlasGlobalSystemFields) {
		return [
			FIELD_TYPES.PLATFORM_GOALS,
			FIELD_TYPES.ATLAS_PROJECT,
			FIELD_TYPES.ATLAS_PROJECT_STATUS,
		].some((type) => type === field.type);
	}

	// otherwise filter nothing out.
	return false;
};
