import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { TableItem } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import type { GroupDescriptor } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/group/types.tsx';
import type { MessageDescriptor } from '@atlassian/jira-intl';

export const MIN = 'MIN' as const;
export const MAX = 'MAX' as const;
export const SUM = 'SUM' as const;
export const MAP = 'MAP' as const;
export const CONCAT = 'CONCAT' as const;

export type AggregateType = typeof MIN | typeof MAX | typeof SUM | typeof MAP | typeof CONCAT;

export type OwnPropsOld = {
	groupName: string;
	id: string;
};

export type OwnProps = {
	groupName: string;
	id: string;
	item: TableItem;
};

export type Breakdown<T> = {
	values: T[];
	unassignedCount: number;
};

export type IssueFieldTypes = {
	startDate: number | undefined | null;
	dueDate: number | undefined | null;
	targetStart: number | undefined | null;
	targetEnd: number | undefined | null;
	storyPoints: Issue['storyPoints'];
	timeEstimate: Issue['timeEstimate'];
	status: Issue['status'];
	assignee: Issue['assignee'];
	breakdown: Issue['status'];
	team: Issue['team'];
	priority: Issue['priority'];
	progressByEstimation: Issue[];
	goals: Issue['goals'];
	labels: Issue['labels'];
	fixVersions: Issue['fixVersions'];
	ideas: Issue['associatedIssueIds'];
	incomingDependencies: Issue['id'];
	outgoingDependencies: Issue['id'];
};

export type AggregateFieldTypes = {
	startDate: number | undefined;
	dueDate: number | undefined;
	targetStart: number | undefined;
	targetEnd: number | undefined;
	storyPoints: number | undefined;
	timeEstimate: number | undefined;
	status: string[];
	assignee: string[];
	breakdown: string[];
	team: Breakdown<string>;
	priority: string[];
	progressByEstimation: Issue[];
	goals: Breakdown<string>;
	labels: Breakdown<string>;
	fixVersions: Breakdown<string>;
	ideas: Breakdown<string>;
	incomingDependencies: string[];
	outgoingDependencies: string[];
};

export type FieldKey = keyof AggregateFieldTypes;

export type NumberFieldKey =
	| 'dueDate'
	| 'targetStart'
	| 'targetEnd'
	| 'storyPoints'
	| 'timeEstimate';

export type StringFieldKey =
	| 'status'
	| 'assignee'
	| 'priority'
	| 'incomingDependencies'
	| 'outgoingDependencies';

export type ArrayFieldKey = 'goals' | 'labels' | 'fixVersions' | 'ideas';

export type AggregateValue<K extends FieldKey> = {
	fieldKey: K;
	fieldValue: AggregateFieldTypes[K];
	type: AggregateType;
};

export type AggregateValuesMap = {
	[K in FieldKey]: AggregateValue<K>;
};

export type AggregateField = AggregateValuesMap[FieldKey];

export const CUSTOM: 'CUSTOM' = 'CUSTOM' as const;
export const STANDARD: 'STANDARD' = 'STANDARD' as const;

export type GroupByMesage =
	| {
			type: typeof CUSTOM;
			message: string;
	  }
	| {
			type: typeof STANDARD;
			message: MessageDescriptor;
	  };

export type StatePropsOld = {
	// Aggregated field of the group
	field: AggregateField | undefined;
};

export type StateProps = {
	// Aggregated field of the group
	field: AggregateField | undefined;
	// total issues in the group
	totalIssues: number;
	groupDescriptor: GroupDescriptor;
	groupByLabel: GroupByMesage;
};

export type MapStateToPropsOld = (state: State, ownProps: OwnPropsOld) => StatePropsOld;
export type MapStateToProps = (state: State, ownProps: OwnProps) => StateProps;

export type PropsOld = StatePropsOld & OwnPropsOld;
export type Props = StateProps & OwnProps;
