import React, { useEffect, useState } from 'react';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { monitorForDependencyCreate } from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import {
	useViewport,
	useScrollX,
	useScrollY,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/index.tsx';
import { TimelineAligned } from '../../body/timeline/aligned/index.tsx';

const SCROLL_PADDING = 2e6;

export const DependencyDragPreview: React.FC = () => {
	const [viewport] = useViewport();
	const [scrollX] = useScrollX();
	const [scrollY] = useScrollY();
	const [columnWidths] = useColumnWidths({ preview: false });
	const [{ zoomLevel }] = useHorizontalScrolling();

	const [active, drag] = useState<{ row: number; position: 'start' | 'end' }>();
	const [anchor, setAnchor] = useState<{
		horizontalScrollingContainerX: number;
		horizontalScrollingContainerY: number;
	}>();

	const [mouseOffset, setMouseOffset] = useState<{ pageX: number; pageY: number }>();

	// mouse cursor
	// container position
	// timeline aligned
	useEffect(
		() =>
			monitorForDependencyCreate({
				onGenerateDragPreview: ({ nativeSetDragImage }) => {
					// We will have a custom DOM UI for drag preview.
					disableNativeDragPreview({ nativeSetDragImage });

					// Cancels the animation when dropping on wrong targets
					preventUnhandled.start();
				},

				onDragStart: ({ source }) => {
					try {
						const { row, position } = source.data;

						if (typeof row !== 'number') {
							throw new TypeError();
						}

						if (position !== 'start' && position !== 'end') {
							throw new TypeError('position is not "start" or "end"');
						}

						drag({ row, position });
					} catch {
						// No need to handle errors
					}
				},

				onDrop: () => {
					drag(undefined);
					setMouseOffset(undefined);
					setAnchor(undefined);
				},

				onDrag: ({ location, source }) => {
					// Non type-safe data accessing
					try {
						const { horizontalScrollingContainerX, row } = source.data;

						if (typeof horizontalScrollingContainerX !== 'number' || typeof row !== 'number') {
							throw new TypeError();
						}

						const horizontalScrollingContainerY =
							row * 40 + 10 /* First row is 50 */ + 20; /* middle point vertically */

						setAnchor({
							horizontalScrollingContainerX,
							horizontalScrollingContainerY,
						});
					} catch {
						// No need to handle errors
					}

					setMouseOffset({
						pageX: location.current.input.pageX,
						pageY: location.current.input.pageY,
					});
				},
			}),
		[drag, viewport.left, viewport.top],
	);

	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	const target: typeof anchor = active &&
		mouseOffset && {
			horizontalScrollingContainerY: mouseOffset.pageY - viewport.top + scrollY,
			horizontalScrollingContainerX:
				zoomLevel !== undefined || !fg('plans-fix-dependency-dnd-fixed-mode')
					? mouseOffset.pageX - viewport.left - marginLeft + scrollX - SCROLL_PADDING
					: mouseOffset.pageX - viewport.left - marginLeft,
		};

	return (
		<ZIndex layer={Z_INDEX_LAYER.DEPENDENCY_LINE_DRAG_PREVIEW}>
			{(zIndex) => (
				<Box xcss={containerStyles} style={{ zIndex }}>
					<TimelineAligned offset={SCROLL_PADDING}>
						<Box xcss={layerAlignedStyles} style={{ marginLeft }}>
							{anchor && target && (
								<Box
									xcss={markerStyles}
									style={{
										top: (target.horizontalScrollingContainerY ?? 0) - 8,
										left: (target.horizontalScrollingContainerX ?? 0) - 8,
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
										backgroundColor: token('color.border.bold'),
									}}
								>
									<EditorAddIcon label="" LEGACY_size="small" />
								</Box>
							)}
							<svg
								data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.dependency-drag-preview.svg"
								// Since the overflow is set to be visible, so the width & height has no effect,
								// but it still needs to be > 0 to tell browsers that this svg matters
								width={80}
								height={80}
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								style={{ overflow: 'visible' }}
							>
								{anchor && target && (
									<line
										x1={anchor.horizontalScrollingContainerX}
										y1={anchor.horizontalScrollingContainerY}
										x2={target.horizontalScrollingContainerX}
										y2={target.horizontalScrollingContainerY}
										stroke={token('color.border.bold')}
										strokeWidth="2px"
										strokeLinecap="round"
									/>
								)}
							</svg>
						</Box>
					</TimelineAligned>
				</Box>
			)}
		</ZIndex>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	pointerEvents: 'none',
});

const layerAlignedStyles = xcss({
	position: 'relative',
});

const markerStyles = xcss({
	position: 'absolute',
	display: 'flex',
	width: 'size.100',
	height: 'size.100',
	borderRadius: 'border.radius.circle',
	color: 'color.text.inverse',
});
