/** @jsx jsx */
import React, { useCallback, useMemo, type ChangeEvent } from 'react';
import { jsx } from '@atlaskit/css';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';

import { SettingsItem } from '../settings-item/index.tsx';
import type { Props as SettingsItemProps } from '../settings-item/types.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

export const RollupsSettings = ({
	rollupSettings: { showingRolledUpDate, showRolledUpOthers },
	setShowingRolledUpDates,
	setShowRolledUpOthers,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleRolledUpDatesChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
			const updatedValue = !showingRolledUpDate;

			const [actionSubject, action, actionSubjectId] =
				PRODUCT_ANALYTICS_EVENT_NAMES.ROLL_UP_DATES_CHANGED.split(' ');

			fireUIAnalytics(analyticsEvent.update({ actionSubject, action }), actionSubjectId, {
				newPlanSettingValue: updatedValue,
			});

			setShowingRolledUpDates(updatedValue);
		},
		[showingRolledUpDate, setShowingRolledUpDates],
	);

	const handleRolledUpOthersChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
			const updatedValue = !showRolledUpOthers;

			const [actionSubject, action, actionSubjectId] =
				PRODUCT_ANALYTICS_EVENT_NAMES.ROLL_UP_OTHERS_CHANGED.split(' ');

			fireUIAnalytics(analyticsEvent.update({ actionSubject, action }), actionSubjectId, {
				newPlanSettingValue: updatedValue,
			});

			setShowRolledUpOthers(updatedValue);
		},
		[showRolledUpOthers, setShowRolledUpOthers],
	);

	const testIdPrefix =
		'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollups-settings';

	const settings: SettingsItemProps['settings'] = useMemo(
		() => [
			{
				isChecked: showingRolledUpDate,
				testId: `${testIdPrefix}.rollup-dates`,
				descriptionId: `${testIdPrefix}.rollup-dates.description`,
				title: formatMessage(messages.titleDates),
				description: formatMessage(messages.descriptionDates),
				toggleLabel: formatMessage(messages.toggleLabelDates),
				onChange: handleRolledUpDatesChange,
			},
			{
				isChecked: showRolledUpOthers,
				title: formatMessage(messages.titleOthers),
				testId: `${testIdPrefix}.rollup-others`,
				descriptionId: `${testIdPrefix}.rollup-others.description`,
				description: formatMessage(messages.descriptionOthers),
				toggleLabel: formatMessage(messages.toggleLabelOthers),
				onChange: handleRolledUpOthersChange,
			},
		],
		[
			showRolledUpOthers,
			showingRolledUpDate,
			handleRolledUpDatesChange,
			handleRolledUpOthersChange,
			formatMessage,
		],
	);

	return (
		<SettingsItem
			hasHeadingSeparator
			heading={formatMessage(messages.heading)}
			settings={settings}
		/>
	);
};
