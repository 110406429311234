import React, { Component, type MouseEvent } from 'react';
import flow from 'lodash/flow';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import UnlinkIcon from '@atlaskit/icon/core/migration/link-broken--unlink';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { ANALYTICS_DELETED_ISSUE_DEPENDENCY_LINK } from '../../../../../constants.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class UnlinkButton extends Component<Props> {
	onClick = (event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		event.stopPropagation();

		this.props.onClick();

		const [actionSubject, action] = ANALYTICS_DELETED_ISSUE_DEPENDENCY_LINK.split(' ');

		const analyticsPayload = {
			source: 'dependencyLineDetail',
		};

		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), analyticsPayload);
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;

		return (
			<Tooltip content={formatMessage(messages.unlink)}>
				<Button
					testId="portfolio-3-dependency-line-detail.common.ui.content.common.components.unlink-button.unlink-button"
					iconBefore={
						<UnlinkIcon
							label={formatMessage(messages.unlink)}
							LEGACY_size="medium"
							spacing="spacious"
						/>
					}
					spacing="compact"
					appearance="subtle"
					onClick={this.onClick}
				/>
			</Tooltip>
		);
	}
}

export default flow(
	injectIntl,
	AnalyticsEventToProps('button', {
		onClick: 'clicked',
	}),
)(UnlinkButton);
