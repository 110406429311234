import tabbable from 'tabbable';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from './types.tsx';
import {
	exitTreegrid,
	findNextFocusable,
	getHorizontalSkipValue,
	getVerticalSkipValue,
} from './utils.tsx';
import {
	getSelectColspan as createGetColspan,
	getSelectRowspan as createGetRowspan,
} from './selectors.tsx';

export const actions = {
	resize:
		(col: number, delta: number, commit = false): Action<State> =>
		({ setState, getState }) => {
			const { widths, onWidthsChange, resizer } = getState();

			if (!resizer) {
				return;
			}

			const preview = resizer({ column: col, delta })(widths);

			if (commit) {
				onWidthsChange(preview);
			}

			setState({ preview: commit ? undefined : preview });
		},

	setWidths:
		(next: number[]): Action<State> =>
		({ setState }) =>
			setState({ widths: next }),

	setFocusVisible:
		(visible: boolean): Action<State> =>
		({ setState, getState }) => {
			const { focus } = getState();

			if (focus.visible !== visible) {
				setState({ focus: { ...focus, visible } });
			}
		},

	setFocusPosition:
		(row: number, column: number): Action<State> =>
		({ getState, setState }) => {
			const { focus } = getState();

			if (focus.row !== row || focus.column !== column) {
				setState({ focus: { ...focus, row, column } });
			}
		},

	navigate:
		(
			e: KeyboardEvent,
			direction: 'forward' | 'backward',
			axis: 'horizontal' | 'vertical' | 'auto',
			step: 'single' | 'multiple' | 'all' = 'single',
		): Action<State> =>
		({ getState, setState }) => {
			const state = getState();
			const { focus, rows, columns, getSelectColspan, getSelectRowspan } = state;

			if (!focus.visible) {
				return;
			}

			/** Returns a valid column/row number if input is outside of min and max. */
			const within = (min: number, max: number) => (input: number) =>
				Math.max(min, Math.min(input, max));

			const finalAxis = (() => {
				if (axis !== 'auto') return axis;
				if (focus.column === -1) return 'vertical';
				if (e.ctrlKey) return 'vertical';

				return 'horizontal';
			})();

			const update =
				(value: (curr: number) => number) =>
				(curr: State['focus']): State['focus'] => {
					switch (finalAxis) {
						case 'vertical':
							return {
								...curr,
								row: within(0, rows.length - 1)(value(focus.row)),
							};

						case 'horizontal':
							return {
								...curr,
								column: within(-1, columns.length - 1)(value(focus.column)),
							};

						default:
							return curr;
					}
				};

			const setFocus = (updateFn: (curr: State['focus']) => State['focus']) => {
				e.preventDefault();
				setState({ focus: updateFn(focus) });
			};

			let horizontalSkip: number | undefined = 0;
			let verticalSkip: number | undefined = 0;

			const shouldSkipUsingColspan = () =>
				getSelectColspan &&
				focus.column !== -1 &&
				(step === 'single' || step === 'all') &&
				finalAxis === 'horizontal' &&
				fg('plan_timeline_colspan');

			const shouldSkipUsingRowspan = () =>
				getSelectRowspan &&
				focus.column !== -1 &&
				(step === 'single' || step === 'multiple') &&
				finalAxis === 'vertical' &&
				fg('plan_timeline_colspan');

			if (shouldSkipUsingColspan()) {
				const getColspan = createGetColspan(state);
				horizontalSkip = getHorizontalSkipValue(
					step,
					direction,
					focus.row,
					focus.column,
					getColspan,
					columns.length,
				);
			} else if (shouldSkipUsingRowspan()) {
				const getRowspan = createGetRowspan(state);
				verticalSkip = getVerticalSkipValue(
					step,
					direction,
					focus.row,
					focus.column,
					getRowspan,
					rows.length,
				);
			}

			if (direction === 'forward') {
				if (verticalSkip === undefined || horizontalSkip === undefined) {
					/* when moving forward on a cell with a rowspan
					 * and the next cell doesn't exist, stay on the same cell
					 */
					e.preventDefault();
					return;
				}
				if (step === 'single') {
					setFocus(update((x) => verticalSkip + horizontalSkip + x + 1));
				}
				if (step === 'multiple') {
					// horizontalSkip isn't required since shouldSkipUsingColspan doesn't run
					setFocus(update((x) => verticalSkip + x + 5));
				}
				if (step === 'all') {
					setFocus(
						update(
							() =>
								verticalSkip +
								horizontalSkip +
								(finalAxis === 'vertical' ? rows.length : columns.length) -
								1,
						),
					);
				}
			}

			if (direction === 'backward') {
				if (step === 'single') {
					setFocus(update((x) => x - 1 - (horizontalSkip ?? 0) - (verticalSkip ?? 0)));
				}
				if (step === 'multiple') {
					// horizontalSkip/verticalSkip isn't required since shouldSkipUsingColspan/shouldSkipUsingRowspan doesn't run
					setFocus(update((x) => x - 5));
				}
				if (step === 'all') {
					// horizontalSkip/verticalSkip isn't required since shouldSkipUsingColspan/shouldSkipUsingRowspan doesn't run
					setFocus(update(() => 0));
				}
			}
		},

	enter:
		(e: KeyboardEvent): Action<State> =>
		({ getState, dispatch }) => {
			const { focus } = getState();

			// Only enter the cell if the focus is on the cell (or the grid with aria-activedescendant)
			if (!(e.target instanceof HTMLTableElement || e.target instanceof HTMLTableCellElement)) {
				return;
			}

			if (focus.column === -1) {
				return;
			}

			e.preventDefault();

			const cellEffect = (el: HTMLTableCellElement) => {
				const tabbableEls = tabbable(el);

				if (tabbableEls.length > 0) {
					tabbableEls[0].focus();
				}
			};

			dispatch(registerCellEffect(cellEffect));
		},

	escape:
		(e: KeyboardEvent): Action<State> =>
		({ dispatch }) => {
			e.preventDefault();

			dispatch(
				registerGridEffect((el) => {
					el.focus();
				}),
			);

			// On Safari, once the user interacts with the mouse, subsequent keyboard interactions may not trigger :focus-visible.
			dispatch(actions.setFocusVisible(true)); // Manually override focus-visible.
		},

	tab:
		(e: KeyboardEvent, direction: 'forward' | 'backward' = 'forward'): Action<State> =>
		({ getState, dispatch }) => {
			const { focus } = getState();

			// When the focus is on the cell or the row
			if (focus.visible) {
				e.preventDefault();

				if (focus.column === -1) {
					const rowEffect = (rowEl: HTMLTableRowElement) => {
						const nextFocusable = findNextFocusable(e.shiftKey ? 'backward' : 'forward', rowEl);

						nextFocusable?.focus();

						if (!nextFocusable) {
							const tableEl = rowEl.closest('table');
							tableEl && exitTreegrid(direction, tableEl);
						}
					};

					dispatch(registerRowEffect(rowEffect));
				} else {
					const cellEffect = (cellEl: HTMLTableCellElement) => {
						const rowEl = cellEl.closest('tr');

						if (!rowEl) {
							return;
						}

						const nextFocusable = findNextFocusable(
							e.shiftKey ? 'backward' : 'forward',
							rowEl,
							cellEl,
						);

						if (!nextFocusable) {
							const tableEl = rowEl.closest('table');
							tableEl && exitTreegrid(direction, tableEl);
						}

						nextFocusable?.focus();
					};

					dispatch(registerCellEffect(cellEffect));
				}

				return;
			}

			// When the focus is on the cell elements, cycle the focus within the row
			if (!(e.target instanceof HTMLElement)) {
				return;
			}

			const rowEl = e.target.closest('tr');

			if (!rowEl) {
				return;
			}

			const tabbableEls = tabbable(rowEl);
			if (tabbableEls.length === 0) {
				// TODO Get out of the treegrid
				return;
			}

			const isLastTabbable =
				tabbableEls[tabbableEls.length - 1] === globalThis.document?.activeElement;
			const isFirstTabbable = tabbableEls[0] === globalThis.document?.activeElement;

			if (
				(isLastTabbable && direction === 'forward') ||
				(isFirstTabbable && direction === 'backward')
			) {
				const tableEl = rowEl.closest('table');
				if (tableEl && exitTreegrid(direction, tableEl)) {
					e.preventDefault();
				}
			}
		},
};

export const registerCellEffect =
	(effect: State['focus']['cellEffect']): Action<State> =>
	({ getState, setState }) => {
		const wrappedEffect: typeof effect = (el) => {
			effect?.(el);
			setState({ focus: { ...getState().focus, cellEffect: undefined } });
		};
		setState({ focus: { ...getState().focus, cellEffect: wrappedEffect } });
	};

export const registerRowEffect =
	(effect: State['focus']['rowEffect']): Action<State> =>
	({ getState, setState }) => {
		const wrappedEffect: typeof effect = (el) => {
			effect?.(el);
			setState({ focus: { ...getState().focus, rowEffect: undefined } });
		};
		setState({ focus: { ...getState().focus, rowEffect: wrappedEffect } });
	};

export const registerGridEffect =
	(effect: State['focus']['gridEffect']): Action<State> =>
	({ getState, setState }) => {
		const wrappedEffect: typeof effect = (el) => {
			effect?.(el);
			setState({ focus: { ...getState().focus, gridEffect: undefined } });
		};
		setState({ focus: { ...getState().focus, gridEffect: wrappedEffect } });
	};
