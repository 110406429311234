import React, { useCallback } from 'react';
import { type Pressable, Box, xcss } from '@atlaskit/primitives';
import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { type MessageDescriptor, FormattedMessage } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	component?: typeof Box | typeof Pressable;
	onClick?: (props?: unknown) => void;
	triggerProps?: TriggerProps;
	tabIndex?: number;
	// When cleaning up aggregate_fields_for_plan_m2, make all below required and remove undefined from ariaLabel & labelValues values
	testId: string;
	message?: MessageDescriptor;
	messageValues?: Record<string, string | number | undefined>;
	fieldKey: string;
};

const FieldBase = ({
	component: Component = Box,
	testId,
	message,
	messageValues,
	triggerProps,
	onClick,
	tabIndex,
	fieldKey,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickHandler = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'aggregateField',
			{ field: fieldKey },
		);

		onClick && onClick();
	}, [createAnalyticsEvent, fieldKey, onClick]);

	return (
		<Component
			xcss={[containerStylesBase, onClick && pressableStyles]}
			padding="space.100"
			testId={testId}
			{...triggerProps}
			onClick={onClickHandler}
			tabIndex={tabIndex}
		>
			<Box as="span" xcss={textStyles}>
				<FormattedMessage
					{...message}
					values={{
						...messageValues,
						qualifier: (q) => (
							<Box as="span" xcss={qualifierStyles}>
								{q}
							</Box>
						),
					}}
				/>
			</Box>
		</Component>
	);
};

export default FieldBase;

const containerStylesBase = xcss({
	display: 'block',
	background: 'none',
	height: '100%',
	width: '100%',
	cursor: 'default',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	font: token('font.body'),
	':focus': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, space.negative.025 does not map to token
		outlineOffset: token('space.negative.025'),
	},
	':focus-visible': {
		outlineColor: 'color.border.focused',
		// @ts-expect-error token() is required here, space.negative.025 does not map to token
		outlineOffset: token('space.negative.025'),
	},
});

const pressableStyles = xcss({
	paddingBottom: 'space.150',
	boxSizing: 'border-box',
	cursor: 'pointer',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
});

const textStyles = xcss({
	display: 'inline',
	whiteSpace: 'pre',
});

const qualifierStyles = xcss({
	display: 'inline',
	color: 'color.text.subtlest',
});
