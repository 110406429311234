import React, { type ReactNode, type KeyboardEvent, useMemo } from 'react';
import isNil from 'lodash/isNil';
// eslint-disable-next-line @atlaskit/design-system/no-unsupported-drag-and-drop-libraries
import { SortableElement } from 'react-sortable-hoc';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import FolderIcon from '@atlaskit/icon/core/migration/folder-closed--folder';
import PeopleIcon from '@atlaskit/icon/core/migration/people-group--people';
import ShipIcon from '@atlaskit/icon/core/migration/release--ship';
import ChevronDown from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRight from '@atlaskit/icon/utility/migration/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { AssociatedIssuesView } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/associated-issues-provider/associated-issues-view/index.tsx';
import AvatarInitials from '@atlassian/jira-portfolio-3-common/src/avatar-initials/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import planCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages.tsx';
import { TABLE_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import SprintIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/sprint/index.tsx';
import {
	CustomFieldTypes,
	GROUPING,
	type Grouping,
	DEFAULT_FIELD_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import { Goal } from '@atlassian/jira-software-goals/src/ui/goal/index.tsx';
import { FocusArea } from '@atlassian/jira-software-focus-areas/src/ui/focus-area/index.tsx';
import { GROUP_DESCRIPTOR } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/group/constants.tsx';
import { getGroupName, getLozengeTextForGroupByRelease, getSecondaryGroupName } from './utils.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, GroupIconProps, PropsForGroupHeaderComponent } from './types.tsx';

const groupIcons: Record<Grouping, ReactNode | null | undefined> = {
	[GROUPING.ASSIGNEE]: undefined,
	[GROUPING.REPORTER]: undefined,
	[GROUPING.COMPONENT]: <FolderIcon spacing="spacious" key="component" label="Folder icon" />,
	[GROUPING.LABEL]: <FolderIcon spacing="spacious" key="label" label="Folder icon" />,
	[GROUPING.NONE]: undefined,
	[GROUPING.PROJECT]: undefined,
	[GROUPING.RELEASE]: <ShipIcon spacing="spacious" key="release" label="Ship icon" />,
	[GROUPING.SPRINT]: <SprintIcon key="sprint" label="Sprint icon" />,
	[GROUPING.TEAM]: undefined,
	// Goals handled separately as it requires state
	[GROUPING.GOALS]: undefined,
	// Ideas handled separately as it requires state
	[GROUPING.IDEAS]: undefined,
	// FocusArea handled separately as it requires state
	[GROUPING.FOCUS_AREA]: undefined,
};

const UNASSIGNED_GOALS_GROUP = 'goals-UNDEFINED';
const UNASSIGNED_IDEAS_GROUP = 'ideas-UNDEFINED';
const UNASSIGNED_FOCUS_AREA_GROUP = 'focusArea-UNDEFINED';

export const GroupIcon = ({
	grouping,
	isExportMode,
	url = '',
	hasChildGroups = false,
	groupName,
	customFieldsById,
}: GroupIconProps) => {
	const { formatMessage } = useIntl();
	if (hasChildGroups) {
		return <FolderIcon spacing="spacious" label={formatMessage(messages.folderIconLabel)} />;
	}
	if (grouping === GROUPING.TEAM) {
		// team avatars are not supported in export mode so we render an AK icon instead
		if (url && !isExportMode) {
			return <Avatar size="small" src={url} />;
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.defaultTeamAvatar}>
				<PeopleIcon label={formatMessage(messages.peopleIconLabel)} LEGACY_size="small" />
			</div>
		);
	}

	if (isRoadmapGroupedByCustomField(grouping)) {
		const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);

		if (
			!isNil(customFieldsById) &&
			!isNil(customFieldsById[fieldId]) &&
			customFieldsById[fieldId].type.key === CustomFieldTypes.UserPicker
		) {
			if (isExportMode) {
				return <AvatarInitials size={24} name={groupName} />;
			}
			return <Avatar size="small" src={url} />;
		}

		return (
			<FolderIcon
				spacing="spacious"
				key={grouping}
				label={formatMessage(messages.folderIconLabel)}
			/>
		);
	}

	if (typeof groupIcons[grouping] !== 'undefined') {
		return <>{groupIcons[grouping]}</>;
	}

	if (isExportMode) {
		return <AvatarInitials size={24} name={groupName} />;
	}
	// Always display Avatar if group by "Assignee" or group by "Project" and url is not null,undefined,empty string
	if (grouping === GROUPING.ASSIGNEE || !!url) {
		return <Avatar size="small" src={url} />;
	}
	return null;
};

function GroupHeaderComponent({
	item,
	toggleExpandGroup,
	allTeams,
	additionalTeamsById,
	isExportMode,
	componentGroupsById,
	componentsById,
	projectsById,
	enrichedVersionsById,
	crossProjectVersionsById,
	groupName,
	customFieldsById,
	lazyGoalsByARI,
	associatedIssues,
	viewMode,
	focusAreas,
}: PropsForGroupHeaderComponent) {
	const { formatMessage } = useIntl();
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	const secondaryGroupName = getSecondaryGroupName(item, enrichedVersionsById, allTeams);
	const lozengeText = getLozengeTextForGroupByRelease(
		item,
		enrichedVersionsById,
		crossProjectVersionsById,
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let componentNames: Array<any> | Array<string> = [];

	const { grouping, parentGroup, childGroups } = item;

	if (grouping === GROUPING.COMPONENT) {
		const componentGroup = componentGroupsById[item.groupCombination.components || ''];
		const { components = [] } = componentGroup;

		componentNames = components
			.map((componentId) => componentsById[componentId])
			.filter((component) => !isNil(component))
			.map(({ name, projectId }) => `${name} (${projectsById[projectId].key})`);
	}

	const isExternalTeam =
		!isNil(item.groupCombination.team) && !isNil(additionalTeamsById[item.groupCombination.team]);

	const isVerifiedTeam = () => {
		if (!fg('plans_managed_teams_indicator_timeline_enabled')) {
			return false;
		}
		return (
			!isNil(item.groupCombination.team) &&
			!!allTeams.find((team) => team.id === item.groupCombination.team)?.verified
		);
	};

	const toggle = () =>
		toggleExpandGroup({
			grouping: item.grouping,
			groupName: item.group,
			expand: !item.isExpanded,
		});

	const onKeyDownHandler = (e: KeyboardEvent<EventTarget>) => {
		if (e.keyCode === 13 || e.keyCode === 32) {
			toggle();
		}
	};

	const renderTooltipForExternalTeam = () => (
		<Tooltip
			content={
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles['tooltip-wrapper']}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
					<p className={styles['tooltip-title']}>
						{formatMessage(planCommonMessages.externalTeamTooltipTitle)}
					</p>
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
					<p>{formatMessage(planCommonMessages.externalTeamTooltipContent)}</p>
				</div>
			}
		>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={isExportMode ? styles.exportPNGLozenge : styles['external-lozenge-wrapper']}
			>
				<Lozenge>{formatMessage(planCommonMessages.externalLozenge)}</Lozenge>
			</div>
		</Tooltip>
	);

	const isShareableTeamFromGroupItem = () => {
		if (!isNil(item.groupCombination.team)) {
			const teamId = item.groupCombination.team;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const team = allTeams.find((team) => team.id === teamId);
			return team ? team.shareable : false;
		}
		return false;
	};

	const renderTooltipForPlanOnlyTeam = () => {
		const isShareableTeam = isShareableTeamFromGroupItem();
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { grouping, value } = item;

		if (grouping !== GROUPING.TEAM || value === '') {
			return null;
		}

		if (isExternalTeam || isShareableTeam) {
			return null;
		}

		return (
			<Tooltip
				content={
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles['tooltip-wrapper']}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
						<p className={styles['tooltip-title']}>
							{formatMessage(planCommonMessages.planOnlyTeamTooltipTitle)}
						</p>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<p>{formatMessage(planCommonMessages.planOnlyTeamTooltipContent)}</p>
					</div>
				}
			>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={isExportMode ? styles.exportPNGLozenge : styles['external-lozenge-wrapper']}
				>
					<Lozenge>{formatMessage(planCommonMessages.planOnlyLozenge)}</Lozenge>
				</div>
			</Tooltip>
		);
	};

	const hasParentGroup = !!parentGroup;
	const hasChildGroups = !!childGroups;

	const renderGroupName = () => (
		<>
			<span>{groupName || ''}</span>
			{!isNil(secondaryGroupName) && <small> ({secondaryGroupName})</small>}
		</>
	);

	const renderGroupNameContent = () => {
		const { group } = item;

		if (grouping === GROUPING.GOALS && group !== UNASSIGNED_GOALS_GROUP) {
			// Goal ARI is the group with a prefix removed
			const goalARI = group.replace('goals-', '');
			const { isLoading, goal } = lazyGoalsByARI[goalARI] || {};

			if (isLoading) {
				return <CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />;
			}

			if (goal) {
				const { name, state } = goal;

				if (name && state) {
					return <Goal name={name} state={state} isExportMode={isExportMode} />;
				}
			}
			return null;
		}

		if (grouping === GROUPING.IDEAS && group !== UNASSIGNED_IDEAS_GROUP) {
			// Idea ID is the group with a prefix removed
			const ideaId = group.replace('ideas-', '');
			if (!associatedIssues || !associatedIssues[ideaId]) {
				return null;
			}
			return <AssociatedIssuesView associatedIssues={[associatedIssues[ideaId]]} />;
		}

		if (grouping === GROUPING.FOCUS_AREA && group !== UNASSIGNED_FOCUS_AREA_GROUP) {
			// focusArea ID is the group with a prefix removed
			const focusAreaId = group.replace('focusArea-', '');
			if (!focusAreas || !focusAreas[focusAreaId]) {
				return null;
			}
			const { name, status } = focusAreas[focusAreaId];
			return <FocusArea name={name} status={status} isExportMode={isExportMode} />;
		}

		return renderGroupName();
	};

	const getTooltipContent = () => {
		if (grouping === GROUPING.GOALS) {
			return lazyGoalsByARI[groupName]?.goal?.name || groupName;
		}
		if (grouping === GROUPING.IDEAS && associatedIssues) {
			return associatedIssues[groupName]?.summary || groupName;
		}
		if (grouping === GROUPING.FOCUS_AREA && focusAreas) {
			return focusAreas[groupName]?.name || groupName;
		}
		return groupName;
	};

	const isTransposed =
		!fg('plan-timeline-non-transposed') &&
		(viewMode === VIEW_MODES.LIST || !fg('plan-timeline-non-transposed'));
	const className = isTransposed ? 'group-details-transposed' : 'group-details';

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles[className]}
			role="button"
			tabIndex={0}
			onClick={toggle}
			onKeyDown={onKeyDownHandler}
			data-role="header"
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.group-header"
			aria-expanded={item.isExpanded}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<span className={`${styles['group-chevron']} ${hasParentGroup ? styles.hasParentGroup : ''}`}>
				{item.isExpanded ? (
					<ChevronDown label="" LEGACY_size="large" color="currentColor" />
				) : (
					<ChevronRight label="" LEGACY_size="large" color="currentColor" />
				)}
			</span>

			{grouping !== GROUPING.GOALS &&
				grouping !== GROUPING.IDEAS &&
				grouping !== GROUPING.FOCUS_AREA && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<span className={styles.groupingAvatar}>
						<GroupIcon
							customFieldsById={customFieldsById}
							grouping={item.grouping}
							url={item.url}
							isExportMode={isExportMode}
							hasChildGroups={hasChildGroups}
							groupName={groupName || ''}
						/>
					</span>
				)}
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={
					styles[
						fg('plans_managed_teams_indicator_timeline_enabled') ? 'group-title' : 'group-title-old'
					]
				}
			>
				<Tooltip
					position="mouse"
					content={
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.tooltip}>
							<Heading as="h6" size="xxsmall">
								{getTooltipContent() || ''}
							</Heading>
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							{componentNames.length !== 0 && <p>{componentNames.join(', ')}</p>}
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							{!isNil(secondaryGroupName) && <p>({secondaryGroupName})</p>}
						</div>
					}
				>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={`group-name ${styles.groupName}`}
						id={`group-name-${item.group.replace(/\s/g, '')}`}
					>
						{renderGroupNameContent()}
					</div>
				</Tooltip>
				{isVerifiedTeam() && <VerifiedTeamIcon showTooltip />}
			</div>
			{isExternalTeam ? renderTooltipForExternalTeam() : null}
			{renderTooltipForPlanOnlyTeam()}
			{lozengeText && (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={isExportMode ? styles.exportPNGLozenge : styles['external-lozenge-wrapper']}
				>
					<Lozenge>{lozengeText}</Lozenge>
				</div>
			)}
		</div>
	);
}
const SortableGroupHeaderComponent = SortableElement(GroupHeaderComponent);

const Group = ({
	item,
	allTeams,
	sprints,
	isStickyGroupHeader = false,
	style,
	key = '',
	index,
	lazyGoalsByARI,
	associatedIssues,
	focusAreas,
	groupDescriptor,
	...props
}: Props) => {
	const { group } = item;
	const { formatMessage } = useIntl();
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	const groupNameNew = useMemo((): string => {
		// Remove condition and fallback when cleanup refactor_plan_time_group_name; groupDescriptor will not be undefined
		if (!fg('refactor_plan_time_group_name') || !groupDescriptor) return '';

		return 'groupName' in groupDescriptor
			? groupDescriptor.groupName
			: formatMessage(GROUP_DESCRIPTOR[groupDescriptor.messageKey], {
					count: groupDescriptor.count,
				});
	}, [formatMessage, groupDescriptor]);

	const groupName = fg('refactor_plan_time_group_name')
		? groupNameNew
		: getGroupName({ item, allTeams, sprints, formatMessage }) ?? '';
	const elementKey = `${item.tag}-${group || ''}-${groupName || key || ''}`;

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div key={elementKey} {...(style && { style })} className={styles.groupRow}>
			{isStickyGroupHeader ? (
				<GroupHeaderComponent
					item={item}
					groupName={groupName}
					allTeams={allTeams}
					lazyGoalsByARI={lazyGoalsByARI}
					associatedIssues={associatedIssues}
					focusAreas={focusAreas}
					{...props}
				/>
			) : (
				<SortableGroupHeaderComponent
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					index={index as number}
					item={item}
					groupName={groupName}
					allTeams={allTeams}
					lazyGoalsByARI={lazyGoalsByARI}
					associatedIssues={associatedIssues}
					focusAreas={focusAreas}
					{...props}
				/>
			)}
		</div>
	);
};

export default Group;
