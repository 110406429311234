// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DELIVERY_DATE_AGGREGATION_TYPE_LATEST = 'LATEST';
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DELIVERY_DATE_AGGREGATION_TYPE_EARLIEST = 'EARLIEST';

type DeliveryDateAggregationTypesMap = {
	LATEST: typeof DELIVERY_DATE_AGGREGATION_TYPE_LATEST;
	EARLIEST: typeof DELIVERY_DATE_AGGREGATION_TYPE_EARLIEST;
};

export const DELIVERY_DATE_AGGREGATION_TYPES: DeliveryDateAggregationTypesMap = {
	LATEST: 'LATEST',
	EARLIEST: 'EARLIEST',
};

export type DeliveryDateAggregationType =
	DeliveryDateAggregationTypesMap[keyof DeliveryDateAggregationTypesMap];
