/** @jsx jsx */
import React, { useCallback, useMemo, useRef, forwardRef, type Ref } from 'react';
import { css, jsx } from '@compiled/react';
import includes from 'lodash/includes';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Avatar from '@atlaskit/avatar';
import PeopleIcon from '@atlaskit/icon/core/migration/people-group--people';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import Checkbox from '@atlaskit/checkbox';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import { TEAM_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { NO_TEAM_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/team-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { ContentWrapper, ItemWrapper } from '../common/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

function TeamFilterInner(
	props: Props & { setInitialFocusRef?: (elem: HTMLElement | null) => void },
) {
	const intl = useIntl();
	const {
		value,
		clearTeamFilter,
		searchQuery,
		onQueryChange,
		teams,
		changeTeamFilter,
		setInitialFocusRef,
	} = props;
	const isTeamSelected = useCallback((teamTitle: string) => includes(value, teamTitle), [value]);
	const onTeamClick = useCallback(
		(id: string) => {
			if (isTeamSelected(id)) {
				changeTeamFilter(value.filter((v) => v !== id));
			} else {
				changeTeamFilter([...value, id]);
			}
		},
		[changeTeamFilter, isTeamSelected, value],
	);
	const renderNoTeams = () => {
		const title = intl.formatMessage(messages.noTeamFilterText);
		return (
			<ItemWrapper>
				<Checkbox
					id={NO_TEAM_ID}
					key={NO_TEAM_ID}
					isChecked={isTeamSelected(NO_TEAM_ID)}
					onChange={() => onTeamClick(NO_TEAM_ID)}
					label={
						<Tooltip content={title}>
							<div css={teamCheckboxLabelTitleStyles}>{title}</div>
						</Tooltip>
					}
				/>
			</ItemWrapper>
		);
	};

	const renderTeams = () => {
		const matchSearchQueryResult = teams.filter((team: Team) =>
			team.title.toLowerCase().includes(searchQuery.toLowerCase()),
		);

		const formatCheckBoxLabel = ({
			avatarUrl,
			title,
			verified,
		}: {
			avatarUrl: string;
			id: string;
			title: string;
			verified: Boolean;
		}) => {
			return (
				<Tooltip content={title}>
					<div css={teamCheckboxLabelWrapperStyles}>
						{!avatarUrl ? (
							<div css={teamCheckboxLabelDefaultAvatarStyles}>
								<PeopleIcon label="" LEGACY_size="small" />
							</div>
						) : (
							<Avatar size="small" src={avatarUrl} />
						)}
						<div css={teamCheckboxLabelTitleStyles}>{title}</div>
						{verified && <VerifiedTeamIcon showTooltip />}
					</div>
				</Tooltip>
			);
		};
		return (
			<>
				{matchSearchQueryResult.map(({ avatarUrl = '', id, title, verified = false }) => (
					<ItemWrapper key={id}>
						<Checkbox
							id={id}
							key={id}
							isChecked={isTeamSelected(id)}
							onChange={() => onTeamClick(id)}
							label={formatCheckBoxLabel({ avatarUrl, id, title, verified })}
						/>
					</ItemWrapper>
				))}
				{matchSearchQueryResult.length === 0 && <NoMatchFound />}
			</>
		);
	};

	return (
		<ContentWrapper>
			<ClearFilterButton isVisible={!!value.length} onClearClick={clearTeamFilter} />
			<SearchField
				placeholder={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchTeamPlaceholderIssueTermRefresh
						: messages.searchTeamPlaceholder,
				)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={intl.formatMessage(messages.searchTeamLabel)}
				setInitialFocusRef={setInitialFocusRef}
			/>
			{teams.length > 0 && renderNoTeams()}
			{renderTeams()}
		</ContentWrapper>
	);
}

const TeamFilterTriggerButton = forwardRef(
	(
		props: Pick<Props, 'isOpen' | 'value' | 'teams' | 'onOpenChange'> & TriggerProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { formatMessage } = useIntl();
		const { teams, isOpen, value, onOpenChange, ...triggerProps } = props;

		const constructedFilterText = useMemo(() => {
			const filterText = [];
			if (includes(value, NO_TEAM_ID)) {
				filterText.push(formatMessage(messages.noTeamFilterText));
			}

			return filterText
				.concat(teams.filter(({ id }) => includes(value, id)).map(({ title }) => title))
				.join(', ');
		}, [formatMessage, value, teams]);

		const ariaText = useMemo(() => {
			const filterText = constructedFilterText;
			// Teams, All selected
			return `${formatMessage(filterMessages[TEAM_FILTER_ID])}, ${
				value.length > 0 ? filterText : formatMessage(messages.emptyPlaceholder)
			} ${formatMessage(filterMessages.selected)}`;
		}, [value, constructedFilterText, formatMessage]);

		return (
			<TriggerButton
				{...triggerProps}
				ref={ref}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.trigger-btn"
				triggerButtonText={
					value.length > 0 ? (
						<FilterText text={constructedFilterText} />
					) : (
						<FormattedMessage {...messages.emptyPlaceholder} />
					)
				}
				ariaLabel={ariaText}
			/>
		);
	},
);

function TeamFilter(props: Props) {
	const { isOpen, onOpenChange, teams, value } = props;
	const triggerButtonRef = useRef<HTMLButtonElement>(null);
	return (
		<Popup
			onClose={() => {
				onOpenChange({ isOpen: false });
				triggerButtonRef?.current?.focus();
			}}
			placement="bottom-start"
			isOpen={isOpen}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter"
			content={(contentProps) => (
				<TeamFilterInner {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
			)}
			trigger={(triggerProps) => {
				const { ref: triggerRef, ...triggerPropsForButton } = triggerProps;
				const mergedRef = mergeRefs(triggerRef, triggerButtonRef);
				return (
					<TeamFilterTriggerButton
						{...triggerPropsForButton}
						ref={mergedRef}
						teams={teams}
						isOpen={isOpen}
						value={value}
						onOpenChange={onOpenChange}
					/>
				);
			}}
		/>
	);
}

export default TeamFilter;

const teamCheckboxLabelTitleStyles = css({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const teamCheckboxLabelDefaultAvatarStyles = css({
	backgroundColor: token('color.background.neutral'),
	borderRadius: '50%',
	display: 'flex',
	margin: '0px 2px',
	padding: '4px 4px',
});

const teamCheckboxLabelWrapperStyles = css({
	alignItems: 'center',
	display: 'grid',
	gap: token('space.100'),
	gridTemplateColumns: 'fit-content(100%) fit-content(100%) fit-content(100%)',
});
