import React, { useCallback, useMemo, useRef } from 'react';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { PROJECT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import { ContentWrapper, ItemWrapper } from '../common/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ProjectFilter = ({
	value,
	projects,
	changeProjectFilter,
	clearProjectFilter,
	isOpen,
	onOpenChange,
	searchQuery,
	onQueryChange,
}: Props) => {
	const { formatMessage } = useIntl();
	const triggerButtonRef = useRef<HTMLButtonElement>(null);

	const constructFilterText = useMemo(() => {
		return projects
			.filter(({ id }) => value.includes(id))
			.map(({ name }) => name)
			.join(', ');
	}, [projects, value]);

	const renderFilterText = useMemo(() => {
		const filterText = constructFilterText;
		return value.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	}, [constructFilterText, value.length]);

	const ariaText = useMemo(() => {
		const filterText = constructFilterText;
		// Projects, All
		return `${formatMessage(filterMessages[PROJECT_FILTER_ID])}, ${
			value.length > 0 ? filterText : formatMessage(messages.emptyPlaceholder)
		} ${formatMessage(filterMessages.selected)}`;
	}, [constructFilterText, formatMessage, value]);

	const isProjectSelected = useCallback((projectId: number) => value.includes(projectId), [value]);

	const filterProjectsWithSearchQuery = useCallback(
		(project: Project) => {
			const lowerCaseQuery = searchQuery.toLowerCase();

			const queryMatchProp = (prop: keyof Project) => {
				const propertyValue = project[prop];
				if (typeof propertyValue === 'string') {
					return propertyValue.toLowerCase().includes(lowerCaseQuery);
				}
				return false;
			};

			return queryMatchProp('name') || queryMatchProp('key');
		},
		[searchQuery],
	);

	const onProjectClick = useCallback(
		(projectId: number): void => {
			if (isProjectSelected(projectId)) {
				changeProjectFilter(value.filter((project) => project !== projectId));
			} else {
				changeProjectFilter([...value, projectId]);
			}
		},
		[changeProjectFilter, isProjectSelected, value],
	);

	const renderProjectGroups = useCallback(() => {
		const searchResultProjects = projects.filter(filterProjectsWithSearchQuery);

		return (
			<>
				{searchResultProjects.map(({ id, key, name, avatarUrl }) => (
					<ItemWrapper key={id}>
						<Checkbox
							id={`${id}`}
							key={id}
							isChecked={isProjectSelected(id)}
							onChange={() => onProjectClick(id)}
							label={<TitleWithAvatar avatarUrl={avatarUrl} id={id} projectKey={key} name={name} />}
						/>
					</ItemWrapper>
				))}
				{searchResultProjects.length === 0 && <NoMatchFound />}
			</>
		);
	}, [filterProjectsWithSearchQuery, isProjectSelected, onProjectClick, projects]);

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
			onClose={() => {
				onOpenChange({ isOpen: false });
				triggerButtonRef?.current?.focus();
			}}
			content={(contentProps) => (
				<ContentWrapper>
					<ClearFilterButton isVisible={!!value.length} onClearClick={clearProjectFilter} />
					<SearchField
						placeholder={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchProjectPlaceholderIssueTermRefresh
								: messages.searchProjectPlaceholder,
						)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
						ariaLabel={formatMessage(messages.searchProjectLabel)}
						setInitialFocusRef={contentProps.setInitialFocusRef}
					/>
					{renderProjectGroups()}
				</ContentWrapper>
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter"
			trigger={(triggerProps) => {
				const { ref: triggerRef, ...triggerPropsForButton } = triggerProps;
				const mergedRef = mergeRefs(triggerRef, triggerButtonRef);
				return (
					<TriggerButton
						{...triggerPropsForButton}
						ref={mergedRef}
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter.trigger-btn"
						triggerButtonText={renderFilterText}
						ariaLabel={ariaText}
					/>
				);
			}}
		/>
	);
};

export default ProjectFilter;
