import React, { type SyntheticEvent, useRef } from 'react';
import * as R from 'ramda';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { fg } from '@atlassian/jira-feature-gating';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
// Remove InlineDialog when cleaning up FG 'migrate_plan_filter_to_popup'
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import {
	sortPerson,
	filter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/reporters/utils.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/reporters/types.tsx';
import { REPORTER_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { SearchField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { UserSelectItem } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FilterText } from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH, ContentWrapper, ItemWrapper } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props, CommonEventHandler } from './types.tsx';

export function ReporterCheckbox({
	person,
	checked,
	onChange,
}: {
	person: Person;
	checked: boolean;
	onChange: CommonEventHandler;
}) {
	const { title, email, avatarUrl } = person.jiraUser;

	return (
		<Checkbox
			value={person.personId}
			isChecked={checked}
			onChange={onChange}
			label={<UserSelectItem email={email} label={title} icon={avatarUrl} />}
		/>
	);
}

function ReporterFilter({
	intl,
	searchQuery,
	onQueryChange,
	value,
	onRequestChange,
	onRequestClear,
	reporters,
	setInitialFocusRef,
}: Props & { setInitialFocusRef?: (ref: HTMLElement | null) => void }) {
	const isSelected = (reporter: Person): boolean => R.includes(reporter.personId, value);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleReporterCheckboxChange = (e: SyntheticEvent<any>) => {
		const { value: personId, checked } = e.currentTarget;
		const nextValue = ((): typeof value => {
			const withoutTheReporter = R.filter(R.pipe(R.equals(personId), R.not))(value);

			if (!checked) {
				return withoutTheReporter;
			}

			return withoutTheReporter.concat([personId]);
		})();

		onRequestChange(nextValue);
	};

	const reportersToDisplay = sortPerson(filter(searchQuery, reporters));

	const additionalProps = fg('migrate_plan_filter_to_popup') ? { setInitialFocusRef } : {};

	return (
		<>
			<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
			<SearchField
				placeholder={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchReporterPlaceholderIssueTermRefresh
						: messages.searchReporterPlaceholder,
				)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
				ariaLabel={intl.formatMessage(messages.searchReporterLabel)}
				{...additionalProps}
			/>
			{fg('migrate_plan_filter_to_popup')
				? reportersToDisplay.map((reporter, index) => (
						<ItemWrapper key={index}>
							<ReporterCheckbox
								checked={isSelected(reporter)}
								onChange={handleReporterCheckboxChange}
								person={reporter}
							/>
						</ItemWrapper>
					))
				: reportersToDisplay.map((reporter, index) => (
						<DialogMenuItem key={index}>
							<ReporterCheckbox
								checked={isSelected(reporter)}
								onChange={handleReporterCheckboxChange}
								person={reporter}
							/>
						</DialogMenuItem>
					))}

			{R.isEmpty(reportersToDisplay) && <NoMatchFound />}
		</>
	);
}

const getNames = (reporters: Person[], selected: Set<string>) => {
	const selectedReporters = reporters.filter((person) => selected.has(person.personId));
	const names = selectedReporters.map((person) => person.jiraUser.title);
	return names.join(', ');
};

export function TriggerButtonText({
	value,
	persons,
}: {
	value: Props['value'];
	persons: Person[];
}) {
	const selected = new Set(value);

	if (selected.size === 0) {
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	}

	return <FilterText text={getNames(persons, selected)} />;
}

function ReporterFilterDialog(props: Props) {
	const { isOpen, onOpenChange, value, intl, reporters } = props;
	const triggerButtonRef = useRef<HTMLButtonElement>(null);
	const ariaText = () => {
		const selected = new Set(value);

		// ex: reporters, All selected
		return `${intl.formatMessage(filterMessages[REPORTER_FILTER_ID])}, ${
			selected.size > 0
				? getNames(reporters, selected)
				: intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	if (!fg('migrate_plan_filter_to_popup')) {
		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				isOpen={isOpen}
				onClose={onOpenChange}
				content={
					<DialogMenuContainer>
						<ReporterFilter {...props} />
					</DialogMenuContainer>
				}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter"
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter.trigger-btn"
					triggerButtonText={<TriggerButtonText value={props.value} persons={props.reporters} />}
					ariaLabel={ariaText()}
				/>
			</InlineDialog>
		);
	}
	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			onClose={() => {
				onOpenChange({ isOpen: false });
				triggerButtonRef?.current?.focus();
			}}
			content={(contentProps) => (
				<ContentWrapper>
					<ReporterFilter {...props} setInitialFocusRef={contentProps.setInitialFocusRef} />
				</ContentWrapper>
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter"
			trigger={(triggerProps) => {
				const { ref: triggerRef, ...triggerPropsForButton } = triggerProps;
				const mergedRef = mergeRefs(triggerRef, triggerButtonRef);
				return (
					<TriggerButton
						{...triggerPropsForButton}
						ref={mergedRef}
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter.trigger-btn"
						triggerButtonText={<TriggerButtonText value={props.value} persons={props.reporters} />}
						ariaLabel={ariaText()}
					/>
				);
			}}
		/>
	);
}

export default injectIntl(ReporterFilterDialog);
