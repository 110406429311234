import { type RefObject, useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
/**
 * Custom Hook to handle dialog close behavior when clicking outside or pressing 'Escape'.
 *
 * @param {RefObject<HTMLElement>} dialogRef - A React ref object pointing to the dialog element.
 * @param {(arg1: { isOpen: boolean }) => void} onOpenChange - Callback function to update the 'isOpen' state.
 * @param {boolean} isOpen - Boolean indicating whether the dialog is open.
 */
export const useHandleDialogClose = (
	dialogRef: RefObject<HTMLElement>,
	onOpenChange: (arg1: { isOpen: boolean }) => void,
	isOpen: boolean,
) => {
	useEffect(() => {
		/**
		 * Close the dialog when clicking outside.
		 *
		 * @param {MouseEvent|TouchEvent} event - The DOM event from a mouse or touch action.
		 */
		const handleClickOutside = (event: MouseEvent | TouchEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
				event.preventDefault();
				onOpenChange({ isOpen: false });
			}
		};
		/**
		 * Close the dialog when pressing the 'Escape' key.
		 *
		 * @param {KeyboardEvent} event - The DOM event from a keyboard action.
		 */
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				fg('jpo_a11y_pb_fixes') && event.stopPropagation();
				onOpenChange({ isOpen: false });
			}
		};
		if (isOpen) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousedown', handleClickOutside);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('touchstart', handleClickOutside);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousedown', handleClickOutside);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('touchstart', handleClickOutside);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, onOpenChange, dialogRef]);
};
