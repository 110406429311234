import isNil from 'lodash/isNil';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import {
	MIN,
	MAX,
	SUM,
	MAP,
	CONCAT,
	type AggregateValue,
	type NumberFieldKey,
	type StringFieldKey,
	type ArrayFieldKey,
	type IssueFieldTypes,
	type AggregateFieldTypes,
} from './types.tsx';

// Reducers

const max = (
	acc: number | null | undefined,
	curr: number | null | undefined,
): number | undefined => {
	if (isNil(acc)) return curr ?? undefined;
	if (isNil(curr)) return acc;

	return Math.max(acc, curr);
};

const min = (
	acc: number | null | undefined,
	curr: number | null | undefined,
): number | undefined => {
	if (isNil(acc)) return curr ?? undefined;
	if (isNil(curr)) return acc;

	return Math.min(acc, curr);
};

const sum = (
	acc: number | null | undefined,
	curr: number | null | undefined,
): number | undefined => {
	if (isNil(acc) && isNil(curr)) return undefined;

	return (acc ?? 0) + (curr ?? 0);
};

const map = <T,>(acc: NonNullable<T>[], curr: T): NonNullable<T>[] => {
	if (curr !== undefined && curr !== null) acc.push(curr);

	return acc;
};

const concat = <T,>(acc: T[], curr: T[] | undefined | null): T[] => {
	if (curr !== undefined && curr !== null) acc.push(...curr);
	return acc;
};

// Helper functions

const getUseEarliestOrLatest = (
	objectKey: string,
	dateConfiguration: DateConfiguration,
	preferEarliest: boolean,
): boolean => {
	const { baselineStartField, baselineEndField } = dateConfiguration;
	const isBaselineStartField = baselineStartField.key === objectKey;
	const isBaselineEndField = baselineEndField.key === objectKey;

	// If both or neither are true, return the preferred reducer
	if (isBaselineStartField === isBaselineEndField) {
		return preferEarliest;
	}

	// If one is true and the other is false, return the associated reducer
	return isBaselineStartField;
};

const getBaselineDate = (issue: Issue, fieldKey: string, dateConfiguration: DateConfiguration) => {
	const { baselineStartField, baselineEndField } = dateConfiguration;
	const isBaselineStartField = baselineStartField.key === fieldKey;
	const isBaselineEndField = baselineEndField.key === fieldKey;

	if (isBaselineStartField) return issue.baselineStart;
	if (isBaselineEndField) return issue.baselineEnd;

	return undefined;
};

// Generic aggregate functions

const aggregateDate = <K extends NumberFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => IssueFieldTypes[K],
	dateConfiguration: DateConfiguration,
	preferEarliest: boolean,
): AggregateValue<K> => {
	const useEarliestOrLatest = getUseEarliestOrLatest(fieldKey, dateConfiguration, preferEarliest);
	const dateReducer = useEarliestOrLatest ? min : max;

	return {
		fieldKey,
		fieldValue: issues.reduce<AggregateFieldTypes[K]>(
			(acc, curr) =>
				dateReducer(acc, getBaselineDate(curr, fieldKey, dateConfiguration) ?? extractor(curr)),
			undefined,
		),
		type: useEarliestOrLatest ? MIN : MAX,
	};
};

const aggregateSum = <K extends NumberFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => IssueFieldTypes[K],
): AggregateValue<K> => {
	return {
		fieldKey,
		fieldValue: issues.reduce<AggregateFieldTypes[K]>(
			(acc, curr) => sum(acc, extractor(curr)),
			undefined,
		),
		type: SUM,
	};
};

const aggregateMap = <K extends StringFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => IssueFieldTypes[K],
): AggregateValue<K> => {
	return {
		fieldKey,
		fieldValue: issues.reduce<AggregateFieldTypes[K]>((acc, curr) => map(acc, extractor(curr)), []),
		type: MAP,
	};
};

const aggregateConcat = <K extends ArrayFieldKey>(
	issues: Issue[],
	fieldKey: K,
	extractor: (issue: Issue) => IssueFieldTypes[K],
): AggregateValue<K> => {
	return {
		fieldKey,
		fieldValue: {
			values: issues.reduce<AggregateFieldTypes[K]['values']>(
				(acc, curr) => concat(acc, extractor(curr)),
				[],
			),
			unassignedCount: issues.filter((issue) => (extractor(issue) ?? []).length === 0).length,
		},
		type: CONCAT,
	};
};

// Field aggregate functions

export const aggregateStartDate = (
	issues: Issue[],
	dateConfiguration: DateConfiguration,
	startDateCustomField: CustomField | undefined,
): AggregateValue<'startDate'> => {
	if (!startDateCustomField)
		return {
			fieldKey: 'startDate',
			fieldValue: undefined,
			type: MIN,
		};

	const startDateFieldKey = startDateCustomField.id.toString();
	const useEarliestOrLatest = getUseEarliestOrLatest(startDateFieldKey, dateConfiguration, true);
	const dateReducer = useEarliestOrLatest ? min : max;

	return {
		fieldKey: 'startDate',
		fieldValue: issues.reduce<AggregateFieldTypes['startDate']>(
			(acc, curr) =>
				dateReducer(
					acc,
					getBaselineDate(curr, startDateFieldKey, dateConfiguration) ??
						curr.customFields?.[startDateFieldKey],
				),
			undefined,
		),
		type: useEarliestOrLatest ? MIN : MAX,
	};
};

export const aggregateDueDate = (issues: Issue[], dateConfiguration: DateConfiguration) =>
	aggregateDate(issues, 'dueDate', (issue) => issue.dueDate, dateConfiguration, false);

export const aggregateTargetStart = (issues: Issue[], dateConfiguration: DateConfiguration) =>
	aggregateDate(issues, 'targetStart', (issue) => issue.targetStart, dateConfiguration, true);

export const aggregateTargetEnd = (issues: Issue[], dateConfiguration: DateConfiguration) =>
	aggregateDate(issues, 'targetEnd', (issue) => issue.targetEnd, dateConfiguration, false);

export const aggregateStoryPoints = (issues: Issue[]) =>
	aggregateSum(issues, 'storyPoints', (issue) => issue.storyPoints);

export const aggregateTimeEstimate = (issues: Issue[]) =>
	aggregateSum(issues, 'timeEstimate', (issue) => issue.timeEstimate);

export const aggregateStatus = (issues: Issue[]) =>
	aggregateMap(issues, 'status', (issue) => issue.status);

export const aggregateAssignee = (issues: Issue[]) =>
	aggregateMap(issues, 'assignee', (issue) => issue.assignee);

export const aggregateTeam = (issues: Issue[]): AggregateValue<'team'> => ({
	fieldKey: 'team',
	fieldValue: {
		values: issues.reduce<AggregateFieldTypes['team']['values']>(
			(acc, curr) => map(acc, curr.team),
			[],
		),
		unassignedCount: issues.filter((issue) => issue.team === null || issue.team === undefined)
			.length,
	},
	type: MAP,
});

export const aggregatePriority = (
	issues: Issue[],
	defaultIssuePriorityByProjectId: Record<number, string>,
) =>
	aggregateMap(
		issues,
		'priority',
		(issue) => issue.priority ?? defaultIssuePriorityByProjectId[issue.project],
	);

export const aggregateProgressByEstimation = (
	issues: Issue[],
): AggregateValue<'progressByEstimation'> => {
	return {
		fieldKey: 'progressByEstimation',
		fieldValue: issues,
		type: MAP,
	};
};

export const aggregateGoals = (issues: Issue[]) =>
	aggregateConcat(issues, 'goals', (issue) => issue.goals);

export const aggregateFixVersions = (issues: Issue[]) =>
	aggregateConcat(issues, 'fixVersions', (issue) => issue.fixVersions);

export const aggregateLabels = (issues: Issue[]) =>
	aggregateConcat(issues, 'labels', (issue) => issue.labels);

export const aggregateIdeas = (
	issues: Issue[],
	associatedIssues: Record<string, AssociatedIssue>,
): AggregateValue<'ideas'> => {
	const sanitiseIdeas = (id: string) => associatedIssues[id];
	return {
		fieldKey: 'ideas',
		fieldValue: {
			values: issues.reduce<AggregateFieldTypes['ideas']['values']>((acc, curr) => {
				const ids = curr.associatedIssueIds?.filter(sanitiseIdeas);
				return concat(acc, ids);
			}, []),
			unassignedCount: issues.filter((issue) => {
				const associatedIds = (issue.associatedIssueIds ?? []).filter(sanitiseIdeas);
				return associatedIds.length === 0;
			}).length,
		},
		type: CONCAT,
	};
};

export const aggregateIncomingDependencies = (issues: Issue[]) =>
	aggregateMap(issues, 'incomingDependencies', (issue) => issue.id);

export const aggregateOutgoingDependencies = (issues: Issue[]) =>
	aggregateMap(issues, 'outgoingDependencies', (issue) => issue.id);
