import React, { type ComponentType } from 'react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import DescriptionDI from './description/index.tsx';
import messages from './messages.tsx';

export const getIssueSearchWarningFlag = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Description: ComponentType<Record<any, any>> = DescriptionDI,
) => (
	<Flag
		key="issue-search-warning"
		id="issue-search-warning"
		icon={<WarningIcon spacing="spacious" label="" color={token('color.icon.warning')} />}
		title={<FormattedMessage {...messages.hiddenByFilterTitle} />}
		description={<Description />}
	/>
);
