// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

const store = createStore<State, typeof actions>({
	initialState: {
		column: undefined,
		hovered: false,
		active: false,
	},
	actions,
});

export const useResize = createHook(store);
