import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import mapStateToProps from './query.tsx';
import View, { EstimateCellOld as ViewOld } from './view.tsx';
import type { StateProps, OwnProps } from './types.tsx';

const EstimateField = connect<StateProps, {}, OwnProps, State>(mapStateToProps, null)(View);

const EstimateFieldOld = connect<StateProps, {}, OwnProps, State>(mapStateToProps, null)(ViewOld);

export default componentWithCondition(
	() => expValEquals('aggregate_fields_for_plan_m2_experiment', 'cohort', 'variation'),
	EstimateField,
	EstimateFieldOld,
);
