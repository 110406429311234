import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	deselectSelectedItems: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.deselect-selected-items.deselect-selected-items',
		description:
			'Label displayed in the Jira plan timeline meatball menu for the button that deselects all selected issues.',
		defaultMessage: 'Deselect all',
	},
});

export default messages;
