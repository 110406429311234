import React, { useState, useCallback, useMemo } from 'react';
import { xcss, Pressable, Inline, Box } from '@atlaskit/primitives';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { GoalIcon, normalizeGoalAppearance } from '@atlassian/goals/icon';
import { GoalTracker } from '@atlassian/jira-software-goals/src/ui/goal/tracker/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '../common/ui/messages.tsx';
import FlyoutContent from '../common/ui/flyout-content/index.tsx';
import FieldBase from '../common/ui/index.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const GoalCell = ({
	fieldKey,
	values,
	groupName,
	totalIssues,
	unassignedCount,
	groupByLabel,
	swimlane,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { formatMessage } = useIntl();
	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const label = useMemo(
		() =>
			fg('jira-issue-terminology-refresh-m3')
				? commonMessages.issueCountLabelIssueTermRefresh
				: commonMessages.issueCountLabel,
		[],
	);

	const content = useMemo(
		() =>
			values.map(({ name, state, count }) => ({
				title: (
					<Inline space="space.050" alignBlock="center" xcss={goalWrapperStyles}>
						<Inline as="span" alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
							{state && (
								<GoalIcon
									size="24"
									label={state.label}
									appearance={normalizeGoalAppearance(state.value)}
									goalIconKey="GOAL"
								/>
							)}
						</Inline>
						<Box xcss={goalNameWrapperStyles} title={name}>
							{name}
						</Box>
						<Inline xcss={trackerWrapperStyles}>
							{state && <GoalTracker label={state.label} score={state.score} value={state.value} />}
						</Inline>
					</Inline>
				),
				count,
			})),
		[values],
	);

	const renderContent = useCallback(
		() => (
			<FlyoutContent
				fieldName={commonMessages.goalTitle}
				label={label}
				content={content}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.goal-cell.goal-flyout"
				total={totalIssues}
				issuesWithoutField={unassignedCount}
				showTotal={false}
				titleWidth={300}
				ariaLabel={formatMessage(messages.ariaLabel, {
					count: content.length,
					groupByLabel,
					swimlane,
				})}
			/>
		),
		[content, label, totalIssues, unassignedCount, groupByLabel, swimlane, formatMessage],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<FieldBase
				component={Pressable}
				onClick={onToggle}
				triggerProps={triggerProps}
				tabIndex={0}
				testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.aggregate-field.goal-cell.${groupName}.${fieldKey}`}
				message={messages.numberOfGoals}
				messageValues={{ count: values.length }}
				fieldKey={fieldKey}
			/>
		),
		[onToggle, values.length, groupName, fieldKey],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
		/>
	);
};

export default GoalCell;

const goalWrapperStyles = xcss({
	width: '300px',
});

const trackerWrapperStyles = xcss({
	flexShrink: 0,
	marginLeft: 'space.100',
});

const iconWrapperStyles = xcss({
	flexShrink: 0,
	width: '24px',
	height: '24px',
	marginRight: 'space.050',
});

const goalNameWrapperStyles = xcss({
	flexGrow: 1,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
