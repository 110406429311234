import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	releases: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.marker.icon.releases',
		defaultMessage:
			'{numberOfReleases, plural, one {{numberOfReleases} release} other {{numberOfReleases} releases}}',
		description:
			'This label shows the number of releases when hovering over the release marker icon.',
	},
	releaseLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.marker.icon.release-label',
		defaultMessage: 'Release {name} ({date})',
		description:
			'Aria label for release markers on the Jira Plans Timeline page when there is only one release on a specific end date.',
	},
	releasesLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.marker.icon.releases-label',
		defaultMessage:
			'{numberOfReleases, plural, one {{numberOfReleases} release} other {{numberOfReleases} releases}} ({date})',
		description:
			'Aria label for release markers on the Jira Plans Timeline page when there are multiple releases on a specific end date.',
	},
});
