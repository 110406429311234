import React, { useState, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	CUSTOM_TIME_RANGE_TYPES,
	DateUnits,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import Tabs from '@atlassian/jira-portfolio-3-common/src/tabs/index.tsx';
import type { TabData } from '@atlassian/jira-portfolio-3-common/src/tabs/types.tsx';
import FixedDateRange from './fixed-date-range/index.tsx';
import messages from './messages.tsx';
import RelativeDateRange from './relative-time-range/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getDateString, getStringFromDates } from './relative-time-range/utils.tsx';

export const CustomDateRangeModalContent = ({
	dateRange,
	showTodayIndicator,
	setInitialFocusRef,
	onConfirm,
	onCancel,
	onSelectedTabChange,
}: Props) => {
	const {
		fromDate,
		fromNowUnit = DateUnits.MONTHS,
		fromNowUnitCount = 2,
		toDate,
		toNowUnitCount = 1,
		toNowUnit = DateUnits.MONTHS,
		typeOfCustomDateRange = CUSTOM_TIME_RANGE_TYPES.FIXED,
	} = dateRange;

	const [activeTabId, setActiveTabId] = useState(typeOfCustomDateRange);

	const [fromNowUnitState, setFromNowUnit] =
		useState<(typeof DateUnits)[keyof typeof DateUnits]>(fromNowUnit);
	const [toNowUnitState, setToNowUnit] =
		useState<(typeof DateUnits)[keyof typeof DateUnits]>(toNowUnit);
	const [fromNowUnitCountState, setFromNowUnitCount] = useState<number>(fromNowUnitCount);
	const [toNowUnitCountState, setToNowUnitCount] = useState<number>(toNowUnitCount);

	const [fromDateState, setFromDate] = useState<number>(fromDate || Date.now());
	const [toDateState, setToDate] = useState<number>(toDate || Date.now());

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const isValidDateRange = (fromDate?: string | number, toDate?: string | number): boolean =>
		fromDate != null && toDate != null && new Date(fromDate) <= new Date(toDate);

	const [isValid, setIsValidDateRange] = useState(isValidDateRange(fromDateState, toDateState));
	const [isInvalidFormat, setInvalidFormat] = useState(false);

	const onTabSelect = (selectedTab: TabData) => {
		setActiveTabId(selectedTab.id);

		if (onSelectedTabChange) {
			onSelectedTabChange();
		}
	};

	const onApplyClicked = useCallback(() => {
		if (activeTabId === CUSTOM_TIME_RANGE_TYPES.FIXED) {
			onConfirm({
				fromDate: fromDateState,
				toDate: toDateState,
				typeOfCustomDateRange: activeTabId,
			});
			return;
		}

		const customDateRange: CustomDateRange = {
			toNowUnit: toNowUnitState,
			fromNowUnit: fromNowUnitState,
			typeOfCustomDateRange: activeTabId,
		};

		if (toNowUnitCount != null) {
			customDateRange.toNowUnitCount = Math.max(0, Math.round(toNowUnitCountState));
		}
		if (fromNowUnitCount != null) {
			customDateRange.fromNowUnitCount = Math.max(0, Math.round(fromNowUnitCountState));
		}

		onConfirm(customDateRange);
	}, [
		activeTabId,
		fromDateState,
		fromNowUnitCount,
		fromNowUnitCountState,
		fromNowUnitState,
		onConfirm,
		toDateState,
		toNowUnitCount,
		toNowUnitCountState,
		toNowUnitState,
	]);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onFixedDateRangeChange = useCallback((fromDate: number, toDate: number) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const isValid = isValidDateRange(fromDate, toDate);
		setIsValidDateRange(isValid);
		setFromDate(fromDate);
		setToDate(toDate);
	}, []);

	const onRelativeValuesChange = useCallback(
		(value: number | (typeof DateUnits)[keyof typeof DateUnits], key: string) => {
			if (typeof value === 'number') {
				switch (key) {
					case 'fromNowUnitCount':
						setFromNowUnitCount(value);
						break;
					case 'toNowUnitCount':
						setToNowUnitCount(value);
						break;
					default:
				}
			} else if (typeof value === 'string') {
				switch (key) {
					case 'fromNowUnit':
						setFromNowUnit(value);
						break;
					case 'toNowUnit':
						setToNowUnit(value);
						break;
					default:
				}
			}
		},
		[],
	);

	const tabs = useMemo(
		() => [
			{
				id: CUSTOM_TIME_RANGE_TYPES.FIXED,
				label: <FormattedMessage {...messages.fixedTabLabel} />,
				testId:
					'portfolio-3-portfolio.app-simple-plans.top.time-scale.custom-modal-content.tabs.fixed',
				content: (
					<FixedDateRange
						onChange={onFixedDateRangeChange}
						fromDate={fromDateState}
						toDate={toDateState}
						isValidDateRange={isValid}
						setInvalidFormat={setInvalidFormat}
					/>
				),
			},
			{
				id: CUSTOM_TIME_RANGE_TYPES.RELATIVE,
				label: <FormattedMessage {...messages.relativeTabLabel} />,
				testId:
					'portfolio-3-portfolio.app-simple-plans.top.time-scale.custom-modal-content.tabs.relative',
				content: (
					<RelativeDateRange
						toNowUnitCount={toNowUnitCountState}
						fromNowUnitCount={fromNowUnitCountState}
						toNowUnit={toNowUnitState}
						fromNowUnit={fromNowUnitState}
						onRelativeValuesChange={onRelativeValuesChange}
						showTodayIndicator={showTodayIndicator}
					/>
				),
			},
		],
		[
			fromDateState,
			fromNowUnitCountState,
			fromNowUnitState,
			isValid,
			onFixedDateRangeChange,
			onRelativeValuesChange,
			showTodayIndicator,
			toDateState,
			toNowUnitCountState,
			toNowUnitState,
		],
	);
	const selectedTab = tabs.find((tab) => tab.id === activeTabId);

	return (
		<>
			<Tabs
				tabs={tabs}
				onSelect={onTabSelect}
				selected={selectedTab}
				{...(fg('smart_links_for_plans') ? { setInitialFocusRef } : {})}
			/>
			<ButtonGroup>
				<Button
					appearance="subtle"
					onClick={onCancel}
					testId="portfolio-3-custom-date-range.ui.modal-content.cancel"
				>
					<FormattedMessage {...messages.cancel} />
				</Button>
				<Button
					appearance="primary"
					isDisabled={
						(!isValid && activeTabId === CUSTOM_TIME_RANGE_TYPES.FIXED) || isInvalidFormat
					}
					onClick={onApplyClicked}
					testId="portfolio-3-custom-date-range.ui.modal-content.submit"
				>
					<FormattedMessage {...messages.apply} />
				</Button>
			</ButtonGroup>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: token('space.250'),
	marginRight: '0px',
	marginBottom: token('space.250'),
	marginLeft: '0px',
	gap: token('space.100'),
});
