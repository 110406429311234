import React, { type ReactNode } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';

export const MoreTooltip = ({ children, content }: { children: ReactNode; content: ReactNode }) => (
	<PolarisTooltip
		hideTooltipOnMouseDown
		content={
			<Flex xcss={wrapperStyles} wrap="wrap">
				{content}
			</Flex>
		}
	>
		{children}
	</PolarisTooltip>
);

const wrapperStyles = xcss({
	maxWidth: '370px',
});
