// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer, createHook } from '@atlassian/react-sweet-state';
import { loadPinnedFieldsByProjectIds } from '../actions/index.tsx';
import { getLoadError, getPinnedFieldsByProjectId } from '../selectors/index.tsx';
import type { State, ContainerProps } from '../types.tsx';

const initialState: State = {
	pinnedFields: undefined,
	error: undefined,
	currentFetchingProjectIds: [],
};

export const PinnedFieldsContainer = createContainer<ContainerProps>();

const handlers = {
	onInit: loadPinnedFieldsByProjectIds,
	onContainerUpdate: loadPinnedFieldsByProjectIds,
};

const Store = createStore({
	containedBy: PinnedFieldsContainer,
	initialState,
	handlers,
	actions: {},
	name: 'jpd-idas-cell-pinned-fields-container',
});

export const usePinnedFieldsByProjectId = createHook(Store, {
	selector: getPinnedFieldsByProjectId,
});

export const usePinnedFieldLoadError = createHook(Store, {
	selector: getLoadError,
});
