import { getIssuesWithOriginalsAndOptimizedByGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { IssuesByGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	getDateConfiguration,
	getPlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import {
	getStartDateCustomField,
	getCustomFields,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import { getAllTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { getVisualisationGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import { isConfluenceMacroProxyForIdeas } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import { PlanningUnits } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getDefaultIssuePriorityByProjectId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getAssociatedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/associated-issues/index.tsx';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/associated-issues/types.tsx';
import {
	getGroupByCustomFieldLabel,
	getGroupByStandardFieldLabel,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping/index.tsx';
import { isRoadmapGroupedByCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import { getGroupDescriptor } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/group/index.tsx';
import { isFocusConnectedWithReadPermissions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import {
	type OwnProps,
	type OwnPropsOld,
	type AggregateValuesMap,
	type MapStateToProps,
	type MapStateToPropsOld,
	type FieldKey,
	type AggregateField,
	type GroupByMesage,
	STANDARD,
	CUSTOM,
} from './types.tsx';

import {
	aggregateStartDate,
	aggregateDueDate,
	aggregateTargetStart,
	aggregateTargetEnd,
	aggregateStatus,
	aggregateStoryPoints,
	aggregateTimeEstimate,
	aggregateAssignee,
	aggregateTeam,
	aggregatePriority,
	aggregateProgressByEstimation,
	aggregateGoals,
	aggregateFixVersions,
	aggregateLabels,
	aggregateIdeas,
	aggregateIncomingDependencies,
	aggregateOutgoingDependencies,
} from './utils.tsx';

export const getAggregatedValuesMapPure = (
	issuesByGroup: IssuesByGroup,
	groupName: string,
	dateConfiguration: DateConfiguration,
	startDateCustomField: CustomField | undefined,
	defaultIssuePriorityByProjectId: Record<number, string>,
	associatedIssues: Record<string, AssociatedIssue>,
): AggregateValuesMap | undefined => {
	const group = issuesByGroup[groupName];

	if (!group?.issues) return undefined;

	const issues = Array.from(group.issues.values());

	if (issues.length === 0) return undefined;
	const status = aggregateStatus(issues);

	return {
		startDate: aggregateStartDate(issues, dateConfiguration, startDateCustomField),
		dueDate: aggregateDueDate(issues, dateConfiguration),
		targetStart: aggregateTargetStart(issues, dateConfiguration),
		targetEnd: aggregateTargetEnd(issues, dateConfiguration),
		storyPoints: aggregateStoryPoints(issues),
		timeEstimate: aggregateTimeEstimate(issues),
		assignee: aggregateAssignee(issues),
		team: aggregateTeam(issues),
		priority: aggregatePriority(issues, defaultIssuePriorityByProjectId),
		progressByEstimation: aggregateProgressByEstimation(issues),
		goals: aggregateGoals(issues),
		fixVersions: aggregateFixVersions(issues),
		status,
		breakdown: { ...status, fieldKey: 'breakdown' },
		labels: aggregateLabels(issues),
		ideas: aggregateIdeas(issues, associatedIssues),
		incomingDependencies: aggregateIncomingDependencies(issues),
		outgoingDependencies: aggregateOutgoingDependencies(issues),
	};
};

const getAggregatedValuesMap = createSelector(
	[
		getIssuesWithOriginalsAndOptimizedByGroup,
		(_: State, { groupName }: OwnProps | OwnPropsOld) => groupName,
		getDateConfiguration,
		getStartDateCustomField,
		getDefaultIssuePriorityByProjectId,
		getAssociatedIssues,
	],
	getAggregatedValuesMapPure,
);

// Map the field key to the aggregate field key.
const getFieldKey = createSelector(
	[getStartDateCustomField, getPlanningUnit, (_: State, { id }: OwnProps | OwnPropsOld) => id],
	(startDateCustomField, planningUnit, id): FieldKey | undefined => {
		switch (id) {
			case startDateCustomField?.id.toString():
				return 'startDate';
			case 'estimate':
				return planningUnit === PlanningUnits.storyPoints ? 'storyPoints' : 'timeEstimate';
			case 'issueStatus':
				return 'status';
			case 'goal':
				return 'goals';
			case 'release':
				return 'fixVersions';
			case 'startDate':
			case 'dueDate':
			case 'targetStart':
			case 'targetEnd':
			case 'storyPoints':
			case 'timeEstimate':
			case 'assignee':
			case 'breakdown':
			case 'team':
			case 'priority':
			case 'progressByEstimation':
			case 'labels':
			case 'ideas':
			case 'incomingDependencies':
			case 'outgoingDependencies':
				return id;
			default:
				return undefined;
		}
	},
);

const getField = createSelector(
	[getAggregatedValuesMap, getFieldKey],
	(valuesMap, fieldKey): AggregateField | undefined => valuesMap && fieldKey && valuesMap[fieldKey],
);

const getIssuesCount = createSelector(
	[
		getIssuesWithOriginalsAndOptimizedByGroup,
		(_: State, { groupName }: OwnProps | OwnPropsOld) => groupName,
	],
	(issuesByGroup: IssuesByGroup, groupName: string): number => {
		const group = issuesByGroup[groupName];

		if (!group?.issues) return 0;

		return group.issues.size;
	},
);

const getGroupByLabel = createSelector(
	[
		getCustomFields,
		getVisualisationGrouping,
		isConfluenceMacroProxyForIdeas,
		isFocusConnectedWithReadPermissions,
	],
	(
		customFields,
		groupBy,
		isConfluenceMacroProxyMode,
		focusConnectedWithReadPermissions,
	): GroupByMesage => {
		if (isRoadmapGroupedByCustomField(groupBy)) {
			return {
				type: CUSTOM,
				message: getGroupByCustomFieldLabel(groupBy, customFields),
			};
		}
		return {
			type: STANDARD,
			message: getGroupByStandardFieldLabel(
				groupBy,
				isConfluenceMacroProxyMode,
				focusConnectedWithReadPermissions,
			),
		};
	},
);

export const mapStateToPropsOld: MapStateToPropsOld = createStructuredSelector({
	field: getField,
});

const mapStateToProps: MapStateToProps = createStructuredSelector({
	field: getField,
	sprints: getSprints,
	allTeams: getAllTeams,
	totalIssues: getIssuesCount,
	groupDescriptor: getGroupDescriptor,
	groupByLabel: getGroupByLabel,
});

export default mapStateToProps;
