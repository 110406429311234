/** @jsx jsx */
import { useCallback, useLayoutEffect, useState, type FocusEventHandler } from 'react';
import { css, jsx } from '@compiled/react';
import { useGridRef } from '../../../controllers/grid/index.tsx';

/**
 * This component acts as a focus redirector in the tab sequence and should be placed right after the main grid element.
 * When the user tabs back into the grid, the focus will first reach this component and then be redirected to the grid.
 * This prevents the focus from unexpectedly landing on the last focusable element in the grid.
 */
export const FocusPassthrough: React.FC = () => {
	const [gridRef] = useGridRef();
	const [gridEl, setGridEl] = useState<HTMLTableElement>();

	const handleFocus: FocusEventHandler = useCallback(
		(e) => {
			if (!gridEl) {
				return;
			}

			if (!e.relatedTarget || !gridEl.contains(e.relatedTarget)) {
				gridEl.focus();
			}
		},
		[gridEl],
	);

	useLayoutEffect(() => {
		if (gridRef.current) {
			setGridEl(gridRef.current);
		}
	}, [gridRef]);

	if (!gridEl?.parentNode || !(gridEl.parentNode instanceof HTMLElement)) {
		return null;
	}

	return (
		// The following element is not necessary to be a button, but it needs to be focusable.
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			data-testid="portfolio-3-treegrid.ui.grid.focus-passthrough"
			data-focus-passthrough="true"
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}
			css={styles}
			onFocus={handleFocus}
		/>
	);
};

const styles = css({
	position: 'sticky',
	// This is to avoid scrolling the focus passthrough element into view.
	bottom: 0.1,
	left: 0.1,
});
