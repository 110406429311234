import React, {
	useCallback,
	useState,
	useMemo,
	useRef,
	type FocusEvent,
	type KeyboardEvent,
	type MouseEvent,
} from 'react';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import ScrollLock from '@atlassian/jira-portfolio-3-common/src/scroll-lock/index.tsx';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { dependenciesFlyoutEntryPoint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout/entrypoint.tsx';
import EntryPointPopup from '@atlassian/jira-portfolio-3-common/src/entry-point-popup/index.tsx';
import type { EntryPointContextProps } from '@atlassian/jira-portfolio-3-common/src/entry-point-popup/types.tsx';
import DependenciesSkeleton from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/dependencies-flyout/skeleton.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCellHighlight } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/common/index.tsx';
import { useIsRowFocused } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const DependenciesCell = (props: Props) => {
	const { issue, direction, issueLinkCount, isChanged, isOptimizedMode, isReadOnly } = props;

	const { formatMessage } = useIntl();

	const [isHovered, setIsHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const triggerRef = useRef<HTMLButtonElement>(null);

	let highlight: undefined | boolean | string; // remove the type when the treegrid_keyboard_navigation feature flag is removed
	let isRowFocused: undefined | boolean;
	let row: undefined | number;

	if (fg('plan_timeline_a11y_dependencies_cell')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[highlight] = useCellHighlight();

		if (row !== undefined) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			[isRowFocused] = useIsRowFocused(row);
		}
	}

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onHoverChanged = useCallback((value: boolean) => {
		setIsHovered(value);
	}, []);

	const onClose = useCallback((e: KeyboardEvent | MouseEvent) => {
		setIsOpen(false);
		if (fg('plan_timeline_a11y_dependencies_cell')) {
			// refocuses back on issue count button after adding the first dependency
			if (triggerRef?.current) {
				triggerRef.current.focus();
			}

			// if mouse event hide add dependency button trigger
			if (e.detail !== 0) {
				setIsFocused(false);
			}
		}
	}, []);

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter' || e.key === ' ') {
				e.preventDefault();
				onToggle();
			}
		},
		[onToggle],
	);

	const onFocus = useCallback((e: FocusEvent<HTMLElement>) => {
		if (e.target?.matches(':focus-visible')) {
			setIsFocused(true);
		}
	}, []);

	const onBlur = useCallback(() => {
		if (!isOpen) {
			setIsFocused(false);
		}
	}, [isOpen]);

	const renderIssueCountNew = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => (
			<Pressable
				xcss={issuesCountStyles}
				onClick={onToggle}
				tabIndex={0}
				role="button"
				onKeyDown={onKeyDown}
				ref={mergeRefs(ref, triggerRef)}
				{...triggerProps}
			>
				<Box xcss={issuesLabelStyles}>
					{formatMessage(
						direction === ISSUE_LINK_DIRECTION.INWARD
							? messages.incomingIssues
							: messages.outgoingIssues,
						{ count: issueLinkCount },
					)}
				</Box>

				{(isHovered || isFocused || isOpen || highlight === true) &&
					(isVisualRefreshEnabled() ? (
						<Box xcss={iconContainerStyle}>
							<ChevronDownIcon label="" color={token('color.icon')} />
						</Box>
					) : (
						<Box xcss={iconContainerStyle}>
							<ChevronDownIcon label="" LEGACY_size="large" color={token('color.icon')} />
						</Box>
					))}
			</Pressable>
		),
		[
			isHovered,
			isOpen,
			onToggle,
			onKeyDown,
			isFocused,
			highlight,
			formatMessage,
			direction,
			issueLinkCount,
		],
	);

	const renderIssueCountOld = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => (
			<Pressable ref={ref} xcss={issuesCountStyles} onClick={onToggle} {...triggerProps}>
				<Box xcss={issuesLabelStyles}>
					{formatMessage(
						direction === ISSUE_LINK_DIRECTION.INWARD
							? messages.incomingIssues
							: messages.outgoingIssues,
						{ count: issueLinkCount },
					)}
				</Box>

				{(isHovered || isOpen) &&
					(isVisualRefreshEnabled() ? (
						<Box xcss={iconContainerStyle}>
							<ChevronDownIcon label="" color={token('color.icon')} />
						</Box>
					) : (
						<ChevronDownIcon label="" LEGACY_size="large" color={token('color.icon')} />
					))}
			</Pressable>
		),
		[formatMessage, isHovered, isOpen, onToggle, direction, issueLinkCount],
	);

	const renderIssueCount = fg('plan_timeline_a11y_dependencies_cell')
		? renderIssueCountNew
		: renderIssueCountOld;

	const renderAddDependency = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => {
			if (fg('plan_timeline_a11y_dependencies_cell')) {
				const showHiddenItems =
					isHovered || isFocused || highlight === true || isRowFocused || isOpen;
				const visuallyHideItems =
					!isHovered && isRowFocused && highlight !== true && !isFocused && !isOpen;

				return !isReadOnly && showHiddenItems ? (
					<Pressable
						xcss={[addDependencyButtonStyles, visuallyHideItems && visuallyHiddenStyles]}
						role="button"
						onClick={onToggle}
						onKeyDown={onKeyDown}
						{...triggerProps}
						ref={ref}
					>
						<Box xcss={addDependencyButtonInner}>
							{formatMessage(
								direction === ISSUE_LINK_DIRECTION.INWARD
									? messages.incomingAddDependency
									: messages.outgoingAddDependency,
							)}
						</Box>
					</Pressable>
				) : null;
			}

			return !isReadOnly && (isHovered || isOpen) ? (
				<Button ref={ref} appearance="link" onClick={onToggle} {...triggerProps}>
					{formatMessage(
						direction === ISSUE_LINK_DIRECTION.INWARD
							? messages.incomingAddDependency
							: messages.outgoingAddDependency,
					)}
				</Button>
			) : null;
		},
		[
			formatMessage,
			isHovered,
			isOpen,
			isReadOnly,
			onToggle,
			direction,
			highlight,
			isFocused,
			isRowFocused,
			onKeyDown,
		],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			return (
				<HoverObserver onHoverChanged={onHoverChanged}>
					{fg('plan_timeline_a11y_dependencies_cell') ? (
						<Box
							xcss={triggerContainerStyles}
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.dependencies"
						>
							<Box xcss={addDependencyButtonWrapperStyles} onFocus={onFocus} onBlur={onBlur}>
								{issueLinkCount > 0
									? renderIssueCount(triggerProps)
									: renderAddDependency(triggerProps)}
							</Box>
							{isChanged && !isOptimizedMode ? <ChangeIndicator /> : null}
						</Box>
					) : (
						<Box xcss={triggerContainerStyles}>
							{issueLinkCount > 0
								? renderIssueCount(triggerProps)
								: renderAddDependency(triggerProps)}
							{isChanged && !isOptimizedMode ? <ChangeIndicator /> : null}
						</Box>
					)}
				</HoverObserver>
			);
		},
		[
			isChanged,
			isOptimizedMode,
			renderAddDependency,
			renderIssueCount,
			issueLinkCount,
			onHoverChanged,
			onFocus,
			onBlur,
		],
	);

	const renderContent = useCallback(
		({
			Component: DependenciesFlyout,
			...contentProps
		}: EntryPointContextProps<typeof dependenciesFlyoutEntryPoint>) => (
			<Box xcss={dialogStyles}>
				<DependenciesFlyout
					direction={direction}
					issue={issue}
					context="dropdown"
					updatePopup={contentProps.update}
					onClose={contentProps.onClose}
				/>
				<ScrollLock />
			</Box>
		),
		[direction, issue],
	);

	const renderFallback = useCallback(
		() => <DependenciesSkeleton issueCount={issueLinkCount} />,
		[issueLinkCount],
	);

	const entryPointParams = useMemo(() => ({}), []);

	return (
		<EntryPointPopup
			entryPoint={dependenciesFlyoutEntryPoint}
			entryPointParams={entryPointParams}
			id="dependencies-flyout"
			packageName="jira-portfolio-3-portfolio"
			teamName="jira-portfolio"
			messageId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies"
			messageType="transactional"
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			fallback={renderFallback}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
			{...(fg('plan_timeline_dependencies_field_improvements')
				? {
						role: 'dialog',
						label:
							issueLinkCount === 0
								? formatMessage(messages.addDependenciesPopupLabel)
								: formatMessage(messages.dependenciesPopupLabel),
					}
				: {})}
		/>
	);
};

const dialogStyles = xcss({
	boxSizing: 'border-box',
	overflow: 'hidden',
});

const iconContainerStyle = xcss({
	paddingTop: '0',
	paddingBottom: '0',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

const issuesCountStyles = xcss({
	cursor: 'pointer',
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	height: '100%',
	width: '100%',
	outline: 'none',
	background: 'transparent',
	padding: '0',
	':focus-visible': {
		outlineOffset: 'space.negative.025',
		outline: `2px solid ${token('color.border.focused')}`,
	},
});

const issuesLabelStyles = xcss({
	font: 'font.body',
	flex: '1 1 auto',
	paddingLeft: 'space.100',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});

const addDependencyButtonInner = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const triggerContainerStyles = xcss({
	width: '100%',
	maxWidth: '100%',
	overflow: 'hidden',
	height: '40px',
	display: 'flex',
	alignItems: 'center',
});

const addDependencyButtonWrapperStyles = xcss({
	height: '100%',
	width: '100%',
});

const addDependencyButtonStyles = xcss({
	background: 'transparent',
	color: 'color.link',
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	alignItems: 'center',
	width: '100%',
	height: '100%',
	display: 'flex',
	whiteSpace: 'nowrap',
	borderRadius: 'border.radius.100',
	':hover': {
		textDecoration: 'underline',
	},
	':focus': {
		outlineOffset: 'space.negative.025',
	},
	':focus-visible': {
		outlineOffset: 'space.negative.025',
	},
});

const visuallyHiddenStyles = xcss({
	opacity: 0,
});

export default DependenciesCell;
