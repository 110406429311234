import { memo } from 'react';
import { token } from '@atlaskit/tokens';
import { useParentIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import type { Props } from './types.tsx';

/** Returns the issue row background. */
export const BackgroundProvider = memo(({ issue, index, isOptimized, children }: Props) => {
	const parentIndicator = useParentIndicator({ id: issue?.id, index });

	const isEven = issue?.rootIndex === undefined ? false : issue.rootIndex % 2 === 0;

	if (parentIndicator)
		return children({
			base: parentIndicator.blocked
				? token('color.background.danger')
				: token('color.background.selected'),
		});

	if (!issue)
		return children({
			base: 'transparent',
		});

	if (isOptimized)
		return children({
			base: isEven ? 'transparent' : token('color.background.accent.gray.subtlest'),
			hover: token('elevation.surface.sunken'),
		});

	return children({
		base: isEven ? token('elevation.surface') : token('color.background.accent.gray.subtlest'),
		hover: token('elevation.surface.sunken'),
	});
});
