export const ISSUE_COUNT_BUCKETS = {
	'>30000': 30000,
	'25000->30000': 25000,
	'20000->25000': 20000,
	'15000->20000': 15000,
	'10000->15000': 10000,
	'5000->10000': 5000,
	'2000->5000': 2000,
	'1000->2000': 1000,
	'500->1000': 500,
	'<500': 0,
} as const;

export type PlanSize = keyof typeof ISSUE_COUNT_BUCKETS;

export const getPlanSize = (issueCount: number): PlanSize => {
	let key: PlanSize;
	for (key in ISSUE_COUNT_BUCKETS) {
		if (issueCount >= ISSUE_COUNT_BUCKETS[key]) return key;
	}
	return '<500';
};
