import React from 'react';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { Inline, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { isScenarioIssue as getIsScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import { REMOVE_ISSUE } from '../../constants.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const RemoveIssue = ({
	hierarchy,
	isDisabled = false,
	issue: { level, id: issueId },
	setModalAction,
}: Props) => {
	const currentLevel = hierarchy.find((hierarchyLevel) => level === hierarchyLevel.level);
	if (!isDefined(currentLevel)) {
		return null;
	}

	const isScenarioIssue = getIsScenarioIssue(issueId);

	const removeScrenarioIssueMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.removeScenarioIssueIssueTermRefresh
		: messages.removeScenarioIssue;

	const content = (
		<>
			<DropMenuItem
				id={`remove-${level}`}
				onClick={() => setModalAction(REMOVE_ISSUE)}
				key={`remove-${level}`}
				isDisabled={isDisabled}
			>
				<Inline xcss={menuItemLabelStyles}>
					<FormattedMessage
						{...(isScenarioIssue ? removeScrenarioIssueMessage : messages.removeSavedIssue)}
					/>
				</Inline>
			</DropMenuItem>
			{/* .
	Commenting out this option until inline delete is rolled out for Plans
	See: https://hello.atlassian.net/wiki/spaces/Spork/pages/4017365823/MAKE+PLVR-208+Allow+user+to+delete+unsaved+and+remove+saved+issue+directly+from+timeline
	<DropMenuItem
		id={`delete-${level}`}
		onClick={() => setModalAction(DELETE_ISSUE)}
		key={`delete-${level}`}
		isDisabled={isDisabled}
	>
		<Inline xcss={menuItemLabelStyles}>
			<FormattedMessage
				{...messages.deleteIssueNonFinal}
				values={{ issueType: hierarchyLevelTitle(currentLevel) }}
			/>
		</Inline>
	</DropMenuItem> */}
		</>
	);

	return fg('plan-timeline-a11y-meatball-menu') ? (
		content
	) : (
		<DropMenuItemGroup>{content}</DropMenuItemGroup>
	);
};

export default RemoveIssue;

const menuItemLabelStyles = xcss({
	maxWidth: '215px',
	display: 'inline-block',
});
