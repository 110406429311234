import React from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import type { CommonFieldComponentProps } from '../../common/types.tsx';

export const isShortTextFieldComponentProps = (
	props: CommonFieldComponentProps,
): props is ShortTextFieldComponentProps => 'value' in props && typeof props.value === 'string';

export type ShortTextFieldComponentProps = CommonFieldComponentProps & {
	value: string;
};

export const ShortTextField = ({ value, isMultiline }: ShortTextFieldComponentProps) => (
	<ShortTextFieldDisplay value={value} isMultiline={isMultiline || false} />
);

export const ShortTextFieldDisplay = ({
	value,
	isMultiline,
}: {
	value: string;
	isMultiline: boolean;
}) => <Box xcss={isMultiline ? multilineStyles : ellipsisStyles}>{value}</Box>;

const ellipsisStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const multilineStyles = xcss({
	wordBreak: 'break-word',
	textOverflow: 'clip',
	color: 'color.text',
});
