import React, { type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { DEFAULT_COLOR } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

function RemoveCell(props: Props) {
	const { intl, issueKey, projectKey } = props;
	const fullIssueKey = issueKey ? `${projectKey}-${issueKey}` : projectKey;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onClick = (event: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.DELETED_ISSUE_DEPENDENCY_LINK.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }));

		props.updatePopup?.();
		props.onClick(event);
	};

	return (
		<Tooltip content={intl.formatMessage(messages.removeDependency)}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.trashcan}>
				<Button
					appearance="subtle"
					onClick={onClick}
					ariaLabel={`${intl.formatMessage(messages.removeDependency)}: ${fullIssueKey}`}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies-old.flyout.cells.remove"
				>
					<TrashIcon
						label=""
						LEGACY_size="small"
						color={token('color.icon.subtle')}
						LEGACY_primaryColor={DEFAULT_COLOR}
					/>
				</Button>
			</div>
		</Tooltip>
	);
}

export default injectIntl(RemoveCell);
